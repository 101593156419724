import UserBundleContext from '@/context/UserBundleContext';
import { useContext } from 'react';


export const useOrgBaseURL = () => {

    const userBundle = useContext(UserBundleContext);

    return `/o/${userBundle.organization?.PREFIX}`;
};

