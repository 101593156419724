import React, { useState } from 'react';
import { INFORMATION_HIERARCHY } from '../../../../../../entities/org/INFORMATION_HIERARCHY';
import ReactSelect from 'react-select';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

type DepartmentInputFormProps = {
    orgDepartmentAlias: string;
    departments: INFORMATION_HIERARCHY[];
    onEdit: (department: INFORMATION_HIERARCHY) => void;
};

const useStyles = makeStyles({
    searchInputRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '1rem',
        marginBottom: '1rem',
    },
});

const selectWidth = '28rem';

export function DepartmentInputForm(props: DepartmentInputFormProps) {
    const [selectedDepartmentID, setSelectedDepartmentID] = useState(0);
    const { orgDepartmentAlias, departments } = props;
    const classes = useStyles();

    const options = departments.map(department => {
        return {
            label: department.TITLE,
            value: department.INFORMATION_HIERARCHY_ID,
        };
    });

    const handleButtonClick = () => {
        const selectedDepartment = departments.find(department => department.INFORMATION_HIERARCHY_ID === selectedDepartmentID);
        if (!selectedDepartment) {
            return;
        }

        props.onEdit(selectedDepartment);
    };

    return (
        <div>
            <div className={classes.searchInputRow}>
                <span>{orgDepartmentAlias}:</span>
                <ReactSelect
                    onChange={
                        (selected) => {
                            if (!selected) {
                                return;
                            }

                            const departmentID = selected.value;
                            setSelectedDepartmentID(departmentID);
                        }
                    }
                    options={options}
                    value={options.filter(option => option.value === selectedDepartmentID)}
                    styles={{
                        control: (provided: object) => ({
                            ...provided,
                            width: selectWidth
                        })
                    }}
                    isSearchable
                />
                <Button
                    variant='contained'
                    onClick={handleButtonClick}
                >
                    Display {orgDepartmentAlias} Documents
                </Button>
            </div>
        </div>
    );
}