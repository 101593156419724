import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import OrgSelectMenu from '../OrgSelectMenu/OrgSelectMenu';
import axios from 'axios';
import { PERSON } from '../../../entities/master/PERSON';
import { constructFullName } from '@/components/UserPicker/utils/constructFullName';

export default function UserImport() {
    const [ selectedOrgID, setSelectedOrgID ] = useState<number | undefined>(undefined);
    const [ selectedOrgID2, setSelectedOrgID2 ]  = useState<number | undefined>(undefined);
    const [ uploadedFile, setUploadedFile ] = useState<File | null>(null);
    const [ statusMessage, setStatusMessage ] = useState<string>('');
    const [ usersNewlyCreated, setUsersNewlyCreated ] = useState<PERSON[] | undefined>(undefined);
    const [ usersAlreadyInOrg, setUsersAlreadyInOrg ] = useState<PERSON[] | undefined>(undefined);
    const [ usersWithLoginID, setUsersWithLoginID ] = useState<PERSON[] | undefined>(undefined);
    const [ errorUsers, setErrorUsers ] = useState<PERSON[] | undefined>(undefined);

    function uploadFileToBrowser(files: FileList | null) {
        if (!files || !files[0])
            return;

        setUploadedFile(files[0]);
    }

    async function sendFileToServerToProcess() {
        if (!uploadedFile || !selectedOrgID)
            return;

        setStatusMessage('Processing...');
        setUsersNewlyCreated(undefined);
        setUsersAlreadyInOrg(undefined);
        setUsersWithLoginID(undefined);
        setErrorUsers(undefined);

        const formData = new FormData();
        formData.append('file', uploadedFile);
        formData.append('selectedOrgID', selectedOrgID.toString());

        const res = await axios.post('/api/internal-support/user-import/import-users',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );

        if (res.data.success) {
            setStatusMessage(res.data.success);
            setUsersNewlyCreated(res.data.usersNewlyCreated || []);
            setUsersAlreadyInOrg(res.data.usersAlreadyInOrg || []);
            setUsersWithLoginID(res.data.usersWithLoginID   || []);
            setErrorUsers(res.data.errorUsers || []);
        }
        else {
            setStatusMessage(`Error: ${res.data.error}` || 'Something went wrong.');
        }
    }

    const results: {
        users: PERSON[] | undefined,
        displayText: string
        subText?: string
        fontColor: string
    }[] = [
        {
            users: usersNewlyCreated,
            displayText: 'Users created',
            fontColor: 'green'
        },
        {
            users: usersAlreadyInOrg,
            displayText: 'Users already in this org',
            fontColor: 'dodgerblue'
        },
        {
            users: usersWithLoginID,
            displayText: 'Users with preexisting loginIDs',
            subText: `(you'll need to handle this list manually to see who needs to be mapped, and who has a 
                      conflicting loginID from some other, unrelated org, in which case the new records must be
                      inserted manually some other way)`,
            fontColor: 'darkorange'
        },
        {
            users: errorUsers,
            displayText: 'These new users could not be created',
            subText: '(likely due to a preexisting GUID; the existing user probably just needs to be mapped)',
            fontColor: 'red'
        }
    ];

    const resultsColumns = (
        results.map((column, idx) => {
            if (!column.users)
                return null;

            return (
                <div
                    key={idx}
                    style={{
                        flexBasis: `${100 / results.length}%`,
                        color: column.fontColor,
                        fontSize: '.9rem',
                        padding: '0 .5rem'
                    }}
                >
                    <p>{column.displayText} ({column.users.length}):</p>
                    {column.subText &&
                        <p>{column.subText}</p>
                    }
                    {column.users.length > 0 &&
                        <ul>
                            {column.users.map((user, idx) => {
                                return (
                                    <li key={idx}>
                                        <p>{constructFullName(user, 'lastFirstMiddle')}</p>
                                        <p>UserID: {user.USERID}</p>
                                        <p>LoginID: {user.LOGINID}</p>
                                        <p>({user.ORGANIZATION?.NAME || `orgID: ${user.ORGANIZATIONID}`})</p>
                                    </li>
                                );
                            })}
                        </ul>
                    }
                    {!column.users.length &&
                        <p>(None)</p>
                    }
                </div>
            );
        })
    );

    return (
        <div>
            <p>
                <Link to={'/internal-support'}>
                    Back
                </Link>
            </p>

            <h3>User Import</h3>

            <div style={{ display: 'flex', marginBottom: '2rem' }}>
                <div style={{ flexBasis: '50%', padding: '0 1rem' }}>
                    <p>How to use this feature:</p>

                    <ol>
                        <li>
                            Download&nbsp;
                            <a href={'https://www.lucidoc.com/cgi/doc-gw.pl?ref=lcorp:18456'} target={'_blank'} rel="noopener noreferrer" >
                                this file
                            </a>.
                        </li>
                        <li>
                            Fill out the columns accordingly (TO A MAX OF 1000 ROWS!), in the order provided.
                            The order must be exact, or it'll be rejected. If you need to add a column, ask the devs.
                            <ol style={{ margin: '.5rem 0' }}>
                                <li>LOGINID (this is the only mandatory value)</li>
                                <li>FIRSTNAME</li>
                                <li>LASTNAME</li>
                                <li>LOCATION (this means 'email')</li>
                                <li>MIDDLEINITIALS</li>
                                <li>PREFIX</li>
                                <li>SUFFIX</li>
                                <li>TITLE</li>
                                <li>GUID</li>
                                <li>DEPARTMENT_NAME</li>
                            </ol>
                        </li>
                        <li>
                            Select the org these users will be created in.
                        </li>
                        <li>
                            Upload the file.
                        </li>
                    </ol>
                    <p>
                        Tip: You can upload the same list twice. It'll detect duplicates correctly, and you'll be 
                        able to see the data again if you forget.
                    </p>
                    <p>
                        Other tip: There have been some timeout issues. Try shorter lists (200 rows each), then after you're
                        finished, upload the original, full list, and it'll show you everything that has been done for those accounts.
                    </p>
                    <p>
                        One last tip: If uploading to a multiorg, make sure it is already connected to the parent org
                        (if you ask the devs, this will be the "subscriber_schema" property if it's a subscriber, and
                        "publishing_schema" if it's the parent).
                    </p>
                </div>

                <div style={{ flexBasis: '50%', padding: '0 1rem' }}>
                    <p>What this feature will do:</p>

                    <ol>
                        <li>
                            It'll look through all the LOGINIDs to find any preexisting users.
                        </li>
                        <li>
                            If a user does NOT exist with that LoginID, the user will be created in the new org.
                        </li>
                        <li>
                            If a user DOES exist with that LoginID, but exists in a different org, or the selected org
                            is not a multiorg, then a new user will be created in the target org with that loginID;
                            otherwise no new user will be created.
                        </li>
                        <li>
                            You will then be shown a list of all created users, and all NOT created users (whether they 
                            were already in the org, or not). This script attempts to avoid creating duplicate entries, 
                            and will therefore give you a list of what you need to handle afterwards.
                        </li>
                    </ol>

                    <p>After that, you'll need to:</p>
                    <ol>
                        <li>
                            Read through the list of users that already existed with one of these loginIDs, and were not created
                            (it'll be in <span style={{ color: 'darkorange' }}>orange</span>).
                        </li>
                        <li>
                            Determine which ones have no connection to the selected instance, and just coincidentally 
                            share a LoginID (so a new user must be created, with that same loginID, but in the new org).
                        </li>
                        <li>
                            Determine which ones DO have the connection to this instance, and simply need to be mapped. 
                        </li>
                        <li>
                            Look through the list of users that could NOT be created (it'll be 
                            in <span style={{ color: 'red' }}>red</span>); these are likely due to a preexisting GUID,  
                            and probably just need to be mapped.
                        </li>
                    </ol>
                </div>
            </div>

            <OrgSelectMenu
                selectedOrgID={selectedOrgID}
                setSelectedOrgID={(orgID) => setSelectedOrgID(orgID)}
            />

            <p>Confirm one more time:</p>

            <OrgSelectMenu
                selectedOrgID={selectedOrgID2}
                setSelectedOrgID={(orgID) => setSelectedOrgID2(orgID)}
            />

            <div style={{ marginTop: '1rem' }}>
                <input
                    type={'file'}
                    disabled={!selectedOrgID || !selectedOrgID2 || selectedOrgID !== selectedOrgID2}
                    onChange={(e) => uploadFileToBrowser(e.currentTarget.files)}
                />
            </div>

            <div style={{ marginTop: '1rem' }}>
                <button
                    disabled={!selectedOrgID || !selectedOrgID2 || selectedOrgID !== selectedOrgID2 || !uploadedFile}
                    onClick={() => sendFileToServerToProcess()}
                >
                    Send File
                </button>
            </div>

            {statusMessage &&
                <p>{statusMessage}</p>
            }

            <div style={{ display: 'flex' }}>
                {resultsColumns}
            </div>

        </div>
    );
}
