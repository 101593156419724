import { Button, TextField, makeStyles } from '@material-ui/core';
import { INFORMATION_HIERARCHY } from '../../../entities/org/INFORMATION_HIERARCHY';
import ReactSelect from 'react-select';
import { USERGROUP } from '../../../entities/org/USERGROUP';
import { useState } from 'react';

const useStyles = makeStyles({
    searchContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '1rem',
        marginBottom: '1rem',
        gap: '1rem',
    },
    searchInputRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '1rem',
    },
});

const selectWidth = '28rem';

type AccessListSearchInputFormProps = {
    departments: INFORMATION_HIERARCHY[];
    userGroups: USERGROUP[];
    manuals: INFORMATION_HIERARCHY[];
    onSearch: (
        departmentIDs: number[],
        manualIDs: number[],
        docID: string,
        userGroupIDs: number[],
        userID: string
    ) => void;
};

export function AccessListSearchInputForm(props: AccessListSearchInputFormProps) {
    const [departmentIDs, setDepartmentIDs] = useState<number[]>([]);
    const [userGroupIDs, setUserGroupIDs] = useState<number[]>([]);
    const [manualIDs, setManualIDs] = useState<number[]>([]);
    const [docID, setDocID] = useState<string>('');
    const [userID, setUserID] = useState<string>('');

    const { departments, manuals, userGroups } = props;
    const classes = useStyles();

    const handleSearch = () => {
        props.onSearch(departmentIDs, manualIDs, docID, userGroupIDs, userID);
    };

    const departmentOptions = departments.map(department => {
        return {
            label: department.TITLE,
            value: department.INFORMATION_HIERARCHY_ID,
        };
    });

    const manualOptions = manuals.map(manual => {
        return {
            label: manual.TITLE,
            value: manual.INFORMATION_HIERARCHY_ID,
        };
    });

    const userGroupOptions = userGroups.map(userGroup => {
        return {
            label: userGroup.NAME,
            value: userGroup.USERGROUPID,
        };
    });

    const searchEnabled = (
        (departmentIDs.length > 0 || manualIDs.length > 0 || docID !== '') &&
        (userGroupIDs.length > 0 || userID !== '')
    );

    return (
        <div className={classes.searchContainer}>
            <div className={classes.searchInputRow}>
                <span>Department:</span>
                <ReactSelect
                    onChange={
                        (selected) => {
                            if (!selected) {
                                return;
                            }

                            const newDepartmentIDs = selected.map(option => option.value);
                            setDepartmentIDs(newDepartmentIDs);
                        }
                    }
                    options={departmentOptions}
                    value={departmentOptions.filter(option => departmentIDs.includes(option.value))}
                    styles={{
                        control: (provided: object) => ({
                            ...provided,
                            width: selectWidth
                        })
                    }}
                    isSearchable
                    isClearable
                    isMulti
                />
            </div>
            <div className={classes.searchInputRow}>
                <span>Manual:</span>
                <ReactSelect
                    onChange={
                        (selected) => {
                            if (!selected) {
                                return;
                            }

                            const newManualIDs = selected.map(option => option.value);
                            setManualIDs(newManualIDs);
                        }
                    }
                    options={manualOptions}
                    value={manualOptions.filter(option => manualIDs.includes(option.value))}
                    styles={{
                        control: (provided: object) => ({
                            ...provided,
                            width: selectWidth
                        })
                    }}
                    isSearchable
                    isClearable
                    isMulti
                />
            </div>
            <div className={classes.searchInputRow}>
                <span>Document ID:</span>
                <TextField
                    variant='outlined'
                    size='small'
                    value={docID}
                    onChange={e => setDocID(e.target.value)}
                />
            </div>
            <br />
            <div className={classes.searchInputRow}>
                <span>Usergroup:</span>
                <ReactSelect
                    onChange={
                        (selected) => {
                            if (!selected) {
                                return;
                            }

                            const newUserGroupIDs = selected.map(option => option.value);
                            setUserGroupIDs(newUserGroupIDs);
                        }
                    }
                    options={userGroupOptions}
                    value={userGroupOptions.filter(option => userGroupIDs.includes(option.value))}
                    styles={{
                        control: (provided: object) => ({
                            ...provided,
                            width: selectWidth
                        })
                    }}
                    isSearchable
                    isClearable
                    isMulti
                />
            </div>
            <div className={classes.searchInputRow}>
                <span>User ID:</span>
                <TextField
                    variant='outlined'
                    size='small'
                    value={userID}
                    onChange={e => setUserID(e.target.value)}
                />
            </div>
            <div>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={handleSearch}
                    disabled={!searchEnabled}
                >
                    Search
                </Button>
            </div>
        </div>
    );
}