import React, { ReactNode, useContext, useState } from 'react';
import { FIELD, FieldTypes } from '../../../entities/org/FIELD';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ContractRequestContext from './ContractRequestContext';
import { FIELDTEMPLATE } from '../../../entities/org/FIELDTEMPLATE';
import SelectMenuField from './fieldFieldHelpers/SelectMenuField';
import { documentMetadataValues } from './dataTypes';
import WrappedUserPickerField from './fieldFieldHelpers/WrappedUserPickerField';
import produceFlattenedOptionsFromNestedInformationHierarchy from '../../../utils/produceFlattenedOptionsFromNestedInformationHierarchy';
import { DatePicker } from '../../_shared/DatePicker/DatePicker';
import { Input } from '@/ui/ui/input';

// Yes, we're calling this FieldField as a reminder that it's an ACTUAL field, as opposed
// to a FieldPage, or a FieldGroup. The UI organizes these things like this:
// Page
// -- Group
//    -- Field
//    -- Field
// But "Page" or "Group" are too generic as names, so the React components are
// FieldPage and FieldGroup, and the Fields are FieldFields just to follow the pattern
// (and to avoid conflict with anything else called Field). Yes, it's weird. Whatever.
// Think of this as "ActualField" or "IndividualField" if you want.

interface FieldFieldProps {
    fieldTemplate: FIELDTEMPLATE
    field: FIELD | null
    updateDocumentMetadata: (arg0: documentMetadataValues) => void
}

const useStyles = makeStyles({
    fieldDiv: {
        marginBottom: '1.25rem'
    },
    fieldName: {
        margin: '0.25rem',
    },
    fieldDescription: {
        color: 'grey',
        fontSize: '.75rem'
    },
    radioButtonAndLabel: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '.5rem'
    }
});

export default function FieldField(props: FieldFieldProps) {
    const classes = useStyles();
    const context = useContext(ContractRequestContext);
    const [ date, setDate ] = useState<string>('');

    if (!props.field) {
        return null;
    }

    let input: ReactNode | null = null;

    // Determine which type of Input to use, determined by the field itself:
    switch (props.field.FIELDTYPEID) {

        case FieldTypes.text: {
            input = (
                <Input
                    type={'text'}
                    onChange={(e) => props.updateDocumentMetadata({
                        fieldName: props.field!.NAME,
                        value: e.currentTarget.value,
                        attrs: null
                    })}
                />
            );
            break;
        }

        case FieldTypes.number: {
            input = (
                <Input
                    type={'number'}
                    onChange={(e) => props.updateDocumentMetadata({
                        fieldName: props.field!.NAME,
                        value: e.currentTarget.value,
                        attrs: null
                    })}
                />
            );
            break;
        }

        case FieldTypes.date: {
            input = (
                <DatePicker
                    label={''}
                    value={date}
                    onChange={newDate => {
                        setDate(newDate);
                        const [ year, month, day ] = newDate.split('-');
                        props.updateDocumentMetadata({
                            fieldName: props.field!.NAME,
                            value: `${month}/${day}/${year}`,
                            attrs: null
                        });
                    }}
                />
            );
            break;
        }

        case FieldTypes.yesNoNA: {
            const yesNoNaOptions = [
                { text: 'Yes', value: 1 },
                { text: 'No', value: -1 },
                { text: 'Not applicable', value: null }
            ];
            input = yesNoNaOptions.map((yesNoOrNa, idx) => {
                return (
                    <span key={idx} className={classes.radioButtonAndLabel}>
                        <input
                            type={'radio'}
                            value={yesNoOrNa.text}
                            name={props.field!.FIELDID.toString()}
                            id={props.field!.FIELDID.toString() + `-${yesNoOrNa.text}`}
                            onChange={(e) => props.updateDocumentMetadata({
                                fieldName: props.field!.NAME,
                                value: e.currentTarget.value,
                                attrs: { ID: yesNoOrNa.value }
                            })}
                        />
                        <label htmlFor={props.field!.FIELDID.toString() + `-${yesNoOrNa.text}`}>
                            {yesNoOrNa.text}
                        </label>
                    </span>
                );
            });
            break;
        }

        case FieldTypes.textArea: {
            input = (
                <textarea
                    onChange={(e) => props.updateDocumentMetadata({
                        fieldName: props.field!.NAME,
                        // we need to preserve line breaks in the XML, replacing \n with a <br> tag, using HTML entities:
                        value: e.currentTarget.value.replace(/\n/g, '&#x3C;br&#x3E;'),
                        attrs: null
                    })}
                >
                </textarea>
            );
            break;
        }

        case FieldTypes.values: {
            const options = props.field.THESAURUS_VALUES && props.field.THESAURUS_VALUES
                .sort((a, b) => ('' + a.VALUE).localeCompare(b.VALUE || ''))
                .map(thesaurusValue => {
                    return {
                        value: thesaurusValue.THESAURUSID,
                        label: thesaurusValue.VALUE || '',
                    };
                });
            input = (
                <SelectMenuField
                    field={props.field}
                    options={options}
                    updateDocumentMetadata={(data) => props.updateDocumentMetadata(data)}
                />
            );
            break;
        }

        case FieldTypes.user: {
            input = (
                <WrappedUserPickerField
                    field={props.field}
                    updateDocumentMetadata={(data) => props.updateDocumentMetadata(data)}
                />
            );
            break;
        }

        case FieldTypes.category: {
            const options = produceFlattenedOptionsFromNestedInformationHierarchy(context.departments);
            input = (
                <SelectMenuField
                    field={props.field}
                    options={options}
                    updateDocumentMetadata={(data) => props.updateDocumentMetadata(data)}
                />
            );
            break;
        }

        case FieldTypes.manual: { // <-- FYI, manuals are called publishers in the database
            const options = context.publishers.map(publisher => {
                return {
                    value: publisher.PUBLISHER_ID,
                    label: publisher.TITLE,
                };
            });
            input = (
                <SelectMenuField
                    field={props.field}
                    options={options}
                    updateDocumentMetadata={(data) => props.updateDocumentMetadata(data)}
                />
            );
            break;
        }
    }

    return (
        <div className={classes.fieldDiv}>
            <p className={classes.fieldName}>
                {props.field.DISPLAY}:
            </p>

            {input}

            {props.field.DESCRIPTION &&
                <p className={classes.fieldDescription}>
                    ({props.field.DESCRIPTION})
                </p>
            }
        </div>
    );
}
