import React, { useState } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { Publish } from '@material-ui/icons';

interface FileInputProps {
    text?: string
    fileName: string | undefined // this might be able to do multiple but it hasn't come up yet
    handleFileUpload: (files: FileList | null) => void
    uniqueID: string // we need to use IDs, so if you have two of these on a page, you need unique IDs
}

const useStyles = makeStyles({
    fileInputDiv: {
        display: 'flex',
        alignItems: 'center',
    },
    button: {
        marginRight: '.5rem',
        padding: 0,
    },
    labelInsideButton: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'flex-start',
        padding: '8px 16px 4px 12px',
    },
    fileNameText: {
        margin: 0,
    },
    fakeHiddenInput: {
        display: 'none'
    },
    // hard to use regular startIcon prop in <Button /> because the <label> has to
    // be positioned IN the button (as far as I know), meaning the label needs
    // padding, the button needs zero padding, so the click happens on the button,
    // not just the text within the button, meaning this icon has to be manually
    // added inside the button, and then its CSS doctored to look normal:
    publishIcon: {
        fontSize: '20px',
        marginRight: '8px',
    }
});

export function FileInput(props: FileInputProps) {
    const classes = useStyles();

    // we cannot control the <input type='file' /> directly via React, so this is
    // one of the roundabout ways to force a re-render (useful when you upload the "same"
    // file, but after making changes to that file on the computer, which does not trigger
    // the onChange event, since the file name is the same as before):
    const [ inputKey, setInputKey ] = useState<number>(0);

    const fileName = props.fileName && props.fileName.length > 24
        ? props.fileName.slice(0, 24).concat('...')
        : props.fileName;

    return (
        <div className={classes.fileInputDiv}>
            <Button
                variant={'contained'}
                className={classes.button}
                onClick={() => setInputKey(inputKey + 1)}
            >
                <label htmlFor={props.uniqueID} className={classes.labelInsideButton}>
                    <Publish className={classes.publishIcon} />
                    {props.text || 'Select File...'}
                </label>
            </Button>
            <p className={classes.fileNameText}>{fileName || 'No file selected'}</p>

            <input
                key={inputKey}
                id={props.uniqueID}
                type={'file'}
                className={classes.fakeHiddenInput}
                onChange={e => props.handleFileUpload(e.currentTarget.files)}
            />
        </div>
    );
}
