import React, { useContext, useEffect, useState } from 'react';
import SubheaderItem from '../../../../_shared/Subheader/SubheaderItem';
import Subheader from '../../../../_shared/Subheader/Subheader';
import ItemsPendingApproval from '../ItemsPendingApproval';
import { CommitteeBacklog } from './CommitteeBacklog';
import { DocumentRevisionStatuses } from '../../../../../entities/org/DOCUMENTREVISION';
import { ApprovalSessionStatuses, ApprovalSessionTypes } from '../../../../../entities/org/APPROVAL_SESSION';
import { COMMITTEE } from '../../../../../entities/org/COMMITTEE';
import Select from 'react-select';
import { NotificationWindowTypes } from '../../../../../entities/org/NOTIFICATION_WINDOW';
import hasRights from '../../../../../_shared/utils/hasRights';
import UserBundleContext from '../../../../../context/UserBundleContext';
import axios from 'axios';
import SaveBar from '../../../../_shared/SaveBar/SaveBar';
import { redirectToHomepage } from '../../../../../utils/savebarUtilities';
import { TemporaryFavoritesStar } from '../../../../_shared/TemporaryFavoritesStar/TemporaryFavoritesStar';
import H3WithHelpTip from '../../../../_shared/Forms/H3WithHelpTip';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { makeStyles } from '@material-ui/core/styles';
import setWindowTitle from '../../../../../utils/setWindowTitle';
import { setTitleInAdmin } from '../../../../../utils/setTitleInAdmin';
import { RightsCodeKey } from '@/entities/master/PERSON';

const pageStyles = {
    pageContainer: {
        display: 'flex' as CSSProperties['display'],
        justifyContent: 'space-between' as CSSProperties['justifyContent'],
        alignItems: 'center' as CSSProperties['alignItems'],
        margin: '1rem 1rem 0 1rem',
    },
};

const useStyles = makeStyles(pageStyles);

export default function CRCommittees() {
    const Tabs: {[key: string]: string} = {
        CommitteeBacklog  : 'Committee Backlog',
        PendingApproval   : 'Pending Approval',
        PendingAdditional : 'Pending Additional',
        PendingRouting    : 'Pending Routing',
        PendingReview     : 'Pending Review',
        PendingArchive    : 'Pending Archive',
        PendingExpiration : 'Pending Expiration',
    };

    const classes = useStyles();
    const context = useContext(UserBundleContext);

    const userIsAdmin = hasRights(context, [RightsCodeKey.DocumentAdministrator, RightsCodeKey.UserAdministrator, RightsCodeKey.OrganizationAdministrator]);
    const isInAdmin = window.parent?.location.href.includes('admin/admin.pl');

    const [ selectedTab, setSelectedTab ] = useState<string>(userIsAdmin ? Tabs.CommitteeBacklog : Tabs.PendingApproval);
    const [ allCommittees, setAllCommittees ] = useState<COMMITTEE[]>([]);
    const [ selectedCommittee, setSelectedCommittee ] = useState<COMMITTEE | undefined>(undefined);

    async function getCommittees() {
        const res = await axios.get('/api/approval-routing/get-committee-backlog/get-committees-only');
        setAllCommittees(res.data.committees);
    }

    useEffect(() => {
        // Backlog loads this data anyway, so we only need to load it if we're not loading the backlog
        if (!userIsAdmin) {
            getCommittees().then();
        }

        const newTitle = 'Committee Compliance Reports';
        setWindowTitle(newTitle);
        setTitleInAdmin(newTitle);
    }, []);

    const selectMenu = selectedTab !== Tabs.CommitteeBacklog ? (
        <Select
            value={selectedCommittee
                ? {
                    label: selectedCommittee.NAME,
                    value: selectedCommittee.COMMITTEE_ID
                }
                : {
                    label: 'All Committees',
                    value: 0
                }
            }
            options={
                [{ NAME: 'All Committees', COMMITTEE_ID: 0 }]
                    .concat(allCommittees)
                    .map(committee => {
                        return {
                            label: committee.NAME,
                            value: committee.COMMITTEE_ID
                        };
                    })
            }
            onChange={(selectMenuOption: any) => setSelectedCommittee(allCommittees.find(committee => committee.COMMITTEE_ID === selectMenuOption.value))}
        />
    ) : undefined;

    return (
        <div>
            <Subheader
                value={selectedTab}
                onChange={value => setSelectedTab(value)}
                lastElement={
                    <div style={{ width: '12rem' }}>
                        {selectMenu}
                    </div>
                }
            >
                {Object.keys(Tabs)
                    .filter(tab => userIsAdmin ? true : !tab.includes('Backlog'))
                    .map(tab => <SubheaderItem key={Tabs[tab]} label={Tabs[tab]} value={Tabs[tab]} buttonWidth={'6rem'} />)
                }
            </Subheader>

            <div className={classes.pageContainer}>
                <H3WithHelpTip
                    helpText={`
                        The Committee Compliance reports are a set of committee-centered
                        reports designed to reveal where documents and manuals are in
                        their committee approval process and where completion of that process is delayed.
                    `}
                    text={'Committee Compliance Reports'}
                />

                <TemporaryFavoritesStar
                    targetPathName={isInAdmin ? 'admin/admin.pl?repname=committee_backlogs' : 'docmgr2/docmanager.pl?repname=committee_backlogs'}
                    targetTitle={isInAdmin ? 'Admin - Committee Compliance Reports' : 'DocManager - Committee Compliance Reports'}
                />
            </div>

            {selectedTab === Tabs.CommitteeBacklog &&
                <CommitteeBacklog
                    onSelectCommittee={(committeeID) => {
                        setSelectedCommittee(allCommittees.find(committee => committee.COMMITTEE_ID === committeeID));
                        setSelectedTab(Tabs.PendingApproval);
                    }}
                    sendCommitteesToParent={(committees) => setAllCommittees(committees)}
                />
            }

            {selectedTab === Tabs.PendingApproval &&
                <ItemsPendingApproval
                    committeeID={selectedCommittee && selectedCommittee.COMMITTEE_ID}
                    docStatusID={DocumentRevisionStatuses.pending_committee_approval}
                />
            }

            {selectedTab === Tabs.PendingAdditional &&
                <ItemsPendingApproval
                    committeeID={selectedCommittee && selectedCommittee.COMMITTEE_ID}
                    pendingAdditional={'committees'}
                />
            }

            {selectedTab === Tabs.PendingRouting &&
                <ItemsPendingApproval
                    committeeID={selectedCommittee && selectedCommittee.COMMITTEE_ID}
                    docStatusID={DocumentRevisionStatuses.pending_committee_routing}
                    approvalSessionStatus={ApprovalSessionStatuses.pending_approval_routing}
                />
            }

            {selectedTab === Tabs.PendingReview &&
                <ItemsPendingApproval
                    committeeID={selectedCommittee && selectedCommittee.COMMITTEE_ID}
                    approvalSessionType={ApprovalSessionTypes.review}
                    onlyGetItemsWithActiveCommitteeSessions={true}
                />
            }

            {selectedTab === Tabs.PendingArchive &&
                <ItemsPendingApproval
                    committeeID={selectedCommittee && selectedCommittee.COMMITTEE_ID}
                    approvalSessionType={ApprovalSessionTypes.archive}
                    onlyGetItemsWithActiveCommitteeSessions={true}
                />
            }

            {selectedTab === Tabs.PendingExpiration &&
                <ItemsPendingApproval
                    committeeID={selectedCommittee && selectedCommittee.COMMITTEE_ID}
                    getItemsComingUpFor={NotificationWindowTypes.expiration}
                    onlyGetItemsWithActiveCommitteeSessions={true}
                />
            }
            <SaveBar onClose={() => redirectToHomepage()}/>
        </div>
    );
}
