import { useContext, useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, makeStyles, Radio, RadioGroup } from '@material-ui/core';
import { LoadingIndicator } from '../../LoadingIndicator';
import axios from 'axios';
import { DOCUMENT } from '../../../../entities/org/DOCUMENT';
import { INFORMATION_HIERARCHY } from '../../../../entities/org/INFORMATION_HIERARCHY';
import { PUBLISHER } from '../../../../entities/org/PUBLISHER';
import LucidocColors from '../../../../constants/LucidocColors';
import { type PERSON, isPerson } from '../../../../entities/master/PERSON';
import { USERGROUP, isUserGroup } from '../../../../entities/org/USERGROUP';
import H3WithHelpTip from '../H3WithHelpTip';
import SaveBar from '../../SaveBar/SaveBar';
import SaveIndicator from '../../SaveIndicator';
import UserBundleContext from '../../../../context/UserBundleContext';
import { RefreshRounded } from '@material-ui/icons';
import { LoadingStatuses } from '../../../../utils/LoadingStatuses';
import { EditItemVisibilityUserPicker } from './EditItemVisibilityUserPicker';
import { generateIntersectionError } from '../../../../_shared/utils/editItemVisibility/generateIntersectionError';
import { hasIntersection } from '../../../../_shared/utils/departmentUserPicker/hasIntersection';
type EditItemVisibilityProps = {
    objectType: 'DOCID' | 'INFORMATION_HIERARCHY_ID' | 'PUBLISHER_ID'
    infoHierarchySubType?: 'MANUAL' | 'DEPARTMENT' // because INFORMATION_HIERARCHY can be either; this is just used for display name purposes, FYI
    objectID: number
    closeModal?: () => void
}

const useStyles = makeStyles({
    mainDiv: {
        width: 'calc(100% - 1rem)',
        height: 'calc(100% - 4rem)',
        overflowY: 'auto',
        margin: '1rem 1rem',
    },
    radioButtonLabel: {
        fontFamily: 'Quattrocento Sans'
    },
    userPickerDiv: {
        width: 560,
        margin: '1rem 0'
    },
    indent: {
        marginLeft: '2rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
    },
    rowFlexBox: {
        display: 'flex',
        flexDirection: 'row',
    },
    divider: {
        margin: '1rem 1rem 1rem 0',
    },
    departmentVisibilityRestrictions: {
        color: 'red',
        marginTop: '1rem',
    },
});

export function  EditItemVisibility(props: EditItemVisibilityProps) {
    const classes = useStyles();

    const context = useContext(UserBundleContext);

    const [ loadingStatus, setLoadingStatus ] = useState<LoadingStatuses>(LoadingStatuses.LOADING);
    const [ isSaveIndicatorVisible, setIsSaveIndicatorVisible ] = useState(false);

    const [ object, setObject ] = useState<DOCUMENT | INFORMATION_HIERARCHY | PUBLISHER | undefined>();
    const [ viewers, setViewers ] = useState<(PERSON | USERGROUP)[]>([]);
    const [ departmentViewers, setDepartmentViewers ] = useState<(PERSON | USERGROUP)[]>([]);
    const [ limitedViewers, setLimitedViewers ] = useState<(PERSON | USERGROUP)[]>([]);
    const [ fullViewers, setFullViewers ] = useState<(PERSON | USERGROUP)[]>([]);

    const isDepartment = props.objectType === 'INFORMATION_HIERARCHY_ID' && props.infoHierarchySubType === 'DEPARTMENT';
    const isManual = props.objectType === 'INFORMATION_HIERARCHY_ID' && props.infoHierarchySubType === 'MANUAL';
    const isDocument = props.objectType === 'DOCID';
    const isManualGroup = props.objectType === 'PUBLISHER_ID';
    const isMultiOrg = !!context.organization?.PUBLISHING_SCHEMA;

    useEffect(() => {
        loadData().then();
    }, []);

    function getDisplayName() {
        if (isDocument) return 'Document';
        if (isManualGroup) return `${context.organization?.MANUALNAME} Group`;
        if (isManual) return context.organization?.MANUALNAME;
        if (isDepartment) return context.organization?.ORGUNITNAME;
    }

    async function loadData() {
        setIsSaveIndicatorVisible(false);
        setLoadingStatus(LoadingStatuses.LOADING);
        const res = await axios.get('/api/_shared/forms/edit-item-visibility', {
            params: {
                objectType: props.objectType,
                objectID: props.objectID,
                objectSubType: props.infoHierarchySubType,
            }
        });

        setObject(res.data.object); // this gets VIEWCONTROL and, if it's a document, ISPUBLIC
        setViewers(res.data.viewers || []);
        setDepartmentViewers(res.data.departmentViewers || []);
        setLimitedViewers(res.data.limited || []);
        setFullViewers(res.data.full || []);
        setLoadingStatus(LoadingStatuses.READY);
    }

    async function saveVisibilitySettings() {
        setLoadingStatus(LoadingStatuses.SAVING);

        if (isDepartment) {
            const inheritVisibility = (object as INFORMATION_HIERARCHY).INHERITVISIBILITY;
            const viewControl = (object as INFORMATION_HIERARCHY).VIEWCONTROL;

            (object as INFORMATION_HIERARCHY).INHERITVISIBILITY = viewControl ? inheritVisibility : false;
        }

        const res = await axios.post('/api/_shared/forms/edit-item-visibility/save-viewers', {
            objectType: props.objectType,
            objectID: props.objectID,
            objectSubType: props.infoHierarchySubType,
            object: object,
            viewers: viewers,
            limitedViewers: limitedViewers,
            fullViewers: fullViewers,
        });

        setIsSaveIndicatorVisible(true);
        if (res.data.success) await loadData();
    }

    function getDepartmentVisibilityRestrictionsMessage() {
        const doc = object as DOCUMENT;
        const departmentAlias = (context.organization?.ORGUNITNAME ?? 'department').toLocaleLowerCase();
        const departmentName = doc.INFORMATION_HIERARCHY?.TITLE ?? 'unknown';

        return (
            <p className={classes.departmentVisibilityRestrictions}>
                The {departmentName} {departmentAlias} has applied {departmentAlias}-wide visibility restrictions on all its documents.
            </p>
        );
    }

    if (loadingStatus !== LoadingStatuses.READY || !object) {
        return <LoadingIndicator loadingStatus={loadingStatus} />;
    }

    const radioButtonData = [
        {
            label: `${getDisplayName()} visible to everyone`,
            value: 'false',
        },
        {
            label: `${getDisplayName()} visible to owners, collaborators, and these users/groups:`,
            value: 'true',
        },
    ];

    // this is only a DOCUMENT value so it requires its own checkbox in the form
    const isPublic = isDocument && (object as DOCUMENT).ISPUBLIC;

    const unremovablePeople: {[key: string]: string} = {};
    const unremovableGroups: {[key: string]: string} = {};

    departmentViewers.forEach(collaborator => {
        const unremovableMessage = 'Department collaborators cannot be removed';
        if (isPerson(collaborator)) {
            unremovablePeople[collaborator.USERID] = unremovableMessage;
        }
        if (isUserGroup(collaborator)) {
            unremovableGroups[collaborator.USERGROUPID] = unremovableMessage;
        }
    });

    const userPickerErrors: string[] = [];
    if (isDepartment) {
        limitedViewers.forEach(limitedViewer => {
            fullViewers.forEach(fullViewer => {
                if (!hasIntersection(limitedViewer, fullViewer)) {
                    return;
                }

                userPickerErrors.push(generateIntersectionError(limitedViewer, fullViewer));
            });
        });
    }

    const departmentVisibilityRestrictions = (
        !!(object as DOCUMENT).INFORMATION_HIERARCHY?.VIEWCONTROL &&
        !!(object as DOCUMENT).INFORMATION_HIERARCHY?.INHERITVISIBILITY
    );

    return (
        <div className={classes.mainDiv}>
            {isDocument &&
                <>
                    <H3WithHelpTip
                        helpText={'Set external visibility'}
                        text={'External Visibility'}
                    />

                    <div className={classes.indent}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={(object as DOCUMENT).ISPUBLIC}
                                    onChange={e => setObject({
                                        ...object,
                                        ISPUBLIC: e.currentTarget.checked
                                    } as DOCUMENT)}
                                    style={{ color: LucidocColors.purple }}
                                />
                            }
                            label={
                                <span style={{ fontFamily: 'Quattrocento Sans' }}>
                                    Make document visible outside Lucidoc
                                </span>
                            }
                        />

                        {isDocument && isMultiOrg &&
                            <FormControlLabel
                                label={
                                    <span style={{ fontFamily: 'Quattrocento Sans' }}>
                                        Make document visible to facilities
                                    </span>
                                }
                                control={
                                    <Checkbox
                                        checked={(object as DOCUMENT).IS_VISIBLE_TO_SUBSCRIBER}
                                        onChange={e => setObject({
                                            ...object,
                                            IS_VISIBLE_TO_SUBSCRIBER: e.currentTarget.checked
                                        })}
                                        style={{ color: (object as DOCUMENT).ISPUBLIC ? LucidocColors.gray : LucidocColors.purple }}
                                        disabled={(object as DOCUMENT).ISPUBLIC}
                                    />
                                }
                            />
                        }
                    </div>

                    <hr className={classes.divider} />
                </>
            }

            <H3WithHelpTip
                helpText={'Set internal visibility'}
                text={'Internal Visibility'}
                disabled={(object as DOCUMENT).ISPUBLIC}
            />
            <div className={classes.indent}>
                {isDocument && departmentVisibilityRestrictions && getDepartmentVisibilityRestrictionsMessage()}
                <RadioGroup
                    value={object.VIEWCONTROL ? 'true' : 'false'}
                    onChange={e => setObject({
                            ...object,
                            VIEWCONTROL: e.currentTarget.value === 'true'
                        } as DOCUMENT | INFORMATION_HIERARCHY | PUBLISHER)
                    }
                >
                    {radioButtonData.map(item => (
                        <FormControlLabel
                            value={item.value}
                            label={item.label}
                            control={
                                <Radio
                                    style={{ color: isPublic ? LucidocColors.gray : LucidocColors.purple }}
                                    disabled={isPublic}
                                />
                            }
                            classes={{
                                label: classes.radioButtonLabel
                            }}
                            disabled={isPublic}
                        />
                    ))}
                </RadioGroup>

                <EditItemVisibilityUserPicker
                    userPickerClass={classes.userPickerDiv}
                    objectType={props.objectType}
                    objectSubType={props.infoHierarchySubType}
                    disabled={isPublic || !object.VIEWCONTROL}
                    updateUsers={(newUsers: (PERSON | USERGROUP)[]) => setViewers(newUsers as PERSON[])}
                    updateGroups={(newUsers: (PERSON | USERGROUP)[]) => setViewers(newUsers as PERSON[])}
                    updateLimitedUsers={(newUsers: (PERSON | USERGROUP)[]) => setLimitedViewers(newUsers as PERSON[])}
                    updateLimitedGroups={(newUsers: (PERSON | USERGROUP)[]) => setLimitedViewers(newUsers as PERSON[])}
                    updateFullUsers={(newUsers: (PERSON | USERGROUP)[]) => setFullViewers(newUsers as PERSON[])}
                    updateFullGroups={(newUsers: (PERSON | USERGROUP)[]) => setFullViewers(newUsers as PERSON[])}
                    viewers={viewers}
                    limitedViewers={limitedViewers}
                    fullViewers={fullViewers}
                    unremovablePeople={unremovablePeople}
                    unremovableGroups={unremovableGroups}
                />

                {isDepartment &&
                    <div className={classes.rowFlexBox}>
                    <H3WithHelpTip
                        helpText={'Department documents will be visible only to owner, collaborators, department owner and collaborators, and users on the Full Visibility list.'}
                        text={''}
                        disabled={!object.VIEWCONTROL}
                    />
                    <FormControlLabel
                        value={(object as INFORMATION_HIERARCHY).INHERITVISIBILITY}
                        label={'Enforce department visibility restrictions on department documents'}
                        control={
                            <Checkbox
                                checked={(object as INFORMATION_HIERARCHY).INHERITVISIBILITY}
                                onChange={e => setObject({
                                    ...object,
                                    INHERITVISIBILITY: e.currentTarget.checked
                                })}
                                style={{ color: object.VIEWCONTROL ? LucidocColors.purple : LucidocColors.gray }}
                                disabled={!object.VIEWCONTROL}
                            />
                        }
                        classes={{
                            label: classes.radioButtonLabel
                        }}
                        disabled={!object.VIEWCONTROL}
                    />
                    </div>
                }
                
                {isManual && isMultiOrg &&
                    <div className={classes.rowFlexBox}>
                    <H3WithHelpTip
                        helpText={'Manual will be visible to all users at facilities, even if visibility is otherwise constrained to specific users at the Corporate organization.'}
                        text={''}
                    />
                    <FormControlLabel
                        value={(object as INFORMATION_HIERARCHY).VISIBLE_ON_FACILITY_HOMEPAGES}
                        label={'Manual visible on facility home pages'}
                        control={
                            <Checkbox
                                checked={!!(object as INFORMATION_HIERARCHY).VISIBLE_ON_FACILITY_HOMEPAGES}
                                onChange={e => setObject({
                                    ...object,
                                    VISIBLE_ON_FACILITY_HOMEPAGES: e.currentTarget.checked
                                })}
                                style={{ color: LucidocColors.purple }}
                            />
                        }
                        classes={{
                            label: classes.radioButtonLabel
                        }}
                    />
                    </div>
                }
            </div>

            {userPickerErrors.length > 0 &&
                <div className={classes.indent}>
                    <p>Please fix the following input errors:</p>
                    <ul>
                        {userPickerErrors.map((error, index) => (
                            <li key={index} style={{ color: 'red' }}>{error}</li>
                        ))}
                    </ul>
                </div>
            }

            <SaveBar
                onSave={ () => saveVisibilitySettings() }
                isSaveDisabled={ userPickerErrors.length > 0 }
                whyIsSaveDisabled={ userPickerErrors.length > 0 ? 'Please fix the input errors' : undefined }

                onCancel={ () => loadData() }
                cancelIcon={ <RefreshRounded /> }

                onClose={ () => props.closeModal && props.closeModal() }
            />

            <SaveIndicator
                open={isSaveIndicatorVisible}
                onClose={() => setIsSaveIndicatorVisible(false)}
                message={'Visibility settings updated successfully.'}
            />
        </div>
    );
}
