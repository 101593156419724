import { INFORMATION_HIERARCHY } from '../../../../entities/org/INFORMATION_HIERARCHY';
import { MUIDataTableProps } from 'mui-datatables';
import { transformACLArrayIntoStringArray } from './transformACLs';
import { ucFirst } from '../../../../_shared/utils/docManagerFunctions';
import { constructFullName } from '@/components/UserPicker/utils/constructFullName';

export function transformDepartmentsToReadableDataTableValues(
    departments: INFORMATION_HIERARCHY[]
)
    : MUIDataTableProps['data']
{
    return departments.map(dep => ({
        ID: dep.INFORMATION_HIERARCHY_ID,
        Name: dep.TITLE,
        Status: ucFirst(dep.STATUS),
        Owner: dep.USER ? constructFullName(dep.USER, 'lastFirstMiddle') : '',
        Image: dep.IMAGE?.REVISION_NOTE,
        Collaborators: transformACLArrayIntoStringArray(dep.COLLABORATORS || []).join('\n'),
        Visibility: dep.VIEWCONTROL ? 'Restricted' : '',
        Viewers: transformACLArrayIntoStringArray(dep.VIEWERS || []).join('\n'),
    }));
}
