// Copied from Constants.pm (try to update both if you can)

export const helpUserIDs: {
    [key: string]: number
} = {
    DEFAULT:  1000,
    PRELOGIN: 1001,
    READER:   1002,
    AUTHOR:   1003,
    SIGNER:   1004,
    ADMIN:    1005
};

export const helpTopicManualIDs: {
    [key: string]: number
} = {
    DOCMGR: 10046,
    ADMIN:  10045,
    PLUGIN: 10370
};

export const helpTopicDocIDs = {
    HOME_ANON: 36633,
    HOME_READER: 36632,
    HOME_AUTHOR: 36631,
    HOME_ADMIN: 36630,

    HOME_DASHBOARD_DEPARTMENTS: 36643,
    HOME_DASHBOARD_MANUALS: 36628,

    HOME_PREFERENCES: 36647,
    HOME_SEARCH_POPUP: 36594,
    ADVSEARCH: 11293,
    DOCGW: 11236,
    DOCLISTVIEW: 11333,
    WHATSNEW: 11314,
    FEEDBACK_DRAFT: 36027,
    HOME: 10284, // this is likely deprecated; not sure what's going on here
    LOGIN: 11370,
    LOGINADV: 11373,
    LOGINFAIL: 11370,
    PASSWORD: 11315,
    SUBSCRIBE: 11241,
    EVALUATE: 36302,
    ASK_BUTTON: 36327,
    NEW_ASK_BUTTON: 36635,
    USER_PROFILE: 36600,
    SELF_PROFILE: 36475,
    TRAINING: 36634,
    FAVORITES: 36638,

    // doc manager/wizards help
    DOCMGR: 29443,
    DOCMGR_CREATEDOC: 10022,
    DOCMGR_EDITOR: 10217,
    DOCMGR_CREATEREP: 11293,
    // DOCMGR_GRADEBOOK: 00000, // not sure what the 00000 is supposed to mean

    DOCMGR_DEPTS: 36646, // 35987 is deprecated
    DOCMGR_MANUALS: 27823,
    DOCMGR_GROUPS: 36356,
    DOCMGR_MYDOCS: 11623,
    DOCMGR_EDITABLE: 13243,
    DOCMGR_PENDING: 14863,
    DOCMGR_OFFICIAL: 16483,
    DOCMGR_RELEASE: 18103,
    DOCMGR_SIGN: 19723,
    DOCMGR_WAIVE: 36474,
    DOCMGR_EXPIRING: 35932,
    DOCMGR_REVIEW: 21343,
    DOCMGR_FEEDBACK: 35931,
    DOCMGR_FETCH: 36383,
    CONTRACTS_ROUTING: 36563,
    APPROVAL_ROUTING: 36590,
    MANAGE_ROUTING: 36592,
    SAML_PROFILE: 36611,

    // DOCMGR_BULKLOAD: 11443, // this is wrong, and the ID goes to the homepage
    DOCMGR_ARCHIVED: 36366,

    DOCMGR_FEEDBACK_SENT: 36417,

    DOCMGR_MY_CORPORATE_DOCUMENTS: 36394,
    DOCMGR_MY_CORPORATE_DOCUMENTS_SUBSCRIBER: 36473,
    DOCMGR_NEW_CORPORATE_DOCUMENTS: 36395,
    DOCMGR_CORPORATE_DOCUMENT_STATUS: 36396,
    DOCMGR_SUBSCRIBER_IMPLEMENTATION: 36393,
    DOCMGR_CORPORATE_WAIVER_REQUESTS: 36499,
    DOCMGR_SUBSCRIBER_WAIVER_REQUESTS: 36498,
    DOCMGR_FACILITY_IMPLEMENTATION: 36601,

    DOCMGR_SIGNERS: 36390,

    DOCMGR2_ACK_SCHEDULES: 36434,
    DOCMGR2_ACKNOWLEDGEMENTS: 36402,
    DOCMGR2_COMMITTEE_ROUTING: 36371,
    DOCMGR_EDITCAT_COMMITTEE_ROUTING: 0, // to do

    DOCMGR2_EDUCATION_OVERVIEW: 36423, // COURSE MANAGEMENT
    DOCMGR2_EDUCATION_OVERVIEW_IS_ADMIN: 36425, // COURSE MANAGEMENT
    DOCMGR2_EDUCATION_OVERVIEW_DASHBOARD: 36426, // COURSE REPORTING
    DOCMGR2_EDUCATION_GENERAL_PROPERTIES: 36487,
    DOCMGR2_EDUCATION_GEN_PROP_IS_ADMIN: 36490,
    DOCMGR2_EDUCATION_SYLLABUS: 36494,
    DOCMGR2_EDUCATION_SCHEDULE: 36491,
    DOCMGR2_EDUCATION_ENROLLMENT: 36488,
    DOCMGR2_EDUCATION_REVIEW: 36489,
    DOCMGR2_EDUCATION_CALENDAR: 36424,
    CGI_EDUCATION_OVERVIEW: 36427,

    // manage pages help
    MANAGEDOC: 32683,
    MANAGEDOC_GENERAL: 35947,
    MANAGEDOC_REVCYCLE: 35950,
    MANAGEDOC_COLLAB: 35948,
    MANAGEDOC_NOTIFY: 35955,
    MANAGEDOC_VIS: 35949,
    MANAGEDOC_MANUALS: 35954,
    MANAGEDOC_LINKS: 35953,
    MANAGEDOC_DOWNLOAD: 35990,
    MANAGEDOC_EDIT: 36409,
    MANAGEDOC_EDITBIN: 35988,
    MANAGEDOC_HISTORY: 35952,
    MANAGEDOC_MARKREVIEW: 35928,
    MANAGEDOC_ARCHIVE: 36145,
    MANAGEDOC_NEWREV: 10014,
    MANAGEDOC_PUBSTATE: 35945,
    MANAGEDOC_REVIEWS: 35951,
    MANAGEDOC_SIGSTATUS: 35991,
    MANAGEDOC_EXAM: 36304,
    MANAGEDOC_EXAMRESULTS: 36305,
    MANAGEDOC_XMD: 36330,

    MANAGEDOC_CORPORATE_FEEDBACK: 36397,
    MANAGEDOC_ORIGINAL_REVISION_DATES: 36419,
    MANAGEDOC_SIGNERS_AND_SIGNATURES: 36391,

    MANAGECAT: 26203,
    MANAGEMAN: 35995,
    MANAGEGROUP: 36356,

    // admin tools help
    ADM_HOME_PREFERENCES: 36648,
    ADM_GENERAL: 11342,
    ADM_OINFO: 11343,
    ADM_OCONTACTS: 11344,
    ADM_CONTACTS_NEW: 36620,
    ADM_OUNITNAMES: 11345,
    ADM_OUNITS: 11346,
    ADM_OCSS: 11347,
    ADM_ODISPLAY: 11596,
    ADM_OSECURITY: 11597,
    ADM_REFERENCE_LINKS: 36604,
    ADM_USERS_NEW: 36597,
    ADM_MASS_DISABLE: 36621,
    ADM_UPDATE_GUIDS: 36622,
    ADM_GROUPS: 11600,
    ADM_DOCUMENTS_SEL: 11377,
    ADM_DOCUMENTS_LIST: 11378,
    ADM_TEMPLATES: 11379,
    ADM_MANUALGROUPS: 11380,
    ADM_MANUALGROUP: 11382,
    ADM_LOADDOCS: 36290,
    ADM_RECENT: 36320,
    ADM_COMP_PREFS: 36295,
    ADM_COMP_REPORT: 36296,
    ADM_COMP_REPORT_EXAMS: 36312,
    ADM_COMP_REPORT_RESULTS: 36313,
    ADM_FIELDS: 36326,
    ADM_OXML: 36392,
    ADM_MONTHLY_REPORT: 36358,
    ADM_DASHBOARD_EXPIRING: 36359,
    ADM_DASHBOARD_STATUS: 36360,
    ADM_DASHBOARD_REVIEWING: 36361,
    ADM_TRANSFER_OWNER: 36362,
    ADM_TRANSFER_DEPT: 36363,
    ADM_SIGNERS: 36364,
    ADM_COLLABORATORS: 36388,
    ADM_TRANSFER_HISTORY: 36598,
    ADM_TRANSFER_ACK_SCHEDULES: 36599,
    ADMIN_COMMITTEE_PREFERENCES: 36367,
    ADMIN_COMMITTEES: 36368,
    ADMIN_ACK_PREFERENCES: 36336,
    ADMIN_ACK_SCHEDULES: 36432,
    ADMIN_ACK_USERS: 36337,
    ADMIN_ACK_DOCUMENTS: 36338,
    ADMIN_ACK_MANUALS: 36339,
    ADM_BULK_LOADER: 36407,
    ADM_BULK_LOADER_HISTORY: 36408,
    VIEWING_HISTORY: 36587,
    ADM_FEEDBACK_PREFERENCES: 36591,
    DATE_RANGE_REPORT: 36602,
    ADM_EMAIL_DOMAINS: 36624,
    UPDATE_DOC_REVIEW_METADATA: 36644,

    ADMIN_EDUCATION_REPORT_BUILDER: 36428,
    ADMIN_EDUCATION_PREFERENCES: 36493,
    // ADMIN_DOWNTIME_BACKUP: 36430, // to do
    ADMIN_SUPPORT_PORTAL: 36549,
    ADMIN_CONTRACT_PREFERENCES: 36564,
    ADMIN_GLOBAL_IDENTIFIER_PREFS: 36579,

    // dialog help
    DLG_USERLIST: 11439,
    DLG_USERGROUPLIST: 11602,
    DLG_CALENDAR: 11438,
    DLG_ADD_TO_MANUAL: 36167,
    DLG_MANUAL_GROUP: 36512,
    DLG_ADDREF: 35953,
    DLG_DOCSEARCH: 35993,
    DLG_SIGN: 36084,
    DLG_QUESTION: 36304,
    DLG_RESULTDETAIL: 36305,
    DLG_THESLIST: 36331,
    DLG_FIELDTEMP: 11379,
    DLG_CRITERIA: 11293,
    DLG_EXAMRESULTS: 10004,
    // DLG_RUSERLIST: 00000, // not sure what the 00000 is supposed to mean

    EDITING_IN_PROGRESS: 36370,

    ADMIN_DASHBOARD_COMMITTEE_APPROVAL_STATUS: 36381,
    ADMIN_DASHBOARD_COMMITTEE_BACKLOGS: 36382,
    ADMIN_DASHBOARD_ROUTING_HISTORY: 36380,
    ADMIN_DASHBOARD_ROUTING_HISTORY_NEW: 36588,
    ADMIN_TRANSFER_DOC_TYPE: 36440,
    CGI_MY_ACKNOWLEDGEMENTS: 36433,
    CGI_MY_MEETINGS: 36369,
    DOCMGR2_COMMITTEES: 36375,
    DOCMGR2_CONSENT_AGENDA_MANAGEMENT: 36378,
    DOCMGR2_CONSENT_AGENDA_VIEWING: 36559,
    DOCMGR2_DASHBOARD_COMMITTEE_APPROVAL_STATUS: 36377,
    DOCMGR2_EDITCOMMITTEE: 36376,

    VIEW_MANUAL: 36404,
    VIEW_DEPARTMENT: 36026,

    RECOVER_DISCARDED_DOCUMENT: 0, // to do

    EFFECTIVE_DATE_DEFINITIONS: 36420,

    DOCMGR2_APPROVAL_ROUTE_EDITOR: 36439,

    // Deprecated in favor of the ones just beneath (WEBINAR_CATALOG etc)
    // TRAINING_DIALOG_LUCIDOC_BASICS: 36436,
    // TRAINING_DIALOG_DOCMANAGER: 36437,
    // TRAINING_DIALOG_ADMINISTRATION: 36438,

    WEBINAR_CATALOG_ACCREDITATION_MANAGER: 36605,
    WEBINAR_CATALOG_ACKNOWLEDGEMENTS: 36606,
    WEBINAR_CATALOG_ADMINISTRATION: 36438,
    WEBINAR_CATALOG_COMMITTEE_AND_SIGNER_APPROVALS: 36607,
    WEBINAR_CATALOG_CONTINUING_EDUCATION: 36608,
    WEBINAR_CATALOG_CONTRACTS_MANAGEMENT: 36609,
    WEBINAR_CATALOG_DOCUMENT_AUTHORING_AND_MANAGEMENT: 36437,
    WEBINAR_CATALOG_HOME_PAGE: 36436,
    WEBINAR_CATALOG_MULTI_ORG: 36610,

    WHATS_NEW: 36629,

    ACCREDITATION_MANAGER_ADMIN_PREFERENCES: 36455,

    ACCREDITATION_MANAGER: 36465,
    ACCREDITATION_PROGRAM: 36468,
    ACCREDITATION_CHECKLIST: 36469,
    CHECKLIST_CONDITION: 36470,
    ACCREDITATION_ACTION_HISTORY: 36476,
    ACCREDITATION_MANAGER_DIRECTOR: 36464, // Links to Accreditation Manager Overview (for now)
    WHITELISTED_USER: 36464, // Links to Accreditation Manager Overview (for now)
    ACCREDITOR_REPRESENTATIVE: 36464, // Links to Accreditation Manager Overview (for now)
    CHECKLIST_EDITOR: 36464, // Links to Accreditation Manager Overview (for now)
    ACCREDITATION_MANAGER_SEARCH: 36492,

    LARGE_PDF: 36523,

    COMPLIANCE_REPORT_COMMITTEES: 36581,
    COMPLIANCE_REPORT_DEPARTMENTS: 36582,
    COMPLIANCE_REPORT_DOCTYPES: 36585,
    COMPLIANCE_REPORT_SIGNERS: 36586,

    COMMITTEES_MY_MEETINGS_AND_CALENDAR: 36639,
    COMMITTEES_COMMITTEES: 36376
};

export const trainingWebinarDocIDs = [
    helpTopicDocIDs.WEBINAR_CATALOG_ACCREDITATION_MANAGER,
    helpTopicDocIDs.WEBINAR_CATALOG_ACKNOWLEDGEMENTS,
    helpTopicDocIDs.WEBINAR_CATALOG_ADMINISTRATION,
    helpTopicDocIDs.WEBINAR_CATALOG_COMMITTEE_AND_SIGNER_APPROVALS,
    helpTopicDocIDs.WEBINAR_CATALOG_CONTINUING_EDUCATION,
    helpTopicDocIDs.WEBINAR_CATALOG_CONTRACTS_MANAGEMENT,
    helpTopicDocIDs.WEBINAR_CATALOG_DOCUMENT_AUTHORING_AND_MANAGEMENT,
    helpTopicDocIDs.WEBINAR_CATALOG_HOME_PAGE,
    helpTopicDocIDs.WEBINAR_CATALOG_MULTI_ORG,
];
