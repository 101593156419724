import React, { useContext } from 'react';
import { Route, Switch } from 'react-router';
import GlobalIdentifier from './components/GlobalIdentifier';
import ViewingHistory from './components/Documents/ViewingHistory';
import RoutingHistory from '../ApprovalRouting/components/RoutingHistory';
import EditApprovalRouters from './components/Organization/EditApprovalRouters';
import { ManageUsersRouter } from './components/Users/ManageUsersRouter';
import TransferAcknowledgementSchedules from './components/Acknowledgements/TransferAcknowledgementSchedules';
import DocumentTransferHistory from './components/Documents/DocumentTransferHistory/DocumentTransferHistory';
import { OfficialDocsInDateRange } from './components/Documents/OfficialDocsInDateRange/OfficialDocsInDateRange';
import { EditHomepageLinks } from './components/Homepage/EditHomepageLinks';
import { SamlProfile } from './components/SamlProfile/SamlProfile';
import hasRights from '../../_shared/utils/hasRights';
import UserBundleContext from '../../context/UserBundleContext';
import { ManageDepartments } from '../DocManager/components/Departments/ManageDepartments';
import { ManageManuals } from '../DocManager/components/Manuals/ManageManuals';
import { RightsCodeKey } from '@/entities/master/PERSON';
import { UpdateDocrevReviewMetadata } from './components/Documents/UpdateDocrevReviewMetadata/UpdateDocrevReviewMetadata';
import { ManagePreferences } from './components/Preferences/ManagePreferences';

export default function Administration() {
    const context = useContext(UserBundleContext);

    return (
        <div>
            {hasRights(context, RightsCodeKey.OrganizationAdministrator) &&
                <Switch>
                    <Route path={'/administration/organization/departments'} render={() => <ManageDepartments isInAdmin={true} /> } />
                    <Route path={'/administration/organization/global-identifier'} component={GlobalIdentifier} />
                    <Route path={'/administration/organization/edit-homepage-links'} component={EditHomepageLinks} />
                    <Route path={'/administration/organization/edit-approval-routers'} component={EditApprovalRouters} />
                    <Route path={'/administration/organization/saml-profile'} component={SamlProfile}/>
                </Switch>
            }

            {hasRights(context, RightsCodeKey.UserAdministrator) &&
                <Switch>
                    <Route path={'/administration/users/manage-users'} component={ManageUsersRouter} />
                    <Route path={'/administration/users/manage-preferences'} component={ManagePreferences} />
                </Switch>
            }

            {hasRights(context, RightsCodeKey.DocumentAdministrator) &&
                <Switch>
                    <Route path={'/administration/documents/viewing-history'} component={ViewingHistory} />
                    <Route path={'/administration/documents/transfer-history'} component={DocumentTransferHistory} />
                    <Route path={'/administration/documents/official-docs-in-date-range'} component={OfficialDocsInDateRange} />
                    <Route path={'/administration/documents/update-docrev-review-metadata'} component={UpdateDocrevReviewMetadata} />
                </Switch>
            }

            {/* Any admin can see the following */}
            {hasRights(context, [RightsCodeKey.UserAdministrator, RightsCodeKey.DocumentAdministrator, RightsCodeKey.OrganizationAdministrator]) &&
                <Switch>
                    <Route path={'/administration/dashboards/routing-history'} component={RoutingHistory}/>
                    <Route path={'/administration/acknowledgements/transfer-schedules'} component={TransferAcknowledgementSchedules}/>
                    <Route path={'/administration/organization/manuals'} render={() => <ManageManuals isInAdmin={true} /> } />
                </Switch>
            }
        </div>
    );
}
