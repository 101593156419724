import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from '@/ui/ui/dialog';
import React, { useEffect, useState } from 'react';
import { Homepage2DialogContentStyle, Homepage2Query } from '../../Homepage2';
import { useBrowserQuery } from '@/hooks/useBrowserQuery';
import { GearOutlineIcon } from '@/ui/icons/outline/GearOutline';
import { HOMEPAGE_LINK, createHomepageLinkEntity } from '@/entities/org/HOMEPAGE_LINK';
import axios from 'axios';
import { InputWithLabel } from '@/ui/ui/input';
import { Button } from '@/ui/ui/button';
import { Cross2Icon } from '@radix-ui/react-icons';
import { useForm } from '@tanstack/react-form';
import { schemaEntityManager } from '@/utils/emInstance';
import { TabStates } from '../../Homepage2';

interface EditReferenceLinksDialogProps {
    refetch: () => void
}

interface EditReferenceLinksDialogQuery extends Homepage2Query {
    editReferenceLinksDialogOpen?: boolean
}

export const EditReferenceLinksDialog: React.FC<EditReferenceLinksDialogProps> = ({ refetch }) => {
    const [query, setQuery] = useBrowserQuery<EditReferenceLinksDialogQuery>();

    const [links, setLinks] = useState<HOMEPAGE_LINK[]>([]);



    useEffect(() => {
        axios.get('/api/homepage/card/homepage-links').then(response => {
            setLinks(response.data.homepageLinks);
        });
    }, []);


    const form = useForm({
        defaultValues: {
            title: '',
            url: ''
        },
        onSubmit: ({ value, formApi }) => {
            if (value.title === '' || value.url === '') {
                return;
            }


            if (value.url.startsWith('http://') || value.url.startsWith('https://')) {
                // replace with empty string
                value.url = value.url.replace('http://', '').replace('https://', '');
            }

            const HomepageLinkEntity = createHomepageLinkEntity('', schemaEntityManager);
            const link = new HomepageLinkEntity();
            link.TEXT = value.title;
            link.URL = value.url;

            setLinks([link, ...links]);
            formApi.reset();
        }
    });

    const [saving, setSaving] = useState(false);
    const save = async () => {
        setSaving(true);
        const res = await axios.post('/api/administration/organization/homepage-links/save-links2', {
            homepageLinks: links,
        });

        setLinks([...res.data.newHomepageLinks]);
        setSaving(false);
    };

    return (
        <Dialog
            open={query.editReferenceLinksDialogOpen ?? false}
            // Set to undefined to keep the URL state clean.
            onOpenChange={(open) => {
                setQuery({ 
                    homepageTab: TabStates.Overview,
                    editReferenceLinksDialogOpen: open || undefined 
                });
                refetch();
            }}
        >
            <DialogTrigger asChild>
                <div className='cursor-pointer'>
                    <GearOutlineIcon size={18} />
                </div>
            </DialogTrigger>
            <DialogContent className={Homepage2DialogContentStyle}>
                <DialogHeader>
                    <DialogTitle>
                        Edit Reference Links
                    </DialogTitle>
                    <DialogDescription>
                        Modify reference links.
                    </DialogDescription>
                </DialogHeader>
                <div className='flex flex-col w-full'>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            form.handleSubmit();
                        }}
                        className='flex flex-row space-x-4 w-full items-end'>
                        <form.Field name={'title'}>
                            {(field) => {
                                return <InputWithLabel
                                    label='title'
                                    placeholder='Lucidoc'
                                    name={field.name}
                                    value={field.state.value}
                                    onBlur={field.handleBlur}
                                    onChange={(e) => field.handleChange(e.target.value)}
                                />;
                            }}
                        </form.Field>
                        <form.Field name={'url'}>
                            {(field) => {
                                return <InputWithLabel
                                    label='URL' containerClassName='w-full' placeholder='www.lucidoc.com'
                                    name={field.name}
                                    value={field.state.value}
                                    onBlur={field.handleBlur}
                                    onChange={(e) => field.handleChange(e.target.value)}
                                />;
                            }}
                        </form.Field>
                        <Button type={'submit'} size={'md'}>Create</Button>
                        <Button size={'md'} disabled={saving} onClick={() => save()}>Save</Button>
                    </form>
                    <div className='flex flex-col items-start mt-2 w-full space-y-4'>
                        {links.map((link, idx) => {
                            return <div key={`${link.HOMEPAGE_LINK_ID}-${idx}`}
                                className='flex flex-row py-1 items-start space-y-4 w-full'
                            >
                                <div className='flex flex-col items-start w-full mr-1 space-y-2'>
                                    <InputWithLabel label='Title' containerClassName='w-full' value={link.TEXT} onChange={(e) => {
                                        //    Use idx to update the correct link.
                                        links[idx].TEXT = e.target.value;
                                        setLinks([...links]);

                                    }} />
                                    <InputWithLabel label='URL' containerClassName='w-full' value={link.URL} onChange={(e) => {
                                        links[idx].URL = e.target.value;
                                        setLinks([...links]);
                                    }} />
                                </div>
                                <Button variant={'ghost'} size={'icon'} onClick={() => {
                                    links.splice(idx, 1);
                                    setLinks([...links]);
                                }}><Cross2Icon /></Button>
                            </div>;
                        })}
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};