import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { MUIDataTableProps } from 'mui-datatables';
import { LoadingIndicator } from '../../../../_shared/LoadingIndicator';
import { LoadingStatuses } from '../../../../../utils/LoadingStatuses';
import { NOTIFICATION_WINDOW } from '../../../../../entities/org/NOTIFICATION_WINDOW';
import { DOCUMENT_TYPE_PL } from '../../../../../entities/org/DOCUMENT_TYPE_PL';
import ProduceBacklogTable from '../ProduceBacklogTable';

interface DocTypeBacklogProps {
    onSelectDocType?: (docTypeID: number) => void
    sendDocTypesToParent?: (docTypes: DOCUMENT_TYPE_PL[]) => void
}

export default function DocTypeBacklog(props: DocTypeBacklogProps) {
    const [ loadingStatus, setLoadingStatus ] = useState(LoadingStatuses.MOUNTING);
    const [ tableData, setTableData ] = useState<MUIDataTableProps['data']>([]);

    useEffect(() => {
        loadDocTypeBacklog().then();
    }, []);

    async function loadDocTypeBacklog() {
        setLoadingStatus(LoadingStatuses.LOADING);

        const res = await axios.get('/api/doc-manager/compliance-reports/get-doctype-backlog');

        setTableData(processDocTypesForTable(
            res.data.docTypes,
            res.data.docRevsOverDueByDocTypeID,
            res.data.docRevsDueSoonByDocTypeID,
            res.data.reviewWindows
        ));

        props.sendDocTypesToParent?.(res.data.docTypes);

        setLoadingStatus(LoadingStatuses.READY);
    }

    function processDocTypesForTable(
        docTypes: DOCUMENT_TYPE_PL[],
        docRevsOverDueByDocTypeID: {[key: string]: number},
        docRevsDueSoonByDocTypeID: {
            [key: string]: {
                [key: string]: number
            }
        },
        reviewWindows: NOTIFICATION_WINDOW[]
    ) {
        return docTypes.map(docType => {
            const tableRow: {[key: string]: string | number} = {
                DocTypeID: docType.DOCTYPE_ID,
                DocType: docType.DESCRIPTION,
                'Overdue for Review': docRevsOverDueByDocTypeID[docType.DOCTYPE_ID],
            };

            reviewWindows.forEach(rw => {
                tableRow[`${rw.WINDOW} Day${rw.WINDOW > 1 ? 's': ''}`] = docRevsDueSoonByDocTypeID?.[docType.DOCTYPE_ID]?.[rw.WINDOW] || '';
            });

            return tableRow;
        });
    }

    if (loadingStatus !== LoadingStatuses.READY) {
        return (
            <LoadingIndicator/>
        );
    }

    return (
        <ProduceBacklogTable
            title={'DocType'}
            tableData={tableData}
            titleOfHiddenIDColumn={'DocTypeID'}
            onSelectItem={(docTypeID) => props.onSelectDocType?.(docTypeID)}
            onRefresh={() => loadDocTypeBacklog()}
        />
    );
}
