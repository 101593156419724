import React from 'react';

interface UnknownStatusShadowIconProps {
    size: number;
}

export const UnknownStatusShadowIcon: React.FC<UnknownStatusShadowIconProps> = ({ size }) => {

    return (
        <svg width={size.toString()} height={size.toString()} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 4C0 1.79086 1.79086 0 4 0H12C14.2091 0 16 1.79086 16 4V12C16 14.2091 14.2091 16 12 16H4C1.79086 16 0 14.2091 0 12V4Z" fill="hsl(var(--muted-foreground))"/>
            <path d="M7.01814 10.5C7.00812 10.5 7 10.4919 7 10.4819V10.1881C7 9.16854 7.62073 8.25171 8.56736 7.87306V7.87306C9.13064 7.64774 9.5 7.10219 9.5 6.49551V6.35836C9.5 5.60816 8.89184 5 8.14164 5H7.92623C7.13854 5 6.5 5.63854 6.5 6.42623V6.42623C6.5 6.46697 6.46697 6.5 6.42623 6.5H4.675C4.57835 6.5 4.5 6.42165 4.5 6.325V6.325C4.5 4.48865 5.98865 3 7.825 3H8.28863C10.0622 3 11.5 4.46416 11.5 6.23775V6.23775C11.5 7.59294 10.6749 8.83003 9.41667 9.33333V9.33333C9.16501 9.43399 9 9.67773 9 9.94876V10.2831C9 10.4029 8.9029 10.5 8.78312 10.5H7.01814Z" fill="hsl(var(--muted))"/>
            <path d="M7 11.75C7 11.3358 7.33579 11 7.75 11H8H8.25C8.66421 11 9 11.3358 9 11.75V12.25C9 12.6642 8.66421 13 8.25 13H7.75C7.33579 13 7 12.6642 7 12.25V11.75Z" fill="hsl(var(--muted))"/>
            <path d="M0 4C0 1.79086 1.79086 0 4 0H12C14.2091 0 16 1.79086 16 4V12C16 14.2091 14.2091 16 12 16H4C1.79086 16 0 14.2091 0 12V4Z" fill="hsl(var(--muted-foreground))"/>
            <path d="M7.01814 10.5C7.00812 10.5 7 10.4919 7 10.4819V10.1881C7 9.16854 7.62073 8.25171 8.56736 7.87306V7.87306C9.13064 7.64774 9.5 7.10219 9.5 6.49551V6.35836C9.5 5.60816 8.89184 5 8.14164 5H7.92623C7.13854 5 6.5 5.63854 6.5 6.42623V6.42623C6.5 6.46697 6.46697 6.5 6.42623 6.5H4.675C4.57835 6.5 4.5 6.42165 4.5 6.325V6.325C4.5 4.48865 5.98865 3 7.825 3H8.28863C10.0622 3 11.5 4.46416 11.5 6.23775V6.23775C11.5 7.59294 10.6749 8.83003 9.41667 9.33333V9.33333C9.16501 9.43399 9 9.67773 9 9.94876V10.2831C9 10.4029 8.9029 10.5 8.78312 10.5H7.01814Z" fill="hsl(var(--muted))"/>
            <path d="M7 11.75C7 11.3358 7.33579 11 7.75 11H8H8.25C8.66421 11 9 11.3358 9 11.75V12.25C9 12.6642 8.66421 13 8.25 13H7.75C7.33579 13 7 12.6642 7 12.25V11.75Z" fill="hsl(var(--muted))"/>
        </svg>
    );
};