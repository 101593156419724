import React, { useState } from 'react';
import axios from 'axios';
import { Button, Input, InputLabel } from '@material-ui/core';
import { FileInput } from '../../_shared/FileInput';

interface UpdateAccrediationManagerChecklistsProps {

}

export const UpdateAccrediationManagerChecklists: React.FC<UpdateAccrediationManagerChecklistsProps> = () => {
    const [uploadedFile, setUploadedFile] = useState<File | null>(null);
    const [orgAccreditationId, setOrgAccreditationId] = useState<number | null>(null);
    const [outputMessage, setOutputMessage] = useState<string | null>(null);

    const handleFileUpload = (files: FileList | null) => {
        if (!files || !files[0])
            return;

        setUploadedFile(files[0]);
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!uploadedFile) return;
        if (!orgAccreditationId) {
            alert('You must enter an orgAccreditationId!');
            return;
        }

        if (uploadedFile.type !== 'text/csv') {
            alert('File must be a CSV!');
            return;
        }

        // DO the thing
        console.log(uploadedFile);
        setOutputMessage(null);

        uploadedFile.text()
            .then(fileText => {
                return axios.post('/api/internal-support/fix-accreditation-manager-checklists', {
                    content: fileText,
                    orgAccreditationId: orgAccreditationId
                });
            })
            .then(res => {
                if (res.data.error) {
                    throw res.data.error;
                }

                if (res.data.failure) {
                    throw new Error(res.data.failure);
                }

                setOutputMessage('Success!');
            })
            .catch(err => setOutputMessage('Error occured in process: ' + err));
    };

    return <div>
        <h1>Update Accreditation Manager Checklist</h1>
        <p>This webpage/endpoint is for <span style={{ color: 'red', fontWeight: 500 }}>developer</span> use only!</p>
        <form
            style={{ display: 'flex', flexDirection: 'column', maxWidth: '500px' }}
            onSubmit={handleSubmit}
        >
                <FileInput
                    fileName={uploadedFile?.name}
                    handleFileUpload={
                        files => handleFileUpload(files)
                    }
                    uniqueID='file-input'
                />
                <InputLabel
                 style={{ marginTop: '1rem' }}
                >
                    Org Accrediation ID
                </InputLabel>
                <Input 
                    type='number' 
                    value={orgAccreditationId} 
                    onChange={
                        (e) => 
                            setOrgAccreditationId(Number.parseInt(e.target.value))
                    } 
                />
                <Button
                    style={{ marginTop: '1rem' }}
                    variant='contained'
                    color='primary'
                    type='submit'
                >
                    Submit
                </Button>
                {outputMessage && <p>{outputMessage}</p>}
        </form>
    </div>;
};