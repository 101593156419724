import {
    Column,
    Entity,
    JoinColumn,
    ManyToOne,
    OneToOne,
    PrimaryColumn,
} from 'typeorm';
import { FIELD,  } from './FIELD';
import { TEMPLATE,  } from './TEMPLATE';
import { SchemaEntityManager } from '../SchemaEntityManager';

// Define the factory function
function createFieldTemplateEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('FIELDTEMPLATE', { schema })
    class FIELDTEMPLATE {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'FIELDTEMPLATEID'
        })
        FIELDTEMPLATEID!: number;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'FIELDID'
        })
        FIELDID!: number | null;

        @OneToOne(
            () => manager.getFieldEntity(schema),
            FIELD => FIELD.FIELDTEMPLATE
        )
        @JoinColumn({
            name: 'FIELDID'
        })
        FIELD!: FIELD | null;

        @Column('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'TEMPLATEID'
        })
        TEMPLATEID!: number;

        @ManyToOne(
            () => manager.getTemplateEntity(schema),
            TEMPLATE => TEMPLATE.FIELDTEMPLATES
        )
        @JoinColumn({
            name: 'TEMPLATEID'
        })
        TEMPLATE!: TEMPLATE | null;

        @Column('varchar2', {
            nullable: true,
            length: 200,
            name: 'NAME'
        })
        NAME!: string | null;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'PARENTFIELDTEMPLATEID'
        })
        PARENTFIELDTEMPLATEID!: number | null;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 5,
            name: 'FTORDER'
        })
        FTORDER!: number | null;

        CHILDREN!: FIELDTEMPLATE[] | null;
        // Yes, CHILDREN can be either FIELDTEMPLATES or FIELDS, because the table does a self-join
        // using the PARENTFIELDTEMPLATEID value. The hierarchy looks like this, in the UI:
        // Page
        // -- Group
        //    -- Field
        //    -- Field
        // -- Group
        //    -- Field
        //    -- Field
        //    -- Field
        //
        // But as far as the database is concerned, it looks like this:
        // FIELDTEMPLATE
        // -- FIELDTEMPLATE
        //    -- FIELD
        //    -- FIELD
        // -- FIELDTEMPLATE
        //    -- FIELD
        //    -- FIELD
        //    -- FIELD
        // In other words, Pages and Groups are both FIELDTEMPLATES. A FIELDTEMPLATE with no parent is a Page;
        // a FIELDTEMPLATE with a parent (but no grandparent) is a Group, and it has FIELDS as children.
    }

    return FIELDTEMPLATE;
}

// Export the factory function
export { createFieldTemplateEntity };

// Export the type representing an instance of the class generated by the factory function
export type FIELDTEMPLATE = InstanceType<ReturnType<typeof createFieldTemplateEntity>>;
