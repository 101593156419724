import { useEffect } from 'react';

export const prefix = 'Lucidoc - ';

export const useDocumentTitle = (title: string) => {
    useEffect(() => {
        document.title = `${prefix}${title}`;
    }, [title]);

    return null;
};

// Can't depend on a vararg array in the useeffect deps array above.
// Has to be a string or other primitve, so we have to preprocess it.
export const formatTitle = (...title: string[]): string => {
    return title.filter(t => t !== undefined && t.trim() !== '').join(' - ');
};