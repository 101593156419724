import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import ContractsManager from './ContractsManagement/ContractsManager';
import { makeStyles } from '@material-ui/core';
import ContractsAdmin from './ContractsAdmin';
import UserBundleContext from '../../context/UserBundleContext';
import hasRights from '../../_shared/utils/hasRights';
import ContractRequestOverview from './ContractRequests/ContractRequestOverview';
import { RightsCodeKey } from '@/entities/master/PERSON';

const useStyles = makeStyles({
    contractsRouter: {
        height: '100%',
        overflowY: 'auto',
    }
});

export default function ContractsRouter() {
    const classes = useStyles();

    const context = useContext(UserBundleContext);

    const allowDocRequests = context.organization?.ALLOW_DOC_REQUESTS;

    return (
        <div className={classes.contractsRouter}>
            <Switch>
                {allowDocRequests && hasRights(context, RightsCodeKey.Reader) &&
                    <Route path={'/contracts/request'} component={ContractRequestOverview} />
                }

                {allowDocRequests && context.user?.DOC_REQUEST_HANDLER &&
                    <Route path={'/contracts/manage'} component={ContractsManager} />
                }

                {hasRights(context, RightsCodeKey.DocumentAdministrator) &&
                    <Route path={'/contracts/admin'} component={ContractsAdmin} />
                }
            </Switch>
        </div>
    );
}
