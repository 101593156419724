import Select from 'react-select';
import React from 'react';
import { FIELD } from '../../../../entities/org/FIELD';
import { documentMetadataValues } from '../dataTypes';
import { flattenedInformationHierarchySelectMenuOption } from '../../../../utils/produceFlattenedOptionsFromNestedInformationHierarchy';

interface SelectMenuFieldProps {
    field: FIELD
    options: flattenedInformationHierarchySelectMenuOption[] | null,
    updateDocumentMetadata: (arg0: documentMetadataValues) => void
}

export default function SelectMenuField(props: SelectMenuFieldProps) {
    const isMulti = props.field.REPEATABLE === '1';

    return (
        <Select
            options={(props.options || [] as any[])}
            onChange={(item: any) => {
                let valueToSend;
                // The data has to look totally different for the eventual XMD value depending on
                // whether the value is a single value or an array of values; thus...
                // for single values:
                if (!isMulti) {
                    valueToSend = {
                        fieldName: props.field!.NAME,
                        value: item.actualLabelWithoutWhitespace || item.label,
                        attrs: {
                            ID: item.value
                        }
                    };
                }
                // for multi-values:
                else {
                    const nestedOptions = item.map((singleItem: flattenedInformationHierarchySelectMenuOption) => {
                        return {
                            // the jsontoxml parser we will use on the back-end requires objects
                            // to be in this format:
                            name: props.field!.NAME,
                            text: singleItem.actualLabelWithoutWhitespace || singleItem.label,
                            attrs: {
                                ID: singleItem.value
                            }
                        };
                    });
                    valueToSend = {
                        // the underscore is how Lucidoc's XMD processor identifies an item as multi-value
                        fieldName: `_${props.field!.NAME}`,
                        children: nestedOptions,
                    };
                }
                props.updateDocumentMetadata(valueToSend);
            }}
            isMulti={isMulti}
        />
    );
}
