import {
    Column,
    Entity,
    JoinColumn,
    ManyToOne,
    PrimaryColumn,
} from 'typeorm';
import { CHECKLIST_CONDITION_AUDIT } from './CHECKLIST_CONDITION_AUDIT'; // Ensure this is correctly imported
import { SchemaEntityManager } from '../SchemaEntityManager';

export enum InspectionTypes {
    initial = 'initial',
    qa = 'qa',
}

// Factory function to create INSPECTION entity with dynamic schema
function createInspectionEntity(schemaName: string, manager: SchemaEntityManager) {
    @Entity('INSPECTION', { schema: 'ACCREDITATIONMANAGER' })
    class INSPECTION {
        static SCHEMANAME = schemaName;
        @PrimaryColumn('number', {
            nullable: false,
            name: 'INSPECTION_ID'
        })
        INSPECTION_ID!: number;

        @Column('number', {
            nullable: false,
            name: 'CHECKLIST_CONDITION_AUDIT_ID'
        })
        CHECKLIST_CONDITION_AUDIT_ID!: number;

        @ManyToOne(
            () => manager.getChecklistConditionAuditEntity(schemaName),
            (checklistConditionAudit) => checklistConditionAudit.INSPECTIONS
        )
        @JoinColumn({
            name: 'CHECKLIST_CONDITION_AUDIT_ID',
        })
        CHECKLIST_CONDITION_AUDIT!: CHECKLIST_CONDITION_AUDIT | null;

        @Column('varchar2', {
            nullable: false,
            length: 32,
            name: 'TYPE'
        })
        TYPE!: string;

        @Column('timestamp', {
            nullable: false,
            default: () => 'SYSDATE',
            name: 'CREATED_DATE'
        })
        CREATED_DATE!: Date;

        @Column('char', {
            nullable: true,
            default: () => 'NULL',
            name: 'DECISION'
        })
        DECISION!: string | null;

        @Column('number', {
            nullable: true,
            name: 'USERID'
        })
        USERID!: number | null;

        @Column('varchar2', {
            nullable: true,
            length: 195,
            name: 'USER_NAME_AND_TITLE'
        })
        USER_NAME_AND_TITLE!: string | null;

        @Column('timestamp', {
            nullable: true,
            name: 'COMPLETED_DATE'
        })
        COMPLETED_DATE!: Date | null;

        @Column('clob', {
            nullable: true,
            name: 'SUGGESTIONS'
        })
        SUGGESTIONS!: string | null;

        @Column('clob', {
            nullable: true,
            name: 'DEFICIENCIES'
        })
        DEFICIENCIES!: string | null;

        @Column('clob', {
            nullable: true,
            name: 'NOTES'
        })
        NOTES!: string | null;

        static Types = InspectionTypes;
    }

    return INSPECTION;
}

// Assuming INSPECTION is the type you want to export directly
export { createInspectionEntity };
export type INSPECTION = InstanceType<ReturnType<typeof createInspectionEntity>>;
