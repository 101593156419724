import React from 'react';

interface DepartmentShadowIconProps {
    size: number;
}

export const DepartmentShadowIcon: React.FC<DepartmentShadowIconProps> = ({ size }) => {

    return (
        <svg width={size.toString()} height={size.toString()} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 8V2.5H10V8H1Z" stroke="hsl(var(--muted-foreground))" stroke-width="2"/>
            <path d="M5.57153 13.5V8H15.0001L15 13.5H5.57153Z" stroke="hsl(var(--muted-foreground))" stroke-width="2"/>
        </svg>
    );
};