import Subheader from '../../../_shared/Subheader/Subheader';
import SubheaderItem from '../../../_shared/Subheader/SubheaderItem';
import { PersonStatuses, RightsCodeKey } from '../../../../entities/master/PERSON';
import React, { useContext, useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import { PersonAdd } from '@material-ui/icons';
import hasRights from '../../../../_shared/utils/hasRights';
import UserBundleContext from '../../../../context/UserBundleContext';
import { MassDisableUsers } from './MassDisableUsers';
import { ManageUsers } from './ManageUsers';
import { TemporaryFavoritesStar } from '../../../_shared/TemporaryFavoritesStar/TemporaryFavoritesStar';
import { setTitleInAdmin } from '../../../../utils/setTitleInAdmin';
import UpdateGuids from '../../../InternalSupport/UpdateGuids/UpdateGuids';
import { TemporaryHelpIcon } from '../../../_shared/TemporaryHelpIcon/TemporaryHelpIcon';
import { helpTopicDocIDs } from '../../../../_shared/constants/HelpSystemIDs';
import { EmailManagement } from '../../../InternalSupport/EmailManagement/EmailManagement';
import setWindowTitle from '../../../../utils/setWindowTitle';
import { HelpTopicContext } from '../../../../context/HelpTopicContext';

export function ManageUsersRouter() {
    const context = useContext(UserBundleContext);
    const { helpTopicID, setHelpTopicID } = useContext(HelpTopicContext);

    const MASS_DISABLE = 'Mass-Disable';
    const UPDATE_GUIDS = 'Update GUIDs';
    const EMAILS       = 'Emails';

    const Tabs: {[key: string]: string} = {
        Active         : 'Active',
        Disabled       : 'Disabled',
        All            : 'All',
        [MASS_DISABLE] : MASS_DISABLE,
        [UPDATE_GUIDS] : UPDATE_GUIDS,
        [EMAILS]       : EMAILS,
    };

    useEffect(() => {
        const newTitle = 'Manage Users';
        setWindowTitle(newTitle);

        // this only exists because this feature is loading within an iframe; once loaded
        // normally (AKA inside react) then get rid of this:
        setTitleInAdmin(newTitle);
    }, []);

    const [ selectedTab, setSelectedTab ] = useState<string>(Tabs.Active);

    useEffect(() => {
        switch (selectedTab) {
            case MASS_DISABLE:
                setHelpTopicID(helpTopicDocIDs.ADM_MASS_DISABLE);
                break;
            case UPDATE_GUIDS:
                setHelpTopicID(helpTopicDocIDs.ADM_UPDATE_GUIDS);
                break;
            case EMAILS:
                setHelpTopicID(helpTopicDocIDs.ADM_EMAIL_DOMAINS);
                break;
            default:
                setHelpTopicID(helpTopicDocIDs.ADM_USERS_NEW);
                break;
        }
    }, [selectedTab]);

    const AddNewPersonButton = (
        <span style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <a href={`/cgi/user_profile_new.pl?orgid=${context.organization?.ORGANIZATIONID}`} rel={'noopener noreferrer'} target={'_blank'} style={{ textDecoration: 'none', paddingRight: '.5rem' }}>
                <Button
                    startIcon={<PersonAdd />}
                >
                    Add New
                </Button>
            </a>
        </span>
    );

    const isUserAdmin = hasRights(context, RightsCodeKey.UserAdministrator);

    const canSeeMassDisableFeature = (isUserAdmin
        && (context.organization?.AUTH_TYPE === 'AD_TOKEN' || context.user?.ORGANIZATIONID === 10440)
    );

    const canSeeUpdateGuidsFeature = (isUserAdmin
        && (context.organization?.AUTH_TYPE === 'AD_TOKEN' || context.user?.ORGANIZATIONID === 10440)
    );

    const canSeeEmailManagement = isUserAdmin;

    let statuses: PersonStatuses[] = [];
    switch (selectedTab) {
        case Tabs.Active:
            statuses = [PersonStatuses.ACTIVE];
            break;
        case Tabs.Disabled:
            statuses = [PersonStatuses.INACTIVE];
            break;
        case Tabs.All:
            statuses = [PersonStatuses.ACTIVE, PersonStatuses.INACTIVE];
            break;
        default:
            statuses = [];
            break;
    }

    return (
        <>
            <Subheader
                value={selectedTab}
                onChange={value => setSelectedTab(value)}
                lastElement={
                    <>
                        {AddNewPersonButton}
                        <TemporaryFavoritesStar
                            targetPathName={'admin/admin.pl?repname=manage_users'}
                        />
                        {helpTopicID && <TemporaryHelpIcon helpTopicID={helpTopicID} />}
                    </>
                }
            >
                {Object.keys(Tabs)
                    .filter(tab => {
                        if (tab === MASS_DISABLE) return canSeeMassDisableFeature;
                        if (tab === UPDATE_GUIDS) return canSeeUpdateGuidsFeature;
                        if (tab === EMAILS)       return canSeeEmailManagement;
                        return true;
                    })
                    .map(tab => <SubheaderItem key={Tabs[tab]} label={Tabs[tab]} value={Tabs[tab]} buttonWidth={'6rem'} />)
                }
            </Subheader>

            {[Tabs.Active, Tabs.Disabled, Tabs.All].includes(selectedTab) &&
                <ManageUsers statuses={statuses} displayableStatus={selectedTab} />
            }

            {selectedTab === MASS_DISABLE &&
                <MassDisableUsers />
            }

            {selectedTab === UPDATE_GUIDS &&
                <UpdateGuids />
            }

            {selectedTab === EMAILS &&
                <EmailManagement />
            }
        </>
    );
}
