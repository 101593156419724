import React from 'react';

interface ChevronRightProps {
    size: number;
}

export const ChevronRightOutlineIcon: React.FC<ChevronRightProps> = ({ size }) => {
    return (
        <svg width={size.toString()} height={size.toString()} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="16" height="16"  />
            <path d="M6.55556 11L8.77778 7.5L6.55556 4H8.33333L10.5556 7.5L8.33333 11H6.55556Z" fill="hsl(var(--foreground))" />
        </svg>
    );
};