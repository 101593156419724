import {
    Column,
    Entity,
    JoinColumn,
    ManyToOne,
    PrimaryColumn,
} from 'typeorm';
import { type CHECKLIST_CONDITION_AUDIT } from './CHECKLIST_CONDITION_AUDIT';
import { SchemaEntityManager } from '../SchemaEntityManager';


// Factory function to create CORRECTIVE_ACTION_PLAN entity with dynamic schema
function createCorrectiveActionPlanEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('CORRECTIVE_ACTION_PLAN', { schema: 'ACCREDITATIONMANAGER' })
    class CORRECTIVE_ACTION_PLAN {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            name: 'CORRECTIVE_ACTION_PLAN_ID'
        })
        CORRECTIVE_ACTION_PLAN_ID!: number;

        @Column('number', {
            nullable: false,
            name: 'CHECKLIST_CONDITION_AUDIT_ID'
        })
        CHECKLIST_CONDITION_AUDIT_ID!: number;

        @ManyToOne(
            () => manager.getChecklistConditionAuditEntity(schema),
            checklistConditionAudit => checklistConditionAudit.CORRECTIVE_ACTION_PLANS
        )
        @JoinColumn({
            name: 'CHECKLIST_CONDITION_AUDIT_ID',
        })
        CHECKLIST_CONDITION_AUDIT!: CHECKLIST_CONDITION_AUDIT | null;

        @Column('timestamp', {
            nullable: false,
            default: () => 'SYSDATE',
            name: 'CREATED_DATE'
        })
        CREATED_DATE!: Date;

        @Column('date', {
            nullable: true,
            name: 'IMPLEMENTATION_DATE'
        })
        IMPLEMENTATION_DATE!: Date | null;

        @Column('number', {
            nullable: true,
            name: 'RESPONSIBLE_USERID'
        })
        RESPONSIBLE_USERID!: number | null;

        @Column('number', {
            nullable: true,
            name: 'USERID'
        })
        USERID!: number | null;

        @Column('varchar2', {
            nullable: true,
            length: 195,
            name: 'USER_NAME_AND_TITLE'
        })
        USER_NAME_AND_TITLE!: string | null;

        @Column('timestamp', {
            nullable: true,
            name: 'COMPLETED_DATE'
        })
        COMPLETED_DATE!: Date | null;

        @Column('timestamp', {
            nullable: true,
            name: 'IMPLEMENTED_DATE'
        })
        IMPLEMENTED_DATE!: Date | null;

        @Column('clob', {
            nullable: true,
            name: 'CORRECTIONS'
        })
        CORRECTIONS!: string | null;
    }

    return CORRECTIVE_ACTION_PLAN;
}

// Explicitly export the type and the factory function
export { createCorrectiveActionPlanEntity };
export type CORRECTIVE_ACTION_PLAN = InstanceType<ReturnType<typeof createCorrectiveActionPlanEntity>>;
