import React, { useContext, useEffect, useState } from 'react';
import DataTable from '../../../_shared/DataTable/DataTable';
import axios from 'axios';
import { MUIDataTableColumn, MUIDataTableProps } from 'mui-datatables';
import { DOCUMENTREVISION, isDocumentRevision, DocumentRevisionStatuses as DocStatuses, DisplayStatuses as DocRevDisplayStatuses, DocumentRevisionStatuses } from '../../../../entities/org/DOCUMENTREVISION';
import { ApprovalSessionTypes, ApprovalSessionStatuses as ApprovalSessionStatuses } from '../../../../entities/org/APPROVAL_SESSION';
import UserBundleContext from '../../../../context/UserBundleContext';
import { buildDocURL, buildManualURL, ucFirst } from '../../../../_shared/utils/docManagerFunctions';
import { INFORMATION_HIERARCHY } from '../../../../entities/org/INFORMATION_HIERARCHY';
import { ROUTING_CONFIGURATION } from '../../../../entities/org/ROUTING_CONFIGURATION';
import { LoadingIndicator } from '../../../_shared/LoadingIndicator';
import { LoadingStatuses } from '../../../../utils/LoadingStatuses';
import { TableCell, TableRow } from '@material-ui/core';
import { CommitteeApprovalSessionStatuses } from '../../../../entities/org/COMMITTEE_APPROVAL_SESSION';
import { SignatureSessionStatuses } from '../../../../entities/org/SIGNATURE_SESSION';
import hasRights from '../../../../_shared/utils/hasRights';
import { NotificationWindowTypes } from '../../../../entities/org/NOTIFICATION_WINDOW';
import { CommitteeDecisions } from '../../../../entities/org/COMMITTEE_DECISION_LOG';
import { ConsentAgendaTaskStatuses } from '../../../../entities/org/CONSENT_AGENDA_TASK';
import { SignatureLogDecisions } from '../../../../entities/org/SIGNATURE_LOG';
import { RightsCodeKey } from '@/entities/master/PERSON';
import { constructFullName } from '@/components/UserPicker/utils/constructFullName';

// we need these all in one to combine approval requests with signatures/consent agenda tasks, etc
// so when they're all displayed in the same place ordered by date they're all consistent (since the "status"
// is merely a combination of whether there is a request/task/decision/etc or not, and what has been done with
// it, etc, rather than a simple value that exists somewhere):
interface ApprovalDecision {
    name: string,
    status: string,
    date: Date | string, // string so we can say "Not applicable" or the approvalSessionStepLineBreak
    phase: number // not displayed; needed for calculations to determine which committee phase is active/complete (and only used for committees, not the signer phase)
}

enum CommitteeApprovalStatuses { // these are all the statuses NOT in COMMITTEE_DECISION_LOG.Decisions
    NOT_APPLICABLE = 'Not applicable',
    NOT_YET_RECEIVED = 'Not yet received',
    SCHEDULED = 'Scheduled',
    UNSCHEDULED = 'Unscheduled',
    EDITS_REQUESTED = 'Edits requested'
}

enum SignatureApprovalStatuses { // these are all the statuses NOT in SIGNATURE_LOG.Decisions
    NOT_APPLICABLE = 'Not applicable',
    NOT_YET_RECEIVED = 'Not yet received',
    REQUESTED = 'Requested',
    EDITS_REQUESTED = 'Edits requested'
}

// Mui-DataTables does not allow you to pass in objects; you can only pass in a string, number, or null.
// So if we want to pass in an array, we have to pass in a string with silly break points that we can use
// split() on, then map that resulting array into what we want, which is stacked text with <br /> between
const LINE_BREAK = '\n';

// just a way to differentiate between steps in the stacked block of text:
const approvalSessionStepLineBreak = '     -';
// so a blank line in those stacked blocks will won't be nonexistent, so the stacked block doesn't become shorter incorrectly
const singleSpace = ' ';

interface ItemsPendingApprovalProps {
    signerID?: number
    committeeID?: number
    departmentID?: number
    docTypeID?: number
    docStatusID?: DocStatuses
    approvalSessionType?: ApprovalSessionTypes
    approvalSessionStatus?: ApprovalSessionStatuses
    getItemsComingUpFor?: NotificationWindowTypes
    pendingAdditional?: 'any' | 'signatures' | 'committees'

    // these are specifically used to limit the results so that when looking at upcoming to-review docrevs
    // (pending review, archive, or expiration) it only gets those with active sessions, rather than showing the ones
    // either with NO signature session (or no committee session), or those pending review/archive/expiration but no
    // approval session has started at all:
    onlyGetItemsWithActiveSignatureSessions?: boolean
    onlyGetItemsWithActiveCommitteeSessions?: boolean
}

export default function ItemsPendingApproval(props: ItemsPendingApprovalProps) {
    const context = useContext(UserBundleContext);
    const userIsAdmin = hasRights(context, [RightsCodeKey.DocumentAdministrator, RightsCodeKey.UserAdministrator, RightsCodeKey.OrganizationAdministrator]);

    const [loadingStatus, setLoadingStatus] = useState(LoadingStatuses.MOUNTING);
    const [docRevTableData, setDocRevTableData] = useState<MUIDataTableProps['data']>([]);
    const [manualTableData, setManualTableData] = useState<MUIDataTableProps['data']>([]);

    const displayManuals: boolean = (
        !!props.docStatusID // we're piggybacking on this value because it's not a manual ID status but we'll use it as though it is
        && !props.departmentID
        && !props.docTypeID
        // because manuals don't exist in other states, so we won't bother querying for them:
        && [DocumentRevisionStatuses.pending_committee_approval,
        DocumentRevisionStatuses.pending_committee_routing].includes(props.docStatusID)
    );

    useEffect(() => {
        loadData().then();
    }, [
        props.signerID,
        props.committeeID,
        props.departmentID,
        props.docTypeID
    ]);

    async function loadData() {
        setLoadingStatus(LoadingStatuses.LOADING); // fyi LOADING vs. MOUNTING makes no real difference in this component
        // this clunky-looking sequence assures the loading status will become "ready"
        // only after all the queries are done. You can't do Promise.all() unless you make each
        // of those two functions return a Promise, which seems a little clunkier than doing
        // it this way.
        getPendingDocRevs().then(() => {
            getPendingManuals().then(() => {
                setLoadingStatus(LoadingStatuses.READY);
            });
        });
    }

    if (loadingStatus !== LoadingStatuses.READY) {
        return <LoadingIndicator />;
    }

    async function getPendingDocRevs() {
        const res = await axios.get('/api/doc-manager/compliance-reports/get-docrev-approval-routes-by-status', {
            params: {
                ...props,
                userID: userIsAdmin ? undefined : context.user?.USERID
            }
        });

        const today = new Date();
        const daysUntilReviewOrExpire = props.getItemsComingUpFor === NotificationWindowTypes.expiration
            ? 'Days to Expire'
            : 'Days to Review';

        const formattedDocRevsForTable = (res.data.pendingDocRevs as DOCUMENTREVISION[]).map(docrev => {
            const signatureDecisionsArray = produceSignatureDecisionsArray(docrev);
            const committeeDecisionsArray = produceCommitteeDecisionsArray(docrev);

            const arrayOfAllDecisions: ApprovalDecision[] = [...signatureDecisionsArray, ...committeeDecisionsArray];

            // this is just to insert a line break to distinguish signature phases from committee phases
            if (signatureDecisionsArray.length && committeeDecisionsArray.length) {
                arrayOfAllDecisions.splice(
                    signatureDecisionsArray.length,
                    0,
                    {
                        name: approvalSessionStepLineBreak,
                        status: approvalSessionStepLineBreak,
                        date: approvalSessionStepLineBreak,
                        phase: 0
                    }
                );
            }

            const approvalRouteTemplateEntryForSignatures = (
                docrev.APPROVAL_ROUTE_TEMPLATES
                && docrev.APPROVAL_ROUTE_TEMPLATES.find(art => art.SAS_TEMPLATE_ID === 0) // 0 means yes
            );

            if (approvalRouteTemplateEntryForSignatures && approvalRouteTemplateEntryForSignatures.PHASE === 2) {
                arrayOfAllDecisions.reverse();
            }

            // the Days To Review/Expire column should use the official docrev, if there is one, which we selected
            // in the back-end by joining this way and only getting the official one:
            const officialDocRev = docrev.DOCUMENT && docrev.DOCUMENT.REVISIONS && docrev.DOCUMENT.REVISIONS[0];
            const targetItemToGetDatesFrom = officialDocRev || docrev;

            const targetOverdueDate = props.getItemsComingUpFor === NotificationWindowTypes.expiration
                ? targetItemToGetDatesFrom.EXPIRATIONDT
                : targetItemToGetDatesFrom.STASHED_NEXTREVDT || targetItemToGetDatesFrom.NEXTREVDT;

            const daysUntilDue = targetOverdueDate
                ? Math.trunc(
                    (new Date(targetOverdueDate).getTime() - today.getTime())
                    / 1000 / 60 / 60 / 24
                )
                : '';

            // why don't we have just a simple return {object of some kind}? Because we only want the Global Identifier
            // column if the org has it enabled. We could hide this column with the Mui DataTables option, but if we build
            // this object so it only has the correct values in the first place, we can skip that; and we have to do it in
            // 3 steps so the object is built with its keys retaining the order, rather than doing the global identifier last
            let returnObj: { [key: string]: string | number | null } = {
                Title: docrev.TITLE || (docrev.DOCUMENT && docrev.DOCUMENT.TITLE),
                DocID: docrev.DOCUMENT.DOCID,
                RevNo: isNaN(parseInt(docrev.REVNO)) ? docrev.REVNO : parseInt(docrev.REVNO),
                DocType: docrev.DOCTYPE?.DESCRIPTION || '',
                DocOwner: docrev.DOCUMENT?.DOCOWNER ? constructFullName(docrev.DOCUMENT.DOCOWNER, 'lastFirstMiddle') : '',
                Department: docrev.DOCUMENT?.INFORMATION_HIERARCHY?.TITLE || '',
            };

            // Special detail for Pending Release
            if (props.docStatusID === DocStatuses.preprel) {
                const departmentOwner = docrev.DOCUMENT?.INFORMATION_HIERARCHY?.USER || null;

                returnObj['Department Owner'] = departmentOwner ? constructFullName(departmentOwner, 'lastFirstMiddle') : '';
            }

            if (res.data.organization
                && res.data.organization.GLOBAL_IDENTIFIER_NAME
                && res.data.organization.GLOBAL_IDENTIFIER_AVAILABILITY !== 'none'
            ) {
                returnObj = {
                    ...returnObj,
                    [res.data.organization.GLOBAL_IDENTIFIER_NAME]: docrev.GLOBAL_IDENTIFIER?.VALUE || null,
                };
            }

            returnObj.Approver = arrayOfAllDecisions.map(decision => decision.name).join(LINE_BREAK);

            if (props.docStatusID !== DocStatuses.preprel) {
                returnObj.Status = arrayOfAllDecisions.map(decision => decision.status).join(LINE_BREAK);
                returnObj['Date of Last Action'] = arrayOfAllDecisions
                    .map(decision => (
                        !decision.date
                            || decision.date === CommitteeApprovalStatuses.NOT_APPLICABLE
                            // the next two are just semi-awkward placeholders so the date field will appear with no date, but is
                            // still a string of some kind so it takes up a line in the table instead of collapsing and throwing off
                            // the corresponding stacks in the columns to its left and right
                            || decision.date === approvalSessionStepLineBreak
                            || decision.date === singleSpace
                            ? decision.date
                            : new Date(decision.date).toLocaleDateString()
                    ))
                    .join(LINE_BREAK);
            }

            returnObj[daysUntilReviewOrExpire] = daysUntilDue;

            if (props.approvalSessionType === ApprovalSessionTypes.review
                || props.getItemsComingUpFor === NotificationWindowTypes.expiration
            ) {
                const isDraft = docrev.DOCSTATUS_ID === DocStatuses.draft;
                const approvalSession = docrev.APPROVAL_SESSIONS?.[0];

                returnObj['Latest Activity'] = ''; // we need this key, even if blank, so the Object.keys() which populates the column headers will always have this column

                if (isDraft) {
                    returnObj['Latest Activity'] = 'Draft';
                }
                else if (approvalSession) {
                    const signatureSession = approvalSession?.APPROVAL_SESSION_STEPS?.find(step => !!step.SIGNATURE_SESSION_ID)?.SIGNATURE_SESSION;
                    const committeeApprovalSessionStep = approvalSession?.APPROVAL_SESSION_STEPS?.find(step => !!step.COMMITTEE_APPROVAL_SESSION_ID)?.COMMITTEE_APPROVAL_SESSION;

                    let pendingThisStep = '';
                    if (signatureSession?.STATUS === SignatureSessionStatuses.active) {
                        pendingThisStep = '\nPending Signature';
                    } else if (committeeApprovalSessionStep?.STATUS === CommitteeApprovalSessionStatuses.pending) {
                        pendingThisStep = '\nPending Committee Routing';
                    } else if (committeeApprovalSessionStep?.STATUS === CommitteeApprovalSessionStatuses.active) {
                        pendingThisStep = '\nPending Committee Approval';
                    }
                    const approvalSessionType = approvalSession.TYPE
                        ? `\n(to ${approvalSession.TYPE})`
                        : '';

                    returnObj['Latest Activity'] = pendingThisStep + approvalSessionType;
                }
            }

            return returnObj;
        })
            .sort((a, b) => {
                if (typeof a[daysUntilReviewOrExpire] === 'number' && typeof b[daysUntilReviewOrExpire] === 'number') {
                    return (a[daysUntilReviewOrExpire] as number) - (b[daysUntilReviewOrExpire] as number);
                }
                else if (!a[daysUntilReviewOrExpire]) return 1;
                else if (!b[daysUntilReviewOrExpire]) return -1;
                else return 0;
            });

        setDocRevTableData(formattedDocRevsForTable);
    }

    async function getPendingManuals() {
        if (!displayManuals) return;

        const res = await axios.get('/api/doc-manager/compliance-reports/get-manual-approval-routes-pending-committee-approval', {
            params: {
                committeeID: props.committeeID,
                approvalSessionStatus: props.approvalSessionStatus,
                status: props.docStatusID === DocumentRevisionStatuses.pending_committee_approval
                    ? CommitteeApprovalSessionStatuses.active  // because a status of active  means pending committee approval
                    : CommitteeApprovalSessionStatuses.pending, // because a status of pending means pending routing
                userID: userIsAdmin ? undefined : context.user?.USERID,
            }
        });

        const formattedManualsForTable = (res.data.manualsPendingCommitteeApproval as INFORMATION_HIERARCHY[]).map(manual => {
            const committeeDecisionsArray = produceCommitteeDecisionsArray(manual);

            return {
                Title: manual.TITLE,
                ManualID: manual.INFORMATION_HIERARCHY_ID,
                Approver: committeeDecisionsArray.map(decision => decision.name).join(LINE_BREAK),
                Status: committeeDecisionsArray.map(decision => decision.status).join(LINE_BREAK),
                'Date of Last Action': committeeDecisionsArray
                    .map(decision => (
                        !decision.date || decision.date === CommitteeApprovalStatuses.NOT_APPLICABLE
                            ? decision.date
                            : new Date(decision.date).toLocaleDateString()
                    ))
                    .join(LINE_BREAK),
            };
        });

        setManualTableData(formattedManualsForTable);
    }

    function produceCommitteeDecisionsArray(item: DOCUMENTREVISION | INFORMATION_HIERARCHY): ApprovalDecision[] {
        const committeeSchedulesHash: { [key: string]: ApprovalDecision } = {};
        const committeeDecisionsArray: ApprovalDecision[] = [];

        const approvalSession = item.APPROVAL_SESSIONS && item.APPROVAL_SESSIONS[0];
        // the next variable is relevant for Pending Review and Pending Expiration, since those have previous review
        // sessions (and occasional data corruption can happen with two officialize sessions) so if the approval
        // session is done, we'll show the route it will take, but not any decisions:
        const approvalSessionAlreadyComplete = !approvalSession || (approvalSession && approvalSession.START_DATE && approvalSession.COMPLETION_DATE);
        const routingConfigurations: ROUTING_CONFIGURATION[] | null = isDocumentRevision(item)
            ? item.DOCUMENT.ROUTING_CONFIGURATIONS
            : item.ROUTING_CONFIGURATIONS;

        if (!routingConfigurations || !routingConfigurations.length) return committeeDecisionsArray;

        const itemsPerPhase: {
            [key: string]: {
                total: number,
                complete: number
            }
        } = {};

        const approvalRouteTemplateEntryForCommittee = (
            item.APPROVAL_ROUTE_TEMPLATES
            && item.APPROVAL_ROUTE_TEMPLATES.find(art => art.CAS_TEMPLATE_ID === 0)
        );

        // special fast-fail so we don't bother displaying the routingConfigurations at all; if there is no
        // approval route template step, we shouldn't display them (because routingConfigurations can be stored,
        // but not used, so we have to check if they're used, which would be the approval route template:
        if (!approvalRouteTemplateEntryForCommittee && !props.pendingAdditional) return committeeDecisionsArray;

        const committeeApprovalSessionStep = (
            approvalSession
            && approvalSession.APPROVAL_SESSION_STEPS
            && approvalSession.APPROVAL_SESSION_STEPS.find(step => !!step.COMMITTEE_APPROVAL_SESSION_ID)
        );

        const cas = committeeApprovalSessionStep && committeeApprovalSessionStep.COMMITTEE_APPROVAL_SESSION;

        routingConfigurations.forEach(rc => {
            if (!rc.COMMITTEE_ID || typeof rc.PHASE !== 'number') return;

            if (!itemsPerPhase[rc.PHASE]) {
                itemsPerPhase[rc.PHASE] = {
                    total: 0,
                    complete: 0
                };
            }
            itemsPerPhase[rc.PHASE].total++;

            committeeSchedulesHash[rc.COMMITTEE_ID] = {
                name: rc.COMMITTEE?.NAME || '',
                status: approvalSessionAlreadyComplete && !props.pendingAdditional ? singleSpace : CommitteeApprovalStatuses.NOT_YET_RECEIVED,
                date: approvalSessionAlreadyComplete && !props.pendingAdditional ? singleSpace : CommitteeApprovalStatuses.NOT_APPLICABLE,
                phase: rc.PHASE
            };
        });

        if (cas
            && (!approvalSessionAlreadyComplete || props.pendingAdditional)
        ) {
            if (cas.CONSENT_AGENDA_TASKS) {
                cas.CONSENT_AGENDA_TASKS.forEach(task => {
                    if (!task.CONSENT_AGENDA_HEADING?.CONSENT_AGENDA?.COMMITTEE?.COMMITTEE_ID) return; // just a TypeScript null check:

                    const committeeID = task.CONSENT_AGENDA_HEADING.CONSENT_AGENDA.COMMITTEE.COMMITTEE_ID;
                    const committeeSchedule = committeeSchedulesHash[committeeID];

                    if (!committeeSchedule) return;

                    // this is mostly just a TypeScript check; there should always be a phase. We also can't do a
                    // regular if (phase) because the phase can be zero.
                    if (typeof committeeSchedule.phase !== 'number') return;

                    const editRequest = (
                        approvalSession
                        && approvalSession.EDIT_REQUESTS
                        && approvalSession.EDIT_REQUESTS.find(er => er.COMMITTEE_ID === committeeID)
                    );

                    if (task.COMMITTEE_DECISION_LOG) {
                        committeeSchedule.status = ucFirst(CommitteeDecisions[task.COMMITTEE_DECISION_LOG.DECISION].toLowerCase().replace(/_/g, ' '));
                        committeeSchedule.date = task.COMMITTEE_DECISION_LOG.DECISION_DATE;
                        itemsPerPhase[committeeSchedule.phase].complete++;
                    }
                    else if (editRequest) { // FYI an EDIT_REQUEST does not exist simultaneously with a COMMITTEE_DECISION_LOG
                        committeeSchedule.status = CommitteeApprovalStatuses.EDITS_REQUESTED;
                        committeeSchedule.date = editRequest.REQUESTED;
                    }
                    else if (task.STATUS !== ConsentAgendaTaskStatuses.REMOVED) {
                        committeeSchedule.status = CommitteeApprovalStatuses.SCHEDULED;
                        committeeSchedule.date = task.CREATION_TIMESTAMP;
                    }
                });
            }

            if (cas.COMMITTEE_APPROVAL_REQUESTS) { // just a TypeScript check; this should always be here
                let activePhase = 0;
                let maxPhase = 0;

                for (const phase in itemsPerPhase) {
                    if (parseInt(phase) > maxPhase) {
                        maxPhase = parseInt(phase);
                    }
                }
                for (let i = 0; i <= maxPhase; i++) {
                    if (itemsPerPhase[i].complete !== itemsPerPhase[i].total) {
                        activePhase = i;
                        break;
                    }
                }

                cas.COMMITTEE_APPROVAL_REQUESTS.forEach(car => {
                    const committeeSchedule = committeeSchedulesHash[car.COMMITTEE_ID];

                    if (!committeeSchedule) return;
                    if (committeeSchedule.phase !== activePhase) return;
                    if (committeeSchedule.status !== CommitteeApprovalStatuses.NOT_YET_RECEIVED) return;

                    committeeSchedule.status = CommitteeApprovalStatuses.UNSCHEDULED;
                });
            }
        }

        for (const committeeID in committeeSchedulesHash) {
            // due to some overlapping complexity, pendingAdditional should only really be showing the status of those
            // additional requests, so we'll rewrite the others to show they're not relevant:
            if (props.pendingAdditional && committeeSchedulesHash[committeeID].status === CommitteeApprovalStatuses.NOT_YET_RECEIVED) {
                committeeSchedulesHash[committeeID].status = CommitteeApprovalStatuses.NOT_APPLICABLE;
            }
            // then this part is unrelated, and simply builds the sequential list of all decisions:
            committeeDecisionsArray.push(committeeSchedulesHash[committeeID]);
        }

        committeeDecisionsArray.sort((a, b) => {
            if (a.phase === b.phase) {
                // notice how this is "backwards" on purpose; this ensures Scheduled items appear before Non-Scheduled items;
                // since the scheduling itself occurs at a later date, but it means the task will be dealt with before the next one
                return new Date(b.date).getTime() - new Date(a.date).getTime();
            }
            return a.phase - b.phase;
        });

        return committeeDecisionsArray;
    }

    function produceSignatureDecisionsArray(item: DOCUMENTREVISION | INFORMATION_HIERARCHY): ApprovalDecision[] {
        const signerDecisionsHash: {
            users: { [key: string]: ApprovalDecision },
            groups: { [key: string]: ApprovalDecision }
        } = {
            users: {},
            groups: {}
        };

        const signerDecisionsArray: ApprovalDecision[] = [];

        if (!item.SIGNERASSIGNMENTS || !item.SIGNERASSIGNMENTS.length) return signerDecisionsArray;

        const approvalSession = item.APPROVAL_SESSIONS && item.APPROVAL_SESSIONS[0];
        const approvalSessionAlreadyComplete = !approvalSession || (approvalSession && approvalSession.START_DATE && approvalSession.COMPLETION_DATE);
        const signatureSessionStep = (
            approvalSession
            && approvalSession.APPROVAL_SESSION_STEPS
            && approvalSession.APPROVAL_SESSION_STEPS.find(step => !!step.SIGNATURE_SESSION_ID)
        );

        const approvalRouteTemplateEntryForSignatures = (
            item.APPROVAL_ROUTE_TEMPLATES
            && item.APPROVAL_ROUTE_TEMPLATES.find(art => art.SAS_TEMPLATE_ID === 0)
        );

        // special fast-fail so we don't bother displaying the Signer Assignments at all; if there is no signature phase
        // we shouldn't display them (because the signer assignments can be stored, but not used, so we have to detect if
        // they're used, which would be if there is a signatureSessionStep:
        if (!approvalRouteTemplateEntryForSignatures && !props.pendingAdditional) return signerDecisionsArray;

        const signatureSession = signatureSessionStep && signatureSessionStep.SIGNATURE_SESSION;

        item.SIGNERASSIGNMENTS.forEach(sa => {
            const decision: ApprovalDecision = {
                name: sa.USERID && sa.SIGNER ? constructFullName(sa.SIGNER, 'lastFirstMiddle') : sa.USERGROUP?.NAME || '',
                status: approvalSessionAlreadyComplete && !props.pendingAdditional ? singleSpace : SignatureApprovalStatuses.NOT_YET_RECEIVED,
                date: approvalSessionAlreadyComplete && !props.pendingAdditional ? singleSpace : SignatureApprovalStatuses.NOT_APPLICABLE,
                phase: sa.SIGNORDER || 1 // unused for signers; only applies to committees
            };

            if (sa.USERID) {
                signerDecisionsHash.users[sa.USERID] = decision;
            }
            else if (sa.USERGROUPID) {
                signerDecisionsHash.groups[sa.USERGROUPID] = decision;
            }
        });

        if (signatureSession
            && (!approvalSessionAlreadyComplete || props.pendingAdditional)
        ) {
            let signatureRequests = signatureSession.SIGNATURE_REQUESTS;
            const signatureLogs = signatureSession.SIGNATURE_LOGS;
            const editRequests = approvalSession && approvalSession.EDIT_REQUESTS;

            // we're going to reorder the signature requests (which have no order) and sort them by their
            // corresponding SIGNER_ASSIGNMENTS, which do have an order (and is relevant for ordered signing).
            // this will make it easier to figure out whose turn it is:
            if (item.ORDEREDREVIEW) {
                signatureRequests = signatureSession.SIGNATURE_REQUESTS && signatureSession.SIGNATURE_REQUESTS.sort((a, b) => {
                    if (!item.SIGNERASSIGNMENTS) return 0;

                    const signerAssignmentA = item.SIGNERASSIGNMENTS.find(sa => sa.USERID === a.USERID || sa.USERGROUPID === a.VIA_USERGROUPID);
                    const signerAssignmentB = item.SIGNERASSIGNMENTS.find(sa => sa.USERID === b.USERID || sa.USERGROUPID === b.VIA_USERGROUPID);

                    if (!signerAssignmentA || !signerAssignmentB || !signerAssignmentA.SIGNORDER || !signerAssignmentB.SIGNORDER) {
                        return 0;
                    }

                    return signerAssignmentA.SIGNORDER - signerAssignmentB.SIGNORDER;
                });
            }

            let hasAnyoneRequestedEdits = false;

            signatureRequests && signatureRequests.forEach(sr => {
                if (!sr.USERID) return; // just a TypeScript check
                if (item.ORDEREDREVIEW && hasAnyoneRequestedEdits) return;

                // set initial data for the signature request (for a single person):
                if (!sr.VIA_USERGROUPID) {
                    signerDecisionsHash.users[sr.USERID].status = SignatureApprovalStatuses.REQUESTED;
                }

                // set initial data for the signature request (for a group):
                else if (sr.VIA_USERGROUPID
                    // we only need to do this step once per group:
                    && signerDecisionsHash.groups[sr.VIA_USERGROUPID].status !== SignatureApprovalStatuses.REQUESTED
                ) {
                    signerDecisionsHash.groups[sr.VIA_USERGROUPID].status = SignatureApprovalStatuses.REQUESTED;
                }

                const signatureLog = signatureLogs && signatureLogs.find(sl => sl.USERID === sr.USERID);
                const editRequest = editRequests && editRequests.find(er => er.SIGNER_USERID === sr.USERID);

                // overwrite if a decision has been made:
                if (signatureLog) {
                    // ...for a single person:
                    if (!sr.VIA_USERGROUPID) {
                        signerDecisionsHash.users[sr.USERID].status = ucFirst(signatureLog.DECISION);
                        signerDecisionsHash.users[sr.USERID].date = signatureLog.DECISION_DATE;
                    }

                    // ...for a group:
                    else if (sr.VIA_USERGROUPID
                        // if anyone within a group approved with edits (or requested edits), leave it that way:
                        && signerDecisionsHash.groups[sr.VIA_USERGROUPID].status !== SignatureLogDecisions.APPROVED_WITH_EDITS
                        && signerDecisionsHash.groups[sr.VIA_USERGROUPID].status !== SignatureApprovalStatuses.EDITS_REQUESTED
                    ) {
                        signerDecisionsHash.groups[sr.VIA_USERGROUPID].status = ucFirst(signatureLog.DECISION);
                        signerDecisionsHash.groups[sr.VIA_USERGROUPID].date = signatureLog.DECISION_DATE;
                    }
                }
                // ...or if an edit request has been made (an EDIT_REQUEST does not exist at the same time as the SIGNATURE_LOG
                else if (editRequest) {
                    hasAnyoneRequestedEdits = true;

                    // for a single person:
                    if (!sr.VIA_USERGROUPID) {
                        signerDecisionsHash.users[sr.USERID].status = SignatureApprovalStatuses.EDITS_REQUESTED;
                        signerDecisionsHash.users[sr.USERID].date = editRequest.REQUESTED;
                    }

                    // for a group:
                    else if (sr.VIA_USERGROUPID) {
                        signerDecisionsHash.groups[sr.VIA_USERGROUPID].status = SignatureApprovalStatuses.EDITS_REQUESTED;
                        signerDecisionsHash.groups[sr.VIA_USERGROUPID].date = editRequest.REQUESTED;
                    }
                }
            });
        }

        item.SIGNERASSIGNMENTS
            .sort((a, b) => {
                if (!a.SIGNORDER || !b.SIGNORDER) return 0;
                return a.SIGNORDER - b.SIGNORDER;
            })
            .forEach(sa => {
                if (sa.USERID) {
                    // overwrite irrelevant "decisions" due to pendingAdditional edge case:
                    if (props.pendingAdditional && signerDecisionsHash.users[sa.USERID].status === SignatureApprovalStatuses.NOT_YET_RECEIVED) {
                        signerDecisionsHash.users[sa.USERID].status = SignatureApprovalStatuses.NOT_APPLICABLE;
                    }
                    // then unrelated, built the sequential array of all decisions:
                    signerDecisionsArray.push(signerDecisionsHash.users[sa.USERID]);
                }
                else if (sa.USERGROUPID) {
                    // overwrite irrelevant "decisions" due to pendingAdditional edge case:
                    if (props.pendingAdditional && signerDecisionsHash.groups[sa.USERGROUPID].status === SignatureApprovalStatuses.NOT_YET_RECEIVED) {
                        signerDecisionsHash.groups[sa.USERGROUPID].status = SignatureApprovalStatuses.NOT_APPLICABLE;
                    }
                    // then unrelated, built the sequential array of all decisions:
                    signerDecisionsArray.push(signerDecisionsHash.groups[sa.USERGROUPID]);
                }
            });

        return signerDecisionsArray;
    }

    // This function is necessary because you can't pass objects into Mui-DataTables; you have to pass strings or numbers
    // into each cell, meaning the <a> tag has to be built as an HTML element, and the only way to put HTML elements
    // into the table is with a customRowRender, and we put it in this function so both the DocRev and Manual tables
    // can use it. They can use the same function yet produce different columns because they are being fed the variable
    // called "data", which has objects with key/value pairs specific either to docrevs or manuals that determine the
    // column names anyway.
    function produceCustomTableRow(
        data: (string | number | null)[],
        linkURL: string,
        firstIndexOfWhitespacePre: number // the "stacked" decisions (approval, date of last action, etc) have to line up properly, so we have to stop a line break due to whiteSpace: 'pre-wrap' which is the DataTable default
    ) {
        const key = `${data[1]}:${data[2]}` || undefined; // notice how sensitive this is to index position, so be careful
        //             ^docid  :  ^revno (must be a unique key so we need both, since pending expiration can have multiple of the same docid)

        return (
            <TableRow key={key}>
                {data.map((value: string | number | null, idx: number) => {
                    if (idx === 0) { // first column is docrev/manual title
                        return (
                            <TableCell key={idx} style={{ whiteSpace: 'pre-wrap' }}>
                                <a href={linkURL} target={'_blank'} rel="noopener noreferrer" style={{ textDecoration: 'none' }}>{value}</a>
                            </TableCell>
                        );
                    }
                    // idx === 5 ? because the Department/Latest Activity can have a huge name and we want it to wrap
                    // idx === 11 ? because the Latest Activity (appearing in Pending Review or Expiration) has the same issue
                    return (
                        <TableCell key={idx} style={idx >= firstIndexOfWhitespacePre ? { whiteSpace: 'pre' } : {}}>
                            {typeof value === 'string'
                                ? value.split(LINE_BREAK).map((splitValue, idx) => <div key={idx}>{splitValue}</div>)
                                : value
                            }
                        </TableCell>
                    );
                })}
            </TableRow>
        );
    }

    let PendingSomeCondition = '';

    if (props.approvalSessionStatus) {
        PendingSomeCondition = 'Pending Routing'; // this is the only case in which we use this value (for now)
    } else if (props.docStatusID) {
        PendingSomeCondition = DocRevDisplayStatuses[props.docStatusID];
    } else if (props.approvalSessionType) {
        PendingSomeCondition = `Pending ${ucFirst(props.approvalSessionType)}`;
    } else if (props.getItemsComingUpFor) {
        PendingSomeCondition = `Pending ${ucFirst(props.getItemsComingUpFor)}`;
    } else if (props.pendingAdditional) {
        PendingSomeCondition = 'Pending Additional Approvals';
    }

    let PendingSomeDocrevCondition = PendingSomeCondition;
    const PendingSomeManualCondition = PendingSomeCondition;

    if (props.docStatusID === DocumentRevisionStatuses.prepsig
        || props.docStatusID === DocumentRevisionStatuses.pending_committee_approval
    ) {
        PendingSomeDocrevCondition += ' (to officialize)';
    }

    const columnsExcludedFromFilterMenu = [
        'Title',
        'DocID',
        'RevNo',
        'Approver',
        'Status',
        'Date of Last Action'
    ];

    return (
        <div>
            <DataTable
                muiDataTableProps={{
                    title: `Documents ${PendingSomeDocrevCondition}`,
                    columns: docRevTableData[0] && Object.keys(docRevTableData[0]).map(key => {
                        const returnObj: MUIDataTableColumn = {
                            name: key,
                        };

                        if (columnsExcludedFromFilterMenu.includes(key)) {
                            returnObj.options = {
                                filter: false
                            };
                        }

                        return returnObj;
                    }),
                    data: docRevTableData,
                    options: {
                        selectableRows: 'none',
                        customRowRender: (data: any) => {
                            const docID = data[1];
                            const revno = data[2];

                            const docURL = context.organization && context.organization.PREFIX
                                ? buildDocURL(
                                    context.organization.PREFIX,
                                    docID,
                                    parseInt(revno)
                                ) : '';

                            return produceCustomTableRow(data, docURL, 7);
                        }
                    }
                }}
                onRefresh={() => loadData()}
            />

            {displayManuals &&
                <>
                    <div style={{ height: '1rem' }}> </div>

                    <DataTable
                        muiDataTableProps={{
                            title: `Manuals ${PendingSomeManualCondition}`,
                            columns: manualTableData[0] && Object.keys(manualTableData[0]).map(key => {
                                const returnObj: MUIDataTableColumn = {
                                    name: key,
                                };

                                if (columnsExcludedFromFilterMenu.includes(key)) {
                                    returnObj.options = {
                                        filter: false
                                    };
                                }

                                return returnObj;
                            }),
                            data: manualTableData,
                            options: {
                                selectableRows: 'none',
                                customRowRender: (data: any) => {
                                    const manualID = data[1];
                                    const manualURL = buildManualURL(manualID);

                                    return produceCustomTableRow(data, manualURL, 2);
                                }
                            },
                        }}
                        onRefresh={() => loadData()}
                    />
                </>
            }

        </div>
    );
}
