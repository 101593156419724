import {
    Column,
    Entity,
    JoinColumn,
    ManyToOne,
    OneToOne,
    PrimaryColumn,
} from 'typeorm';
import { COMMITTEE_APPROVAL_SESSION,  } from './COMMITTEE_APPROVAL_SESSION';
import { CONSENT_AGENDA_TASK,  } from './CONSENT_AGENDA_TASK';
import { SchemaEntityManager } from '../SchemaEntityManager';

export enum CommitteeDecisions {
    REJECTED = 0,
    APPROVED = 1,
    APPROVED_WITH_EDITS = 2,
    FAILED_TO_RATIFY = 3
}

// Factory function to create COMMITTEE_DECISION_LOG entity with dynamic schema
function createCommitteeDecisionLogEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('COMMITTEE_DECISION_LOG', { schema })
    class COMMITTEE_DECISION_LOG {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', { nullable: false, name: 'COMMITTEE_ID' })
        COMMITTEE_ID!: number;

        @PrimaryColumn('number', { nullable: false, name: 'DECISION' })
        DECISION!: CommitteeDecisions;

        @PrimaryColumn('timestamp', { nullable: false, name: 'TIMESTAMP' })
        TIMESTAMP!: Date;

        @Column('number', { nullable: true, name: 'SUBREVNO' })
        SUBREVNO!: number | null;

        @Column('varchar2', { nullable: true, length: 4000, name: 'JUSTIFICATION' })
        JUSTIFICATION!: string | null;

        @PrimaryColumn('number', { nullable: false, name: 'COMMITTEE_APPROVAL_SESSION_ID' })
        COMMITTEE_APPROVAL_SESSION_ID!: number;

        @ManyToOne(
            () => manager.getCommitteeApprovalSessionEntity(schema),
            committeeApprovalSession => committeeApprovalSession.COMMITTEE_DECISION_LOGS
        )
        @JoinColumn({ name: 'COMMITTEE_APPROVAL_SESSION_ID' })
        COMMITTEE_APPROVAL_SESSION!: COMMITTEE_APPROVAL_SESSION | null;

        @Column('varchar2', { nullable: true, length: 100, name: 'COMMITTEE_NAME' })
        COMMITTEE_NAME!: string | null;

        @PrimaryColumn('timestamp', { nullable: false, default: () => 'SYSDATE', name: 'DECISION_DATE' })
        DECISION_DATE!: Date;

        @Column('number', { nullable: true, name: 'TASK_ID' })
        TASK_ID!: number | null;

        @OneToOne(
            () => manager.getConsentAgendaTaskEntity(schema),
            consentAgendaTask => consentAgendaTask.COMMITTEE_DECISION_LOG
        )
        @JoinColumn({ name: 'TASK_ID' })
        CONSENT_AGENDA_TASK!: CONSENT_AGENDA_TASK | null;

        static Decisions = CommitteeDecisions;
    }

    return COMMITTEE_DECISION_LOG;
}

// Export the factory function
export { createCommitteeDecisionLogEntity };

// Define and export the type for instances created by the factory function
export type COMMITTEE_DECISION_LOG = InstanceType<ReturnType<typeof createCommitteeDecisionLogEntity>>;
