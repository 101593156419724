import {
    Entity,
    PrimaryColumn,
} from 'typeorm';
import { SchemaEntityManager } from '../SchemaEntityManager';

// Factory function to create CONSENT_AGENDA_GUEST entity with dynamic schema
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function createConsentAgendaGuestEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('CONSENT_AGENDA_GUEST', { schema })
    class CONSENT_AGENDA_GUEST {
        static SCHEMANAME = schema;
        @PrimaryColumn(
            'number',
            {
                nullable: false,
                name: 'CONSENT_AGENDA_ID'
            }
        )
        CONSENT_AGENDA_ID!: number;

        @PrimaryColumn(
            'number',
            {
                nullable: false,
                name: 'USERID'
            }
        )
        USERID!: number;

        // Define additional fields and relationships if necessary
    }

    return CONSENT_AGENDA_GUEST;
}

// Export the factory function
export { createConsentAgendaGuestEntity };

// Define and export the type for instances created by the factory function
export type CONSENT_AGENDA_GUEST = InstanceType<ReturnType<typeof createConsentAgendaGuestEntity>>;
