import { Column } from '@tanstack/react-table';
import { Input, InputProps } from '../input';

interface DatatableColumnFilterProps<TData, TValue> extends InputProps {
    column?: Column<TData, TValue>
}

export function DatatableColumnFilter<TData, TValue> ({ column, ...props }: DatatableColumnFilterProps<TData, TValue>) {
    return (
        <Input
            value={(column?.getFilterValue() as string) ?? ''}
            onChange={(event) =>
                column?.setFilterValue(event.target.value)
            }
            {...props}
            className="w-full sm:w-[150px] lg:w-[250px]"
        />
    );
}