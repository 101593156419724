import React, { useEffect, useRef, useState } from 'react';

import { Button } from '@/ui/ui/button';
import { Input } from '@/ui/ui/input';
import { Popover, PopoverContent, PopoverTrigger } from '@/ui/ui/popover';
import { Separator } from '@/ui/ui/separator';
import { ChevronDown, SearchIcon } from 'lucide-react';
import { cn } from '@/lib/utils';


interface AdvancedSelectProps {
    text?: string;
    placeholder: string;
    clearSelection: () => void;
    filterText: string;
    setFilterText: (text: string) => void;
    children?: React.ReactNode;
    isSingleSelect?: boolean;
    disabled?: boolean;
}

export const AdvancedSelect: React.FC<AdvancedSelectProps> = ({
    text,
    placeholder,
    clearSelection,
    filterText,
    setFilterText,
    children,
    isSingleSelect,
    disabled,
}) => {

    const ref = useRef<HTMLDivElement>(null);
    const triggerRef = useRef<HTMLButtonElement>(null);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                ref.current && 
                !ref.current.contains(event.target as Node) &&
                triggerRef.current &&
                !triggerRef.current.contains(event.target as Node)
            ) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);

    return <Popover 
        open={isOpen}
    >
        <PopoverTrigger
            onClick={() => {
                if (!disabled) {
                    setIsOpen(!isOpen);
                }
            }}
            ref={triggerRef}
        >
            <div className={
                cn(
                    'w-full border-2 border-border p-1.5 rounded-md flex items-center justify-between bg-background',
                    !disabled && 'hover:bg-accent hover:text-accent-foreground',
                    disabled && 'bg-muted cursor-not-allowed',
                )
            }>
                {text ? <p>{text}</p> : <p className='text-muted-foreground'>{placeholder}</p>}
                <ChevronDown size={20} />
            </div>
        </PopoverTrigger>
        <PopoverContent 
            ref={ref}
            align='start'
            className='w-full min-w-[300px] p-0'
        >
            <div className='text-sm w-full p-2 pb-2 flex flex-row items-center border-b-2'>
                <SearchIcon className='text-secondary mr-2' size={18} />
                <Input
                    className='border-none w-full outline-none text-secondary'
                    placeholder={placeholder ?? 'Search departments...'}
                    value={filterText}
                    onChange={(e) => setFilterText(e.target.value)}
                />
            </div>
            <div
                className='w-full flex flex-col overflow-y-auto max-h-[275px] px-1'
                
            >
                {
                    (children as React.ReactNode[]).map((child) => {
                        return <div 
                            onClick={() => isSingleSelect ? setIsOpen(false) : null}
                        >
                            {child}
                        </div>;
                    })
                    
                }
            </div>
            <Separator orientation='horizontal' className='mt-2' />
            <Button className='w-full' onClick={() => clearSelection()} variant={'ghost'}>
                Clear Selection
            </Button>
        </PopoverContent>
    </Popover>;
};