import React, { useState } from 'react';

import { helpTopicDocIDs } from '@/_shared/constants/HelpSystemIDs';
import { useBrowserQuery } from '@/hooks/useBrowserQuery';
import { Button } from '@/ui/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogHelpTopicIcon, DialogTitle, DialogTrigger } from '@/ui/ui/dialog';
import { Input } from '@/ui/ui/input';
import { useField, useForm } from '@tanstack/react-form';
import axios from 'axios';
import { CommitteesQuery } from '../../Committees';
import SelectConsentAgenda from '@/ui/ui/advanced-select/select-consent-agenda';

interface CreateAgendaDialogProps {
    onCreate: () => void;
}

export const CreateAgendaDialog: React.FC<CreateAgendaDialogProps> = (props: CreateAgendaDialogProps) => {

    const [query, setQuery] = useBrowserQuery<CommitteesQuery>();
    const [invalidTimes, setInvalidTimes] = useState(false);

    const form = useForm<{
        date: string
        startTime: string,
        endTime: string,
        location: string,
        consentAgendaId: number | null
    }>({
        defaultValues: {
            date: '',
            startTime: '',
            endTime: '',
            location: '',
            consentAgendaId: null
        },
        onSubmit: async ({ value: {
            date,
            startTime,
            endTime,
            location,
            consentAgendaId
        } }) => {
            const dmy = date.split('-');
                        
            const startDate = new Date();
            startDate.setDate(parseInt(dmy[2]));
            startDate.setMonth(parseInt(dmy[1]) - 1);
            startDate.setFullYear(parseInt(dmy[0]));
            startDate.setHours(parseInt(startTime.split(':')[0]));
            startDate.setMinutes(parseInt(startTime.split(':')[1]));
            const startDateStr = startDate.toLocaleDateString() + ' ' + startDate.toLocaleTimeString();

            const endDate = new Date();
            endDate.setDate(parseInt(dmy[2]));
            endDate.setMonth(parseInt(dmy[1]) - 1);
            endDate.setFullYear(parseInt(dmy[0]));
            endDate.setHours(parseInt(endTime.split(':')[0]));
            endDate.setMinutes(parseInt(endTime.split(':')[1]));
            const endDateStr = endDate.toLocaleDateString() + ' ' + endDate.toLocaleTimeString();

            if (startDate.getTime() > endDate.getTime()) {
                setInvalidTimes(true);
                return;
            }

            await axios.post(
                `/api/committee/${query.editingCommitteeId}/consent-agenda`,
                {
                    startDateStr,
                    endDateStr,
                    location,
                    prototypeConsentAgendaId: consentAgendaId ?? undefined
                }
            );
            setQuery({ ...query, createAgendaDialogOpen: undefined });
            props.onCreate();
        }
    });

    
    const startTime = useField({ form, name: 'startTime' });
    const endTime = useField({ form, name: 'endTime' });
    const location = useField({ form, name: 'location' });
    
    return (
        <Dialog
            open={!!query.createAgendaDialogOpen}
            onOpenChange={(open) => {
                setQuery({ ...query, createAgendaDialogOpen: open || undefined });
            }}
        >
            <DialogTrigger asChild>
            </DialogTrigger>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Create Meeting<DialogHelpTopicIcon helpTopicId={helpTopicDocIDs['ADMIN_COMMITTEES']} />
                    </DialogTitle>
                </DialogHeader>
                <form className='w-full flex flex-col h-full items-start space-y-4'
                    onSubmit={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        form.handleSubmit();
                    }}
                >
                    <Section>
                        <p>Date:</p>
                        <form.Field
                            name={'date'}
                            children={(field) => {
                                return <Input
                                    placeholder='Enter a date...'
                                    type='date'
                                    className='w-full'
                                    value={field.getValue()}
                                    onChange={(e) => field.handleChange(e.target.value)}
                                />;
                            }}
                        />
                    </Section>
                    <Section>
                        <p>Start/End Times:</p>
                        <div className='flex'>
                            <form.Field
                                name={'startTime'}
                                children={(field) => {
                                    return <Input
                                        placeholder='Enter a start time...'
                                        className='w-full'
                                        type='time'
                                        value={field.getValue()}
                                        onChange={(e) => field.handleChange(e.target.value)}
                                    />;
                                }}
                            />
                            <div className='w-8'/>
                            <form.Field
                                name={'endTime'}
                                children={(field) => {
                                    return <Input
                                        placeholder='Enter a end time...'
                                        className='w-full'
                                        type='time'
                                        value={field.getValue()}
                                        onChange={(e) => field.handleChange(e.target.value)}
                                    />;
                                }}
                            />
                        </div>
                        {
                            invalidTimes ? <p className='text-red-500'>End time must be after start time</p> : null
                        }
                    </Section>
                    <Section>
                        <p>Location:</p>
                        <form.Field
                            name={'location'}
                            children={(field) => {
                                return <Input
                                    placeholder='Enter a location...'
                                    className='w-full'
                                    value={field.getValue()}
                                    onChange={(e) => field.handleChange(e.target.value)}
                                />;
                            }}
                        />
                    </Section>
                    <Section>
                        <p>Copy from Agenda:</p>
                        <form.Field
                            name={'consentAgendaId'}
                            children={(field) => {
                                return query.editingCommitteeId ? <SelectConsentAgenda
                                    placeholder='Copy from agenda...'
                                    committeeId={query.editingCommitteeId}
                                    consentAgendaId={field.getValue() ?? undefined}
                                    onChange={
                                        (value) => {
                                            field.handleChange(value?.CONSENT_AGENDA_ID ?? null);
                                            if (value) {
                                                const startTimePST = (new Date(value.MEETING_START_DATE)).toLocaleString('en-US', {
                                                    timeZone: 'America/Los_Angeles'
                                                });
                                                const endTimeFullPST = (new Date(value.MEETING_END_DATE!)).toLocaleString('en-US', {
                                                    timeZone: 'America/Los_Angeles'
                                                });
                                    
                                                // Use toTimeString from dates constructed with PST timeZones
                                                const startTimeFull = (new Date(startTimePST)).toTimeString();
                                                const endTimeFull = (new Date(endTimeFullPST)).toTimeString();
                                    
                                                // Remove the seconds to just use HH:MM as input element requires
                                                const startTimeWithoutSeconds = `${startTimeFull.split(':')[0]}:${startTimeFull.split(':')[1]}`;
                                                const endTimeWithoutSeconds = `${endTimeFull.split(':')[0]}:${endTimeFull.split(':')[1]}`;
                                    
                                                startTime.handleChange(startTimeWithoutSeconds);
                                                endTime.handleChange(endTimeWithoutSeconds);
                                                location.handleChange(value?.MEETING_LOCATION || '');
                                            }
                                        }
                                    }
                                /> : null;
                            }}
                        />
                    </Section>
                    <div className='pt-4 flex flex-row w-full'>
                        <Button
                            className='w-full mx-1'
                            type={'submit'}
                        >
                            Create
                        </Button>
                        <Button 
                            className='w-full mx-1'
                            variant={'secondary'}
                            type='button'
                            onClick={
                                () => setQuery({ ...query, createAgendaDialogOpen: undefined })
                            }
                        >
                            Close
                        </Button>
                    </div>
                </form>
            </DialogContent>
        </Dialog >
    );
};


const Section = ({ children }) => {
    return <div className='flex flex-col w-full'>
        {children}
    </div>;
};