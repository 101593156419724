import React, { useState, useEffect } from 'react';
import Subheader from '../_shared/Subheader/Subheader';
import SubheaderItem from '../_shared/Subheader/SubheaderItem';
import Pending from './components/Pending';
import { makeStyles } from '@material-ui/core';
import RoutingHistory from './components/RoutingHistory';
import { TemporaryFavoritesStar } from '../_shared/TemporaryFavoritesStar/TemporaryFavoritesStar';
import setWindowTitle from '../../utils/setWindowTitle';

const useStyles = makeStyles({
    approvalRouting: {
        width: '100%',
        height: '100%',
        overflowY: 'auto'
    },
    loadingIndicator: {
        color: 'darkgrey',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
});

export default function ApprovalRouting() {
    const classes = useStyles({});

    enum Tabs {
        Pending = 'Pending',
        Active = 'Active',
        History = 'History',
    }

    const [ selectedTab, setSelectedTab ] = useState<string>(Tabs.Pending);

    useEffect(() => {
        setWindowTitle('Approval Routing');
    }, []);

    return (
        <div className={classes.approvalRouting}>
            <Subheader
                value={selectedTab}
                onChange={value => setSelectedTab(value)}
                lastElement={
                    <TemporaryFavoritesStar targetPathName={'docmgr2/docmanager.pl?repname=toRoute'} />
                }
                shrinkLastElement={true}
            >
                <SubheaderItem label={Tabs.Pending} value={Tabs.Pending} />
                <SubheaderItem label={Tabs.Active} value={Tabs.Active} />
                <SubheaderItem label={Tabs.History} value={Tabs.History} />
            </Subheader>

            {selectedTab === Tabs.Pending &&
                <Pending
                    selectedList={'pending'}
                />
            }

            {selectedTab === Tabs.Active &&
                <Pending
                    selectedList={'active'}
                />
            }

            {selectedTab === Tabs.History &&
                <RoutingHistory />
            }

        </div>
    );
}
