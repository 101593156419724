import DataTable from '../../../_shared/DataTable/DataTable';
import { MUIDataTableColumn, MUIDataTableProps } from 'mui-datatables';
import { TableCell, TableRow } from '@material-ui/core';
import React from 'react';

interface ProduceBacklogTableProps {
    title: string // the word "Backlog" is included already
    tableData: MUIDataTableProps['data']
    titleOfHiddenIDColumn: string // you will have a hidden column called UserID or DepartmentID etc, so we can use the ID, but it won't show
    onSelectItem?: (itemID: number) => void // uses the ID in the hidden column (titleOfHiddenIDColumn) to figure out what to do when clicking on an item
    onRefresh: () => void
}

export default function ProduceBacklogTable(props: ProduceBacklogTableProps) {

    return (
        <div>
            <DataTable
                muiDataTableProps={{
                    title: `${props.title} Backlog`,
                    data: props.tableData,
                    columns: props.tableData[0] && Object.keys(props.tableData[0]).map(key => {
                        const returnObj: MUIDataTableColumn = { name: key };

                        if (key === props.titleOfHiddenIDColumn) {
                            returnObj.options = {
                                display: 'false',
                                filter: false
                            };
                        }

                        return returnObj;
                    }),
                    options: {
                        customRowRender: (data: (string | number | null)[]) => {
                            const itemID = data[0] || undefined;

                            return (
                                <TableRow key={itemID}>
                                    {data.slice(1).map((value, idx) => {
                                        // .slice(1) to remove the itemID column from displaying
                                        if (idx === 0) {
                                            return (
                                                <TableCell key={idx}>
                                                    <a
                                                        onClick={() => typeof itemID === 'number' && props.onSelectItem?.(itemID)}
                                                        style={props.onSelectItem ? { color: 'blue', cursor: 'pointer' } : {}}
                                                    >
                                                        {value}
                                                    </a>
                                                </TableCell>
                                            );
                                        }
                                        return (
                                            <TableCell key={idx}>
                                                {value}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        }
                    }
                }}
                onRefresh={() => props.onRefresh()}
            />
        </div>
    );
}
