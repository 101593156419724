export const menuBarColors = {
    regular: '#243442',
    light: '#364d63',
    xLight: '#476785'
};

export const mobileViewBreakpointSmall = 600; // also used by HomePageSharedStyles.tsx
export const mobileViewBreakpoint = 800;  // also used by HomePageSharedStyles.tsx

export const headerSharedStyles = {
    displayFlexAlignCenter: {
        display: 'flex',
        alignItems: 'center',
    },
    justifyContentSpaceBetween: {
        justifyContent: 'space-between',
    },
    lightColor: {
        color: 'white',
    },
    lightColorHover: {
        '&:hover': {
            backgroundColor: menuBarColors.light
        }
    },
    headerIconDiv: {
        position: 'relative' as const,
        '&:hover': {
            '& > div': {
                display: 'block',
            }
        }
    },
    headerDropdownMenu: {
        color: 'white',
        backgroundColor: menuBarColors.regular,
        display: 'none',
        position: 'absolute' as const,
        right: 0,
        zIndex: 10000,
        '&:hover': {
            display: 'block'
        },
        [`@media (max-width:${mobileViewBreakpoint}px)`]: {
            right: 48,
            top: 0
        }
    },
    headerMobileMiniMenu: { // exists purely to override headerDropdownMenu media query values since we want all the CSS except for that
        top: 48,
        right: 0
    },
    headerMenuItemLink: {
        cursor: 'pointer', // some are <a> tags with onClick instead of href so we need this
        display: 'block',
        padding: '.8rem',
        fontSize: '.8rem',
        whiteSpace: 'nowrap' as const,
        '&:hover': {
            backgroundColor: menuBarColors.xLight
        },
        textDecoration: 'none',
        '& > a': { // handles a React Router <a> tag which gets nested deeper down
            textDecoration: 'none',
        }
    },
    headerFavoritesRow: {
        width: '100%',
    }
};
