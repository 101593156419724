import { constructFullName } from '@/components/UserPicker/utils/constructFullName';
import { PERSON, isPerson } from '../../../entities/master/PERSON';
import { USERGROUP, isUserGroup } from '../../../entities/org/USERGROUP';


function generateIntersectionError(limitedViewer: PERSON | USERGROUP, fullViewer: PERSON | USERGROUP): string {
    const limitedViewerName = isPerson(limitedViewer) ? constructFullName(limitedViewer, 'firstLast') : limitedViewer.NAME;
    const fullViewerName = isPerson(fullViewer) ? constructFullName(fullViewer, 'firstLast') : fullViewer.NAME;

    if (isPerson(limitedViewer) && isPerson(fullViewer)) {
        return `${limitedViewerName} cannot be both a limited and full viewer.`;
    }

    if (isPerson(limitedViewer) && isUserGroup(fullViewer)) {
        return `${limitedViewerName} cannot be both a limited viewer and a full viewer (via '${fullViewerName}').`;
    }

    if (isUserGroup(limitedViewer) && isPerson(fullViewer)) {
        return `${fullViewerName} cannot be both a limited viewer (via '${limitedViewerName}') and a full viewer.`;
    }

    if (isUserGroup(limitedViewer) && isUserGroup(fullViewer)) {
        if (limitedViewer.USERGROUPID === fullViewer.USERGROUPID) {
            return `'${limitedViewerName}' cannot be both a limited viewer and a full viewer.`;
        }

        const limitedViewerMembers = new Set(limitedViewer.PEOPLE || []);
        const fullViewerMembers = new Set(fullViewer.PEOPLE || []);
        const intersectedMembers = new Set<PERSON>();

        // set intersection is not fully browser compatible, hence the manual check
        limitedViewerMembers.forEach(member => {
            if (fullViewerMembers.has(member)) {
                intersectedMembers.add(member);
            }
        });

        fullViewerMembers.forEach(member => {
            if (limitedViewerMembers.has(member)) {
                intersectedMembers.add(member);
            }
        });

        const intersectedMembersArray = Array.from(intersectedMembers).map(member => constructFullName(member, 'firstLast')).join(', ');
        return `The following members cannot be both limited and full viewers (via '${limitedViewerName}' and '${fullViewerName}'): ${intersectedMembersArray}.`;
    }

    return '';
}

export { generateIntersectionError };