import { UserSearchPerson } from '@/hooks/useUserSearch';
import { PERSON } from  '../../../entities/master/PERSON';

type FullNameFormat = 
    'firstMiddleLast' | // Bob J Smith
    'lastFirstMiddle' | // Smith, Bob J
    'firstLastShortened' |  // Bob S
    'firstLast';        // Bob Smith

export function constructFullName(person: PERSON | UserSearchPerson, format: FullNameFormat): string {
    // If you don't have a first name, the user should know about it.
    const firstName = person.FIRSTNAME ?? 'null';

    if (format === 'lastFirstMiddle') {
        const lastName = person.LASTNAME ? `${person.LASTNAME},` : '';
        const middleInitials = person.MIDDLEINITIALS ?? '';

        const parts = [lastName, firstName, middleInitials].filter(part => part.length > 0);
        return parts.join(' ').trim();
    }

    if (format === 'firstMiddleLast') {
        const middleInitials = person.MIDDLEINITIALS ?? '';
        const lastName = person.LASTNAME ?? '';

        const parts = [firstName, middleInitials, lastName].filter(part => part.length > 0);
        return parts.join(' ').trim();
    }

    if (format === 'firstLast') {
        const lastName = person.LASTNAME ?? '';

        const parts = [firstName, lastName].filter(part => part.length > 0);
        return parts.join(' ').trim();
    }

    if (format === 'firstLastShortened') {
        const lastName = person.LASTNAME ?? '';

        const parts = [firstName, lastName[0]].filter(part => part.length > 0);
        return parts.join(' ').trim();
    }

    // This should never happen
    return '';
}