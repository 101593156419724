import { INFORMATION_HIERARCHY } from '../../../../entities/org/INFORMATION_HIERARCHY';
import { MUIDataTableProps } from 'mui-datatables';
import { transformACLArrayIntoStringArray } from './transformACLs';
import { ucFirst } from '../../../../_shared/utils/docManagerFunctions';
import { COMMITTEE_DECISION_LOG } from '../../../../entities/org/COMMITTEE_DECISION_LOG';
import { constructFullName } from '@/components/UserPicker/utils/constructFullName';

export function transformManualsToReadableDataTableValues(
    departments: INFORMATION_HIERARCHY[],
    manualIDtoManualMap: [number: INFORMATION_HIERARCHY],
    approvalsFromManualID: [number: COMMITTEE_DECISION_LOG[]],
)
    : MUIDataTableProps['data']
{
    return departments.map(dep => {
        const parentManual = manualIDtoManualMap[dep.PARENTID ?? -1];
        const hasApprovals = Object.keys(approvalsFromManualID).length > 0;

        const returnObj = {
            ID: dep.INFORMATION_HIERARCHY_ID,
            Name: dep.TITLE,
            Status: ucFirst(dep.STATUS),
            Owner: dep.USER ? constructFullName(dep.USER, 'lastFirstMiddle') : '',
            Collaborators: transformACLArrayIntoStringArray(dep.COLLABORATORS || []).join('\n'),
            Visibility: dep.VIEWCONTROL ? 'Restricted' : '',
            Viewers: transformACLArrayIntoStringArray(dep.VIEWERS || []).join('\n'),
            ['Parent Manual']: parentManual ? parentManual.TITLE : '',
            ['Manual Group']: dep.PUBLISHER?.TITLE ?? '',
        };

        if (hasApprovals) {
            const approvals = (approvalsFromManualID[dep.INFORMATION_HIERARCHY_ID] || [])
                .map(decision => {
                    const decisionDate = new Date(decision.DECISION_DATE);
                    return `${decision.COMMITTEE_NAME} - ${decisionDate.toLocaleDateString()}`;
                });
            returnObj['View Approvals'] = approvals.join('\n');
        }

        return returnObj;
    });
}
