import {
    PrimaryColumn,
    Entity,
} from 'typeorm';
import { SchemaEntityManager } from '../SchemaEntityManager';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function createAckScheduleManualEntity(schemaName: string, manager: SchemaEntityManager) {
    @Entity('ACK_SCHEDULE_MANUAL', { schema: schemaName })
    class ACK_SCHEDULE_MANUAL {
        static SCHEMANAME = schemaName;
        @PrimaryColumn('number', {
            nullable: false,
            name: 'ACK_SCHEDULE_ID',
        })
        ACK_SCHEDULE_ID!: number;

        @PrimaryColumn('number', {
            nullable: false,
            name: 'INFORMATION_HIERARCHY_ID',
        })
        INFORMATION_HIERARCHY_ID!: number;
    }

    return ACK_SCHEDULE_MANUAL;
}

export { createAckScheduleManualEntity };
export type ACK_SCHEDULE_MANUAL = InstanceType<ReturnType<typeof createAckScheduleManualEntity>>;
