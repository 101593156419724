import { useState, useEffect } from 'react';

import axios from 'axios';
import { AdvancedSelect } from './advanced-select';
import { Button } from '../button';
import { CONSENT_AGENDA } from '@/entities/org/CONSENT_AGENDA';

interface ISelectConsentAgendaProps {
    committeeId: number;
    consentAgendaId?: number;
    onChange: (consentAgenda?: CONSENT_AGENDA) => void;
    disabled?: boolean;
    placeholder?: string;
    exemptConsentAgendaIds?: number[];
    hasMeetingMinutesDocId?: boolean;
    active?: boolean;
}

const SelectConsentAgenda: React.FC<ISelectConsentAgendaProps> = (props: ISelectConsentAgendaProps) => {

    const [consentAgendas, setConsentAgendas] = useState<CONSENT_AGENDA[]>([]);

    const loadInitialData = async () => {
        const res = !props.active ?
            await axios.get(`/api/committee/${ props.committeeId }/consent-agenda`) :
            await axios.get(`/api/committee/${ props.committeeId }/consent-agenda/active`);

        setConsentAgendas(res.data.consentAgendas);
    };

    useEffect(() => {
        loadInitialData().then();
    }, []);

    const consentAgendaOptions = consentAgendas
        .filter(ca => props.exemptConsentAgendaIds ? !props.exemptConsentAgendaIds.includes(ca.CONSENT_AGENDA_ID) : true)
        .filter(ca => props.hasMeetingMinutesDocId ? ca.MINUTES_DOCID !== null : true)
        .map(ca => ({
            label: (new Date(ca.MEETING_START_DATE)).toDateString(),
            value: ca.CONSENT_AGENDA_ID
        }));

    const consentAgendaOptionsOrdered = props.active ? consentAgendaOptions.reverse() : consentAgendaOptions;
    const selectedMenuValue = consentAgendaOptions.find(ca => ca.value === props.consentAgendaId)?.label;

    const [filterText, setFilterText] = useState('');

    return <AdvancedSelect 
        text={ selectedMenuValue ?? props.placeholder }
        placeholder={ props.placeholder ?? 'No placeholder defined.' }
        clearSelection={ () => props.onChange(undefined) }
        filterText={filterText}
        setFilterText={setFilterText}
        isSingleSelect
    >
        {
            consentAgendaOptionsOrdered.map(option => {
                return <Button
                    key={ option.value }
                    onClick={ () => props.onChange(consentAgendas.find(ca => ca.CONSENT_AGENDA_ID === option.value)) }
                    className='w-full'
                    variant='ghost'
                >
                    { option.label }
                </Button>;
            })
        }
    </AdvancedSelect>;

};

export default SelectConsentAgenda;
