import {
    Column,
    Entity,
    JoinColumn,
    ManyToOne,
    PrimaryColumn,
} from 'typeorm';
import { SchemaEntityManager } from '../SchemaEntityManager';
import { CONVERSATION } from './CONVERSATION';

function createConversationParticipantsEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('CONVERSATION_PARTICIPANTS', { schema: schema })
    class CONVERSATION_PARTICIPANTS {
        @PrimaryColumn('number', {
            nullable: false,
            name: 'CONVERSATIONID'
        })
        CONVERSATIONID!: number;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'USERID'
        })
        USERID!: number | null;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'GROUPID'
        })
        GROUPID!: number | null;

        @Column('char', {
            nullable: true,
            name: 'IS_MULTIORG_REP'
        })
        IS_MULTIORG_REP!: string | null;

        @ManyToOne(
            () => manager.getConversationEntity(schema),
            (conversation) => conversation.CONVERSATION_PARTICIPANTS
        )
        @JoinColumn({
            name: 'CONVERSATIONID',
        })
        CONVERSATION!: CONVERSATION | null;
    }

    return CONVERSATION_PARTICIPANTS;
}

export { createConversationParticipantsEntity };
export type CONVERSATION_PARTICIPANTS = InstanceType<ReturnType<typeof createConversationParticipantsEntity>>;