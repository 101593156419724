import { useState, useEffect } from 'react';

import axios from 'axios';

import ReactSelect from 'react-select';
import { CONSENT_AGENDA } from '../../../../entities/org/CONSENT_AGENDA';

interface ISelectConsentAgendaProps {
    committeeId: number;
    consentAgendaId?: number;
    onChange: (consentAgenda?: CONSENT_AGENDA) => void;
    disabled?: boolean;
    placeholder?: string;
    exemptConsentAgendaIds?: number[];
    hasMeetingMinutesDocId?: boolean;
    active?: boolean;
}

const SelectConsentAgenda: React.FC<ISelectConsentAgendaProps> = (props: ISelectConsentAgendaProps) => {

    const [consentAgendas, setConsentAgendas] = useState<CONSENT_AGENDA[]>([]);

    const loadInitialData = async () => {
        const res = !props.active ?
            await axios.get(`/api/committee/${ props.committeeId }/consent-agenda`) :
            await axios.get(`/api/committee/${ props.committeeId }/consent-agenda/active`);

        setConsentAgendas(res.data.consentAgendas);
    };

    useEffect(() => {
        loadInitialData().then();
    }, []);

    const consentAgendaOptions = consentAgendas
        .filter(ca => props.exemptConsentAgendaIds ? !props.exemptConsentAgendaIds.includes(ca.CONSENT_AGENDA_ID) : true)
        .filter(ca => props.hasMeetingMinutesDocId ? ca.MINUTES_DOCID !== null : true)
        .map(ca => ({
            label: (new Date(ca.MEETING_START_DATE)).toDateString(),
            value: ca.CONSENT_AGENDA_ID
        }));

    const consentAgendaOptionsOrdered = props.active ? consentAgendaOptions.reverse() : consentAgendaOptions;
    
    return <ReactSelect
        onChange={
            (selected) => {
                if (!selected) {
                    props.onChange(undefined);
                } else {
                    props.onChange(consentAgendas.find(ca => ca.CONSENT_AGENDA_ID === (selected.value as number)));
                }
            }
        }
        value={ consentAgendaOptionsOrdered.find(option => option.value === props.consentAgendaId) }
        options={ consentAgendaOptionsOrdered }
        placeholder={ props.placeholder }
        styles={{
            control: (provided: object) => ({
                ...provided,
                width: '28rem'
            })
        }}
        isClearable
        isDisabled={ props.disabled }
    />;

};

export default SelectConsentAgenda;
