import {
    Column,
    Entity,
    JoinColumn,
    ManyToOne,
    PrimaryColumn
} from 'typeorm';
import {  type SUBSCRIPTION } from './SUBSCRIPTION';
import {  type EDITION } from './EDITION';
import { SchemaEntityManager } from '../SchemaEntityManager';

export enum InventoryAdoptionStatuses {
    Adopted = 'Adopted',
    Archived = 'Archived',
    Pending = 'Pending',
    Waived = 'Waived',
    WaiverRejected = 'Waiver Rejected',
    WaiverRequested = 'Waiver Requested',
}

function createInventoryEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('INVENTORY', { schema: schema })
    class INVENTORY {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            name: 'INVENTORYID'
        })
        INVENTORYID!: number;

        @Column('timestamp', {
            default: () => 'SYSDATE',
            name: 'CREATED_DATE'
        })
        CREATED_DATE!: Date;

        @Column('timestamp', {
            name: 'ACTION_DATE'
        })
        ACTION_DATE!: Date;

        @Column('number', {
            nullable: false,
            name: 'SUBSCRIPTIONID'
        })
        SUBSCRIPTIONID!: number;

        @ManyToOne(
            () => manager.getSubscriptionEntity(schema),
            (subscription) => subscription.INVENTORIES
        )
        @JoinColumn({
            name: 'SUBSCRIPTIONID',
        })
        SUBSCRIPTION!: SUBSCRIPTION;

        @Column('number', {
            nullable: false,
            name: 'EDITIONID'
        })
        EDITIONID!: number;

        @ManyToOne(
            () => manager.getEditionEntity(schema),
            (edition) => edition.INVENTORIES
        )
        @JoinColumn({
            name: 'EDITIONID',
        })
        EDITION!: EDITION;

        @Column('number', {
            name: 'DOCREVID'
        })
        DOCREVID!: number;

        @Column('timestamp', {
            name: 'ADOPTION_DATE'
        })
        ADOPTION_DATE!: Date;

        @Column('timestamp', {
            name: 'WAIVED_DATE'
        })
        WAIVED_DATE!: Date;

        @Column('varchar2', {
            default: () => 'Pending',
            name: 'ADOPTION_STATUS'
        })
        ADOPTION_STATUS!: InventoryAdoptionStatuses;

        @Column('number', {
            name: 'CONVERSATIONID'
        })
        CONVERSATIONID!: number;

        static AdoptionStatuses = InventoryAdoptionStatuses;
    }

    return INVENTORY;
}

export { createInventoryEntity };
export type INVENTORY = InstanceType<ReturnType<typeof createInventoryEntity>>;
