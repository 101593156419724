import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import DataTable from '../../../_shared/DataTable/DataTable';
import { MUIDataTableColumn, MUIDataTableProps } from 'mui-datatables';
import UserBundleContext from '../../../../context/UserBundleContext';
import produceFlattenedOptionsFromNestedInformationHierarchy from '../../../../utils/produceFlattenedOptionsFromNestedInformationHierarchy';
import { Button, makeStyles } from '@material-ui/core';
import { LoadingIndicator } from '../../../_shared/LoadingIndicator';
import { LoadingStatuses } from '../../../../utils/LoadingStatuses';
import Subheader from '../../../_shared/Subheader/Subheader';
import SubheaderItem from '../../../_shared/Subheader/SubheaderItem';
import { transformDepartmentsToReadableDataTableValues } from '../../../_shared/DataTable/transformers/transformDepartmentsToReadableDataTableValues';
import { setTitleInAdmin } from '../../../../utils/setTitleInAdmin';
import { LucidocModal } from '../../../_shared/LucidocModal/LucidocModal';
import { EditInfoHierarchy } from '../InfoHierarchy/EditInfoHierarchy';
import { INFORMATION_HIERARCHY, createInformationHierarchyEntity } from '../../../../entities/org/INFORMATION_HIERARCHY';
import { findNestedIH } from '../../../../utils/findNestedIH';
import { Add } from '@material-ui/icons';
import SaveBar from '../../../_shared/SaveBar/SaveBar';
import { redirectToHomepage } from '../../../../utils/savebarUtilities';
import { schemaEntityManager } from '../../../../utils/emInstance';
import { onDownloadForTablesWithMultilineData } from '../../../../_shared/utils/dataTableFunctions';
import setWindowTitle from '../../../../utils/setWindowTitle';
import { TemporaryFavoritesStar } from '@/components/_shared/TemporaryFavoritesStar/TemporaryFavoritesStar';
import { formatTitle } from '@/hooks/useDocumentTitle';
import { QueryParams, useBrowserQuery } from '@/hooks/useBrowserQuery';

const useStyles = makeStyles({
    departmentTitle: {
        cursor: 'pointer',
        color: 'blue',
        whiteSpace: 'pre'
    },
    lastElementsDiv: {
        display: 'flex',
        alignItems: 'center',
    }
});

type ManageDepartmentsProps = {
    isInAdmin?: boolean
}

export interface ManageDepartmentsPageQuery extends QueryParams {
    idOfDepartmentBeingEdited?: number
}

export function ManageDepartments(props: ManageDepartmentsProps) {
    const classes = useStyles();
    const [query, setQuery] = useBrowserQuery<ManageDepartmentsPageQuery>();

    const context = useContext(UserBundleContext);
    const isInAdmin = props.isInAdmin ?? false;

    const Tabs: {[key: string]: string} = isInAdmin
        ? {
            'All (Admin)' : 'All (Admin)'
        }
        : {
            'All'            : 'All',
            'Owned By Me'    : 'Owned By Me',
            'Collaborate On' : 'Collaborate On',
        };

    const [ selectedTab, setSelectedTab ] = useState<string>(isInAdmin
        ? Tabs['All (Admin)']
        : Tabs['All']
    );

    const [ loadingStatus, setLoadingStatus ] = useState(LoadingStatuses.READY);
    const [ departments, setDepartments ] = useState<INFORMATION_HIERARCHY[]>([]);
    const [ selectedDepartment, setSelectedDepartment ] = useState<INFORMATION_HIERARCHY>();
    const [ tableData, setTableData ] = useState<MUIDataTableProps['data']>([]);

    const [ allViewers, setAllViewers ] = useState<string[]>([]);
    const [ allCollaborators, setAllCollaborators ] = useState<string[]>([]);

    useEffect(() => {
        const newTitle = 'Manage Departments';
        setWindowTitle(newTitle);
        setTitleInAdmin(newTitle);
    }, []);

    useEffect(() => {
        loadDepartments().then();
    }, [selectedTab]);

    useEffect(() => {
        const viewerSet = new Set<string>();
        const collaboratorSet = new Set<string>();

        tableData.forEach(row => {
            (row as any).Viewers?.split('\n').forEach((name: string) => {
                if (!viewerSet.has(name)) viewerSet.add(name);
            });
            (row as any).Collaborators?.split('\n').forEach((name: string) => {
                if (!collaboratorSet.has(name)) collaboratorSet.add(name);
            });
        });

        setAllViewers(Array.from(viewerSet));
        setAllCollaborators(Array.from(collaboratorSet));

    }, [tableData]);

    async function loadDepartments() {
        setLoadingStatus(LoadingStatuses.LOADING);

        const res = props.isInAdmin
            ? await axios.get('/api/administration/organization/departments/get-departments', {
                params: {
                    activeOnly: false
                }
            })
            : await axios.get('/api/doc-manager/departments/manage-departments/get-departments', {
                params: {
                    selectedTab
                }
        });

        const newDepartments = res.data.departments || [];
        setDepartments(newDepartments);

        if (query.idOfDepartmentBeingEdited) {
            setSelectedDepartment(findNestedIH(newDepartments, query.idOfDepartmentBeingEdited));
        }

        setTableData(
            transformDepartmentsToReadableDataTableValues(
                produceFlattenedOptionsFromNestedInformationHierarchy(
                    res.data.departments || [],
                    [],
                    0,
                    true,
                    ' '.repeat(4)
                )
            )
        );

        setLoadingStatus(LoadingStatuses.READY);
    }

    const targetTitle = formatTitle(
        isInAdmin ? 'Administration' : 'DocManager',
        'Manage Departments'
    );
    const favoriteStar =  <TemporaryFavoritesStar
        targetPathName={isInAdmin ? 'admin/admin.pl?repname=manage_departments' : 'docmgr2/docmanager.pl?repname=Departments'}
        targetTitle={targetTitle}
    />;
    const editingADepartment = !!selectedDepartment?.INFORMATION_HIERARCHY_ID;
    const modalTitle = `Edit ${context.organization?.ORGUNITNAME || 'Department'}: ${selectedDepartment?.INFORMATION_HIERARCHY_ID ? selectedDepartment?.TITLE : 'Create new'}`;

    return (
        <div>
            <Subheader
                value={selectedTab}
                onChange={value => setSelectedTab(value)}
                lastElement={isInAdmin
                    ? (
                        <div className={classes.lastElementsDiv}>
                            <Button
                                startIcon={<Add />}
                                onClick={() => setSelectedDepartment(new (createInformationHierarchyEntity('', schemaEntityManager)))}
                            >
                                Create New
                            </Button>
                            {favoriteStar}
                        </div>
                    ) : <div>{favoriteStar}</div>
                }
            >
                {Object.keys(Tabs).map(tab => <SubheaderItem key={Tabs[tab]} label={Tabs[tab]} value={Tabs[tab]} />)}
            </Subheader>

            {loadingStatus !== LoadingStatuses.READY &&
                <LoadingIndicator loadingStatus={loadingStatus} />
            }

            {loadingStatus === LoadingStatuses.READY &&
                <DataTable
                    muiDataTableProps={{
                        title: context.organization?.ORGUNITPLURAL || 'Departments',
                        columns: tableData[0] && Object.keys(tableData[0]).map(key => {
                            const returnObj: MUIDataTableColumn = {
                                name: key
                            };

                            if (key === 'ID') {
                                returnObj.options = {
                                    display: false,
                                    filter: false,
                                };
                            }

                            if (key === 'Name') {
                                returnObj.options = {
                                    customBodyRender: (value, tableMeta) => {
                                        const depID = tableMeta.rowData[0];

                                        return (
                                            <span
                                                className={classes.departmentTitle}
                                                onClick={() => setSelectedDepartment(findNestedIH(departments, depID))}
                                            >
                                                {value}
                                            </span>
                                        );
                                    }
                                };
                            }

                            if (key == 'Status') {
                                returnObj.options = {
                                    filter: true,
                                    filterOptions: {
                                        names: ['Active', 'Archived'],
                                        logic: (value, selectedStatuses) => {
                                            return !selectedStatuses.find(status => value.includes(status));
                                        },
                                    },
                                };
                            }

                            if (key === 'Collaborators') {
                                returnObj.options = {
                                    filterOptions: {
                                        names: allCollaborators,
                                        logic: (value, selectedCollaborators) => {
                                            return !selectedCollaborators.find(collaborator => value.includes(collaborator));
                                        },
                                    },
                                    customBodyRender: (value, tableMeta) => {
                                        const collaborators = tableMeta.rowData[5];

                                        return (
                                            <span style={{ whiteSpace: 'pre' }}>
                                                {collaborators}
                                            </span>
                                        );
                                    }
                                };
                            }

                            if (key === 'Viewers') {
                                returnObj.options = {
                                    filterOptions: {
                                        names: allViewers,
                                        logic: (value, selectedViewers) => {
                                            return !selectedViewers.find(viewer => value.includes(viewer));
                                        },
                                    },
                                    customBodyRender: (value, tableMeta) => {
                                        const viewers = tableMeta.rowData[7];

                                        return (
                                            <span style={{ whiteSpace: 'pre' }}>
                                                {viewers}
                                            </span>
                                        );
                                    }
                                };
                            }

                            return returnObj;
                        }),
                        data: tableData,
                        options: {
                            rowsPerPage: 100,
                            onDownload: onDownloadForTablesWithMultilineData,
                            downloadOptions: {
                                filterOptions: {
                                    useDisplayedColumnsOnly: false
                                }
                            },
                        }
                    }}
                    onRefresh={() => loadDepartments()}
                />
            }

            {selectedDepartment &&
                <LucidocModal
                    title={modalTitle}
                    open={!!selectedDepartment}
                    onOpen={() => {
                        editingADepartment && setQuery({
                            ...query,
                            idOfDepartmentBeingEdited: selectedDepartment.INFORMATION_HIERARCHY_ID,
                        });
                    }}
                    onClose={() => {
                        setSelectedDepartment(undefined);
                        setQuery({
                            ...query,
                            idOfDepartmentBeingEdited: undefined,
                        });
                    }}
                    removePadding={true}
                    hideFavoriteStar={!editingADepartment}
                    titleForFavoriteStarLink={formatTitle(isInAdmin ? 'Administration' : 'DocManager', modalTitle)}
                >
                    <EditInfoHierarchy
                        ihType={'department'}
                        ih={selectedDepartment}
                        sendIhToParent={department => {
                            // update the selected department with the new values
                            setSelectedDepartment({ ...selectedDepartment, ...department });
                            loadDepartments().then();
                        }}
                        isInAdmin={isInAdmin}
                        closeModal={() => setSelectedDepartment(undefined)}
                    />
                </LucidocModal>
            }
            <SaveBar onClose={() => redirectToHomepage()} />
        </div>
    );
}
