import {
    Column,
    Entity,
    PrimaryColumn,
} from 'typeorm';
import { SchemaEntityManager } from '../SchemaEntityManager';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function createXMDValueEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('XMDVALUE', { schema: schema })
    class XMDVALUE {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'XMDVALUEID'
        })
        XMDVALUEID!: number;

        @Column('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'DOCREVID'
        })
        DOCREVID!: number;

        @Column('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'FIELDID'
        })
        FIELDID!: number;

        @Column('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'FIELDTYPEID'
        })
        FIELDTYPEID!: number;

        @Column('date', {
            nullable: true,
            name: 'XMD_DATE'
        })
        XMD_DATE!: Date | null;

        @Column('number', {
            nullable: true,
            name: 'XMD_NUMBER'
        })
        XMD_NUMBER!: number | null;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'XMD_KEY'
        })
        XMD_KEY!: number | null;

        @Column('varchar2', {
            nullable: true,
            length: 500,
            name: 'XMD_VALUE'
        })
        XMD_VALUE!: string | null;
    }

    return XMDVALUE;
}

export { createXMDValueEntity };
export type XMDVALUE = InstanceType<ReturnType<typeof createXMDValueEntity>>;