import React, { useContext, useEffect, useState } from 'react';
import { INFORMATION_HIERARCHY, InformationHierarchyStatuses } from '../../../../entities/org/INFORMATION_HIERARCHY';
import ReactSelect from 'react-select';
import { ucFirst } from '../../../../_shared/utils/docManagerFunctions';
import { makeStyles, TextField } from '@material-ui/core';
import { SelectManual } from '../../../../ui/ui/advanced-select/select-manual';
import SaveBar from '../../../_shared/SaveBar/SaveBar';
import { LoadingIndicator } from '../../../_shared/LoadingIndicator'; 
import { LoadingStatuses } from '../../../../utils/LoadingStatuses';
import axios from 'axios';
import UserBundleContext from '../../../../context/UserBundleContext';
import hasRights from '../../../../_shared/utils/hasRights';
import SelectUser from '../../../_shared/Forms/Select/SelectUser';
import { SelectPublisher } from '../../../_shared/Forms/Select/SelectPublisher';
import { RightsCodeKey } from '@/entities/master/PERSON';
import { RefreshRounded } from '@material-ui/icons';
import { SelectDepartment } from '@/components/_shared/Forms/Select/SelectDepartment';

type EditIhGeneralProps = {
    ihType: 'department' | 'manual'
    ih: INFORMATION_HIERARCHY
    sendIhToParent: (dep: INFORMATION_HIERARCHY) => void
    closeModal?: () => void
}

const useStyles = makeStyles({
    mainDiv: {
        margin: '1.5rem',
        display: 'grid',
        gridTemplateColumns: '1fr 5fr',
        gridGap: '1rem',
        alignItems: 'center',
    },
});

export function EditIhGeneral(props: EditIhGeneralProps) {
    const classes = useStyles();

    const context = useContext(UserBundleContext);

    const [ loadingStatus, setLoadingStatus ] = useState(LoadingStatuses.LOADING);
    const [ ih, setIh] = useState<INFORMATION_HIERARCHY>(props.ih);

    useEffect(() => {
        loadData().then();
    }, []);

    const isOrgAdmin = hasRights(context, RightsCodeKey.OrganizationAdministrator);
    const isOwner = props.ih.USERID === context.user?.USERID;

    async function loadData() {
        if (!props.ih.INFORMATION_HIERARCHY_ID) {
            // if no ID, it means we're creating a new ih
            setLoadingStatus(LoadingStatuses.READY);
            return;
        }

        setLoadingStatus(LoadingStatuses.LOADING);

        const res = await axios.get('/api/doc-manager/info-hierarchy/edit-info-hierarchy/general', {
            params: {
                ihID: props.ih.INFORMATION_HIERARCHY_ID
            }
        });

        setIh(res.data.ih);
        setLoadingStatus(LoadingStatuses.READY);
    }

    async function handleStatusUpdate(status: keyof typeof InformationHierarchyStatuses) {
        if (status === InformationHierarchyStatuses.active) {
            ih && setIh({
                ...ih,
                STATUS: status
            });

            return;
        }
        else if (status === InformationHierarchyStatuses.archived) {
            const res = await axios.get('/api/doc-manager/info-hierarchy/edit-info-hierarchy/general/is-archive-blocked', {
                params: {
                    ihType: props.ihType,
                    ihID: props.ih.INFORMATION_HIERARCHY_ID
                }
            });

            if (res.data.whyIsIhBlockedFromArchival) {
                window.alert(res.data.whyIsIhBlockedFromArchival);
                return;
            }
            else {
                ih && setIh({
                    ...ih,
                    STATUS: status
                });
            }
        }
    }

    async function saveData() {
        setLoadingStatus(LoadingStatuses.SAVING);

        const res = await axios.post('/api/doc-manager/info-hierarchy/edit-info-hierarchy/general/save-general', {
            ih
        });

        setIh(res.data.ih);
        props.sendIhToParent(res.data.ih);
        setLoadingStatus(LoadingStatuses.READY);
    }

    const statusOptions = [
        {
            label: ucFirst(InformationHierarchyStatuses.active),
            value: InformationHierarchyStatuses.active,
        },
        {
            label: ucFirst(InformationHierarchyStatuses.archived),
            value: InformationHierarchyStatuses.archived,
        },
    ];

    return (
        <div className={classes.mainDiv}>
            {loadingStatus !== LoadingStatuses.READY || !ih &&
                <LoadingIndicator loadingStatus={loadingStatus} />
            }

            {loadingStatus === LoadingStatuses.READY &&
                <>
                    <p>Name</p>
                    <TextField
                        variant={'outlined'}
                        size={'small'}
                        value={ih.TITLE || ''}
                        onChange={e => {
                            setIh({
                                ...ih,
                                TITLE: e.currentTarget.value,
                            });
                        }}
                        inputProps={{ maxLength: 90 }}
                        style={{ width: '100%' }}
                    />

                    <p>Owner</p>
                    <SelectUser
                        userId={ih.USERID}
                        onChange={value => value && setIh({
                            ...ih,
                            USERID: value
                        })}
                        rights={[RightsCodeKey.Author, RightsCodeKey.Signer]}
                        getMappedUsers={true}
                        disabled={!isOwner && !isOrgAdmin}
                    />

                    {props.ihType === 'manual' &&
                        <>
                            <p>{context.organization?.MANUALNAME} Group</p>
                            <SelectPublisher
                                publisherID={ih.PUBLISHER_ID}
                                onChange={publisher => publisher && setIh({
                                    ...ih,
                                    PUBLISHER_ID: publisher?.PUBLISHER_ID,
                                    PARENTID: null,
                                })}
                                isClearable={false}
                                disabled={!isOrgAdmin}
                            />

                            <p>Parent {context.organization?.MANUALNAME}</p>
                            <SelectManual
                                manualId={ih.PARENTID || undefined}
                                publisherID={ih.PUBLISHER_ID}
                                onChange={value => setIh({
                                    ...ih,
                                    PARENTID: value || null
                                })}
                                disabled={!isOrgAdmin}
                                dummyValue={{
                                    label: 'None (make top level)',
                                    value: 0
                                }}
                                unselectableManuals={[ih]}
                            />
                        </>
                    }

                    {props.ihType === 'department' &&
                        <>
                            <p>Parent {context.organization?.ORGUNITNAME}</p>
                            <SelectDepartment
                                departmentID={ih.PARENTID || undefined}
                                onChange={value => setIh({
                                    ...ih,
                                    PARENTID: value || null
                                })}
                                isClearable={false}
                                disabled={!isOrgAdmin}
                                dummyValue={{
                                    label: 'None (make top level)',
                                    value: 0
                                }}
                                unselectableDepartments={[ih]}
                            />
                        </>
                    }

                    {props.ih.INFORMATION_HIERARCHY_ID && isOrgAdmin &&
                        <>
                            <p>Status</p>
                            <ReactSelect
                                value={statusOptions.find(option => option.value === ih.STATUS)}
                                options={statusOptions}
                                onChange={(option: any) => handleStatusUpdate(option.value)}
                                isDisabled={!isOrgAdmin}
                            />
                        </>
                    }

                    <SaveBar
                        onSave={() => saveData()}
                        isSaveDisabled={
                               ! ih.TITLE
                            || ! ih.USERID
                        }
                        onClose={() => props.closeModal?.()}
                        onCancel={() => loadData()}
                        cancelIcon={ <RefreshRounded /> }
                    />
                </>
            }
        </div>
    );
}
