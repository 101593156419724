import { makeStyles } from '@material-ui/core';
import { PERSON } from '../../../../entities/master/PERSON';
import { USERGROUP } from '../../../../entities/org/USERGROUP';
import { DragDropContext } from 'react-beautiful-dnd';
import UserPicker from '../../../UserPicker/UserPicker';
import H3WithHelpTip from '../H3WithHelpTip';

type EditItemCollaboratorsUserPickerProps = {
    objectType: 'DOCID' | 'INFORMATION_HIERARCHY_ID' | 'PUBLISHER_ID'
    objectSubType?: 'MANUAL' | 'DEPARTMENT'
    updateUsers: (users: (PERSON | USERGROUP)[]) => void
    updateGroups: (groups: (PERSON | USERGROUP)[]) => void
    updateLimitedVisibilityLimitedCollaboratorUsers: (users: (PERSON | USERGROUP)[]) => void
    updateLimitedVisibilityLimitedCollaboratorGroups: (users: (PERSON | USERGROUP)[]) => void
    updateFullVisibilityLimitedCollaboratorUsers: (users: (PERSON | USERGROUP)[]) => void
    updateFullVisibilityLimitedCollaboratorGroups: (users: (PERSON | USERGROUP)[]) => void
    updateFullCollaboratorUsers: (users: (PERSON | USERGROUP)[]) => void
    updateFullCollaboratorGroups: (users: (PERSON | USERGROUP)[]) => void
    limitedVisibilityLimitedCollaborators: (PERSON | USERGROUP)[]
    fullVisibilityLimitedCollaborators: (PERSON | USERGROUP)[]
    fullCollaborators: (PERSON | USERGROUP)[]
    collaborators: (PERSON | USERGROUP)[] // collaborators for documents, manuals
    unremovablePeople: ({ [key: string]: string })
    unremovableGroups: ({ [key: string]: string })
    userPickerClass: string
}

const useStyles = makeStyles({
    rowContainer: {
        marginTop: '1rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
    },
    indent: {
        marginLeft: '2rem'
    },
    fullCollaboratorDisclaimer: {
        marginTop: '1rem',
    }
});

export function EditItemCollaboratorsUserPicker(props: EditItemCollaboratorsUserPickerProps) {
    const classes = useStyles();
    const isDepartment = props.objectType === 'INFORMATION_HIERARCHY_ID' && props.objectSubType === 'DEPARTMENT';

    return (
        <div className={classes.rowContainer}>
            {isDepartment &&
                <>
                    <div>
                        <H3WithHelpTip
                            helpText={'Assigned users or user groups can manage some department properties and create new documents only.'}
                            text={'Limited Collaboration / Limited Visibility'}
                        />
                        <div className={props.userPickerClass}>
                            <DragDropContext onDragEnd={() => {}}>
                                <UserPicker
                                    getUsers={true}
                                    getGroups={true}
                                    addUserSignature={(newUsers: (PERSON | USERGROUP)[]) => props.updateLimitedVisibilityLimitedCollaboratorUsers(newUsers)}
                                    removeUserSignature={(newUsers: (PERSON | USERGROUP)[]) => props.updateLimitedVisibilityLimitedCollaboratorUsers(newUsers)}
                                    addGroupSignature={(newUsers: (PERSON | USERGROUP)[]) => props.updateLimitedVisibilityLimitedCollaboratorGroups(newUsers)}
                                    removeGroupSignature={(newUsers: (PERSON | USERGROUP)[]) => props.updateLimitedVisibilityLimitedCollaboratorGroups(newUsers)}
                                    selectedUsersPlusGroups={props.limitedVisibilityLimitedCollaborators}
                                    unremovablePeople={props.unremovablePeople}
                                    unremovableGroups={props.unremovableGroups}
                                    allowedPrivileges={['author']}
                                    isDragDisabled={true}
                                    hideArchivedGroups={true}
                                />
                            </DragDropContext>
                        </div>
                    </div>
                    <div>
                        <H3WithHelpTip
                            helpText={'Assigned users or user groups can manage some department properties, create new documents, and view visibility-restricted department documents.'}
                            text={'Limited Collaboration / Full Visibility'}
                        />
                        <div className={props.userPickerClass}>
                            <DragDropContext onDragEnd={() => {}}>
                                <UserPicker
                                    getUsers={true}
                                    getGroups={true}
                                    addUserSignature={(newUsers: (PERSON | USERGROUP)[]) => props.updateFullVisibilityLimitedCollaboratorUsers(newUsers as PERSON[])}
                                    removeUserSignature={(newUsers: (PERSON | USERGROUP)[]) => props.updateFullVisibilityLimitedCollaboratorUsers(newUsers as PERSON[])}
                                    addGroupSignature={(newUsers: (PERSON | USERGROUP)[]) => props.updateFullVisibilityLimitedCollaboratorGroups(newUsers as PERSON[])}
                                    removeGroupSignature={(newUsers: (PERSON | USERGROUP)[]) => props.updateFullVisibilityLimitedCollaboratorGroups(newUsers as PERSON[])}
                                    selectedUsersPlusGroups={props.fullVisibilityLimitedCollaborators}
                                    unremovablePeople={props.unremovablePeople}
                                    unremovableGroups={props.unremovableGroups}
                                    allowedPrivileges={['author']}
                                    isDragDisabled={true}
                                    hideArchivedGroups={true}
                                    />
                            </DragDropContext>
                        </div>
                    </div>
                    <div>
                        <H3WithHelpTip
                            helpText={'Assigned users or user groups can manage some department properties, create new documents, view visibility-restricted department documents, and manage department documents in their DocManager.'}
                            text={'Full Collaboration'}
                        />
                        <div className={props.userPickerClass}>
                            <DragDropContext onDragEnd={() => {}}>
                                <UserPicker
                                    getUsers={true}
                                    getGroups={true}
                                    addUserSignature={(newUsers: (PERSON | USERGROUP)[]) => props.updateFullCollaboratorUsers(newUsers as PERSON[])}
                                    removeUserSignature={(newUsers: (PERSON | USERGROUP)[]) => props.updateFullCollaboratorUsers(newUsers as PERSON[])}
                                    addGroupSignature={(newUsers: (PERSON | USERGROUP)[]) => props.updateFullCollaboratorGroups(newUsers as PERSON[])}
                                    removeGroupSignature={(newUsers: (PERSON | USERGROUP)[]) => props.updateFullCollaboratorGroups(newUsers as PERSON[])}
                                    selectedUsersPlusGroups={props.fullCollaborators}
                                    unremovablePeople={props.unremovablePeople}
                                    unremovableGroups={props.unremovableGroups}
                                    allowedPrivileges={['author']}
                                    isDragDisabled={true}
                                    hideArchivedGroups={true}
                                    />
                            </DragDropContext>
                            <p className={classes.fullCollaboratorDisclaimer}>
                                Department owners must also be Full Collaborators to manage department documents.
                            </p>
                        </div>
                    </div>
                </>
            }

            {!isDepartment &&
                <>
                    <div className={props.userPickerClass}>
                        <DragDropContext onDragEnd={() => {}}>
                            <UserPicker
                                getUsers={true}
                                getGroups={true}
                                addUserSignature={(newUsers: (PERSON | USERGROUP)[]) => props.updateUsers(newUsers as PERSON[])}
                                removeUserSignature={(newUsers: (PERSON | USERGROUP)[]) => props.updateUsers(newUsers as PERSON[])}
                                addGroupSignature={(newUsers: (PERSON | USERGROUP)[]) => props.updateGroups(newUsers as PERSON[])}
                                removeGroupSignature={(newUsers: (PERSON | USERGROUP)[]) => props.updateGroups(newUsers as PERSON[])}
                                selectedUsersPlusGroups={props.collaborators}
                                unremovablePeople={props.unremovablePeople}
                                unremovableGroups={props.unremovableGroups}
                                allowedPrivileges={['author']}
                                isDragDisabled={true}
                                hideArchivedGroups={true}
                            />
                        </DragDropContext>
                    </div>
                </>
            }
        </div>
    );
}