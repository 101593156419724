import React, { useContext, useEffect, useState } from 'react';
import Subheader from '../../../../_shared/Subheader/Subheader';
import SubheaderItem from '../../../../_shared/Subheader/SubheaderItem';
import ItemsPendingApproval from '../ItemsPendingApproval';
import { ApprovalSessionStatuses, ApprovalSessionTypes } from '../../../../../entities/org/APPROVAL_SESSION';
import { DocumentRevisionStatuses } from '../../../../../entities/org/DOCUMENTREVISION';
import Select from 'react-select';
import { NotificationWindowTypes } from '../../../../../entities/org/NOTIFICATION_WINDOW';
import { DOCUMENT_TYPE_PL } from '../../../../../entities/org/DOCUMENT_TYPE_PL';
import DocTypeBacklog from './DocTypeBacklog';
import UserBundleContext from '../../../../../context/UserBundleContext';
import hasRights from '../../../../../_shared/utils/hasRights';
import axios from 'axios';
import { redirectToHomepage } from '../../../../../utils/savebarUtilities';
import SaveBar from '../../../../_shared/SaveBar/SaveBar';
import { RightsCodeKey } from '@/entities/master/PERSON';

export default function CRDDocTypes() {
    const Tabs: { [key: string]: string } = {
        DocTypeBacklog: 'DocType Backlog',
        PendingSignature: 'Pending Signature',
        PendingApproval: 'Pending Approval',
        PendingAdditional: 'Pending Additional',
        PendingRelease: 'Pending Release',
        PendingRouting: 'Pending Routing',
        PendingReview: 'Pending Review',
        PendingArchive: 'Pending Archive',
        PendingExpiration: 'Pending Expiration',
    };

    const context = useContext(UserBundleContext);
    const userIsAdmin = hasRights(context, [RightsCodeKey.DocumentAdministrator, RightsCodeKey.UserAdministrator, RightsCodeKey.OrganizationAdministrator]);

    const [selectedTab, setSelectedTab] = useState<string>(userIsAdmin ? Tabs.DocTypeBacklog : Tabs.PendingSignature);
    const [allDocTypes, setAllDocTypes] = useState<DOCUMENT_TYPE_PL[]>([]);
    const [selectedDocType, setSelectedDocType] = useState<DOCUMENT_TYPE_PL | undefined>(undefined);

    async function getDocTypes() {
        const res = await axios.get('/api/doc-manager/compliance-reports/get-doctype-backlog/get-doctypes-only');
        setAllDocTypes(res.data.docTypes);
    }

    useEffect(() => {
        // Backlog loads this data anyway, so we only need to load it if we're not loading the backlog
        if (!userIsAdmin) {
            getDocTypes().then();
        }
    }, []);

    const selectMenu = selectedTab !== Tabs.DocTypeBacklog ? (
        <Select
            value={selectedDocType
                ? {
                    label: selectedDocType.DESCRIPTION || '',
                    value: selectedDocType.DOCTYPE_ID
                }
                : {
                    label: 'All DocTypes',
                    value: 0
                }
            }
            options={
                [{ DESCRIPTION: 'All DocTypes', DOCTYPE_ID: 0 }]
                    .concat(allDocTypes)
                    .map(docType => {
                        return {
                            label: docType.DESCRIPTION,
                            value: docType.DOCTYPE_ID
                        };
                    })
            }
            onChange={(selectMenuOption: any) => setSelectedDocType(allDocTypes.find(docType => docType.DOCTYPE_ID === selectMenuOption.value))}
        />
    ) : undefined;

    return (
        <div>
            <Subheader
                value={selectedTab}
                onChange={value => setSelectedTab(value)}
                lastElement={
                    <div style={{ width: '12rem' }}>
                        {selectMenu}
                    </div>
                }
            >
                {Object.keys(Tabs)
                    .filter(tab => userIsAdmin ? true : !tab.includes('Backlog'))
                    .map(tab => <SubheaderItem key={Tabs[tab]} label={Tabs[tab]} value={Tabs[tab]} buttonWidth={'6rem'} />)
                }
            </Subheader>

            {selectedTab === Tabs.DocTypeBacklog &&
                <DocTypeBacklog
                    sendDocTypesToParent={(docTypes) => setAllDocTypes(docTypes)}
                    onSelectDocType={(docTypeID) => {
                        setSelectedDocType(allDocTypes.find(docType => docType.DOCTYPE_ID === docTypeID));
                        setSelectedTab(Tabs.PendingSignature);
                    }}
                />
            }

            {selectedTab === Tabs.PendingSignature &&
                <ItemsPendingApproval
                    docTypeID={selectedDocType?.DOCTYPE_ID}
                    docStatusID={DocumentRevisionStatuses.prepsig}
                />
            }

            {selectedTab === Tabs.PendingApproval &&
                <ItemsPendingApproval
                    docTypeID={selectedDocType?.DOCTYPE_ID}
                    docStatusID={DocumentRevisionStatuses.pending_committee_approval}
                />
            }

            {selectedTab === Tabs.PendingAdditional &&
                <ItemsPendingApproval
                    docTypeID={selectedDocType?.DOCTYPE_ID}
                    pendingAdditional={'any'}
                />
            }

            {selectedTab === Tabs.PendingRelease &&
                <ItemsPendingApproval
                    docTypeID={selectedDocType?.DOCTYPE_ID}
                    docStatusID={DocumentRevisionStatuses.preprel}
                />
            }

            {selectedTab === Tabs.PendingRouting &&
                <ItemsPendingApproval
                    docTypeID={selectedDocType?.DOCTYPE_ID}
                    docStatusID={DocumentRevisionStatuses.pending_committee_routing}
                    approvalSessionStatus={ApprovalSessionStatuses.pending_approval_routing}
                />
            }

            {selectedTab === Tabs.PendingReview &&
                <ItemsPendingApproval
                    docTypeID={selectedDocType?.DOCTYPE_ID}
                    approvalSessionType={ApprovalSessionTypes.review}
                    getItemsComingUpFor={NotificationWindowTypes.review}
                />
            }

            {selectedTab === Tabs.PendingArchive &&
                <ItemsPendingApproval
                    docTypeID={selectedDocType?.DOCTYPE_ID}
                    approvalSessionType={ApprovalSessionTypes.archive}
                />
            }

            {selectedTab === Tabs.PendingExpiration &&
                <ItemsPendingApproval
                    docTypeID={selectedDocType?.DOCTYPE_ID}
                    getItemsComingUpFor={NotificationWindowTypes.expiration}
                />
            }
            <SaveBar onClose={() => redirectToHomepage()} />
        </div>
    );
}
