import {
    Column,
    Entity,
    PrimaryColumn,
} from 'typeorm';
import { SchemaEntityManager } from '../SchemaEntityManager';


// eslint-disable-next-line @typescript-eslint/no-unused-vars
function createAccreditorCondition(schemaName: string, manager: SchemaEntityManager) {
    @Entity('ACCREDITOR_CONDITION', { schema: 'ACCREDITATIONMANAGER' })
    class ACCREDITOR_CONDITION {
        @PrimaryColumn('number', {
            nullable: false,
            name: 'ACCREDITOR_CONDITION_ID'
        })
        ACCREDITOR_CONDITION_ID!: number;
    
        @Column('number', {
            nullable: false,
            name: 'ACCREDITOR_ID'
        })
        ACCREDITOR_ID!: number;
    
        @Column('varchar2', {
            nullable: true,
            length: 64,
            name: 'SHA256_ID'
        })
        SHA256_ID!: string | null;
    
        @Column('number', {
            nullable: true,
            name: 'UPLOADED_BY'
        })
        UPLOADED_BY!: number | null;
    
        @Column('date', {
            nullable: false,
            default: () => 'SYSDATE',
            name: 'UPLOADED'
        })
        UPLOADED!: Date;
    
        @Column('varchar2', {
            nullable: true,
            length: 64,
            name: 'CONDITION_ID'
        })
        CONDITION_ID!: string | null;
    }

    return ACCREDITOR_CONDITION;
}

export { createAccreditorCondition };
export type ACCREDITOR_CONDITION = InstanceType<ReturnType<typeof createAccreditorCondition>>;
