import { Dialog, DialogContent, DialogDescription, DialogTitle, DialogTrigger } from '@/ui/ui/dialog';
import { Progress } from '@/ui/ui/progress';
import { CheckIcon } from '@radix-ui/react-icons';
import React from 'react';


export enum ExportTablePhase {
    PREPARING,
    RENDERING,
    CONVERTING,
    COMPLETE,
}

interface ExportModalProps {
    exportModalOpen: boolean;
    onExportModalOpenChange: (open: boolean) => void;
    rowsRendered: number;
    totalRows: number;
    phase: ExportTablePhase
}

export const ExportModal: React.FC<ExportModalProps> = ({ exportModalOpen, onExportModalOpenChange, rowsRendered, totalRows, phase }) => {

    const renderPreparing = () => {
        if (phase === ExportTablePhase.PREPARING) {
            return <div className='flex flex-row'>
                <PhaseStatusIndicator status={PhaseStatus.IN_PROGRESS} />
                <p>Preparing to export...</p>
            </div>;
        } else {
            return <div className='flex flex-row'>
                <PhaseStatusIndicator status={PhaseStatus.COMPLETE} />
                <p>Data is prepared.</p>
            </div>;
        }
    };

    const renderRendering = () => {
        const phaseStatusRender = (status: PhaseStatus, text: string) => {
            return <div className='flex flex-row'>
                <PhaseStatusIndicator status={status} />
                <div className='flex flex-col w-full'>
                    <p>{text}</p>
                    <Progress value={(rowsRendered / totalRows) * 100} />
                </div>
            </div>;
        };



        if (phase === ExportTablePhase.PREPARING) {
            return <>
                {phaseStatusRender(PhaseStatus.NOT_STARTED, 'Rendering has not started yet')}
            </>;
        } else if (phase === ExportTablePhase.RENDERING) {
            return <>
                {phaseStatusRender(PhaseStatus.IN_PROGRESS, `Rendering rows ${rowsRendered}/${totalRows}`)}
            </>;
        } else {
            return <>
                {phaseStatusRender(PhaseStatus.COMPLETE, 'Rendering complete!')}
            </>;
        }
    };

    const renderConverting = () => {
        const phaseStatusRender = (status: PhaseStatus, text: string) => {
            return <div className='flex flex-row'>
                <PhaseStatusIndicator status={status} />
                <p>{text}</p>
            </div>;
        };

        if (phase === ExportTablePhase.PREPARING || phase === ExportTablePhase.RENDERING) {
            return <>
                {phaseStatusRender(PhaseStatus.NOT_STARTED, 'Converting has not started yet')}
            </>;
        } else if (phase === ExportTablePhase.CONVERTING) {
            return <>
                {phaseStatusRender(PhaseStatus.IN_PROGRESS, 'Converting data to file...')}
            </>;
        } else {
            return <>
                {phaseStatusRender(PhaseStatus.COMPLETE, 'Conversion complete!')}
            </>;
        }
    };

    const renderReadyToDownload = () => {
        if (phase === ExportTablePhase.COMPLETE) {
            return <div className='flex flex-row'>
                <PhaseStatusIndicator status={PhaseStatus.COMPLETE} />
                <a href='#' className='text-primary'>Download file</a>
            </div>;
        } else {
            return <div className='flex flex-row'>
                <PhaseStatusIndicator status={PhaseStatus.NOT_STARTED} />
                <p>Export is not complete.</p>
            </div>;
        }
    };

    return (
        <Dialog open={exportModalOpen} onOpenChange={onExportModalOpenChange}>
            <DialogTrigger>

            </DialogTrigger>
            <DialogContent>
                <DialogTitle>Export Table</DialogTitle>
                <DialogDescription>Table export progress.</DialogDescription>
                <div className='w-full flex flex-col space-y-4'>
                    {renderPreparing()}
                    {renderRendering()}
                    {renderConverting()}
                    {renderReadyToDownload()}
                </div>
            </DialogContent>
        </Dialog>
    );
};


enum PhaseStatus {
    NOT_STARTED,
    IN_PROGRESS,
    COMPLETE,
}

interface PhaseStatusIndicatorProps {
    status: PhaseStatus;
}

const PhaseStatusIndicator: React.FC<PhaseStatusIndicatorProps> = ({ status }) => {


    const spinner = <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={'animate-spin'}
    >
        <path d="M21 12a9 9 0 1 1-6.219-8.56" />
    </svg>;

    let item: JSX.Element;

    if (status === PhaseStatus.NOT_STARTED) {
        item = <div className='bg-muted rounded-full h-[18px] w-[18px] mr-2'>

        </div>;
    } else if (status === PhaseStatus.IN_PROGRESS) {
        item = spinner;
    } else {
        item = <div className='bg-primary text-white flex justify-center items-center rounded-full h-[18px] w-[18px] mr-2'>
            <CheckIcon height={'14px'} width={'14px'} />
        </div>;
    }

    return (
        <div className='flex items-center mr-2'>
            {item}
        </div>
    );
};
