import React, { useContext } from 'react';
import { RightsCodeKey } from '../../../../../../entities/master/PERSON';
import { buildActiveDirectoryURL, buildDocEditURL, buildDocURL } from '../../../../../../_shared/utils/docManagerFunctions';
import UserBundleContext from '../../../../../../context/UserBundleContext';
import { DocProperties, FormInputFlags } from '../../../../../../types/editDoc/generalInfo';
import { Textarea } from '@/ui/ui/textarea';
import { Input, InputWithLabel } from '@/ui/ui/input';
import SelectUser from '@/ui/ui/select-user';
import { SelectSingle } from '@/ui/ui/select';
import { SimpleTooltip } from '@/ui/ui/tooltip';
import { EditDocLockedFieldMessages } from './EditDocLockedFieldMessages';
import { DOCUMENT_LIMITS, DOCUMENT_REVISION_LIMITS } from '@/entities/Limits';
import { GLOBAL_IDENTIFIER } from '@/entities/org/GLOBAL_IDENTIFIER';

type EditDocGeneralFormProps = {
    docURL: string,
    docRevURL: string,
    docID: number,
    docProperties: DocProperties,
    formInputFlags: FormInputFlags,
    // this will ensure that a doc property (K) is updated to a correct value type
    onInputChange<K extends keyof DocProperties>(propertyName: K, value: DocProperties[K]): void,
};

export function EditDocGeneralForm(props: EditDocGeneralFormProps) {
    const context = useContext(UserBundleContext);

    const shortenedDocRevURL = buildDocURL(
        context.organization?.PREFIX ?? '',
        props.docID,
        props.docProperties.revNumber,
        false
    );

    const shortenedDocURL = buildDocURL(
        context.organization?.PREFIX ?? '',
        props.docID,
        undefined,
        false
    );

    const adDocURL = props.docProperties.loginURL ? buildActiveDirectoryURL(props.docProperties.loginURL, shortenedDocURL) : '';
    const adDocRevURL = props.docProperties.loginURL ? buildActiveDirectoryURL(props.docProperties.loginURL, shortenedDocRevURL) : '';

    const newestDocRevURL = props.formInputFlags.includeNewerRevisionLink ? buildDocEditURL(
        context.organization?.PREFIX ?? '',
        props.docID,
        Number(props.docProperties.activeDocRevNo),
        true,
    ) : '';

    const expirationDate = props.docProperties.expirationDate ? new Date(props.docProperties.expirationDate) : null;
    const nextReviewDate = props.docProperties.nextReviewDate ? new Date(props.docProperties.nextReviewDate) : null;

    const docTypePickerOptions = props.docProperties.docTypeOptions.map(docType => ({
        label: docType.DESCRIPTION,
        value: docType.DOCTYPE_ID.toString(),
    }));

    const docTemplatePickerOptions = props.docProperties.docTemplateOptions
        .filter(template => template.DOCTYPE_ID === props.docProperties.docType.DOCTYPE_ID)
        .map(template => ({
            label: template.NAME,
            value: template.TEMPLATEID.toString(),
        }));

    const globalIdentifierPickerOptions = props.docProperties.orgGlobalIDs.map(globalID => ({
        label: globalID.VALUE,
        value: globalID.GLOBAL_IDENTIFIER_ID.toString(),
    }))
    .concat({
        label: 'None',
        value: '-1',
    });

    const createLabel = (label: string) => (<p className='text-xs font-semibold uppercase text-secondary m-0 mb-[5px]'>{label}</p>);
    const gridRowClassName = 'w-full flex flex-row gap-8';

    return (
        <div>
            <div className='flex flex-col gap-2 mt-4'>
                <h2>Document Info</h2>
                <p>Document URL: <a href={props.docURL}>{props.docURL}</a></p>
                {adDocURL && <p>AD Link: <a href={adDocURL}>{adDocURL}</a></p>}
                {
                    props.formInputFlags.isCorporateDoc &&
                    <p>Corporate Revision: Doc ID {props.docProperties.corporateDocID} Rev {props.docProperties.corporateDocRevNo}</p>
                }
            </div>
            
            <br />

            <div className='w-full flex flex-col gap-8'>
                <div className={gridRowClassName}>
                    <SimpleTooltip tooltip={!props.formInputFlags.titleEditable ? EditDocLockedFieldMessages.docTitle : ''}>
                    <div className='w-1/2'>
                        <InputWithLabel
                            label='Title'
                            type='text'
                            onChange={(e) => props.onInputChange('title', e.target.value)}
                            value={props.docProperties.title}
                            disabled={!props.formInputFlags.titleEditable}
                            maxLength={DOCUMENT_LIMITS.TITLE_LENGTH}
                        />
                    </div>
                    </SimpleTooltip>
                    <div className='w-1/2'>
                        <InputWithLabel
                            label='Reference ID'
                            type='text'
                            onChange={(e) => props.onInputChange('sourceReference', e.target.value)}
                            value={props.docProperties.sourceReference}
                            maxLength={DOCUMENT_LIMITS.SOURCE_REFERENCE_LENGTH}
                        />
                    </div>
                </div>
                <div className={gridRowClassName}>
                    <SimpleTooltip tooltip={!props.formInputFlags.isDocOwnerEditable ? EditDocLockedFieldMessages.docOwner : ''}>
                    <div className='w-1/2'>
                        {createLabel('Owner')}
                        <SelectUser
                            className='w-full border-solid border-2 border-gray-200'
                            selectedUser={props.docProperties.docOwner}
                            setSelectedUser={(selectedUser) => selectedUser && props.onInputChange('docOwner', selectedUser)}
                            rights={[RightsCodeKey.Author]}
                            disabled={!props.formInputFlags.isDocOwnerEditable}
                            getMappedUsers
                        />
                    </div>
                    </SimpleTooltip>
                    <SimpleTooltip tooltip={!props.formInputFlags.isDocTypeEditable ? EditDocLockedFieldMessages.docType : ''}>
                    <div className='w-1/2'>
                        {createLabel('Type')}
                        {
                            props.formInputFlags.isDocTypeEditable ?
                            <SelectSingle
                                value={props.docProperties.docType.DOCTYPE_ID.toString()}
                                setValue={(newDocType) => {
                                    props.onInputChange('docType', { ...props.docProperties.docType, DOCTYPE_ID: Number(newDocType) });
                                }}
                                options={docTypePickerOptions}
                            />
                            :
                            <Input
                                value={props.docProperties.docType.DESCRIPTION}
                                type='text'
                                disabled
                            />
                        }
                    </div>
                    </SimpleTooltip>
                </div>
                <div className={gridRowClassName}>
                    <div className='w-1/2 items-center'>
                        <p>{props.docProperties.orgUnitOwnerName}: {props.docProperties.departmentOwner}</p>
                        <p>{props.docProperties.orgUnitName}: {props.docProperties.department}</p>
                    </div>
                    <SimpleTooltip tooltip={!props.formInputFlags.isDocTemplateEditable ? EditDocLockedFieldMessages.docTemplate : ''}>
                    <div className='w-1/2'>
                        {createLabel('Template')}
                        {
                            props.formInputFlags.isDocTemplateEditable ?
                            <SelectSingle
                                value={props.docProperties.template.id.toString()}
                                setValue={(newTemplate) => {
                                    props.onInputChange('template', { ...props.docProperties.template, id: Number(newTemplate) });
                                }}
                                options={docTemplatePickerOptions}
                            />
                            :
                            <Input
                                value={props.docProperties.template.name}
                                type='text'
                                disabled
                            />
                        }
                    </div>
                    </SimpleTooltip>
                </div>
                <div className={gridRowClassName}>
                    <div className='w-1/2'>
                        <InputWithLabel
                            label='Keywords'
                            type='text'
                            onChange={(e) => props.onInputChange('keywords', e.target.value)}
                            value={props.docProperties.keywords}
                        />
                    </div>
                    <SimpleTooltip tooltip={
                        props.formInputFlags.isGlobalIDsAvailable && !props.formInputFlags.globalIDEditable ? EditDocLockedFieldMessages.globalID : ''
                    }>
                    <div className='w-1/2'>
                    {
                        props.formInputFlags.isGlobalIDsAvailable &&
                        <>
                            {createLabel(props.docProperties.orgGlobalIdentifierName)}
                            <SelectSingle
                                value={props.docProperties.docGlobalIdentifier?.GLOBAL_IDENTIFIER_ID.toString() ?? ''}
                                setValue={(newGlobalID) => {
                                    if (!newGlobalID || newGlobalID === '-1') {
                                        return props.onInputChange('docGlobalIdentifier', null);
                                    }

                                    const globalIDObject = props.docProperties.docGlobalIdentifier ? props.docProperties.docGlobalIdentifier : { VALUE: '', STATUS: '' } as GLOBAL_IDENTIFIER;
                                    const selectedGlobalID = props.docProperties.orgGlobalIDs.find(globalID => globalID.GLOBAL_IDENTIFIER_ID === Number(newGlobalID));
                                    if (!selectedGlobalID) {
                                        return;
                                    }

                                    props.onInputChange(
                                        'docGlobalIdentifier',
                                        {
                                            ...globalIDObject,
                                            ...selectedGlobalID,
                                        }
                                    );
                                }}
                                options={globalIdentifierPickerOptions}
                                disabled={!props.formInputFlags.globalIDEditable}
                                placeholder='None'
                            />
                        </>
                    }
                    </div>
                    </SimpleTooltip>
                </div>
            </div>
            
            {
                props.formInputFlags.isMimeTypeMismatch &&
                <p className='w-full mt-4 text-red-500'>
                    File type mismatch: The template's MIME type is {props.docProperties.templateExtension}, but
                    the document content's MIME type is {props.docProperties.fileContentsExtension}.
                </p>
            }

            <br />
            <h2>This Revision</h2>
            <div className='w-full flex flex-col gap-2 mt-2'>
                <p>Document Revision URL: <a href={props.docRevURL}>{props.docRevURL}</a></p>
                {adDocRevURL && <p>AD Document Revision Link: <a href={adDocRevURL}>{adDocRevURL}</a></p>}
                <p>Status: {props.docProperties.docStatus}</p>
                <p>Revision Number: {props.docProperties.revNumber}</p>
                {props.docProperties.editRequestUser && <p>Edit Request started by: {props.docProperties.editRequestUser}</p>}
                {
                    props.formInputFlags.isOfficialDoc && expirationDate &&
                    <p>Expires: {expirationDate.toLocaleDateString()}</p>
                }
                {
                    props.formInputFlags.isOfficialDoc && !expirationDate && nextReviewDate &&
                    <p>Next Review: {nextReviewDate.toLocaleDateString()}</p>
                }
                {
                    newestDocRevURL && 
                    <p>
                        This is an old revision of the document. 
                        To manage the most current revision, click <a href={newestDocRevURL} target="_blank">Current Revision</a>.
                    </p>
                }
            </div>
            <SimpleTooltip tooltip={props.formInputFlags.isOfficialDoc ? EditDocLockedFieldMessages.revisionNote : ''}>
            <div className='w-1/2 mt-4'>
                {createLabel('Revision Notes')}
                {
                    !props.formInputFlags.isOfficialDoc && 
                    <Textarea
                        className='border-solid border border-gray-500'
                        onChange={(e) => props.onInputChange('revisionNote', e.target.value)}
                        value={props.docProperties.revisionNote}
                        maxLength={DOCUMENT_REVISION_LIMITS.REVISION_NOTE_LENGTH}
                        cols={50}
                        rows={5}
                    />
                }
                {
                    props.formInputFlags.isOfficialDoc &&
                    <div className='whitespace-pre-wrap'>
                        {props.docProperties.revisionNote.trim()}
                    </div>
                }
            </div>
            </SimpleTooltip>
        </div>
    );
}