import React from 'react';

interface CommitteePreferencesProps {
    size: number
}

export const CommitteePreferencesIcon: React.FC<CommitteePreferencesProps> = ({ size }) => {
    return (
        <svg width={size.toString()} height={size.toString()} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" rx="4" fill="hsl(var(--primary))" />
            <path d="M7 17V8H17V17H7Z" stroke="white" stroke-width="2" />
            <path d="M6 8.00003V7H18V8.00003H6Z" stroke="white" stroke-width="2" />
            <path d="M9.89201 10.9582C9.8713 10.9194 9.87841 10.8716 9.90954 10.8405L10.3405 10.4095C10.3716 10.3784 10.4194 10.3713 10.4582 10.392L11.1656 10.7693C11.2302 10.8037 11.3105 10.7755 11.3393 10.7082L11.6169 10.0606C11.6326 10.0238 11.6688 10 11.7088 10H12.2912C12.3312 10 12.3674 10.0238 12.3831 10.0606L12.6607 10.7082C12.6895 10.7755 12.7698 10.8037 12.8344 10.7693L13.5418 10.392C13.5806 10.3713 13.6284 10.3784 13.6595 10.4095L14.0905 10.8405C14.1216 10.8716 14.1287 10.9194 14.108 10.9582L13.7307 11.6656C13.6963 11.7302 13.7245 11.8105 13.7918 11.8393L14.4394 12.1169C14.4762 12.1326 14.5 12.1688 14.5 12.2088V12.7912C14.5 12.8312 14.4762 12.8674 14.4394 12.8831L13.7918 13.1607C13.7245 13.1895 13.6963 13.2698 13.7307 13.3344L14.108 14.0418C14.1287 14.0806 14.1216 14.1284 14.0905 14.1595L13.6595 14.5905C13.6284 14.6216 13.5806 14.6287 13.5418 14.608L12.8344 14.2307C12.7698 14.1963 12.6895 14.2245 12.6607 14.2918L12.3831 14.9394C12.3674 14.9762 12.3312 15 12.2912 15H11.7088C11.6688 15 11.6326 14.9762 11.6169 14.9394L11.3393 14.2918C11.3105 14.2245 11.2302 14.1963 11.1656 14.2307L10.4582 14.608C10.4194 14.6287 10.3716 14.6216 10.3405 14.5905L9.90954 14.1595C9.87841 14.1284 9.8713 14.0806 9.89201 14.0418L10.2693 13.3344C10.3037 13.2698 10.2755 13.1895 10.2082 13.1607L9.56061 12.8831C9.52384 12.8674 9.5 12.8312 9.5 12.7912V12.2088C9.5 12.1688 9.52384 12.1326 9.56061 12.1169L10.2082 11.8393C10.2755 11.8105 10.3037 11.7302 10.2693 11.6656L9.89201 10.9582Z" fill="hsl(var(--white))" />
            <path d="M11 12.5C11 11.9477 11.4477 11.5 12 11.5C12.5523 11.5 13 11.9477 13 12.5C13 13.0523 12.5523 13.5 12 13.5C11.4477 13.5 11 13.0523 11 12.5Z" fill="hsl(var(--primary))" />
        </svg>

    );
};