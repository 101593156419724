import { makeStyles, Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import LucidocColors from '../../../constants/LucidocColors';

const useStyles = makeStyles({
    displayFlexAlignCenter: {
        display: 'flex',
        alignItems: 'center'
    },
    helpIcon: {
        color: LucidocColors.gray,
        '&:hover': {
            color: LucidocColors.lightPurple
        }
    },
    customTooltip: {
        backgroundColor: LucidocColors.darkGray,
        fontSize: '.8rem',
    },
    customArrow: {
        color: LucidocColors.darkGray,
    },
    h3Text: {
        fontWeight: 'normal',
        marginLeft: '.5rem'
    }
});

interface H3WithHelpTipProps {
    helpText: string
    text: string
    disabled?: boolean
}

export default function H3WithHelpTip(props: H3WithHelpTipProps) {
    const classes = useStyles();

    return (
        <div className={classes.displayFlexAlignCenter}>
            {props.helpText &&
                <Tooltip
                    title={props.helpText}
                    classes={{
                        tooltip: classes.customTooltip,
                        arrow: classes.customArrow
                    }}
                    enterDelay={0}
                    enterTouchDelay={0}
                    arrow
                >
                    <Info className={classes.helpIcon} />
                </Tooltip>
            }
            <h3
                className={classes.h3Text}
                style={props.disabled ? { color: LucidocColors.gray } : {}}
            >
                {props.text}
            </h3>
        </div>
    );
}
