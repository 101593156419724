import React from 'react';

interface PhoneProps {
    size: number
}

export const PhoneIcon: React.FC<PhoneProps> = ({ size }) => {
    return (
        <svg width={size.toString()} height={size.toString()} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" rx="4" fill="hsl(var(--primary))" />
            <path d="M6.19524 11.3571L6.14972 10.9475C6.05018 10.0517 6.04522 9.14781 6.13491 8.25091L6.26956 6.90444C6.28748 6.72523 6.42439 6.58086 6.6024 6.55348L9.04794 6.17724C9.6537 6.08405 10.2 6.55273 10.2 7.16561V9.57682C10.2 9.5903 10.1905 9.6019 10.1773 9.60455L9.4673 9.74654C9.02115 9.83577 8.7 10.2275 8.7 10.6825C8.7 10.7605 8.70957 10.8383 8.7285 10.914L8.8687 11.4748C8.95597 11.8239 9.08118 12.1624 9.2421 12.4842C9.47915 12.9583 9.79108 13.3911 10.1659 13.7659L10.2 13.8L10.2341 13.8341C10.6089 14.2089 11.0417 14.5209 11.5158 14.7579C11.8376 14.9188 12.1761 15.044 12.5252 15.1313L13.086 15.2715C13.1617 15.2904 13.2395 15.3 13.3175 15.3C13.7725 15.3 14.1642 14.9789 14.2535 14.5327L14.3955 13.8227C14.3981 13.8095 14.4097 13.8 14.4232 13.8H16.5H16.9201C17.5026 13.8 17.9619 14.2959 17.9172 14.8767L17.729 17.3232C17.7123 17.5407 17.5409 17.7145 17.3236 17.7342L15.7204 17.88C14.8424 17.9598 13.9585 17.9509 13.0822 17.8536L12.6429 17.8048C12.016 17.7351 11.3983 17.5994 10.8 17.4L10.7231 17.3744C10.1768 17.1923 9.65723 16.9382 9.17811 16.6187C8.46642 16.1443 7.85572 15.5336 7.38126 14.8219C7.06185 14.3428 6.80773 13.8232 6.62564 13.2769L6.6 13.2C6.40056 12.6017 6.26488 11.984 6.19524 11.3571Z" fill="hsl(var(--white))" stroke="hsl(var(--white))" />
        </svg>

    );
};