import { QueryParams, useBrowserQuery } from '@/hooks/useBrowserQuery';
import { Button } from '@/ui/ui/button';
import { DialogContent, Dialog, DialogTrigger, DialogHeader, DialogTitle, DialogDescription } from '@/ui/ui/dialog';
import axios from 'axios';
import React from 'react';

interface ResetPreferencesDialogProps {
    mode: 'organization' | 'user';
}

interface ResetPreferencesQuery extends QueryParams {
    resetPreferencesDialogOpen?: boolean
}


export const ResetPreferencesDialog: React.FC<ResetPreferencesDialogProps> = ({ mode }) => {
    const [query, setQuery] = useBrowserQuery<ResetPreferencesQuery>();

    const resetPrefs = () => {
        axios.post('/api/preferences/update', {
            mode: mode,
            delete: true
        });
        setQuery({ ...query, resetPreferencesDialogOpen: false });
        window.location.reload();
    };

    const preferencesText = mode === 'organization' ? 'Clicking "Reset" will reset your organization\'s default preferences to the default preferences defined by Lucidoc.' : 'Clicking "Reset" will reset your preferences to your organization\'s defaults.';

    return (
        <Dialog open={query.resetPreferencesDialogOpen} onOpenChange={o => setQuery({ ...query, resetPreferencesDialogOpen: o })}>
            <DialogTrigger asChild>
                <Button variant={'secondary'} size={'md'} className='ml-2'
                    onClick={(e) => {
                        e.preventDefault();
                        setQuery({ ...query, resetPreferencesDialogOpen: !query.resetPreferencesDialogOpen });
                    }}>Reset</Button>
            </DialogTrigger>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Reset Preferences</DialogTitle>
                    <DialogDescription>{preferencesText}</DialogDescription>
                </DialogHeader>
                <div className='flex flex-row w-full justify-end space-x-2'>
                    <Button variant={'destructive'} onClick={() => resetPrefs()}>
                        Reset
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
};