import { Sidebar2 } from '@/components/Sidebar/Sidebar2';
import UserBundleContext from '@/context/UserBundleContext';
import React, { useContext, useEffect } from 'react';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { Homepage2 } from './pages/homepage/Homepage2';
import { Search2 } from './pages/search/Search2';
import Header from './pages/header/Header';
import { PreferencesEditor } from './pages/preferences/Preferences';
import { Training2 } from './pages/training/Training2';
import { ThemeProvider } from '@/context/ThemeProvder';
import { WhatsNew } from './pages/whatsnew/WhatsNew';
import { Committees } from './pages/committees/Committees';

interface UIV2RouterProps {

}

export const UIV2Router: React.FC<UIV2RouterProps> = () => {

    const userBundle = useContext(UserBundleContext);

    const { prefix } = useParams<{ prefix: string }>();

    const { url } = useRouteMatch();

    useEffect(() => {
        if (userBundle.organization?.PREFIX !== prefix) {
            window.location.href = '/cgi/login.pl';
        }
    }, [prefix]);

    return (
        <ThemeProvider defaultTheme={userBundle.preferences.theme}>
            {/* dvh is dynamic vh, it is for browsers that have a bottom dock or top bar that takes up space.
                vh doesn't account for those, but dvh does.
            */}
            <div className='flex flex-col h-[100dvh] bg-background'>
                <Header />
                <div className='flex flex-col-reverse xs:flex-row flex-1 overflow-hidden'>
                    <Sidebar2 />
                    <div className='flex-grow overflow-y-auto'>
                        <Switch>
                            <Route path={`${url}/dashboard`} component={Homepage2} />
                            <Route path={`${url}/search`} component={Search2} />
                            <Route path={`${url}/training`} component={Training2} />
                            <Route path={`${url}/whatsnew`} component={WhatsNew} />
                            <Route path={`${url}/committees`} component={Committees} />
                            <Route path={`${url}/preferences`} render={() => {
                                return <PreferencesEditor mode='user' />;
                            }} />
                            <Route component={() => <p className='text-xl m-3 font-bold'>404</p>} />
                        </Switch>
                    </div>
                </div>
            </div>
        </ThemeProvider>
    );
};