import { INFORMATION_HIERARCHY } from '../entities/org/INFORMATION_HIERARCHY';

/**
 * This function is extremely specific; it takes an array of NESTED departments (produced by
 * the back-end function getNestedInformationHierarchy), then flattens them out back into a
 * regular, NON-nested array, but with the DEPTH of each department displayed (by hyphens),
 * so it can be inserted into a Select menu (or DataTable) with a visual indicator of which
 * department belongs to which parent department.
 *
 * In other words, it will take this (potentially) infinitely-nested array:
 *
 * [
 *     {
 *         name: 'Some Department Name',
 *         children: [                      <-- array INFORMATION_HIERARCHY objects (AKA departments)
 *             {
 *                 name: 'Child Department',
 *                 children: []             <-- array INFORMATION_HIERARCHY objects (AKA departments)
 *             }
 *         ]
 *     },
 *     {
 *         name: 'Some Other Department',
 *         children: []                     <-- array INFORMATION_HIERARCHY objects (AKA departments)
 *     }
 * ]
 *
 * And convert it into this flattened array (or an INFORMATION_HIERARCHY[] if you set returnAsInfoHierarchyArray as true):
 *
 * [
 *     {
 *         label: 'Some Department Name',
 *         actualLabelWithoutWhitespace: 'Some Department Name',
 *         value: <departmentID>
 *     },
 *     {
 *         label: '-Some Name', <-- Notice the hyphen in the name
 *         actualLabelWithoutWhitespace: 'Some Name',
 *         value: <departmentID>
 *     },
 *     {
 *         label: '--Some Other Name', <-- Notice the DOUBLE hyphen in the name
 *         actualLabelWithoutWhitespace: 'Some Other Name',
 *         value: <departmentID>
 *     },
 * ]
 *
 * So that it will display in the select menu (or DataTable) like this:
 *
 * Parent Department
 * -Some child department
 * -Some other child department
 * --Grandchild department
 * --Another grandchild department
 * -Child department
 * Another Parent Department
 * -Child department
 *
 * This function ONLY flattens the array, and inserts the hyphen for each level down from the
 * top-level parent; it RELIES on the fact that getNestedInformationHierarchy returns a list that
 * is already alphabetized, and returns either ALL departments, or all departments of a given PublisherID.
 */

export default function produceFlattenedOptionsFromNestedInformationHierarchy(
    departments: INFORMATION_HIERARCHY[],
    flattenedOptions: any[]=[],
    depthSoFar: number=0,
    returnAsInfoHierarchyArray?: boolean, // otherwise it'll return as flattenedInformationHierarchySelectMenuOption[]
    spacer: string='-' // replace with spaces, asterisks, etc
) {
    departments.forEach(department => {
        const spaces = spacer.repeat(depthSoFar);

        if (returnAsInfoHierarchyArray) { // produces flattened array with the intention of being displayed in a DataTable
            (flattenedOptions as any[]).push({
                ...department,
                TITLE: `${spaces + department.TITLE}`
            });
        }
        else { // produces flattened array with the intention of being displayed in a ReactSelect
            (flattenedOptions as any[]).push({
                value: department.INFORMATION_HIERARCHY_ID,
                label: `${spaces + department.TITLE}`,
                actualLabelWithoutWhitespace: department.TITLE,
            });
        }

        if (department.CHILDREN) {
            produceFlattenedOptionsFromNestedInformationHierarchy(
                department.CHILDREN,
                flattenedOptions,
                depthSoFar + 1,
                returnAsInfoHierarchyArray,
                spacer
            );
        }
    });
    return flattenedOptions;
}

export interface flattenedInformationHierarchySelectMenuOption {
    value: number, // the database ID
    label: string, // the text you will see
    actualLabelWithoutWhitespace?: string // some values look like this: "--Departments" (to show hierarchy);
    // this "actual" label is what the text should eventually be (aka "Department") and is used if needed,
    // and ignored if not (in which case just "label" is used).
}
