import React from 'react';

interface CalendarShadowIconProps {
    size: number;
}

export const CalendarShadowIcon: React.FC<CalendarShadowIconProps> = ({ size }) => {

    return (
        <svg width={size.toString()} height={size.toString()} viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.52222 2.49975L3.5 2.47802V1.5H4V1.55556V2.55556H5H11H12V1.55556V1.5H12.5V1.55556V2.55556H13.5H14C14.5523 2.55556 15 3.00327 15 3.55556V12C15 12.5523 14.5523 13 14 13H2C1.44772 13 1 12.5523 1 12V3.5116C1 2.96798 1.43429 2.52393 1.97778 2.51185L2.52222 2.49975Z" stroke="hsl(var(--muted-foreground))" stroke-width="2"/>
        </svg>
    );
};  