import {
    Column,
    Entity,
    OneToMany,
    PrimaryColumn,
} from 'typeorm';
import { ACL,  } from './ACL';
import { nullableStringToBooleanTransformer } from '../_helperFunctions/transformers';
import { INFORMATION_HIERARCHY,  } from './INFORMATION_HIERARCHY';
import { SchemaEntityManager } from '../SchemaEntityManager';

// Define the enum outside the factory function to ensure it's globally accessible
export enum PublisherStatuses {
    active = 'active',
    archived = 'archived',
}

// Define the factory function
function createPublisherEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('PUBLISHER', { schema: schema })
    class PUBLISHER {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'PUBLISHER_ID'
        })
        PUBLISHER_ID!: number;

        @Column('varchar2', {
            nullable: false,
            length: 40,
            name: 'TITLE'
        })
        TITLE!: string;

        @Column('varchar2', {
            nullable: false,
            length: 90,
            name: 'DESCRIPTION'
        })
        DESCRIPTION!: string;

        @Column('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'USERID'
        })
        USERID!: number;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'PUBLISHER_PARENTID'
        })
        PUBLISHER_PARENTID!: number | null;

        @Column('varchar2', {
            nullable: true,
            length: 400,
            name: 'NOTE'
        })
        NOTE!: string | null;

        @Column('varchar2', {
            nullable: true,
            length: 200,
            name: 'COLLIST'
        })
        COLLIST!: string | null;

        @Column('varchar2', {
            nullable: true,
            length: 32,
            name: 'DEFAULTSORT'
        })
        DEFAULTSORT!: string | null;

        @Column('char', {
            nullable: true,
            name: 'ISCATEGORY',
            transformer: nullableStringToBooleanTransformer
        })
        ISCATEGORY!: string | null;

        @Column('varchar2', {
            nullable: true,
            length: 30,
            name: 'HEADERCODE'
        })
        HEADERCODE!: string | null;

        @Column('number', {
            nullable: true,
            precision: 3,
            scale: 0,
            name: 'ACCESSLEVEL'
        })
        ACCESSLEVEL!: number | null;

        @Column('char', {
            nullable: true,
            name: 'VIEWCONTROL',
            transformer: nullableStringToBooleanTransformer
        })
        VIEWCONTROL!: boolean;

        @Column('varchar2', {
            nullable: false,
            length: 10,
            default: () => '\'active\'',
            name: 'STATUS'
        })
        STATUS!: string;

        @OneToMany(
            () => manager.getAclEntity(schema), 
            (acl) => acl.PUBLISHER
        )
        ACL!: ACL[] | null;

        @OneToMany(
            () => manager.getInformationHierarchyEntity(schema), 
            (informationHierarchy) => informationHierarchy.PUBLISHER
        )
        MANUALS!: INFORMATION_HIERARCHY[] | null;

    }

    return PUBLISHER;
}

// Export the factory function
export { createPublisherEntity };

// Export the type representing an instance of the class generated by the factory function
export type PUBLISHER = InstanceType<ReturnType<typeof createPublisherEntity>>;
