import { DisplayStatuses, DOCUMENTREVISION } from '../../../../entities/org/DOCUMENTREVISION';
import { MUIDataTableProps } from 'mui-datatables';
import { createSortableDate } from '../../../../_shared/utils/docManagerFunctions';
import { constructFullName } from '@/components/UserPicker/utils/constructFullName';

export type DocRevStandardValueStrings =
      'DocID'
    | 'Rev #'
    | 'Title'
    | 'Status'
    | 'DocType'
    | 'Owner'
    | 'Department'
    | 'Organization'
    | 'Prefix'
    | 'Snippet'
    | 'Effective/Official Date'
    | 'Source Revision'
    | 'Official Date'
    | 'Effective Date'
;

type DocRevStandardValuesObject = {
    DocID:                      number,
    'Rev #'?:                   string,
    Title?:                     string,
    Status?:                    string,
    DocType?:                   string,
    Owner?:                     string,
    Department?:                string,
    Organization?:              string,
    Prefix?:                    string,
    Snippet?:                   string,
    'Effective/Official Date'?: string,
    'Source Revision'?:         string,
    'Official Date'?:           string,
    'Effective Date'?:          string
    // adding a value? add to DocRevStandardValueStrings also. not sure of a good
    // TypeScript way to do the rigidity of [keyof: something] with a type instead
    // of a const
}

export function transformDocRevsToDataTableValues(
    docrevs:           DOCUMENTREVISION[],
    columnsToExclude?: DocRevStandardValueStrings[],
    columnsToAdd?:     DocRevStandardValueStrings[]
)
    : MUIDataTableProps['data']
{
    const columnsToExcludeHashed = columnsToExclude?.reduce((acc: {[key: string]: true}, column) => {
        acc[column] = true;
        return acc;
    }, {}) || {};

    const columnsToAddHashed = columnsToAdd?.reduce((acc: {[key: string]: true}, column) => {
        acc[column] = true;
        return acc;
    }, {}) || {};

    return docrevs.map(docrev => {
        const returnObj: DocRevStandardValuesObject = {
            // DO NOT MOVE THESE OUT OF POSITION. INDEX POSITION IS VERY IMPORTANT IN THE
            // DATATABLE COMPONENT. If you want to hide it, use display: false, filter: false:
            DocID: docrev.DOCID,
            'Rev #': docrev.REVNO,
        };

        // these columns can be made optional if necessary with columnsToExclude
        returnObj.Title   = docrev.TITLE || '';
        returnObj.Status  = DisplayStatuses[docrev.DOCSTATUS_ID];
        returnObj.DocType = docrev.DOCTYPE?.DESCRIPTION || '';
        returnObj.Owner   = docrev.DOCUMENT.DOCOWNER ? constructFullName(docrev.DOCUMENT.DOCOWNER, 'lastFirstMiddle') : '';

        if (!columnsToExcludeHashed.Department) {
            returnObj.Department = docrev.DOCUMENT.INFORMATION_HIERARCHY?.TITLE;
        }

        if (columnsToAddHashed.Organization) {
            returnObj.Organization = docrev.ORGANIZATION?.NAME || '';
        }
        if (columnsToAddHashed.Prefix) {
            returnObj.Prefix = docrev.ORGANIZATION?.PREFIX || '';
        }

        if (columnsToAddHashed['Effective/Official Date']) {
            if (docrev.EFFECTIVEDT) {
                returnObj['Effective/Official Date'] = createSortableDate(new Date(docrev.EFFECTIVEDT));
            } else if (docrev.OFFICIALDT) {
                returnObj['Effective/Official Date'] = createSortableDate(new Date(docrev.OFFICIALDT));
            } else {
                returnObj['Effective/Official Date'] = '-';
            }
        }

        if (columnsToAddHashed['Source Revision']) {
            returnObj['Source Revision'] = docrev.DOCUMENT.SOURCE_REFERENCE || 'N/A'; // legacy doc ID
        }

        if (columnsToAddHashed['Official Date']) {
            returnObj['Official Date'] = (
                docrev.OFFICIALDT ? createSortableDate(new Date(docrev.OFFICIALDT)) : '-'
            );
        }

        if (columnsToAddHashed['Effective Date']) {
            returnObj['Effective Date'] = (
                docrev.EFFECTIVEDT ? createSortableDate(new Date(docrev.EFFECTIVEDT)) : '-'
            );
        }

        // probably should leave snippets last
        if (columnsToAddHashed.Snippet) {
            returnObj.Snippet = (docrev as any).SNIPPET || '';
        }

        return returnObj;
    });
}
