import { INFORMATION_HIERARCHY } from '../entities/org/INFORMATION_HIERARCHY';

// Used in combination with getNestedInformationHierarchy, since you need a recursive search
export function findNestedIH(
    IHs: INFORMATION_HIERARCHY[] | null,
    ihID: number
)
    : INFORMATION_HIERARCHY | undefined
{
    if (!IHs) return;

    for (const ih of IHs) {
        if (ih.INFORMATION_HIERARCHY_ID === ihID) return ih;
        const foundIH = findNestedIH(ih.CHILDREN, ihID);
        if (foundIH) return foundIH;
    }
}
