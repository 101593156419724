import React from 'react';
import { DataTableToolbarProps } from '@/ui/ui/datatable/data-table';
import { DataTableFacetedFilter } from '@/ui/ui/datatable/datatable-faceted-filter';
import { DataTableViewOptions } from '@/ui/ui/datatable/view-options';
import { ConsentAgendaEntry } from './MyMeetingsTable';
import { CalendarShadowIcon } from '@/ui/icons/shadow/CalendarShadow';

const ArchivedAgendasTableToolbar: React.FC<DataTableToolbarProps<ConsentAgendaEntry>> = ({ table, datatableRef }) => {

    return (
        <div className="flex items-center justify-between my-4">
            <div className="flex flex-1 items-center space-x-2">
                <DataTableFacetedFilter
                    column={table.getColumn('year')}
                    title="Year"
                    icon={<CalendarShadowIcon size={16} />}
                />
            </div>
            <DataTableViewOptions
                datatableRef={datatableRef}
                table={table}
                noExtraHeaders
            />
        </div>
    );
};

export default ArchivedAgendasTableToolbar;
