import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, makeStyles } from '@material-ui/core';
import { ORGANIZATION } from '../../../../entities/master/ORGANIZATION';
import LucidocColors from '../../../../constants/LucidocColors';
import InputFeature from './InputFeature';
import InputOrganizations from './InputOrganizations';
import StagedChanges from './StagedChanges';

const useStyles = makeStyles({
    dialogPaper: {
        minWidth: '50%',
        minHeight: '75%',
    }
});

type FeatureFlagModalProps = {
    open: boolean
    title: string
    organizations: ORGANIZATION[]
    onSave?: (formData: FormData) => void
    onClose: () => void
};

type Pages = 'INPUT_FEATURE' | 'INPUT_ORGS' | 'CONFIRM';

export default function ChangeFeatureModal(props: FeatureFlagModalProps) {
    const [ page, setPage ] = useState<Pages>('INPUT_FEATURE');
    const [ feature, setFeature ] = useState<string>('');
    const [ selectedOrganizations, setSelectedOrganizations ] = useState<number[]>([]);

    const classes = useStyles();

    useEffect(() => {
        if (props.open) {
            setPage('INPUT_FEATURE');
            setFeature('');
            setSelectedOrganizations([]);
        }
    }, [props.open]);

    const incrementPage = () => {
        if (page === 'INPUT_FEATURE') {
            setPage('INPUT_ORGS');
        } else if (page === 'INPUT_ORGS') {
            setPage('CONFIRM');
        }
    };

    const decrementPage = () => {
        if (page === 'INPUT_ORGS') {
            setPage('INPUT_FEATURE');
        } else if (page === 'CONFIRM') {
            setPage('INPUT_ORGS');
        }
    };

    const handleSave = () => {
        const formData = new FormData();
        formData.append('feature', feature);
        formData.append('ids', selectedOrganizations.join(','));

        props.onSave?.(formData);
        props.onClose();
    };

    const continueButtonDisabled = page === 'INPUT_FEATURE' && feature === '';

    return (
        <Dialog
            open={props.open}
            onClose={() => props.onClose()}
            color={LucidocColors.lightGray}
            classes={{
                paper: classes.dialogPaper
            }}
        >
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>
                <InputFeature
                    value={feature}
                    onChange={setFeature}
                    isVisible={page === 'INPUT_FEATURE'}
                />
                <InputOrganizations
                    organizations={props.organizations}
                    selectedOrganizations={selectedOrganizations}
                    onChange={setSelectedOrganizations}
                    isVisible={page === 'INPUT_ORGS'}
                />
                <StagedChanges
                    isVisible={page === 'CONFIRM'}
                    feature={feature}
                    selectedOrganizationIDs={selectedOrganizations}
                    organizations={props.organizations}
                />
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={() => props.onClose()}>Cancel</Button>
                {page !== 'INPUT_FEATURE' && <Button variant='contained' onClick={() => decrementPage()}>Back</Button>}
                {page !== 'CONFIRM' && <Button variant='contained' disabled={continueButtonDisabled} onClick={() => incrementPage()}>Continue</Button>}
                {page === 'CONFIRM' && props.onSave && <Button variant='contained' onClick={() => handleSave()}>Submit</Button>}
            </DialogActions>
        </Dialog>
    );
}