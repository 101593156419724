import React, { useContext, useRef, useState } from 'react';
import axios from 'axios';
import { TextField } from '@material-ui/core';
import { DOCUMENTREVISION } from '../../../../../../entities/org/DOCUMENTREVISION';
import { Button } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import UserBundleContext from '../../../../../../context/UserBundleContext';
import { mobileViewBreakpointSmall } from '../../../../../Header/HeaderSharedStyles';
import { makeStyles } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

type SingularDocumentInputFormProps = {
    onEdit: (docrev: DOCUMENTREVISION) => void;
};

const searchBarStyles = {
    searchInput: {
        fontSize: '.9rem',
        width: 240,
        backgroundColor: 'white',
        paddingRight: '1.5rem',
        [`@media (max-width:${mobileViewBreakpointSmall}px)`]: {
            width: 160,
        }
    },
    docInputContainer: {
        display: 'flex' as CSSProperties['display'],
        flexDirection: 'row' as CSSProperties['flexDirection'],
        gap: '5px',
        marginBottom: '1rem',
    },
    docReviewInfoContainer: {
        display: 'flex' as CSSProperties['display'],
        flexDirection: 'column' as CSSProperties['flexDirection'],
        gap: '10px',
        marginBottom: '1rem',
    },
};

const useStyles = makeStyles({
    ...searchBarStyles
});

export function SingularDocumentInputForm(props: SingularDocumentInputFormProps) {
    const classes = useStyles();

    const docIDInputRef = useRef<HTMLInputElement>(null);
    const [docrev, setDocrev] = useState<DOCUMENTREVISION | null>(null);

    const context = useContext(UserBundleContext);
    const orgID = context.organization?.ORGANIZATIONID || -1;

    async function getOfficialRevisionFromDocID() {
        const docID = docIDInputRef.current?.value ?? -1;
        if (docID === -1) {
            return;
        }

        setDocrev(null);
        axios.get('/api/administration/documents/update-docrev-review-metadata/revision', {
            params: {
                docID
            }
        })
        .then(res => {
            setDocrev(res.data.docrev);
        })
        .catch(err => {
            alert(`Error fetching official revision for Doc ID: ${docID} - ${err}`);
        });
    }

    return (
        <div>
            <div className={classes.docInputContainer}>
                <TextField
                    label={'Doc ID'}
                    placeholder={'Select a Doc ID...'}
                    variant={'outlined'}
                    size={'small'}
                    style={{ marginRight: '1rem' }}
                    InputProps={{
                        className: classes.searchInput,
                        autoComplete: 'off',
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    type='number'
                    inputRef={docIDInputRef}
                />

                <Button
                    disabled={!orgID}
                    startIcon={<Search />}
                    variant={'contained'}
                    onClick={() => getOfficialRevisionFromDocID()}
                >
                    Select Document
                </Button>
            </div>

            {
                docrev &&
                <div className={classes.docReviewInfoContainer}>
                    <h4>Offical Document Revision:</h4>
                    <p>{docrev.TITLE}</p>
                    <p>Revision Number: {docrev.REVNO}</p>
                    <Button
                        disabled={!orgID}
                        variant={'contained'}
                        onClick={e => {
                            e.preventDefault();

                            // deep copy to prevent mutation of the original object
                            const clonedDocRev = JSON.parse(JSON.stringify(docrev)) as DOCUMENTREVISION;
                            props.onEdit(clonedDocRev);
                        }}
                    >
                        Display Document Info
                    </Button>
                </div>
            }
        </div>
    );
}
