import { RightsCodeKey, rightsCodes } from '../../master/PERSON';



/**
 * Takes one of these rights strings, stored in the RIGHTS column in the database:
 *
 * '#'!{'"   /'                  .  '
 *
 * and turns it into one of these objects:
 *
 * {
 *     reader: false,
 *     signer: false,
 *     author: false,
 *     courseWriter: false,
 *     documentAdministrator: false,
 *     userAdministrator: false,
 *     organizationAdministrator: false,
 *     systemAdministrator: false
 * }
 *
 * used in (PERSON | PERSONORGANIZATION).getRightsInEnglish()
 *
 * @param rightsString
 */

export function produceRightsObjectFromString(
    rightsString: string | null | undefined
): { [key in RightsCodeKey]: boolean } {
    const rightsObject: { [key in RightsCodeKey]: boolean } = {
        reader: false,
        signer: false,
        author: false,
        courseWriter: false,
        documentAdministrator: false,
        userAdministrator: false,
        organizationAdministrator: false,
        systemAdministrator: false
    };

    if (rightsString) {
        for (const key in rightsCodes) {
            const rightsCode = rightsCodes[key as RightsCodeKey];
            if ((rightsString.charCodeAt(rightsCode.idx) & rightsCode.bit) > 0) {
                rightsObject[key as RightsCodeKey] = true;
            }
        }
    }

    return rightsObject;
}

