import { Cell, ColumnDef } from '@tanstack/react-table';
import { DocRevEntry } from './DocumentRevisionTable';
import { TableExpander } from '@/ui/ui/datatable/table-expander';
import { DocumentsDataTableExpansionCell } from './DocumentsDataTableExpansionCell';
import { DataTableColumnHeader } from '@/ui/ui/datatable/column-header';
import { buildDocURL } from '@/_shared/utils/docManagerFunctions';
import UserBundleContext, { UserBundleType } from '@/context/UserBundleContext';
import { useContext } from 'react';
import { decodeUTF8String } from './utils';
import { DisplayStatuses, DocumentRevisionStatuses } from '@/entities/org/DOCUMENTREVISION';

export const useDocumentRevisionTableColumns = () => {
    const userBundle = useContext(UserBundleContext);

    const columns: ColumnDef<DocRevEntry>[] = [
        {
            id: 'expander',
            accessorKey: 'statusId',
            enableSorting: false,
            header: ({ table }) => {
                return (
                    <TableExpander table={table} />
                );
            },
            cell: ({ cell, row }) => {
                return <DocumentsDataTableExpansionCell docStatusId={cell.getValue<number>()} row={row} />;
            },
            meta: {
                label: 'Expand/Collapse Controls',
                skipExport: true
            }
        },
        {
            id: 'title',
            accessorKey: 'title',
            header: ({ column }) => {
                return (
                    <DataTableColumnHeader column={column} title='Title' />
                );
            },
            meta: {
                label: 'Title',
            },
            cell: ({ cell }) => {
                const prefix = cell.row.original.orgPrefix ?? userBundle.organization!.PREFIX!;
                return <div>
                    <p
                        onClick={() => {
                            window.open(
                                buildDocURL(prefix,
                                    parseInt(cell.row.getValue('id')),
                                    parseInt(cell.row.getValue('revNo')),
                                    true)
                            );
                        }}

                        className='inline-block text-secondary whitespace-break-spaces font-medium text-left px-2 p-1.5 hover:bg-accent  hover:cursor-pointer rounded-sm'>
                        {decodeUTF8String(cell.getValue<string>())}
                    </p>
                </div>;
            }
        },
        {
            id: 'id',
            accessorKey: 'id',
            enableSorting: false,
            header: ({ column }) => {
                return (
                    <DataTableColumnHeader column={column} title='DOCID' />
                );
            },
            cell: ({ cell }) => {
                return <code className='text-secondary'>{cell.getValue<string>()}</code>;
            },
            meta: {
                label: 'DOCID',
                exportValue: (cell: Cell<DocRevEntry, unknown>) => {
                    return `<a href="${buildDocURL(userBundle.organization!.PREFIX!, parseInt(cell.getValue<string>()), undefined, true)}">${cell.getValue<string>()}</a>`;
                },
            },

        },
        {
            id: 'revNo',
            accessorKey: 'revNo',
            enableSorting: false,
            header: ({ column }) => {
                return (
                    <DataTableColumnHeader column={column} title='RevNo' />
                );
            },
            cell: ({ cell }) => {
                return <code className='text-secondary'>{cell.getValue<string>()}</code>;
            },
            meta: {
                label: 'RevNo'
            }
        },

        {
            id: 'status',
            accessorKey: 'status',
            enableSorting: false,
            enableColumnFilter: true,
            header: ({ column }) => {
                return (
                    <DataTableColumnHeader column={column} title='Status' />
                );
            },
            filterFn: (row, id, value) => {
                return value.includes(row.getValue(id));
            },
            cell: ({ cell }) => {
                return <p>{cell.getValue<string>()}</p>;
            },
            meta: {
                label: 'Status'
            }
        },
        {
            accessorKey: 'docType',
            enableSorting: true,
            header: ({ column }) => {
                return (
                    <DataTableColumnHeader column={column} title='Document Type' />
                );
            },
            filterFn: (row, id, value) => {
                return value.includes(row.getValue(id));
            },
            meta: {
                label: 'Document Type'
            },
            cell: ({ cell }) => {
                return <p>{cell.getValue<string>()}</p>;
            }
        },
        {
            accessorKey: 'owner',
            enableSorting: true,
            header: ({ column }) => {
                return (
                    <DataTableColumnHeader column={column} title='Owner' />
                );
            },
            filterFn: (row, id, value) => {
                return value.includes(row.getValue(id));
            },
            meta: {
                label: 'Owner'
            },
            cell: ({ cell }) => {
                return <p className='text-secondary'>{cell.getValue<string>()}</p>;
            }
        },
        {
            id: 'officialDate',
            accessorKey: 'officialDate',
            enableSorting: true,
            cell: ({ cell }) => {

                return <p className='text-secondary'>{cell.getValue<Date>()?.toDateString() ?? '-'}</p>;
            },
            header: ({ column }) => {
                return (
                    <DataTableColumnHeader column={column} title='Official Date' />
                );
            },
            meta: {
                label: 'Official Date',
                exportValue: (cell: Cell<DocRevEntry, unknown>) => {
                    const value = cell.getValue<Date>();
                    if (value) {
                        return value.toDateString();
                    }

                    return '-- -- --';
                },
            }
        }
    ];

    return columns;
};

export default function fixOfficialDocumentLinks(userBundle: UserBundleType, columns: ColumnDef<DocRevEntry>[]): ColumnDef<DocRevEntry>[] {
    // Official documents should link to the latest official revision
    const idx = columns.findIndex(column => column.id === 'title');
    if (idx !== -1) {
        columns[idx].cell = ({ cell }) => {
            const prefix = cell.row.original.orgPrefix ?? userBundle.organization!.PREFIX!;
            return <div>
                <p
                    onClick={() => {
                        const isOfficial = cell.row.getValue('status') === DisplayStatuses[DocumentRevisionStatuses.official];
                        window.open(
                            buildDocURL(prefix,
                                parseInt(cell.row.getValue('id')),
                                isOfficial ? undefined : parseInt(cell.row.getValue('revNo')),
                                true)
                        );
                    }}

                    className='inline-block text-secondary whitespace-break-spaces font-medium text-left px-2 p-1.5 hover:bg-accent  hover:cursor-pointer rounded-sm'>
                    {decodeUTF8String(cell.getValue<string>())}
                </p>
            </div>;
        };
    }

    return columns;
}
