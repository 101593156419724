import React, { useContext, useEffect, useState } from 'react';
import { LoadingIndicator } from '../../../../_shared/LoadingIndicator';
import DataTable from '../../../../_shared/DataTable/DataTable';
import { MUIDataTableColumn, MUIDataTableProps } from 'mui-datatables';
import axios from 'axios';
import { DisplayStatuses, DOCUMENTREVISION } from '../../../../../entities/org/DOCUMENTREVISION';
import UserBundleContext from '../../../../../context/UserBundleContext';
import { ClickableTitleCell } from '../../../../_shared/DataTable/ClickableTitleCell';
import { TableCell, TableRow } from '@material-ui/core';
import { FacilityImplementationByOrg } from './FacilityImplementationByOrg';
import { LoadingStatuses } from '../../../../../utils/LoadingStatuses';
import { constructFullName } from '@/components/UserPicker/utils/constructFullName';

interface FacilityImplementationSystemWideProps {
    getActiveDocsOnly: boolean
    getLatestRevisionsOnly: boolean
}

export function FacilityImplementationSystemWide(props: FacilityImplementationSystemWideProps) {
    const [ loadingStatus, setLoadingStatus ] = useState(LoadingStatuses.LOADING);
    const [ tableData, setTableData ] = useState<MUIDataTableProps['data']>([]);

    const context = useContext(UserBundleContext);

    useEffect(() => {
        loadInitialData().then();
    }, [
        props.getActiveDocsOnly,
        props.getLatestRevisionsOnly
    ]);

    async function loadInitialData() {
        setLoadingStatus(LoadingStatuses.LOADING);

        const res = await axios.get('/api/doc-manager/multiorg/facility-implementation/get-corporate-docs', {
            params: {
                getActiveDocsOnly:      props.getActiveDocsOnly,
                getLatestRevisionsOnly: props.getLatestRevisionsOnly
            }
        });

        const formattedTableData = ((res.data.corporateDocs || []) as DOCUMENTREVISION[]).map(docrev => {
            const returnObj = {
                DocRevID: docrev.DOCREVID,
                Title: docrev.TITLE,
                HiddenTitle: docrev.TITLE, // because regular Title will be converted into a clickable <a> tag React element, but we want the regular string to pass to a child component
                DocID: docrev.DOCID,
                'Rev#': docrev.REVNO,

                'Impl. Rate': res.data.adoptionRatesByDocRevIDs[docrev.DOCREVID] + '%',

                DocType: docrev.DOCTYPE?.DESCRIPTION,

                [context.organization?.ORGUNITNAME || 'Department']: docrev.DOCUMENT?.INFORMATION_HIERARCHY?.TITLE,
                Owner: docrev.DOCUMENT?.DOCOWNER ? constructFullName(docrev.DOCUMENT?.DOCOWNER, 'lastFirstMiddle') : '',

                [context.organization?.GLOBAL_IDENTIFIER_NAME || 'Global Identifier']: docrev.GLOBAL_IDENTIFIER?.VALUE,
            };

            if (!props.getActiveDocsOnly || !props.getLatestRevisionsOnly) {
                returnObj.Status = DisplayStatuses[docrev.DOCSTATUS_ID];
            }

            return returnObj;
        });

        setTableData(formattedTableData);
        setLoadingStatus(LoadingStatuses.READY);
    }

    return (
        <div>
            {loadingStatus !== LoadingStatuses.READY &&
                <LoadingIndicator />
            }

            {loadingStatus === LoadingStatuses.READY &&
                <DataTable
                    muiDataTableProps={{
                        title: 'Corporate Documents',
                        columns: tableData[0] && Object.keys(tableData[0]).map(key => {
                            const returnObj: MUIDataTableColumn = {
                                name: key,
                            };

                            if (   key === 'DocRevID'
                                || key === 'HiddenTitle'
                            ) {
                                returnObj.options = {
                                    filter: false,
                                    display: false
                                };
                            }

                            if (key === 'Title') {
                                returnObj.options = {
                                    customBodyRender: (value, tableMeta) => {
                                        return ClickableTitleCell(
                                            context.organization?.PREFIX,
                                            tableMeta.rowData[3],
                                            tableMeta.rowData[4],
                                            tableMeta.rowData[1]
                                        );
                                    }
                                };
                            }

                            return returnObj;
                        }),
                        data: tableData,
                        options: {
                            expandableRows: true,
                            renderExpandableRow: (rowData: string[]) => {
                                return (
                                    <TableRow>
                                        <TableCell colSpan={tableData[0] && Object.keys(tableData[0]).length + 1}>
                                            <FacilityImplementationByOrg
                                                selectedOrgID={undefined}
                                                getActiveDocsOnly={false}      // superseded by selectedDocRevID
                                                getLatestRevisionsOnly={false} // superseded by selectedDocRevID
                                                selectedDocRevID={parseInt(rowData[0])}
                                                selectedDocRevTitle={rowData[2]}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            }
                        }
                    }}
                />
            }
        </div>
    );
}
