import React, { useEffect, useState } from 'react';
import { DOCUMENTREVISION } from '../../../entities/org/DOCUMENTREVISION';
import { ORGANIZATION } from '../../../entities/master/ORGANIZATION';
import axios from 'axios';
import { buildDocURL } from '../../../_shared/utils/docManagerFunctions';
import { LINK } from '../../../entities/master/LINK';

interface IWhatsNewLinkedDocRevsParams {
    docRevId: number;
}

type WhatsNewDocRevLink = {
    docRev: DOCUMENTREVISION
    organization: ORGANIZATION
}

const WhatsNewLinkedDocRevs: React.FC<IWhatsNewLinkedDocRevsParams> = (props: IWhatsNewLinkedDocRevsParams) => {

    const [links, setLinks] = useState<WhatsNewDocRevLink[]>([]);
    const [regularLinks, setRegularLinks] = useState<LINK[]>([]);

    useEffect(() => {
        const getLinks = async () => {
            const resLinks = await axios.get(`/api/homepage/card/whats-new/referenced-documents/${ props.docRevId }`);
            if (resLinks.data.links) {
                setLinks(resLinks.data.links);
            }

            if (resLinks.data.regularLinks) {
                setRegularLinks(resLinks.data.regularLinks);
            }
        };
        getLinks();
    }, []);

    const totalNumberOfLinks = links.length + regularLinks.length;

    if (totalNumberOfLinks === 0) {
        return <>(No links found)</>;
    }

    return <>
        Links:
        <br/>
        <ul>
        {
            links.map(
                (drl, idx) =>
                    <li key={idx}>
                        <a
                            href={
                                buildDocURL(
                                    drl.organization.PREFIX!,
                                    drl.docRev.DOCID
                                )
                            }
                            target={'_blank'}
                            rel={'noopener noreferrer'}
                            style={{ textDecoration: 'none' }}
                        >
                            { drl.docRev.TITLE }
                        </a>
                    </li>
            )
        }
        {
            regularLinks.map(
                (link, idx) => 
                    link.REFERENCED_URL &&
                    <li key={links.length + idx}>
                        <a
                            href={link.REFERENCED_URL}
                            target={'_blank'}
                            rel={'noopener noreferrer'}
                            style={{ textDecoration: 'none' }}
                        >
                            {link.REFERENCED_URL_TITLE}
                        </a>
                    </li>
            )
        }
        </ul>
    </>;

};

export default WhatsNewLinkedDocRevs;
