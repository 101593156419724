import { DashboardCard, DashboardCardContent, DashboardCardTitle } from '@/components/Homepage/components/DashboardCard';
import { useFavorites } from '@/hooks/useFavorites';
import { HybridLink } from '@/ui/ui/hybridlink';
import { Button } from '@/ui/ui/button';
import { Cross2Icon } from '@radix-ui/react-icons';
import React from 'react';

interface FavoritesCard2Props {

}

export const FavoritesCard2: React.FC<FavoritesCard2Props> = () => {

    const { toggleFavoriteToBeDeleted, getHrefsOnly } = useFavorites();

    return (
        <DashboardCard disableMinWidth>
            <DashboardCardTitle>
                Favorites
            </DashboardCardTitle>
            <DashboardCardContent>
                <div className='w-full flex flex-col'>
                    {getHrefsOnly().map((fav, idx) => {
                        let title = fav.REFERENCED_URL_TITLE;
                        const maxTitleLength = 50;
                        if (title && title.length > maxTitleLength) {
                            title = title.substring(0, maxTitleLength) + '...';
                        }
                        return (
                            <HybridLink
                                openInNewTab
                                key={fav.LINK_ID + '-' + idx}
                                className='text-primary-accent text-base text-wrap hover:underline mt-1 w-full flex flex-row justify-between items-center'
                                external={true} href={fav.REFERENCED_URL ?? ''}
                            >
                                <span className='text-base'> {title} {fav.archived && <span className='text-muted-foreground'>[Archived]</span>}</span>
                                <Button
                                    variant={'ghost'}
                                    size={'icon'}
                                    className='align-top'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        toggleFavoriteToBeDeleted(fav, true);
                                    }}
                                >
                                    <Cross2Icon />
                                </Button>
                            </HybridLink>
                        );
                    })}
                </div>
            </DashboardCardContent>
        </DashboardCard>
    );
};