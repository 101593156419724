import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { FacilityImplementation } from './FacilityImplementation/FacilityImplementation';

export function MultiOrgComponents() {

    return (
        <div>
            <Switch>
                <Route path={'/docmanager/multiorg/facility-implementation'} component={FacilityImplementation} />
            </Switch>
        </div>
    );
}
