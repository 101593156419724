import React from 'react';
import { Route, Switch } from 'react-router';
import CRCommittees from './Committees/CRCommittees';
import CRDepartments from './Departments/CRDepartments';
import CRSigners from './Signers/CRSigners';
import CRDDocTypes from './DocTypes/CRDocTypes';

export default function ComplianceReports() {
    return (
        <div>
            <Switch>
                <Route path={'/docmanager/compliance-reports/committees'}  component={CRCommittees}  />
                <Route path={'/docmanager/compliance-reports/departments'} component={CRDepartments} />
                <Route path={'/docmanager/compliance-reports/doctypes'}    component={CRDDocTypes} />
                <Route path={'/docmanager/compliance-reports/signers'}     component={CRSigners}  />
            </Switch>
        </div>
    );
}
