import { PERSON } from  '../../../entities/master/PERSON';

export default function sortLastNameThenFirst(a: PERSON, b: PERSON) {
    let aLast = a.LASTNAME || '';
    let aFirst = a.FIRSTNAME || '';
    let bLast = b.LASTNAME || '';
    let bFirst = b.FIRSTNAME || '';

    aLast = aLast.toLowerCase();
    aFirst = aFirst.toLowerCase();
    bLast = bLast.toLowerCase();
    bFirst = bFirst.toLowerCase();

    if (aLast < bLast) {
        return -1;
    }
    if (aLast > bLast) {
        return 1;
    }
    if (aLast === bLast) {
        if (aFirst < bFirst) {
            return -1;
        }
        if (aFirst > bFirst) {
            return 1;
        }
        return 0;
    }
    return 0;
}