import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import CachedIcon from '@material-ui/icons/Cached';
import { FormatLineSpacing, VerticalAlignCenterSharp } from '@material-ui/icons';

const useStyles = makeStyles({
    dataTableFooter: {
        padding: '.5rem 1.5rem',
        boxSizing: 'border-box',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    }
});

interface DataTableFooterProps {
    onRefresh?: () => void
    isRefreshing?: boolean

    expandableRows?: boolean
    expandAll: () => void
    collapseAll: () => void
    areAllRowsExpanded: boolean
    areAllRowsCollapsed: boolean

    children?: JSX.Element
}

function DefaultFooterButtons(props: DataTableFooterProps) {
    return (
        <>
            <span>
                {props.onRefresh &&
                    <Button
                        disabled={props.isRefreshing}
                        startIcon={<CachedIcon />}
                        onClick={() => props.onRefresh?.()}
                    >
                        {props.isRefreshing ? 'Loading' : 'Refresh'}
                    </Button>
                }
            </span>

            <span>
                {props.expandableRows &&
                    <>
                        <Button
                            disabled={props.areAllRowsExpanded}
                            startIcon={<FormatLineSpacing />}
                            onClick={() => props.expandAll()}
                        >
                            Expand All
                        </Button>

                        <Button
                            disabled={props.areAllRowsCollapsed}
                            startIcon={<VerticalAlignCenterSharp />}
                            onClick={() => props.collapseAll()}
                            >
                            Collapse All
                        </Button>
                    </>
                }
            </span>
        </>
    );
}

export default function DataTableFooter(props: DataTableFooterProps)  {
    const classes = useStyles({});

    return (
        <div className={classes.dataTableFooter}>
            {props.children || <DefaultFooterButtons {...props} />}
        </div>
    );
}
