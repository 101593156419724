import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import DataTable from '../../../_shared/DataTable/DataTable';
import { displayableRightToRightsCode, PERSON, PersonStatuses as PersonStatuses } from '../../../../entities/master/PERSON';
import { MUIDataTableColumn, MUIDataTableProps } from 'mui-datatables';
import {  LoadingIndicator } from '../../../_shared/LoadingIndicator';
import { buildUserProfileURL } from '../../../../_shared/utils/linkBuilders';
import UserBundleContext from '../../../../context/UserBundleContext';
import { createSortableDate } from '../../../../_shared/utils/docManagerFunctions';
import { LoadingStatuses } from '../../../../utils/LoadingStatuses';

interface ManageUsersProps {
    statuses: PersonStatuses[]
    displayableStatus: string
}

export function ManageUsers(props: ManageUsersProps) {
    const context = useContext(UserBundleContext);

    const [loadingStatus, setLoadingStatus] = useState<LoadingStatuses>(LoadingStatuses.LOADING);
    const [tableData, setTableData] = useState<MUIDataTableProps['data']>([]);

    // for some reason this function activates on initial page load, meaning you don't need an extra
    // on-first-load function, which would just run the getUsers twice
    useEffect(() => {
        getUsers(props.statuses).then();
    }, [props.statuses]);

    async function getUsers(statuses: PersonStatuses[]) {
        setLoadingStatus(LoadingStatuses.LOADING);

        const res = await axios.get('/api/administration/users/manage-users', {
            params: {
                statuses: statuses
            }
        });

        const dataForTable = (res.data.users as PERSON[]).map(user => {
            const returnObj = {
                // USERID IS USED FOR LINK BUILDING AND MUST BE IN THE EXACT
                // INDEX POSITION DO NOT CHANGE DO NOT PASS GO DO NOT COLLECT $200
                UserID: user.USERID,

                // These you can change and reorder or add more in whichever position
                LoginID: user.LOGINID,
                'First Name': user.FIRSTNAME,
                'Last Name': user.LASTNAME,
                Title: user.TITLE,
                Department: user.DEPARTMENT_NAME,
                Organization: user.ORGANIZATION?.ORGANIZATIONID === context.organization?.ORGANIZATIONID
                    ? context.organization?.NAME
                    : user.ORGANIZATION?.NAME,
                Email: user.LOCATION,
                Privileges: user.RIGHTS,
                'Last Login': user.LASTLOGIN && createSortableDate(new Date(user.LASTLOGIN)),
                'User Since': user.CREATED_DATE && createSortableDate(new Date(user.CREATED_DATE)),
                GUID: user.GUID,
            };

            if (props.statuses.length > 1) {
                (returnObj as any).Status = user.STATUSID === PersonStatuses.ACTIVE
                    ? 'Active'
                    : 'Disabled';
            }

            return returnObj;
        });

        setTableData(dataForTable);
        setLoadingStatus(LoadingStatuses.READY);

    }

    const readablePrivilegeStrings = Object.keys(displayableRightToRightsCode);

    const tableColumns = tableData[0] && Object.keys(tableData[0]).map(key => {
        // general ones that don't need complex stuff
        const returnObj: MUIDataTableColumn = {
            name: key,
            options: {
                filter: ['Title', 'Department', 'Organization'].includes(key),
                display: key !== 'OrganizationID',
            }
        };

        // complicated ones from here down
        if (['UserID', 'LoginID'].includes(key) && returnObj.options) {
            returnObj.options = {
                filter: false,
                customBodyRender: (value, tableMeta) => {
                    const profileURL = buildUserProfileURL(
                        context.organization?.ORGANIZATIONID || 0,
                        tableMeta.rowData[0]
                    );

                    return (
                        <a href={profileURL} target={'_blank'} rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                            {value}
                        </a>
                    );
                }
            };
        }

        if (key === 'Privileges') {
            returnObj.options = {
                filterOptions: {
                    names: readablePrivilegeStrings,
                    logic: (value, selectedPrivileges) => {
                        // this filtration function removes items that return true, AKA it's filterOUT
                        return !selectedPrivileges.find(privilege => {
                            return value.includes(displayableRightToRightsCode[privilege].letter);
                        });
                    },
                },
                customBodyRender: value => {
                    return (
                        <span style={{ whiteSpace: 'pre', fontFamily: 'monospace' }}>
                            {value}
                        </span>
                    );
                }
            };
        }

        return returnObj;
    });

    if (loadingStatus !== LoadingStatuses.READY) {
        return <LoadingIndicator />;
    }

    return (
        <DataTable
            muiDataTableProps={{
                title: `${props.displayableStatus} Users`,
                data: tableData,
                columns: tableColumns,
                options: {
                    rowsPerPage: 15
                },
            }}
            onRefresh={() => getUsers(props.statuses)}
        />
    );
}
