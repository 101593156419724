import React, { useContext, useEffect, useRef, useState } from 'react';

import Subheader from '../../../_shared/Subheader/Subheader';
import SubheaderItem from '../../../_shared/Subheader/SubheaderItem';
import MyCommittees, { IMyCommitteesHandle } from './components/MyCommittees';
import { Button, Checkbox, FormControlLabel, makeStyles } from '@material-ui/core';
import MyMeetings from './components/MyMeetings';
import CommitteePreferences from './components/CommitteePreferences';
import CreateCommittee from './components/CreateCommittee';
import Calendar from './components/Calendar';
import CommitteeEditor from '../CommitteeEditor/CommitteeEditor';
import hasRights from '../../../../_shared/utils/hasRights';
import UserBundleContext from '../../../../context/UserBundleContext';
import LucidocColors from '../../../../constants/LucidocColors';
import { HelpTopicContext } from '../../../../context/HelpTopicContext';
import { helpTopicDocIDs } from '../../../../_shared/constants/HelpSystemIDs';
import { QueryParams, useBrowserQuery } from '../../../../hooks/useBrowserQuery';
import { TemporaryHelpIcon } from '../../../_shared/TemporaryHelpIcon/TemporaryHelpIcon';
import { formatTitle, useDocumentTitle } from '@/hooks/useDocumentTitle';
import { RightsCodeKey } from '@/entities/master/PERSON';
import axios from 'axios';

const useStyles = makeStyles({
    lastElementsDiv: {
        display: 'flex',
        alignItems: 'center',
    }
});

const Tabs = {
    MyMeetings: 'My Meetings',
    Calendar: 'Calendar',
    Committees: 'Committees',
    Preferences: 'Admin Preferences'
} as const;

type TabValue = typeof Tabs[keyof typeof Tabs];

export interface CommiteesPageQuery extends QueryParams {
    idOfCommitteeBeingEdited?: number
    creatingCommittee?: boolean
    tab?: TabValue
    activeCommitteesOnly: boolean
}

const CommitteesPage: React.FC = () => {
    const [hasCommittees, setHasCommittees] = useState(false);
    const isInAdmin = window.parent?.location.href.includes('admin/admin.pl');
    const classes = useStyles();

    const context = useContext(UserBundleContext);

    const myCommitteesRef = useRef<IMyCommitteesHandle>(null);

    const isCommitteeAdmin = hasRights(
        context,
        [
            RightsCodeKey.DocumentAdministrator,
            RightsCodeKey.OrganizationAdministrator
        ]
    );

    const [query, setQuery] = useBrowserQuery<CommiteesPageQuery>();

    useEffect(() => {
        if (!isInAdmin) {
            axios.get('/api/committee')
            .then(res => {
                const committees = res.data.committees;
                setHasCommittees(committees && committees.length > 0);
            })
            .catch(console.log);
        } else {
            setHasCommittees(true);
        }
    }, []);

    useEffect(() => {
        // If there is a tab in the URL, set the selected tab to that tab
        if (query.tab) {
            switch (query.tab) {
                case Tabs.Committees:
                    // If the user does not have any committees, they should not be able to see the committees tab.
                    modifySelectedTab(hasCommittees ? query.tab : Tabs.MyMeetings);
                    break;
                case Tabs.Preferences:
                    // If the user is not a committee admin, they should not be able to see the preferences tab.
                    modifySelectedTab(isCommitteeAdmin ? query.tab : Tabs.MyMeetings);
                    break;
                default:
                    modifySelectedTab(query.tab);
            }
        }

        // If we are looking at these cases, the tab needs to be set to Committees.
        if (query.idOfCommitteeBeingEdited || query.creatingCommittee) {
            modifySelectedTab(Tabs.Committees);
        }

    }, [query]);

    const modifySelectedTab = (tab: TabValue) => {
        setQuery({
            ...query,
            tab: tab
        });
    };

    const { helpTopicID, setHelpTopicID } = useContext(HelpTopicContext);
    // If no tab is selected, default to My Meetings
    const selectedTab = query.tab || Tabs.MyMeetings;
    // Default to only showing active committees if the URL param is not set
    // Since it is a boolean, we have to explicitly check for undefined to see if it is set.
    const activeCommitteesOnly = query.activeCommitteesOnly === undefined ? true : query.activeCommitteesOnly;

    useEffect(() => {
        if (!isInAdmin) {
            switch (selectedTab) {
                case Tabs.Committees:
                    setHelpTopicID(helpTopicDocIDs.DOCMGR2_EDITCOMMITTEE);
                    break;
                default:
                    setHelpTopicID(null);
                    break;
            }
        } else {
            switch (selectedTab) {
                case Tabs.MyMeetings:
                case Tabs.Calendar:
                    setHelpTopicID(helpTopicDocIDs.DOCMGR2_EDITCOMMITTEE);
                    break;
                case Tabs.Preferences:
                    setHelpTopicID(helpTopicDocIDs.ADMIN_COMMITTEE_PREFERENCES);
                    break;
                case Tabs.Committees:
                    if (isCommitteeAdmin) {
                        setHelpTopicID(helpTopicDocIDs.ADMIN_COMMITTEES);
                    } else {
                        setHelpTopicID(helpTopicDocIDs.DOCMGR2_EDITCOMMITTEE);
                    }
                    break;
                default:
                    break;
            }
        }
    }, [selectedTab]);

    const renderTabContent = () => {
        switch (selectedTab) {
            case Tabs.Committees:
                return <MyCommittees
                    ref={myCommitteesRef}
                    activeCommitteesOnly={activeCommitteesOnly}
                />;
            case Tabs.MyMeetings:
                return <MyMeetings />;
            case Tabs.Calendar:
                return <Calendar />;
            case Tabs.Preferences:
                return <CommitteePreferences />;
            default:
                return null;
        }
    };

    const getTabNameFormatted = () => {
        switch (selectedTab) {
            case Tabs.MyMeetings:
                return 'My Meetings';
            case Tabs.Calendar:
                return 'Calendar';
            case Tabs.Committees:
                return 'Committees';
            case Tabs.Preferences:
                return 'Admin Preferences';
            default:
                throw new Error('Tab name not defined in committees!');
        }
    };

    useDocumentTitle(formatTitle('Committees', getTabNameFormatted()));

    const filteredTabs = Object.entries(Tabs)
        .filter(tabEntry => tabEntry[1] !== Tabs.Preferences || isCommitteeAdmin)
        .filter(tabEntry => tabEntry[1] !== Tabs.Committees || hasCommittees)
        .map(tabEntry => tabEntry[0]);

    return <div>
        <Subheader
            value={selectedTab}
            onChange={tab => { modifySelectedTab(tab as TabValue); }}
            lastElementZIndex={0}
            lastElement={
                <div className={classes.lastElementsDiv}>
                    {isCommitteeAdmin && selectedTab === Tabs.Committees &&
                        <>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={activeCommitteesOnly}
                                    onChange={() => {
                                        setQuery({
                                            ...query,
                                            activeCommitteesOnly: !activeCommitteesOnly
                                        });
                                    }}
                                    style={{ color: LucidocColors.purple }}
                                />
                            }
                            label={
                                <span style={{ fontFamily: 'Quattrocento Sans' }}>
                                    Active Only
                                </span>
                            }
                        />
                        <Button
                            onClick={() => {
                                setQuery({
                                    ...query,
                                    creatingCommittee: true
                                });
                            }}
                        >
                            Create Committee
                        </Button>
                        </>
                    }
                    {helpTopicID && <TemporaryHelpIcon helpTopicID={helpTopicID} />}
                </div>
            }
        >
            {filteredTabs.map(tab => <SubheaderItem key={Tabs[tab]} label={Tabs[tab]} value={Tabs[tab]} />)}
        </Subheader>
        {renderTabContent()}
        {
            query.creatingCommittee &&
            <CreateCommittee
                onCommitteeCreate={
                    () => {
                        modifySelectedTab(Tabs.Committees);
                        if (myCommitteesRef && myCommitteesRef.current) {
                            myCommitteesRef.current.refreshCommittees();
                        }
                    }
                }
                onClose={() => {
                    setQuery({
                        ...query,
                        creatingCommittee: undefined
                    });
                }}
            />
        }
        {
            query.idOfCommitteeBeingEdited &&
            <CommitteeEditor
                committeeId={query.idOfCommitteeBeingEdited}
                open={query.idOfCommitteeBeingEdited !== undefined}
                onClose={
                    () => {
                        setQuery({
                            ...query,
                            idOfCommitteeBeingEdited: undefined,
                            tab: Tabs.Committees
                        });
                    }
                }
            />
        }
    </div>;

};

export default CommitteesPage;
