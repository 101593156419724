import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { LoadingIndicator } from '../../LoadingIndicator';
import { type PERSON, isPerson } from '../../../../entities/master/PERSON';
import { USERGROUP, isUserGroup } from '../../../../entities/org/USERGROUP';
import axios from 'axios';
import H3WithHelpTip from '../H3WithHelpTip';
import SaveBar from '../../SaveBar/SaveBar';
import SaveIndicator from '../../SaveIndicator';
import { INFORMATION_HIERARCHY } from '../../../../entities/org/INFORMATION_HIERARCHY';
import { DOCUMENT } from '../../../../entities/org/DOCUMENT';
import { PUBLISHER } from '../../../../entities/org/PUBLISHER';
import { RefreshRounded } from '@material-ui/icons';
import { LoadingStatuses } from '../../../../utils/LoadingStatuses';
import { EditItemCollaboratorsUserPicker } from './EditItemCollaboratorsUserPicker';
import { hasIntersection } from '../../../../_shared/utils/departmentUserPicker/hasIntersection';
import { generateIntersectionError } from '../../../../_shared/utils/editItemCollaborators/generateIntersectionError';

type EditItemCollaboratorsProps = {
    // match: any
    objectType: 'DOCID' | 'INFORMATION_HIERARCHY_ID' | 'PUBLISHER_ID'
    infoHierarchySubType?: 'MANUAL' | 'DEPARTMENT' // because INFORMATION_HIERARCHY can be either; this is just used for display name purposes, FYI
    objectID: number
    closeModal?: () => void
}

const useStyles = makeStyles({
    mainDiv: {
        width: 'calc(100% - 1rem)',
        height: 'calc(100% - 4rem)',
        overflowY: 'auto',
        margin: '1rem 1rem',
    },
    userPickerDiv: {
        width: 500,
        margin: '10px 10px'
    },
    indent: {
        marginLeft: '2rem'
    },
});

export function EditItemCollaborators(props: EditItemCollaboratorsProps) {
    const classes = useStyles();

    const [ loadingStatus, setLoadingStatus ] = useState<LoadingStatuses>(LoadingStatuses.LOADING);
    const [ object, setObject ] = useState<DOCUMENT | INFORMATION_HIERARCHY | PUBLISHER | undefined>();
    const [ isSaveIndicatorVisible, setIsSaveIndicatorVisible ] = useState(false);
    const [ collaborators, setCollaborators ] = useState<(PERSON | USERGROUP)[]>([]); // collaborators for documents, manuals
    const [ departmentCollaborators, setDepartmentCollaborators ] = useState<(PERSON | USERGROUP)[]>([]);
    const [ limitedVisibilityLimitedCollaborators, setLimitedVisibilityLimitedCollaborators ] = useState<(PERSON | USERGROUP)[]>([]);
    const [ fullVisibilityLimitedCollaborators, setFullVisibilityLimitedCollaborators ] = useState<(PERSON | USERGROUP)[]>([]);
    const [ fullCollaborators, setFullCollaborators ] = useState<(PERSON | USERGROUP)[]>([]);

    const isDepartment = props.objectType === 'INFORMATION_HIERARCHY_ID' && props.infoHierarchySubType === 'DEPARTMENT';

    useEffect(() => {
        loadData().then();
    }, []);

    async function loadData() {
        setIsSaveIndicatorVisible(false);
        setLoadingStatus(LoadingStatuses.LOADING);

        const res = await axios.get('/api/_shared/forms/edit-item-collaborators', {
            params: {
                objectType: props.objectType,
                objectID: props.objectID,
                objectSubType: props.infoHierarchySubType,
            }
        });

        setObject(res.data.object);
        setCollaborators(res.data.collaborators || []);
        setDepartmentCollaborators(res.data.departmentCollaborators || []);
        setLimitedVisibilityLimitedCollaborators(res.data.limitedVisibilityLimitedCollaborators || []);
        setFullVisibilityLimitedCollaborators(res.data.fullVisibilityLimitedCollaborators || []);
        setFullCollaborators(res.data.fullCollaborators || []);
        setLoadingStatus(LoadingStatuses.READY);
    }

    async function saveData() {
        setLoadingStatus(LoadingStatuses.SAVING);

        const res = await axios.post('/api/_shared/forms/edit-item-collaborators/save-collaborators', {
            objectType: props.objectType,
            objectSubType: props.infoHierarchySubType,
            objectID: props.objectID,
            object,
            collaborators,
            limitedVisibilityLimitedCollaborators,
            fullVisibilityLimitedCollaborators,
            fullCollaborators,
        });

        setIsSaveIndicatorVisible(true);
        if (res.data.success) await loadData();
    }

    if (loadingStatus !== LoadingStatuses.READY) {
        return <LoadingIndicator loadingStatus={loadingStatus} />;
    }

    const unremovablePeople: {[key: string]: string} = {};
    const unremovableGroups: {[key: string]: string} = {};

    departmentCollaborators.forEach(collaborator => {
        const unremovableMessage = 'Department collaborators cannot be removed';
        if (isPerson(collaborator)) {
            unremovablePeople[collaborator.USERID] = unremovableMessage;
        }
        if (isUserGroup(collaborator)) {
            unremovableGroups[collaborator.USERGROUPID] = unremovableMessage;
        }
    });

    const userPickerErrors: string[] = [];
    if (isDepartment) {
        limitedVisibilityLimitedCollaborators.forEach(limitedVisibilityLimitedCollaborator => {
            fullVisibilityLimitedCollaborators.forEach(fullVisibilityLimitedCollaborator => {
                if (!hasIntersection(limitedVisibilityLimitedCollaborator, fullVisibilityLimitedCollaborator)) {
                    return;
                }

                userPickerErrors.push(generateIntersectionError(limitedVisibilityLimitedCollaborator, fullVisibilityLimitedCollaborator, undefined));
            });

            fullCollaborators.forEach(fullCollaborator => {
                if (!hasIntersection(limitedVisibilityLimitedCollaborator, fullCollaborator)) {
                    return;
                }

                userPickerErrors.push(generateIntersectionError(limitedVisibilityLimitedCollaborator, undefined, fullCollaborator));
            });
        });

        fullVisibilityLimitedCollaborators.forEach(fullVisibilityLimitedCollaborator => {
            fullCollaborators.forEach(fullCollaborator => {
                if (!hasIntersection(fullVisibilityLimitedCollaborator, fullCollaborator)) {
                    return;
                }

                userPickerErrors.push(generateIntersectionError(undefined, fullVisibilityLimitedCollaborator, fullCollaborator));
            });
        });
    }

    return (
        <div className={classes.mainDiv}>
            <H3WithHelpTip
                helpText={'Add the names of users or user groups that should have collaboration rights'}
                text={'Collaborators'}
            />

            <EditItemCollaboratorsUserPicker
                userPickerClass={classes.userPickerDiv}
                objectType={props.objectType}
                objectSubType={props.infoHierarchySubType}
                updateUsers={(newUsers: (PERSON | USERGROUP)[]) => setCollaborators(newUsers as PERSON[])}
                updateGroups={(newUsers: (PERSON | USERGROUP)[]) => setCollaborators(newUsers as PERSON[])}
                updateLimitedVisibilityLimitedCollaboratorUsers={(newUsers: (PERSON | USERGROUP)[]) => setLimitedVisibilityLimitedCollaborators(newUsers as PERSON[])}
                updateLimitedVisibilityLimitedCollaboratorGroups={(newUsers: (PERSON | USERGROUP)[]) => setLimitedVisibilityLimitedCollaborators(newUsers as PERSON[])}
                updateFullVisibilityLimitedCollaboratorUsers={(newUsers: (PERSON | USERGROUP)[]) => setFullVisibilityLimitedCollaborators(newUsers as PERSON[])}
                updateFullVisibilityLimitedCollaboratorGroups={(newUsers: (PERSON | USERGROUP)[]) => setFullVisibilityLimitedCollaborators(newUsers as PERSON[])}
                updateFullCollaboratorUsers={(newUsers: (PERSON | USERGROUP)[]) => setFullCollaborators(newUsers as PERSON[])}
                updateFullCollaboratorGroups={(newUsers: (PERSON | USERGROUP)[]) => setFullCollaborators(newUsers as PERSON[])}
                collaborators={collaborators}
                limitedVisibilityLimitedCollaborators={limitedVisibilityLimitedCollaborators}
                fullVisibilityLimitedCollaborators={fullVisibilityLimitedCollaborators}
                fullCollaborators={fullCollaborators}
                unremovablePeople={unremovablePeople}
                unremovableGroups={unremovableGroups}
            />

            <br />

            {userPickerErrors.length > 0 &&
                <div className={classes.indent}>
                    <p>Please fix the following input errors:</p>
                    <ul>
                        {userPickerErrors.map((error, index) => (
                            <li key={index} style={{ color: 'red' }}>{error}</li>
                        ))}
                    </ul>
                </div>
            }

            <SaveBar
                onSave={ () => saveData() }
                isSaveDisabled={ userPickerErrors.length > 0 }
                whyIsSaveDisabled={ userPickerErrors.length > 0 ? 'Please fix the input errors' : undefined }

                onCancel={ () => loadData() }
                cancelIcon={ <RefreshRounded /> }

                onClose={ () => props.closeModal?.() }
            />

            <SaveIndicator
                open={isSaveIndicatorVisible}
                onClose={() => setIsSaveIndicatorVisible(false)}
                message={'Collaborators saved successfully.'}
            />
        </div>
    );
}
