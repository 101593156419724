import React, { useContext, useEffect, useState } from 'react';

import { NavigationMenuContent, NavigationMenuItem, NavigationMenuTrigger } from '@/ui/ui/navigation-menu';
import { NavigationMenuContentDropdown, NavigationDropdownSectionEntry, NavigationDropdownTitle, NavigationMenuFooter } from '@/ui/ui/navigation-dropdown';
import { Input } from '@/ui/ui/input';
import { INFORMATION_HIERARCHIES_PARENT, INFORMATION_HIERARCHY } from '@/entities/org/INFORMATION_HIERARCHY';
import UserBundleContext, { UserBundleType } from '@/context/UserBundleContext';
import useSWR from 'swr';
import { useOrgBaseURL } from '@/hooks/useOrgBaseURL';

export const HeaderDepartmentsDropdown: React.FC = () => {

    const userBundle = useContext<UserBundleType>(UserBundleContext);

    const { data: departmentsData, isLoading } = useSWR<{ parent: INFORMATION_HIERARCHIES_PARENT }>('/api/homepage/card/departments');
    const orgBaseURL = useOrgBaseURL();

    const [departments, setDepartments] = useState<INFORMATION_HIERARCHY[]>([]);      
    const [query, setQuery] = useState('');

    const addDepartmentsToFlatList = (departmentsFlat: INFORMATION_HIERARCHY[], department: INFORMATION_HIERARCHY) => {
        departmentsFlat.push(department);
        department.CHILDREN?.forEach(
            child => addDepartmentsToFlatList(departmentsFlat, child)
        );
    };

    useEffect(() => {
        if (
            !isLoading &&
            departmentsData &&
            departmentsData.parent
        ) {
            const newDepartments: INFORMATION_HIERARCHY[] = [];    
            departmentsData.parent.CHILDREN.forEach(
                department => addDepartmentsToFlatList(newDepartments, department)
            );
            setDepartments(newDepartments);
        }
    }, [isLoading]);

    const displayedDepartments = departments
        .sort(
            (a, b) =>
                a.TITLE.localeCompare(b.TITLE)
        )
        .filter(manual => manual.TITLE.toLowerCase().includes(query.toLowerCase()));

    const departmentNameSymbol = userBundle.organization?.ORGUNITPLURAL?.toLowerCase();

    return (
        <NavigationMenuItem>
            <NavigationMenuTrigger>
                <p className='text-primary'>{ userBundle.organization?.ORGUNITPLURAL }</p>
            </NavigationMenuTrigger>
            <NavigationMenuContent>
                <NavigationMenuContentDropdown
                    footer={
                        <NavigationMenuFooter href={`${orgBaseURL}/dashboard?homepageTab=departments`}>
                        Go to { userBundle.organization?.ORGUNITPLURAL?.toLowerCase() }...
                    </NavigationMenuFooter>
                    }
                >
                    <NavigationDropdownTitle>
                        { userBundle.organization?.ORGUNITPLURAL }
                    </NavigationDropdownTitle>
                    <Input
                        type='search'
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        placeholder={`Search ${ departmentNameSymbol }...`}
                        className='border-none pl-3.5'
                        autoFocus
                    />
                    {
                        !isLoading &&
                        displayedDepartments &&
                        displayedDepartments.length > 0 &&
                            <div
                                className='max-h-48 overflow-auto'
                            >
                                {
                                    displayedDepartments?.map (manual => 
                                        <NavigationDropdownSectionEntry
                                            icon={
                                                <svg width="6" height="20" viewBox="0 0 6 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect width="6" height="20" rx="1" fill="#DC9292"/>
                                                </svg>
                                            }
                                            href={`${orgBaseURL}/dashboard?homepageTab=departments&infoHierarchyID=${ manual.INFORMATION_HIERARCHY_ID }`}
                                            title={manual.TITLE}
                                            description={`Go to ${manual.TITLE}...`}
                                            external={false}
                                        />
                                    )   
                                }
                            </div>
                    }      
                    {
                        isLoading &&
                        (displayedDepartments && displayedDepartments.length === 0) &&
                            <NavigationDropdownSectionEntry
                                external={false}
                                title={`No ${ departmentNameSymbol } found`}
                                description='Try different search criteria?'
                            />
                    }
                </NavigationMenuContentDropdown>
            </NavigationMenuContent>
        </NavigationMenuItem>
    );
};
