import React, { useEffect, useState } from 'react';
import { Star, StarBorder } from '@material-ui/icons';
import { IconButton, makeStyles } from '@material-ui/core';
import { LoadingIndicator } from '../LoadingIndicator';
import URLs from '../../../_shared/constants/URLs';
import axios from 'axios';
import Regexes from '../../../_shared/constants/Regexes';
import { LINK } from '../../../entities/master/LINK';
import { LoadingStatuses } from '../../../utils/LoadingStatuses';
import { prefix } from '@/hooks/useDocumentTitle';

const useStyles = makeStyles({
    fakeFaveStar: {
        color: 'black',
        opacity: .8,
    }
});

type TemporaryFavoritesStarProps = {
    // targetPathName is the part AFTER https://lucidoc.com/ (after the slash, so don't do the slash)
    // however it will be doctored to remove beta/staging subdomains so the final favorite
    // will be regular lucidoc.com, so the link will work on prod
    targetPathName: string
    targetTitle?: string
}

/**
 * This component is a stop-gap to get the Favorites star into the pages written in the new
 * stack which are loaded in iframes. All the pages NOT loaded in iframes already have the
 * header, and the header has a fully-functioning favorites star. Pages loaded WITHOUT the
 * header need their own favorites star manually placed on the page somehow. Once those pages
 * are loaded regularly (not in iframes) we can remove this stop-gap favorites star from
 * the components using it.
 *
 * @param props
 * @constructor
 */

export function TemporaryFavoritesStar(props: TemporaryFavoritesStarProps) {
    const classes = useStyles();

    const [targetPathName, setTargetPathName] = useState<string>('');
    const [loadingStatus, setLoadingStatus] = useState(LoadingStatuses.READY);
    const [favorites, setFavorites] = useState<LINK[]>([]);
    const [pageAlreadyFavorited, setPageAlreadyFavorited] = useState<LINK>();

    useEffect(() => {
        getFavorites().then();

        setTargetPathName(props.targetPathName // get just the pathname (with no leading slash) since later code will assume it looks like that
            .replace(Regexes.URLs.lucidocDomain, '') // remove domain (which might be beta/staging), so we can insert a clean https://lucidoc.com instead
            .replace(/^(\/)?/, '') // we'll remove a leading slash if you added one, which you didn't need to
        );
    }, []);

    useEffect(() => {
        // strip the domain to compare just the pathname (after lucidoc.com; AKA we ignore beta/staging/etc)
        setPageAlreadyFavorited(favorites?.find(fave => {
            return (fave.REFERENCED_URL || '').replace(Regexes.URLs.lucidocDomain, '') === targetPathName;
        }));
    }, [favorites]);

    async function getFavorites() {
        setLoadingStatus(LoadingStatuses.LOADING);
        const res = await axios.get('/api/homepage/header/favorites/get-favorites');
        setFavorites(res.data.favorites);
        setLoadingStatus(LoadingStatuses.READY);
    }

    async function addFavorite() {
        if (pageAlreadyFavorited || loadingStatus !== LoadingStatuses.READY) return;

        setLoadingStatus(LoadingStatuses.SAVING);

        let newFavoriteTitle = props.targetTitle ?? window.document.title;
        const newFavoriteURL = URLs.domain.lucidoc + '/' + targetPathName;

        if (newFavoriteTitle.startsWith(prefix)) {
            newFavoriteTitle = newFavoriteTitle.slice(prefix.length - 1);
        }

        const res = await axios.post('/api/homepage/header/favorites/add-favorite', {
            newFavoriteURL,
            newFavoriteTitle,
        });

        setFavorites(res.data.favorites);
        setLoadingStatus(LoadingStatuses.READY);
    }

    async function deleteFavorites() {
        if (!pageAlreadyFavorited || loadingStatus !== LoadingStatuses.READY) return;

        setLoadingStatus(LoadingStatuses.SAVING);

        const res = await axios.post('/api/homepage/header/favorites/delete-favorites', {
            linkIDs: [pageAlreadyFavorited.LINK_ID] // array because the route was originally made for many at once
        });

        setFavorites(res.data.favorites);
        setLoadingStatus(LoadingStatuses.READY);
    }

    return (
        <IconButton
            className={classes.fakeFaveStar}
            onClick={() => pageAlreadyFavorited
                ? deleteFavorites()
                : addFavorite()
            }
        >
            {loadingStatus !== LoadingStatuses.READY &&
                <LoadingIndicator iconOnly={true} />
            }
            {loadingStatus === LoadingStatuses.READY &&
                <>
                    {pageAlreadyFavorited
                        ? <Star />
                        : <StarBorder />
                    }
                </>
            }
        </IconButton>
    );
}
