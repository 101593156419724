import React, { useContext, useEffect, useState } from 'react';

import { NavigationMenuContent, NavigationMenuItem, NavigationMenuTrigger } from '@/ui/ui/navigation-menu';
import { NavigationMenuContentDropdown, NavigationDropdownSectionEntry, NavigationDropdownTitle, NavigationMenuFooter } from '@/ui/ui/navigation-dropdown';
import { Input } from '@/ui/ui/input';
import { INFORMATION_HIERARCHY } from '@/entities/org/INFORMATION_HIERARCHY';
import UserBundleContext, { UserBundleType } from '@/context/UserBundleContext';
import useSWR from 'swr';
import { PUBLISHER } from '@/entities/org/PUBLISHER';
import { useOrgBaseURL } from '@/hooks/useOrgBaseURL';

interface ManualGroup {
    publisher: PUBLISHER;
    informationHierarchies: INFORMATION_HIERARCHY[];
    organizationId?: number;
    organizationName?: string;
}

export const HeaderManualsDropdown: React.FC = () => {

    const userBundle = useContext<UserBundleType>(UserBundleContext);
    const orgBaseURL = useOrgBaseURL();

    const { data: manuals, isLoading } = useSWR<{ manualGroups: ManualGroup[] }>('/api/homepage/card/manuals');

    const [flatManuals, setFlatManuals] = useState<INFORMATION_HIERARCHY[]>();
    const [query, setQuery] = useState('');

    useEffect(() => {
        if (
            !isLoading &&
            manuals 
        ) {
            const newManuals: INFORMATION_HIERARCHY[] = [];
            manuals.manualGroups
                .forEach(
                    mg =>
                        mg.informationHierarchies
                            .forEach(
                                ih => newManuals.push(ih)
                            )
                );
            setFlatManuals(newManuals);
        }
    }, [isLoading]);

    const displayedManuals = flatManuals?.sort(
        (a, b) =>
            a.TITLE.localeCompare(b.TITLE)
    ).filter(manual => manual.TITLE.toLowerCase().includes(query.toLowerCase()));

    const manualNamePlural = userBundle.organization?.MANUALNAMEPLURAL?.toLowerCase();

    return (
        <NavigationMenuItem>
            <NavigationMenuTrigger>
                <p className='text-primary'>{ userBundle.organization?.MANUALNAMEPLURAL }</p>
            </NavigationMenuTrigger>
            <NavigationMenuContent>
                <NavigationMenuContentDropdown
                    footer={
                        <NavigationMenuFooter href={`${orgBaseURL}/dashboard?homepageTab=manuals`}>
                        Go to { userBundle.organization?.MANUALNAMEPLURAL?.toLowerCase() }...
                    </NavigationMenuFooter>
                    }
                >
                    <NavigationDropdownTitle>
                        { userBundle.organization?.MANUALNAMEPLURAL }
                    </NavigationDropdownTitle>
                    <Input
                        type='search'
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        placeholder={`Search ${ manualNamePlural }...`}
                        className='border-none pl-3.5'
                        autoFocus
                    />
                    {
                        !isLoading &&
                        displayedManuals &&
                        displayedManuals.length > 0 &&
                            <div
                                className='max-h-48 overflow-auto'
                            >
                                {
                                    displayedManuals?.map (manual => 
                                        <NavigationDropdownSectionEntry
                                            icon={
                                                <svg width="6" height="20" viewBox="0 0 6 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect width="6" height="20" rx="1" fill="#DC9292"/>
                                                </svg>
                                            }
                                            href={`${orgBaseURL}/dashboard?homepageTab=manuals&infoHierarchyID=${ manual.INFORMATION_HIERARCHY_ID }`}
                                            title={manual.TITLE}
                                            description={`Go to ${manual.TITLE}...`}
                                            external={false}
                                        />
                                    )   
                                }
                            </div>
                    }      
                    {
                        isLoading &&
                        (displayedManuals && displayedManuals.length === 0) &&
                            <NavigationDropdownSectionEntry
                                external={false}
                                title={`No ${ manualNamePlural } found`}
                                description='Try different search criteria?'
                            />
                    }
                </NavigationMenuContentDropdown>
            </NavigationMenuContent>
        </NavigationMenuItem>
    );
};
