import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { INFORMATION_HIERARCHY, InformationHierarchyStatuses } from '../../../entities/org/INFORMATION_HIERARCHY';
import produceFlattenedOptionsFromNestedInformationHierarchy from '../../../utils/produceFlattenedOptionsFromNestedInformationHierarchy';
import { PUBLISHER } from '../../../entities/org/PUBLISHER';
import { Button } from '@/ui/ui/button';
import { AdvancedSelect } from './advanced-select';

export interface ISharedSelectManualProps { // also used by SelectManuals (multi-select)
    // types of manuals to get in the query:
    statuses?: InformationHierarchyStatuses[],
    publisherID?: number,
    userID?: number,
    visibleOnFacilityHomePages?: boolean

    // Select menu controls:
    placeholder?: string;
    disabled?: boolean;

    unselectableManuals?: INFORMATION_HIERARCHY[] // this might be usable just when it's by itself, but so far this is the only use case
}

interface ISelectManualProps {
    // always used:
    onChange: (manualId?: number) => void;

    // if used alone (not part of SelectManuals.tsx):
    manualId?: number;

    // if used by SelectManuals.tsx:
    availableManualGroupsFromParent?: PUBLISHER[] // used by SelectManualMulti so we only do one DB query and the parent hands the info down to the child

    isClearable?: boolean

    // useful if you want the first option to say "None" (sometimes more helpful than the isClearable X button)
    dummyValue?: {
        label: string,
        value: number // probably a zero and you'll handle it on the back end with if (!)
    }
}

export const SelectManual: React.FC<ISelectManualProps & ISharedSelectManualProps> = (props: ISelectManualProps & ISharedSelectManualProps) => {

    const [flattenedOptions, setFlattenedOptions] = useState<GroupedOptions[]>([]);

    useEffect(() => {
        if (props.availableManualGroupsFromParent) {
            setFlattenedOptions(
                groupManualGroupsIntoSelectMenuOptions(
                    props.availableManualGroupsFromParent,
                    props.dummyValue
                )
            );
        }
        else {
            getManualGroups(
                props.statuses,
                props.publisherID,
                props.userID,
                props.visibleOnFacilityHomePages
            ).then(manualGroups => {
                setFlattenedOptions(
                    groupManualGroupsIntoSelectMenuOptions(
                        manualGroups,
                        props.dummyValue
                    )
                );
            });
        }
    }, [
        props.availableManualGroupsFromParent,
        props.publisherID
    ]);

    // can't do this within the ReactSelect value={} because we can't do just a single find()
    let selectMenuValue;
    for (const manualGroupOption of flattenedOptions) {
        selectMenuValue = manualGroupOption.options.find(option => option.value === props.manualId);
        if (selectMenuValue) break;
    }

    const [filterText, setFilterText] = useState('');


    return <AdvancedSelect
        text={selectMenuValue?.label}
        placeholder={props.placeholder ?? 'No placeholder defined.'}
        clearSelection={() => props.onChange(undefined)}
        filterText={filterText}
        setFilterText={setFilterText}
        isSingleSelect
    >
        {flattenedOptions.map((groupedOption, i) => {
            return <div key={i} className='flex flex-col items-center border-t-2 first:border-t-0 py-2'>
                <p className='text-muted-foreground uppercase text-xs'>{groupedOption.label}</p>
                <div className='flex flex-col w-full'>
                    {groupedOption.options.filter(o => o.label.toLowerCase().trim().includes(filterText.toLowerCase().trim())).map(option => {
                        return <Button
                            key={option.value}
                            onClick={() => {
                                props.onChange(option.value);
                            }}
                            className='w-full' variant={'ghost'}>{option.label}</Button>;
                    })}
                </div>
            </div>;
        })}
    </AdvancedSelect>;
};

export type GroupedOptions = {
    label: string;
    options: { label: string, value: number }[]
}

export async function getManualGroups( // also used by SelectManuals.tsx
    statuses?: InformationHierarchyStatuses[],
    publisherID?: number,
    userID?: number,
    visibleOnFacilityHomePages?: boolean
)
    : Promise<PUBLISHER[]> {
    const res = await axios.get('/api/_shared/forms/select/select-manuals/', {
        params: {
            statuses,
            publisherID,
            userID,
            visibleOnFacilityHomePages
        }
    });

    return res.data.manualGroups || [];
}

export function groupManualGroupsIntoSelectMenuOptions(
    manualGroups: PUBLISHER[],
    dummyValue?: {
        label: string,
        value: number // probably a zero and you'll handle it on the back end with if (!)
    }
)
    : GroupedOptions[] {
    const allGroupedOptions: GroupedOptions[] = [];

    if (dummyValue) {
        allGroupedOptions.push({
            label: dummyValue.label,
            options: [dummyValue]
        });
    }

    manualGroups.forEach(manualGroup => {
        const newGroupedOption = {
            label: manualGroup.TITLE,
            options: produceFlattenedOptionsFromNestedInformationHierarchy(manualGroup.MANUALS || [])
        };
        allGroupedOptions.push(newGroupedOption);
    });

    return allGroupedOptions;
}
