import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { LoadingIndicator } from '../../../../../_shared/LoadingIndicator';
import { LoadingStatuses } from '../../../../../../utils/LoadingStatuses';
import { BATCH_JOB, BatchJobTypes, readableJobTypesToCamelCase } from '../../../../../../entities/org/BATCH_JOB';
import DataTable from '../../../../../_shared/DataTable/DataTable';
import UserBundleContext from '../../../../../../context/UserBundleContext';

interface TransferHistoryDetailProps {
    jobID: string   // string because the parent stores them in the <DataTable />, which is all strings
}

export default function TransferHistoryDetail(props: TransferHistoryDetailProps) {
    const context = useContext(UserBundleContext);

    const [ loadingStatus, setLoadingStatus ] = useState<LoadingStatuses>(LoadingStatuses.LOADING);
    const [ transferDetail, setTransferDetail ] = useState<BATCH_JOB>();

    useEffect(() => {
        getTransferHistoryItem().then();
    }, []);

    const ColumnsToDisplayForEachJobType: {
        // each job type stores its JSON data with its own custom key names which don't
        // necessarily correspond to each other ('title' vs 'doctitle' etc). So here is a map
        // of each job type, with the key names we need to extract from the JSON object
        // (stored in the DOCUMENT_CHANGES column, plus one exception stored in JOB_DEFINITION)
        // and the corresponding readable display name for each of those keys
        [key: string]: { // JOB_TYPE
            [key: string]: string // example [key_name_in_json]: 'Corresponding Readable Display Name For This Key'
        }
    } = {
        transferAckSchedules: {
            ack_schedule_id: 'Acknowledgement Schedule ID',
            ack_schedule_title: 'Acknowledgement Schedule Title',
            // from_owner_id: 'Previous Owner ID',
            // to_owner_id: 'New Owner',
            from_owner_name: 'Previous Owner',
            to_owner_name: 'New Owner'
        },
        transferCollaborators: {
            doctitle: 'Document Title',
            doctype: 'DocType',
            category: context.organization?.ORGUNITNAME || 'Department',
            docstatus: 'Doc Status',
            document_owner: 'Document Owner',
            docid: 'Doc ID',
            // revno: 'Rev #', // This info was never recorded in this case, but it should have been; mentioned here so you're not confused and try to find it
            collaborators_added: 'Collaborators Added',
            collaborators_deleted: 'Collaborators Deleted',
        },
        transferDepartment: {
            title: 'Document Title',
            document_type: 'DocType',
            document_status: 'Doc Status',
            document_owner: 'Document Owner',
            docid: 'Doc ID',
            dept_name_from: `From ${context.organization?.ORGUNITNAME || 'Department'}`,
            dept_name_to: `To ${context.organization?.ORGUNITNAME || 'Department'}`,
        },
        // transferDocumentType has  special exceptions, because it stores part of the data
        // in a different property (JOB_DEFINITION), and a few of its properties are not
        // stored at the same nesting level as the others (search "jobDefinition" below to see)
        transferDocumentType: {
            title: 'Document Title',
            status: 'Doc Status',
            document_owner: 'Document Owner',
            docid: 'Doc ID',
            revision_number: 'Rev #',
            from_document_type: 'Previous Doc Type',
            to_document_type: 'New Doc Type',
        },
        transferOwner: {
            title: 'Document Title',
            document_type: 'Doc Type',
            department_name: context.organization?.ORGUNITNAME || 'Department',
            document_status: 'Doc Status',
            docid: 'Doc ID',
            user_name_from: 'Previous Owner',
            user_name_to: 'New Owner',
        },
        transferSigners: {
            doctitle: 'Document Title',
            doctype: 'Doc Type',
            category: context.organization?.ORGUNITNAME || 'Department',
            docstatus: 'Doc Status',
            document_owner: 'Document Owner',
            docid: 'Doc ID',
            revno: 'Rev #',
            signers_added: 'Signers Added',
            signers_deleted: 'Signers Deleted'
        },
    };

    async function getTransferHistoryItem() {
        const res = await axios.get('/api/administration/documents/transfer-history/detail', {
            params: {
                jobID: props.jobID
            }
        });

        setTransferDetail(res.data.transferDetail);
        setLoadingStatus(LoadingStatuses.READY);
    }

    if (loadingStatus !== LoadingStatuses.READY || !transferDetail) {
        return <LoadingIndicator />;
    }

    const jobType = transferDetail.JOB_TYPE;

    if (!jobType) return null; // just a TypeScript check really

    const docChanges = transferDetail.DOCUMENT_CHANGES
        ? JSON.parse(transferDetail.DOCUMENT_CHANGES)
        : [];

    const jobTypeInCamelCase = readableJobTypesToCamelCase[jobType];

    const columnsToDisplay = ColumnsToDisplayForEachJobType[jobTypeInCamelCase] || {};

    // special exception for Transfer Document Type; certain info is stored in JOB_DEFINITION;
    // also certain items are stored in a more deeply nested level, so we create new keys
    // at the correct nesting level so the standard Object.keys() loops below will handle them
    // normally. We use conditional ? checks just in case someone stored this JSON data in a
    // totally different format for some reason:
    if (jobType === BatchJobTypes.transferDocumentType) {
        const jobDefinition = transferDetail.JOB_DEFINITION
            ? JSON.parse(transferDetail.JOB_DEFINITION)
            : {};

        docChanges.forEach((docChange: any) => {
            docChange.document_owner     = docChange.owner?.name;
            docChange.from_document_type = docChange.document_type?.name;
            docChange.to_document_type   = jobDefinition.document_type?.name;
        });
    }

    return (
        <DataTable
            muiDataTableProps={{
                title: `Details for Job ID ${props.jobID}: ${jobType}`,
                columns: Object.keys(columnsToDisplay).map((key: string) => columnsToDisplay[key]),
                data: docChanges.map((docChange: any) => {
                    return Object.keys(columnsToDisplay).map((key: string) => {
                        return Array.isArray(docChange[key])
                            ? docChange[key].join('\n')
                            : docChange[key];
                        }
                    );
                }),
            }}
        />
    );
}
