import React, { useContext, useState } from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import LucidocColors from '../../../../../constants/LucidocColors';
import { makeStyles } from '@material-ui/core/styles';
import { FacilityImplementationByOrg } from './FacilityImplementationByOrg';
import { MultiOrgSelectMenu } from '../../../../_shared/MultiOrgSelectMenu/MultiOrgSelectMenu';
import UserBundleContext from '../../../../../context/UserBundleContext';
import { FacilityImplementationSystemWide } from './FacilityImplementationSystemWide';
import { TemporaryFavoritesStar } from '@/components/_shared/TemporaryFavoritesStar/TemporaryFavoritesStar';
import { useDocumentTitle } from '@/hooks/useDocumentTitle';

const useStyles = makeStyles({
    headerDiv: {
        padding: '1rem'
    },
    optionsDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
});

export function FacilityImplementation() {
    useDocumentTitle('Facility Implementation');
    const classes = useStyles();

    const context = useContext(UserBundleContext);

    const [ selectedOrgID, setSelectedOrgID ] = useState<number | undefined>(context.organization?.ORGANIZATIONID);
    const [ getActiveDocsOnly, setGetActiveDocsOnly ] = useState<boolean>(true);
    const [ getLatestRevisionsOnly, setGetLatestRevisionsOnly ] = useState<boolean>(true);

    const dummyValuesForSelectMenu = [
        {
            label: context.organization?.NAME || '',
            value: context.organization?.ORGANIZATIONID
        },
        {
            label: 'View All Facilities',
            value: undefined
        }
    ];

    return (
        <div className={classes.headerDiv}>
            <div className={classes.optionsDiv}>
                <h4>Facility Implementation</h4>
                <TemporaryFavoritesStar targetPathName={'docmgr2/docmanager.pl?repname=FacilityImplementation'} />
            </div>

            <div className={classes.optionsDiv}>
                <MultiOrgSelectMenu
                    selectedOrgID={selectedOrgID}
                    setSelectedOrgID={setSelectedOrgID}
                    additionalValues={dummyValuesForSelectMenu}
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={getActiveDocsOnly}
                            onChange={() => setGetActiveDocsOnly(!getActiveDocsOnly)}
                            style={{ color: LucidocColors.purple }}
                        />
                    }
                    label={
                        <span style={{ fontFamily: 'Quattrocento Sans' }}>
                            Get Active Documents Only
                        </span>
                    }
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={getLatestRevisionsOnly}
                            onChange={() => setGetLatestRevisionsOnly(!getLatestRevisionsOnly)}
                            style={{ color: LucidocColors.purple }}
                        />
                    }
                    label={
                        <span style={{ fontFamily: 'Quattrocento Sans' }}>
                            Get Latest Revisions Only
                        </span>
                    }
                />

            </div>

            {selectedOrgID === context.organization?.ORGANIZATIONID &&
                <FacilityImplementationSystemWide
                    getActiveDocsOnly={getActiveDocsOnly}
                    getLatestRevisionsOnly={getLatestRevisionsOnly}
                />
            }

            {selectedOrgID !== context.organization?.ORGANIZATIONID &&
                <FacilityImplementationByOrg
                    selectedOrgID={selectedOrgID}
                    getActiveDocsOnly={getActiveDocsOnly}
                    getLatestRevisionsOnly={getLatestRevisionsOnly}
                />
            }

        </div>
    );
}
