import React, { useContext, useEffect, useState } from 'react';
import Subheader from '../../../../_shared/Subheader/Subheader';
import SubheaderItem from '../../../../_shared/Subheader/SubheaderItem';
import SignerBacklog from './SignerBacklog';
import ItemsPendingApproval from '../ItemsPendingApproval';
import { RightsCodeKey, type PERSON } from '../../../../../entities/master/PERSON';
import {  ApprovalSessionStatuses, ApprovalSessionTypes } from '../../../../../entities/org/APPROVAL_SESSION';
import Select from 'react-select';
import hasRights from '../../../../../_shared/utils/hasRights';
import UserBundleContext from '../../../../../context/UserBundleContext';
import axios from 'axios';
import { redirectToHomepage } from '../../../../../utils/savebarUtilities';
import SaveBar from '../../../../_shared/SaveBar/SaveBar';
import { DocumentRevisionStatuses } from '../../../../../entities/org/DOCUMENTREVISION';
import { NotificationWindowTypes } from '../../../../../entities/org/NOTIFICATION_WINDOW';
import { constructFullName } from '@/components/UserPicker/utils/constructFullName';

export default function CRSigners() {
    const Tabs: { [key: string]: string } = {
        SignerBacklog: 'Signer Backlog',
        PendingSignature: 'Pending Signature',
        PendingAdditional: 'Pending Additional',
        PendingRouting: 'Pending Routing',
        PendingRelease: 'Pending Release',
        PendingReview: 'Pending Review',
        PendingArchive: 'Pending Archive',
        PendingExpiration: 'Pending Expiration',
    };

    const context = useContext(UserBundleContext);
    const userIsAdmin = hasRights(context, [RightsCodeKey.DocumentAdministrator, RightsCodeKey.UserAdministrator, RightsCodeKey.OrganizationAdministrator]);
    const [selectedTab, setSelectedTab] = useState<string>(userIsAdmin ? Tabs.SignerBacklog : Tabs.PendingSignature);
    const [allSigners, setAllSigners] = useState<PERSON[]>([]);
    const [selectedSigner, setSelectedSigner] = useState<PERSON | undefined>(undefined);

    async function getSigners() {
        const res = await axios.get('/api/doc-manager/compliance-reports/get-signer-backlog/get-signers-only');
        setAllSigners(res.data.signers);
    }

    useEffect(() => {
        // Backlog loads this data anyway, so we only need to load it if we're not loading the backlog
        if (!userIsAdmin) {
            getSigners().then();
        }
    }, []);

    const selectMenu = selectedTab !== Tabs.SignerBacklog ? (
        <Select
            value={selectedSigner
                ? {
                    label: constructFullName(selectedSigner, 'lastFirstMiddle'),
                    value: selectedSigner.USERID
                }
                : {
                    label: 'All Signers',
                    value: 0
                }
            }
            options={
                [{ label: 'All Signers', value: 0 }]
                    .concat(
                        allSigners.map(signer => {
                            return {
                                label: constructFullName(signer, 'lastFirstMiddle'),
                                value: signer.USERID
                            };
                        })
                    )
            }
            onChange={(selectMenuOption: any) => setSelectedSigner(allSigners.find(signer => signer.USERID === selectMenuOption.value))}
        />
    ) : undefined;

    return (
        <div>
            <Subheader
                value={selectedTab}
                onChange={value => setSelectedTab(value)}
                lastElement={
                    <div style={{ width: '12rem' }}>
                        {selectMenu}
                    </div>
                }
            >
                {Object.keys(Tabs)
                    .filter(tab => userIsAdmin ? true : !tab.includes('Backlog'))
                    .map(tab => <SubheaderItem key={Tabs[tab]} label={Tabs[tab]} value={Tabs[tab]} buttonWidth={'6rem'} />)
                }
            </Subheader>

            {selectedTab === Tabs.SignerBacklog &&
                <SignerBacklog
                    onSelectSigner={(signerID) => {
                        setSelectedSigner(allSigners.find(signer => signer.USERID === signerID));
                        setSelectedTab(Tabs.PendingSignature);
                    }}
                    sendSignersToParent={(signers) => setAllSigners(signers)}
                />
            }

            {selectedTab === Tabs.PendingSignature &&
                <ItemsPendingApproval
                    signerID={selectedSigner?.USERID}
                    docStatusID={DocumentRevisionStatuses.prepsig}
                />
            }

            {selectedTab === Tabs.PendingAdditional &&
                <ItemsPendingApproval
                    signerID={selectedSigner?.USERID}
                    pendingAdditional={'signatures'}
                />
            }

            {selectedTab === Tabs.PendingRouting &&
                <ItemsPendingApproval
                    signerID={selectedSigner?.USERID}
                    docStatusID={DocumentRevisionStatuses.pending_committee_routing}
                    approvalSessionStatus={ApprovalSessionStatuses.pending_approval_routing}
                />
            }

            {selectedTab === Tabs.PendingRelease &&
                <ItemsPendingApproval
                    signerID={selectedSigner?.USERID}
                    docStatusID={DocumentRevisionStatuses.preprel}
                />
            }

            {selectedTab === Tabs.PendingReview &&
                <ItemsPendingApproval
                    signerID={selectedSigner?.USERID}
                    approvalSessionType={ApprovalSessionTypes.review}
                    onlyGetItemsWithActiveSignatureSessions={true}
                />
            }

            {selectedTab === Tabs.PendingArchive &&
                <ItemsPendingApproval
                    signerID={selectedSigner?.USERID}
                    approvalSessionType={ApprovalSessionTypes.archive}
                    onlyGetItemsWithActiveSignatureSessions={true}
                />
            }

            {selectedTab === Tabs.PendingExpiration &&
                <ItemsPendingApproval
                    signerID={selectedSigner?.USERID}
                    getItemsComingUpFor={NotificationWindowTypes.expiration}
                    onlyGetItemsWithActiveSignatureSessions={true}
                />
            }
            <SaveBar onClose={() => redirectToHomepage()} />
        </div>
    );
}
