import React from 'react';

interface AcknowledgementIcon {
    size: number
}

export const AcknowledgementIcon: React.FC<AcknowledgementIcon> = ({ size }) => {
    return (
        <svg width={size.toString()} height={size.toString()} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" rx="4" fill="hsl(var(--primary))"/>
            <path d="M6 6C6 5.44772 6.44772 5 7 5H13.5858C13.851 5 14.1054 5.10536 14.2929 5.29289L17.7071 8.70711C17.8946 8.89464 18 9.149 18 9.41421V18C18 18.5523 17.5523 19 17 19H7C6.44772 19 6 18.5523 6 18V6Z" fill="white"/>
            <path d="M9.5 15L8.17678 13.6768C8.07915 13.5791 8.07915 13.4209 8.17678 13.3232L9.32322 12.1768C9.42085 12.0791 9.57915 12.0791 9.67678 12.1768L11 13.5L14.3261 9.22354C14.4185 9.10484 14.5939 9.09392 14.7002 9.20025L15.8386 10.3386C15.93 10.43 15.9366 10.5761 15.8538 10.6754L11.1753 16.2897C11.0811 16.4026 10.9104 16.4104 10.8064 16.3064L9.5 15Z" fill="#A291CE"/>
        </svg>
    );
};