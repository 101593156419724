import React, { useEffect, useState } from 'react';
import { DOCUMENTREVISION } from '../../../../../entities/org/DOCUMENTREVISION';
import { LucidocModal } from '../../../../_shared/LucidocModal/LucidocModal';
import { makeStyles, Checkbox, FormControlLabel } from '@material-ui/core';
import { DatePicker } from '../../../../_shared/DatePicker/DatePicker';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import ReactSelect from 'react-select';
import { TextareaAutosize } from '@material-ui/core';
import LucidocColors from '../../../../../constants/LucidocColors';

type UpdateDocrevReviewMetadataModalProps = {
    docRevs: DOCUMENTREVISION[] | null;
    isOpen: boolean;
    onClose: () => void;
    onSave: (docRevs: DOCUMENTREVISION[]) => void;
};

const pageStyles = {
    inputContainer: {
        display: 'flex' as CSSProperties['display'],
        flexDirection: 'column' as CSSProperties['flexDirection'],
        gap: '20px',
        marginTop: '20px',
        height: '450px',
    },
    reviewCycleContainer: {
        display: 'flex' as CSSProperties['display'],
        flexDirection: 'column' as CSSProperties['flexDirection'],
        gap: '10px',
    },
    nextReviewDatePicker: {
        marginTop: '25px',
        zIndex: 0,
    }
};

const useStyles = makeStyles({
    ...pageStyles
});

export function UpdateDocrevReviewMetadataModal(props: UpdateDocrevReviewMetadataModalProps) {
    const [docRevs, setDocRevs] = useState<DOCUMENTREVISION[] | null>(null);
    const [retainNextReviewDate, setRetainNextReviewDate] = useState(false);
    const [reviewCycle, setReviewCycle] = useState<number | null>(null);
    const [reviewCycleJustification, setReviewCycleJustification] = useState('');
    const [nextReviewDate, setNextReviewDate] = useState<Date | null>(null);
    const [originalNextReviewDate, setOriginalNextReviewDate] = useState<Date | null>(null);
    const classes = useStyles();

    useEffect(() => {
        if (props.docRevs?.length === 1) {
            const docRev = props.docRevs[0];
            setReviewCycle(docRev.REVIEW_CYCLE);
            setReviewCycleJustification(docRev.REVIEW_CYCLE_JUSTIFICATION ?? '');

            if (docRev.NEXTREVDT) {
                const date = new Date(docRev.NEXTREVDT);
                setNextReviewDate(date);
                setOriginalNextReviewDate(date);
            }
        } else {
            setReviewCycle(null);
            setReviewCycleJustification('');
            setNextReviewDate(null);
        }

        setDocRevs(props.docRevs);
    }, [props.docRevs]);

    const hasDocRevs = docRevs !== null && docRevs.length > 0;
    const isBulkEdit = hasDocRevs && docRevs.length > 1;

    if (!hasDocRevs) {
        return null;
    }

    const docTitle = !isBulkEdit ? docRevs[0].TITLE : 'Multiple Documents';
    const docID = !isBulkEdit ? docRevs[0].DOCID : 'Multiple Documents';
    const revisionNumber = !isBulkEdit ? docRevs[0].REVNO : 'Multiple Documents';

    function getDateString(date: Date | string | number | null | undefined): string {
        if (date === null || date === undefined) {
            return '';
        }

        if (typeof date === 'string' || typeof date === 'number') {
            date = new Date(date);
        }

        return date.toISOString().split('T')[0];
    }

    function onSave() {
        if (!hasDocRevs) {
            return;
        }

        const err = validateNextReviewDate();
        if (err) {
            alert(err.message);
            return;
        }

        docRevs.forEach(docRev => {            
            docRev.REVIEW_CYCLE = reviewCycle;
            docRev.REVIEW_CYCLE_JUSTIFICATION = reviewCycleJustification;

            if (!retainNextReviewDate) {
                docRev.NEXTREVDT = nextReviewDate;
            }
        });
        props.onSave(docRevs);
    }

    function validateNextReviewDate(): Error | null {
        if (!reviewCycle || !nextReviewDate) {
            return null;
        }

        const maxDate = calculateNextRevDT(reviewCycle);
        if (maxDate && maxDate < nextReviewDate) {
            return new Error(`The selected date is beyond the maximum review date (${getDateString(maxDate)}).`);
        }

        return null;
    }

    function calculateNextRevDT(newReviewCycle: number | null): Date | null {
        if (!hasDocRevs) {
            return null;
        }

        const oldNextRevDT = originalNextReviewDate;
        const oldReviewCycle = reviewCycle ?? 0;

        if (!newReviewCycle || !oldNextRevDT || retainNextReviewDate) {
            return oldNextRevDT;
        }

        const referenceDate = new Date(oldNextRevDT);
        referenceDate.setFullYear(referenceDate.getFullYear() - oldReviewCycle);

        const newReviewDate = new Date(referenceDate);
        newReviewDate.setFullYear(newReviewDate.getFullYear() + newReviewCycle);

        return newReviewDate;
    }

    const reviewCycleOptions = [0, 1, 2, 3, 4, 5]
        .map(cycle => {
            let label;
            if (cycle === 0) {
                label = 'None';
            } else if (cycle === 1) {
                label = '1 year';
            } else {
                label = `${cycle} years`;
            }
            return {
                label: label,
                value: cycle > 0 ? cycle : null
            };
        });

    return (
        <LucidocModal
            open={props.isOpen}
            onClose={props.onClose}
            title="Edit Review Date or Review Cycle"
            onSave={onSave}
        >
            <p>{docTitle}</p>
            <p>Doc ID: {docID}</p>
            <p>Revision Number: {revisionNumber}</p>
            <div className={classes.inputContainer}>
                <div className={classes.reviewCycleContainer}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={retainNextReviewDate}
                                onChange={() => setRetainNextReviewDate(!retainNextReviewDate)}
                                style={{ color: LucidocColors.purple }}
                            />
                        }
                        label={
                            <span style={{ fontFamily: 'Quattrocento Sans' }}>
                                Retain current Next Review Date of selected document(s)
                            </span>
                        }
                    />
                </div>
                <div className={classes.reviewCycleContainer}>
                    <label>Review Cycle:</label>
                    <ReactSelect
                        placeholder={'Select the new review cycle'}
                        onChange={(selected) => {
                            const newReviewCycle = selected ? selected.value : null;
                            const nextRevDT = calculateNextRevDT(newReviewCycle);

                            setReviewCycle(newReviewCycle);
                            setNextReviewDate(nextRevDT);
                            setOriginalNextReviewDate(nextRevDT);
                        }}
                        options={reviewCycleOptions}
                        value={reviewCycleOptions.find(option => option.value === reviewCycle)}
                        isClearable={false}
                    />
                </div>
                <div className={classes.reviewCycleContainer}>
                    <label>Review Cycle Justification:</label>
                    <TextareaAutosize
                        value={reviewCycleJustification}
                        onChange={event => {
                            setReviewCycleJustification(event.target.value);
                        }}
                        cols={50}
                        minRows={5}
                    />
                </div>
                <div className={classes.nextReviewDatePicker}>
                    <DatePicker
                        label='Next Review Date'
                        value={getDateString(nextReviewDate)}
                        onChange={(newDateStr) => {
                            if (!newDateStr) {
                                return;
                            }

                            const date = new Date(newDateStr);
                            setNextReviewDate(date);
                        }}
                        disabled={retainNextReviewDate}
                        defaultValue={getDateString(originalNextReviewDate)}
                        fullWidth
                    />
                </div>
            </div>
        </LucidocModal>
    );
}
