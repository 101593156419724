import React, { useContext, useEffect, useState } from 'react';

import { helpTopicDocIDs } from '@/_shared/constants/HelpSystemIDs';
import { useBrowserQuery } from '@/hooks/useBrowserQuery';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogHelpTopicIcon, DialogTitle, DialogTrigger } from '@/ui/ui/dialog';
import { Tabs, TabsList, TabsTrigger } from '@/ui/ui/tabs';
import { TabsContent } from '@radix-ui/react-tabs';
import useSWR from 'swr';
import { CommitteesQuery } from '../Committees';
import { EditCommitteesDialogInfoTab } from './editcommitteesdialog/EditCommitteesDialogInfoTab';
import { COMMITTEE } from '@/entities/org/COMMITTEE';
import { EditCommitteesDialogAgendasTab } from './editcommitteesdialog/EditCommitteesDialogAgendasTab';
import { EditCommitteesDialogArchivedAgendas } from './editcommitteesdialog/EditCommitteesDialogArchivedAgendasTab';
import UserBundleContext from '../../../../context/UserBundleContext';
import { RightsCodeKey } from '@/entities/master/PERSON';
import hasRights from '@/_shared/utils/hasRights';

interface EditCommitteeDialogProps {
    onEdit: () => void;
}

export enum EditCommitteeTabStates {
    Info = 'info',
    Agendas = 'agendas',
    ArchivedAgendas = 'archivedAgendas'
}

export const EditCommitteeDialog: React.FC<EditCommitteeDialogProps> = (props: EditCommitteeDialogProps) => {
    const [query, setQuery] = useBrowserQuery<CommitteesQuery>();
    const userBundle = useContext(UserBundleContext);

    const [tab, setTab] = useState<EditCommitteeTabStates>(EditCommitteeTabStates.Info);
    const handleTabChange = (value: string) => {
        setTab(value as EditCommitteeTabStates);
        setQuery({ ...query, editCommitteeTab: value as EditCommitteeTabStates });
    };

    const { data: committeeData, mutate: refetchCommittee } =
        useSWR<{ committee: COMMITTEE }>(`/api/committee/${query.editingCommitteeId}`);

    useEffect(() => {
        setTab(query.editCommitteeTab ?? EditCommitteeTabStates.Info);
    }, []);

    const isChairPersonOrSecretary = (
        userBundle.user?.USERID === committeeData?.committee.SECRETARY_USERID ||
        userBundle.user?.USERID === committeeData?.committee.CHAIRPERSON_USERID
    );
    const isCollaborator = (committeeData?.committee.COLLABORATORS ?? []).some(person => person.USERID === userBundle.user?.USERID);

    const isCommitteeAdmin = hasRights(
        userBundle,
        [
            RightsCodeKey.DocumentAdministrator,
            RightsCodeKey.OrganizationAdministrator
        ]
    );

    return (
        <Dialog
            open={!!query.editingCommitteeId}
            onOpenChange={() => {
                setQuery({ 
                    ...query,
                    editingCommitteeId: undefined,
                    editCommitteeTab: undefined,
                    createAgendaDialogOpen: undefined
                });
            }}
        >
            <DialogTrigger asChild>
            </DialogTrigger>
            <DialogContent
                className='max-w-6xl overflow-auto h-full sm:h-auto sm:max-h-[95dvh]'
                style={{
                    height: '90dvh',
                    display: 'grid',
                    gridTemplateRows: '2rem auto'
                }}
            >
                <DialogHeader>
                    <DialogTitle>Edit Committee<DialogHelpTopicIcon helpTopicId={helpTopicDocIDs['ADMIN_COMMITTEES']} />
                    </DialogTitle>
                    <DialogDescription>{committeeData?.committee.NAME}</DialogDescription>
                </DialogHeader>
                <Tabs defaultValue={EditCommitteeTabStates.Info} value={tab} onValueChange={handleTabChange} className='mt-3'>
                    <TabsList
                        defaultValue={EditCommitteeTabStates.Info}
                    >
                        <TabsTrigger value={EditCommitteeTabStates.Info}>Info</TabsTrigger>
                        <TabsTrigger 
                            disabled={
                                !(isChairPersonOrSecretary || isCollaborator || isCommitteeAdmin)
                            }
                            value={EditCommitteeTabStates.Agendas}
                        >
                            Agendas
                        </TabsTrigger>
                        <TabsTrigger
                            disabled={
                                !(isChairPersonOrSecretary || isCollaborator || isCommitteeAdmin)
                            }
                            value={EditCommitteeTabStates.ArchivedAgendas}
                        >
                            Archived Agendas
                        </TabsTrigger>
                    </TabsList>
                    <TabsContent value={EditCommitteeTabStates.Info}>
                        <EditCommitteesDialogInfoTab
                            onEdit={
                                async () => {
                                    refetchCommittee();
                                    props.onEdit();
                                } 
                            }
                        />
                    </TabsContent>
                    <TabsContent value={EditCommitteeTabStates.Agendas}>
                       <EditCommitteesDialogAgendasTab/>
                    </TabsContent>
                    <TabsContent value={EditCommitteeTabStates.ArchivedAgendas}>
                       <EditCommitteesDialogArchivedAgendas/>
                    </TabsContent>
                </Tabs>
            </DialogContent>
        </Dialog >
    );
};


export const Section = ({ children }) => {
    return <div className='flex flex-col w-full'>
        {children}
    </div>;
};