import React, { useContext, useState } from 'react';
import { INFORMATION_HIERARCHY } from '../../../../entities/org/INFORMATION_HIERARCHY';
import Subheader from '../../../_shared/Subheader/Subheader';
import SubheaderItem from '../../../_shared/Subheader/SubheaderItem';
import { EditIhGeneral } from './EditIhGeneral';
import { makeStyles } from '@material-ui/core';
import { EditItemVisibility } from '../../../_shared/Forms/EditItemVisibility/EditItemVisibility';
import { EditItemCollaborators } from '../../../_shared/Forms/EditItemCollaborators/EditItemCollaborators';
import UserBundleContext from '../../../../context/UserBundleContext';
import hasRights from '../../../../_shared/utils/hasRights';
import { EditIhDocuments } from './EditIhDocuments';
import { EditIhImage } from './EditIhImage';
import { RightsCodeKey } from '@/entities/master/PERSON';

type EditIhProps = {
    ihType: 'department' | 'manual'
    ih: INFORMATION_HIERARCHY
    sendIhToParent: (dep: INFORMATION_HIERARCHY) => void
    closeModal?: () => void
    isInAdmin: boolean
}

const useStyles = makeStyles({
    outerDiv: {
        height: 'calc(100vh - 128px)',
        width: 'calc(100vw - 128px)',
    },
});

export function EditInfoHierarchy(props: EditIhProps) {
    const classes = useStyles();

    const context = useContext(UserBundleContext);

    const isOwner = props.ih.USERID === context.user?.USERID;
    const isCollaborator = !!props.ih.COLLABORATORS?.find(collaborator => collaborator.USERID === context.user?.USERID);
    const isOrgAdmin = hasRights(context, RightsCodeKey.OrganizationAdministrator);

    const ihSubType = props.ihType === 'department' ? 'DEPARTMENT' : 'MANUAL';

    const Tabs: {[key: string]: string} = {
        General : 'General',
    };

    if (props.ih.INFORMATION_HIERARCHY_ID // if no ID, it means we're creating a new ih
        && (isOwner || isOrgAdmin || isCollaborator)
    ) {
        Tabs.Collaborators = 'Collaborators';
        Tabs.Visibility    = 'Visibility';
        Tabs.Documents     = 'Documents';

        if (props.ihType === 'department') {
            Tabs.Image = 'Image';
        }
    }

    const [ selectedTab, setSelectedTab ] = useState<string>(Tabs.General);
    const allowedTabs = Object.keys(Tabs).filter(tab => {
        if (isOwner || isOrgAdmin) {
            return true;
        }

        // department collaborators have access to these tabs
        return isCollaborator && [Tabs.General, Tabs.Documents].includes(tab);
    });

    return (
        <div className={classes.outerDiv}>
            <Subheader
                value={selectedTab}
                onChange={value => setSelectedTab(value)}
            >
                {allowedTabs.map(tab => <SubheaderItem key={Tabs[tab]} label={Tabs[tab]} value={Tabs[tab]} />)}
            </Subheader>

            {selectedTab === Tabs.General &&
                <EditIhGeneral
                    ihType={props.ihType}
                    ih={props.ih}
                    closeModal={() => props.closeModal?.()}
                    sendIhToParent={ih => props.sendIhToParent(ih)}
                />
            }

            {selectedTab === Tabs.Visibility &&
                <EditItemVisibility
                    objectType={'INFORMATION_HIERARCHY_ID'}
                    infoHierarchySubType={ihSubType}
                    objectID={props.ih.INFORMATION_HIERARCHY_ID}
                    closeModal={() => props.closeModal?.()}
                />
            }

            {selectedTab === Tabs.Collaborators &&
                <EditItemCollaborators
                    objectType={'INFORMATION_HIERARCHY_ID'}
                    infoHierarchySubType={ihSubType}
                    objectID={props.ih.INFORMATION_HIERARCHY_ID}
                    closeModal={() => props.closeModal?.()}
                />
            }

            {selectedTab === Tabs.Documents &&
                <EditIhDocuments
                    isInAdmin={props.isInAdmin}
                    ihType={props.ihType}
                    ih={props.ih}
                    isCollaborator={isCollaborator}
                />
            }

            {props.ihType === 'department' && selectedTab === Tabs.Image &&
                <EditIhImage
                    ihID={props.ih.INFORMATION_HIERARCHY_ID}
                    closeModal={() => props.closeModal?.()}
                />
            }

        </div>
    );
}
