import {
    Column,
    Entity,
    OneToMany,
    OneToOne,
    PrimaryColumn,
} from 'typeorm';

import { PERSON,  } from './PERSON';
import { PERSON_ORGANIZATION,  } from './PERSON_ORGANIZATION';
import { LINK,  } from './LINK';
import { DOC_REQUEST_HANDLER } from '../org/DOC_REQUEST_HANDLER';
import {  numberToBooleanTransformer, stringToBooleanTransformer } from '../_helperFunctions/transformers';
import { SAML_PROFILE,  } from './SAML_PROFILE';
import { SchemaEntityManager } from '../SchemaEntityManager';

export enum OrganizationStatuses {
    active = 1,
    disabled = 2
}

export enum GlobalIdentifierAvailabilities {
    all = 'all',
    none = 'none',
    standard_and_model = 'standard_and_model'
}

/**
 * To completely create a link, you must create one of these records, AND ALSO a record
 *  in the DOCUMENTREVISION_LINK table (stored in each org schema, not master).
 * 
 * @param schema - This schema is the organization schema. ORGANIZATION has a decorator specifying schema: MASTER, but still has relations to a parameter specified organization schema
 * 
 * @returns 
 */

function createOrganizationEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('ORGANIZATION', { schema: 'MASTER' })
    class ORGANIZATION {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'ORGANIZATIONID'
        })
        ORGANIZATIONID!: number;

        @Column('char', {
            nullable: false,
            default: () => '0',
            name: 'IS_IMPERSONATE_SIGNER_ENABLED'
        })
        IS_IMPERSONATE_SIGNER_ENABLED!: string;

        @Column('char', {
            nullable: false,
            default: () => '1',
            name: 'REGULAR_LOGOUT'
        })
        REGULAR_LOGOUT!: string;

        @Column('char', {
            nullable: false,
            default: () => '1',
            name: 'ACTIVE_DIRECTORY_LOGOUT'
        })
        ACTIVE_DIRECTORY_LOGOUT!: string;

        @Column('char', {
            nullable: false,
            default: () => '0',
            name: 'CERTIFICATE_LOGOUT'
        })
        CERTIFICATE_LOGOUT!: string;

        @Column('varchar2', {
            nullable: false,
            length: 30,
            default: () => '\'start_date\'',
            name: 'REVIEW_DATE_DISPLAY_PREFERENCE'
        })
        REVIEW_DATE_DISPLAY_PREFERENCE!: string;

        @Column('varchar2', {
            nullable: true,
            length: 40,
            name: 'ADDRESS1'
        })
        ADDRESS1!: string | null;

        @Column('varchar2', {
            nullable: true,
            length: 40,
            name: 'ADDRESS2'
        })
        ADDRESS2!: string | null;

        @Column('varchar2', {
            nullable: true,
            length: 40,
            name: 'CITY'
        })
        CITY!: string | null;

        @Column('varchar2', {
            nullable: true,
            length: 40,
            name: 'STATE'
        })
        STATE!: string | null;

        @Column('varchar2', {
            nullable: true,
            length: 10,
            name: 'POSTALCODE'
        })
        POSTALCODE!: string | null;

        @Column('varchar2', {
            nullable: true,
            length: 20,
            name: 'COUNTRY'
        })
        COUNTRY!: string | null;

        @Column('timestamp', {
            nullable: true,
            name: 'ACTIVE_UNTIL'
        })
        ACTIVE_UNTIL!: Date | null;

        @Column('varchar2', {
            nullable: true,
            length: 40,
            name: 'CONTACTNAME'
        })
        CONTACTNAME!: string | null;

        @Column('varchar2', {
            nullable: true,
            length: 40,
            name: 'CONTACTPHONE'
        })
        CONTACTPHONE!: string | null;

        @Column('varchar2', {
            nullable: true,
            length: 20,
            name: 'CONTACTFAX'
        })
        CONTACTFAX!: string | null;

        @Column('varchar2', {
            nullable: true,
            length: 20,
            name: 'CONTACTPAGER'
        })
        CONTACTPAGER!: string | null;

        @Column('varchar2', {
            nullable: true,
            length: 30,
            name: 'DATABASEUID'
        })
        DATABASEUID!: string | null;

        @Column('varchar2', {
            nullable: true,
            length: 30,
            name: 'DATABASEPWD'
        })
        DATABASEPWD!: string | null;

        @Column('varchar2', {
            nullable: true,
            length: 60,
            name: 'DATABASESID'
        })
        DATABASESID!: string | null;

        @Column('varchar2', {
            nullable: true,
            length: 60,
            name: 'EMAILDOMAIN'
        })
        EMAILDOMAIN!: string | null;

        @Column('varchar2', {
            nullable: true,
            length: 60,
            name: 'IMAGEDIR'
        })
        IMAGEDIR!: string | null;

        @Column('varchar2', {
            nullable: true,
            length: 40,
            name: 'ORGUNITNAME'
        })
        ORGUNITNAME!: string | null;

        @Column('varchar2', {
            nullable: true,
            length: 40,
            name: 'ORGUNITOWNERNAME'
        })
        ORGUNITOWNERNAME!: string | null;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'TECHSUPPORTID'
        })
        TECHSUPPORTID!: number | null;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'ADMINID'
        })
        ADMINID!: number | null;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'SYSADMINID'
        })
        SYSADMINID!: number | null;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'FEEDBACKID'
        })
        FEEDBACKID!: number | null;

        @Column('varchar2', {
            nullable: true,
            length: 40,
            name: 'ORGUNITPLURAL'
        })
        ORGUNITPLURAL!: string | null;

        @Column('varchar2', {
            nullable: true,
            length: 60,
            name: 'FEEDBACK_SUBJECT'
        })
        FEEDBACK_SUBJECT!: string | null;

        @Column('varchar2', {
            nullable: true,
            length: 1000,
            name: 'STANDARD_ELEMENTS'
        })
        STANDARD_ELEMENTS!: string | null;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'TEMPLATE_PROCID'
        })
        TEMPLATE_PROCID!: number | null;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'ORG_PUBLISHERID'
        })
        ORG_PUBLISHERID!: number | null;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'WHATSNEW_LIFE'
        })
        WHATSNEW_LIFE!: number | null;

        @Column('char', {
            nullable: true,
            name: 'SENDMAIL'
        })
        SENDMAIL!: string | null;

        @Column('clob', {
            nullable: true,
            name: 'CSS'
        })
        CSS!: string | null;

        @Column('varchar2', {
            nullable: true,
            length: 300,
            name: 'PRINTTAG'
        })
        PRINTTAG!: string | null;

        @Column('char', {
            nullable: true,
            length: 32,
            name: 'DISPLAYCONTROL'
        })
        DISPLAYCONTROL!: string | null;

        @Column('char', {
            nullable: true,
            length: 32,
            name: 'FUNCTIONCONTROL'
        })
        FUNCTIONCONTROL!: string | null;

        // FUNCTIONCONTROL is another one of those stupid strings of punctuation marks that's supposed to
        // mean something in English. See PERSON.ts, getRightsInEnglish() for a full explanation.
        //
        // Here's some code to run to see an example of what should happen:
        //
        // let str =  '!%#*           .                ' // this is for Lucidoc Training
        //
        // let bb = { // bytes and bits
        //     // tested successfully:
        //     autoSign: [0,2],
        //     cycleWarning: [1,1],
        //     signFailToAdmin: [1,4],
        //
        //     // not yet tested (and I don't know what some of these mean), but I'm pretty sure these are the values:
        //     useSignature: [0,1], // deprecated; everyone uses signatures now
        //     feedbackToUser: [1,2],
        //     sequentialSign: [1,8],
        //     requireHTTPS: [2,1],
        //     useACLs: [2,2],
        //     useLevels: [2,4]
        // }
        //
        // // tested successfully:
        // console.log('Sign Automatically on Publication:', (str.charCodeAt(bb.autoSign[0]       ) & bb.autoSign[1]       ) > 0 ? true : false)
        // console.log('Send Cycle Warning:',                (str.charCodeAt(bb.cycleWarning[0]   ) & bb.cycleWarning[1]   ) > 0 ? true : false)
        // console.log('Sign Fail to Admin:',                (str.charCodeAt(bb.signFailToAdmin[0]) & bb.signFailToAdmin[1]) > 0 ? true : false)
        //
        // // not yet tested:
        // console.log('Use Signatures:',               (str.charCodeAt(bb.useSignature[0]  ) & bb.useSignature[1]  ) > 0 ? true : false)
        // console.log('CC Feedback to Feedback User:', (str.charCodeAt(bb.feedbackToUser[0]) & bb.feedbackToUser[1]) > 0 ? true : false)
        // console.log('Sequential Signing:',           (str.charCodeAt(bb.sequentialSign[0]) & bb.sequentialSign[1]) > 0 ? true : false)
        // console.log('Require HTTPS:',                (str.charCodeAt(bb.requireHTTPS[0]  ) & bb.requireHTTPS[1]  ) > 0 ? true : false)
        // console.log('Use ACLs:',                     (str.charCodeAt(bb.useACLs[0]       ) & bb.useACLs[1]       ) > 0 ? true : false)
        // console.log('Use Access Levels:',            (str.charCodeAt(bb.useLevels[0]     ) & bb.useLevels[1]     ) > 0 ? true : false)

        @Column('varchar2', {
            nullable: true,
            length: 48,
            name: 'MANUALNAME'
        })
        MANUALNAME!: string | null;

        @Column('varchar2', {
            nullable: true,
            length: 48,
            name: 'MANUALNAMEPLURAL'
        })
        MANUALNAMEPLURAL!: string | null;

        @Column('varchar2', {
            nullable: true,
            name: 'DOMAIN'
        })
        DOMAIN!: string | null;

        @Column('varchar2', {
            nullable: true,
            length: 60,
            name: 'NAME'
        })
        NAME!: string | null;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'STATUSID'
        })
        STATUSID!: number | null;

        @Column('varchar2', {
            nullable: true,
            length: 16,
            name: 'PREFIX'
        })
        PREFIX!: string | null;

        @Column('varchar2', {
            nullable: true,
            length: 120,
            name: 'SITEURL'
        })
        SITEURL!: string | null;

        @Column('clob', {
            nullable: true,
            name: 'LAYOUT'
        })
        LAYOUT!: string | null;

        @Column('blob', {
            nullable: true,
            name: 'BACKGROUNDBMP'
        })
        BACKGROUNDBMP!: Buffer | null;

        @Column('blob', {
            nullable: true,
            name: 'LOGO'
        })
        LOGO!: Buffer | null;

        @Column('blob', {
            nullable: true,
            name: 'DOCLOGO'
        })
        DOCLOGO!: Buffer | null;

        @Column('blob', {
            nullable: true,
            name: 'HEADERBMP'
        })
        HEADERBMP!: Buffer | null;

        @Column('varchar2', {
            nullable: true,
            length: 200,
            name: 'GRAPHICEXT'
        })
        GRAPHICEXT!: string | null;

        @Column('varchar2', {
            nullable: true,
            length: 4000,
            name: 'PROPERTYHASH'
        })
        PROPERTYHASH!: string | null;

        @Column('clob', {
            nullable: true,
            name: 'MAILTEMPLATE'
        })
        MAILTEMPLATE!: string | null;

        @Column('char', {
            nullable: true,
            name: 'USECOMPETENCY'
        })
        USECOMPETENCY!: string | null;

        @Column('varchar2', {
            nullable: true,
            length: 2000,
            name: 'FUNCTIONALITY'
        })
        FUNCTIONALITY!: string | null;

        @Column('number', {
            nullable: true,
            precision: 3,
            scale: 0,
            name: 'DEFAULTACCESSLEVEL'
        })
        DEFAULTACCESSLEVEL!: number | null;

        @Column('number', {
            nullable: false,
            default: () => '0',
            precision: 2,
            scale: 0,
            name: 'PWD_REQUIRED_LENGTH'
        })
        PWD_REQUIRED_LENGTH!: number;

        @Column('number', {
            nullable: false,
            default: () => '0',
            precision: 1,
            scale: 0,
            name: 'PWD_REQUIRED_COMPLEXITY'
        })
        PWD_REQUIRED_COMPLEXITY!: number;

        @Column('number', {
            nullable: false,
            default: () => '0',
            precision: 3,
            scale: 0,
            name: 'PWD_EXPIRATION_DAYS'
        })
        PWD_EXPIRATION_DAYS!: number;

        @Column('number', {
            nullable: false,
            default: () => '0',
            precision: 2,
            scale: 0,
            name: 'PWD_REQUIRED_UNIQUES'
        })
        PWD_REQUIRED_UNIQUES!: number;

        @Column('number', {
            nullable: false,
            default: () => '0',
            precision: 1,
            scale: 0,
            name: 'PWD_DEFAULT_TO_USERNAME'
        })
        PWD_DEFAULT_TO_USERNAME!: number;

        @Column('number', {
            nullable: false,
            default: () => '0',
            precision: 3,
            scale: 0,
            name: 'PWD_EXPIRATION_WARNING_DAYS'
        })
        PWD_EXPIRATION_WARNING_DAYS!: number;

        @Column('number', {
            nullable: false,
            default: () => '0',
            precision: 2,
            scale: 0,
            name: 'PWD_LOCKOUT_LIMIT'
        })
        PWD_LOCKOUT_LIMIT!: number;

        @Column('number', {
            nullable: false,
            default: () => '0',
            precision: 3,
            scale: 0,
            name: 'INACTIVITYLOCKOUT'
        })
        INACTIVITYLOCKOUT!: number;

        @Column('timestamp', {
            nullable: true,
            name: 'ACK_ACTIVATED'
        })
        ACK_ACTIVATED!: Date | null;

        @Column('timestamp', {
            nullable: true,
            name: 'ACK_CUSTOMER_ACTIVATED'
        })
        ACK_CUSTOMER_ACTIVATED!: Date | null;

        @Column('varchar2', {
            nullable: true,
            length: 20,
            default: () => '\'Acknowledgement\'',
            name: 'ACK_SUBSTITUTION_TEXT'
        })
        ACK_SUBSTITUTION_TEXT!: string | null;

        @Column('varchar2', {
            nullable: true,
            length: 20,
            default: () => '\'Acknowledgements\'',
            name: 'ACK_SUBSTITUTION_TEXT_PLURAL'
        })
        ACK_SUBSTITUTION_TEXT_PLURAL!: string | null;

        @Column('varchar2', {
            nullable: true,
            length: 1,
            name: 'ACK_EMAIL_EDITABLE'
        })
        ACK_EMAIL_EDITABLE!: string | null;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'ACK_DEFAULT_DOCID'
        })
        ACK_DEFAULT_DOCID!: number | null;

        @Column('number', {
            nullable: true,
            default: () => '30',
            precision: 5,
            scale: 0,
            name: 'ACK_WARNING_PERIOD'
        })
        ACK_WARNING_PERIOD!: number | null;

        @Column('number', {
            nullable: true,
            default: () => '7',
            precision: 5,
            scale: 0,
            name: 'ACK_REMINDER_FREQUENCY'
        })
        ACK_REMINDER_FREQUENCY!: number | null;

        @Column('timestamp', {
            nullable: true,
            default: () => 'sysdate',
            name: 'DEFAULT_DOCID_ACTIVATED'
        })
        DEFAULT_DOCID_ACTIVATED!: Date | null;

        @Column('varchar2', {
            nullable: true,
            length: 12,
            default: () => '\'CRYPT\'',
            name: 'AUTH_TYPE'
        })
        AUTH_TYPE!: string | null;

        @Column('varchar2', {
            nullable: true,
            length: 100,
            name: 'TOKEN'
        })
        TOKEN!: string | null;

        @Column('varchar2', {
            nullable: true,
            name: 'LOGIN_URL'
        })
        LOGIN_URL!: string | null;

        @Column('varchar2', {
            nullable: true,
            length: 30,
            default: () => '\'Acknowledged\'',
            name: 'ACK_SUBSTITUTION_TEXT_PAST'
        })
        ACK_SUBSTITUTION_TEXT_PAST!: string | null;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'ACK_DOCTYPE_ID'
        })
        ACK_DOCTYPE_ID!: number | null;

        @Column('number', {
            nullable: true,
            default: () => '1',
            precision: 5,
            scale: 0,
            name: 'ACK_OOC_REMINDER_FREQUENCY'
        })
        ACK_OOC_REMINDER_FREQUENCY!: number | null;

        @Column('varchar2', {
            nullable: true,
            length: 30,
            default: () => '\'Acknowledge\'',
            name: 'ACK_SUBSTITUTION_TEXT_VERB'
        })
        ACK_SUBSTITUTION_TEXT_VERB!: string | null;

        @Column('char', {
            nullable: false,
            default: () => '\'0\'',
            name: 'SUPPRESS_ORGSWITCHING'
        })
        SUPPRESS_ORGSWITCHING!: string;

        @Column('timestamp', {
            nullable: true,
            name: 'SIGNATURE_REMINDER_ENABLED'
        })
        SIGNATURE_REMINDER_ENABLED!: Date | null;

        @Column('varchar2', {
            nullable: true,
            name: 'LOGOUT_URL'
        })
        LOGOUT_URL!: string | null;

        // This column appears to have been removed, both in production and development:
        // @Column('char', {
        //     nullable: false,
        //     default: () => '\'0\'',
        //     name: 'INTEGER_REVNO_IN_USE'
        // })
        // INTEGER_REVNO_IN_USE!: string;

        // This column appears to have been removed, both in production and development:
        // @Column('char', {
        //     nullable: false,
        //     default: () => '\'0\'',
        //     name: 'SUBREVISIONS_IN_USE'
        // })
        // SUBREVISIONS_IN_USE!: string;

        @Column('timestamp', {
            nullable: true,
            name: 'THREADED_FEEDBACK_ENABLED'
        })
        THREADED_FEEDBACK_ENABLED!: Date | null;

        @Column('number', {
            nullable: true,
            name: 'COMMITTEE_MINUTES_DOCTYPEID'
        })
        COMMITTEE_MINUTES_DOCTYPEID!: number | null;

        // This column appears to have been removed, both in production and development:
        // @Column('number', {
        //     nullable: true,
        //     name: 'COMMITTEE_MINUTES_TEMPLATEID'
        // })
        // COMMITTEE_MINUTES_TEMPLATEID!: number | null;

        @Column('varchar2', {
            nullable: true,
            length: 30,
            name: 'PUBLISHING_SCHEMA'
        })
        PUBLISHING_SCHEMA!: string | null;

        @Column('varchar2', {
            nullable: true,
            length: 30,
            name: 'SUBSCRIBER_SCHEMA'
        })
        SUBSCRIBER_SCHEMA!: string | null;

        @Column('number', {
            nullable: true,
            default: () => 0,
            transformer: numberToBooleanTransformer,
            name: 'ROUTING_REMINDER_ENABLED'
        })
        ROUTING_REMINDER_ENABLED!: boolean | null;

        @Column('number', {
            nullable: false,
            default: () => '0',
            name: 'CONSENT_AGENDAS_ENABLED'
        })
        CONSENT_AGENDAS_ENABLED!: number;

        @Column('number', {
            nullable: false,
            default: () => '0',
            name: 'CONSENT_AGENDAS_ACTIVATED'
        })
        CONSENT_AGENDAS_ACTIVATED!: number;

        @Column('char', {
            nullable: false,
            default: () => '0',
            name: 'ACK_FEATURE_IS_ENABLED'
        })
        ACK_FEATURE_IS_ENABLED!: string;

        @Column('char', {
            nullable: false,
            default: () => '0',
            name: 'DOCOWNER_CAN_EDIT_ROUTE',
            transformer: stringToBooleanTransformer
        })
        DOCOWNER_CAN_EDIT_ROUTE!: boolean | null;

        @Column('varchar2', {
            nullable: true,
            length: 200,
            name: 'NETWORK_NAME'
        })
        NETWORK_NAME!: string | null;

        @Column('char', {
            nullable: false,
            default: () => '0',
            name: 'IS_ACCESS_LEVELS_ENABLED'
        })
        IS_ACCESS_LEVELS_ENABLED!: string;

        @Column('char', {
            nullable: false,
            default: () => '1',
            name: 'IS_ACCESS_LISTS_ENABLED'
        })
        IS_ACCESS_LISTS_ENABLED!: string;

        @Column('char', {
            nullable: false,
            default: () => '0',
            name: 'IS_ACCMAN_ENABLED'
        })
        IS_ACCMAN_ENABLED!: string;

        @Column('blob', {
            nullable: true,
            name: 'MOBILE_LOGO'
        })
        MOBILE_LOGO!: Buffer | null;

        @Column('char', {
            nullable: false,
            default: () => '1',
            name: 'REVIEW_APPROVALS_DEFAULT'
        })
        REVIEW_APPROVALS_DEFAULT!: string;

        @Column('number', {
            nullable: false,
            name: 'ALLOW_DOC_REQUESTS',
            default: () => 0,
            transformer: numberToBooleanTransformer
        })
        ALLOW_DOC_REQUESTS!: boolean;

        @Column('number', {
            nullable: false,
            name: 'REQUIRE_PASSWORD_ON_SIGN',
            default: () => 0,
            transformer: numberToBooleanTransformer
        })
        REQUIRE_PASSWORD_ON_SIGN!: boolean;

        @Column('number', {
            nullable: false,
            precision: 1,
            scale: 0,
            default: () => 1,
            name: 'ALLOW_BULK_SIGNING',
            transformer: numberToBooleanTransformer
        })
        ALLOW_BULK_SIGNING!: boolean;

        @Column('varchar2', {
            nullable: true,
            name: 'GLOBAL_IDENTIFIER_NAME',
        })
        GLOBAL_IDENTIFIER_NAME!: string | null;

        @Column('varchar2', {
            nullable: false,
            default: () => 'none',
            name: 'GLOBAL_IDENTIFIER_AVAILABILITY',
        })
        GLOBAL_IDENTIFIER_AVAILABILITY!: string;

        @Column('number', {
            nullable: false,
            name: 'MY_EDUCATION_IS_ENABLED',
            default: () => 0,
            transformer: numberToBooleanTransformer
        })
        MY_EDUCATION_IS_ENABLED!: boolean | null;

        @Column('number', {
            nullable: false,
            precision: 1,
            scale: 0,
            default: () => 0,
            name: 'ENABLE_CERTIFICATE_LOGIN',
            transformer: numberToBooleanTransformer
        })
        ENABLE_CERTIFICATE_LOGIN!: boolean;

        @Column('number', {
            nullable: false,
            precision: 1,
            scale: 0,
            default: () => 0,
            name: 'HAS_BETA_ACCESS',
            transformer: numberToBooleanTransformer
        })
        HAS_BETA_ACCESS!: boolean;

        @OneToMany(
            () => manager.getPersonEntity(schema),
            (person) => person.ORGANIZATION
        )
        PEOPLE!: PERSON[] | null;

        @OneToMany(
            () => manager.getPersonOrganizationEntity(schema),
            (personOrganization) => personOrganization.ORGANIZATION
        )
        PERSON_ORGANIZATIONS!: PERSON_ORGANIZATION[] | null;

        @OneToMany(
            () => manager.getLinkEntity(schema),
            (link) => link.REFERENCED_ORG
        )
        LINKS!: LINK[] | null;

        @OneToOne(
            () => manager.getSamlProfileEntity(schema),
            (samlProfile) => samlProfile.ORGANIZATION
        )
        SAML_PROFILE!: SAML_PROFILE | null;

        // This is not a true database relation (since ORGANIZATION is in master and DOC_REQUEST_HANDLER is stored in each org)
        // but it's convenient to store this here. This value is filled by the query that creates the UserBundle context object.
        DOC_REQUEST_HANDLERS!: DOC_REQUEST_HANDLER[] | null;
    }

    return ORGANIZATION;
}

export { createOrganizationEntity };
export type ORGANIZATION = InstanceType<ReturnType<typeof createOrganizationEntity>>;