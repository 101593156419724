import React from 'react';

interface InvisibleDraftProps {
    size: number
}

export const InvisibleDraftDocumentIcon: React.FC<InvisibleDraftProps> = ({ size }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="16" height="16" rx="4" fill="#E8C97B" />
            <path d="M5 4.75C5 4.33579 5.33579 4 5.75 4H9L11 6V11.25C11 11.6642 10.6642 12 10.25 12H5.75C5.33579 12 5 11.6642 5 11.25V4.75Z" fill="hsl(var(--white))" />
            <rect x="6" y="4" width="1" height="1" fill="#E3E3E3" />
            <rect x="7" y="5" width="1" height="1" fill="#E3E3E3" />
            <rect x="8" y="6" width="1" height="1" fill="#E3E3E3" />
            <rect x="9" y="7" width="1" height="1" fill="#E3E3E3" />
            <rect x="7" y="7" width="1" height="1" fill="#E3E3E3" />
            <rect x="5" y="7" width="1" height="1" fill="#E3E3E3" />
            <rect x="6" y="8" width="1" height="1" fill="#E3E3E3" />
            <rect x="8" y="8" width="1" height="1" fill="#E3E3E3" />
            <rect x="5" y="9" width="1" height="1" fill="#E3E3E3" />
            <rect x="7" y="9" width="1" height="1" fill="#E3E3E3" />
            <rect x="9" y="9" width="1" height="1" fill="#E3E3E3" />
            <rect x="10" y="8" width="1" height="1" fill="#E3E3E3" />
            <path d="M5 11H6V12H5.75C5.33579 12 5 11.6642 5 11.25V11Z" fill="#E3E3E3" />
            <rect x="6" y="10" width="1" height="1" fill="#E3E3E3" />
            <rect x="6" y="10" width="1" height="1" fill="#E3E3E3" />
            <rect x="8" y="10" width="1" height="1" fill="#E3E3E3" />
            <rect x="7" y="11" width="1" height="1" fill="#E3E3E3" />
            <rect x="9" y="11" width="1" height="1" fill="#E3E3E3" />
            <rect x="10" y="10" width="1" height="1" fill="#E3E3E3" />
            <rect x="9" y="5" width="1" height="1" fill="#E3E3E3" />
            <rect x="9" y="5" width="1" height="1" fill="#E3E3E3" />
            <rect x="10" y="6" width="1" height="1" fill="#E3E3E3" />
            <path d="M8 4H9V5H8V4Z" fill="#E3E3E3" />
            <rect x="5" y="5" width="1" height="1" fill="#E3E3E3" />
            <rect x="6" y="6" width="1" height="1" fill="#E3E3E3" />
        </svg>

    );
};