import React from 'react';

interface DiscardProps {
    size: number
}

export const DiscardDocumentIcon: React.FC<DiscardProps> = ({ size }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="16" height="16" rx="4" fill="#B9B9B9" />
            <path d="M4 6.75C4 6.61193 4.11193 6.5 4.25 6.5H11.75C11.8881 6.5 12 6.61193 12 6.75V9.25C12 9.38807 11.8881 9.5 11.75 9.5H4.25C4.11193 9.5 4 9.38807 4 9.25V6.75Z" fill="hsl(var(--white))" />
        </svg>

    );
};