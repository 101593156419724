import {
    Column,
    Entity,
    JoinColumn,
    ManyToOne,
    PrimaryColumn,
} from 'typeorm';

import { COMMITTEE,  } from './COMMITTEE';
import { DOCUMENT,  } from './DOCUMENT';
import { INFORMATION_HIERARCHY,  } from './INFORMATION_HIERARCHY';
import { SchemaEntityManager } from '../SchemaEntityManager';

// Define the factory function
function createRoutingConfigurationEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('ROUTING_CONFIGURATION', { schema: schema })
    class ROUTING_CONFIGURATION {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'DOCID'
        })
        DOCID!: number | null;

        @ManyToOne(
            () => manager.getDocumentEntity(schema), 
            (document) => document.ROUTING_CONFIGURATIONS
        )
        @JoinColumn({
            name: 'DOCID'
        })
        DOCUMENT!: DOCUMENT | null;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'MANUALID'
        })
        MANUALID!: number | null;

        @ManyToOne(
            () => manager.getInformationHierarchyEntity(schema),
            (informationHierarchy) => informationHierarchy.ROUTING_CONFIGURATIONS
        )
        @JoinColumn({
            name: 'MANUALID'
        })
        MANUAL!: INFORMATION_HIERARCHY | null;

        @PrimaryColumn('number', {
            nullable: false,
            name: 'COMMITTEE_ID'
        })
        COMMITTEE_ID!: number | null;

        @ManyToOne(
            () => manager.getCommitteeEntity(schema), 
            (committee) => committee.ROUTING_CONFIGURATIONS
        )
        @JoinColumn({
            name: 'COMMITTEE_ID'
        })
        COMMITTEE!: COMMITTEE | null;

        @Column('number', {
            nullable: true,
            name: 'PHASE'
        })
        PHASE!: number | null;
    }

    return ROUTING_CONFIGURATION;
}

// Export the factory function
export { createRoutingConfigurationEntity };

// Export the type representing an instance of the class generated by the factory function
export type ROUTING_CONFIGURATION = InstanceType<ReturnType<typeof createRoutingConfigurationEntity>>;
