import {
    Column,
    Entity,
    JoinColumn,
    ManyToOne,
    OneToMany,
    PrimaryColumn,
} from 'typeorm';
import { type INSPECTION } from './INSPECTION';
import { type CORRECTIVE_ACTION_PLAN } from './CORRECTIVE_ACTION_PLAN';
import { type CHECKLIST_CONDITION } from './CHECKLIST_CONDITION';
import { SchemaEntityManager } from '../SchemaEntityManager';



// Factory function to create CHECKLIST_CONDITION_AUDIT entity with dynamic schema
function createChecklistConditionAuditEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('CHECKLIST_CONDITION_AUDIT', { schema: 'ACCREDITATIONMANAGER' })
    class CHECKLIST_CONDITION_AUDIT {

        static SCHEMANAME = schema;

        @PrimaryColumn('number', {
            nullable: false,
            name: 'CHECKLIST_CONDITION_AUDIT_ID'
        })
        CHECKLIST_CONDITION_AUDIT_ID!: number;

        @Column('number', {
            nullable: false,
            name: 'CHECKLIST_CONDITION_ID'
        })
        CHECKLIST_CONDITION_ID!: number;

        @ManyToOne(
            () => manager.getChecklistConditionEntity(schema), 
            checklistCondition => checklistCondition.CHECKLIST_CONDITION_AUDITS
        )
        @JoinColumn({
            name: 'CHECKLIST_CONDITION_ID',
        })
        CHECKLIST_CONDITION!: CHECKLIST_CONDITION | null;

        @Column('number', {
            nullable: false,
            name: 'CHECKLIST_AUDIT_ID'
        })
        CHECKLIST_AUDIT_ID!: number;

        @OneToMany(
            () => manager.getInspectionEntity(schema), 
            (inspection) => inspection.CHECKLIST_CONDITION_AUDIT
        )
        INSPECTIONS!: INSPECTION[] | null;

        @OneToMany(
            () => manager.getCorrectiveActionPlanEntity(schema), 
            (correctiveActionPlan) => correctiveActionPlan.CHECKLIST_CONDITION_AUDIT
        )
        CORRECTIVE_ACTION_PLANS!: CORRECTIVE_ACTION_PLAN[] | null;
    }

    return CHECKLIST_CONDITION_AUDIT;
}

export { createChecklistConditionAuditEntity };
export type CHECKLIST_CONDITION_AUDIT = InstanceType<ReturnType<typeof createChecklistConditionAuditEntity>>;
