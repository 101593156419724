export const LucidocFamilyOrgIDs: {[key: number]: boolean} = {
    10440: true, // Lucidoc Corp
    10542: true, // Lucidoc Training
    10560: true, // Lucidoc Screenshots
    10600: true, // Multi-Org Boston
    10547: true, // Multi-Org Corporate
    10549: true, // Multi-Org Denver
    10599: true, // Multi-Org Houston
    10598: true, // Multi-Org New York
    10548: true, // Multi-Org Seattle
};
