import {
    Column,
    Entity,
    JoinColumn,
    ManyToOne,
    OneToMany,
    PrimaryColumn,
} from 'typeorm';
import { APPROVAL_SESSION } from './APPROVAL_SESSION';
import { DOCUMENTREVISION } from './DOCUMENTREVISION';
import { SchemaEntityManager } from '../SchemaEntityManager';
import { LOCKED_FOR_EDITS } from './LOCKED_FOR_EDITS';
import { APPROVAL_SESSION_STEP } from './APPROVAL_SESSION_STEP';


// Factory function to create EDIT_REQUEST entity with dynamic schema
function createEditRequestEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('EDIT_REQUEST', { schema: schema })
    class EDIT_REQUEST {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            name: 'DOCREVID'
        })
        DOCREVID!: number;

        @ManyToOne(
            () => manager.getDocumentRevisionEntity(schema),
            (documentRevision) => documentRevision.EDIT_REQUESTS
        )
        @JoinColumn({
            name: 'DOCREVID'
        })
        DOCUMENTREVISION!: DOCUMENTREVISION | null;

        @Column('number', {
            nullable: true,
            name: 'SIGNER_USERID'
        })
        SIGNER_USERID!: number | null;

        @Column('number', {
            nullable: true,
            name: 'COMMITTEE_ID'
        })
        COMMITTEE_ID!: number | null;

        @PrimaryColumn('number', {
            nullable: false,
            name: 'APPROVAL_SESSION_ID'
        })
        APPROVAL_SESSION_ID!: number;

        @ManyToOne(
            () => manager.getApprovalSessionEntity(schema),
            (approvalSession) => approvalSession.EDIT_REQUESTS
        )
        @JoinColumn({
            name: 'APPROVAL_SESSION_ID'
        })
        APPROVAL_SESSION!: APPROVAL_SESSION | null;

        @Column('number', {
            nullable: true,
            name: 'FEEDBACKID'
        })
        FEEDBACKID!: number | null;

        @PrimaryColumn('timestamp', {
            nullable: false,
            default: () => 'SYSDATE',
            name: 'REQUESTED'
        })
        REQUESTED!: Date;

        @Column('char', {
            nullable: false,
            default: () => '0',
            name: 'SENT_TO_OWNER'
        })
        SENT_TO_OWNER!: string;

        @OneToMany(
            () => manager.getLockedForEditsEntity(schema),
            (lockedForEdits) => lockedForEdits.EDIT_REQUEST
        )
        @JoinColumn({
            name: 'APPROVAL_SESSION_ID',
            referencedColumnName: 'APPROVAL_SESSION_ID'
        })
        LOCKED_FOR_EDITS_ENTRIES!: LOCKED_FOR_EDITS[] | null;

        @OneToMany(
            () => manager.getApprovalSessionStepEntity(schema),
            (approvalSessionStep) => approvalSessionStep.EDIT_REQUEST
        )
        @JoinColumn({
            name: 'APPROVAL_SESSION_ID',
            referencedColumnName: 'APPROVAL_SESSION_ID'
        })
        APPROVAL_SESSION_STEPS!: APPROVAL_SESSION_STEP[] | null;
    }

    return EDIT_REQUEST;
}

export { createEditRequestEntity };
export type EDIT_REQUEST = InstanceType<ReturnType<typeof createEditRequestEntity>>;
