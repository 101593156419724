import React from 'react';
import { buildDocURL } from '../../../_shared/utils/docManagerFunctions';

export function ClickableTitleCell(
    orgPrefix: string | null | undefined, // potentially null because it's coming from the UserBundle context objection, whose ORGANIZATION has a nullable PREFIX value
    docID: number,
    revNo: string, // because it's a string, like '  1' with those spaces at the beginning
    title: string
) {
    const docURL = orgPrefix
        ? buildDocURL(
            orgPrefix,
            docID,
            parseInt(revNo)
        ) : '';

    return (
        <a href={docURL} target={'_blank'} rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
            {title}
        </a>
    );
}
