import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { LoadingStatuses } from '../utils/LoadingStatuses';
import { LINK } from '../entities/master/LINK';
import { useLocation } from 'react-router-dom';
import { FavoritesContext } from '../context/FavoritesContext';
import { prefix } from './useDocumentTitle';

export const useFavorites = () => {
    const [loadingStatus, setLoadingStatus] = useState(LoadingStatuses.LOADING);

    const { favoritesCtx, setFavoritesCtx } = useContext(FavoritesContext);

    const location = useLocation();

    const [currentURL, setCurrentURL] = useState(location.pathname + location.search);

    useEffect(() => {
        setCurrentURL(location.pathname + location.search);
        // have to hook into the location search as well or the query params we use for mapping to state in UI won't trigger the currentURL to change.
        // which breaks the favorites.
    }, [location.pathname, location.search]);

    // Determine if the current page is already favorited
    const pageAlreadyFavorited = favoritesCtx.favorites.find(fave => (fave.REFERENCED_URL || '') === currentURL);
    const isCurrentPageInTheDeleteList = favoritesCtx.favoritesToDelete.find(faveToDelete => faveToDelete.LINK_ID === pageAlreadyFavorited?.LINK_ID);

    useEffect(() => {
        getFavorites();
    }, []);

    const getFavorites = async () => {
        setLoadingStatus(LoadingStatuses.LOADING);
        try {
            const res = await axios.get('/api/homepage/header/favorites/get-favorites');
            setFavoritesCtx({ favorites: res.data.favorites, favoritesToDelete: favoritesCtx.favoritesToDelete });
            setLoadingStatus(LoadingStatuses.READY);
        } catch (error) {
            // Handle error
            console.error(error);
        }
    };

    const addFavorite = async (newFavoriteURL: string, newFavoriteTitle: string) => {
        if (loadingStatus !== LoadingStatuses.READY) return;

        if (newFavoriteTitle.startsWith(prefix)) {
            newFavoriteTitle = newFavoriteTitle.slice(prefix.length - 1);
        }

        setLoadingStatus(LoadingStatuses.SAVING);
        try {
            const res = await axios.post('/api/homepage/header/favorites/add-favorite', {
                newFavoriteURL,
                newFavoriteTitle,
            });

            setFavoritesCtx({ favorites: res.data.favorites, favoritesToDelete: favoritesCtx.favoritesToDelete });
            setLoadingStatus(LoadingStatuses.READY);
        } catch (error) {
            // Handle error
            console.error(error);
        }
    };


    const deleteMarkedFavorites = async () => {
        if (!Object.keys(favoritesCtx.favoritesToDelete).length) return;

        setLoadingStatus(LoadingStatuses.SAVING);
        try {
            const res = await axios.post('/api/homepage/header/favorites/delete-favorites', {
                linkIDs: favoritesCtx.favoritesToDelete.map(fave => fave.LINK_ID),
            });

            setFavoritesCtx({ favorites: res.data.favorites, favoritesToDelete: [] });
            setLoadingStatus(LoadingStatuses.READY);
        } catch (error) {
            // Handle error
            console.error(error);
        }
    };

    const toggleFavoriteToBeDeleted = async (link: LINK, sync?: boolean) => {
        const newFavoritesToDelete = favoritesCtx.favoritesToDelete.slice();

        const existingFavIndex = newFavoritesToDelete.findIndex(fave => fave.LINK_ID === link.LINK_ID);
        if (existingFavIndex !== -1) {
            newFavoritesToDelete.splice(existingFavIndex, 1);
        } else {
            newFavoritesToDelete.push(link);
        }

        // I had to add a "sync" param since an alternative to this would be to call the deleteMarkedFavorites function.
        // But there is a small issue with that, since state updates aren't sync, the deleteMarkedFavorites function would
        // be called before the state is updated with the new favorite to be deleted. 
        if (sync) {
            const res = await axios.post('/api/homepage/header/favorites/delete-favorites', {
                linkIDs: newFavoritesToDelete.map(fave => fave.LINK_ID),
            });
            setFavoritesCtx({ favorites: res.data.favorites, favoritesToDelete: [] });
            return;
        }

        setFavoritesCtx({ favorites: favoritesCtx.favorites, favoritesToDelete: newFavoritesToDelete });
    };

    // This is used to strip out any instance specific info out of links for the new webpages.
    const getHrefsOnly = () => {
        // If you edit the system for parsing the URLs make sure to check all kinds of formats.
        // There are a few different formats through the old perl and old react code.
        const favs = favoritesCtx?.favorites.map(fav => {
            if (fav.REFERENCED_URL === null) return fav;
            try {
                const url = new URL(fav.REFERENCED_URL);
                return {
                    ...fav,
                    REFERENCED_URL: `https://${window.location.host}${url.pathname}${url.search}${url.hash}`
                };
            } catch (err) {
                // Check if the URL is already a full URL with a scheme
                const hasScheme = /^https?:\/\//i.test(fav.REFERENCED_URL);
                let url = fav.REFERENCED_URL;
                if (!hasScheme) {
                    url = url.charAt(0) === '/' ? url : `/${url}`;
                }
                return {
                    ...fav,
                    REFERENCED_URL: url
                };
            }
        });

        return favs;
    };


    return {
        favorites: favoritesCtx.favorites,
        favoritesToDelete: favoritesCtx.favoritesToDelete,
        loadingStatus,
        addFavorite,
        deleteMarkedFavorites,
        toggleFavoriteToBeDeleted,
        pageAlreadyFavorited,
        currentURL,
        isCurrentPageInTheDeleteList,
        getHrefsOnly
    };
};

