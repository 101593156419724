import React from 'react';

interface UserShadowIconProps {
    size: number;
}

export const UserShadowIcon: React.FC<UserShadowIconProps> = ({ size }) => {

    return (
        <svg width={size.toString()} height={size.toString()} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 11.5C3 9.84314 4.34315 8.49999 6 8.49999H10C11.6569 8.49999 13 9.84314 13 11.5V12.8018C13 13.6988 12.382 14.4775 11.5085 14.6813V14.6813C9.2005 15.2199 6.7995 15.2199 4.49148 14.6813V14.6813C3.61797 14.4775 3 13.6988 3 12.8018V11.5Z" fill="hsl(var(--muted-foreground))"/>
            <path d="M4.5 3.5C4.5 1.567 6.067 0 8 0V0C9.933 0 11.5 1.567 11.5 3.5V3.5C11.5 5.433 9.933 7 8 7V7C6.067 7 4.5 5.433 4.5 3.5V3.5Z" fill="hsl(var(--muted-foreground))"/>
        </svg>
    );
};  