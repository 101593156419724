import React from 'react';

interface GavelOutlineProps {
    size: number
}

export const GavelOutlineIcon: React.FC<GavelOutlineProps> = ({ size }) => {
    return (
        <svg width={size.toString()} height={(size / (21/20)).toString()} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.5 4.91421L11.0858 8.5L10.5858 9L11.2929 9.70711L19.5858 18L19 18.5858L10.7071 10.2929L10 9.58579L9.5 10.0858L5.91421 6.5L7.5 4.91421Z" fill="hsl(var(--foreground))" stroke="hsl(var(--foreground))" strokeWidth="2"/>
            <path d="M9.91421 2.5L11 1.41421L14.5858 5L13.5 6.08579L9.91421 2.5Z" stroke="hsl(var(--foreground))" strokeWidth="2"/>
            <path d="M2.41421 10L3.5 8.91421L7.08579 12.5L6 13.5858L2.41421 10Z" stroke="hsl(var(--foreground))" strokeWidth="2"/>
            <path d="M1 17.75H9C9.13807 17.75 9.25 17.8619 9.25 18V19.25H0.75V18C0.75 17.8619 0.861929 17.75 1 17.75Z" stroke="hsl(var(--foreground))" strokeWidth="1.5"/>
        </svg>
    );
};
