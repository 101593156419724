import { useBrowserQuery } from '@/hooks/useBrowserQuery';
import { DataTable, DataTableHandle } from '@/ui/ui/datatable/data-table';
import React, { useRef } from 'react';
import { CommitteesQuery } from '../Committees';
import { ColumnDef } from '@tanstack/react-table';
import { CommitteeEntry } from '@/v2/components/committeeTable/CommitteeTable';
import { DataTableColumnHeader } from '@/ui/ui/datatable/column-header';
import CommitteeTableToolbar from '@/v2/components/committeeTable/CommitteeTableToolbar';
import useSWR from 'swr';
import { COMMITTEE, CommitteeStatuses } from '@/entities/org/COMMITTEE';
import { PaginationToolsForTable } from '@/ui/ui/datatable/pagination-tools';
import { EditCommitteeDialog } from '../dialogs/EditCommitteeDialog';
import { CreateCommitteeDialog } from '../dialogs/CreateCommitteeDialog';

export const CommitteesTab: React.FC = () => {

    const [query, setQuery] = useBrowserQuery<CommitteesQuery>();

    const columns: ColumnDef<CommitteeEntry>[] = [
        {
            id: 'id',
            accessorKey: 'id',
            enableSorting: true,
            cell: ({ cell }) => {
                return <code className='text-secondary'>{cell.getValue<string>()}</code>;
            },
            header: ({ column }) => {
                return (
                    <DataTableColumnHeader column={column} title='ID' />
                );
            },
            filterFn: (row, id, value) => {
                return value.includes(row.getValue(id));
            },
            meta: {
                label: 'Committee ID',

            }
        },
        {
            id: 'name',
            accessorKey: 'name',
            enableSorting: true,
            cell: ({ cell }) => {
                return <div className='flex flex-row'>
                    <p
                        onClick={
                            () => {
                                setQuery({
                                    ...query,
                                    editingCommitteeId: cell.row.original.id,
                                });
                            }
                        }
                        className='inline-block  text-secondary whitespace-break-spaces font-medium text-left px-2 p-1.5 hover:bg-accent  hover:cursor-pointer rounded-sm'
                    >
                        {cell.getValue<string>()}
                    </p>
                </div>;
            },
            header: ({ column }) => {
                return (
                    <DataTableColumnHeader column={column} title='Name' />
                );
            },
            meta: {
                label: 'Name',
            }
        },
        {
            id: 'chairPerson',
            accessorKey: 'chairPerson',
            enableSorting: true,
            cell: ({ cell }) => {
                return <p>{cell.getValue<string>()}</p>;
            },
            header: ({ column }) => {
                return (
                    <DataTableColumnHeader column={column} title='Chairperson' />
                );
            },
            filterFn: (row, id, value) => {
                return value.includes(row.getValue(id));
            },
            meta: {
                label: 'Chairperson'
            }
        },
        {
            id: 'secretary',
            accessorKey: 'secretary',
            enableSorting: true,
            cell: ({ cell }) => {
                return <p>{cell.getValue<string>()}</p>;
            },
            header: ({ column }) => {
                return (
                    <DataTableColumnHeader column={column} title='Secretary' />
                );
            },
            filterFn: (row, id, value) => {
                return value.includes(row.getValue(id));
            },
            meta: {
                label: 'Secretary'
            }
        },
        {
            id: 'status',
            accessorKey: 'status',
            enableSorting: true,
            cell: ({ cell }) => {
                return <p>{cell.getValue<string>()}</p>;
            },
            header: ({ column }) => {
                return (
                    <DataTableColumnHeader column={column} title='Status' />
                );
            },
            filterFn: (row, id, value) => {
                return value.includes(row.getValue(id));
            },
            meta: {
                label: 'Status'
            }
        },
        {
            id: 'minutesDepartment',
            accessorKey: 'minutesDepartment',
            enableSorting: true,
            cell: ({ cell }) => {
                return <p>{cell.getValue<string>()}</p>;
            },
            header: ({ column }) => {
                return (
                    <DataTableColumnHeader column={column} title='Minutes Department' />
                );
            },
            filterFn: (row, id, value) => {
                return value.includes(row.getValue(id));
            },
            meta: {
                label: 'Minutes Department'
            }
        }
    ];

    const { data: committeeData, isLoading: isLoadingCommittees, mutate: refetchCommittees } = useSWR('/api/committee');

    const dataTableRef = useRef<DataTableHandle<CommitteeEntry>>(null);

    return (
        <div className='pt-4'>
            <DataTable
                initialFilterColumns={[
                    {
                        id: 'status',
                        value: [
                            'Active'
                        ]
                    }
                ]}
                columns={columns}
                toolbar={CommitteeTableToolbar}
                ref={dataTableRef}
                data={committeeData ? committeeData.committees.map((committee: COMMITTEE) => ({
                    id: committee.COMMITTEE_ID,
                    name: committee.NAME,
                    chairPerson: `${committee.CHAIRPERSON?.LASTNAME}, ${committee.CHAIRPERSON?.FIRSTNAME} ${committee.CHAIRPERSON?.MIDDLEINITIALS || ''}`,
                    secretary: `${committee.SECRETARY?.LASTNAME}, ${committee.SECRETARY?.FIRSTNAME} ${committee.SECRETARY?.MIDDLEINITIALS || ''}`,
                    status: committee.STATUS === CommitteeStatuses.ACTIVE ? 'Active' : 'Archived',
                    minutesDepartment: `${committee.MINUTES_DEPARTMENT?.TITLE}`
                })) : []}
                loading={isLoadingCommittees}
                exportAsFileName='my_committees'
                renderExpandedRowForTableExport={
                    async () => [] 
                }
                pagination={PaginationToolsForTable}
                initialColumnVisibility={{
                    'id': false
                }}
                limitTableHeight={false}
                sorting={[
                    {
                        id: 'name',
                        desc: false
                    }
                ]}
            />
            {query.editingCommitteeId &&
                <EditCommitteeDialog
                    onEdit={() => refetchCommittees()}
                />
            }
            {
                query.createCommitteeDialogOpen &&
                <CreateCommitteeDialog
                    onCreate={() => refetchCommittees()}
                />
            }
        </div>
    );
};