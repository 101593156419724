import {
    Column,
    Entity,
    JoinColumn,
    ManyToOne,
    PrimaryColumn,
} from 'typeorm';
import { CE_ENROLLMENT_SNAPSHOT } from './CE_ENROLLMENT_SNAPSHOT';
import { SchemaEntityManager } from '../SchemaEntityManager';

export enum CECourseAttemptStatuses {
    Active = 'Active',
    Completed = 'Completed',
    Terminated = 'Terminated',
}

// Factory function to create CE_COURSE_ATTEMPT entity with dynamic schema
function createCeCourseAttemptEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('CE_COURSE_ATTEMPT', { schema })
    class CE_COURSE_ATTEMPT {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            name: 'CE_COURSE_ATTEMPT_ID'
        })
        CE_COURSE_ATTEMPT_ID!: number;

        @Column('number', {
            nullable: false,
            name: 'CE_ENROLLMENT_SNAPSHOT_ID'
        })
        CE_ENROLLMENT_SNAPSHOT_ID!: number;

        @ManyToOne(
            () => manager.getCeEnrollmentSnapshotEntity(schema),
            (ceEnrollmentSnapshot) => ceEnrollmentSnapshot.CE_COURSE_ATTEMPTS
        )
        @JoinColumn({
            name: 'CE_ENROLLMENT_SNAPSHOT_ID'
        })
        CE_ENROLLMENT_SNAPSHOT!: CE_ENROLLMENT_SNAPSHOT | null;

        @Column('timestamp', {
            nullable: false,
            default: () => 'SYSDATE',
            name: 'START_DATE'
        })
        START_DATE!: Date;

        @Column('timestamp', {
            nullable: false,
            default: () => 'SYSDATE',
            name: 'FINAL_STATUS_DATE'
        })
        FINAL_STATUS_DATE!: Date;

        @Column('varchar2', {
            nullable: false,
            length: 10,
            default: () => '\'Active\'',
            name: 'STATUS'
        })
        STATUS!: string;

        static Statuses = CECourseAttemptStatuses;
    }

    return CE_COURSE_ATTEMPT;
}

// Export the factory function
export { createCeCourseAttemptEntity };

// Export the type for CE_COURSE_ATTEMPT entities created by the factory function
export type CE_COURSE_ATTEMPT = ReturnType<typeof createCeCourseAttemptEntity>;
