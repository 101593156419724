import { INFORMATION_HIERARCHIES_PARENT, INFORMATION_HIERARCHY, isInformationHierarchyParent } from '@/entities/org/INFORMATION_HIERARCHY';
import { QueryParams, buildURLSearchParams, useBrowserQuery } from '@/hooks/useBrowserQuery';
import { BreadcrumbBar, BreadcrumbBarLink } from '@/ui/ui/breadcrumb';
import { Button } from '@/ui/ui/button';
import { Input } from '@/ui/ui/input';
import React, { useState, useContext } from 'react';
import { SearchIcon } from 'lucide-react';
import { formatTitle, useDocumentTitle } from '@/hooks/useDocumentTitle';
import UserBundleContext from '@/context/UserBundleContext';
import { DepartmentsTable } from '../../pages/homepage/overview/components/DepartmentsTable';
import { useOrgBaseURL } from '@/hooks/useOrgBaseURL';
import { HybridLink } from '@/ui/ui/hybridlink';
import { IHSidebarEntry } from './IHSidebar';

interface InformationHierachyBrowserProps<T extends INFORMATION_HIERARCHY> {
    browserHierarchies: T[];
    breadcrumb: BreadcrumbBarLink;
    ihType: 'department' | 'manual'
    loading: boolean;
    expandAll: boolean;
}

export interface InfoHierarchyQuery extends QueryParams {
    infoHierarchyID?: number
    orgID?: number
}

export const sortDepartments = (ihs: INFORMATION_HIERARCHY[]): INFORMATION_HIERARCHY[] => {
    // https://lucidoc.atlassian.net/browse/LUCIDOC-3531
    return ihs.sort((a, b) => {
        // Regular expression to match numeric prefix before the period and manual and the rest of the string
        const regex = /^(\d+)[.)]\s*(.*)$/;

        const matchA = a.TITLE.match(regex);
        const matchB = b.TITLE.match(regex);

        // If the string doesn't match the regex, treat the entire string as the rest part
        const numA = matchA ? Number(matchA[1]) : null; // numeric part, or null if no match
        const strA = matchA ? matchA[2] : a.TITLE; // rest of the string, or the full string if no match

        const numB = matchB ? Number(matchB[1]) : null;
        const strB = matchB ? matchB[2] : b.TITLE;

        // Sort by numeric prefix if both have numeric parts
        if (numA !== null && numB !== null) {
            const numDiff = numA - numB;
            if (numDiff !== 0) {
                return numDiff; // Sort numerically if numbers differ
            }
        }

        // If one has a number and the other doesn't, the one with the number comes first
        if (numA !== null && numB === null) return -1;
        if (numA === null && numB !== null) return 1;

        // Sort alphabetically if numeric prefixes are the same or don't exist
        return strA.localeCompare(strB, undefined, { sensitivity: 'base' });
    });
};

export const SelectedInfoHierachyContext = React.createContext<ReturnType<typeof useBrowserQuery<InfoHierarchyQuery>>>({} as any);

export const InformationHierachyBrowser: React.FC<InformationHierachyBrowserProps<INFORMATION_HIERARCHY>> = ({ browserHierarchies, loading, ihType, breadcrumb, expandAll }) => {
    const userBundle = useContext(UserBundleContext);

    const [ihQuery, setIhQuery] = useBrowserQuery<InfoHierarchyQuery>();

    const [filter, setFilter] = useState('');

    function doesSelectedIHHaveChildren(): boolean {
        if (ihType !== 'manual') return false;
        if (ihQuery.infoHierarchyID === undefined) return false;
        const path = findIHPath(browserHierarchies ?? [], ihQuery.orgID, ihQuery.infoHierarchyID);
        if (path === undefined) return false;
        return path.length === 1;
    }
    function findIHPath<T extends INFORMATION_HIERARCHY>(
        items: T[],
        orgID: number | undefined,
        targetId: number,
        currentPath: INFORMATION_HIERARCHY[] = []
    ): INFORMATION_HIERARCHY[] | undefined {
        for (const item of items) {
            // At the top level, if an organizationID is present and doesn't match, skip this branch
            if (
                currentPath.length === 0 &&
                (item as any).organizationID !== undefined && // using a type assertion if organizationID isn't part of INFORMATION_HIERARCHY
                (item as any).organizationID !== orgID
            ) {
                continue;
            }

            // If the current node matches the target, return the full path
            if (item.INFORMATION_HIERARCHY_ID === targetId) {
                return [...currentPath, item];
            }

            // If there are children, recursively search through them (passing orgID along)
            if (item.CHILDREN && item.CHILDREN.length > 0) {
                const path = findIHPath(item.CHILDREN, orgID, targetId, [...currentPath, item]);
                if (path) {
                    return path;
                }
            }
        }
        // If the target wasn't found in any branch, return undefined
        return undefined;
    }


    const orgBaseURL = useOrgBaseURL();

    const renderInfoHierarchies = () => {
        const departments = SearchInfoHierarchies(browserHierarchies ?? [], filter);

        return sortDepartments(departments).map((entry, idx) => {
            return <IHSidebarEntry key={idx} topLevel={expandAll} entry={entry} expandAll={filter.length > 0} orgID={entry['organizationID']} />;
        });
    };

    const getInfoHeirarchyBreadcrumbLinks = () => {
        if (ihQuery.infoHierarchyID === undefined) return [];
        const path = findIHPath(browserHierarchies ?? [], ihQuery.orgID, ihQuery.infoHierarchyID);
        if (path === undefined) return [];
        const pathArray: BreadcrumbBarLink[] = [];
        return path!.reduce((pathArr, ihEntry) => {
            const params = buildURLSearchParams({
                ...ihQuery,
                infoHierarchyID: ihEntry.INFORMATION_HIERARCHY_ID
            });

            pathArr.push({
                title: ihEntry.TITLE, href: `${orgBaseURL}/dashboard?${params}`
            });
            return pathArr;
        }, pathArray);
    };

    const links = getInfoHeirarchyBreadcrumbLinks();

    const ihName = links[links.length - 1]?.title;

    const getIhFormattedName = () => {
        switch (ihType) {
            case 'department':
                return userBundle.organization?.ORGUNITPLURAL ?? 'Departments';
            case 'manual':
                return userBundle.organization?.MANUALNAMEPLURAL ?? 'Manuals';
            default:
                throw new Error('IH TYPE FORMATTED NAME MISSING');
        }
    };

    const renderTable = () => {
        if (ihQuery.infoHierarchyID === undefined) return null;
        if (doesSelectedIHHaveChildren()) return <div className='w-full h-24 flex flex-col items-start justify-center'>
            <p className='text-lg'>Select a Manual in this Group to display its contents.</p>
        </div>;
        return <DepartmentsTable
            loading={loading}
            ihType={ihType}
            exportFileName={ihName}
            infoHeirarchyID={ihQuery.infoHierarchyID}
            orgID={ihQuery.orgID ?? userBundle.organization!.ORGANIZATIONID!}
        />;
    };


    useDocumentTitle(formatTitle(getIhFormattedName(), ihName));

    return (
        <SelectedInfoHierachyContext.Provider value={[ihQuery, setIhQuery]}>
            <div className='flex flex-col lg:flex-row w-full mt-4 min-h-32 h-full'>
                <div className='w-full lg:max-w-64'>
                    <Input
                        icon={<SearchIcon size={18} />}
                        placeholder={`Search ${ihType}s...`}
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                    />
                    <div className='flex flex-col w-full  max-h-[46rem] overflow-y-auto'>
                        {renderInfoHierarchies()}
                    </div>
                </div>
                <div className='flex-grow ml-2 p-2 pt-0 flex flex-col'>
                    <div className='flex flex-row flex-wrap items-center justify-between'>
                        <BreadcrumbBar

                            links={
                                [
                                    { title: 'Dashboard', href: `${orgBaseURL}/dashboard` },
                                    breadcrumb,
                                    ...links
                                ]
                            }
                        />
                        {(ihType === 'manual' && ihQuery.infoHierarchyID !== undefined && !doesSelectedIHHaveChildren())
                            && <HybridLink className='md:ml-3 my-1' external={true} openInNewTab href={`/cgi/cat-list.pl?usepub=1&catid=${ihQuery.infoHierarchyID}`}>
                                <Button size={'sm'} variant={'outline'}>
                                    View Table of Contents
                                </Button>
                            </HybridLink>}
                    </div>
                    {renderTable()}
                </div>
            </div>
        </SelectedInfoHierachyContext.Provider>
    );
};

// A DFS search to find departments that match the query
export function SearchInfoHierarchies<T extends INFORMATION_HIERARCHY | INFORMATION_HIERARCHIES_PARENT>(items: T[], query: string): T[] {
    // Check if the department or any of its children (recursively) contains the query in the title
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function matches(item: T, query: string): boolean {
        const lowerCaseQuery = query.toLowerCase();

        // Handle the wrapper type INFORMATION_HIERARCHIES_PARENT
        if (isInformationHierarchyParent(item)) {
            // This is the shell parent, ignore the title and just search in children
            return item.CHILDREN?.some(child => matches(child as T, query)) || false;
        }

        // Regular matching logic
        if ('TITLE' in item && item.TITLE.toLowerCase().includes(lowerCaseQuery)) {
            return true;
        }

        // Recursively check children if they exist
        return item.CHILDREN ? item.CHILDREN.some(child => matches(child as T, query)) : false;
    }

    // Recursively filter departments to include only those that match the query or have children that match
    function filterDepartments(department: T, query: string): T | null {
        const lowerCaseQuery = query.toLowerCase();

        // Handle the wrapper type INFORMATION_HIERARCHIES_PARENT
        if (isInformationHierarchyParent(department)) {
            // Skip checking the wrapper itself and only filter its children
            const filteredChildren = department.CHILDREN!
                .map(child => filterDepartments(child as T, query))
                .filter((child): child is T => child !== null);

            if (filteredChildren.length > 0) {
                // If any children match, return the department with filtered children
                return { ...department, CHILDREN: filteredChildren } as T;
            }
            return null;
        }

        // Normal search for INFORMATION_HIERARCHY items
        if (department.TITLE.toLowerCase().includes(lowerCaseQuery)) {
            return department; // The department matches the query
        } else if (department.CHILDREN) {
            const filteredChildren = department.CHILDREN
                .map(child => filterDepartments(child as T, query))
                .filter((child): child is T => child !== null);

            if (filteredChildren.length > 0) {
                // If any children match, return the department with filtered children
                return { ...department, CHILDREN: filteredChildren } as T;
            }
        }
        return null; // No match found in this branch
    }

    // Start the search from the root departments
    return items
        .map(item => filterDepartments(item, query))
        .filter((item): item is T => item !== null); // Filter out nulls
}