import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import DataTable from '../../_shared/DataTable/DataTable';
import { DOCUMENTREVISION } from '../../../entities/org/DOCUMENTREVISION';
import { MUIDataTableProps } from 'mui-datatables';
import { IconButton, makeStyles, TableCell, TableRow, Tooltip } from '@material-ui/core';
import Select from 'react-select';
import { PERSON } from '../../../entities/master/PERSON';
import { CheckCircleOutline, Schedule, Send } from '@material-ui/icons';
import UserBundleContext from '../../../context/UserBundleContext';
import { buildDocURL } from '../../../_shared/utils/docManagerFunctions';
import produceFlattenedOptionsFromNestedInformationHierarchy
    , { flattenedInformationHierarchySelectMenuOption } from '../../../utils/produceFlattenedOptionsFromNestedInformationHierarchy';
import { TemporaryFavoritesStar } from '../../_shared/TemporaryFavoritesStar/TemporaryFavoritesStar';
import setWindowTitle from '../../../utils/setWindowTitle';
import { constructFullName } from '@/components/UserPicker/utils/constructFullName';

const useStyles = makeStyles({
    fakeLink: {
        color: 'blue',
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    lightgrey: {
        color: 'lightgrey'
    },
    limegreen: {
        color: 'limegreen'
    }
});

interface SelectMenuOptionType {
    label: string
    value: number
}

export default function ContractsManager() {
    const context = useContext(UserBundleContext);

    const classes = useStyles();

    const [ requestedContractsHashedByDocRevID, setRequestedContractsHashedByDocRevID ] = useState<{[key: string]: DOCUMENTREVISION}>({});
    const [ tableData, setTableData ] = useState<MUIDataTableProps['data']>([]);
    const [ selectablePeopleMappedForSelectMenu, setSelectablePeopleMappedForSelectMenu ] = useState<SelectMenuOptionType[]>([]);
    const [ selectableDepartmentsMappedForSelectMenu, setSelectableDepartmentsMappedForSelectMenu ] = useState<flattenedInformationHierarchySelectMenuOption[]>([]);

    // loading in progress:
    const [ assigningDocRevs, setAssigningDocRevs ] = useState<{[key: string]: DOCUMENTREVISION}>({});
    // finished assigning:
    const [ assignedDocRevs, setAssignedDocRevs ] = useState<{[key: string]: DOCUMENTREVISION}>({});

    useEffect(() => {
        setWindowTitle('Contract Routing');

        getInitialData().then(data => {
            // all of these require some formatting:
            setSelectablePeopleMappedForSelectMenu(processPeopleForSelectMenu(data.selectablePeople));
            setSelectableDepartmentsMappedForSelectMenu(produceFlattenedOptionsFromNestedInformationHierarchy(data.selectableDepartments));
            setTableData(mapThroughDocRevsToProduceTableData(data.requestedContracts));
            setRequestedContractsHashedByDocRevID(hashDocRevsByDocRevID(data.requestedContracts));
        });
    }, []);

    function processPeopleForSelectMenu(people: PERSON[]) {
        return people.map(person => {
            return {
                label: constructFullName(person, 'lastFirstMiddle'),
                value: person.USERID
            };
        });
    }

    async function getInitialData() {
        const res = await axios.get('/api/doc-manager/contracts/manage/get-initial-data');
        return res.data.success;
    }

    function mapThroughDocRevsToProduceTableData(docRevs: DOCUMENTREVISION[]) {
        return docRevs.map(docRev => {
            const createdDate = new Date(docRev.CREATEDT).toLocaleDateString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            });

            return {
                DocRevID: docRev.DOCREVID,
                DocID: docRev.DOCID,
                Title: docRev.TITLE,
                'Requested By': docRev.DOCREV_OWNER && constructFullName(docRev.DOCREV_OWNER, 'lastFirstMiddle'),
                'Requested On': createdDate,
                Link: (docRev.DOCID
                    && docRev.REVNO
                    && !isNaN(parseInt(docRev.REVNO.trim()))
                    && context.organization
                    && context.organization.PREFIX
                    && buildDocURL(context.organization.PREFIX, docRev.DOCID, parseInt(docRev.REVNO.trim())) )
                    || null,
            };
        });
    }

    function hashDocRevsByDocRevID(docRevs: DOCUMENTREVISION[]) {
        const docRevsHash: {[key: string]: DOCUMENTREVISION} = {};
        docRevs.forEach(docRev => {
            // while we're here, we're going to remove the USERID value if the docRev owner does not
            // have author privileges; this will force the user of this page to select a new owner
            // who does have this privilege.
            if (   !docRev.DOCREV_OWNER
                || !docRev.DOCREV_OWNER.RIGHTSINENGLISH
                || !docRev.DOCREV_OWNER.RIGHTSINENGLISH
                || !docRev.DOCREV_OWNER.RIGHTSINENGLISH.author
            ) {
                docRev.USERID = 0;
            }
            // now do the regular task, of putting the docRevs into the docRevsHash:
            docRevsHash[docRev.DOCREVID] = docRev;
        });
        return docRevsHash;
    }

    function updateDocRevOwner(docRevID: number, newOwnerUserID: number) {
        // minor note: this will update the USERID field on the DocRev, but not the
        // DOCREV_OWNER field; we needed DOCREV_OWNER to display first and last name, but the USERID
        // is all we'll need on the back-end, so the mismatched DOCREV_OWNER
        // (only used in the Requested By column in the table) won't matter.
        const updatedContractsHash = Object.assign({}, requestedContractsHashedByDocRevID);
        updatedContractsHash[docRevID].USERID = newOwnerUserID;
        setRequestedContractsHashedByDocRevID(updatedContractsHash);
    }

    function updateDepartmentOfDocument(docRevID: number, newDepartmentID: number) {
        const updatedContractsHash = Object.assign({}, requestedContractsHashedByDocRevID);
        updatedContractsHash[docRevID].DOCUMENT.INFORMATION_HIERARCHY_ID = newDepartmentID;
        setRequestedContractsHashedByDocRevID(updatedContractsHash);
    }

    function removeDocRevFromAssigningDocRevs(docRevID: number) {
        const newAssigningDocRevs = Object.assign(assigningDocRevs);
        delete newAssigningDocRevs[docRevID];
        setAssigningDocRevs(newAssigningDocRevs);
    }

    function assignDocRev(docRevID: number) {
        const docRev = requestedContractsHashedByDocRevID[docRevID];
        if (!docRev) {
            return false;
        }
        setAssigningDocRevs({
            ...assigningDocRevs,
            [docRevID]: docRev
        });

        axios.post('/api/doc-manager/contracts/manage/assign-docrev', {
            params: {
                docRevID: docRev.DOCREVID,
                newOwnerUserID: docRev.USERID,
                newDepartmentID: docRev.DOCUMENT.INFORMATION_HIERARCHY_ID
            }
        }).then(res => {
            if (res.data.success) {
                removeDocRevFromAssigningDocRevs(docRevID);
                setAssignedDocRevs({
                    ...assignedDocRevs,
                    [docRevID]: docRev
                });
            }
            else {
                alert('Error: This contract could not be reassigned.');
                removeDocRevFromAssigningDocRevs(docRevID);
            }
        }).catch(() => {
            removeDocRevFromAssigningDocRevs(docRevID);
        });
    }

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <TemporaryFavoritesStar targetPathName={'docmgr2/docmanager.pl?repname=contractsManager'} />
            </div>

            <DataTable
                noFooter={true}
                muiDataTableProps={{
                    data: tableData,
                    title: 'Pending Contract Requests',
                    columns: [
                        {
                            name: 'DocRevID',
                            options: {
                                display: 'false',
                                filter: false
                            }
                        },
                        { name: 'DocID' },
                        {
                            name: 'Title',
                            options: {
                                customBodyRender: (value, tableMeta) => {
                                    const linkURL = tableMeta.rowData[8];
                                    return (
                                        <a
                                            className={classes.fakeLink}
                                            onClick={() => {
                                                window.open(linkURL, '_blank', 'toolbar=0,location=0,menubar=0');
                                                return false;
                                            }}
                                        >
                                            {value}
                                        </a>
                                    );
                                }
                            }
                        },
                        { name: 'Requested By' },
                        { name: 'Requested On' },
                        {
                            name: 'New Owner',
                            options: {
                                filter: false,
                                customBodyRender: (value, tableMeta) => {
                                    const docRevID = tableMeta.rowData[0];
                                    const docRev = requestedContractsHashedByDocRevID[docRevID];

                                    const valueOfCurrentOwnerForSelectMenu = docRev && docRev.USERID
                                        ? selectablePeopleMappedForSelectMenu.find((person: SelectMenuOptionType) => {
                                            return person.value === docRev.USERID;
                                        })
                                        : ({
                                            label: '',
                                            value: -1
                                        });

                                    return (
                                        <Select
                                            isDisabled={!!assigningDocRevs[docRevID] || !!assignedDocRevs[docRevID]}
                                            value={valueOfCurrentOwnerForSelectMenu}
                                            options={selectablePeopleMappedForSelectMenu}
                                            onChange={(option: any) => updateDocRevOwner(docRevID, option.value)}
                                        />
                                    );
                                }
                            }
                        },
                        {
                            name: 'New Department',
                            options: {
                                filter: false,
                                customBodyRender: (value, tableMeta) => {
                                    const docRevID = tableMeta.rowData[0];
                                    const docRev = requestedContractsHashedByDocRevID[docRevID];

                                    const valueOfCurrentDepartmentForSelectMenu = docRev && docRev.DOCUMENT
                                        ? selectableDepartmentsMappedForSelectMenu.find((dept) => {
                                            return dept.value === docRev.DOCUMENT.INFORMATION_HIERARCHY_ID;
                                        })
                                        : ({
                                            label: '',
                                            value: -1
                                        });

                                    return (
                                        <Select
                                            isDisabled={!!assigningDocRevs[docRevID] || !!assignedDocRevs[docRevID]}
                                            value={valueOfCurrentDepartmentForSelectMenu}
                                            options={selectableDepartmentsMappedForSelectMenu}
                                            onChange={(option: any) => updateDepartmentOfDocument(docRevID, option.value)}
                                        />
                                    );
                                }
                            }
                        },
                        {
                            name: 'Assign',
                            options: {
                                filter: false,
                                customBodyRender: (value, tableMeta) => {
                                    const docRevID = tableMeta.rowData[0];

                                    if (assignedDocRevs[docRevID]) {
                                        return <CheckCircleOutline className={classes.limegreen}/>;
                                    }
                                    else if (assigningDocRevs[docRevID]) {
                                        return <Schedule className={classes.lightgrey}/>;
                                    }
                                    else {
                                        const docRev = requestedContractsHashedByDocRevID[docRevID];
                                        const isActionButtonEnabled = (
                                            docRev
                                            && docRev.USERID
                                            && docRev.DOCUMENT
                                            && docRev.DOCUMENT.INFORMATION_HIERARCHY_ID
                                        );

                                        return (
                                            <Tooltip title={!isActionButtonEnabled ? 'Document requires a document owner and department before assignment.' : ''}>
                                                <span>
                                                    <IconButton
                                                        size={'small'}
                                                        disabled={!isActionButtonEnabled}
                                                        onClick={() => assignDocRev(docRevID)}
                                                    >
                                                        <Send fontSize={'small'}/>
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        );
                                    }
                                }
                            }
                        },
                        {
                            name: 'Link',
                            options: {
                                display: 'false',
                                filter: false
                            }
                        },
                    ],
                    options: {
                        selectableRows: 'none',
                        expandableRows: true,
                        renderExpandableRow: (rowData: string[]) => {
                            const docRevID = rowData[0];
                            const docRev = requestedContractsHashedByDocRevID[docRevID];

                            const linkedDocuments = docRev.DOCUMENTREVISION_LINKS && docRev.DOCUMENTREVISION_LINKS.map((docRevLink, idx) => {
                                if (   docRevLink.LINK
                                    && docRevLink.LINK.REFERENCED_ORG
                                    && docRevLink.LINK.REFERENCED_ORG.PREFIX
                                    && docRevLink.LINK.REFERENCED_DOCID
                                ) {
                                    const linkURL = buildDocURL(docRevLink.LINK.REFERENCED_ORG.PREFIX, docRevLink.LINK.REFERENCED_DOCID);

                                    return (
                                        <li key={idx}>
                                            <a
                                                className={classes.fakeLink}
                                                onClick={() => {
                                                    window.open(linkURL, '_blank', 'toolbar=0,location=0,menubar=0');
                                                    return false;
                                                }}
                                            >
                                                {docRevLink.LINK.LATEST_REFERENCED_DOC_TITLE}
                                            </a>
                                        </li>
                                    );
                                }
                                return undefined;
                            });

                            return (
                                <TableRow>
                                    <TableCell> </TableCell>
                                    <TableCell> </TableCell>
                                    <TableCell>
                                        <p>Links:</p>
                                        <ul>
                                            {linkedDocuments}
                                        </ul>
                                    </TableCell>
                                    <TableCell> </TableCell>
                                    <TableCell> </TableCell>
                                    <TableCell> </TableCell>
                                    <TableCell> </TableCell>
                                    <TableCell> </TableCell>
                                </TableRow>
                            );
                        }
                    }
                }}
            />
        </div>
    );
}
