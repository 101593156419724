import {
    Column,
    Entity,
    JoinColumn,
    ManyToOne,
    PrimaryColumn,
} from 'typeorm';
import { SIGNATURE_SESSION,  } from './SIGNATURE_SESSION';
import { stringToBooleanTransformer } from '../_helperFunctions/transformers';
import { SchemaEntityManager } from '../SchemaEntityManager';

// Define the factory function
function createSignatureRequestEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('SIGNATURE_REQUEST', { schema: schema })
    class SIGNATURE_REQUEST {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'SIGNATURE_SESSION_ID'
        })
        SIGNATURE_SESSION_ID!: number;

        @ManyToOne(
            () => manager.getSignatureSessionEntity(schema), 
            (signatureSession) => signatureSession.SIGNATURE_REQUESTS
        )
        @JoinColumn({
            name: 'SIGNATURE_SESSION_ID'
        })
        SIGNATURE_SESSION!: SIGNATURE_SESSION | null;

        @PrimaryColumn('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'USERID'
        })
        USERID!: number;

        @PrimaryColumn('timestamp', {
            nullable: false,
            default: () => 'SYSDATE',
            name: 'REQUEST_DATE'
        })
        REQUEST_DATE!: Date;

        @Column('number', {
            nullable: true,
            name: 'SIGN_ORDER'
        })
        SIGN_ORDER!: number | null;

        @Column('number', {
            nullable: true,
            name: 'VIA_USERGROUPID'
        })
        VIA_USERGROUPID!: number | null;

        @Column('varchar2', {
            nullable: true,
            default: () => '0',
            transformer: stringToBooleanTransformer,
            name: 'PRESERVE_ON_VOID'
        })
        PRESERVE_ON_VOID!: boolean | null;
    }

    return SIGNATURE_REQUEST;
}

// Export the factory function
export { createSignatureRequestEntity };

// Export the type representing an instance of the class generated by the factory function
export type SIGNATURE_REQUEST = InstanceType<ReturnType<typeof createSignatureRequestEntity>>;
