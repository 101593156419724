import {
    Column,
    Entity,
    JoinColumn,
    ManyToOne,
    OneToMany,
    PrimaryColumn
} from 'typeorm';

import { type PUBLICATION } from './PUBLICATION';
import { INVENTORY } from './INVENTORY';
import { SchemaEntityManager } from '../SchemaEntityManager';

function createEditionEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('EDITION', { schema: schema })
    class EDITION {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            name: 'EDITIONID'
        })
        EDITIONID!: number;

        @Column('timestamp', {
            default: () => 'SYSDATE',
            name: 'CREATED_DATE'
        })
        CREATED_DATE!: Date;

        @Column('number', {
            nullable: false,
            name: 'PUBLICATIONID'
        })
        PUBLICATIONID!: number;

        @ManyToOne(
            () => manager.getPublicationEntity(schema),
            (publication) => publication.EDITIONS
        )
        @JoinColumn({
            name: 'PUBLICATIONID',
        })
        PUBLICATION!: PUBLICATION;

        @Column('number', {
            nullable: false,
            name: 'DOCREVID'
        })
        DOCREVID!: number;

        @Column('varchar2', {
            nullable: false,
            name: 'TITLE'
        })
        TITLE!: string;

        @Column('varchar2', {
            nullable: false,
            name: 'REVNO',
            length: 6
        })
        REVNO!: string;

        @Column('number', {
            nullable: false,
            name: 'PRIMARY_BLOBID'
        })
        PRIMARY_BLOBID!: number;

        @OneToMany(
            () => manager.getInventoryEntity(schema),
            (inventory) => inventory.EDITION
        )
        INVENTORIES!: INVENTORY[];
    }

    return EDITION;
}

export { createEditionEntity };
export type EDITION = InstanceType<ReturnType<typeof createEditionEntity>>;
