import { helpTopicDocIDs } from '@/_shared/constants/HelpSystemIDs';
import { constructFullName } from '@/components/UserPicker/utils/constructFullName';
import { QueryParams, useBrowserQuery } from '@/hooks/useBrowserQuery';
import { Button } from '@/ui/ui/button';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogHelpTopicIcon, DialogTitle, DialogTrigger } from '@/ui/ui/dialog';
import { Input } from '@/ui/ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/ui/ui/select';
import { Textarea } from '@/ui/ui/textarea';
import { useForm } from '@tanstack/react-form';
import axios from 'axios';
import React from 'react';
import useSWR from 'swr';

interface AskAQuestionDialogProps {

}

export interface AskAQuestionDialogParams extends QueryParams {
    askAQuestionDialogOpen?: boolean
}


// This component specifically sits inside of the "Header.tsx" component instead of inside
// of the HeaderOrganizationDropdown component where it should sit because
// if the dropdown closes after clicking an element, it unmounts, which unmounts the dialog too.
// So I placed it in a spot where it will be mounted, and the query params control the open/close.
export const AskAQuestionDialog: React.FC<AskAQuestionDialogProps> = () => {

    const [query, setQuery] = useBrowserQuery<AskAQuestionDialogParams>();


    const { data: askDialogInfo } = useSWR('/api/homepage/header/ask-dialog/get-ask-dialog-info');



    const form = useForm({
        defaultValues: {
            selectedFeedbackType: askDialogInfo?.feedbackTypes?.[0].FEEDBACK_TYPE_ID,
            userEmail: '',
            question: ''
        },
        onSubmit: async (values) => {
            const res = await axios.post('/api/homepage/header/ask-dialog/send-question', {
                feedbackDescription: askDialogInfo?.feedbackTypes?.find((feedbackType) => feedbackType.FEEDBACK_TYPE_ID === values.value.selectedFeedbackType)?.DESCRIPTION,
                question: values.value.question,
                userEmail: values.value.userEmail,
            });

            if (res.data.success) {
                setQuery({ ...query, askAQuestionDialogOpen: false });
            }
        }
    });


    return (
        <Dialog
            open={query.askAQuestionDialogOpen ?? false}
            onOpenChange={(open) => {
                setQuery({ ...query, askAQuestionDialogOpen: open || undefined });
            }}
        >
            <DialogTrigger asChild>
            </DialogTrigger>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Ask a Question <DialogHelpTopicIcon helpTopicId={helpTopicDocIDs.NEW_ASK_BUTTON} />
                    </DialogTitle>
                    <DialogDescription>Contact your organization's Lucidoc Administrator(s) </DialogDescription>
                </DialogHeader>
                <form className='w-full flex flex-col h-full items-start space-y-4'
                    onSubmit={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        form.handleSubmit();
                    }}
                >
                    <Section>
                        <p>Question Type:</p>
                        <form.Field
                            name='selectedFeedbackType'
                            children={(field) => {
                                return <Select
                                    // v is always a string, and the value of the select is parsed.
                                    onValueChange={(v) => field.setValue(parseInt(v))}
                                    value={field.getValue()}

                                >
                                    <SelectTrigger className="w-full">
                                        <SelectValue />
                                    </SelectTrigger>
                                    <SelectContent>
                                        {askDialogInfo?.feedbackTypes?.map((feedbackType) => {
                                            return <SelectItem value={feedbackType.FEEDBACK_TYPE_ID}>
                                                {feedbackType.DESCRIPTION}
                                            </SelectItem>;
                                        })}
                                    </SelectContent>
                                </Select>;
                            }}
                        />
                    </Section>
                    <Section>
                        <p>Sending Feedback To:</p>
                        <p>
                            {askDialogInfo?.
                                feedbackUsers?.
                                map((contact) => contact.USER ? constructFullName(contact.USER, 'firstMiddleLast') : '')
                                .join(', ')}
                        </p>
                    </Section>
                    <Section>
                        <p>Your Email:</p>
                        <form.Field
                            name={'userEmail'}
                            children={(field) => {
                                return <Input
                                    className='w-full'
                                    value={field.getValue()}
                                    onChange={(e) => field.setValue(e.target.value)}
                                />;
                            }}
                        />
                    </Section>
                    <Section>
                        <p>Question:</p>
                        <form.Field
                            name={'question'}
                            children={(field) => {
                                return <Textarea
                                    className='w-full'
                                    value={field.getValue()}
                                    onChange={(e) => field.setValue(e.target.value)}
                                />;
                            }}
                        />
                    </Section>
                    <div className='flex flex-row w-full'>
                        <Button className='w-full mx-1' type={'submit'}>Send</Button>
                        <Button className='w-full mx-1' type={'button'} variant={'secondary'} onClick={() => setQuery({ ...query, askAQuestionDialogOpen: false })}>Close</Button>
                    </div>
                </form>
            </DialogContent>
        </Dialog >
    );
};


const Section = ({ children }) => {
    return <div className='flex flex-col w-full'>
        {children}
    </div>;
};
