import React, { useEffect, useState } from 'react';
import { DOCUMENTREVISION } from '../../entities/org/DOCUMENTREVISION';
import { MUIDataTableColumn, MUIDataTableProps } from 'mui-datatables';
import DataTable from '../_shared/DataTable/DataTable';
import { TableCell, TableRow } from '@material-ui/core';
import { LoadingIndicator } from '../_shared/LoadingIndicator';
import { LoadingStatuses } from '../../utils/LoadingStatuses';

type TrainingWebinarProps = {
    trainingWebinarDocs: DOCUMENTREVISION[]
}

export function TrainingWebinars(props: TrainingWebinarProps) {
    const [ loadingStatus, setLoadingStatus ] = useState(LoadingStatuses.LOADING);
    const [ tableData, setTableData ] = useState<MUIDataTableProps['data']>([]);
    const [ docContentHashedByDocID, setDocContentHashedByDocID ] = useState<{[key: string]: string}>({});

    useEffect(() => {
        setLoadingStatus(LoadingStatuses.LOADING);

        const newDocContentHashedByDocID: {[key: string]: string} = {};

        const newTableData = ((props.trainingWebinarDocs || []) as DOCUMENTREVISION[]).map(docrev => {
            // build the hash
            newDocContentHashedByDocID[docrev.DOCID] = (docrev.SUB_REVISIONS?.[0].ALL_STORE?.HTML || '')
                .replace(/target="_top"/g, 'target="_blank"');

            // build the newTableData array
            return {
                DocID: docrev.DOCID,
                Title: (docrev.TITLE || '').replace(/(.*)- /g, ''),
            };
        });

        setTableData(newTableData);
        setDocContentHashedByDocID(newDocContentHashedByDocID);

        setLoadingStatus(LoadingStatuses.READY);
    }, [props.trainingWebinarDocs]);

    if (loadingStatus !== LoadingStatuses.READY) {
        return <LoadingIndicator />;
    }

    return (
        <DataTable
            muiDataTableProps={{
                title: 'Training Webinars',
                columns: tableData[0] && Object.keys(tableData[0]).map(key => {
                    const returnObj: MUIDataTableColumn = {
                        name: key
                    };

                    if (key === 'DocID') {
                        returnObj.options = {
                            display: false
                        };
                    }

                    return returnObj;
                }),
                data: tableData,
                options: {
                    expandableRows: true,
                    renderExpandableRow: (rowData: string[]) => {
                        const docID = rowData[0];

                        return (
                            <TableRow>
                                <TableCell colSpan={3}>
                                    <div
                                        dangerouslySetInnerHTML={{ __html: docContentHashedByDocID[docID] }}
                                    />
                                </TableCell>
                            </TableRow>
                        );
                    }
                }
            }}
        />
    );
}
