import {
    Column,
    Entity,
    OneToMany,
    OneToOne,
    PrimaryColumn,
} from 'typeorm';
import { CE_COURSE_SNAPSHOT,  } from './CE_COURSE_SNAPSHOT';
import { CE_COURSE_SCHEDULE,  } from './CE_COURSE_SCHEDULE';
import { SchemaEntityManager } from '../SchemaEntityManager';

// Factory function to create CE_COURSE_PROTOTYPE entity dynamically
function createCeCoursePrototypeEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('CE_COURSE_PROTOTYPE', { schema })
    class CE_COURSE_PROTOTYPE {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            name: 'CE_COURSE_PROTOTYPE_ID'
        })
        CE_COURSE_PROTOTYPE_ID!: number;

        @Column('number', {
            nullable: false,
            name: 'OWNER_USERID'
        })
        OWNER_USERID!: number;

        @Column('varchar2', {
            nullable: true,
            length: 500,
            name: 'TITLE'
        })
        TITLE!: string | null;

        @Column('clob', {
            nullable: true,
            name: 'DESCRIPTION'
        })
        DESCRIPTION!: string | null;

        @Column('varchar2', {
            nullable: false,
            length: 14,
            default: () => '\'In Preparation\'',
            name: 'STATUS'
        })
        STATUS!: string;

        @Column('timestamp', {
            nullable: true,
            name: 'ARCHIVE_DATE'
        })
        ARCHIVE_DATE!: Date | null;

        @Column('char', {
            nullable: false,
            default: () => '1',
            name: 'ENFORCE_ASSIGNMENT_ORDER'
        })
        ENFORCE_ASSIGNMENT_ORDER!: string;

        @Column('number', {
            nullable: true,
            name: 'CREDIT_HOURS'
        })
        CREDIT_HOURS!: number | null;

        @Column('char', {
            nullable: false,
            default: () => '1',
            name: 'REQUIRES_PASSING_GRADE'
        })
        REQUIRES_PASSING_GRADE!: string;

        @OneToMany(
            () => manager.getCeCourseSnapshotEntity(schema),
            (ceCourseSnapshot) => ceCourseSnapshot.CE_COURSE_PROTOTYPE
        )
        CE_COURSE_SNAPSHOTS!: CE_COURSE_SNAPSHOT[] | null;

        @OneToOne(
            () => manager.getCeCourseScheduleEntity(schema),
            (ceCourseSchedule) => ceCourseSchedule.CE_COURSE_PROTOTYPE
        )
        CE_COURSE_SCHEDULE!: CE_COURSE_SCHEDULE | null;
    }

    return CE_COURSE_PROTOTYPE;
}

// Export the factory function
export { createCeCoursePrototypeEntity };

// Export the type for CE_COURSE_PROTOTYPE entities created by the factory function
export type CE_COURSE_PROTOTYPE = InstanceType<ReturnType<typeof createCeCoursePrototypeEntity>>;
