const LucidocColors = {

    // ---------------- ROYGBIV ---------------

    lightRed: '#FD7C6D',
    red: '#D54A3B',

    orange: 'orange', // temp color, maybe change later to something more personalized

    yellow: '#FFC85E',

    green: '#0EC356',

    lightLightBlue: 'aliceblue', // these blues are temp colors, maybe change later to something more personalized
    lightBlue: 'deepskyblue',
    blue: 'royalblue',

    lightPurple: '#A38BDC',
    purple: '#8665D6',

    // ---------- WHITE, GREY, BLACK ----------

    white: '#F8F8F8',

    lightLightGray: '#f4f5f7',
    lightGray: '#E9ECEF',
    gray: '#C7C7C7',
    darkGray: '#5A6169',

    black: '#434343'
};

export default LucidocColors;
