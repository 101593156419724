import React, { useEffect, useState } from 'react';
import LucidocColors from '../../../../../constants/LucidocColors';

import axios from 'axios';

import { LoadingStatuses } from '../../../../../utils/LoadingStatuses';
import LucidocForm from '../../../../_shared/Forms/LucidocForm/LucidocForm';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import SelectCommittee from '../../../../_shared/Forms/Select/SelectCommittee';
import SaveBar from '../../../../_shared/SaveBar/SaveBar';
import { redirectToHomepage } from '../../../../../utils/savebarUtilities';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    committeeBoardDescription: {
        marginTop: '1rem',
    },
});

const AdminCommitteePreferences: React.FC = () => {
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatuses>(LoadingStatuses.MOUNTING);

    const [initialState, setInitialState] = useState<{
        consentAgendasActivated: number;
        boardCommitteeId?: number;
    }>();

    const [consentAgendasActivated, setConsentAgendasActivated] = useState<number>();
    const [boardCommitteeId, setBoardCommitteeId] = useState<number | undefined>();

    const classes = useStyles();

    const loadInitialData = async () => {
        const res = await axios.get('/api/committee/preferences');

        setInitialState(res.data);
        setConsentAgendasActivated(res.data.consentAgendasActivated);
        setBoardCommitteeId(res.data.boardCommitteeId);
    };

    useEffect(() => {
        if (
            loadingStatus === LoadingStatuses.LOADING ||
            loadingStatus === LoadingStatuses.MOUNTING
        ) {
            loadInitialData()
                .then(() => setLoadingStatus(LoadingStatuses.READY));
        }
    }, [loadingStatus]);

    const displaySaveButton = initialState ? (
        initialState.boardCommitteeId !== boardCommitteeId ||
        initialState.consentAgendasActivated !== consentAgendasActivated
    ) : false;

    const saveCommitteePreferences = async () => {
        await axios.put(
            '/api/committee/preferences',
            {
                consentAgendasActivated,
                boardCommitteeId
            }
        );
        setLoadingStatus(LoadingStatuses.LOADING);
    };

    return <div>
        <LucidocForm
            width='50%'
            title='Update committee preferences'
            name='Committee Preferences'
            fields={[
                {
                    component: <FormControlLabel
                        control={
                            <Checkbox
                                checked={consentAgendasActivated === 1}
                                onChange={() => setConsentAgendasActivated(prev => prev === 1 ? 0 : 1)}
                                style={{ color: LucidocColors.purple }}
                            />
                        }
                        label='Use Committees and Consent Agendas'
                    />
                },
                {
                    name: 'Committee to be considered the board',
                    description: <div className={classes.committeeBoardDescription}>
                        Some organizations choose to make a document revision Official once it has received approval
                        from all committees except the Board. Once Official, such document revisions
                        are available for use by readers while still pending ratification by the Board.
                        <br />
                        <br />
                        If a committee is specified, below, to be considered the Board, the user routing a document
                        revision will be given the option of having that revision become Official once it has received
                        approval from all committees except the Board.
                    </div>,
                    component: <SelectCommittee
                        onChange={setBoardCommitteeId}
                        committeeId={boardCommitteeId}
                        placeholder='Select Board Committee...'
                    />
                }
            ]}
        />
        <SaveBar
            onSave={async () => await saveCommitteePreferences()}
            isSaveDisabled={!displaySaveButton}

            onCancel={() => setLoadingStatus(LoadingStatuses.LOADING)}
            isCancelDisabled={!displaySaveButton}

            onClose={() => { 
               redirectToHomepage();
             }}
        />
    </div>;

};

export default AdminCommitteePreferences;
