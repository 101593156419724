import React, { useEffect, useState } from 'react';
import { MUIDataTableProps } from 'mui-datatables';
import axios from 'axios';
import { COMMITTEE } from '../../../../../entities/org/COMMITTEE';
import { LoadingIndicator } from '../../../../_shared/LoadingIndicator';
import { LoadingStatuses } from '../../../../../utils/LoadingStatuses';
import ProduceBacklogTable from '../ProduceBacklogTable';
import { constructFullName } from '@/components/UserPicker/utils/constructFullName';

interface BacklogProps {
    onSelectCommittee?: (committeeID: number) => void
    // this component gets a list of all committees, and sometimes the parent needs that same list, so we can use that same query:
    sendCommitteesToParent?: (committees: COMMITTEE[]) => void
}

export function CommitteeBacklog(props: BacklogProps) {
    const [ loadingStatus, setLoadingStatus ] = useState(LoadingStatuses.MOUNTING);
    const [ tableData, setTableData ] = useState<MUIDataTableProps['data']>([]);

    useEffect(() => {
        loadBacklog().then();
    }, []);

    async function loadBacklog() {
        setLoadingStatus(LoadingStatuses.LOADING);

        const res = await axios.get('/api/approval-routing/get-committee-backlog');

        const dataForTable = (res.data.committees as COMMITTEE[]).map(committee => {
            return {
                CommitteeID: committee.COMMITTEE_ID,
                Committee: committee.NAME,
                '# Unscheduled': committee.COMMITTEE_APPROVAL_REQUESTS && committee.COMMITTEE_APPROVAL_REQUESTS.length > 0 ? committee.COMMITTEE_APPROVAL_REQUESTS.length : null,
                '# Scheduled': committee.CONSENT_AGENDA_TASKS && committee.CONSENT_AGENDA_TASKS.length > 0 ? committee.CONSENT_AGENDA_TASKS.length : null,
                'Avg # Days to Approve': res.data.daysToApproveByCommittee && res.data.daysToApproveByCommittee[committee.COMMITTEE_ID] && Math.round(res.data.daysToApproveByCommittee[committee.COMMITTEE_ID]),
                'Next Meeting Date': res.data.nextStartDates && res.data.nextStartDates[committee.COMMITTEE_ID] && new Date(res.data.nextStartDates[committee.COMMITTEE_ID]).toLocaleDateString(),
                Chairperson: committee.CHAIRPERSON && constructFullName(committee.CHAIRPERSON, 'lastFirstMiddle'),
                Secretary: committee.SECRETARY && constructFullName(committee.SECRETARY, 'lastFirstMiddle'),
            };
        });

        setTableData(dataForTable);
        props.sendCommitteesToParent?.(res.data.committees);

        setLoadingStatus(LoadingStatuses.READY);
    }

    if (loadingStatus === LoadingStatuses.LOADING) {
        return (
            <LoadingIndicator/>
        );
    }

    return (
        <ProduceBacklogTable
            title={'Committee'}
            tableData={tableData}
            titleOfHiddenIDColumn={'CommitteeID'}
            onSelectItem={(committeeID) => props.onSelectCommittee?.(committeeID)}
            onRefresh={() => loadBacklog()}
        />
    );
}
