import {
    Column,
    Entity,
    JoinColumn,
    ManyToOne,
    OneToMany,
    PrimaryColumn,
} from 'typeorm';
import { CE_ENROLLMENT_SNAPSHOT,  } from './CE_ENROLLMENT_SNAPSHOT';
import { CE_COURSE_SNAPSHOT,  } from './CE_COURSE_SNAPSHOT';
import { SchemaEntityManager } from '../SchemaEntityManager';

// Factory function to create CE_COURSE_SESSION entity with dynamic schema
function createCeCourseSessionEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('CE_COURSE_SESSION', { schema })
    class CE_COURSE_SESSION {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            name: 'CE_COURSE_SESSION_ID'
        })
        CE_COURSE_SESSION_ID!: number;

        @Column('number', {
            nullable: false,
            name: 'CE_COURSE_SNAPSHOT_ID'
        })
        CE_COURSE_SNAPSHOT_ID!: number;

        @ManyToOne(
            () => manager.getCeCourseSnapshotEntity(schema),
            (ceCourseSnapshot) => ceCourseSnapshot.CE_COURSE_SESSIONS
        )
        @JoinColumn({
            name: 'CE_COURSE_SNAPSHOT_ID'
        })
        CE_COURSE_SNAPSHOT!: CE_COURSE_SNAPSHOT | null;

        @Column('timestamp', {
            nullable: true,
            name: 'START_DATE'
        })
        START_DATE!: Date | null;

        @Column('timestamp', {
            nullable: true,
            name: 'END_DATE'
        })
        END_DATE!: Date | null;

        @OneToMany(
            () => manager.getCeEnrollmentSnapshotEntity(schema),
            (ceEnrollmentSnapshot) => ceEnrollmentSnapshot.CE_COURSE_SESSION
        )
        CE_ENROLLMENT_SNAPSHOTS!: CE_ENROLLMENT_SNAPSHOT[] | null;
    }

    return CE_COURSE_SESSION;
}

// Export the factory function
export { createCeCourseSessionEntity };

// Export the type for CE_COURSE_SESSION entities created by the factory function
export type CE_COURSE_SESSION = InstanceType<ReturnType<typeof createCeCourseSessionEntity>>;
