import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { EditDocReviewNotifications } from './components/EditDocReviewNotifications';
import { EditDocManuals } from './components/EditDocManuals';
import { EditDocCollaborators } from './components/EditDocCollaborators';
import { EditDocVisibility } from './components/EditDocVisibility';
import { EditInPlace2 } from './components/EditInPlace2';
import { EditDocAskLucy } from './components/EditDocAskLucy';
import { EditDocGeneral } from './components/EditDocGeneral/EditDocGeneral';

// this function currently loads content in an iframe, which we need to do until the entire
// EditDoc component can edit all doc values; then it can likely be taken out of the react-router
// format and dont some other way.

export function EditDoc() {
    return (
        <Switch>
            <Route path='/docmanager/editdoc/general/docid/:docid/docrevid/:docrevid' component={EditDocGeneral} />
            <Route path='/docmanager/editdoc/collaborators/docid/:docid/docrevid/:docrevid' component={EditDocCollaborators} />
            <Route path='/docmanager/editdoc/ask-lucy/docid/:docid/docrevid/:docrevid' component={EditDocAskLucy} />
            <Route path='/docmanager/editdoc/visibility/docid/:docid/docrevid/:docrevid' component={EditDocVisibility} />
            <Route path='/docmanager/editdoc/edit-in-place2-enable' component={EditInPlace2}/>
            <Route path='/docmanager/editdoc/review-notifications/docrevid/:docrevid' component={EditDocReviewNotifications} />
            <Route path='/docmanager/editdoc/manuals/docrevid/:docrevid' component={EditDocManuals} />
        </Switch>
    );
}
