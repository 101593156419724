import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { TextField } from '@material-ui/core';
import SaveBar from '../../../_shared/SaveBar/SaveBar';
import { HOMEPAGE_LINK, createHomepageLinkEntity } from '../../../../entities/org/HOMEPAGE_LINK';
import { AddCircleOutline, Close } from '@material-ui/icons';
import { LoadingIndicator } from '../../../_shared/LoadingIndicator';
import { LoadingStatuses } from '../../../../utils/LoadingStatuses';
import { makeStyles } from '@material-ui/core/styles';
import LucidocColors from '../../../../constants/LucidocColors';
import SaveIndicator from '../../../_shared/SaveIndicator';
import { TemporaryFavoritesStar } from '../../../_shared/TemporaryFavoritesStar/TemporaryFavoritesStar';
import { setTitleInAdmin } from '../../../../utils/setTitleInAdmin';
import { redirectToHomepage } from '../../../../utils/savebarUtilities';
import setWindowTitle from '../../../../utils/setWindowTitle';

const useStyles = makeStyles({
    homepageLinkOverallDiv: {
        padding: '1rem',
        boxSizing: 'border-box',
    },
    homepageLinkInput: {
        marginRight: '.5rem',
    },
    homepageLinkHTTPSpan: {
        color: LucidocColors.darkGray,
        margin: '0 .25rem 0 1rem',
    },
    homepageLinkURLInput: {
        width: '500px'
    },
    homepageLinkInputRow: {
        marginBottom: '1rem',
        display: 'flex',
        alignItems: 'center',
    },
    homepageLinkAddButton: {
        color: LucidocColors.gray,
        '&:hover': {
            color: LucidocColors.green,
            cursor: 'pointer'
        }
    },
    homepageLinkDeleteButton: {
        color: LucidocColors.gray,
        '&:hover': {
            color: LucidocColors.red,
            cursor: 'pointer'
        }
    }
});

type EditHomepageLinksProps = {
    onSave?: (arg0: HOMEPAGE_LINK[]) => void
    onCancel?: () => void // if loaded in modal, close modal on cancel
    loadInsideModal?: boolean // this just removes padding since the modal has its own
    helpTopicID?: number // modal has a help topic
}

export function EditHomepageLinks(props: EditHomepageLinksProps) {
    const classes = useStyles();

    const [ loadingStatus, setLoadingStatus ] = useState(LoadingStatuses.LOADING);

    const [ homepageLinks, setHomepageLinks ] = useState<HOMEPAGE_LINK[]>([]);
    const [ homepageLinksToRemove, setHomepageLinksToRemove ] = useState<HOMEPAGE_LINK[]>([]);

    const [ isSaveIndicatorVisible, setIsSaveIndicatorVisible ] = useState(false);

    useEffect(() => {
        const newTitle = 'Manage Homepage Reference Links';
        setWindowTitle(newTitle);
        setTitleInAdmin(newTitle);
        getHomepageLinks().then();
    }, []);

    // so there's always one on the page to prompt them to fill it out
    const dummyHomepageLink = new (createHomepageLinkEntity(''));

    async function getHomepageLinks() {
        setLoadingStatus(LoadingStatuses.LOADING);
        const res = await axios.get('/api/administration/organization/homepage-links');

        setHomepageLinks(res.data.homepageLinks.length
            ? res.data.homepageLinks
            : [dummyHomepageLink]
        );
        setLoadingStatus(LoadingStatuses.READY);
    }

    async function saveHomepageLinks() {
        setLoadingStatus(LoadingStatuses.SAVING);
        const res = await axios.post('/api/administration/organization/homepage-links/save-links', {
            homepageLinks,
            homepageLinksToRemove,
        });

        setHomepageLinks(res.data.newHomepageLinks.length
            ? res.data.newHomepageLinks
            : [dummyHomepageLink]
        );
        props.onSave?.(res.data.newHomepageLinks);

        setIsSaveIndicatorVisible(true);
        setLoadingStatus(LoadingStatuses.READY);
    }

    function updateHomepageLink(
        field: 'TEXT' | 'URL',
        value: string,
        idx: number
    ) {
        const newHomepageLinks = homepageLinks.slice();
        newHomepageLinks[idx][field] = value;

        setHomepageLinks(newHomepageLinks);
    }

    function removeHomepageLink(idx: number) {
        const newHomepageLinks = homepageLinks.slice();
        newHomepageLinks.splice(idx, 1);

        if (homepageLinks[idx].HOMEPAGE_LINK_ID) {
            setHomepageLinksToRemove(homepageLinksToRemove.concat(homepageLinks[idx]));
        }

        setHomepageLinks(newHomepageLinks);
    }

    if (loadingStatus !== LoadingStatuses.READY) {
        return <LoadingIndicator loadingStatus={loadingStatus} />;
    }

    return (
        <div>
            <div style={{ float: 'right' }}>
                <TemporaryFavoritesStar targetPathName={'admin/admin.pl?repname=reference_links'} />
            </div>

            <div
                className={classes.homepageLinkOverallDiv}
                style={props.loadInsideModal ? { padding: 0 } : {}}
            >

                {homepageLinks.map((link, idx) => {
                    return (
                        <div key={idx} className={classes.homepageLinkInputRow}>
                            <TextField
                                label={'Text'}
                                variant={'outlined'}
                                size={'small'}
                                value={link.TEXT || ''}
                                onChange={e => updateHomepageLink(
                                    'TEXT',
                                    e.currentTarget.value,
                                    idx
                                )}
                                inputProps={{ maxLength: 64 }}
                                className={classes.homepageLinkInput}
                            />

                            <span className={classes.homepageLinkHTTPSpan}>https:// </span>

                            <TextField
                                label={'URL'}
                                variant={'outlined'}
                                size={'small'}
                                value={link.URL || ''}
                                onChange={e => updateHomepageLink(
                                    'URL',
                                    e.currentTarget.value,
                                    idx
                                )}
                                inputProps={{ maxLength: 512 }}
                                className={`${classes.homepageLinkInput} ${classes.homepageLinkURLInput}`}
                            />

                            <Close
                                onClick={() => removeHomepageLink(idx)}
                                className={classes.homepageLinkDeleteButton}
                            />
                        </div>
                    );
                })}

                <AddCircleOutline
                    onClick={() => setHomepageLinks(homepageLinks.concat(new (createHomepageLinkEntity(''))))}
                    className={classes.homepageLinkAddButton}
                />
            </div>

            <SaveBar
                onSave={() => saveHomepageLinks()}
                isSaveDisabled={!!homepageLinks.find(link => !link.TEXT || !link.URL)}
                whyIsSaveDisabled={homepageLinks.find(link => !link.TEXT || !link.URL)
                    ? 'Items cannot be left blank'
                    : ''
                }
                onCancel={() => props.onCancel
                    ? props.onCancel()
                    : getHomepageLinks()
                }
                helpTopicID={props.helpTopicID}
                onClose={() => redirectToHomepage()}
            />

            <SaveIndicator
                open={isSaveIndicatorVisible}
                onClose={() => setIsSaveIndicatorVisible(false)}
                message={'Reference links saved successfully.'}
            />
        </div>
    );
}
