import { Row } from '@tanstack/react-table';
import { DocRevEntry } from './DocumentRevisionTable';
import { InPrepDocumentIcon } from '@/ui/icons/document/InPrep';
import { DraftDocumentIcon } from '@/ui/icons/document/Draft';
import { DisplayStatuses, DocumentRevisionStatuses } from '@/entities/org/DOCUMENTREVISION';
import { OfficialDocumentIcon } from '@/ui/icons/document/Official';
import { ArchivedDocumentIcon } from '@/ui/icons/document/Archived';
import { InvisibleDraftDocumentIcon } from '@/ui/icons/document/InvisibleDraft';
import { DiscardDocumentIcon } from '@/ui/icons/document/Discard';
import { PendingReleaseDocumentIcon } from '@/ui/icons/document/PendingRelease';
import { ChevronDownIcon, ChevronRightIcon } from '@radix-ui/react-icons';
import { Button } from '@/ui/ui/button';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/ui/ui/tooltip';

interface DocumentsDataTableExpansionCellProps {
    docStatusId: number
    row: Row<DocRevEntry>
}

export const DocumentsDataTableExpansionCell: React.FC<DocumentsDataTableExpansionCellProps> = ({ docStatusId, row }) => {
    const renderIcon = () => {
        switch (docStatusId) {
            case DocumentRevisionStatuses.prep:
                return <InPrepDocumentIcon size={20} />;
            case DocumentRevisionStatuses.draft:
                return <DraftDocumentIcon size={20} />;
            case DocumentRevisionStatuses.official:
                return <OfficialDocumentIcon size={20} />;
            case DocumentRevisionStatuses.archive:
                return <ArchivedDocumentIcon size={20} />;
            case DocumentRevisionStatuses.invisible:
                return <InvisibleDraftDocumentIcon size={20} />;
            case DocumentRevisionStatuses.discard:
                return <DiscardDocumentIcon size={20} />;
            default:
                return <PendingReleaseDocumentIcon size={20} />;
        }
    };

    return <div className='flex flex-row space-x-1 items-center justify-start'>
        <Button
            className='h-6 w-6'
            variant={'ghost'}
            size={'icon'}
            onClick={() => row.toggleExpanded()}
        >
            {row.getIsExpanded() ? <ChevronDownIcon width={20} height={20} /> : <ChevronRightIcon width={20} height={20} />}
        </Button>
        <TooltipProvider delayDuration={100}>
            <Tooltip>
                <TooltipTrigger>{renderIcon()}</TooltipTrigger>
                <TooltipContent sideOffset={4} side='right'>
                    {DisplayStatuses[docStatusId]}
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    </div>;
};

