import { ACL } from '../../../../entities/org/ACL';
import { PERSON } from '../../../../entities/master/PERSON';
import { USERGROUP } from '../../../../entities/org/USERGROUP';
import { constructFullName } from '@/components/UserPicker/utils/constructFullName';

// this flattening function could be used elsewhere, but it's here for now since we
// need it for a DataTable for now:

export function transformACLArrayIntoPersonOrUserGroupArray(
    ACLs: ACL[]
)
    : (PERSON | USERGROUP)[] | string[]
{
    const usersAndGroups: (PERSON | USERGROUP)[] = [];

    ACLs.forEach(acl => {
        if (acl.OWNERORWHATEVER) usersAndGroups.push(acl.OWNERORWHATEVER);
        else if (acl.USERGROUP) usersAndGroups.push(acl.USERGROUP);
    });

    return usersAndGroups;
}

export function transformACLArrayIntoStringArray(
    ACLs: ACL[],
)
    : string[]
{
    const namesAsString: string[] = [];

    ACLs.forEach(acl => {
        if (acl.OWNERORWHATEVER) namesAsString.push(constructFullName(acl.OWNERORWHATEVER, 'lastFirstMiddle'));
        else if (acl.USERGROUP) namesAsString.push(acl.USERGROUP.NAME);
    });

    return namesAsString;
}
