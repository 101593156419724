import React, { useEffect, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import UserPicker from '../../../../UserPicker/UserPicker';
import { PERSON } from '../../../../../entities/master/PERSON';
import { USERGROUP } from '../../../../../entities/org/USERGROUP';
import H3WithHelpTip from '../../../../_shared/Forms/H3WithHelpTip';
import { makeStyles } from '@material-ui/core';
import axios from 'axios';
import { REVIEWERASSIGNMENT } from '../../../../../entities/org/REVIEWERASSIGNMENT';
import SaveBar from '../../../../_shared/SaveBar/SaveBar';
import { LoadingIndicator } from '../../../../_shared/LoadingIndicator';
import { LoadingStatuses } from '../../../../../utils/LoadingStatuses';
import { RefreshRounded } from '@material-ui/icons';

type EditDocReviewNotificationsProps = {
    match: any
}

const useStyles = makeStyles({
    mainDiv: {
        width: '500px',
        margin: '1rem 1rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
    },
    hrDivider: {
        marginTop: '2rem',
        borderTop: 0,
        borderColor: 'grey',
    }
});

export function EditDocReviewNotifications(props: EditDocReviewNotificationsProps) {
    const classes = useStyles();

    const [ loadingStatus, setLoadingStatus ] = useState<LoadingStatuses>(LoadingStatuses.LOADING);

    const [ reviewerAssignments, setReviewerAssignments ] = useState<(PERSON | USERGROUP)[]>([]);
    const [ notificationAssignments, setNotificationAssignments ] = useState<(PERSON | USERGROUP)[]>([]);

    useEffect(() => {
        loadData().then();
    }, []);

    async function loadData() {
        setLoadingStatus(LoadingStatuses.LOADING);

        const res = await axios.get('/api/doc-manager/document/edit-doc/review-notifications', {
            params: {
                docrevid: props.match.params.docrevid
            }
        });

        setReviewerAssignments(convertReviewerAssignmentForUserPicker(res.data.reviewerAssignments));
        setNotificationAssignments(convertReviewerAssignmentForUserPicker(res.data.notificationAssignments));

        setLoadingStatus(LoadingStatuses.READY);
    }

    function convertReviewerAssignmentForUserPicker(revAssignments: REVIEWERASSIGNMENT[]): (PERSON | USERGROUP)[] {
        return revAssignments.reduce((acc: (PERSON | USERGROUP)[], ra) => {
                if (ra.USER) acc.push(ra.USER);
                if (ra.USERGROUP) acc.push(ra.USERGROUP);

                return acc;
            }, []
        );
    }

    async function saveData() {
        setLoadingStatus(LoadingStatuses.SAVING);

        const res = await axios.post('/api/doc-manager/document/edit-doc/review-notifications/save-reviewer-assignments', {
            docrevid: props.match.params.docrevid,
            reviewerAssignments,
            notificationAssignments
        });

        setReviewerAssignments(convertReviewerAssignmentForUserPicker(res.data.reviewerAssignments));
        setNotificationAssignments(convertReviewerAssignmentForUserPicker(res.data.notificationAssignments));

        setLoadingStatus(LoadingStatuses.READY);
    }

    if (loadingStatus !== LoadingStatuses.READY) {
        return <LoadingIndicator loadingStatus={loadingStatus} />;
    }

    return (
        <div className={classes.mainDiv}>
            <DragDropContext onDragEnd={() => {}}>
                <H3WithHelpTip
                    helpText={'Add the names of users or user groups that should receive feedback requests'}
                    text={'Draft Feedback Notifications'}
                />
                <UserPicker
                    hideArchivedGroups={true}
                    getUsers={true}
                    getGroups={true}
                    addUserSignature={(newUsers: (PERSON | USERGROUP)[]) => setReviewerAssignments(newUsers as PERSON[])}
                    removeUserSignature={(newUsers: (PERSON | USERGROUP)[]) => setReviewerAssignments(newUsers as PERSON[])}
                    addGroupSignature={(newUsers: (PERSON | USERGROUP)[]) => setReviewerAssignments(newUsers as PERSON[])}
                    removeGroupSignature={(newUsers: (PERSON | USERGROUP)[]) => setReviewerAssignments(newUsers as PERSON[])}
                    selectedUsersPlusGroups={reviewerAssignments}
                    isDragDisabled={true}
                />

                <hr className={classes.hrDivider} />

                <H3WithHelpTip
                    helpText={'Add the names of users or user groups that should receive notification of Official publication'}
                    text={'Official Publication Notifications'}
                />
                <UserPicker
                    hideArchivedGroups={true}
                    getUsers={true}
                    getGroups={true}
                    addUserSignature={(newUsers: (PERSON | USERGROUP)[]) => setNotificationAssignments(newUsers as PERSON[])}
                    removeUserSignature={(newUsers: (PERSON | USERGROUP)[]) => setNotificationAssignments(newUsers as PERSON[])}
                    addGroupSignature={(newUsers: (PERSON | USERGROUP)[]) => setNotificationAssignments(newUsers as PERSON[])}
                    removeGroupSignature={(newUsers: (PERSON | USERGROUP)[]) => setNotificationAssignments(newUsers as PERSON[])}
                    selectedUsersPlusGroups={notificationAssignments}
                    isDragDisabled={true}
                />
            </DragDropContext>

            <SaveBar
                onSave={ () => saveData() }
                // saveButtonText='Save Edits'

                onCancel={ () => loadData() }
                // cancelButtonText='Undo Edits'
                cancelIcon={ <RefreshRounded /> }

                onClose={ () => window.parent.close() }
            />
        </div>
    );
}
