import React from 'react';
import { PrioritiesCard2 } from './overview/PrioritiesCard2';
import { ContactsCard2 } from './overview/ContactsCard2';
import { MessageBoardCard2 } from './overview/MessageBoardCard2';
import { ReferenceLinksCard2 } from './overview/ReferenceLinksCard2';
import { FavoritesCard2 } from './overview/FavoritesCard2';

interface OverviewProps {

}

export const Overview: React.FC<OverviewProps> = () => {
    return (
        <div className='flex flex-row flex-wrap w-full mt-4 max-w-[100rem]'>
                <PrioritiesCard2 />
                <FavoritesCard2 />
                <ReferenceLinksCard2 />
            <div className='flex flex-row mb-2 flex-wrap lg:flex-nowrap'>
                <ContactsCard2 />
                <MessageBoardCard2 />
            </div>
        </div>
    );
};