import React from 'react';

interface CreateReportIcon {
    size: number
}

export const CreateReportIcon: React.FC<CreateReportIcon> = ({ size }) => {
    return (
        <svg width={size.toString()} height={size.toString()} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" rx="4" fill="hsl(var(--primary))"/>
            <path d="M6 6C6 5.44772 6.44772 5 7 5H13.5858C13.851 5 14.1054 5.10536 14.2929 5.29289L17.7071 8.70711C17.8946 8.89464 18 9.149 18 9.41421V18C18 18.5523 17.5523 19 17 19H7C6.44772 19 6 18.5523 6 18V6Z" fill="white"/>
            <path d="M9.82319 12.8077C9.62379 12.8077 9.50464 12.5857 9.61486 12.4195L11.7562 9.19104C11.8931 8.98465 12.2145 9.08156 12.2145 9.32922V11.9423C12.2145 12.0804 12.3265 12.1923 12.4645 12.1923H13.8916C14.091 12.1923 14.2101 12.4143 14.0999 12.5805L11.9586 15.809C11.8217 16.0154 11.5002 15.9184 11.5002 15.6708V13.0577C11.5002 12.9196 11.3883 12.8077 11.2502 12.8077H9.82319Z" fill="#A291CE"/>
        </svg>
    );
};