import {
    Column,
    Entity,
    PrimaryColumn,
} from 'typeorm';
import { SchemaEntityManager } from '../SchemaEntityManager';

export enum ChecklistAuditStatuses {
    Active = 'Active',
    Completed = 'Completed',
    Archived = 'Completed, Archived',
    Cancelled = 'Cancelled'
}

// Factory function to create CHECKLIST_AUDIT entity with dynamic schema
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function createChecklistAuditEntity(schemaName: string,  manager: SchemaEntityManager) {
    @Entity('CHECKLIST_AUDIT', { schema: 'ACCREDITATIONMANAGER' })
    class CHECKLIST_AUDIT {
        static SCHEMANAME = schemaName;
        @PrimaryColumn('number', {
            nullable: false,
            name: 'CHECKLIST_AUDIT_ID'
        })
        CHECKLIST_AUDIT_ID!: number;

        @Column('number', {
            nullable: false,
            name: 'CHECKLIST_EDITION_ID'
        })
        CHECKLIST_EDITION_ID!: number;

        @Column('varchar2', {
            nullable: false,
            length: 32,
            name: 'TYPE'
        })
        TYPE!: string;

        @Column('varchar2', {
            nullable: false,
            length: 32,
            default: () => '\'Active\'',
            name: 'STATUS'
        })
        STATUS!: string;

        @Column('timestamp', {
            nullable: false,
            default: () => 'SYSDATE',
            name: 'START_DATE'
        })
        START_DATE!: Date;

        @Column('timestamp', {
            nullable: true,
            name: 'END_DATE'
        })
        END_DATE!: Date | null;

        @Column('date', {
            nullable: true,
            name: 'DESIGNATED_START_DATE'
        })
        DESIGNATED_START_DATE!: Date | null;

        @Column('date', {
            nullable: true,
            name: 'DESIGNATED_END_DATE'
        })
        DESIGNATED_END_DATE!: Date | null;

        static Statuses = ChecklistAuditStatuses;
    }

    return CHECKLIST_AUDIT;
}

// Type definition for the return type of the factory function
type CHECKLIST_AUDIT = ReturnType<typeof createChecklistAuditEntity>;

// Explicitly export the type
export type { CHECKLIST_AUDIT };
export { createChecklistAuditEntity };
