import {
    Column,
    Entity,
    JoinColumn,
    ManyToOne,
    PrimaryColumn,
} from 'typeorm';
import { PERSON } from '../master/PERSON';
import { SchemaEntityManager } from '../SchemaEntityManager';

export enum BatchJobTypes {
    transferAckSchedules  = 'Transfer Acknowledgement Schedules',
    transferCollaborators = 'Transfer Collaborators',
    transferDepartment    = 'Transfer Department',
    transferDocumentType  = 'Transfer Document Type',
    transferOwner         = 'Transfer Owner',
    transferSigners       = 'Transfer Signers',
}

export const readableJobTypesToCamelCase = Object.keys(BatchJobTypes).reduce((acc: {[key: string]: string}, key) => {
    acc[BatchJobTypes[key as keyof typeof BatchJobTypes]] = key;
    return acc;
}, {});

export interface TransferCollaboratorAffectedDocument {
    docowner_id: string,
    processid: string,
    docstatus: string,
    doctitle: string,
    collaborators_added: string[],
    collaborators_deleted: string[],
    doctype: string,
    document_owner: string,
    docid: string,
    reference: string,
    category: string,
    doclink: string,
}

// Factory function to create BATCH_JOB entity with dynamic schema
function createBatchJobEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('BATCH_JOB', { schema })
    class BATCH_JOB {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            name: 'ID'
        })
        ID!: number;

        @Column('clob', {
            nullable: false,
            name: 'JOB_DEFINITION'
        })
        JOB_DEFINITION!: string;

        @Column('timestamp', {
            nullable: true,
            default: () => 'SYSDATE',
            name: 'CREATED'
        })
        CREATED!: Date | null;

        @Column('timestamp', {
            nullable: true,
            name: 'COMPLETED'
        })
        COMPLETED!: Date | null;

        @Column('clob', {
            nullable: true,
            name: 'DOCUMENT_CHANGES'
        })
        DOCUMENT_CHANGES!: string | null;

        @Column('varchar2', {
            nullable: true,
            name: 'JOB_TYPE'
        })
        JOB_TYPE!: string | null;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'JOB_OWNER_ID'
        })
        JOB_OWNER_ID!: number | null;

        @ManyToOne(() => manager.getPersonEntity(schema), (person) => person.BATCH_JOBS)
        @JoinColumn({
            name: 'JOB_OWNER_ID'
        })
        JOB_OWNER!: PERSON | null;
    }

    return BATCH_JOB;
}

// Export the factory function
export { createBatchJobEntity };

// Export the type for BATCH_JOB entities created by the factory function
export type BATCH_JOB = InstanceType<ReturnType<typeof createBatchJobEntity>>;
