import { MixerHorizontalIcon } from '@radix-ui/react-icons';
import { DropdownMenu, DropdownMenuCheckboxItem, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuSub, DropdownMenuSubContent, DropdownMenuSubTrigger, DropdownMenuTrigger } from '../dropdown-menu';
import { Button } from '../button';
import { DataTableToolbarProps, TableExportOptions } from './data-table';
import { getDocumentsTableExtraHeaders } from '@/v2/components/documentrevisiontable/utils';

export function DataTableViewOptions<TData>({
    table,
    datatableRef,
    noExtraHeaders
}: DataTableToolbarProps<TData>) {

    const isAnyColumnHidden = table.getAllColumns().some((column) => !column.getIsVisible());

    const exportTable = async (exportType: TableExportOptions) => {
        if (datatableRef && datatableRef.current) {
            datatableRef.current.exportTable(exportType, noExtraHeaders ? [] : getDocumentsTableExtraHeaders(false), undefined);
        }
    };

    const isTableEmpty = datatableRef?.current?.getTable().getRowCount() === 0;

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button
                    variant="outline"
                    size="sm"
                    className="ml-auto my-2 h-8 flex"
                >
                    <MixerHorizontalIcon className="h-4 w-4" />
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="w-[200px]">
                <DropdownMenuItem
                    disabled={isTableEmpty}
                    onSelect={() => {
                        // https://tanstack.com/table/v8/docs/guide/row-models#the-order-of-row-model-execution
                        // Row models are executed in an order, view at the link above.
                        // table.getPrePaginationRowModel().rows.forEach((row) => {
                        //     row.getAllCells().map(cell => cell.).join()
                        // })
                        exportTable('csv');
                    }}
                >
                    Export to CSV File
                </DropdownMenuItem>
                <DropdownMenuItem
                    disabled={isTableEmpty}
                    onSelect={() => {
                        exportTable('xlsx');
                    }}
                >
                    Export to Excel File
                </DropdownMenuItem>
                <DropdownMenuItem
                    disabled={isTableEmpty}
                    onSelect={() => {
                        exportTable('pdf');
                    }}
                >
                    Export to PDF
                </DropdownMenuItem>
                <DropdownMenuSub>
                    <DropdownMenuSubTrigger>
                        Toggle Columns
                    </DropdownMenuSubTrigger>
                    <DropdownMenuSubContent>
                        {table
                            .getAllColumns()
                            .filter(
                                (column) =>
                                    typeof column.accessorFn !== 'undefined' && column.getCanHide()
                            )
                            .map((column) => {
                                return (
                                    <DropdownMenuCheckboxItem
                                        key={column.id}
                                        // Prevents the dropdown from closing when visibility options are toggled.
                                        onSelect={(e) => e.preventDefault()}
                                        checked={column.getIsVisible()}
                                        onCheckedChange={(value) => column.toggleVisibility(!!value)}
                                    >
                                        {column.columnDef.meta?.['label'] ?? `No meta.label provided in column definition (${column.id})`}
                                    </DropdownMenuCheckboxItem>
                                );
                            })}
                        {isAnyColumnHidden && <>
                            <DropdownMenuSeparator />
                            <DropdownMenuItem className='items-center justify-center' onSelect={() => {
                                table.getAllColumns().forEach((column) => {
                                    column.toggleVisibility(true);
                                });
                            }}>

                                Reset
                            </DropdownMenuItem>
                        </>}
                    </DropdownMenuSubContent>
                </DropdownMenuSub>
            </DropdownMenuContent>
        </DropdownMenu >
    );
}