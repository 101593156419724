import URLs from '../_shared/constants/URLs';

export const redirectToHomepage = () => {
    if (window.top) {
        window.top.location.href = URLs.home.main;
        return;
    }

    window.location.href = URLs.home.main;
};

export const closeWindow = () => {
    if (window.top) {
        window.top.close();
        return;
    }

    window.close();
};