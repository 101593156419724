import { cn } from '@/lib/utils';
import React, { ReactNode, Ref } from 'react';
import { Link } from 'react-router-dom';

export interface HybridLinkProps {
    // external is set to false if the href points to a React page.
    external: boolean
    href?: string
    children: ReactNode
    className?: string
    openInNewTab?: boolean
    ref?: Ref<HTMLAnchorElement>,
    onClick?: () => void
}

export const HybridLink: React.FC<HybridLinkProps> = ({ href, external, onClick, children, className, openInNewTab, ref }) => {
    if (external) {
        return <a ref={ref} onClick={onClick} className={cn('', className)} target={openInNewTab ? '_blank' : ''} href={href ?? '#'} >
            {children}
        </a>;
    }
    return <Link ref={ref} onClick={onClick}  target={openInNewTab ? '_blank' : ''} className={cn('', className)} to={href ?? '#'}>
        {children}
    </Link>;
};