import { ORGANIZATION } from '@/entities/master/ORGANIZATION';
import { PERSON } from '@/entities/master/PERSON';
import { HomepageContactsGroup, createContactsEntity } from '@/entities/org/CONTACTS';
import { GearOutlineIcon } from '@/ui/icons/outline/GearOutline';
import { Button } from '@/ui/ui/button';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from '@/ui/ui/dialog';
import { Input } from '@/ui/ui/input';
import { MultipleUserPicker } from '@/ui/ui/user-picker';
import { schemaEntityManager } from '@/utils/emInstance';
import { Cross2Icon } from '@radix-ui/react-icons';
import { useForm } from '@tanstack/react-form';
import axios from 'axios';
import React, { useEffect } from 'react';
import { Homepage2DialogContentStyle, Homepage2Query } from '../../Homepage2';
import { useBrowserQuery } from '@/hooks/useBrowserQuery';
interface EditContactsDialogProps {
    refetch: () => void
}
interface EditContactsDialogQuery extends Homepage2Query {
    editContactsDialogOpen?: boolean
}


export const EditContactsDialog: React.FC<EditContactsDialogProps> = ({ refetch }) => {

    const [query, setQuery] = useBrowserQuery<EditContactsDialogQuery>();

    interface HomepageContacts {
        homepageContacts: HomepageContactsGroup[]
        organization?: ORGANIZATION
    }

    const [contacts, setContacts] = React.useState<HomepageContacts>({
        homepageContacts: [],
        organization: undefined
    });

    const [saving, setSaving] = React.useState(false);


    useEffect(() => {
        axios.get('/api/homepage/card/homepage-contacts').then(response => {
            setContacts(response.data);
        });
    }, []);

    const updateContactGroupUsers = (newUsers: PERSON[], group: HomepageContactsGroup) => {
        const targetGroup = contacts.homepageContacts.find((g) => g === group);
        if (targetGroup) {
            targetGroup.contacts = newUsers.map(u => {
                const contactEntity = createContactsEntity('', schemaEntityManager);
                const contact = new contactEntity();
                contact.USER = u;
                contact.USERID = u.USERID;
                contact.TYPE = 'homepage';
                contact.SUBTYPE = group.subtype;

                return contact;
            });
        }
        setContacts({ ...contacts, homepageContacts: [...contacts.homepageContacts] });
    };

    const form = useForm({
        defaultValues: {
            groupName: ''
        },
        onSubmit: ({ value }) => {
            if (value.groupName === '') return;
            setContacts({
                ...contacts,
                homepageContacts: [
                    ...contacts.homepageContacts,
                    {
                        subtype: value.groupName,
                        contacts: []
                    }
                ]
            });
        }
    });

    const saveContacts = async () => {
        setSaving(true);
        const res = await axios.post('/api/administration/organization/edit-contacts/save-contacts',
            {
                homepageContacts: contacts.homepageContacts,
                organization: contacts.organization
            });

        setContacts(res.data);
        setSaving(false);
    };

    return (

        <Dialog
            open={query.editContactsDialogOpen ?? false}
            // Setting the state to undefined will avoid cluttering the url.
            onOpenChange={(open) => {
                setQuery({ ...query, editContactsDialogOpen: open || undefined });
                refetch();
            }}
        >
            <DialogTrigger asChild>
                <div className='cursor-pointer'>
                    <GearOutlineIcon size={18} />
                </div>
            </DialogTrigger>
            <DialogContent className={Homepage2DialogContentStyle}>
                <DialogHeader>
                    <DialogTitle>Edit Contacts</DialogTitle>
                    <DialogDescription>Manage contacts for your Organization</DialogDescription>
                </DialogHeader>
                <div className='w-full flex flex-col h-full items-start'>
                    <div className='flex flex-row w-full justify-between space-x-4'>
                        <form className='flex flex-row space-x-4'
                            onSubmit={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                form.handleSubmit();
                            }}
                        >
                            <form.Field name={'groupName'}>
                                {(field) => {
                                    return <Input
                                        name={field.name}
                                        value={field.state.value}
                                        onBlur={field.handleBlur}
                                        onChange={(e) => field.handleChange(e.target.value)}
                                        placeholder='New Group Name'
                                    />;
                                }}
                            </form.Field>
                            <Button type={'submit'} size={'sm'} variant={'secondary'}>Create</Button>
                        </form>
                        <div>
                            <Button disabled={saving} size={'sm'} variant={'secondary'} onClick={() => saveContacts()}>Save</Button>
                        </div>
                    </div>
                    <div className='flex flex-col justify-start space-y-4 mt-2 w-full h-full'>
                        {contacts.homepageContacts.map((group, idx) => {
                            return <div key={`${group.subtype}-${idx}`} className='flex flex-row justify-between w-full'>
                                <div className='w-full mr-1'>
                                    <p className='text-sm'>{group.subtype}</p>
                                    <MultipleUserPicker
                                        rights={[]}
                                        className='w-full'
                                        inputType='person'
                                        selectedUsers={group.contacts.map((c) => c.USER!)}
                                        setSelectedUsers={(newUsers) => {
                                            updateContactGroupUsers(newUsers as PERSON[], group);
                                        }}
                                        getMappedUsers
                                    />
                                </div>
                                <div>
                                    <Button
                                        size={'icon'} variant={'ghost'}>
                                        <Cross2Icon onClick={() => {
                                            setContacts({
                                                ...contacts,
                                                homepageContacts: contacts.homepageContacts
                                                    .filter((g) => g !== group)
                                            });
                                        }} />
                                    </Button>
                                </div>
                            </div>;
                        })}
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};
