import { useFavorites } from '@/hooks/useFavorites';
import { Button } from '@/ui/ui/button';
import { StarOutline } from '@/ui/icons/outline/StarOutline';

const FavoritesStar = () => {
    const { pageAlreadyFavorited, isCurrentPageInTheDeleteList, toggleFavoriteToBeDeleted, addFavorite, currentURL } = useFavorites();

    return (
        <div className='flex flex-row space-x-4 p-1 absolute right-0 top-0 mr-1 mt-1'>
            <Button
                variant={'ghost'}
                size={'icon'}
                onClick={() => {
                    if (pageAlreadyFavorited) {
                        toggleFavoriteToBeDeleted(pageAlreadyFavorited, true);
                    } else {
                        addFavorite(currentURL, window.document.title);
                    }
                }}
            >
                <StarOutline
                    size={20}
                    fill={pageAlreadyFavorited && !isCurrentPageInTheDeleteList}
                />
            </Button>
        </div>
    );
};
FavoritesStar.displayName = 'FavoritesStar';

export { FavoritesStar };