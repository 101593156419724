import React, { useContext } from 'react';

import UserBundleContext from '@/context/UserBundleContext';

import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/ui/ui/select';

import { helpTopicDocIDs } from '@/_shared/constants/HelpSystemIDs';
import { useBrowserQuery } from '@/hooks/useBrowserQuery';
import { Button } from '@/ui/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogHelpTopicIcon, DialogTitle, DialogTrigger } from '@/ui/ui/dialog';
import { useForm } from '@tanstack/react-form';
import axios from 'axios';
import { CommitteesQuery } from '../../Committees';
import SelectConsentAgenda from '@/ui/ui/advanced-select/select-consent-agenda';
import { COMMITTEE } from '@/entities/org/COMMITTEE';
import { Spinner } from '@/ui/ui/spinner';

interface CreateMinutesDialogProps {
    onCreate: () => void;
    onClose: () => void;
    isOpen: boolean;
    committee?: COMMITTEE;
}

export const CreateMinutesDialog: React.FC<CreateMinutesDialogProps> = (props: CreateMinutesDialogProps) => {
    const context = useContext(UserBundleContext);

    const query = useBrowserQuery<CommitteesQuery>()[0];

    const form = useForm<{
        copyFrom: string
        consentAgendaId: number | null
    }>({
        defaultValues: {
            copyFrom: 'default_content',
            consentAgendaId: null
        },
        onSubmit: async ({ value: {
            copyFrom,
            consentAgendaId
        } }) => {
            if (copyFrom === 'other_minutes' && !consentAgendaId) {
                return;
            }
            const formData = new FormData();
            formData.append('ajax', 'create_minutes_document');
            formData.append('organizationid', `${ context?.organization?.ORGANIZATIONID }`);
            formData.append('consent_agenda_id', `${ query.createAgendaMinutesForAgendaId }`);
            formData.append('copy_from', copyFrom);
            if (consentAgendaId && copyFrom === 'other_minutes') {
                formData.append('copy_from_consent_agenda_id', `${ consentAgendaId }`);
            }

            axios.post(
                '/ajax/consent_agenda_management.pl',
                formData,
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }
            )
            .then(response => {
                const data = response.data;
                if (data.failure) {
                    throw new Error(data.failure);
                }

                if (data.edit_minutes_document_url) {
                    window.open(data.edit_minutes_document_url, '_blank');
                }

                props.onCreate();
            })
            .catch(err => alert(err));
        }
    });

    const copyFrom = form.useStore((state) => state.values.copyFrom);

    return (
        <Dialog
            open={props.isOpen}
            onOpenChange={() => props.onClose()}
        >
            <DialogTrigger asChild>
            </DialogTrigger>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Create Meeting Minutes<DialogHelpTopicIcon helpTopicId={helpTopicDocIDs['ADMIN_COMMITTEES']} />
                    </DialogTitle>
                </DialogHeader>
                <form className='w-full flex flex-col h-full items-start space-y-4'
                    onSubmit={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        form.handleSubmit();
                    }}
                >
                    <Section>
                        <p>Select the source for the minutes:</p>
                        <form.Field
                            name={'copyFrom'}
                            children={(field) => {
                                return <Select
                                    onValueChange={
                                        (v) => {
                                            field.handleChange(v);
                                        }
                                    }
                                    value={field.getValue()}   
                                >
                                    <SelectTrigger className="w-full">
                                        <SelectValue />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectItem value='default_content'>This committee's minutes template</SelectItem>
                                        <SelectItem value='other_minutes'>Another meeting's minutes</SelectItem>
                                        <SelectItem value='none'>No, thanks</SelectItem>
                                    </SelectContent>
                                </Select>;
                            }}
                        />
                        <p>Would you like to start these minutes with content copied from elsewhere?</p>
                    </Section>
                    {
                        copyFrom === 'other_minutes' &&
                            <Section>
                                <p>Select the source for the minutes:</p>
                                <form.Field
                                    name={'consentAgendaId'}
                                    children={(field) => {
                                        return query.editingCommitteeId ? <SelectConsentAgenda
                                            placeholder='Copy from agenda...'
                                            committeeId={query.editingCommitteeId}
                                            consentAgendaId={field.getValue() ?? undefined}
                                            exemptConsentAgendaIds={[parseInt(query.createAgendaMinutesForAgendaId as string)]}
                                            onChange={
                                                (value) => {
                                                    field.handleChange(value?.CONSENT_AGENDA_ID ?? null);
                                                }
                                            }
                                            hasMeetingMinutesDocId
                                        /> : null;
                                    }}
                                />
                                <p>Would you like to start these minutes with content copied from elsewhere?</p>
                            </Section>
                    }
                    <div className='pt-4 flex flex-row w-full'>
                        <form.Subscribe>
                            {
                                formState => 
                                    <Button
                                        className='w-full mx-1'
                                        type={'submit'}
                                    >
                                        {
                                            formState.isSubmitting ? <Spinner /> : 'Create'
                                        }
                                    </Button>
                            }
                        </form.Subscribe>
                        <Button 
                            className='w-full mx-1'
                            variant={'secondary'}
                            type='button'
                            onClick={() => props.onClose()}
                        >
                            Close
                        </Button>
                    </div>
                </form>
            </DialogContent>
        </Dialog >
    );
};


const Section = ({ children }) => {
    return <div className='flex flex-col w-full'>
        {children}
    </div>;
};