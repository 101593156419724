import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import { ArrowRightAlt } from '@material-ui/icons';
import { CONSENT_AGENDA } from '../../../../../../entities/org/CONSENT_AGENDA';
import axios from 'axios';
import { LucidocModal } from '../../../../../_shared/LucidocModal/LucidocModal';
import LucidocForm from '../../../../../_shared/Forms/LucidocForm/LucidocForm';
import SelectConsentAgenda from '../../../../../_shared/Forms/Select/SelectConsentAgenda';

interface ICreateNewMeetingProps {
    onCreateMeeting: () => void;
    onClose: () => void;
    committeeId: number;
}

const CreateNewMeeting: React.FC<ICreateNewMeetingProps> = (props: ICreateNewMeetingProps) => {

    const [loading, setLoading] = useState(false);

    const [date, setDate] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [location, setLocation] = useState('');

    const [invalidTimes, setInvalidTimes] = useState(false);

    const [prototypeConsentAgenda, setPrototypeConsentAgenda] = useState<CONSENT_AGENDA | undefined>();

    const [isSaveIndicatorVisible, setIsSaveIndicatorVisible] = useState(false);

    useEffect(() => {
        if (prototypeConsentAgenda) {
            // Convert UTC date from database to PST
            const startTimePST = (new Date(prototypeConsentAgenda.MEETING_START_DATE)).toLocaleString('en-US', {
                timeZone: 'America/Los_Angeles'
            });
            const endTimeFullPST = (new Date(prototypeConsentAgenda.MEETING_END_DATE!)).toLocaleString('en-US', {
                timeZone: 'America/Los_Angeles'
            });

            // Use toTimeString from dates constructed with PST timeZones
            const startTimeFull = (new Date(startTimePST)).toTimeString();
            const endTimeFull = (new Date(endTimeFullPST)).toTimeString();

            // Remove the seconds to just use HH:MM as input element requires
            const startTimeWithoutSeconds = `${ startTimeFull.split(':')[0] }:${ startTimeFull.split(':')[1] }`;
            const endTimeWithoutSeconds = `${ endTimeFull.split(':')[0] }:${ endTimeFull.split(':')[1] }`;

            setStartTime(startTimeWithoutSeconds);
            setEndTime(endTimeWithoutSeconds);
            setLocation(prototypeConsentAgenda.MEETING_LOCATION || '');
        }
    }, [prototypeConsentAgenda]);

    const createButtonDisabled = (
        startTime === '' ||
        endTime === '' ||
        date === '' ||
        invalidTimes
    );

    useEffect(() => {
        if (loading) {
            createConsentAgenda();
        }
    }, [loading]);

    const createConsentAgenda = async () => {
        const dmy = date.split('-');

        const startDate = new Date();
        startDate.setDate(parseInt(dmy[2]));
        startDate.setMonth(parseInt(dmy[1]) - 1);
        startDate.setFullYear(parseInt(dmy[0]));
        startDate.setHours(parseInt(startTime.split(':')[0]));
        startDate.setMinutes(parseInt(startTime.split(':')[1]));
        const startDateStr = startDate.toLocaleDateString() + ' ' + startDate.toLocaleTimeString();

        const endDate = new Date();
        endDate.setDate(parseInt(dmy[2]));
        endDate.setMonth(parseInt(dmy[1]) - 1);
        endDate.setFullYear(parseInt(dmy[0]));
        endDate.setHours(parseInt(endTime.split(':')[0]));
        endDate.setMinutes(parseInt(endTime.split(':')[1]));
        const endDateStr = endDate.toLocaleDateString() + ' ' + endDate.toLocaleTimeString();

        if (startDate.getTime() > endDate.getTime()) {
            setInvalidTimes(true);
            return;
        }

        await axios.post(
            `/api/committee/${ props.committeeId }/consent-agenda`,
            {
                startDateStr,
                endDateStr,
                location,
                prototypeConsentAgendaId: prototypeConsentAgenda?.CONSENT_AGENDA_ID
            }
        );
        setLoading(false);
        props.onCreateMeeting();
    };

    return <LucidocModal
        open
        onClose={ () => props.onClose() }
        title='Add new meeting'

        onSave={ () => setLoading(true) }
        isSaveDisabled={ createButtonDisabled }
        whyIsSaveDisabled='You must select a valid date and time for the new meeting'
        // saveButtonText='Create'

        onCancel={ () => props.onClose() }

        isSaveIndicatorVisible={ isSaveIndicatorVisible }
        setIsSaveIndicatorVisible={ setIsSaveIndicatorVisible }
        saveIndicatorMessage='Successfully created meeting'
    >
        <div
            style={{
                backgroundColor: '#fff',
                width: '80vw',
                height: '70vh',
                borderRadius: '.5rem',
                position: 'relative'
            }}
        >
            <LucidocForm
                noHeaderSpacing
                fields={[
                    {
                        name: 'Date',
                        component: <TextField
                            variant='outlined'
                            size='small'
                            type='date'
                            value={ date }
                            onChange={ event => setDate(event.currentTarget.value) }
                            style={{
                                width: '28rem'
                            }}
                        />
                    },
                    {
                        name: 'Time',
                        description: 'Select the start and end times of this meeting',
                        component: <div
                            style={{
                                display: 'grid',
                                gridTemplateColumns: '13rem 2rem 13rem',
                                alignItems: 'center'
                            }}
                        >
                            <TextField
                                variant='outlined'
                                size='small'
                                type='time'
                                value={ startTime }
                                onChange={ event => {
                                    setInvalidTimes(false);
                                    setStartTime(event.currentTarget.value);
                                } }
                                style={{
                                    width: '13rem'
                                }}
                            />
                            <div
                                style={{
                                    display: 'flex',
                                    width: '2rem',
                                    justifyContent: 'center'
                                }}
                            >
                                <ArrowRightAlt/>
                            </div>
                            <TextField
                                variant='outlined'
                                size='small'
                                type='time'
                                value={ endTime }
                                onChange={ event => {
                                    setInvalidTimes(false);
                                    setEndTime(event.currentTarget.value);
                                } }
                                style={{
                                    width: '13rem'
                                }}
                            />
                        </div>
                    },
                    {
                        name: 'Location',
                        component: <TextField
                            value={ location }
                            onChange={ event => setLocation(event.currentTarget.value) }
                            placeholder='Location'
                            variant='outlined'
                            size='small'
                            style={{
                                width: '28rem'
                            }}
                        />
                    },
                    {
                        name: 'Copy from another agenda',
                        description: 'Copy the time and agenda items from another agenda',
                        component: <SelectConsentAgenda
                            committeeId={ props.committeeId }
                            onChange={ setPrototypeConsentAgenda }
                            consentAgendaId={ prototypeConsentAgenda?.CONSENT_AGENDA_ID }
                            placeholder='Copy from agenda...'
                        />
                    }
                ]}
            />
        </div>
    </LucidocModal>;
    
};

export default CreateNewMeeting;
