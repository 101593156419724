import React, { useContext } from 'react';
import { DashboardCard, DashboardCardTitle, DashboardCardContent, DashboardCardToolbar, DashboardCardToolbarItem } from '../../../../components/Homepage/components/DashboardCard';
import useSWR from 'swr';
import { cn } from '@/lib/utils';
import { HybridLink } from '@/ui/ui/hybridlink';
import UserBundleContext from '@/context/UserBundleContext';
import hasRights from '@/_shared/utils/hasRights';
import { RefreshOutlineIcon } from '@/ui/icons/outline/RefreshOutline';
import { RightsCodeKey } from '@/entities/master/PERSON';
import { useOrgBaseURL } from '@/hooks/useOrgBaseURL';
import { Button } from '@/ui/ui/button';


interface PrioritiesCard2Props {

}

export const PrioritiesCard2: React.FC<PrioritiesCard2Props> = () => {

    const userBundle = useContext(UserBundleContext);
    const orgBaseURL = useOrgBaseURL();

    const { data: docManagerPrioritiesData, mutate: refetchDocManagerPriorities } = useSWR('/api/doc-manager/priorities');
    const { data: accreditationManagerToDoList, mutate: refetchAccreditationmanagerToDoList } = useSWR({
        url: '/ajax/accreditation_manager.pl',
        params: {
            action: 'get_item_counts'
        }
    });

    const { data: whatsnewCountData, mutate: refetchWhatsNewData } = useSWR('/api/homepage/card/whats-new/count');


    const { data: accManPermissions } = useSWR({
        url: '/ajax/accreditation_manager.pl',
        params: {
            action: 'has_any_privileges'
        }
    });


    let items = [
        {
            name: 'To Sign',
            href: '/docmgr2/docmanager.pl?repname=Sign',
            external: true,
            notifications: docManagerPrioritiesData?.toSign
        },
        {
            name: 'To Review',
            href: '/docmgr2/docmanager.pl?repname=Expiring',
            external: true,
            notifications: docManagerPrioritiesData?.toReview
        },
        {
            name: 'To Release',
            href: '/docmgr2/docmanager.pl?repname=Release',
            external: true,
            notifications: docManagerPrioritiesData?.toRelease
        },
        {
            name: 'My Acknowledgements',
            href: '/cgi/my_acknowledgements.pl',
            external: true,
            notifications: docManagerPrioritiesData?.myAcknowledgements
        },
        {
            name: 'My Education',
            href: '/cgi/education/overview.pl',
            external: true,
            notifications: docManagerPrioritiesData?.myEducation
        },
        {
            name: 'My Meetings',
            href: `${orgBaseURL}/committees?committeeTab=myMeetings`,
            external: false,
            notifications: docManagerPrioritiesData?.myMeetings
        },
        {
            name: 'Contracts To Route',
            href: '/docmgr2/docmanager.pl?repname=contractsManager',
            external: true,
            notifications: docManagerPrioritiesData?.contractsToRoute
        },
        {
            name: 'To Route',
            href: '/docmgr2/docmanager.pl?repname=toRoute',
            external: true,
            notifications: docManagerPrioritiesData?.toRoute
        },
        {
            name: 'To Waive',
            href: '/docmgr2/docmanager.pl?repname=WaiverRequest',
            external: true,
            notifications: docManagerPrioritiesData?.toWaive
        },
        {
            name: 'New Corporate Docs',
            href: '/docmgr2/docmanager.pl?repname=CorporateDocumentStatus',
            external: true,
            notifications: docManagerPrioritiesData?.newCorpDocs
        }
    ];

    const hasAccessToContractRequests = userBundle.organization?.ALLOW_DOC_REQUESTS && hasRights(userBundle, RightsCodeKey.Reader);

    if (hasRights(userBundle, RightsCodeKey.Reader)) {
        items.push({
            name: 'Feedback Requests To Fulfill',
            href: '/docmgr2/docmanager.pl?repname=Review',
            external: true,
            notifications: docManagerPrioritiesData?.requestsToFulfill
        });
    }

    if (accManPermissions?.hasAccManPrivileges === 1) {
        items.push({
            name: 'Accreditation Manager To-Do List',
            href: '/accreditationmanager/accreditation_manager.pl',
            external: true,
            notifications: accreditationManagerToDoList?.accManToDoList
        });
    }

    // might be null if you don't have permissions, Naman's account gets a null response.
    // But Kim's account does get a number, which can be 0.
    items = items.filter(item => item.notifications !== null);



    return (
        <DashboardCard disableMinWidth disableMaxHeight>
            <DashboardCardTitle>
                Priorities
            </DashboardCardTitle>
            <DashboardCardToolbar>
                <DashboardCardToolbarItem tooltip='Refresh priorities'>
                    <div className='cursor-pointer flex items-center justify-center' onClick={() => {
                        refetchDocManagerPriorities();
                        refetchAccreditationmanagerToDoList();
                        refetchWhatsNewData();
                    }}>
                        <RefreshOutlineIcon size={20} />
                    </div>
                </DashboardCardToolbarItem>
            </DashboardCardToolbar>
            <DashboardCardContent>
                <div className='flex flex-col w-full h-full'>
                    <div className='w-full space-y-0.5 p-1 flex-grow overflow-y-auto'>
                        {items.map((item, index) => {
                            if (typeof item.notifications === 'object' && item.notifications !== null) {
                                // In multi org feedback requests to fulfill can be like:
                                // {10431: 16, 10524: 12 } etc. So we have to add them up.
                                item.notifications = Object.values(item.notifications).reduce((acc: number, val: any) => acc + val, 0);
                            }
                            return (
                                <PrioritiesCardItem
                                    key={index}
                                    href={item.href ?? ''}
                                    external={item.external}
                                    notifications={item.notifications}
                                >
                                    {item.name}
                                </PrioritiesCardItem>
                            );
                        })}
                    </div>
                    <div className='flex flex-col'>
                        <div className='w-full h-[1px] border border-border mb-2'></div>
                        <div className='flex flex-col xs:flex-row w-full justify-between space-y-2 xs:space-y-0 xs:space-x-2'>
                            <HybridLink external={false} href={`${orgBaseURL}/whatsnew`} className='w-full'>
                                <Button variant={'secondary'} className='w-full'><p>What's New ({whatsnewCountData?.count ?? 0})</p></Button>
                            </HybridLink>
                            {hasAccessToContractRequests && (
                                <HybridLink className='w-full' external={true} href='/contracts/request'>
                                    <Button variant={'secondary'} className='w-full'><p>Contract Request</p></Button>
                                </HybridLink>
                            )}
                        </div>
                    </div>
                </div>
            </DashboardCardContent>
        </DashboardCard>
    );
};

interface PrioritiesCardItemProps {
    children: React.ReactNode;
    notifications: number | undefined;
    href: string;
    external?: boolean
}

export const PrioritiesCardItem: React.FC<PrioritiesCardItemProps> = ({ notifications, href, external, children }) => {

    let notificationsBubbleClassNames = 'bg-primary';
    if (notifications === undefined) {
        notificationsBubbleClassNames = 'animate-pulse bg-muted';
    } else if (notifications === 0) {
        notificationsBubbleClassNames = 'bg-muted';
    }

    const textColor = notifications != undefined && notifications > 0 ? 'text-white' : 'text-primary';

    return (
        <HybridLink href={href} external={external ? external : false}>
            <div className='flex flex-row items-center w-full cursor-pointer mb-0.5'>
                <div
                     className={cn(
                        `flex items-center justify-center rounded-lg w-[24px] h-[20px] mr-2 p-0.5 text-bubble ${ textColor }`,
                        notificationsBubbleClassNames
                    )}
                    style={{

                    }}
                >
                    {notifications && notifications > 99 ? '99+' : notifications}
                </div>
                <p className='text-md text-primary'>{children}</p>
            </div>
        </HybridLink>
    );
};