import { Snackbar } from '@material-ui/core';
import React from 'react';
import LucidocColors from '../../constants/LucidocColors';
import { makeStyles } from '@material-ui/core/styles';

const messageColors = {
    save:    LucidocColors.green,
    error:   LucidocColors.red,

    // not sure of these two yet; these are strange colors, so figure it out as needed
    // info:    LucidocColors.blue,
    // warning: LucidocColors.orange,
};

interface SaveIndicatorProps {
    open: boolean
    onClose: () => void
    message: string
    messageType?: keyof typeof messageColors // default 'save' which shows as green
}

const useStyles = makeStyles({
    snackBar: {
        '& .MuiSnackbarContent-root': {
            backgroundColor: (props: SaveIndicatorProps) => props.messageType
                ? messageColors[props.messageType]
                : messageColors['save']
        }
    }
});

export default function SaveIndicator(props: SaveIndicatorProps) {
    const classes = useStyles(props);

    // you'll need something like this in the parent:
    // const [ isSaveIndicatorVisible, setIsSaveIndicatorVisible ] = useState(false);

    return (
        <Snackbar
            // props
            open={props.open}
            onClose={() => props.onClose()}
            message={props.message}

            // not props
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            autoHideDuration={3000}
            classes={{
                root: classes.snackBar,
            }}
        />
    );
}
