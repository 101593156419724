import React from 'react';

interface HomeOutlineProps {
    size: number,
    className?: string
}

export const HomeOutlineIcon: React.FC<HomeOutlineProps> = ({ size, className }) => {
    return (
        <svg width={size.toString()} height={(size / 3 * 2).toString()} className={className} viewBox="0 0 30 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 8H22V17C22 18.1046 21.1046 19 20 19H10C8.89543 19 8 18.1046 8 17V8Z" stroke="hsl(var(--foreground))" strokeWidth="2" />
            <path d="M12 8H18V18C18 18.5523 17.5523 19 17 19H13C12.4477 19 12 18.5523 12 18V8Z" stroke="hsl(var(--foreground))" strokeWidth="2" />
            <path d="M14.4855 1.47489C14.8022 1.28488 15.1978 1.28488 15.5145 1.47489L23.2939 6.14251C24.1635 6.66432 23.7936 8 22.7794 8H7.22063C6.20641 8 5.83645 6.66432 6.70614 6.14251L14.4855 1.47489Z" stroke="hsl(var(--foreground))" strokeWidth="2" />
        </svg>

    );
};