import React from 'react';

interface UsersProps {
    size: number
}

export const UsersIcon: React.FC<UsersProps> = ({ size }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 4H20V20H4V4Z" fill="hsl(var(--white))" />
            <path d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z" fill="hsl(var(--primary))" />
            <path d="M12.5 13C12.5 11.8954 13.3954 11 14.5 11L17.5 11C18.6046 11.0001 19.5 11.8955 19.5 13V17.2457C19.5 17.6922 19.1983 18.0862 18.769 18.2089C16.9734 18.7219 15.0266 18.7219 13.231 18.2089C12.8017 18.0862 12.5 17.6922 12.5 17.2457V13Z" fill="hsl(var(--white))" />
            <path d="M4.5 15C4.5 13.8954 5.39543 13 6.5 13H9.5C10.6046 13 11.5 13.8954 11.5 15L11.5 17.2457C11.5 17.6922 11.1983 18.0862 10.769 18.2089C8.97343 18.7219 7.02657 18.7219 5.23102 18.2089C4.80172 18.0862 4.5 17.6922 4.5 17.2457V15Z" fill="hsl(var(--white))" />
            <path d="M5.40918 9.40918C5.40918 8.02847 6.52847 6.90918 7.90918 6.90918H8.00009C9.3808 6.90918 10.5001 8.02847 10.5001 9.40918V9.50009C10.5001 10.8808 9.3808 12.0001 8.00009 12.0001H7.90918C6.52847 12.0001 5.40918 10.8808 5.40918 9.50009V9.40918Z" fill="hsl(var(--white))" />
            <path d="M13.5 7.40918C13.5 6.02847 14.6193 4.90918 16 4.90918H16.0909C17.4716 4.90918 18.5909 6.02847 18.5909 7.40918V7.50009C18.5909 8.8808 17.4716 10.0001 16.0909 10.0001H16C14.6193 10.0001 13.5 8.8808 13.5 7.50009V7.40918Z" fill="hsl(var(--white))" />
        </svg>

    );
};