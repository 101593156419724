import React from 'react';

interface DocumentTypeShadowIconProps {
    size: number;
}

export const DocumentTypeShadowIcon: React.FC<DocumentTypeShadowIconProps> = ({ size }) => {

    return (
        <svg width={size.toString()} height={size.toString()} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1.14286C1 0.511675 1.52233 0 2.16667 0H9.85009C10.1595 0 10.4563 0.120408 10.675 0.334735L14.6583 4.23669C14.8771 4.45102 15 4.74171 15 5.04482V14.8571C15 15.4883 14.4777 16 13.8333 16H2.16667C1.52233 16 1 15.4883 1 14.8571V1.14286Z" fill="hsl(var(--muted-foreground))"/>
            <path d="M2.80386 8.49219C2.7712 8.54017 2.7712 8.60269 2.80386 8.65067L5.03997 11.9364C5.06702 11.9761 5.11255 12 5.16131 12H7.1441C7.26058 12 7.33005 11.8728 7.26544 11.7779L5.13719 8.65067C5.10453 8.60269 5.10453 8.54017 5.13719 8.49219L7.26544 5.36496C7.33005 5.27002 7.26058 5.14286 7.1441 5.14286H5.16131C5.11255 5.14286 5.06702 5.16673 5.03997 5.20647L2.80386 8.49219Z" fill="hsl(var(--muted))"/>
            <path d="M13.196 8.65067C13.2287 8.60269 13.2287 8.54017 13.196 8.49219L10.9599 5.20647C10.9328 5.16673 10.8873 5.14286 10.8385 5.14286H8.85575C8.73928 5.14286 8.6698 5.27002 8.73441 5.36496L10.8627 8.49219C10.8953 8.54017 10.8953 8.60269 10.8627 8.65067L8.73441 11.7779C8.6698 11.8728 8.73928 12 8.85575 12H10.8385C10.8873 12 10.9328 11.9761 10.9599 11.9364L13.196 8.65067Z" fill="hsl(var(--muted))"/>
        </svg>
    );
};