import React from 'react';

interface ProfileProps {
    size: number
}

export const ProfileIcon: React.FC<ProfileProps> = ({ size }) => {
    return (
        <svg width={size.toString()} height={size.toString()} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 4H20V20H4V4Z" fill="hsl(var(--white))" />
            <path d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z" fill="hsl(var(--primary))" />
            <path d="M7 16C7 14.3431 8.34315 13 10 13H14C15.6569 13 17 14.3431 17 16V17.3018C17 18.1988 16.382 18.9775 15.5085 19.1813C13.2005 19.7199 10.7995 19.7199 8.49148 19.1813C7.61797 18.9775 7 18.1988 7 17.3018V16Z" fill="hsl(var(--white))" />
            <path d="M8.5 8C8.5 6.067 10.067 4.5 12 4.5C13.933 4.5 15.5 6.067 15.5 8C15.5 9.933 13.933 11.5 12 11.5C10.067 11.5 8.5 9.933 8.5 8Z" fill="hsl(var(--white))" />
        </svg>

    );
};