import {
    Column,
    Entity,
    JoinColumn,
    ManyToOne,
    PrimaryColumn,
} from 'typeorm';
import { DOCUMENTREVISION,  } from './DOCUMENTREVISION';
import { SchemaEntityManager } from '../SchemaEntityManager';
import { EDIT_REQUEST } from './EDIT_REQUEST';

// Define the factory function
function createLockedForEditsEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('LOCKED_FOR_EDITS', { schema: schema })
    class LOCKED_FOR_EDITS {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', { nullable: false, name: 'DOCREVID' })
        DOCREVID!: number;

        @ManyToOne(
            () => manager.getDocumentRevisionEntity(schema), 
            (documentRevision) => documentRevision.LOCKED_FOR_EDITS
        )
        @JoinColumn({ name: 'DOCREVID' })
        DOCUMENTREVISION?: DOCUMENTREVISION | null;

        @Column('number', { nullable: true, name: 'USERID' })
        USERID!: number | null;

        @Column('number', { nullable: true, name: 'SIGNER_USERID' })
        SIGNER_USERID!: number | null;

        @Column('number', { nullable: true, name: 'COMMITTEE_ID' })
        COMMITTEE_ID!: number | null;

        @PrimaryColumn('number', { nullable: false, name: 'APPROVAL_SESSION_ID' })
        APPROVAL_SESSION_ID!: number;

        @PrimaryColumn('timestamp', { nullable: false, default: () => 'SYSDATE', name: 'LOCKED' })
        LOCKED!: Date;

        @Column('timestamp', { nullable: true, name: 'UNLOCKED' })
        UNLOCKED!: Date | null;

        @ManyToOne(
            () => manager.getEditRequestEntity(schema),
            (editRequest) => editRequest.LOCKED_FOR_EDITS_ENTRIES
        )
        @JoinColumn({
            name: 'APPROVAL_SESSION_ID',
            referencedColumnName: 'APPROVAL_SESSION_ID',
        })
        EDIT_REQUEST!: EDIT_REQUEST | null;
    }

    return LOCKED_FOR_EDITS;
}

// Export the factory function
export { createLockedForEditsEntity };

// Export the type representing an instance of the class generated by the factory function
export type LOCKED_FOR_EDITS = InstanceType<ReturnType<typeof createLockedForEditsEntity>>;
