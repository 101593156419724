import {
    Column,
    Entity,
    JoinColumn,
    ManyToMany,
    ManyToOne,
    OneToMany,
    OneToOne,
    PrimaryColumn,
} from 'typeorm';

import { ORGANIZATION,  } from './ORGANIZATION';
import { DOCUMENT,  } from '../org/DOCUMENT';
import { USERGROUP,  } from '../org/USERGROUP';
import { ACL,  } from '../org/ACL';
import { SIGNERASSIGNMENT,  } from '../org/SIGNERASSIGNMENT';
import {  type PERSON_ORGANIZATION } from './PERSON_ORGANIZATION';
import { DOCUMENTREVISION,  } from '../org/DOCUMENTREVISION';
import { DOC_REQUEST_HANDLER,  } from '../org/DOC_REQUEST_HANDLER';
import { INFORMATION_HIERARCHY,  } from '../org/INFORMATION_HIERARCHY';
import { TRACKING,  } from '../org/TRACKING';
import { COMMITTEE_ROUTING_LOG,  } from '../org/COMMITTEE_ROUTING_LOG';
import { DOC_REQUEST,  } from '../org/DOC_REQUEST';
import { COMMITTEE_ROUTER,  } from '../org/COMMITTEE_ROUTER';
import { PERSON_LINK } from './PERSON_LINK';
import { SEARCH_USER } from '../multiorg/SEARCH_USER';
import { ACK_SCHEDULE,  } from '../org/ACK_SCHEDULE';
import { BATCH_JOB,  } from '../org/BATCH_JOB';
import { REPRESENTATIVE_CONTACT } from '../multiorg/REPRESENTATIVE_CONTACT';
import { produceRightsObjectFromString } from '../_helperFunctions/rights/produceRightsObjectFromString';
import { CONTACTS,  } from '../org/CONTACTS';
import { MESSAGE_BOARD_ITEM,  } from '../org/MESSAGE_BOARD_ITEM';
import { CONSENT_AGENDA,  } from '../org/CONSENT_AGENDA';
import { REVIEWERASSIGNMENT,  } from '../org/REVIEWERASSIGNMENT';
import { SchemaEntityManager } from '../SchemaEntityManager';
import { COMMITTEE } from '../org/COMMITTEE';

export enum PersonStatuses {
    ACTIVE = 1,
    INACTIVE = 2,
    INVISIBLE = 3,
    DISABLED = 4 // not 100% sure of this one
}

interface RightsCode {
    idx: number;
    bit: number;
    display: string;
    letter: string; // used in a readable string like -RSA -DU  - (like on the Admin->Users page)
}

export enum RightsCodeKey {
    Reader = 'reader',
    Signer = 'signer',
    Author = 'author',
    CourseWriter = 'courseWriter',
    DocumentAdministrator = 'documentAdministrator',
    UserAdministrator = 'userAdministrator',
    OrganizationAdministrator = 'organizationAdministrator',
    SystemAdministrator = 'systemAdministrator',
}

type RightsCodes = {
    [key in RightsCodeKey]: RightsCode;
};

export const rightsCodes: RightsCodes = {
    reader:                    { idx:  9, bit:  2, display: 'Reader',                     letter: 'R' },
    signer:                    { idx:  9, bit:  4, display: 'Signer',                     letter: 'S' },
    author:                    { idx:  9, bit:  8, display: 'Author',                     letter: 'A' },
    courseWriter:              { idx:  3, bit: 64, display: 'Course Writer',              letter: 'E' },
    documentAdministrator:     { idx: 10, bit:  1, display: 'Document Administrator',     letter: 'D' },
    userAdministrator:         { idx: 10, bit:  2, display: 'User Administrator',         letter: 'U' },
    organizationAdministrator: { idx: 10, bit:  4, display: 'Organization Administrator', letter: 'O' },
    systemAdministrator:       { idx:  7, bit:  1, display: 'System Administrator',       letter: 'Y' }
};

export const displayableRightToRightsCode: {
    [key: string]: RightsCode;
} = Object.keys(rightsCodes).reduce((acc, key) => {
    // looks like this:
    // const displayableRightToRightsCode = {
    //     Reader:                       rightsCodes.reader,
    //     Signer:                       rightsCodes.signer,
    //     "Organization Administrator": rightsCodes.organizationAdministrator,
    //     "System Administrator":       rightsCodes.systemAdministrator
    //     ...etc
    // }
    const rightsCode = rightsCodes[key as RightsCodeKey];
    const display = rightsCode.display;
    acc[display] = rightsCode;
    return acc;
}, Object.create({}));

/**
 * To completely create a link, you must create one of these records, AND ALSO a record
 *  in the DOCUMENTREVISION_LINK table (stored in each org schema, not master).
 * 
 * @param schema - This schema is the organization schema. PERSON has a decorator specifying schema: MASTER, but still has relations to a parameter specified organization schema
 * 
 * @returns 
 */

function createPersonEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('PERSON', { schema: 'MASTER' })
    class PERSON {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'USERID'
        })
        USERID!: number;


        @Column('varchar2', {
            nullable: false,
            name: 'LOGINID'
        })
        LOGINID!: string;


        @Column('varchar2', {
            nullable: false,
            length: 30,
            name: 'PASSWORD'
        })
        PASSWORD!: string;


        @Column('varchar2', {
            nullable: true,
            length: 30,
            name: 'FIRSTNAME'
        })
        FIRSTNAME!: string | null;


        @Column('varchar2', {
            nullable: true,
            length: 30,
            name: 'LASTNAME'
        })
        LASTNAME!: string | null;


        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'ORGANIZATIONID'
        })
        ORGANIZATIONID!: number | null;

        @ManyToOne(
            () => manager.getOrganizationEntity(schema),
            (organization) => organization.PEOPLE
        )
        @JoinColumn({
            name: 'ORGANIZATIONID'
        })
        ORGANIZATION?: ORGANIZATION | null;

        @Column('number', {
            nullable: false,
            default: () => '1',
            precision: 10,
            scale: 0,
            name: 'STATUSID'
        })
        STATUSID!: number;


        @Column('timestamp', {
            nullable: true,
            name: 'STATUSDT'
        })
        STATUSDT!: Date | null;


        @Column('char', {
            nullable: true,
            length: 32,
            name: 'RIGHTS'
        })
        RIGHTS!: string | null;


        RIGHTSINENGLISH?: { [key in RightsCodeKey]: boolean };
        // Looks like this, after running getRightsInEnglish(): {
        //     reader: true/false,
        //     signer: true/false,
        //     author: true/false,
        //     courseWriter: true/false,
        //     documentAdministrator: true/false,
        //     userAdministrator: true/false,
        //     organizationAdministrator: true/false,
        //     systemAdministrator: true/false
        // };

        getRightsInEnglish() {
            this.RIGHTSINENGLISH = produceRightsObjectFromString(this.RIGHTS);
        }

        // Here's how you get the stupid rights for users from that ridiculous string of punctuation marks:
        // To get a SINGLE right, you need to:
        // 1) Get the character at a given index number from that rights string
        // 2) Derive the ASCII value of that character
        // 3) Use the bitwise '&' operator on that ASCII value and another given value, like this:
        //    ASCIIValue & secondGivenValue
        // You should be given two values to do this, which in the Perl looked like this:
        // [indexPositionInRightsStringWithWhichToDeriveASCIIValue, valueToBitwiseCompareWithThatASCIIValue]
        // This means you would get an array like this:
        // [9,2]
        // And you would use the value at index 0 of that set (in this case 9), then use that as an index position in the
        // 'rights' field for a given user, so you'd go to index position 9 in that giant rights string; then
        // get the ASCII value of that character, then use the '&' operator on that ASCII value and the 2 from the
        // given set of [9,2].
        // The result will either be a zero, or a number greater than zero.
        // Zero means you don't have the right. Anything higher means you do.
        // The array values appear to be consistent, and are:
        //
        // Reader: [9,2]
        // Signer: [9,4]
        // Author: [9,8]
        // Course Writer: [3,64]
        // Document Administrator: [10,1]
        // User Administrator: [10,2]
        // Organization Administrator: [10,4]
        //
        // For example, here is some code you can run. Copy and paste a rights string into the "str" variable and run the code below.
        // Remember to correctly escape any problematic quotation marks inside the string!

        // let str = "#'!3     /                   .  "
        //
        // let rightsArrays = {
        //     reader: [9,2],
        //     signer: [9,4],
        //     author: [9,8],
        //     courseWriter: [3,64],
        //     documentAdministrator: [10,1],
        //     userAdministrator: [10,2],
        //     organizationAdministrator: [10,4]
        // }
        //
        // console.log('Reader:', (str.charCodeAt(rightsArrays.reader[0]) & rightsArrays.reader[1]) > 0 ? true : false)
        // console.log('Signer:', (str.charCodeAt(rightsArrays.signer[0]) & rightsArrays.signer[1]) > 0 ? true : false)
        // console.log('Author:', (str.charCodeAt(rightsArrays.author[0]) & rightsArrays.author[1]) > 0 ? true : false)
        // console.log('Course Writer:', (str.charCodeAt(rightsArrays.courseWriter[0]) & rightsArrays.courseWriter[1]) > 0 ? true : false)
        // console.log('Document Administrator:', (str.charCodeAt(rightsArrays.documentAdministrator[0]) & rightsArrays.documentAdministrator[1]) > 0 ? true : false)
        // console.log('User Administrator:', (str.charCodeAt(rightsArrays.userAdministrator[0]) & rightsArrays.userAdministrator[1]) > 0 ? true : false)
        // console.log('Organization Administrator:', (str.charCodeAt(rightsArrays.organizationAdministrator[0]) & rightsArrays.organizationAdministrator[1]) > 0 ? true : false)

        // Running the above code should produce this result:
        // Reader: true
        // Signer: true
        // Author: true
        // Course Writer: false
        // Document Administrator: false
        // User Administrator: false
        // Organization Administrator: false

        @Column('varchar2', {
            nullable: true,
            name: 'LOCATION'
        })
        LOCATION!: string | null;


        @Column('number', {
            nullable: true,
            precision: 3,
            scale: 0,
            name: 'ACCESSLEVEL'
        })
        ACCESSLEVEL!: number | null;


        @Column('char', {
            nullable: true,
            length: 5,
            name: 'MIDDLEINITIALS'
        })
        MIDDLEINITIALS!: string | null;


        @Column('char', {
            nullable: true,
            length: 5,
            name: 'PREFIX'
        })
        PREFIX!: string | null;


        @Column('varchar2', {
            nullable: true,
            length: 60,
            name: 'SUFFIX'
        })
        SUFFIX!: string | null;


        @Column('varchar2', {
            nullable: true,
            length: 128,
            name: 'TITLE'
        })
        TITLE!: string | null;


        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'LOCATIONID'
        })
        LOCATIONID!: number | null;


        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'ORGCHARTID'
        })
        ORGCHARTID!: number | null;


        @Column('timestamp', {
            nullable: true,
            name: 'PASSWORD_TIMESTAMP'
        })
        PASSWORD_TIMESTAMP!: Date | null;


        @Column('number', {
            nullable: false,
            default: () => '0',
            precision: 2,
            scale: 0,
            name: 'PASSWORD_STATUS'
        })
        PASSWORD_STATUS!: number;


        @Column('varchar2', {
            nullable: true,
            length: 30,
            name: 'PASSWORD_HISTORY_1'
        })
        PASSWORD_HISTORY_1!: string | null;


        @Column('varchar2', {
            nullable: true,
            length: 30,
            name: 'PASSWORD_HISTORY_2'
        })
        PASSWORD_HISTORY_2!: string | null;


        @Column('varchar2', {
            nullable: true,
            length: 30,
            name: 'PASSWORD_HISTORY_3'
        })
        PASSWORD_HISTORY_3!: string | null;


        @Column('varchar2', {
            nullable: true,
            length: 30,
            name: 'PASSWORD_HISTORY_4'
        })
        PASSWORD_HISTORY_4!: string | null;


        @Column('varchar2', {
            nullable: true,
            length: 30,
            name: 'PASSWORD_HISTORY_5'
        })
        PASSWORD_HISTORY_5!: string | null;


        @Column('varchar2', {
            nullable: true,
            length: 30,
            name: 'PASSWORD_HISTORY_6'
        })
        PASSWORD_HISTORY_6!: string | null;


        @Column('varchar2', {
            nullable: true,
            length: 30,
            name: 'PASSWORD_HISTORY_7'
        })
        PASSWORD_HISTORY_7!: string | null;


        @Column('varchar2', {
            nullable: true,
            length: 30,
            name: 'PASSWORD_HISTORY_8'
        })
        PASSWORD_HISTORY_8!: string | null;


        @Column('varchar2', {
            nullable: true,
            length: 30,
            name: 'PASSWORD_HISTORY_9'
        })
        PASSWORD_HISTORY_9!: string | null;


        @Column('varchar2', {
            nullable: true,
            length: 30,
            name: 'PASSWORD_HISTORY_10'
        })
        PASSWORD_HISTORY_10!: string | null;


        @Column('varchar2', {
            nullable: true,
            length: 30,
            name: 'PASSWORD_HISTORY_11'
        })
        PASSWORD_HISTORY_11!: string | null;


        @Column('varchar2', {
            nullable: true,
            length: 30,
            name: 'PASSWORD_HISTORY_12'
        })
        PASSWORD_HISTORY_12!: string | null;


        @Column('varchar2', {
            nullable: true,
            length: 30,
            name: 'PASSWORD_HISTORY_13'
        })
        PASSWORD_HISTORY_13!: string | null;


        @Column('varchar2', {
            nullable: true,
            length: 30,
            name: 'PASSWORD_HISTORY_14'
        })
        PASSWORD_HISTORY_14!: string | null;


        @Column('varchar2', {
            nullable: true,
            length: 30,
            name: 'PASSWORD_HISTORY_15'
        })
        PASSWORD_HISTORY_15!: string | null;


        @Column('number', {
            nullable: false,
            default: () => '0',
            precision: 3,
            scale: 0,
            name: 'LOGINFAILCOUNT'
        })
        LOGINFAILCOUNT!: number;


        @Column('timestamp', {
            nullable: true,
            name: 'LASTLOGIN'
        })
        LASTLOGIN!: Date | null;


        @Column('raw', {
            nullable: true,
            length: 32,
            name: 'GUID'
        })
        GUID!: Buffer | null;


        @Column('char', {
            nullable: false,
            default: () => '\'0\'',
            name: 'ANONYMOUS'
        })
        ANONYMOUS!: string;


        @Column('char', {
            nullable: true,
            length: 86,
            name: 'SHA512'
        })
        SHA512!: string | null;


        @Column('varchar2', {
            nullable: true,
            length: 500,
            name: 'DEPARTMENT_NAME'
        })
        DEPARTMENT_NAME!: string | null;


        @Column('timestamp', {
            nullable: true,
            default: () => 'SYSDATE',
            name: 'CREATED_DATE'
        })
        CREATED_DATE!: Date | null;


        @Column('timestamp', {
            nullable: true,
            name: 'LAST_LOGIN_ATTEMPT'
        })
        LAST_LOGIN_ATTEMPT!: Date | null;


        @Column('varchar2', {
            nullable: false,
            length: 30,
            default: () => '\'font\'',
            name: 'SIGNATURE_PREFERENCE'
        })
        SIGNATURE_PREFERENCE!: string;

        @OneToMany(
            () => manager.getDocumentEntity(schema),
            (DOCUMENT) => DOCUMENT.DOCOWNER
        )
        DOCUMENTS!: DOCUMENT[] | null;

        @OneToMany(
            () => manager.getDocumentRevisionEntity(schema),
            (DOCUMENTREVISION) => DOCUMENTREVISION.DOCREV_OWNER
        )
        DOCUMENTREVISIONS!: DOCUMENTREVISION[] | null;

        @ManyToMany(
            () => manager.getCommitteeEntity(schema),
            (COMMITTEE) => COMMITTEE.PEOPLE
        )
        COMMITTEES_MEMBER!: COMMITTEE[] | null;

        @ManyToMany(
            () => manager.getCommitteeEntity(schema),
            (COMMITTEE) => COMMITTEE.COLLABORATORS
        )
        COMMITTEES_COLLABORATE_ON!: COMMITTEE[] | null;

        @ManyToMany(
            () => manager.getUserGroupEntity(schema),
            (USERGROUP) => USERGROUP.PEOPLE
        )
        USERGROUPS!: USERGROUP[] | null;

        @OneToMany(
            () => manager.getPersonOrganizationEntity(schema),
            (personOrganization) => personOrganization.PERSON
        )
        PERSON_ORGANIZATIONS!: PERSON_ORGANIZATION[] | null;

        @OneToMany(
            () => manager.getAclEntity(schema),
            (ACL) => ACL.OWNERORWHATEVER
        )
        ACL!: ACL[] | null;

        @OneToMany(
            () => manager.getInformationHierarchyEntity(schema),
            (INFORMATION_HIERARCHY) => INFORMATION_HIERARCHY.USER
        )
        INFORMATION_HIERARCHIES!: INFORMATION_HIERARCHY[] | null;

        @OneToMany(
            () => manager.getSignerAssignmentEntity(schema),
            (SIGNERASSIGNMENT) => SIGNERASSIGNMENT.SIGNER
        )
        SIGNERASSIGNMENTS!: SIGNERASSIGNMENT[] | null;

        @OneToMany(
            () => manager.getCommitteeEntity(schema),
            (COMMITTEE) => COMMITTEE.CHAIRPERSON
        )
        COMMITTEE_CHAIRS!: COMMITTEE[] | null;

        @OneToMany(
            () => manager.getCommitteeEntity(schema),
            (COMMITTEE) => COMMITTEE.SECRETARY
        )
        COMMITTEE_SECRETARIES!: COMMITTEE[] | null;

        @OneToOne(
            () => manager.getDocRequestHandlerEntity(schema),
            (DOC_REQUEST_HANDLER) => DOC_REQUEST_HANDLER.PERSON
        )
        DOC_REQUEST_HANDLER!: DOC_REQUEST_HANDLER | null;

        @OneToMany(
            () => manager.getDocRequestEntity(schema),
            (DOC_REQUEST) => DOC_REQUEST.PERSON
        )
        DOC_REQUESTS!: DOC_REQUEST[] | null;

        @OneToMany(
            () => manager.getTrackingEntity(schema),
            (TRACKING) => TRACKING.USER
        )
        TRACKINGS!: TRACKING[] | null;

        @OneToMany(
            () => manager.getTrackingEntity(schema),
            (TRACKING) => TRACKING.IMPERSONATOR
        )
        TRACKINGS_AS_IMPERSONATOR!: TRACKING[] | null;

        @OneToMany(
            () => manager.getCommitteeRoutingLogEntity(schema),
            (COMMITTEE_ROUTING_LOG) => COMMITTEE_ROUTING_LOG.ROUTER
        )
        COMMITTEE_ROUTING_LOGS!: COMMITTEE_ROUTING_LOG[] | null;

        @OneToMany(
            () => manager.getCommitteeRoutingLogEntity(schema),
            (COMMITTEE_ROUTING_LOG) => COMMITTEE_ROUTING_LOG.IMPERSONATOR
        )
        COMMITTEE_ROUTING_LOGS_AS_IMPERSONATOR!: COMMITTEE_ROUTING_LOG[] | null;

        // "COMMITTEE_ROUTER" has been rebranded as just "Approval Router" in the UI, but left the name to be backward compatible
        @OneToOne(
            () => manager.getCommitteeRouterEntity(schema),
            (COMMITTEE_ROUTER) => COMMITTEE_ROUTER.PERSON
        )
        ROUTER!: COMMITTEE_ROUTER | null;

        // this allows a multi-org member to do a multi-org search. Notice how it's not a real relation,
        // because it has to go through one of the MultiOrg tables, which isn't easily connected to
        // via the regular TypeORM relations, so you should just do a manual query (see navigation.ts)
        SEARCH_USER!: SEARCH_USER | null;

        // Specifically the MULTIORG representative contact:
        REPRESENTATIVE_CONTACT!: REPRESENTATIVE_CONTACT | null;

        @OneToMany(
            () => manager.getPersonLinkEntity(schema),
            (PERSON_LINK) => PERSON_LINK.USER
        )
        PERSON_LINKS!: PERSON_LINK[] | null;

        @OneToMany(
            () => manager.getAckScheduleEntity(schema),
            (ACK_SCHEDULE) => ACK_SCHEDULE.OWNER
        )
        ACK_SCHEDULES!: ACK_SCHEDULE[] | null;

        @OneToMany(
            () => manager.getBatchJobEntity(schema),
            (BATCH_JOB) => BATCH_JOB.JOB_OWNER
        )
        BATCH_JOBS!: BATCH_JOB[] | null;

        @OneToMany(
            () => manager.getContactsEntity(schema),
            (CONTACTS) => CONTACTS.USER
        )
        CONTACTS!: CONTACTS[] | null;

        @OneToMany(
            () => manager.getMessageBoardItemEntity(schema),
            (MESSAGE_BOARD_ITEM) => MESSAGE_BOARD_ITEM.USER
        )
        MESSAGE_BOARD_ITEMS!: MESSAGE_BOARD_ITEM[] | null;

        @ManyToMany(
            () => manager.getConsentAgendaEntity(schema),
            (CONSENT_AGENDA) => CONSENT_AGENDA.GUESTS
        )
        GUEST_AGENDAS!: CONSENT_AGENDA[] | null;

        @OneToMany(
            () => manager.getReviewerAssignmentEntity(schema),
            (REVIEWERASSIGNMENT) => REVIEWERASSIGNMENT.USER
        )
        REVIEWERASSIGNMENTS!: REVIEWERASSIGNMENT[] | null;
    }

    return PERSON;
}


export const isPerson = (potentialPerson: PERSON | USERGROUP): potentialPerson is PERSON => {
    return (potentialPerson as PERSON).USERID !== undefined;
};

export { createPersonEntity };
export type PERSON = InstanceType<ReturnType<typeof createPersonEntity>>;