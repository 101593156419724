import React, { useEffect, useState } from 'react';
import { INFORMATION_HIERARCHY } from '../../../../../../entities/org/INFORMATION_HIERARCHY';
import { DOCUMENTREVISION } from '../../../../../../entities/org/DOCUMENTREVISION';
import axios from 'axios';
import { LucidocModal } from '../../../../../_shared/LucidocModal/LucidocModal';
import DataTable from '../../../../../_shared/DataTable/DataTable';
import { MUIDataTableColumn } from 'mui-datatables';
import { Button, makeStyles } from '@material-ui/core';
import { transformDate, transformReviewCycle } from '../../../../../../_shared/utils/documentFunctions';
import { LoadingStatuses } from '../../../../../../utils/LoadingStatuses';
import { LoadingIndicator } from '../../../../../_shared/LoadingIndicator';
import { constructFullName } from '@/components/UserPicker/utils/constructFullName';

const pageStyles = {
    container: {
        height: 600,
    },
    editButton: {
        marginRight: '10px',
    },
};

const useStyles = makeStyles({
    ...pageStyles
});

type DepartmentDocumentsModalProps = {
    orgDepartmentAlias: string;
    department: INFORMATION_HIERARCHY | null;
    isOpen: boolean;
    onClose: () => void;
    onEdit: (incomingDepartmentDocs: DOCUMENTREVISION[]) => void;
};

export function DepartmentDocumentsModal(props: DepartmentDocumentsModalProps) {
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatuses>(LoadingStatuses.LOADING);
    const [departmentDocs, setDepartmentDocs] = useState<DOCUMENTREVISION[]>([]);
    const classes = useStyles();

    useEffect(() => {
        if (props.department === null) {
            return;
        }

        setLoadingStatus(LoadingStatuses.LOADING);
        axios.get('/api/doc-manager/info-hierarchy/edit-info-hierarchy/documents', {
            params: {
                ihType: 'department',
                ihID: props.department?.INFORMATION_HIERARCHY_ID,
                officialOnly: true,
                ignoreDocumentVisibility: true,
            }
        })
            .then((response) => {
                setDepartmentDocs(response.data.docrevs || []);
                setLoadingStatus(LoadingStatuses.READY);
            })
            .catch(err => console.log(err));
    }, [props.department]);

    const department = props.department;
    if (department === null) {
        return null;
    }

    const formattedTableData = departmentDocs.map(docRev => {
        const owner = docRev.DOCUMENT?.DOCOWNER ? constructFullName(docRev.DOCUMENT?.DOCOWNER, 'lastFirstMiddle') : '';

        return {
            'Document ID': docRev.DOCID,
            'Revision Number': docRev.REVNO,
            'Title': docRev.TITLE,
            'Doc Type': docRev.DOCTYPE?.DESCRIPTION,
            'Owner': owner,
            'Next Review Date': transformDate(docRev.NEXTREVDT),
            'Review Cycle': transformReviewCycle(docRev.REVIEW_CYCLE),
        };
    });

    const noFilterColumns = [
        'Title',
    ];

    const dataTableComponent = (
        <DataTable
            muiDataTableProps={{
                data: formattedTableData,
                title: `${props.orgDepartmentAlias} Documents`,
                columns: formattedTableData[0] && Object.keys(formattedTableData[0]).map(key => {
                    const returnObj: MUIDataTableColumn = {
                        name: key,
                    };

                    if (noFilterColumns.includes(key)) {
                        returnObj.options = {
                            filter: false,
                        };
                    }

                    return returnObj;
                }),
                options: {
                    selectableRows: 'multiple',
                    customToolbarSelect: (selectedRows) => (
                        <div>
                            <Button
                                className={classes.editButton}
                                variant='contained'
                                onClick={() => {
                                    const selectedDocRevs = selectedRows.data.map(row => departmentDocs[row.dataIndex]);
                                    props.onEdit(selectedDocRevs);
                                }}
                            >
                                Edit Selected
                            </Button>
                        </div>
                    ),
                },
            }}
        />
    );

    return (
        <LucidocModal
            open={props.isOpen}
            onClose={() => {
                setDepartmentDocs([]);
                props.onClose();
            }}
            title={`${props.orgDepartmentAlias}: ${department.TITLE}`}
        >
            <div className={classes.container}>
                {loadingStatus === LoadingStatuses.READY && dataTableComponent}
                {loadingStatus !== LoadingStatuses.READY && <LoadingIndicator />}
            </div>
        </LucidocModal>
    );
}