import React, { useEffect, useState } from 'react';
import produceFlattenedOptionsFromNestedInformationHierarchy, { flattenedInformationHierarchySelectMenuOption } from '../../../../utils/produceFlattenedOptionsFromNestedInformationHierarchy';
import { INFORMATION_HIERARCHY, InformationHierarchyStatuses } from '../../../../entities/org/INFORMATION_HIERARCHY';
import axios from 'axios';
import ReactSelect from 'react-select';

export type SharedSelectDepartmentProps = {
    // types of manuals to get in the query:
    statuses?: InformationHierarchyStatuses[],
    userID?: number,

    // Select menu controls:
    placeholder?: string;
    disabled?: boolean;

    unselectableDepartments?: INFORMATION_HIERARCHY[]
}

type SelectDepartmentProps = {
    // always used:
    onChange: (depID?: number) => void

    // if used alone (not part of SelectDepartments.tsx):
    departmentID?: number,

    // if used by SelectDepartments.tsx:
    availableDepartmentsFromParent?: INFORMATION_HIERARCHY[] // used by SelectManualMulti so we only do one DB query and the parent hands the info down to the child

    isClearable?: boolean

    // useful if you want the first option to say "None" (sometimes more helpful than the isClearable X button)
    dummyValue?: {
        label: string,
        value: number // probably a zero and you'll handle it on the back end with if (!)
    }
}

export function SelectDepartment(props: SelectDepartmentProps & SharedSelectDepartmentProps) {
    const [ flattenedOptions, setFlattenedOptions ] = useState<flattenedInformationHierarchySelectMenuOption[]>([]);

    useEffect(() => {
        getDepartments(
            props.statuses,
            props.userID
        ).then(departments => setFlattenedOptions(
            (props.dummyValue ? [props.dummyValue] : [])
                .concat(
                    produceFlattenedOptionsFromNestedInformationHierarchy(
                        departments
                    )
                )
            )
        );
    }, []);

    const unselectableDepartmentsHash = props.unselectableDepartments?.reduce((acc: {[key: string]: boolean}, department) => {
        acc[department.INFORMATION_HIERARCHY_ID] = true;
        return acc;
    }, {});

    return (
        <ReactSelect
            onChange={
                (selected) => {
                    props.onChange(!selected ? undefined : (selected as any).value as (number | undefined));
                }
            }
            value={ flattenedOptions.find(option => option.value === props.departmentID) }
            options={ flattenedOptions }
            placeholder={ props.placeholder }
            isClearable={ typeof props.isClearable !== 'undefined' ? props.isClearable : true}
            isDisabled={ props.disabled }
            isOptionDisabled={option => !!unselectableDepartmentsHash?.[option.value]}
        />
    );
}

export async function getDepartments(
    statuses?: InformationHierarchyStatuses[],
    userID?: number,
)
    : Promise<INFORMATION_HIERARCHY[]>
{
    const res = await axios.get('/api/_shared/forms/select/select-departments/', {
        params: {
            statuses,
            userID
        }
    });

    return res.data.departments || [];
}