import {
    Column,
    Entity,
    PrimaryColumn,
} from 'typeorm';
import { SchemaEntityManager } from '../SchemaEntityManager';

export enum DocumentRevisionLogAuthorTypes {
    user = 'user',
    system = 'system',
}

export enum DocumentRevisionLogAttributeTypes {
    doctype_id = 'doctype_id',
    templateid = 'templateid',
    docowner_id = 'docowner_id',
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function createDocumentRevisionLogEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('DOCUMENTREVISION_LOG', { schema: schema })
    class DOCUMENTREVISION_LOG {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            name: 'DOCREVID'
        })
        DOCREVID!: number;

        @Column('number', {
            nullable: true,
            name: 'USERID'
        })
        USERID!: number | null;

        @Column('number', {
            nullable: true,
            name: 'VIA_USERID'
        })
        VIA_USERID!: number | null;

        @Column('date', {
            nullable: false,
            name: 'DATE_OF_CHANGE'
        })
        DATE_OF_CHANGE!: Date;

        @Column('varchar2', {
            nullable: false,
            length: 30,
            name: 'ATTRIBUTE_NAME'
        })
        ATTRIBUTE_NAME!: string;

        @Column('varchar2', {
            nullable: true,
            length: 4000,
            name: 'ATTRIBUTE_VALUE'
        })
        ATTRIBUTE_VALUE!: string | null;

        @Column('varchar2', {
            nullable: true,
            length: 100,
            name: 'AUTHOR_TYPE'
        })
        AUTHOR_TYPE!: string | null;
    }

    return DOCUMENTREVISION_LOG;
}

export { createDocumentRevisionLogEntity };
export type DOCUMENTREVISION_LOG = InstanceType<ReturnType<typeof createDocumentRevisionLogEntity>>;