import React, { useContext, useEffect, useState } from 'react';
import { COMMITTEE } from '../../../../../entities/org/COMMITTEE';
import UserBundleContext from '../../../../../context/UserBundleContext';
import axios from 'axios';
import { LoadingIndicator } from '../../../../_shared/LoadingIndicator';
import { LoadingStatuses } from '../../../../../utils/LoadingStatuses';
import { CONSENT_AGENDA } from '../../../../../entities/org/CONSENT_AGENDA';
import LucidocForm from '../../../../_shared/Forms/LucidocForm/LucidocForm';
import SaveBar from '../../../../_shared/SaveBar/SaveBar';
import { onStorageUpdate } from '../CommitteeEditor';
import DataTable from '../../../../_shared/DataTable/DataTable';
import ReactSelect from 'react-select';
import { ValueType } from 'react-select/src/types';
import { buildDocURL, buildManualURL } from '../../../../../_shared/utils/docManagerFunctions';
import SelectConsentAgenda from '../../../../_shared/Forms/Select/SelectConsentAgenda';
import { CommiteeAgendaMeetings } from './Agendas/CommitteeAgendaMeetings';
import { MUIDataTableColumn } from 'mui-datatables';
import { redirectToHomepage } from '../../../../../utils/savebarUtilities';

interface CommitteeAgendasProps {
    committeeId: number;
}

const CommitteeAgendas: React.FC<CommitteeAgendasProps> = (props: CommitteeAgendasProps) => {
    const context = useContext(UserBundleContext);
    const userBundleContext = useContext(UserBundleContext);

    const [loadingStatus, setLoadingStatus] = useState(LoadingStatuses.MOUNTING);

    const [committee, setCommittee] = useState<COMMITTEE>();

    const [selectedActiveAgendaId, setSelectedActiveAgendaId] = useState<number>();
    const [selectedAgendaHeaderId, setSelectedAgendaHeaderId] = useState<number>();

    const [activeConsentAgendas, setActiveConsentAgendas] = useState<CONSENT_AGENDA[]>([]);

    const [unscheduledCommitteeApprovalRequests, setUnscheduledCommitteeApprovalRequests] = useState<{
        COMMITTEE_APPROVAL_SESSION_ID: number;
        COMMITTEE_ID: number;
        REQUEST_DATE: string;
        PHASE: number;
        cas_TYPE: number;
        docRev_DOCREVID: number;
        docRev_DOCID: number;
        docRev_REVNO: string;
        docRev_TITLE: string;
        docType_DESCRIPTION: string;
        informationHierarchy_TITLE: string;
        mih_INFORMATION_HIERARCHY_ID: number;
        mih_TITLE: string;
    }[]>([]);

    const activeAgendaHeaderOptions = !selectedActiveAgendaId ? [] : activeConsentAgendas
        .find(aca => aca.CONSENT_AGENDA_ID === selectedActiveAgendaId)?.CONSENT_AGENDA_HEADINGS?.sort((a, b) => a.LIST_INDEX - b.LIST_INDEX)
        .map((cah, index) => ({
            label: `${index + 1}. ${cah.LABEL}`,
            value: cah.HEADING_ID
        })) || [];

    const formattedUnscheduledItemsTableDate = unscheduledCommitteeApprovalRequests
        .map(car => ({
            Title: car.docRev_TITLE ? car.docRev_TITLE : car.mih_TITLE,
            DocId: car.docRev_DOCID,
            ManualId: car.mih_INFORMATION_HIERARCHY_ID,
            Revision: car.docRev_REVNO,
            DocType: car.docType_DESCRIPTION,
            Type: car.cas_TYPE,
            Department: car.informationHierarchy_TITLE
        })).sort((a, b) => a.Title.localeCompare(b.Title));

    const loadInitialData = async () => {
        try {
            const res = await axios.get(`/api/committee/${props.committeeId}`);

            setCommittee(res.data.committee);

            setSelectedActiveAgendaId(res.data.committee.ACTIVE_CONSENT_AGENDA_ID);
            setSelectedAgendaHeaderId(res.data.committee.ACTIVE_CA_HEADING_ID);

            const resActiveConsentAgendas = await axios.get(`/api/committee/${props.committeeId}/consent-agenda/active`);
            setActiveConsentAgendas(resActiveConsentAgendas.data.consentAgendas);

            const resUnscheduledCommitteeApprovalRequests = await axios.get(`/api/committee/${props.committeeId}/unscheduled-items`);
            setUnscheduledCommitteeApprovalRequests(resUnscheduledCommitteeApprovalRequests.data.unscheduledCommitteeApprovalRequests);

            setLoadingStatus(LoadingStatuses.READY);
        } catch (e) {
            redirectToHomepage();
        }
    };

    useEffect(() => {
        if (
            loadingStatus === LoadingStatuses.LOADING ||
            loadingStatus === LoadingStatuses.MOUNTING
        ) {
            loadInitialData().then();
        }
    }, [loadingStatus]);

    const saveCommitteeDefaultActiveAgenda = async () => {
        await axios.put(
            `/api/committee/${props.committeeId}/active-consent-agenda`,
            {
                activeConsentAgendaId: selectedActiveAgendaId,
                activeConsentAgendaHeadingId: selectedAgendaHeaderId
            }
        );
        setLoadingStatus(LoadingStatuses.LOADING);
    };

    const isChairPersonOrSecretary = (
        context.user?.USERID === committee?.SECRETARY_USERID ||
        context.user?.USERID === committee?.CHAIRPERSON_USERID
    );
    const isCollaborator = (committee?.COLLABORATORS ?? []).some(person => person.USERID === context.user?.USERID);

    const displaySaveButton = committee ? (
        committee.ACTIVE_CONSENT_AGENDA_ID !== selectedActiveAgendaId ||
        committee.ACTIVE_CA_HEADING_ID !== selectedAgendaHeaderId
    ) : false;

    useEffect(() => {
        window.addEventListener('storage', onStorageUpdate(setLoadingStatus));
        return () => {
            window.removeEventListener('storage', onStorageUpdate(setLoadingStatus));
        };
    }, []);



    return <div>
        {
            loadingStatus !== LoadingStatuses.READY &&
            <LoadingIndicator />
        }
        <div
            style={{
                maxHeight: 'calc(84vh - 6.125rem)',
                overflowY: 'auto'
            }}
        >
            {
                loadingStatus === LoadingStatuses.READY &&
                <LucidocForm
                    name='Consent Agendas'
                    title='View current agendas and scheduled unscheduled items'
                    fields={[
                        {
                            component: <CommiteeAgendaMeetings
                                activeConsentAgendas={activeConsentAgendas}
                                committee={committee}
                                isChairPersonOrSecretary={isChairPersonOrSecretary}
                                isCollaborator={isCollaborator}
                                setLoadingStatus={setLoadingStatus}

                            />
                        },
                        {
                            name: 'Select Default Active Agenda and Item',
                            component: <div
                                style={{
                                    zIndex: 101
                                }}
                            >
                                <SelectConsentAgenda
                                    disabled={!(isChairPersonOrSecretary || isCollaborator)}
                                    onChange={
                                        agenda => {
                                            setSelectedActiveAgendaId(agenda?.CONSENT_AGENDA_ID);
                                            if (
                                                agenda &&
                                                agenda.CONSENT_AGENDA_HEADINGS &&
                                                agenda.CONSENT_AGENDA_HEADINGS.length !== 0 &&
                                                (
                                                    selectedAgendaHeaderId === undefined ||
                                                    !agenda?.CONSENT_AGENDA_HEADINGS?.map(heading => heading.HEADING_ID).includes(selectedAgendaHeaderId)
                                                )
                                            ) {
                                                setSelectedAgendaHeaderId(agenda.CONSENT_AGENDA_HEADINGS[0].HEADING_ID);
                                            } else {
                                                setSelectedAgendaHeaderId(undefined);
                                            }
                                        }
                                    }
                                    consentAgendaId={selectedActiveAgendaId}
                                    committeeId={props.committeeId}
                                    placeholder='Select active consent agenda...'
                                    active
                                />
                                <br />
                                {
                                    selectedAgendaHeaderId &&
                                    <ReactSelect
                                        isDisabled={!(isChairPersonOrSecretary || isCollaborator)}
                                        placeholder='Select agenda item to add unscheduled items to...'
                                        value={activeAgendaHeaderOptions.find(o => o.value === selectedAgendaHeaderId)}
                                        onChange={
                                            (selected: ValueType<{ label: string; value: number; }, false>) => {
                                                setSelectedAgendaHeaderId(selected ? selected.value : undefined);
                                            }
                                        }
                                        options={activeAgendaHeaderOptions}
                                    />
                                }
                            </div>
                        },
                        {
                            component: <div>
                                <hr />
                                <DataTable
                                    muiDataTableProps={{
                                        data: formattedUnscheduledItemsTableDate,
                                        title: 'Unscheduled Items',
                                        columns: formattedUnscheduledItemsTableDate[0] &&
                                            Object.keys(formattedUnscheduledItemsTableDate[0]).map(key => {
                                                const opt: MUIDataTableColumn = {
                                                    name: key,
                                                    options: {
                                                        filter: key === 'Department',
                                                    }
                                                };

                                                if (key === 'Title') {
                                                    opt.options = opt.options || {};
                                                    opt.options.customBodyRender = (value, tableMeta) => {
                                                        const docURL = tableMeta.rowData[1] ? buildDocURL(
                                                            userBundleContext.organization!.PREFIX!,
                                                            tableMeta.rowData[1],
                                                            parseInt(tableMeta.rowData[3])
                                                        ) : buildManualURL(tableMeta.rowData[2]);
                                                        return <a
                                                            target='_blank'
                                                            href={
                                                                docURL
                                                            }
                                                        >
                                                            {value}
                                                        </a>;
                                                    };
                                                }

                                                return opt;
                                            })
                                    }}
                                />
                            </div>
                        }
                    ]}
                />
            }
        </div>
        <SaveBar
            onSave={async () => await saveCommitteeDefaultActiveAgenda()}
            isSaveDisabled={!displaySaveButton}
            onCancel={() => setLoadingStatus(LoadingStatuses.LOADING)}
            isCancelDisabled={!displaySaveButton}
            // cancelButtonText='Discard'
        />
    </div>;

};

export default CommitteeAgendas;
