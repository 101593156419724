import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Checkbox, FormControlLabel, TextField, Tooltip } from '@material-ui/core';
import SaveBar from '../../../_shared/SaveBar/SaveBar';
import H3WithHelpTip from '../../../_shared/Forms/H3WithHelpTip';
import { LoadingIndicator } from '../../../_shared/LoadingIndicator';
import { LoadingStatuses } from '../../../../utils/LoadingStatuses';
import { makeStyles } from '@material-ui/core/styles';
import LucidocColors from '../../../../constants/LucidocColors';
import { GetApp } from '@material-ui/icons';
import { setTitleInAdmin } from '../../../../utils/setTitleInAdmin';
import { TemporaryFavoritesStar } from '../../../_shared/TemporaryFavoritesStar/TemporaryFavoritesStar';
import { FileInput } from '../../../_shared/FileInput';
import ReactSelect from 'react-select';
import { SAMLTypes } from '../../../../entities/master/SAML_PROFILE';
import { redirectToHomepage } from '../../../../utils/savebarUtilities';
import setWindowTitle from '../../../../utils/setWindowTitle';

const inputWidth = 700;

const useStyles = makeStyles({
    mainDiv: {
        padding: '0 1rem',
        boxSizing: 'border-box',
        marginTop: '1rem',
    },
    selectMenuDiv: {
        width: inputWidth,
        zIndex: 100,
        position: 'relative'
    },
    textFieldLine: {
        margin: '1rem 0 0 0',
        display: 'flex',
        alignItems: 'center',
    },
    textField: {
        width: inputWidth,
        marginRight: '1rem'
    },
    hrDivider: {
        margin: '2rem 0 .75rem 0',
        borderTop: 0,
        borderColor: LucidocColors.gray,
    },
    certUploadDownloadButton: {
        marginLeft: '.5rem',
    },
    certControlsOuter: {
        width: '700px',
        marginTop: '1rem',
        display: 'flex',
        justifyContent: 'flex-start',
    },
    certControlsInner: {
        display: 'flex',
        flexDirection: 'column',
    },
    certControlsRow: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '.5rem',
    },
    samlItem: {
        marginTop: '1rem',
    },
});

export function SamlProfile() {
    const classes = useStyles();

    const [ loadingStatus, setLoadingStatus ] = useState(LoadingStatuses.LOADING);

    const [ SAMLType, setSAMLType ] = useState<SAMLTypes>();

    // MICROSOFT (AZURE) VALUES:
    // these are the terms used in Microsoft's UI, so we'll display them for ease of use,
    // then run the conversions to what it needs to be in our database when loading/saving:
    const [ applicationID, setApplicationID ] = useState<string>('');
    const [ directoryID,   setDirectoryID   ] = useState<string>('');
    const [ clientSecret,  setClientSecret  ] = useState<string>('');

    // OKTA VALUES:
    const [ IDPSingleSignOnURL, setIDPSingleSignOnURL ] = useState<string>('');
    const [ identityProviderIssuer, setIdentityProviderIssuer ] = useState<string>('');
    const [ audience, setAudience] = useState<string>('');
    const [ LucidocSingleSignOnURLForOKTA, setLucidocSingleSignOnURLForOKTA ] = useState<string>('');

    const [ generateNewKeys, setGenerateNewKeys ] = useState<boolean>(false);
    const [ updateEmailLinks, setUpdateEmailLinks ] = useState<boolean>(false);

    // code-generated values (cannot be updated by users):
    const [ loginURL,    setLoginURL    ] = useState<string>('');
    const [ redirectURI, setRedirectURI ] = useState<string>('');
    const [ ourCert,     setOurCert     ] = useState<string>('');
    const [ theirCert,   setTheirCert   ] = useState<string>('');

    // the existing AD or SAML redirect used in email notifications; used to check if updateEmailLinks is necessary
    const [ orgLoginURL, setOrgLoginURL ] = useState<string>('');

    useEffect(() => {
        const newTitle = 'Manage SAML Profile';
        setWindowTitle(newTitle);
        setTitleInAdmin(newTitle);
        getSamlProfile().then();
    }, []);

    async function getSamlProfile() {
        setLoadingStatus(LoadingStatuses.LOADING);
        setGenerateNewKeys(false);

        const res = await axios.get('/api/administration/organization/saml-profile/get-saml-profile');

        setSAMLType(res.data.SAMLType);

        // Microsoft (Azure) values:
        setApplicationID(res.data.applicationID);
        setDirectoryID(res.data.directoryID);
        setClientSecret(res.data.clientSecret);

        // OKTA values:
        setIDPSingleSignOnURL(res.data.IDPSingleSignOnURL);
        setIdentityProviderIssuer(res.data.identityProviderIssuer);
        setAudience(res.data.audience);
        setLucidocSingleSignOnURLForOKTA(res.data.LucidocSingleSignOnURLForOKTA);

        setLoginURL(res.data.loginURL);
        setRedirectURI(res.data.redirectURI);
        setOurCert(res.data.ourCert);
        setTheirCert(res.data.theirCert);

        setOrgLoginURL(res.data.orgLoginURL);
        setUpdateEmailLinks(res.data.loginURL === res.data.orgLoginURL);

        setLoadingStatus(LoadingStatuses.READY);
    }

    async function saveSamlProfile() {
        if (generateNewKeys && !window.confirm(
            'You have chosen to generate new access keys. Once generated, the corresponding certificate must be entered into Microsoft Azure for SAML sign-in to function properly.\n\nAre you sure you wish to proceed?'
        )) {
            return;
        }

        setLoadingStatus(LoadingStatuses.SAVING);

        const res = await axios.post('/api/administration/organization/saml-profile/save-saml-profile', {
            SAMLType: SAMLType,

            // Microsoft (Azure) values:
            applicationID,
            directoryID,
            clientSecret,

            // OKTA values:
            IDPSingleSignOnURL,
            identityProviderIssuer,
            audience,

            theirCert,
            generateNewKeys,
            updateEmailLinks,
        });

        if (!res.data.success) {
            alert('Something went wrong');
            setLoadingStatus(LoadingStatuses.READY);
            return;
        }

        setGenerateNewKeys(false);
        await getSamlProfile();
    }

    async function handleFileUpload(files: FileList | null) {
        if (!files) return;
        const file = files[0];
        setTheirCert(await file.text());
    }

    interface samlItem {
        displayName: string
        stateValue: string // first value from useState but they all happen to be strings
        stateSetter?: (value: string) => void
        tooltip: string
        disabled?: boolean  // is generated by Lucidoc, therefore customer can't edit
        actionButton?: React.ReactElement
    }

    interface samlItemSet {
        displayName: string
        tooltip: string
        samlItems: samlItem[]
    }

    const AzureEditableSamlItems: samlItem[] = [
        {
            displayName: 'Application (client) ID / Issuer',
            stateValue: applicationID,
            stateSetter: setApplicationID,
            tooltip: '',
        },
        {
            displayName: 'Directory (tenant) ID',
            stateValue: directoryID,
            stateSetter: setDirectoryID,
            tooltip: '',
        },
        {
            displayName: 'Client Secret',
            stateValue: clientSecret,
            stateSetter: setClientSecret,
            tooltip: '',
        }
    ];

    const OktaEditableSamlItems: samlItem[] = [
        {
            displayName: 'Identity Provider Single Sign-On URL',
            stateValue: IDPSingleSignOnURL,
            stateSetter: setIDPSingleSignOnURL,
            tooltip: '',
        },
        {
            displayName: 'Identity Provider Issuer',
            stateValue: identityProviderIssuer,
            stateSetter: setIdentityProviderIssuer,
            tooltip: '',
        },
        {
            displayName: 'Audience URI (SP Entity ID)',
            stateValue: audience,
            stateSetter: setAudience,
            tooltip: '',
        }
    ];

    const editableValues: samlItemSet = {
        displayName: 'Editable Values',
        tooltip: 'Editable Values',
        samlItems: (() => {
            if (!SAMLType) return [];
            if (SAMLType === SAMLTypes.Azure) return AzureEditableSamlItems;
            return OktaEditableSamlItems;
        })()
    };

    const urlValues: samlItemSet = {
        displayName: 'URL Values',
        tooltip: 'URL Values',
        samlItems: [
            {
                displayName: 'Login URL',
                stateValue: loginURL,
                tooltip: '',
                disabled: true,
            },
            {
                displayName: SAMLType === SAMLTypes.Azure
                    ? 'Redirect URI'
                    : 'Single Sign On / Recipient / Destination URL',
                stateValue: SAMLType === SAMLTypes.Azure
                    ? redirectURI
                    : LucidocSingleSignOnURLForOKTA,
                tooltip: '',
                disabled: true,
            },
        ]
    };

    let provider;
    if (SAMLType === SAMLTypes.Azure) {
        provider = 'Microsoft';
    } else if (SAMLType === SAMLTypes.OKTA) {
        provider = 'OKTA';
    } else { 
        provider = 'Identity Provider';
    }
  
    const keyValues: samlItemSet = {
        displayName: 'Key Values',
        tooltip: 'Key Values',
        samlItems: [
            {
                displayName: `${provider} Certificate`,
                stateValue: theirCert,
                tooltip: '',
                disabled: true,
                actionButton: <UploadTheirCertButton />,
            },
            {
                displayName: 'Lucidoc Certificate',
                stateValue: ourCert,
                tooltip: '',
                disabled: true,
                actionButton: <DownloadOurCertButton />,
            },
        ]
    };

    const allValues: samlItemSet[] = [
        editableValues,
        urlValues,
        keyValues,
    ];

    // no numerical values here because we want the string to be the displayed label,
    // plus we store it in the database as a string anyway:
    const selectMenuOptions = [
        {
            label: 'Azure',
            value: 'Azure'
        },
        {
            label: 'OKTA',
            value: 'OKTA'
        },
    ];

    function DownloadOurCertButton() {
        const DownloadButton = (
            <Button
                className={classes.certUploadDownloadButton}
                disabled={generateNewKeys}
                variant={'contained'}
                startIcon={<GetApp />}
            >
                Download .CRT
            </Button>
        );

        return (
            <Tooltip
                title={generateNewKeys ? 'Cannot download existing .CRT if new keys are going to be generated' : ''}
                arrow
            >
                <span>
                    {/* this is a roundabout way of disabling the button because the <a> tag is on the outside, so you can't just do disabled={something} on the button itself */}
                    {!generateNewKeys &&
                        <a
                            href={window.URL.createObjectURL(new Blob([ourCert], { type: 'text/plain' }))}
                            download={'LucidocSaml.crt'}
                            style={{ textDecoration: 'none' }}
                        >
                            {DownloadButton}
                        </a>
                    }

                    {generateNewKeys &&
                        DownloadButton
                    }
                </span>
            </Tooltip>
        );
    }

    function UploadTheirCertButton() {
        return (
            <div className={classes.certUploadDownloadButton}>
                <FileInput
                    text={'Upload B64 Cert'}
                    fileName={' '}
                    handleFileUpload={(files) => handleFileUpload(files)}
                    uniqueID={'file-input'}
                />
            </div>
        );
    }

    return (
        <div>
            <div style={{ float: 'right' }}>
                <TemporaryFavoritesStar targetPathName={'admin/admin.pl?repname=saml_profile'} />
            </div>

            <div className={classes.mainDiv}>
                {loadingStatus !== LoadingStatuses.READY &&
                    <LoadingIndicator loadingStatus={loadingStatus} />
                }

                {loadingStatus === LoadingStatuses.READY &&
                    <>
                        <H3WithHelpTip helpText={''} text={'SAML Type'} />
                        <div className={classes.selectMenuDiv}>
                            <ReactSelect
                                value={{
                                    value: SAMLType || '',
                                    label: SAMLType || ''
                                }}
                                onChange={(option: any) => setSAMLType(option.value)}
                                options={selectMenuOptions}
                            />
                        </div>


                        {allValues.map((samlItemList, outerIdx) => {
                            return (
                                <div key={outerIdx} className={classes.samlItem}>
                                    <H3WithHelpTip
                                        helpText={samlItemList.displayName}
                                        text={samlItemList.tooltip}
                                    />

                                    {!samlItemList.samlItems.length &&
                                        <p style={{ color: LucidocColors.darkGray }}>(Please select a SAML Type)</p>
                                    }

                                    {samlItemList.samlItems.map((samlItem, idx) => {
                                        return (
                                            <div key={`${samlItem.displayName}_${idx}`}>
                                                <div className={classes.textFieldLine}>
                                                    <TextField
                                                        id={samlItem.displayName}
                                                        label={samlItem.displayName}
                                                        value={samlItem.stateValue}
                                                        onChange={e => samlItem.stateSetter?.(e.currentTarget.value)}
                                                        variant={'outlined'}
                                                        size={'small'}
                                                        className={classes.textField}
                                                        autoComplete={'off'}
                                                        disabled={samlItem.disabled}
                                                    />

                                                    <H3WithHelpTip
                                                        helpText={samlItem.tooltip}
                                                        text={''}
                                                    />

                                                    {samlItem.actionButton}
                                                </div>
                                            </div>
                                        );
                                    })}

                                    {outerIdx < allValues.length - 1 &&
                                        <hr className={classes.hrDivider} />
                                    }
                                </div>
                            );
                        })}

                        <div className={classes.certControlsOuter}>
                            <div className={classes.certControlsInner}>
                                <div className={classes.certControlsRow}>
                                    <H3WithHelpTip
                                        helpText={'Check this box to regenerate these access keys, including a new certificate (Be sure to click Save when finished, then enter the new .PEM and .CRT files into Azure)'}
                                        text={''}
                                    />

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={generateNewKeys}
                                                style={{ color: LucidocColors.purple }}
                                                onChange={e => setGenerateNewKeys(e.currentTarget.checked)}
                                            />
                                        }
                                        label={
                                            <span>Generate New Lucidoc Certificate</span>
                                        }
                                    />
                                </div>

                            </div>
                        </div>

                        <hr className={classes.hrDivider} />

                        <H3WithHelpTip
                            helpText={loginURL === orgLoginURL
                                ? 'Links in future emails will redirect through SAML'
                                : 'Check this box to update links in future emails to redirect through SAML'
                            }
                            text={'Email Links'}
                        />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={updateEmailLinks}
                                    style={{ color: loginURL === orgLoginURL ? LucidocColors.gray : LucidocColors.purple }}
                                    onChange={e => setUpdateEmailLinks(e.currentTarget.checked)}
                                    disabled={loginURL === orgLoginURL}
                                />
                            }
                            label={
                                <span>Redirect email links through SAML</span>
                            }
                        />
                    </>
                }
            </div>

            {loadingStatus === LoadingStatuses.READY &&
                <SaveBar
                    onSave={() => saveSamlProfile()}
                    onCancel={() => getSamlProfile()}
                    onClose={() => redirectToHomepage()}
                />
            }

        </div>
    );
}
