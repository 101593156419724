import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Schedule } from '@material-ui/icons';
import { ucFirst } from '../../_shared/utils/docManagerFunctions';
import { LoadingStatuses } from '../../utils/LoadingStatuses';


const useStyles = makeStyles({
    loadingIndicator: {
        color: 'darkgrey',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
});

interface LoadingIndicatorProps {
    // this parameter is optional because a lot of components that use LoadingIndicator only use
    // LoadingStatuses.LOADING, so this component originally only said "Loading" but now it's also
    // a Saving indicator, but this parameter is optional so it's still backward compatible.
    loadingStatus?: keyof typeof LoadingStatuses
    zeroMargin?: boolean // useful in dropdowns so the <p> tag doesn't take up too much room
    iconOnly?: boolean
}

export function LoadingIndicator(props: LoadingIndicatorProps) {
    const classes = useStyles({});

    const loadingText = props.loadingStatus
        ? ucFirst(props.loadingStatus.toLowerCase())
        : 'Loading';

    return (
        <div className={classes.loadingIndicator}>
            <Schedule />
            {!props.iconOnly &&
                <p style={props.zeroMargin ? { margin: 0 } : {}}>{loadingText}...</p>
            }
        </div>
    );
}
