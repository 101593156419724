import {
    Column,
    Entity,
    JoinColumn,
    ManyToOne,
    PrimaryColumn,
} from 'typeorm';
import { type DOCUMENT,  } from './DOCUMENT';
import { type INFORMATION_HIERARCHY,  } from './INFORMATION_HIERARCHY';
import { SchemaEntityManager } from '../SchemaEntityManager';

// Define the factory function
function createInformationIndexEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('INFORMATION_INDEX', { schema: schema })
    class INFORMATION_INDEX {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', { nullable: false, precision: 10, scale: 0, name: 'INFORMATION_INDEX_ID' })
        INFORMATION_INDEX_ID!: number;

        @Column('number', { nullable: false, precision: 10, scale: 0, name: 'INFORMATION_HIERARCHY_ID' })
        INFORMATION_HIERARCHY_ID!: number;

        @Column('number', { nullable: false, precision: 10, scale: 0, name: 'DOCID' })
        DOCID!: number;

        @Column('number', { nullable: true, name: 'NEXT_INFO_INDEX_ID' })
        NEXT_INFO_INDEX_ID!: number | null;

        @Column('number', { nullable: true, name: 'SECTION_INDEX' })
        SECTION_INDEX!: number | null;

        @Column('number', { nullable: true, name: 'DOCUMENT_INDEX' })
        DOCUMENT_INDEX!: number | null;

        @ManyToOne(
            () => manager.getDocumentEntity(schema), 
            (document) => document.INFORMATION_INDEXES
        )
        @JoinColumn({ name: 'DOCID' })
        DOCUMENT!: DOCUMENT | null;

        @ManyToOne(
            () => manager.getInformationHierarchyEntity(schema), 
            (informationHierarchy) => informationHierarchy.INFORMATION_INDEXES
        )
        @JoinColumn({ name: 'INFORMATION_HIERARCHY_ID' })
        INFORMATION_HIERARCHY!: INFORMATION_HIERARCHY | null;
    }

    return INFORMATION_INDEX;
}

// Export the factory function
export { createInformationIndexEntity };

// Export the type representing an instance of the class generated by the factory function
export type INFORMATION_INDEX = InstanceType<ReturnType<typeof createInformationIndexEntity>>;
