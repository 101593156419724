import { ORGANIZATION } from '@/entities/master/ORGANIZATION';
import { NavigationMenuContent, NavigationMenuItem, NavigationMenuTrigger } from '@/ui/ui/navigation-menu';
import React, { useContext } from 'react';
import { NavigationMenuContentDropdown, NavigationDropdownSectionEntry, NavigationDropdownSectionEntryLabel, NavigationDropdownTitle, NavigationMenuFooter } from '@/ui/ui/navigation-dropdown';
import { UsersIcon } from '@/ui/icons/box/Users';
import { GroupIcon } from '@/ui/icons/box/Group';
import { QuestionFormIcon } from '@/ui/icons/box/QuestionForm';
import { CurrentLocationIcon } from '@/ui/icons/box/CurrentLocation';
import { InfoIcon } from '@/ui/icons/box/Info';
import { CommitteePreferencesIcon } from '@/ui/icons/box/CommitteePreferences';
import hasRights from '@/_shared/utils/hasRights';
import UserBundleContext from '@/context/UserBundleContext';
import { RightsCodeKey } from '@/entities/master/PERSON';
import { AskAQuestionDialogParams } from './orgdropdown/AskAQuestionDialog';
import { useBrowserQuery } from '@/hooks/useBrowserQuery';
import { useLocation } from 'react-router-dom';

interface HeaderOrganizationDropdownProps {
    organization: ORGANIZATION | null;
}


export const HeaderOrganizationDropdown: React.FC<HeaderOrganizationDropdownProps> = ({ organization }) => {


    const userBundle = useContext(UserBundleContext);
    const isOrgAdmin = hasRights(userBundle, RightsCodeKey.OrganizationAdministrator);
    const location = useLocation();

    const isAdmin =
        hasRights(userBundle, RightsCodeKey.OrganizationAdministrator) ||
        hasRights(userBundle, RightsCodeKey.DocumentAdministrator) ||
        hasRights(userBundle, RightsCodeKey.UserAdministrator) ||
        hasRights(userBundle, RightsCodeKey.SystemAdministrator);

    const [query, setQuery] = useBrowserQuery<AskAQuestionDialogParams>();

    return (
        <NavigationMenuItem>
            <NavigationMenuTrigger>
                <p className='hidden xs:block text-primary'>{organization?.NAME}</p>
                <p className='block xs:hidden'>Organization</p>
            </NavigationMenuTrigger>
            <NavigationMenuContent>
                <NavigationMenuContentDropdown
                    footer={
                        isAdmin && <NavigationMenuFooter href='/admin/admin.pl'>
                            Go to administration...
                        </NavigationMenuFooter>
                    }
                >
                    <NavigationDropdownTitle>
                        Organization
                    </NavigationDropdownTitle>
                    <NavigationDropdownSectionEntryLabel>
                        organization login session
                    </NavigationDropdownSectionEntryLabel>
                    <NavigationDropdownSectionEntry
                        icon={<CurrentLocationIcon size={28} />}
                        title={organization?.NAME ?? 'Org is NULL'}
                        description='Currently logged in to this organization'
                        external={false}
                    />
                    <NavigationDropdownSectionEntry
                        icon={<QuestionFormIcon size={28} />}
                        title='Ask Question'
                        description={'Contact your organization\'s Lucidoc Administrator'}
                        onClick={() => {
                            setQuery({ ...query, askAQuestionDialogOpen: true });
                        }}
                        href={undefined}
                        external={false}
                    />
                    {isOrgAdmin && <>
                        <NavigationDropdownSectionEntryLabel>
                            Administration
                        </NavigationDropdownSectionEntryLabel>
                        <NavigationDropdownSectionEntry
                            href={'/admin/admin.pl?repname=oinfo'}
                            icon={<InfoIcon size={28} />}
                            title='Info'
                            description={'Configure your organization\'s info'}
                            external={true}
                        />
                        <NavigationDropdownSectionEntry
                            href={'/admin/admin.pl?repname=manage_users'}
                            icon={<UsersIcon size={28} />}
                            title='Users'
                            description={'Configure your organization\'s users'}
                            external={true}
                        />
                        <NavigationDropdownSectionEntry
                            href={'/admin/admin.pl?repname=manage_groups'}
                            icon={<GroupIcon size={28} />}
                            title='Groups'
                            description={'Configure your organization\'s groups'}
                            external={true}
                        />
                        {
                            location.pathname.includes('committees') &&
                            <NavigationDropdownSectionEntry
                                href={'/admin/admin.pl?repname=committee_preferences'}
                                external={true}
                                icon={<CommitteePreferencesIcon size={28} />}
                                title='Committee Preferences'
                                description={'Configure your organization\'s committee preferences'}
                            />
                        }
                    </>}
                </NavigationMenuContentDropdown>
            </NavigationMenuContent>
        </NavigationMenuItem>
    );
};
