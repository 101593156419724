import { Column } from '@tanstack/react-table';
import { FacetedFilter } from '../facteted-filter';

interface DataTableFacetedFilterProps<TData, TValue> {
    column: Column<TData, TValue> | undefined;
    title: string;
    options?: {
        label: string;
        value: string;
    }[];
    icon?: React.ReactNode;
}

export function DataTableFacetedFilter<TData, TValue>({
    column,
    title,
    options,
    icon
}: DataTableFacetedFilterProps<TData, TValue>) {

    if (!column) return <p>Column is undefined</p>;

    // Extract faceted values and counts from the column
    const facets = column.getFacetedUniqueValues();
    const selectedValues = new Set(column.getFilterValue() as string[]);

    const getOptions = () => {
        if (options) {
            return options.map(o => {
                return {
                    ...o,
                    count: facets.get(o.value) || 0
                };
            });
        }
        const uniqueValues = Array.from(facets.keys() ?? []);
        return uniqueValues.map(value => {
            return {
                label: value,
                value: value,
                count: facets.get(value) ?? 0
            };
        });
    };

    const handleFilterChange = (newSelectedValues: Set<string>) => {
        const filterValues = Array.from(newSelectedValues);
        column.setFilterValue(filterValues.length ? filterValues : undefined);
    };

    return (
        <FacetedFilter
            title={title}
            options={getOptions()}
            setSelectedValues={handleFilterChange}
            selectedValues={selectedValues}
            icon={icon}
        />
    );
}
