import React from 'react';

interface LockProps {
    size: number
}

export const LockIcon: React.FC<LockProps> = ({ size }) => {
    return (
        <svg width={size.toString()} height={size.toString()} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" rx="4" fill="hsl(var(--primary))" />
            <path d="M6 12.5C6 12.2239 6.22386 12 6.5 12H17.5C17.7761 12 18 12.2239 18 12.5V18.5C18 18.7761 17.7761 19 17.5 19H6.5C6.22386 19 6 18.7761 6 18.5V12.5Z" fill="hsl(var(--white))" />
            <path d="M6.85742 10.1429C6.85742 7.30254 9.15996 5 12.0003 5C14.8406 5 17.1431 7.30254 17.1431 10.1429V12H15.086V9.96806C15.086 8.26387 13.7045 6.88234 12.0003 6.88234C10.2961 6.88234 8.91456 8.26387 8.91456 9.96806V12H6.85742V10.1429Z" fill="hsl(var(--white))" />
        </svg>

    );
};