import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { ORGANIZATION } from '../../../entities/master/ORGANIZATION';
import { Checkbox } from '@material-ui/core';
import LucidocColors from '../../../constants/LucidocColors';
import  { LoadingIndicator } from '../../_shared/LoadingIndicator';
import { LoadingStatuses } from '../../../utils/LoadingStatuses';
import DataTable from '../../_shared/DataTable/DataTable';

export function HandleOrgSecurityAccess() {
    const [ loadingStatus, setLoadingStatus ] = useState<LoadingStatuses>(LoadingStatuses.LOADING);
    const [ orgs, setOrgs ] = useState<ORGANIZATION[]>([]);

    useEffect(() => {
        getOrgs().then();
    }, []);

    async function getOrgs() {
        setLoadingStatus(LoadingStatuses.LOADING);

        const res = await axios.get('/api/internal-support/handle-org-security-access/get-orgs');

        setOrgs(res.data.orgs || []);

        setLoadingStatus(LoadingStatuses.READY);
    }

    async function handleSecurityUpdate(org: ORGANIZATION, valueType: 'HAS_BETA_ACCESS' | 'ENABLE_CERTIFICATE_LOGIN', isEnabled: boolean) {
        setLoadingStatus(LoadingStatuses.SAVING);

        let message = (
              `${isEnabled ? 'Enable' : 'Disable'} `
            + `${valueType === 'HAS_BETA_ACCESS' ? 'beta access' : 'certificate login'}`
            + ` for ${org.NAME}?`
        );

        if (valueType === 'HAS_BETA_ACCESS') {
            message += '\n(Beta access must also be handled through the AWS firewall)';
        }

        if (window.confirm(message)) {
            const res = await axios.post('/api/internal-support/handle-org-security-access/handle-org-security-update', {
                orgID: org.ORGANIZATIONID,
                valueType,
                isEnabled,
            });

            setOrgs(res.data.orgs || []);
        }

        setLoadingStatus(LoadingStatuses.READY);
    }

    if (loadingStatus === LoadingStatuses.LOADING) {
        return <LoadingIndicator />;
    }

    return (
        <div>
            <p>
                <Link to={'/internal-support'}>
                    Back
                </Link>
            </p>

            <DataTable
                muiDataTableProps={{
                    title: 'Organization Security Access',
                    columns: [
                        { name: 'OrgID' },
                        { name: 'Name' },
                        {
                            name: 'Beta Access',
                            options: {
                                customBodyRender: (value, tableMeta) => {
                                    const orgID = tableMeta.rowData[0];
                                    const org = orgs.find(org => org.ORGANIZATIONID === orgID);

                                    if (!org) return null;

                                    return (
                                        <Checkbox
                                            checked={org.HAS_BETA_ACCESS}
                                            onChange={() => handleSecurityUpdate(org, 'HAS_BETA_ACCESS', !org.HAS_BETA_ACCESS)}
                                            style={{ color: LucidocColors.purple, padding: 0 }}
                                        />
                                    );
                                }
                            }
                        },
                        {
                            name: 'Certificate Access',
                            options: {
                                customBodyRender: (value, tableMeta) => {
                                    const orgID = tableMeta.rowData[0];
                                    const org = orgs.find(org => org.ORGANIZATIONID === orgID);

                                    if (!org) return null;

                                    return (
                                        <Checkbox
                                            checked={org.ENABLE_CERTIFICATE_LOGIN}
                                            onChange={() => handleSecurityUpdate(org, 'ENABLE_CERTIFICATE_LOGIN', !org.ENABLE_CERTIFICATE_LOGIN)}
                                            style={{ color: LucidocColors.purple, padding: 0 }}
                                        />
                                    );
                                }
                            }
                        }
                    ],
                    data: orgs.map(org => {
                        return {
                            OrgID: org.ORGANIZATIONID,
                            Name: org.NAME,
                            'Beta Access': org.HAS_BETA_ACCESS,
                            'Certificate Access': org.ENABLE_CERTIFICATE_LOGIN
                        };
                    }),
                    options: {
                        rowsPerPage: 100
                    }
                }}
                isDisabled={loadingStatus !== LoadingStatuses.READY}
            />
        </div>
    );
}
