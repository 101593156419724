import * as React from 'react';
import * as TabsPrimitive from '@radix-ui/react-tabs';

import { cn } from '@/lib/utils';
import { VariantProps, cva } from 'class-variance-authority';

const Tabs = TabsPrimitive.Root;




const tabListVariants = cva(
    'inline-flex h-10 items-center justify-center text-muted-foreground',
    {
        variants: {
            variant: {
                default: 'rounded-md bg-muted px-0.5',
                minimal: ''
            }
        },
        defaultVariants: {
            variant: 'default'
        }
    }
);

// This context is used so that the sub-components don't require the exact same variant props applied to the parent.
// It's just silly to have to specify the variant prop on every single child component.
const TabsContext = React.createContext<VariantProps<typeof tabListVariants>>({ variant: 'default' });
export const useTabsContext = () => React.useContext(TabsContext);

interface TabsListProps extends React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>, VariantProps<typeof tabListVariants> { }

const TabsList = React.forwardRef<
    React.ElementRef<typeof TabsPrimitive.List>,
    TabsListProps
>(({ className, ...props }, ref) => (
    <TabsContext.Provider value={{ variant: props.variant! }}>
        <TabsPrimitive.List
            ref={ref}
            className={cn(
                tabListVariants({ variant: props.variant }),
                className
            )}
            {...props}
        />
    </TabsContext.Provider>
));
TabsList.displayName = TabsPrimitive.List.displayName;


const tabsTriggerVariants = cva(
    'inline-flex items-center justify-center whitespace-nowrap py-1 text-sm font-medium transition-all disabled:pointer-events-none disabled:opacity-50 data-[state=active]:text-foreground px-1',
    {
        variants: {
            variant: {
                default: 'ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 data-[state=active]:bg-background data-[state=active]:shadow-sm rounded-sm px-2 mx-1',
                minimal: 'border-b-2 data-[state=active]:border-primary data-[state=active]:text-primary-accent',
            }
        },
        defaultVariants: {
            variant: 'default'
        }
    }
);

const TabsTrigger = React.forwardRef<
    React.ElementRef<typeof TabsPrimitive.Trigger>,
    React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => {
    const { variant } = useTabsContext();
    return (
        <TabsPrimitive.Trigger
            ref={ref}
            className={cn(
                tabsTriggerVariants({ variant: variant }),
                className
            )}
            {...props}
        />
    );
});
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;

const TabsContent = React.forwardRef<
    React.ElementRef<typeof TabsPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
    <TabsPrimitive.Content
        ref={ref}
        className={cn(
            'ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
            className
        )}
        {...props}
    />
));
TabsContent.displayName = TabsPrimitive.Content.displayName;

export { Tabs, TabsList, TabsTrigger, TabsContent };
