import React, { useContext, useEffect, useState } from 'react';

import axios from 'axios';
import { createSortableDate } from '../../../../../_shared/utils/docManagerFunctions';

import { CONSENT_AGENDA, ConsentAgendaStatuses } from '../../../../../entities/org/CONSENT_AGENDA';
import { COMMITTEE } from '../../../../../entities/org/COMMITTEE';

import UserBundleContext from '../../../../../context/UserBundleContext';
import { LoadingIndicator } from '../../../../_shared/LoadingIndicator';
import { LoadingStatuses } from '../../../../../utils/LoadingStatuses';
import { Tooltip } from '@material-ui/core';

type DateAdjustedConsentAgenda = Pick<CONSENT_AGENDA,
    'CONSENT_AGENDA_ID' |
    'MEETING_START_DATE' |
    'MEETING_END_DATE' |
    'STATUS'
> & {
    COMMITTEE: COMMITTEE | null
};

enum Months {
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
}

interface ICalendarDayProps {
    date: Date;
    empty?: boolean;
    noLeftBorder?: boolean;
    onClick?: (date: Date) => void;

    consentAgendas: DateAdjustedConsentAgenda[];
}

const CalendarDay: React.FC<ICalendarDayProps> = (props: ICalendarDayProps) => {
    const context = useContext(UserBundleContext);

    const isToday = props.date.toDateString() === (new Date()).toDateString() && !props.empty;
    
    const date = new Date(props.date.getFullYear(), props.date.getMonth(), props.date.getDate() + 1);
    const endOfMonth = date.getMonth() !== props.date.getMonth();

    let isLastSunday = false;
    const dayNextWeek = new Date(props.date.getFullYear(), props.date.getMonth(), props.date.getDate() + 7);
    if (dayNextWeek.getMonth() !== props.date.getMonth() && props.date.getDay() === 0) {
        isLastSunday = true;
    }

    let isLastSaturday = false;
    if (dayNextWeek.getMonth() !== props.date.getMonth() && props.date.getDay() === 6) {
        isLastSaturday = true;
    }

    const isSaturday = props.date.getDay() === 6;

    return <div
        style={{
            display: 'grid',
            gridTemplateRows: '1rem 7.25rem',
            borderLeft: '.0625rem solid #d0d0d0',
            borderBottom: '.0625rem solid #d0d0d0',
            borderRight: (isSaturday || endOfMonth) ? '.0625rem solid #d0d0d0' : undefined,
            borderBottomLeftRadius: isLastSunday ? '.375rem' : undefined,
            borderBottomRightRadius: (endOfMonth || isLastSaturday) ? '.375rem' : undefined,
            width: '100%',
            cursor: props.empty ? 'initial' : 'pointer'
        }}
    >
        <div
            style={{
                paddingTop: '.15rem',
                paddingLeft: '.15rem',
                display: 'grid',
                fontSize: '.85rem',
                fontWeight: 500
            }}
        >
            <span
                style={{
                    backgroundColor: isToday ? '#a392ce' : undefined,
                    color: isToday ? '#fff' : undefined,
                    width: '1.25rem',
                    height: '1.25rem',
                    display: 'flex',
                    borderRadius: '.125rem',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <p>
                    {
                        !props.empty &&
                        props.date.getDate()
                    }
                </p>
            </span>
            <div
                style={{
                    padding: '.15rem',
                    paddingTop: '.5rem',
                    display: 'grid'
                }}
            >
                {
                    props.consentAgendas
                        .map(
                            consentAgenda => {
                                const startTime = consentAgenda.MEETING_START_DATE.toLocaleTimeString().substr(0, 5);
                                const endTime = consentAgenda.MEETING_END_DATE?.toLocaleTimeString().substr(0, 5);
                                const isChairperson = context.user?.USERID === consentAgenda.COMMITTEE?.CHAIRPERSON_USERID;
                                const isSecretary = context.user?.USERID === consentAgenda.COMMITTEE?.SECRETARY_USERID;
                                const isCollaborator = !!consentAgenda.COMMITTEE?.COLLABORATORS?.some(collaborator => collaborator.USERID === context.user?.USERID);

                                const notReleasedAndNotChairOrSecretary = (
                                    consentAgenda.STATUS === ConsentAgendaStatuses.invisible_and_mutable &&
                                    !isChairperson && !isSecretary && !isCollaborator
                                );

                                const releasedOrNotReleasedButChairOrSecretary = (
                                    consentAgenda.STATUS !== ConsentAgendaStatuses.invisible_and_mutable ||
                                    (
                                        consentAgenda.STATUS === ConsentAgendaStatuses.invisible_and_mutable &&
                                        (
                                            isChairperson || isSecretary || isCollaborator
                                        )
                                    )
                                );

                                return <Tooltip
                                    key={ `calendar-consent-agenda-${ consentAgenda.CONSENT_AGENDA_ID }` }
                                    title={ `${ startTime } - ${ endTime }` }
                                >
                                    <div
                                        style={{
                                            borderRadius: '.125rem',
                                            backgroundColor: consentAgenda.STATUS === ConsentAgendaStatuses.invisible_and_mutable ? '#F2AB20' : '#61CB7F',
                                            height: '1.125rem',
                                            paddingLeft: '.25rem',
                                            marginBottom: '.25rem',
                                            display: 'grid',
                                            alignItems: 'center',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        {
                                            notReleasedAndNotChairOrSecretary &&
                                                <span
                                                    style={{
                                                        textDecoration: 'none',
                                                        color: '#fff',
                                                        fontWeight: 300,
                                                        fontSize: '.75rem'
                                                    }}
                                                >
                                                    { consentAgenda.COMMITTEE?.NAME }
                                                </span>
                                        }
                                        {
                                            releasedOrNotReleasedButChairOrSecretary &&
                                                <a
                                                    style={{
                                                        textDecoration: 'none',
                                                        color: '#fff',
                                                        fontWeight: 300,
                                                        fontSize: '.75rem'
                                                    }}
                                                    target='_blank'
                                                    href={ `/docmgr2/consent_agenda_viewing.pl?organizationid=${ context.organization?.ORGANIZATIONID }&consent_agenda_id=${ consentAgenda.CONSENT_AGENDA_ID }` }
                                                >
                                                    { consentAgenda.COMMITTEE?.NAME }
                                                </a>
                                        }
                                    </div>
                                </Tooltip>;
                            }
                    )
                }
            </div>
        </div>
    </div>;

};

const Calendar: React.FC = () => {
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatuses>(LoadingStatuses.MOUNTING);

    const [consentAgendas, setConsentAgendas] = useState<CONSENT_AGENDA[]>([]);

    const loadInitialData = async () => {
        const res = await axios.get('/api/consent-agenda/my-meetings/all');

        setConsentAgendas(res.data.consentAgendas);

        setLoadingStatus(LoadingStatuses.READY);
    };

    useEffect(() => {
        if (
            loadingStatus === LoadingStatuses.LOADING ||
            loadingStatus === LoadingStatuses.MOUNTING
        ) {
            loadInitialData().then();
        }
    }, [loadingStatus]);

    const dateAdjustedConsentAgendas: DateAdjustedConsentAgenda[] = consentAgendas
        .map(
            consentAgenda => ({
                ...consentAgenda,
                MEETING_START_DATE: new Date(createSortableDate(new Date(consentAgenda.MEETING_START_DATE))),
                MEETING_END_DATE: consentAgenda.MEETING_END_DATE ? new Date(createSortableDate(new Date(consentAgenda.MEETING_END_DATE))) : null
            })
        );

    const [currentYear, setCurrentYear] = useState((new Date()).getFullYear());
    const [currentMonth, setCurrentMonth] = useState((new Date()).getMonth());

    const firstDay = new Date(currentYear, currentMonth, 1);

    const display: (null | Date)[] = [];

    for (let i = 0; i < firstDay.getDay(); i++) {
        display.push(null);
    }

    let date = firstDay;
    while (date.getMonth() === firstDay.getMonth()) {
        display.push(date);
        date = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
    }

    const daysOfWeek = [
        'SUNDAY',
        'MONDAY',
        'TUESDAY',
        'WEDNESDAY',
        'THURSDAY',
        'FRIDAY',
        'SATURDAY'
    ];

    const daysOfWeekHeader = daysOfWeek.map(day => {
        const styles = {
            borderLeft: '.0625rem solid #d0d0d0',
            borderBottom: '.0625rem solid #d0d0d0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            borderTopLeftRadius: '',
            borderRight: '',
            borderTopRightRadius: '',
        };
    
        if (day === 'SUNDAY') {
            styles.borderTopLeftRadius = '.375rem';
        }
        if (day === 'SATURDAY') {
            styles.borderRight = '.0625rem solid #d0d0d0';
            styles.borderTopRightRadius = '.375rem';
        }
    
        return (
            <div style={styles}>
                <p>{day}</p>
            </div>
        );
    });

    return <>
        {
            loadingStatus !== LoadingStatuses.READY &&
                <LoadingIndicator />
        }
        {
            loadingStatus === LoadingStatuses.READY &&
                <div>
                    <div
                        style={{
                            display: 'grid',
                            gridTemplateColumns: '4rem auto 4rem'
                        }}
                    >
                        <div />
                        <div
                            style={{
                                display: 'flex',
                                userSelect: 'none',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '4.5rem',
                                fontWeight: 500,
                                fontSize: '1.65rem'
                            }}
                        >
                                <span
                                    title='Previous month'
                                    onClick={
                                        () => {
                                            if (currentMonth === Months.January) {
                                                setCurrentMonth(Months.December);
                                                setCurrentYear(prev => prev - 1);
                                            } else {
                                                setCurrentMonth(prev => prev - 1);
                                            }
                                        }
                                    }
                                >
                                    <svg width='14' height='18' viewBox='0 0 14 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                        <path
                                            d='M12.2452 2.50434C13.0183 1.80158 12.7302 0.524314 11.7304 0.221336V0.221336C11.2774 0.0840545 10.7855 0.194965 10.4353 0.51339L1.62787 8.52012C0.754996 9.31364 0.754996 10.6864 1.62787 11.4799L10.4353 19.4866C10.7855 19.805 11.2774 19.9159 11.7304 19.7787V19.7787C12.7302 19.4757 13.0183 18.1984 12.2452 17.4957L4 10L12.2452 2.50434Z'
                                            fill='#666666'
                                        />
                                    </svg>
                                </span>
                            <span
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '12.5rem',
                                    cursor: 'pointer'
                                }}
                                onClick={
                                    () => {
                                        setCurrentMonth((new Date()).getMonth());
                                        setCurrentYear((new Date()).getFullYear());
                                    }
                                }
                                title='Return to current month and year'
                            >
                                    { Months[currentMonth] } { currentYear }
                                </span>
                            <span
                                title='Next month'
                                onClick={
                                    () => {
                                        if (currentMonth === Months.December) {
                                            setCurrentMonth(Months.January);
                                            setCurrentYear(prev => prev + 1);
                                        } else {
                                            setCurrentMonth(prev => prev + 1);
                                        }
                                    }
                                }
                            >
                                    <svg width='14' height='18' viewBox='0 0 14 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                        <path
                                            d='M0.754773 2.50434C-0.0182614 1.80158 0.269763 0.524314 1.26959 0.221336V0.221336C1.72262 0.0840545 2.21446 0.194965 2.56473 0.51339L11.3721 8.52012C12.245 9.31364 12.245 10.6864 11.3721 11.4799L2.56473 19.4866C2.21446 19.805 1.72262 19.9159 1.26959 19.7787V19.7787C0.269764 19.4757 -0.0182596 18.1984 0.754774 17.4957L9 10L0.754773 2.50434Z'
                                            fill='#666666'
                                        />
                                    </svg>
                                </span>
                        </div>
                        <div />
                    </div>
                    <div>
                        <div
                            style={{
                                display: 'grid',
                                height: '2rem',
                                borderTop: '.0625rem solid #d0d0d0',      
                                borderTopLeftRadius: '.375rem',
                                borderTopRightRadius: '.375rem',
                                gridTemplateColumns: '14.2857% 14.2857% 14.2857% 14.2857% 14.2857% 14.2857% 14.2857%'
                            }}
                        >
                            {daysOfWeekHeader}
                        </div>
                        <div
                            style={{
                                display: 'grid',
                                gridTemplateColumns: '14.2857% 14.2857% 14.2857% 14.2857% 14.2857% 14.2857% 14.2857%',
                            }}
                        >
                            {
                                display.map(
                                    (date, index) => {
                                        if (date)
                                            return <CalendarDay
                                                key={ `calendar-day-${ index }` }
                                                date={ date }
                                                consentAgendas={
                                                    (dateAdjustedConsentAgendas || [])
                                                        .filter(consentAgenda =>
                                                            (
                                                                consentAgenda.MEETING_START_DATE.getMonth() === date?.getMonth() &&
                                                                consentAgenda.MEETING_START_DATE.getDate() === date?.getDate() &&
                                                                consentAgenda.MEETING_START_DATE.getFullYear() === date?.getFullYear()
                                                            )
                                                        )
                                                }
                                            />;
                                        else
                                            return <CalendarDay
                                                key={ `calendar-day-${ index }` }
                                                date={ new Date() }
                                                empty
                                                noLeftBorder={ index === 0 }
                                                consentAgendas={ [] }
                                            />;
                                    }
                                )
                            }
                        </div>
                    </div>
                </div>
        }
    </>;

};

export default Calendar;
