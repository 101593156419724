import React from 'react';
import { FavoriteLink } from '../entities/master/LINK';

export interface FavoritesContextStateType {
    favorites: FavoriteLink[];
    favoritesToDelete: FavoriteLink[];
}

export interface FavoritesContextType {
    favoritesCtx: FavoritesContextStateType,
    setFavoritesCtx: React.Dispatch<React.SetStateAction<FavoritesContextStateType>>
}

export const FavoritesContext = React.createContext<FavoritesContextType>({
    favoritesCtx: {
        favorites: [],
        favoritesToDelete: [],
    },
    setFavoritesCtx: () => { },
});