import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Button, makeStyles } from '@material-ui/core';
import SaveIndicator from '../../../_shared/SaveIndicator';
import { buildDocURL } from '../../../../_shared/utils/docManagerFunctions';
import SaveBar from '../../../_shared/SaveBar/SaveBar';
import { PERSON } from '../../../../entities/master/PERSON';
import DataTable from '../../../_shared/DataTable/DataTable';
import { MUIDataTableColumn, MUIDataTableProps } from 'mui-datatables';
import { LoadingIndicator } from '../../../_shared/LoadingIndicator';
import { LoadingStatuses } from '../../../../utils/LoadingStatuses';
import LucidocColors from '../../../../constants/LucidocColors';
import { FileInput } from '../../../_shared/FileInput';
import { ArrowForward } from '@material-ui/icons';
import { buildUserProfileURL } from '../../../../_shared/utils/linkBuilders';
import UserBundleContext from '../../../../context/UserBundleContext';

const useStyles = makeStyles({
    upperLevel: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '1rem 0 2rem 0',
    },
    buttonContainerDiv: {
        width: '240px',
        '&:last-child': {
            width: '360px' // because the "No file selected" can turn into a long file name and it'll try to be multi-line if the div is too small to fit, which makes the next elements move up or down on file upload
        }
    },
    arrowForward: {
        color: LucidocColors.green,
        margin: '0 2rem',
    }
});

export function MassDisableUsers() {
    const classes = useStyles();

    const context = useContext(UserBundleContext);

    const [ loadingStatus, setLoadingStatus ] = useState(LoadingStatuses.MOUNTING);

    const [ fileToUpload, setFileToUpload ] = useState<File | undefined>(undefined);

    const [ tableDataForReaders, setTableDataForReaders ] = useState<MUIDataTableProps['data']>([]);
    const [ tableDataForNonReaders, setTableDataForNonReaders ] = useState<MUIDataTableProps['data']>([]);

    const [ isSaveIndicatorVisible, setIsSaveIndicatorVisible ] = useState(false);
    const [ updateCount, setUpdateCount ] = useState<number>(0);

    useEffect(() => {
        fileToUpload && getUsersToDisable().then();
    }, [fileToUpload]);

    function handleFileUpload(files: FileList | null) {
        setLoadingStatus(LoadingStatuses.LOADING);
        setFileToUpload(files?.[0]);
        // useEffect will then query for usersToDisable
    }

    async function getUsersToDisable() {
        if (!fileToUpload) return;

        setLoadingStatus(LoadingStatuses.LOADING);

        const formData = new FormData();
        formData.append('file', fileToUpload);

        const res = await axios.post(
            '/api/administration/users/mass-disable-users/get-users-to-disable',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );

        if (res.data.readerAccounts && res.data.nonReadersAccounts) {
            const arraysAndSetters = [
                {
                    array: res.data.readerAccounts,
                    setter: setTableDataForReaders
                },
                {
                    array: res.data.nonReadersAccounts,
                    setter: setTableDataForNonReaders
                },
            ];

            arraysAndSetters.forEach(item => {
                const newTableData = (item.array as PERSON[]).map(person => {
                    return {
                        UserID: person.USERID,
                        LoginID: person.LOGINID,
                        'First Name': person.FIRSTNAME,
                        'Last Name': person.LASTNAME,
                        Title: person.TITLE,
                        Department: person.DEPARTMENT_NAME,
                        Email: person.LOCATION,
                        Privileges: person.RIGHTS, // converted on the back end
                        GUID: person.GUID,
                    };
                });

                item.setter(newTableData);
            });
        }

        setLoadingStatus(LoadingStatuses.READY);
    }

    async function massDisableUsers() {
        if (!fileToUpload || loadingStatus !== LoadingStatuses.READY || !tableDataForReaders.length) return;

        setLoadingStatus(LoadingStatuses.SAVING);

        const formData = new FormData();
        formData.append('file', fileToUpload);

        const res = await axios.post(
            '/api/administration/users/mass-disable-users/finalize-mass-disable',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );

        if (res.data.error) alert('Something went wrong');

        if (typeof res.data.updateCount === 'number') {
            setUpdateCount(res.data.updateCount);
            setIsSaveIndicatorVisible(true);
        }

        setLoadingStatus(LoadingStatuses.READY);
    }

    const tableValues = [
        {
            title: 'Active Accounts with Only Reader Privileges Found',
            data: tableDataForReaders
        },
        {
            title: 'Active Accounts with Greater Than Reader Privileges Found',
            data: tableDataForNonReaders
        }
    ];

    const sampleSpreadsheetURL = buildDocURL(
        'lcorp',
        19092,
        undefined,
        true,
        true
    );

    return (
        <div>

            <div className={classes.upperLevel}>
                <div className={classes.buttonContainerDiv}>
                    <p>Download this Excel template:</p>
                    <Button variant={'contained'}>
                        <a href={sampleSpreadsheetURL}
                           style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}
                        >
                            Download File
                        </a>
                    </Button>
                </div>

                <ArrowForward className={classes.arrowForward}/>

                <div className={classes.buttonContainerDiv}>
                    <p>Fill in Column A with GUIDs of terminated employee accounts</p>
                </div>

                <ArrowForward className={classes.arrowForward}/>

                <div className={classes.buttonContainerDiv}>
                    <p>Upload the completed file:</p>
                    <FileInput
                        fileName={fileToUpload?.name}
                        handleFileUpload={(files) => handleFileUpload(files)}
                        uniqueID={'file-input'}
                    />
                </div>
            </div>

            {loadingStatus !== LoadingStatuses.READY && loadingStatus !== LoadingStatuses.MOUNTING &&
                <LoadingIndicator loadingStatus={loadingStatus} />
            }

            {loadingStatus === LoadingStatuses.READY &&
                tableValues.map((tableValue, idx) => {
                    return (
                        <DataTable
                            key={idx}
                            muiDataTableProps={{
                                title: tableValue.title,
                                columns: tableValue.data[0] && Object.keys(tableValue.data[0]).map(key => {
                                    const returnObj: MUIDataTableColumn = {
                                        name: key,
                                        options: {
                                            filter: ['Title', 'Department'].includes(key),
                                        }
                                    };

                                    // complicated ones from here down
                                    if (['UserID', 'LoginID'].includes(key) && returnObj.options) {
                                        returnObj.options = {
                                            customBodyRender: (value, tableMeta) => {
                                                const profileURL = buildUserProfileURL(
                                                    context.organization?.ORGANIZATIONID || 0,
                                                    tableMeta.rowData[0]
                                                );

                                                return (
                                                    <a href={profileURL} target={'_blank'} rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                                        {value}
                                                    </a>
                                                );
                                            }
                                        };
                                    }

                                    if (key === 'Privileges') {
                                        returnObj.options = {
                                            customBodyRender: (value) => {
                                                return (
                                                    <span style={{ whiteSpace: 'pre', fontFamily: 'monospace' }}>
                                                        {value}
                                                    </span>
                                                );
                                            }
                                        };
                                    }

                                    return returnObj;
                                }),
                                data: tableValue.data
                            }}
                        />
                    );
                })
            }

            <SaveBar
                onSave={() => massDisableUsers()}
                // saveButtonText={'Disable Active Reader Accounts'}
                isSaveDisabled={!fileToUpload || loadingStatus !== LoadingStatuses.READY || !tableDataForReaders.length}
            />

            <SaveIndicator
                open={isSaveIndicatorVisible}
                onClose={() => setIsSaveIndicatorVisible(false)}
                message={`Successfully updated ${updateCount} record${updateCount === 1 ? '' : 's'}`}
            />
        </div>
    );
}
