import React from 'react';

import './reviewapprovalroute.css';
import adjourn from '../../images/adjourn.png';
import { RequestStatusDataType } from './customDataTypes';
import { COMMITTEE } from '../../entities/org/COMMITTEE';
import { PERSON } from '../../entities/master/PERSON';
import { USERGROUP } from '../../entities/org/USERGROUP';
import { Group, Person } from '@material-ui/icons';
import { constructFullName } from '../UserPicker/utils/constructFullName';

interface ReviewApprovalRouteProps {
    signatureApprovalGroup: []
    showCommitteeApprovals: boolean
    committeeApprovalGroups: []
    requestStatus: RequestStatusDataType
    scheduledTaskDates: { [key: string]: string }
    phaseStatus: {
        committees: string
        signers: string
    }
    orderedSigning: boolean
    phaseOrder: string[]
    ratification: boolean
    isActive: boolean
}

function ReviewApprovalRoute(props: ReviewApprovalRouteProps) {

    const alreadyAssigned: { [key: string]: string } = {};

    const getStatusInfo = (person: PERSON) => {
        let signerStatus = '';
        let statusText = '';
        let statusClass = '';
        let statusColor = '';
        let titleText = '';

        if (!person.RIGHTSINENGLISH?.signer
            && !person.PERSON_ORGANIZATIONS?.[0]?.RIGHTSINENGLISH?.signer
        ) {
            statusText = ' (Not a signer)';
            statusClass = 'review-approval-route-disabled-user';
            statusColor = 'red';
            titleText = 'Users cannot sign without the signer privilege.';
        }

        if (props.isActive) {
            if (props.requestStatus.signers.users[person.USERID]) {
                signerStatus = props.requestStatus.signers.users[person.USERID];
            }

            if (alreadyAssigned[person.USERID]) {
                statusText = alreadyAssigned[person.USERID];
                statusClass = 'review-approval-route-disabled-user';
                statusColor = 'darkorange';
                titleText = 'Users cannot sign multiple times';
            }
            else if (signerStatus) {
                    if (signerStatus === 'Notified') {
                        statusText = ' (Notified)';
                        statusColor = 'dodgerBlue';
                    }
                    else if (signerStatus === 'Approved') {
                        statusText = ' (Approved)';
                        statusColor = 'green';
                    }
                    else if (signerStatus === 'Approved with edits') {
                        statusText = ' (Approved with edits)';
                        statusColor = 'green';
                    }
                    else if (signerStatus === 'Requested Edits') {
                        statusText = ' (Requested edits)';
                        statusColor = 'orange';
                    }
                    else if (signerStatus === 'Rejected') {
                        statusText = ' (Rejected)';
                        statusColor = 'red';
                    }
                }
        }

        return {
            statusText,
            statusClass,
            statusColor,
            titleText
        };
    };

    const signatureApprovalGroup = props.signatureApprovalGroup.map((signer: PERSON | USERGROUP, idx: number) => {
        if (isPerson(signer)) {
            const statusInfo = getStatusInfo(signer);
            if (!alreadyAssigned[signer.USERID]) {
                alreadyAssigned[signer.USERID] = '(Assigned as User)';
            }
            return (
                <li key={idx} className='review-approval-route-outer-li' title={statusInfo.titleText}>
                    <Person style={{ color: 'dodgerblue' }} />
                    <span className={statusInfo.statusClass}>{idx + 1}: {constructFullName(signer, 'lastFirstMiddle')}</span>
                    <span style={{ color: statusInfo.statusColor }}>{statusInfo.statusText}</span>
                </li>
            );
        }
        else if (isUserGroup(signer)) {
            let thoseWhoHaveSigned = 0;
            let thoseWhoHaveBeenNotified = 0;
            const people = signer.PEOPLE ? signer.PEOPLE.map((person: PERSON, innerIdx: number) => {

                const statusInfo = getStatusInfo(person);
                let signerStatus = '';
                if (props.isActive) {
                    if (props.requestStatus.signers.users[person.USERID]) {
                        signerStatus = props.requestStatus.signers.users[person.USERID];
                    }
                }

                if (!alreadyAssigned[person.USERID]) {
                    alreadyAssigned[person.USERID] = `(Assigned in Group: "${signer.NAME}")`;
                    if (signerStatus) {
                        if (signerStatus === 'Notified') {
                            thoseWhoHaveBeenNotified++;
                        }
                        if (signerStatus === 'Approved'
                            || signerStatus === 'Approved with edits'
                        ) {
                            thoseWhoHaveSigned++;
                        }
                    }
                }

                return (
                    <li key={innerIdx} className='review-approval-route-inner-li' title={statusInfo.titleText}>
                        <Person style={{ color: 'dodgerblue' }} />
                        <span className={statusInfo.statusClass}>{constructFullName(person, 'lastFirstMiddle')} </span>
                        <span style={{ color: statusInfo.statusColor }}>{statusInfo.statusText}</span>
                    </li>
                );
            }) : [];

            let groupStatus = '';
            let groupStatusColor = '';

            if (thoseWhoHaveSigned === people.length) {
                groupStatus = '(Approved)';
                groupStatusColor = 'green';
            }
            else if (thoseWhoHaveBeenNotified > 0 && thoseWhoHaveSigned < people.length) {
                groupStatus = '(In progress)';
                groupStatusColor = 'lime';
            }

            return (
                <li key={idx} className='review-approval-route-outer-li'>
                    <Group style={{ color: 'blue' }} />
                    {idx + 1}: {signer.NAME} <span style={{ color: groupStatusColor }}>{groupStatus}</span>
                    <ul>
                        {people}
                    </ul>
                </li>
            );
        }
        return undefined; // TypeScript syntax check
    });

    const committeeApprovalGroup = props.committeeApprovalGroups.map((committeeGroup: COMMITTEE[], idx: number) => {

        let containsBoard = false;
        let committeeGroupStatus = '';
        let committeeGroupStatusColor = '';

        if (props.isActive) {
            if (props.requestStatus.committees.lastCompletedPhase >= idx) {
                committeeGroupStatus = ' - Complete';
                committeeGroupStatusColor = 'green';
            } else if (props.requestStatus.committees.activePhase === idx) {
                committeeGroupStatus = ' - In progress';
                committeeGroupStatusColor = 'lime';
            } else if (props.requestStatus.committees.notifiedPhase === idx) {
                committeeGroupStatus = ' - Notified';
                committeeGroupStatusColor = 'dodgerblue';
            }
        }

        const committees = committeeGroup.map((committee: COMMITTEE, innerIdx: number) => {

            if (props.ratification && committee.IS_BOARD) {
                containsBoard = true;
            }

            let committeeStatus = '';
            let committeeStatusColor = 'black';
            // let committeeTitleText = '';

            if (props.isActive) {
                if (props.requestStatus.committees[committee.COMMITTEE_ID]) {
                    const committeeRequestStatus = props.requestStatus.committees[committee.COMMITTEE_ID];

                    if (committeeRequestStatus === 'Approved' || committeeRequestStatus === 'Approved with Edits') {
                        committeeStatusColor = 'green';
                        committeeStatus = '(Approved)';
                        // committeeTitleText = `${committee.NAME} has fulfilled their approval request.`
                    }
                    else if (committeeRequestStatus === 'Requested Edits') {
                        committeeStatusColor = 'darkorange';
                        committeeStatus = '(Requested Edits)';
                        // committeeTitleText = `${committee.NAME} has requested edits by the owner.`
                    }
                    else if (committeeRequestStatus === 'Scheduled') {
                        committeeStatusColor = 'brown';
                        committeeStatus = `(Scheduled ${props.scheduledTaskDates[committee.COMMITTEE_ID]})`;
                        // committeeTitleText = `${committee.NAME} has scheduled their approval request.`
                    }
                    else if (committeeRequestStatus === 'Notified') {
                        committeeStatusColor = 'dodgerblue';
                        committeeStatus = '(Notified)';
                        // committeeTitleText = `${committee.NAME} has been notified of their approval request.`
                    }
                }
            }

            return (
                <li key={innerIdx} className='review-approval-route-inner-li'>
                    <img src={adjourn} className="approval-route-editor-gavel-icon" alt="gavel icon" />
                    {committee.NAME}
                    {committee.IS_BOARD ? <span style={{ color: 'grey' }}> (Board)</span> : null}
                    <span style={{ color: committeeStatusColor }}> {committeeStatus}</span>
                </li>
            );
        });


        return (
            <li key={idx} className='review-approval-route-outer-li'>
                Group {idx + 1}
                {containsBoard ? <span style={{ color: 'grey' }}> (Board Ratification)</span> : null}
                <span style={{ color: committeeGroupStatusColor }}>{committeeGroupStatus}</span>
                <ul>
                    {committees}
                </ul>
            </li>
        );
    });

    // the following const is used to simplify the conditional rendering, based on where
    // the signatures should appear; before committees, or after.
    const displaySignatureApprovalGroup = (
        <div>
            <h2 className='review-approval-route-phase-heading'>
                Signatures:
                {props.isActive && props.phaseStatus.signers ?
                    ` ${props.phaseStatus.signers}` :
                    null
                }
                {props.orderedSigning ? ' (Ordered)' : ''}
            </h2>
            <ul className='review-approval-route-outer-ul'>
                {signatureApprovalGroup}
            </ul>
        </div>
    );

    return (
        <div className='review-approval-route'>

            {props.phaseOrder[0] && props.phaseOrder[0] === 'signers' &&
                displaySignatureApprovalGroup
            }

            {props.showCommitteeApprovals &&
                <div>
                    <h2 className='review-approval-route-phase-heading'>
                        Committee Approvals:
                        <span>{
                            props.isActive && props.phaseStatus.committees ?
                                ` ${props.phaseStatus.committees}` :
                                null
                        }</span>
                    </h2>
                    <ul className='review-approval-route-outer-ul'>
                        {committeeApprovalGroup}
                    </ul>
                </div>
            }

            {props.phaseOrder[1] && props.phaseOrder[1] === 'signers' &&
                displaySignatureApprovalGroup
            }

            {!props.phaseOrder.length &&
                <p style={{ color: 'grey' }}>[No approval route set]</p>
            }

        </div>
    );
}

export default ReviewApprovalRoute;

function isPerson(potentialPerson: PERSON | USERGROUP | unknown): potentialPerson is PERSON {
    return (potentialPerson as PERSON).USERID !== undefined;
}
function isUserGroup(potentialUsergroup: PERSON | USERGROUP | unknown): potentialUsergroup is USERGROUP {
    return (potentialUsergroup as USERGROUP).USERGROUPID !== undefined;
}

