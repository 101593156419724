import React, { useContext, useEffect, useState } from 'react';
import { FeatureFlagClient, FeatureFlagMap } from './FeatureFlagClient';
import FeatureFlagMapDisplay from './FeatureFlagMapDisplay';
import FeatureFlagModal from './FeatureFlagModal';
import ChangeFeatureModal from './ChangeFeature/ChangeFeatureModal';
import { TextField, Button, makeStyles, Select, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import { sleep } from '../../../_shared/utils/jsUtils/sleep';
import { ORGANIZATION } from '../../../entities/master/ORGANIZATION';
import axios from 'axios';
import UserBundleContext from '@/context/UserBundleContext';

const useStyles = makeStyles({
    dialogPaper: {
        minWidth: '50%',
        minHeight: '75%',
    },
    selectContainer: {
        marginTop: '10px',
    },
});

export function FeatureFlagManagement() {
    const [ featureFlagFile, setFeatureFlagFile ] = useState<string>('');
    const [ organizations, setOrganizations ] = useState<ORGANIZATION[]>([]);
    const [ loading, setLoading ] = useState(true);
    const [ enableForAllModalOpen, setEnableForAllModalOpen ] = useState(false);
    const [ removeFeatureModalOpen, setRemoveFeatureModalOpen ] = useState(false);
    const [ enableFeatureModalOpen, setEnableFeatureModalOpen ] = useState(false);
    const [ disableFeatureModalOpen, setDisableFeatureModalOpen ] = useState(false);
    const [ queryFeatureModalOpen, setQueryFeatureModalOpen ] = useState(false);
    const [ resyncFeaturesModalOpen, setResyncFeaturesModalOpen ] = useState(false);

    const classes = useStyles();

    const fetchFeatureFlagFile = () => {
        return sleep(1000)
            .then(() => FeatureFlagClient.getFeatureFlagFile())
            .then((newFeatureFlagFile) => {
                setFeatureFlagFile(JSON.stringify(newFeatureFlagFile));
            })
            .catch((err) => console.log(err));
    };

    const fetchOrganizations = () => {
        return axios.get('/api/_shared/org/get-organizations')
            .then((res) => {
                setOrganizations(res.data.organizations);
            })
            .catch((err) => console.log(err));
    };

    const handleEnableFeatureForAll = (feature: string) => {
        setLoading(true);

        FeatureFlagClient.enableFeatureForAll(feature)
            .then(() => fetchFeatureFlagFile())
            .catch((err) => console.log(err))
            .finally(() => setLoading(false));
    };

    const handleRemoveFeature = (feature: string) => {
        setLoading(true);

        FeatureFlagClient.removeFeature(feature)
            .then(() => fetchFeatureFlagFile())
            .catch((err) => console.log(err))
            .finally(() => setLoading(false));
    };

    const handleChangeFeature = (feature: string, ids: string[], enabled: boolean) => {
        setLoading(true);

        FeatureFlagClient.setFeature(feature, ids, enabled)
            .then(() => fetchFeatureFlagFile())
            .catch((err) => console.log(err))
            .finally(() => setLoading(false));
    };

    const handleQueryFeature = (feature: string, id: string) => {
        setLoading(true);

        const org = organizations.find(org => org.ORGANIZATIONID === parseInt(id));
        const name = org ? org.NAME : 'Unknown';

        FeatureFlagClient.queryFeature(feature, id)
            .then(hasAccess => {
                alert(`Feature ${feature} is ${hasAccess ? 'enabled' : 'disabled'} for (${name}, ${id})`);
            })
            .catch((err) => {
                const responseData = err.response.data;
                const message = [
                    err.message,
                    responseData.error ?? '',
                    responseData.failure ?? '',
                ].join('\n');
                alert(`Error querying feature ${feature} for (${name}, ${id}): ${message}`);
            })
            .finally(() => setLoading(false));
    };

    const handleResyncFeatures = () => {
        setLoading(true);

        FeatureFlagClient.resyncFeatureFlagFile()
            .catch((err) => console.log(err))
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        setLoading(true);
        fetchFeatureFlagFile()
            .then(() => fetchOrganizations())
            .finally(() => setLoading(false));
    }, []);

    const orgFromIDMap = organizations.reduce((acc, org) => {
        acc[org.ORGANIZATIONID] = org;
        return acc;
    }, {} as [number: ORGANIZATION]);

    const userBundle = useContext(UserBundleContext);

    const isSysAdmin = userBundle.user?.RIGHTSINENGLISH?.systemAdministrator;

    if (!isSysAdmin) {
        return <p>You do not have permission to view this page. SystemAdmins only.</p>;
    }

    if (loading) {
        return <p>Loading...</p>;
    }

    const featureFlagMap: FeatureFlagMap = JSON.parse(featureFlagFile);
    return (
        <>
            <h1>Feature Flag Management</h1>
            <p>This is where we can monitor Lucidoc's active feature flags in real-time.</p>

            <div>
                <Button variant='contained' onClick={() => setEnableFeatureModalOpen(true)}>Enable a Feature</Button>
                <Button variant='contained' onClick={() => setDisableFeatureModalOpen(true)}>Disable a Feature</Button>
                <Button variant='contained' onClick={() => setRemoveFeatureModalOpen(true)}>Remove a Feature</Button>
                <Button variant='contained' onClick={() => setEnableForAllModalOpen(true)}>Enable Feature for All</Button>
                <Button variant='contained' onClick={() => setQueryFeatureModalOpen(true)}>Query a Feature</Button>
                <Button variant='contained' onClick={() => setResyncFeaturesModalOpen(true)}>Resync Feature Flags</Button>
            </div>

            <FeatureFlagMapDisplay featureFlagMap={featureFlagMap} organizationMap={orgFromIDMap} />

            <FeatureFlagModal open={enableForAllModalOpen} onClose={() => setEnableForAllModalOpen(false)}
                title={'Enable a Feature for All'}
                onSave={(formData) => {
                    const feature: string = formData.get('feature') as string;
                    handleEnableFeatureForAll(feature);
                }}
            >
                <p>Enables a feature for ALL customers.</p>
                <TextField autoFocus required fullWidth variant="standard" margin="dense" type="text"
                    id="feature"
                    name="feature"
                    label="Feature"
                />
            </FeatureFlagModal>

            <FeatureFlagModal open={removeFeatureModalOpen} onClose={() => setRemoveFeatureModalOpen(false)}
                title={'Remove a Feature'}
                onSave={(formData) => {
                    const feature: string = formData.get('feature') as string;
                    handleRemoveFeature(feature);
                }}
            >
                <p>Removes a feature.</p>
                <TextField autoFocus required fullWidth variant="standard" margin="dense" type="text"
                    id="feature"
                    name="feature"
                    label="Feature"
                />
            </FeatureFlagModal>

            <ChangeFeatureModal
                open={enableFeatureModalOpen}
                onClose={() => setEnableFeatureModalOpen(false)}
                title={'Enable a Feature'}
                organizations={organizations}
                onSave={(formData) => {
                    const feature = formData.get('feature') as string;
                    const rawIDs = formData.get('ids') as string;
                    const ids = rawIDs.split(',').filter(id => id !== '');

                    handleChangeFeature(feature, ids, true);
                }}
            />

            <ChangeFeatureModal
                open={disableFeatureModalOpen}
                onClose={() => setDisableFeatureModalOpen(false)}
                title={'Disable a Feature'}
                organizations={organizations}
                onSave={(formData) => {
                    const feature = formData.get('feature') as string;
                    const rawIDs = formData.get('ids') as string;
                    const ids = rawIDs.split(',');

                    handleChangeFeature(feature, ids, false);
                }}
            />

            <FeatureFlagModal open={queryFeatureModalOpen} onClose={() => setQueryFeatureModalOpen(false)}
                paperClass={classes.dialogPaper}
                title={'Query a Feature'}
                onSave={(formData) => {
                    const feature = formData.get('feature') as string;
                    const id = formData.get('organization') as string;

                    handleQueryFeature(feature, id);
                }}
            >
                <p>Quickly test if a feature is enabled for a user.</p>
                <TextField required fullWidth variant="standard" margin="dense" type="text"
                    id="feature"
                    name="feature"
                    label="Feature"
                />
                <FormControl className={classes.selectContainer} fullWidth>
                    <InputLabel variant="standard" id="organization">Organization</InputLabel>
                    <Select
                        required
                        fullWidth
                        variant="standard"
                        margin="dense"
                        labelId='organization'
                        id="organization"
                        name="organization"
                        placeholder='Organization'
                    >
                        {organizations.map(org => (
                            <MenuItem key={org.ORGANIZATIONID} value={org.ORGANIZATIONID}>{org.NAME}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </FeatureFlagModal>

            <FeatureFlagModal open={resyncFeaturesModalOpen} onClose={() => setResyncFeaturesModalOpen(false)}
                title={'Resync Feature Flags'}
                onSave={() => handleResyncFeatures()}
            >
                <p>This will resync the dev feature flags with the production feature flags.</p>
                <p>Are you sure you want to do this?</p>
            </FeatureFlagModal>
        </>
    );
}