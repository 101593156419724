import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import axios from 'axios';
import produceFlattenedOptionsFromNestedInformationHierarchy, { flattenedInformationHierarchySelectMenuOption } from '../../utils/produceFlattenedOptionsFromNestedInformationHierarchy';
import { type PERSON } from '../../entities/master/PERSON';
import { makeStyles, Checkbox, FormControlLabel } from '@material-ui/core';
import UserPicker from '../UserPicker/UserPicker';
import { DragDropContext } from 'react-beautiful-dnd';
import { USERGROUP } from '../../entities/org/USERGROUP';
import { DOCUMENT_TYPE_PL } from '../../entities/org/DOCUMENT_TYPE_PL';
import LucidocColors from '../../constants/LucidocColors';
import { createInformationHierarchyEntity } from '../../entities/org/INFORMATION_HIERARCHY';
import { LoadingIndicator } from '../_shared/LoadingIndicator';
import { LoadingStatuses } from '../../utils/LoadingStatuses';
import SaveBar from '../_shared/SaveBar/SaveBar';
import H3WithHelpTip from '../_shared/Forms/H3WithHelpTip';
import { redirectToHomepage } from '../../utils/savebarUtilities';
import { schemaEntityManager } from '../../utils/emInstance';

const useStyles = makeStyles({
    contractsAdmin: {
        padding: '1rem',
        width: '532px',
        boxSizing: 'border-box',
        '& button': {
            marginTop: '1rem'
        }
    },
    displayFlexAlignCenter: {
        display: 'flex',
        alignItems: 'center'
    },
    selectMenuContainer: {
        marginLeft: '.5rem',
        flexGrow: 5
    },
    dividerLineDiv: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '1rem',
        marginBottom: '1rem',
        gap: '1rem',
        paddingBottom: '1rem',
        borderBottom: `1px solid ${LucidocColors.gray}`,
    },
    blueText: {
        fontWeight: 'bold',
        color: LucidocColors.purple
    },
    greyText: {
        color: LucidocColors.gray
    },
    selectContractRoutersContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
    }
});

export default function ContractsAdmin() {
    const classes = useStyles();

    const [ loadingStatus, setLoadingStatus ] = useState(LoadingStatuses.LOADING);

    const [ doesOrgAllowDocRequests, setDoesOrgAllowDocRequests ] = useState<boolean>(false);

    const [ allContractDocTypes, setAllContractDocTypes ] = useState<DOCUMENT_TYPE_PL[]>([]);
    const [ departmentsProcessedForSelectMenu, setDepartmentsProcessedForSelectMenu ] = useState<flattenedInformationHierarchySelectMenuOption[]>([]);

    const [ docRequestHandlers, setDocRequestHandlers ] = useState<(PERSON | USERGROUP)[]>([]);

    async function getInitialData() {
        setLoadingStatus(LoadingStatuses.LOADING);
        const res = await axios.get('/api/doc-manager/contracts/admin/get-initial-data');
        if (res.data.success) {
            const {
                doesOrgAllowDocRequests,
                allContractDocTypes,
                departments,
                docRequestHandlers
            } = res.data.success;

            setLoadingStatus(LoadingStatuses.READY);
            setDoesOrgAllowDocRequests(doesOrgAllowDocRequests);
            setAllContractDocTypes(allContractDocTypes);
            setDepartmentsProcessedForSelectMenu(
                produceFlattenedOptionsFromNestedInformationHierarchy(departments)
            );
            setDocRequestHandlers(docRequestHandlers);
        }
    }

    useEffect(() => {
        getInitialData().then();
    }, []);

    if (loadingStatus !== LoadingStatuses.READY) {
        return (
            <LoadingIndicator
                loadingStatus={loadingStatus}
            />
        );
    }

    function handleDocTypeReceivingDeptUpdate(docTypeID: number, targetDeptID: number, targetDeptName: string) {
        const newContractDocTypes = allContractDocTypes.slice();
        const chosenContractDocType = allContractDocTypes.find((docTypeInArray) => docTypeInArray.DOCTYPE_ID === docTypeID);
        if (chosenContractDocType) {
            // this value is a database column, which we'll use to update on the back end:
            chosenContractDocType.DOC_REQUEST_RECEIVING_DEPARTMENT_ID = targetDeptID;
            // these two values are the value/label combination used in the select menu, to update the UI:
            if (!chosenContractDocType.DOC_REQUEST_RECEIVING_DEPARTMENT) {
                chosenContractDocType.DOC_REQUEST_RECEIVING_DEPARTMENT = new (createInformationHierarchyEntity('', schemaEntityManager));
            }
            chosenContractDocType.DOC_REQUEST_RECEIVING_DEPARTMENT.INFORMATION_HIERARCHY_ID = targetDeptID;
            chosenContractDocType.DOC_REQUEST_RECEIVING_DEPARTMENT.TITLE = targetDeptName;

            setAllContractDocTypes(newContractDocTypes);
        }
    }

    async function saveChanges() {
        setLoadingStatus(LoadingStatuses.SAVING);
        await axios.post(
            '/api/doc-manager/contracts/admin/save-changes',
            {
                doesOrgAllowDocRequests: doesOrgAllowDocRequests,
                allContractDocTypes: allContractDocTypes,
                docRequestHandlers: docRequestHandlers
            }
        );
        await getInitialData();
    }

    const contractDocTypeTargetDepartmentSelectMenus = allContractDocTypes.map((doctype, idx) => (
        <div
            key={idx}
            className={classes.dividerLineDiv}
        >
            <p>
                <span className={classes.blueText}>DocType: </span>"{doctype.DESCRIPTION}"
            </p>
            <div className={classes.displayFlexAlignCenter}>
                <p className={classes.blueText}>
                    Default Department:
                </p>
                <div className={classes.selectMenuContainer}>
                    <Select
                        value={doctype.DOC_REQUEST_RECEIVING_DEPARTMENT && {
                            label: doctype.DOC_REQUEST_RECEIVING_DEPARTMENT.TITLE,
                            value: doctype.DOC_REQUEST_RECEIVING_DEPARTMENT.INFORMATION_HIERARCHY_ID
                        }}
                        options={departmentsProcessedForSelectMenu}
                        onChange={(department: any) => {
                            handleDocTypeReceivingDeptUpdate(doctype.DOCTYPE_ID, department.value, department.label);
                        }}
                    />
                </div>
            </div>
        </div>
    ));

    return (
        <>

            <div className={classes.contractsAdmin}>

                <div className={`${classes.dividerLineDiv}`}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={doesOrgAllowDocRequests}
                                onChange={() => setDoesOrgAllowDocRequests(!doesOrgAllowDocRequests)}
                                style={{ color: LucidocColors.purple }}
                            />
                        }
                        label={
                            <span style={{ fontFamily: 'Quattrocento Sans' }}>
                                Allow users to request contracts
                            </span>
                        }
                    />
                </div>

                <H3WithHelpTip
                    helpText={`Documents in Lucidoc must belong to a department. Please choose a default department
                    for each document type. Documents can be transferred to new departments after document creation.`}
                    text={'Select Default Department for each Document Type:'}
                />

                {contractDocTypeTargetDepartmentSelectMenus}

                <div className={classes.selectContractRoutersContainer}>
                    <H3WithHelpTip
                        helpText={'Contract routers can see and manage contract requests while they are still pending assignment.'}
                        text={'Select contract routers:'}
                    />

                    <DragDropContext onDragEnd={() => {}}>
                        <UserPicker
                            getUsers={true}
                            addUserSignature={(newUsers: (PERSON | USERGROUP)[]) => setDocRequestHandlers(newUsers)}
                            removeUserSignature={(newUsers: (PERSON | USERGROUP)[]) => setDocRequestHandlers(newUsers)}
                            selectedUsersPlusGroups={docRequestHandlers}
                            isDragDisabled={true}
                        />
                    </DragDropContext>
                </div>

            </div>

            <SaveBar
                onSave={() => saveChanges()}
                onCancel={() => getInitialData()}
                onClose={() => redirectToHomepage()}
            />

        </>
    );
}
