import {
    Entity,
    JoinColumn,
    ManyToOne,
    OneToOne,
    PrimaryColumn
} from 'typeorm';
import { LINK } from './LINK';
import { PERSON } from './PERSON';
import { SchemaEntityManager } from '../SchemaEntityManager';

/**
 * To completely create a link, you must create one of these records, AND ALSO a record
 *  in the DOCUMENTREVISION_LINK table (stored in each org schema, not master).
 * 
 * @param schema - This schema is the organization schema. PERSON_LINK has a decorator specifying schema: MASTER, but still has relations to a parameter specified organization schema
 * 
 * @returns 
 */

function createPersonLinkEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('PERSON_LINK', { schema: 'MASTER' })
    class PERSON_LINK {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            name: 'USERID'
        })
        USERID!: number;

        @ManyToOne(
            () => manager.getPersonEntity(schema),
            (person) => person.PERSON_LINKS
        )
        @JoinColumn({
            name: 'USERID'
        })
        USER!: PERSON | null;

        @PrimaryColumn('number', {
            nullable: false,
            name: 'LINK_ID'
        })
        LINK_ID!: number;

        @OneToOne(
            () => manager.getLinkEntity(schema),
            (link) => link.PERSON_LINK
        )
        @JoinColumn({
            name: 'LINK_ID'
        })
        LINK!: LINK | null;
    }

    return PERSON_LINK;
}

export { createPersonLinkEntity };
export type PERSON_LINK = InstanceType<ReturnType<typeof createPersonLinkEntity>>;