import React from 'react';

interface CheckOutlineIconProps {
    size?: number;
}

export const CheckOutlineIcon: React.FC<CheckOutlineIconProps> = ({ size = 8 }) => {
    return (
        <svg width={size.toString()} height={size.toString()} viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.5 6.4L0.160299 4.97099C0.0701446 4.87482 0.0701444 4.72518 0.160299 4.62901L1.31762 3.39454C1.41638 3.28919 1.58362 3.28919 1.68238 3.39454L3 4.8L6.32784 0.236103C6.41828 0.112078 6.59808 0.0980821 6.70662 0.20662L7.84478 1.34478C7.93358 1.43358 7.94273 1.57446 7.86616 1.67399L3.17935 7.76684C3.08536 7.88903 2.90425 7.89787 2.79881 7.7854L1.5 6.4Z" fill="white"/>
        </svg>
    );
};