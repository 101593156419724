import {
    Column,
    Entity,
    JoinColumn,
    ManyToOne,
    OneToMany,
    PrimaryColumn,
} from 'typeorm';

import { type DOCUMENT,  } from './DOCUMENT';
import { SIGNATURE_SESSION,  } from './SIGNATURE_SESSION';
import { APPROVAL_SESSION,  } from './APPROVAL_SESSION';
import { EDIT_REQUEST,  } from './EDIT_REQUEST';
import { COMMITTEE_APPROVAL_SESSION } from './COMMITTEE_APPROVAL_SESSION';
import { LOCKED_FOR_EDITS,  } from './LOCKED_FOR_EDITS';
import { DOCUMENT_TYPE_PL,  } from './DOCUMENT_TYPE_PL';
import { PERSON,  } from '../master/PERSON';
import { DOCUMENTREVISION_LINK,  } from './DOCUMENTREVISION_LINK';
import { TEMPLATE,  } from './TEMPLATE';
import { SUB_REVISION,  } from './SUB_REVISION';
import { GLOBAL_IDENTIFIER,  } from './GLOBAL_IDENTIFIER';
import { SIGNERASSIGNMENT,  } from './SIGNERASSIGNMENT';
import { APPROVAL_ROUTE_TEMPLATE,  } from './APPROVAL_ROUTE_TEMPLATE';
import { TRACKING,  } from './TRACKING';
import { COMMITTEE_ROUTING_LOG_DETAIL,  } from './COMMITTEE_ROUTING_LOG_DETAIL';
import { INVENTORY } from '../multiorg/INVENTORY';
import { ORGANIZATION } from '../master/ORGANIZATION';
import { SchemaEntityManager } from '../SchemaEntityManager';
import { FEEDBACK } from './FEEDBACK';
import { DOCUMENT_REVISION_LIMITS } from '../Limits';

export enum DocumentRevisionStatuses {
    prep                       = 200,
    draft                      = 201,
    official                   = 202,
    obsolete                   = 204,
    invisible                  = 206,
    archive                    = 207,
    prepsig                    = 208,
    discard                    = 209,
    preprel                    = 211,
    pending_committee_routing  = 213,
    pending_committee_approval = 214,
    pending_assignment         = 215,
    pending_approval_routing   = 216,
}

export const DisplayStatuses: {[key:string]: string} = {
    [DocumentRevisionStatuses.prep]                       : 'In Prep',
    [DocumentRevisionStatuses.draft]                      : 'Draft',
    [DocumentRevisionStatuses.official]                   : 'Official',
    [DocumentRevisionStatuses.obsolete]                   : 'Obsolete',
    [DocumentRevisionStatuses.invisible]                  : 'Invisible',
    [DocumentRevisionStatuses.archive]                    : 'Archive',
    [DocumentRevisionStatuses.prepsig]                    : 'Pending Signature',
    [DocumentRevisionStatuses.discard]                    : 'Discard',
    [DocumentRevisionStatuses.preprel]                    : 'Pending Release',
    [DocumentRevisionStatuses.pending_committee_routing]  : 'Pending Committee Routing',
    [DocumentRevisionStatuses.pending_committee_approval] : 'Pending Committee Approval',
    [DocumentRevisionStatuses.pending_assignment]         : 'Pending Assignment',
    [DocumentRevisionStatuses.pending_approval_routing]   : 'Pending Approval Routing',
};

export type DisplayStatusValues = typeof DisplayStatuses[keyof typeof DisplayStatuses];


export const DocRevActiveStatuses = [
    DocumentRevisionStatuses.prep,
    DocumentRevisionStatuses.draft,
    DocumentRevisionStatuses.official,
    DocumentRevisionStatuses.prepsig,
    DocumentRevisionStatuses.preprel,
    DocumentRevisionStatuses.pending_committee_routing,
    DocumentRevisionStatuses.pending_committee_approval,
    DocumentRevisionStatuses.pending_assignment
];

function createDocumentRevisionEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('DOCUMENTREVISION', { schema: schema })
    class DOCUMENTREVISION {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'DOCREVID'
        })
        DOCREVID!: number;
    
        @Column('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'DOCID'
        })
        DOCID!: number;
    
        @ManyToOne(
            () => manager.getDocumentEntity(schema), 
            (document) => document.REVISIONS
        )
        @JoinColumn({
            name: 'DOCID'
        })
        DOCUMENT!: DOCUMENT;
    
        @Column('varchar2', {
            nullable: false,
            length: 6,
            name: 'REVNO'
        })
        REVNO!: string;
    
        @Column('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'USERID'
        })
        USERID!: number;
    
        @ManyToOne(
            () => manager.getPersonEntity(schema), 
            (person) => person.DOCUMENTREVISIONS
        )
        @JoinColumn({
            name: 'USERID'
        })
        DOCREV_OWNER!: PERSON | null;
    
        @Column('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'REVBY_USERID'
        })
        REVBY_USERID!: number;
    
        @Column('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'DOCSTATUS_ID'
        })
        DOCSTATUS_ID!: number;
    
        @Column('timestamp', {
            nullable: false,
            name: 'CREATEDT'
        })
        CREATEDT!: Date;
    
        @Column('timestamp', {
            nullable: true,
            name: 'DRAFTISSUEDT'
        })
        DRAFTISSUEDT!: Date | null;
    
        @Column('timestamp', {
            nullable: true,
            name: 'OFFICIALDT'
        })
        OFFICIALDT!: Date | null;
    
        @Column('timestamp', {
            nullable: true,
            name: 'OBSOLETEDT'
        })
        OBSOLETEDT!: Date | null;
    
        @Column('timestamp', {
            nullable: true,
            name: 'EXPIRATIONDT'
        })
        EXPIRATIONDT!: Date | null;
    
        @Column('timestamp', {
            nullable: true,
            name: 'NEXTREVDT'
        })
        NEXTREVDT!: Date | null;
    
        @Column('timestamp', {
            nullable: true,
            name: 'INACTIVEDT'
        })
        INACTIVEDT!: Date | null;
    
        @Column('timestamp', {
            nullable: true,
            name: 'EFFECTIVEDT'
        })
        EFFECTIVEDT!: Date | null;
    
        @Column('number', {
            nullable: true,
            precision: 1,
            scale: 0,
            name: 'DEFAULT_REVIEW'
        })
        DEFAULT_REVIEW!: number | null;
        
        @Column('number', {
            nullable: true,
            precision: 1,
            scale: 0,
            name: 'DEFAULT_NOTIFY'
        })
        DEFAULT_NOTIFY!: number | null;
    
        @Column('number', {
            nullable: true,
            precision: 1,
            scale: 0,
            name: 'DEFAULT_ASSESS'
        })
        DEFAULT_ASSESS!: number | null;
    
        @Column('number', {
            nullable: true,
            precision: 1,
            scale: 0,
            name: 'REVIEW_CYCLE'
        })
        REVIEW_CYCLE!: number | null;
    
        @Column('number', {
            nullable: true,
            precision: 1,
            scale: 0,
            name: 'EFFECTIVE_DT_TYPE'
        })
        EFFECTIVE_DT_TYPE!: number | null;
    
        @Column('number', {
            nullable: true,
            precision: 1,
            scale: 0,
            name: 'EXPIRE_WARNING'
        })
        EXPIRE_WARNING!: number | null;
    
        @Column('number', {
            nullable: true,
            precision: 1,
            scale: 0,
            name: 'REVIEW_WARNING'
        })
        REVIEW_WARNING!: number | null;
    
        @Column('varchar2', {
            nullable: true,
            length: DOCUMENT_REVISION_LIMITS.TITLE_LENGTH,
            name: 'TITLE'
        })
        TITLE!: string | null;
    
        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'PRIMARY_BLOBID'
        })
        PRIMARY_BLOBID!: number | null;
    
        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'LASTMODBY_USERID'
        })
        LASTMODBY_USERID!: number | null;
    
        @Column('timestamp', {
            nullable: true,
            name: 'LASTMODDT'
        })
        LASTMODDT!: Date | null;
    
        @Column('number', {
            nullable: true,
            name: 'HITCOUNT'
        })
        HITCOUNT!: number | null;
    
        @Column('char', {
            nullable: true,
            name: 'SUPRESSNOTIFY'
        })
        SUPRESSNOTIFY!: string | null;
    
        @Column('varchar2', {
            nullable: true,
            length: DOCUMENT_REVISION_LIMITS.REVISION_NOTE_LENGTH,
            name: 'REVISION_NOTE'
        })
        REVISION_NOTE!: string | null;
    
        @Column('varchar2', {
            nullable: true,
            length: DOCUMENT_REVISION_LIMITS.KEYWORDS_LENGTH,
            name: 'KEYWORD'
        })
        KEYWORD!: string | null;
    
        @Column('number', {
            nullable: true,
            precision: 5,
            scale: 0,
            name: 'ORDEREDREVIEW'
        })
        ORDEREDREVIEW!: number | null;
    
        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'ORGCHARTID'
        })
        ORGCHARTID!: number | null;
    
        @Column('varchar2', {
            nullable: true,
            length: 4000,
            name: 'ABSTRACT'
        })
        ABSTRACT!: string | null;
    
        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'XMD_TEMPLATEID'
        })
        XMD_TEMPLATEID!: number | null;
    
        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'TEMPLATEID'
        })
        TEMPLATEID!: number | null;
        
        @ManyToOne(
            () => manager.getTemplateEntity(schema), 
            (template) => template.DOCUMENTREVISIONS
        )
        @JoinColumn({
            name: 'TEMPLATEID'
        })
        TEMPLATE!: TEMPLATE | null;
    
        @Column('char', {
            nullable: false,
            default: () => '0',
            name: 'GATHER_APPROVALS_ON_REVIEW'
        })
        GATHER_APPROVALS_ON_REVIEW!: string;
    
        @Column('clob', {
            nullable: true,
            name: 'XMD'
        })
        XMD!: string | null;
    
        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'LOCKEDBY'
        })
        LOCKEDBY!: number | null;
    
        @Column('timestamp', {
            nullable: true,
            name: 'LOCKEDDT'
        })
        LOCKEDDT!: Date | null;
    
        @Column('char', {
            nullable: true,
            name: 'ARCHIVE_ON_EXPIRATION'
        })
        ARCHIVE_ON_EXPIRATION!: string | null;
    
        @Column('number', {
            nullable: true,
            name: 'DELAYED_RATIFICATION'
        })
        DELAYED_RATIFICATION!: number | null;
    
        @Column('number', {
            nullable: true,
            name: 'COMMITTEE_APPROVAL_ON_REVIEW'
        })
        COMMITTEE_APPROVAL_ON_REVIEW!: number | null;
    
        @Column('varchar2', {
            nullable: true,
            length: 200,
            name: 'OWNER_NAME_ON_OFFICIAL'
        })
        OWNER_NAME_ON_OFFICIAL!: string | null;
    
        @Column('varchar2', {
            nullable: true,
            length: 200,
            name: 'OWNER_TITLE_ON_OFFICIAL'
        })
        OWNER_TITLE_ON_OFFICIAL!: string | null;
    
        @Column('varchar2', {
            nullable: true,
            length: 64,
            name: 'ORGANIZATION_GXML_ON_OFFICIAL'
        })
        ORGANIZATION_GXML_ON_OFFICIAL!: string | null;
    
        @Column('varchar2', {
            nullable: true,
            length: 64,
            name: 'TEMPLATE_GXML_ON_OFFICIAL'
        })
        TEMPLATE_GXML_ON_OFFICIAL!: string | null;
    
        @Column('number', {
            nullable: true,
            name: 'DOCTYPE_ID'
        })
        DOCTYPE_ID!: number | null;
    
        @ManyToOne(
            () => manager.getDocumentTypePLEntity(schema), 
            (documentTypePL) => documentTypePL.DOCUMENTREVISIONS
        )
        @JoinColumn({
            name: 'DOCTYPE_ID'
        })
        DOCTYPE!: DOCUMENT_TYPE_PL | null;
    
        @Column('varchar2', {
            nullable: true,
            length: 500,
            name: 'REVIEW_CYCLE_JUSTIFICATION'
        })
        REVIEW_CYCLE_JUSTIFICATION!: string | null;
    
        @Column('char', {
            nullable: false,
            default: () => '0',
            name: 'GATHER_APPROVALS_ON_EXPIRATION'
        })
        GATHER_APPROVALS_ON_EXPIRATION!: string;
    
        @Column('timestamp', {
            nullable: true,
            name: 'STASHED_NEXTREVDT'
        })
        STASHED_NEXTREVDT!: Date | null;
    
        @Column('number', {
            nullable: true,
            name: 'STASHED_REVIEW_WARNING'
        })
        STASHED_REVIEW_WARNING!: number | null;
    
        @Column('number', {
            nullable: true,
            name: 'BLOBID_ON_ARCHIVE'
        })
        BLOBID_ON_ARCHIVE!: number | null;
    
        @Column('number', {
            nullable: true,
            name: 'GLOBAL_IDENTIFIER_ID'
        })
        GLOBAL_IDENTIFIER_ID!: number | null;
    
        @ManyToOne(
            () => manager.getGlobalIdentifierEntity(schema),
            (globalIdentifier) => globalIdentifier.DOCUMENTREVISIONS
        )
        @JoinColumn({
            name: 'GLOBAL_IDENTIFIER_ID'
        })
        GLOBAL_IDENTIFIER!: GLOBAL_IDENTIFIER | null;
    
        INVENTORY!: INVENTORY | null;
    
        ORGANIZATION!: ORGANIZATION | null;
    
        @OneToMany(
            () => manager.getSignerAssignmentEntity(schema), 
            (signerAssignment) => signerAssignment.DOCUMENTREVISION
        )
        SIGNERASSIGNMENTS!: SIGNERASSIGNMENT[] | null;
    
        @OneToMany(
            () => manager.getSignatureSessionEntity(schema), 
            (signatureSession) => signatureSession.DOCUMENTREVISION
        )
        SIGNATURE_SESSIONS!: SIGNATURE_SESSION[] | null;
    
        @OneToMany(
            () => manager.getApprovalSessionEntity(schema), 
            (approvalSession) => approvalSession.DOCUMENTREVISION
        )
        APPROVAL_SESSIONS!: APPROVAL_SESSION[] | null;
    
        @OneToMany(
            () => manager.getApprovalRouteTemplateEntity(schema) ,
            (approvalRouteTemplate) => approvalRouteTemplate.DOCUMENTREVISION
        )
        APPROVAL_ROUTE_TEMPLATES!: APPROVAL_ROUTE_TEMPLATE[] | null;
    
        @OneToMany(
            () => manager.getEditRequestEntity(schema), 
            (editRequest) => editRequest.DOCUMENTREVISION
        )
        EDIT_REQUESTS!: EDIT_REQUEST[] | null;
    
        @OneToMany(
            () => manager.getCommitteeApprovalSessionEntity(schema), 
            (committeeApprovalSession) => committeeApprovalSession.DOCUMENTREVISION
        )
        COMMITTEE_APPROVAL_SESSIONS!: COMMITTEE_APPROVAL_SESSION[] | null;
    
        @OneToMany(
            () => manager.getLockedForEditsEntity(schema), 
            (lockedForEdits) => lockedForEdits.DOCUMENTREVISION
        )
        LOCKED_FOR_EDITS!: LOCKED_FOR_EDITS[] | null;
    
        @OneToMany(
            () => manager.getDocumentRevisionLinkEntity(schema), 
            (documentRevisionLink) => documentRevisionLink.DOCREV
        )
        DOCUMENTREVISION_LINKS!: DOCUMENTREVISION_LINK[] | null;
    
        @OneToMany(
            () => manager.getSubRevisionEntity(schema), 
            (subRevision) => subRevision.DOCREV
        )
        SUB_REVISIONS!: SUB_REVISION[] | null;
    
        @OneToMany(
            () => manager.getTrackingEntity(schema), 
            (tracking) => tracking.DOCREV
        )
        TRACKINGS!: TRACKING[] | null;
    
        @OneToMany(
            () => manager.getCommitteeRoutingLogDetailEntity(schema), 
            (committeeRoutingLogDetail) => committeeRoutingLogDetail.DOCUMENTREVISION
        )
        COMMITTEE_ROUTING_LOG_DETAILS!: COMMITTEE_ROUTING_LOG_DETAIL[] | null;

        @OneToMany(
            () => manager.getFeedbackEntity(schema), 
            (feedback) => feedback.DOCUMENTREVISION
        )
        @JoinColumn({
            name: 'DOCREVID'
        })
        FEEDBACKS!: FEEDBACK[] | null;
    
    }
    
    return DOCUMENTREVISION;
}


export function isDocumentRevision(docRev: unknown): docRev is DOCUMENTREVISION {
    return (docRev as DOCUMENTREVISION).DOCREVID !== undefined;
}

export { createDocumentRevisionEntity };
export type DOCUMENTREVISION = InstanceType<ReturnType<typeof createDocumentRevisionEntity>>;
