import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { ORGANIZATION } from '../../../entities/master/ORGANIZATION';

const useStyles = makeStyles({
    selectMenuAndTitleDiv: {
        display: 'flex',
        alignItems: 'center'
    },
    selectMenuDiv: {
        zIndex: 101,
        width: '300px',
        marginLeft: '.5rem'
    }
});

interface MultiOrgSelectMenuProps {
    selectedOrgID: number | undefined,
    setSelectedOrgID: (orgID: number) => void
    additionalValues?: {label: string, value: number | undefined}[]
}

export function MultiOrgSelectMenu(props: MultiOrgSelectMenuProps) {
    const classes = useStyles();

    const [ childOrgsForSelectMenu, setChildOrgsForSelectMenu ] = useState<{label: string, value: number | undefined}[]>([]);

    useEffect(() => {
        loadChildOrgs().then();
    }, []);

    async function loadChildOrgs() {
        const res = await axios.get('/api/_shared/multiorg/multiorg-select-menu');

        const formattedOrgsForSelectMenu = (props.additionalValues || [])
            .concat(
                ((res.data.childOrgs || []) as ORGANIZATION[]).map(org => {
                    return {
                        label: org.NAME || '',
                        value: org.ORGANIZATIONID as any // can't figure it out without the any
                    };
                })
            );

        setChildOrgsForSelectMenu(formattedOrgsForSelectMenu);
    }

    return (
        <div className={classes.selectMenuAndTitleDiv}>
            <div>Select Org:</div>
            <div className={classes.selectMenuDiv}>
                <Select
                    value={childOrgsForSelectMenu.find(org => org.value === props.selectedOrgID)}
                    onChange={(selectMenuOption: any) => props.setSelectedOrgID(selectMenuOption.value)}
                    options={childOrgsForSelectMenu}
                />
            </div>
        </div>
    );
}
