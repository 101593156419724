import { createSubscriberTemplateEntity } from './multiorg/SUBSCRIBER_TEMPLATE';
import { createAccreditationManagerAuditLogEntity } from './accreditationmanager/AUDIT_LOG';
import { createChecklistAuditEntity } from './accreditationmanager/CHECKLIST_AUDIT';
import { createChecklistConditionEntity } from './accreditationmanager/CHECKLIST_CONDITION';
import { createChecklistConditionAuditEntity } from './accreditationmanager/CHECKLIST_CONDITION_AUDIT';
import { createChecklistEditionEntity } from './accreditationmanager/CHECKLIST_EDITION';
import { ORGANIZATION, createOrganizationEntity } from './master/ORGANIZATION';
import { createChecklistEntity } from './accreditationmanager/CHECKLIST';
import { createCorrectiveActionPlanEntity } from './accreditationmanager/CORRECTIVE_ACTION_PLAN';
import { createInspectionEntity } from './accreditationmanager/INSPECTION';
import { createLinkEntity } from './master/LINK';
import { createMimeTypeEntity } from './master/MIME_TYPE';
import { createEditionEntity } from './multiorg/EDITION';
import { createInventoryEntity } from './multiorg/INVENTORY';
import { createPublicationEntity } from './multiorg/PUBLICATION';
import { createRepresentativeContactEntity } from './multiorg/REPRESENTATIVE_CONTACT';
import { createSearchUserEntity } from './multiorg/SEARCH_USER';
import { createSubscriptionEntity } from './multiorg/SUBSCRIPTION';
import { createAckRequestEntity } from './org/ACK_REQUEST';
import { createAckScheduleManualEntity } from './org/ACK_SCHEDULE_MANUAL';
import { createSamlProfileEntity } from './master/SAML_PROFILE';
import { createPersonOrganizationEntity } from './master/PERSON_ORGANIZATION';
import { createPersonLoginSessionEntity } from './master/PERSON_LOGIN_SESSION';
import { createPersonLinkEntity } from './master/PERSON_LINK';
import { createPersonEntity } from './master/PERSON';
import { createAckScheduleEntity } from './org/ACK_SCHEDULE';
import { createACLEntity } from './org/ACL';
import { createAllStoreEntity } from './org/ALL_STORE';
import { createApprovalRouteTemplateEntity } from './org/APPROVAL_ROUTE_TEMPLATE';
import { createApprovalSessionStepEntity } from './org/APPROVAL_SESSION_STEP';
import { createApprovalRouteTemplateLogEntity } from './org/APPROVAL_ROUTE_TEMPLATE_LOG';
import { createApprovalSessionEntity } from './org/APPROVAL_SESSION';
import { createAuditLogEntity } from './org/AUDIT_LOG';
import { createBatchJobEntity } from './org/BATCH_JOB';
import { createCeCourseAttemptEntity } from './org/CE_COURSE_ATTEMPT';
import { createCeCoursePrototypeEntity } from './org/CE_COURSE_PROTOTYPE';
import { createCeCourseScheduleEntity } from './org/CE_COURSE_SCHEDULE';
import { createCeCourseSessionEntity } from './org/CE_COURSE_SESSION';
import { createCeCourseSnapshotEntity } from './org/CE_COURSE_SNAPSHOT';
import { createCeEnrollmentSnapshotEntity } from './org/CE_ENROLLMENT_SNAPSHOT';
import { createCommitteeApprovalRequestEntity } from './org/COMMITTEE_APPROVAL_REQUEST';
import { createCommitteeApprovalSessionEntity } from './org/COMMITTEE_APPROVAL_SESSION';
import { createCommitteeDecisionLogEntity } from './org/COMMITTEE_DECISION_LOG';
import { createCommitteeMemberEntity } from './org/COMMITTEE_MEMBER';
import { createCommitteeRouterEntity } from './org/COMMITTEE_ROUTER';
import { createCommitteeRoutingLogDetailEntity } from './org/COMMITTEE_ROUTING_LOG_DETAIL';
import { createCommitteeRoutingLogEntity } from './org/COMMITTEE_ROUTING_LOG';
import { createCommitteeEntity } from './org/COMMITTEE';
import { createConsentAgendaAuditLogEntity } from './org/CONSENT_AGENDA_AUDIT_LOG';
import { createConsentAgendaGuestEntity } from './org/CONSENT_AGENDA_GUEST';
import { createConsentAgendaHeadingEntity } from './org/CONSENT_AGENDA_HEADING';
import { createConsentAgendaReferenceEntity } from './org/CONSENT_AGENDA_REFERENCE';
import { createConsentAgendaTaskEntity } from './org/CONSENT_AGENDA_TASK';
import { createConsentAgendaEntity } from './org/CONSENT_AGENDA';
import { createContactsEntity } from './org/CONTACTS';
import { createDocRequestHandlerEntity } from './org/DOC_REQUEST_HANDLER';
import { createDocRequestEntity } from './org/DOC_REQUEST';
import { createDocumentTypePLEntity } from './org/DOCUMENT_TYPE_PL';
import { createDocumentEntity } from './org/DOCUMENT';
import { createDocumentRevisionLinkEntity } from './org/DOCUMENTREVISION_LINK';
import { createDocumentRevisionEntity } from './org/DOCUMENTREVISION';
import { createEditRequestEntity } from './org/EDIT_REQUEST';
import { createFeedbackTypePLEntity } from './org/FEEDBACK_TYPE_PL';
import { createFieldEntity } from './org/FIELD';
import { createFieldTemplateEntity } from './org/FIELDTEMPLATE';
import { createGlobalIdentifierEntity } from './org/GLOBAL_IDENTIFIER';
import { createHomepageLinkEntity } from './org/HOMEPAGE_LINK';
import { createInformationHierarchyEntity } from './org/INFORMATION_HIERARCHY';
import { createLockedForEditsEntity } from './org/LOCKED_FOR_EDITS';
import { createInformationIndexEntity } from './org/INFORMATION_INDEX';
import { createMessageBoardItemEntity } from './org/MESSAGE_BOARD_ITEM';
import { createNotificationWindowEntity } from './org/NOTIFICATION_WINDOW';
import { createPublisherEntity } from './org/PUBLISHER';
import { createRemovedApprovalSessionStepEntity } from './org/REMOVED_APPROVAL_SESSION_STEP';
import { createReviewerAssignmentEntity } from './org/REVIEWERASSIGNMENT';
import { createRoutingConfigurationLogEntity } from './org/ROUTING_CONFIGURATION_LOG';
import { createRoutingConfigurationEntity } from './org/ROUTING_CONFIGURATION';
import { createSignatureLogEntity } from './org/SIGNATURE_LOG';
import { createSignatureAssignmentLog } from './org/SIGNERASSIGNMENT_LOG';
import { createSignatureRequestEntity } from './org/SIGNATURE_REQUEST';
import { createSignatureSessionEntity } from './org/SIGNATURE_SESSION';
import { createSignerAssignmentEntity } from './org/SIGNERASSIGNMENT';
import { createSubRevisionEntity } from './org/SUB_REVISION';
import { createTemplateEntity } from './org/TEMPLATE';
import { createThesaurusEntity } from './org/THESAURUS';
import { createTrackingEntity } from './org/TRACKING';
import { createUserGroupEntity } from './org/USERGROUP';
import { createXMDValueEntity } from './org/XMDVALUE';
import { DataSource, DataSourceOptions } from 'typeorm';
import { createPublisherTemplateEntity } from './multiorg/PUBLISHER_TEMPLATE';
import { createDocumentRevisionLogEntity } from './org/DOCUMENTREVISION_LOG';
import { createFeedbackEntity } from './org/FEEDBACK';
import { createConversationEntity } from './org/CONVERSATION';
import { createConversationParticipantsEntity } from './org/CONVERSATION_PARTICIPANTS';
import { createOrgAccrediationEntity } from './accreditationmanager/ORG_ACCREDITATION';
import { createAccreditorCondition } from './accreditationmanager/ACCREDITOR_CONDITION';
import { createConversationPendingSolicitsEntity } from './org/CONVERSATION_PENDING_SOLICITS';
import { createCommitteeCollaboratorEntity } from './org/COMMITTEE_COLLABORATOR';
import { createUserPreferencesEntity } from './org/USER_PREFERENCES';
import { createDefaultUserPreferencesEntity } from './master/DEFAULT_USER_PREFERENCES';
import { createReferenceTypePlEntity } from './org/REFERENCE_TYPE_PL';
import { createDocumentStatusPLEntity } from './master/DOCUMENT_STATUS_PL';

export enum EntityName {
    // accreditation manager
    ACCREDDITOR_CONDITION = 'ACCREDDITOR_CONDITION',
    ACCREDDITATIONMANAGER_AUDIT_LOG = 'ACCREDDITATIONMANAGER_AUDIT_LOG',
    CHECKLIST_AUDIT = 'CHECKLIST_AUDIT',
    CHECKLIST_CONDITION_AUDIT = 'CHECKLIST_CONDITION_AUDIT',
    CHECKLIST_CONDITION = 'CHECKLIST_CONDITION',
    CHECKLIST_EDITION = 'CHECKLIST_EDITION',
    CHECKLIST = 'CHECKLIST',
    CORRECTIVE_ACTION_PLAN = 'CORRECTIVE_ACTION_PLAN',
    INSPECTION = 'INSPECTION',
    ORG_ACCREDITATION = 'ORG_ACCREDITATION',
    // master
    DEFAULT_USER_PREFERENCES = 'DEFAULT_USER_PREFERENCES',
    DOCUMENT_STATUS_PL = 'DOCUMENT_STATUS_PL',
    LINK = 'LINK',
    MIME_TYPE = 'MIME_TYPE',
    ORGANIZATION = 'ORGANIZATION',
    PERSON_LINK = 'PERSON_LINK',
    PERSON_LOGIN_SESSION = 'PERSON_LOGIN_SESSION',
    PERSON_ORGANIZATION = 'PERSON_ORGANIZATION',
    PERSON = 'PERSON',
    SAML_PROFILE = 'SAML_PROFILE',
    // multiorg
    EDITION = 'EDITION',
    INVENTORY = 'INVENTORY',
    PUBLICATION = 'PUBLICATION',
    PUBLISHER_TEMPLATE = 'PUBLISHER_TEMPLATE',
    REPRESENTATIVE_CONTACT = 'REPRESENTATIVE_CONTACT',
    SEARCH_USER = 'SEARCH_USER',
    SUBSCRIBER_TEMPLATE = 'SUBSCRIBER_TEMPLATE',
    SUBSCRIPTION = 'SUBSCRIPTION',
    // org
    ACK_REQUEST = 'ACK_REQUEST',
    ACK_SCHEDULE_MANUAL = 'ACK_SCHEDULE_MANUAL',
    ACK_SCHEDULE = 'ACK_SCHEDULE',
    ACL = 'ACL',
    ALL_STORE = 'ALL_STORE',
    APPROVAL_ROUTE_TEMPLATE_LOG = 'APPROVAL_ROUTE_TEMPLATE_LOG',
    APPROVAL_ROUTE_TEMPLATE = 'APPROVAL_ROUTE_TEMPLATE',
    APPROVAL_SESSION_STEP = 'APPROVAL_SESSION_STEP',
    APPROVAL_SESSION = 'APPROVAL_SESSION',
    ORG_AUDIT_LOG = 'ORG_AUDIT_LOG',
    BATCH_JOB = 'BATCH_JOB',
    CE_COURSE_ATTEMPT = 'CE_COURSE_ATTEMPT',
    CE_COURSE_PROTOTYPE = 'CE_COURSE_PROTOTYPE',
    CE_COURSE_SCHEDULE = 'CE_COURSE_SCHEDULE',
    CE_COURSE_SESSION = 'CE_COURSE_SESSION',
    CE_COURSE_SNAPSHOT = 'CE_COURSE_SNAPSHOT',
    CE_ENROLLMENT_SNAPSHOT = 'CE_ENROLLMENT_SNAPSHOT',
    COMMITTEE_APPROVAL_REQUEST = 'COMMITTEE_APPROVAL_REQUEST',
    COMMITTEE_APPROVAL_SESSION = 'COMMITTEE_APPROVAL_SESSION',
    COMMITTEE_DECISION_LOG = 'COMMITTEE_DECISION_LOG',
    COMMITTEE_COLLABORATOR = 'COMMITTEE_COLLABORATOR',
    COMMITTEE_MEMBER = 'COMMITTEE_MEMBER',
    COMMITTEE_ROUTER = 'COMMITTEE_ROUTER',
    COMMITTEE_ROUTING_LOG_DETAIL = 'COMMITTEE_ROUTING_LOG_DETAIL',
    COMMITTEE_ROUTING_LOG = 'COMMITTEE_ROUTING_LOG',
    COMMITTEE = 'COMMITTEE',
    CONSENT_AGENDA_AUDIT_LOG = 'CONSENT_AGENDA_AUDIT_LOG',
    CONSENT_AGENDA_GUEST = 'CONSENT_AGENDA_GUEST',
    CONSENT_AGENDA_HEADING = 'CONSENT_AGENDA_HEADING',
    CONSENT_AGENDA_REFERENCE = 'CONSENT_AGENDA_REFERENCE',
    CONSENT_AGENDA_TASK = 'CONSENT_AGENDA_TASK',
    CONSENT_AGENDA = 'CONSENT_AGENDA',
    CONTACTS = 'CONTACTS',
    CONVERSATION = 'CONVERSATION',
    CONVERSATION_PARTICIPANTS = 'CONVERSATION_PARTICIPANTS',
    CONVERSATION_PENDING_SOLICITS = 'CONVERSATION_PENDING_SOLICITS',
    DOC_REQUEST_HANDLER = 'DOC_REQUEST_HANDLER',
    DOC_REQUEST = 'DOC_REQUEST',
    DOCUMENT_TYPE_PL = 'DOCUMENT_TYPE_PL',
    DOCUMENT = 'DOCUMENT',
    DOCUMENTREVISION_LINK = 'DOCUMENTREVISION_LINK',
    DOCUMENTREVISION_LOG = 'DOCUMENTREVISION_LOG',
    DOCUMENTREVISION = 'DOCUMENTREVISION',
    EDIT_REQUEST = 'EDIT_REQUEST',
    FEEDBACK = 'FEEDBACK',
    FEEDBACK_TYPE_PL = 'FEEDBACK_TYPE_PL',
    FIELD = 'FIELD',
    FIELDTEMPLATE = 'FIELDTEMPLATE',
    GLOBAL_IDENTIFIER = 'GLOBAL_IDENTIFIER',
    HOMEPAGE_LINK = 'HOMEPAGE_LINK',
    INFORMATION_HIERARCHY = 'INFORMATION_HIERARCHY',
    INFORMATION_INDEX = 'INFORMATION_INDEX',
    LOCKED_FOR_EDITS = 'LOCKED_FOR_EDITS',
    MESSAGE_BOARD_ITEM = 'MESSAGE_BOARD_ITEM',
    NOTIFICATION_WINDOW = 'NOTIFICATION_WINDOW',
    PUBLISHER = 'PUBLISHER',
    REFERENCE_TYPE_PL = 'REFERENCE_TYPE_PL',
    REMOVED_APPROVAL_SESSION_STEP = 'REMOVED_APPROVAL_SESSION_STEP',
    REVIEWERASSIGNMENT = 'REVIEWERASSIGNMENT',
    ROUTING_CONFIGURATION_LOG = 'ROUTING_CONFIGURATION_LOG',
    ROUTING_CONFIGURATION = 'ROUTING_CONFIGURATION',
    SIGNATURE_LOG = 'SIGNATURE_LOG',
    SIGNATURE_REQUEST = 'SIGNATURE_REQUEST',
    SIGNATURE_SESSION = 'SIGNATURE_SESSION',
    SIGNERASSIGNMENT_LOG = 'SIGNERASSIGNMENT_LOG',
    SIGNERASSIGNMENT = 'SIGNERASSIGNMENT',
    SUB_REVISION = 'SUB_REVISION',
    TEMPLATE = 'TEMPLATE',
    THESAURUS = 'THESAURUS',
    TRACKING = 'TRACKING',
    USER_PREFERENCES = 'USER_PREFERENCES',
    USERGROUP = 'USERGROUP',
    XMDVALUE = 'XMD_VALUE',
}



export class SchemaEntityManager {
    // TODO: Figure out how to type the value properly.
    // entities are organized by schema name, and then by entity name.
    private entityMap: Map<string, Map<EntityName, any>> = new Map();
    private orgIdToSchemaMap: Map<number, string> = new Map();


    /**
     * Will fetch schemas, populate entities, and return a final DataSource.
     * 
     * @returns The datasource that will be used by the application.
     */
    public initialize = async (options: DataSourceOptions): Promise<DataSource> => {
        const allOrgs = await this.fetchAllOrgs(options);
        this.initializeOrganizationIDToSchemaMap(allOrgs);

        let schemas = Array.from(this.orgIdToSchemaMap.values());
        schemas = schemas.concat(this.getAllPublishingSchemas(allOrgs));

        if (process.env.LIMITED_SCHEMAS === 'true') {
            schemas = ['LUCIDOC', 'dev2015040301'];

            const enableAhCorporate = false;
            if (enableAhCorporate) {
                schemas = schemas.concat(
                    'multiorgah', 'ahrsvl', 'ahsjch',
                    'ahcmc', 'ahshcl',
                    'ahcg', 'ahdl', 'ahgamc',
                    'ahhmh', 'ahlm', 'ahmc',
                    'amc', 'ahsl', 'ahsvh',
                    'sonora', 'ahshnv', 'ahtv',
                    'ahtcgh', 'ahtc', 'uvmc',
                    'ahshbh', 'ahwmmc', 'ahro',
                    'ahcvn', 'ahsandboxcorp',
                );
            }

            const enableMultiOrgCorporate = false;
            if (enableMultiOrgCorporate) {
                schemas = schemas.concat(
                    'multiorgmultiorg', 'multiorgcorp',
                    'multiorgboston', 'multiorghouston',
                    'multiorgnewyork', 'multiorgseattle',
                    'multiorgdenver',
                );
            }

            console.log('Running in limited schema mode. active:', schemas);
        }

        // For training functionality.
        schemas = schemas.concat('TUHELP');

        schemas = schemas.map(s => s.toUpperCase());

        this.populateEntityMap(schemas);
        console.log('initialized schema entity map');
        const allEntities = this.getAllEntities();

        const finalDatasourceOptions: DataSourceOptions = {
            ...options,
            entities: allEntities,
        };

        const finalDatasource = new DataSource(finalDatasourceOptions);
        console.time('initialize final datasource');
        await finalDatasource.initialize();
        console.timeEnd('initialize final datasource');
        return finalDatasource;
    };

    private getAllPublishingSchemas = (orgs: ORGANIZATION[]) => {
        const publishingSchemas = new Set<string>();
        for (const org of orgs) {
            if (org.PUBLISHING_SCHEMA !== null) {
                publishingSchemas.add(org.PUBLISHING_SCHEMA);

            }
        }

        return Array.from(publishingSchemas);
    };


    /**
     * This function will return the entity instance for the given schema and entity name.
     * 
     * @param schema - schema/databaseuid/customer instance you want.
     * @param entityName - entity name from the enum.
     * @param defaultInstance - the default instance to create if the entity is not found.
     * @returns - The entity instance.
     */
    private getEntity<T>(schema: string, entityName: EntityName, defaultInstance: any): T {
        const schemaEntities = this.entityMap.get(schema.toUpperCase());
        if (!schemaEntities) {
            throw new Error(`Schema ${schema} not found in entity map.`);
        }

        const entity = schemaEntities.get(entityName);
        if (!entity) {
            this.registerEntity(schema, entityName, defaultInstance);
        }

        return entity as T;
    }

    public getSchemaFromOrgID = (orgID: number | string | undefined) => {
        if (!orgID) {
            return undefined;
        }

        const parsedOrgID = parseInt(orgID.toString(), 10);
        const schema = this.orgIdToSchemaMap.get(parsedOrgID);
        // We implemented a fallback for new schemas, but due to the recent changes, if a new schema is added, we need to reboot anyways.
        // // if (!schema) {
        // //     const requestedOrg = await dataSource.getRepository(createOrganizationEntity('')).findOne({
        // //         where: {
        // //             ORGANIZATIONID: orgID,
        // //             // 1 means the org is active.
        // //             STATUSID: 1
        // //         },
        // //         select: [
        // //             'DATABASEUID'
        // //         ]
        // //     });

        // //     if (requestedOrg !== null && requestedOrg.DATABASEUID !== null) {
        // //         this.orgIdToSchemaMap.set(orgID, requestedOrg.DATABASEUID);
        // //     }

        //     return requestedOrg?.DATABASEUID ? requestedOrg.DATABASEUID : undefined;
        // }

        return schema;
    };

    // public getEntity = (schema: string, entityName: EntityName) => {
    //     const schemaEntities = this.entityMap.get(schema);
    //     if (!schemaEntities) {
    //         throw new Error(`Schema ${schema} not found in entity map.`);
    //     }

    //     const entity = schemaEntities.get(entityName);
    //     if (!entity) {
    //         throw new Error(`Entity ${entityName} not found in schema ${schema}.`);
    //     }

    //     return entity;
    // };

    /**
     * Fetches all organizations from the master schema using a temporary datasource without any entities.
     */
    private fetchAllOrgs = async (options: DataSourceOptions) => {
        const tempDatasource = new DataSource(options);

        await tempDatasource.initialize();

        const orgs: ORGANIZATION[] = await tempDatasource
            .query(`
            SELECT
                ORGANIZATIONID,
                DATABASEUID,
                PUBLISHING_SCHEMA          
            FROM
                MASTER.ORGANIZATION organization           
            WHERE
                organization.STATUSID = 1
           `);

        await tempDatasource.destroy();

        return orgs;
    };



    private initializeOrganizationIDToSchemaMap = (orgs: ORGANIZATION[]) => {
        for (const org of orgs) {
            if (org.DATABASEUID === null) {
                continue;
            }

            this.orgIdToSchemaMap.set(org.ORGANIZATIONID, org.DATABASEUID);
        }
    };



    /**
     * 
     * @returns All entities flattened from the `entityMap` class member.
     */
    private getAllEntities = () => {
        const allEntities: any[] = [];
        for (const schemaEntities of Array.from(this.entityMap.values())) {
            for (const entity of Array.from(schemaEntities.values())) {
                allEntities.push(entity);
            }
        }
        return allEntities;
    };

    private registerEntity = (schema: string, entityName: EntityName, entity: any) => {
        const schemaEntities = this.entityMap.get(schema);
        if (!schemaEntities) {
            throw new Error('Schema not found in entity map.');
        }

        const existingInstance = schemaEntities.get(entityName);
        if (existingInstance) {
            return existingInstance;
        }

        schemaEntities.set(entityName, entity);
        return entity;
    };


    private populateEntityMap = (schemas: Iterable<string>) => {
        for (const schema of Array.from(schemas)) {
            // TODO: fix type here too.
            const nameToEntityInstanceMap = new Map<EntityName, any>();

            nameToEntityInstanceMap.set(EntityName.ACCREDDITOR_CONDITION,
                createAccreditorCondition(schema, this));
            nameToEntityInstanceMap.set(EntityName.ACCREDDITATIONMANAGER_AUDIT_LOG,
                createAccreditationManagerAuditLogEntity(schema, this)
            );
            nameToEntityInstanceMap.set(EntityName.CHECKLIST_AUDIT,
                createChecklistAuditEntity(schema, this)
            );
            nameToEntityInstanceMap.set(EntityName.CHECKLIST_CONDITION,
                createChecklistConditionEntity(schema, this));
            nameToEntityInstanceMap.set(
                EntityName.CHECKLIST_CONDITION_AUDIT,
                createChecklistConditionAuditEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.CHECKLIST_CONDITION_AUDIT,
                createChecklistConditionAuditEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.CHECKLIST_EDITION,
                createChecklistEditionEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.CHECKLIST,
                createChecklistEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.CORRECTIVE_ACTION_PLAN,
                createCorrectiveActionPlanEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.INSPECTION,
                createInspectionEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.ORG_ACCREDITATION, createOrgAccrediationEntity(schema, this));

            // master
            nameToEntityInstanceMap.set(EntityName.DEFAULT_USER_PREFERENCES,
                createDefaultUserPreferencesEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.DOCUMENT_STATUS_PL,
                createDocumentStatusPLEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.LINK,
                createLinkEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.MIME_TYPE,
                createMimeTypeEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.ORGANIZATION,
                createOrganizationEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.PERSON_LINK,
                createPersonLinkEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.PERSON_LOGIN_SESSION,
                createPersonLoginSessionEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.PERSON_ORGANIZATION,
                createPersonOrganizationEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.PERSON,
                createPersonEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.SAML_PROFILE,
                createSamlProfileEntity(schema, this));

            // multiorg
            nameToEntityInstanceMap.set(EntityName.EDITION,
                createEditionEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.INVENTORY,
                createInventoryEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.PUBLICATION,
                createPublicationEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.PUBLISHER_TEMPLATE,
                createPublisherTemplateEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.REPRESENTATIVE_CONTACT,
                createRepresentativeContactEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.SEARCH_USER,
                createSearchUserEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.SUBSCRIBER_TEMPLATE,
                createSubscriberTemplateEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.SUBSCRIPTION,
                createSubscriptionEntity(schema, this));

            // org
            nameToEntityInstanceMap.set(EntityName.ACK_REQUEST,
                createAckRequestEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.ACK_SCHEDULE_MANUAL,
                createAckScheduleManualEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.ACK_SCHEDULE, createAckScheduleEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.ACL,
                createACLEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.ALL_STORE,
                createAllStoreEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.APPROVAL_ROUTE_TEMPLATE_LOG,
                createApprovalRouteTemplateLogEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.APPROVAL_ROUTE_TEMPLATE,
                createApprovalRouteTemplateEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.APPROVAL_SESSION_STEP,
                createApprovalSessionStepEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.APPROVAL_SESSION,
                createApprovalSessionEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.ORG_AUDIT_LOG,
                createAuditLogEntity(schema, this)); // Assuming this maps to createAuditLogEntity for ORG_AUDIT_LOG
            nameToEntityInstanceMap.set(EntityName.BATCH_JOB,
                createBatchJobEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.CE_COURSE_ATTEMPT,
                createCeCourseAttemptEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.CE_COURSE_PROTOTYPE,
                createCeCoursePrototypeEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.CE_COURSE_SCHEDULE,
                createCeCourseScheduleEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.CE_COURSE_SESSION,
                createCeCourseSessionEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.CE_COURSE_SNAPSHOT,
                createCeCourseSnapshotEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.CE_ENROLLMENT_SNAPSHOT,
                createCeEnrollmentSnapshotEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.COMMITTEE_APPROVAL_REQUEST,
                createCommitteeApprovalRequestEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.COMMITTEE_APPROVAL_SESSION,
                createCommitteeApprovalSessionEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.COMMITTEE_DECISION_LOG,
                createCommitteeDecisionLogEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.COMMITTEE_COLLABORATOR,
                createCommitteeCollaboratorEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.COMMITTEE_MEMBER,
                createCommitteeMemberEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.COMMITTEE_ROUTER,
                createCommitteeRouterEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.COMMITTEE_ROUTING_LOG_DETAIL,
                createCommitteeRoutingLogDetailEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.COMMITTEE_ROUTING_LOG,
                createCommitteeRoutingLogEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.COMMITTEE,
                createCommitteeEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.CONSENT_AGENDA_AUDIT_LOG,
                createConsentAgendaAuditLogEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.CONSENT_AGENDA_GUEST,
                createConsentAgendaGuestEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.CONSENT_AGENDA_HEADING,
                createConsentAgendaHeadingEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.CONSENT_AGENDA_REFERENCE,
                createConsentAgendaReferenceEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.CONSENT_AGENDA_TASK,
                createConsentAgendaTaskEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.CONSENT_AGENDA,
                createConsentAgendaEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.CONTACTS,
                createContactsEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.CONVERSATION,
                createConversationEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.CONVERSATION_PARTICIPANTS,
                createConversationParticipantsEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.CONVERSATION_PENDING_SOLICITS,
                createConversationPendingSolicitsEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.DOC_REQUEST_HANDLER,
                createDocRequestHandlerEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.DOC_REQUEST,
                createDocRequestEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.DOCUMENT_TYPE_PL,
                createDocumentTypePLEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.DOCUMENT,
                createDocumentEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.DOCUMENTREVISION_LINK,
                createDocumentRevisionLinkEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.DOCUMENTREVISION_LOG,
                createDocumentRevisionLogEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.DOCUMENTREVISION,
                createDocumentRevisionEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.EDIT_REQUEST,
                createEditRequestEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.FEEDBACK,
                createFeedbackEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.FEEDBACK_TYPE_PL,
                createFeedbackTypePLEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.FIELD,
                createFieldEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.FIELDTEMPLATE,
                createFieldTemplateEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.GLOBAL_IDENTIFIER,
                createGlobalIdentifierEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.HOMEPAGE_LINK,
                createHomepageLinkEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.INFORMATION_HIERARCHY,
                createInformationHierarchyEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.INFORMATION_INDEX,
                createInformationIndexEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.LOCKED_FOR_EDITS,
                createLockedForEditsEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.MESSAGE_BOARD_ITEM,
                createMessageBoardItemEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.NOTIFICATION_WINDOW,
                createNotificationWindowEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.PUBLISHER,
                createPublisherEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.REFERENCE_TYPE_PL,
                createReferenceTypePlEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.REMOVED_APPROVAL_SESSION_STEP,
                createRemovedApprovalSessionStepEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.REVIEWERASSIGNMENT,
                createReviewerAssignmentEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.ROUTING_CONFIGURATION_LOG,
                createRoutingConfigurationLogEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.ROUTING_CONFIGURATION,
                createRoutingConfigurationEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.SIGNATURE_LOG,
                createSignatureLogEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.SIGNATURE_REQUEST,
                createSignatureRequestEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.SIGNATURE_SESSION,
                createSignatureSessionEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.SIGNERASSIGNMENT_LOG,
                createSignatureAssignmentLog(schema, this));
            nameToEntityInstanceMap.set(EntityName.SIGNERASSIGNMENT,
                createSignerAssignmentEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.SUB_REVISION
                , createSubRevisionEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.TEMPLATE,
                createTemplateEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.THESAURUS,
                createThesaurusEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.TRACKING,
                createTrackingEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.USER_PREFERENCES,
                createUserPreferencesEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.USERGROUP,
                createUserGroupEntity(schema, this));
            nameToEntityInstanceMap.set(EntityName.XMDVALUE,
                createXMDValueEntity(schema, this));

            this.entityMap.set(schema, nameToEntityInstanceMap);
        }

    };


    getAccreditorConditionEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createAccreditorCondition>>(
            schema,
            EntityName.ACCREDDITOR_CONDITION,
            createAccreditorCondition(schema, this)
        );
    }

    getAccreditationManagerAuditLogEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createAccreditationManagerAuditLogEntity>>(
            schema,
            EntityName.ACCREDDITATIONMANAGER_AUDIT_LOG,
            createAccreditationManagerAuditLogEntity(schema, this)
        );
    }

    getChecklistAuditEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createChecklistAuditEntity>>(schema,
            EntityName.CHECKLIST_AUDIT,
            createChecklistAuditEntity(schema, this));
    }

    getChecklistConditionAuditEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createChecklistConditionAuditEntity>>(schema,
            EntityName.CHECKLIST_CONDITION_AUDIT,
            createChecklistConditionAuditEntity(schema, this));
    }

    getChecklistConditionEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createChecklistConditionEntity>>(schema,
            EntityName.CHECKLIST_CONDITION,
            createChecklistConditionEntity(schema, this));
    }

    getChecklistEditionEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createChecklistEditionEntity>>(schema,
            EntityName.CHECKLIST_EDITION,
            createChecklistEditionEntity(schema, this));
    }

    getChecklistEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createChecklistEntity>>(schema, EntityName.CHECKLIST,
            createChecklistEntity(schema, this));
    }

    getCorrectiveActionPlanEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createCorrectiveActionPlanEntity>>(schema,
            EntityName.CORRECTIVE_ACTION_PLAN,
            createCorrectiveActionPlanEntity(schema, this));
    }

    getInspectionEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createInspectionEntity>>(schema,
            EntityName.INSPECTION,
            createInspectionEntity(schema, this));
    }

    getOrgAccreditationEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createOrgAccrediationEntity>>(schema,
            EntityName.ORG_ACCREDITATION,
            createOrgAccrediationEntity(schema, this));
    }

    getDefaultUserPreferencesEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createDefaultUserPreferencesEntity>>(schema,
            EntityName.DEFAULT_USER_PREFERENCES,
            createDefaultUserPreferencesEntity(schema, this));
    }

    getDocumentStatusPLEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createDocumentStatusPLEntity>>(schema,
            EntityName.DOCUMENT_STATUS_PL,
            createDocumentStatusPLEntity(schema, this));
    }

    getLinkEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createLinkEntity>>(schema,
            EntityName.LINK,
            createLinkEntity(schema, this));
    }

    getMimeTypeEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createMimeTypeEntity>>(schema,
            EntityName.MIME_TYPE,
            createMimeTypeEntity(schema, this));
    }

    getOrganizationEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createOrganizationEntity>>(schema,
            EntityName.ORGANIZATION,
            createOrganizationEntity(schema, this));
    }

    getPersonLinkEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createPersonLinkEntity>>(schema,
            EntityName.PERSON_LINK,
            createPersonLinkEntity(schema, this));
    }

    getPersonLoginSessionEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createPersonLoginSessionEntity>>(schema,
            EntityName.PERSON_LOGIN_SESSION,
            createPersonLoginSessionEntity(schema, this));
    }

    getPersonOrganizationEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createPersonOrganizationEntity>>(schema,
            EntityName.PERSON_ORGANIZATION,
            createPersonOrganizationEntity(schema, this));
    }

    getPersonEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createPersonEntity>>(schema,
            EntityName.PERSON,
            createPersonEntity(schema, this));
    }

    getSamlProfileEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createSamlProfileEntity>>(schema,
            EntityName.SAML_PROFILE,
            createSamlProfileEntity(schema, this));
    }

    getEditionEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createEditionEntity>>(schema,
            EntityName.EDITION,
            createEditionEntity(schema, this));
    }

    getInventoryEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createInventoryEntity>>(schema,
            EntityName.INVENTORY,
            createInventoryEntity(schema, this));
    }

    getPublicationEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createPublicationEntity>>(schema,
            EntityName.PUBLICATION,
            createPublicationEntity(schema, this));
    }

    getPublisherTemplateEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createPublisherTemplateEntity>>(schema,
            EntityName.PUBLISHER_TEMPLATE,
            createPublisherTemplateEntity(schema, this));
    }

    getRepresentativeContactEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createRepresentativeContactEntity>>(schema,
            EntityName.REPRESENTATIVE_CONTACT,
            createRepresentativeContactEntity(schema, this));
    }

    getSearchUserEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createSearchUserEntity>>(schema,
            EntityName.SEARCH_USER,
            createSearchUserEntity(schema, this));
    }

    getSubscriberTemplateEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createSubscriberTemplateEntity>>(schema,
            EntityName.SUBSCRIBER_TEMPLATE,
            createSubscriberTemplateEntity(schema, this));
    }

    getSubscriptionEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createSubscriptionEntity>>(schema,
            EntityName.SUBSCRIPTION,
            createSubscriptionEntity(schema, this));
    }

    getAckRequestEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createAckRequestEntity>>(schema,
            EntityName.ACK_REQUEST,
            createAckRequestEntity(schema, this));
    }

    getAckScheduleManualEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createAckScheduleManualEntity>>(schema,
            EntityName.ACK_SCHEDULE_MANUAL,
            createAckScheduleManualEntity(schema, this));
    }

    getAckScheduleEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createAckScheduleEntity>>(schema,
            EntityName.ACK_SCHEDULE,
            createAckScheduleEntity(schema, this));
    }

    getAclEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createACLEntity>>(schema,
            EntityName.ACL,
            createACLEntity(schema, this));
    }

    getAllStoreEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createAllStoreEntity>>(schema,
            EntityName.ALL_STORE,
            createAllStoreEntity(schema, this));
    }

    getApprovalRouteTemplateLogEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createApprovalRouteTemplateLogEntity>>(schema,
            EntityName.APPROVAL_ROUTE_TEMPLATE_LOG,
            createApprovalRouteTemplateLogEntity(schema, this));
    }

    getApprovalRouteTemplateEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createApprovalRouteTemplateEntity>>(schema,
            EntityName.APPROVAL_ROUTE_TEMPLATE,
            createApprovalRouteTemplateEntity(schema, this));
    }

    getApprovalSessionStepEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createApprovalSessionStepEntity>>(schema,
            EntityName.APPROVAL_SESSION_STEP,
            createApprovalSessionStepEntity(schema, this));
    }

    getApprovalSessionEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createApprovalSessionEntity>>(schema,
            EntityName.APPROVAL_SESSION,
            createApprovalSessionEntity(schema, this));
    }

    getOrgAuditLogEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createAuditLogEntity>>(schema,
            EntityName.ORG_AUDIT_LOG,
            createAuditLogEntity(schema, this));
    }

    getBatchJobEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createBatchJobEntity>>(schema,
            EntityName.BATCH_JOB,
            createBatchJobEntity(schema, this));
    }

    getCeCourseAttemptEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createCeCourseAttemptEntity>>(schema,
            EntityName.CE_COURSE_ATTEMPT,
            createCeCourseAttemptEntity(schema, this));
    }

    getCeCoursePrototypeEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createCeCoursePrototypeEntity>>(schema,
            EntityName.CE_COURSE_PROTOTYPE,
            createCeCoursePrototypeEntity(schema, this));
    }

    getCeCourseScheduleEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createCeCourseScheduleEntity>>(schema,
            EntityName.CE_COURSE_SCHEDULE,
            createCeCourseScheduleEntity(schema, this));
    }

    getCeCourseSessionEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createCeCourseSessionEntity>>(schema,
            EntityName.CE_COURSE_SESSION,
            createCeCourseSessionEntity(schema, this));
    }

    getCeCourseSnapshotEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createCeCourseSnapshotEntity>>(schema,
            EntityName.CE_COURSE_SNAPSHOT,
            createCeCourseSnapshotEntity(schema, this));
    }

    getCeEnrollmentSnapshotEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createCeEnrollmentSnapshotEntity>>(schema,
            EntityName.CE_ENROLLMENT_SNAPSHOT,
            createCeEnrollmentSnapshotEntity(schema, this));
    }

    getCommitteeApprovalRequestEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createCommitteeApprovalRequestEntity>>(schema,
            EntityName.COMMITTEE_APPROVAL_REQUEST,
            createCommitteeApprovalRequestEntity(schema, this));
    }

    getCommitteeApprovalSessionEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createCommitteeApprovalSessionEntity>>(schema,
            EntityName.COMMITTEE_APPROVAL_SESSION,
            createCommitteeApprovalSessionEntity(schema, this));
    }

    getCommitteeDecisionLogEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createCommitteeDecisionLogEntity>>(schema,
            EntityName.COMMITTEE_DECISION_LOG,
            createCommitteeDecisionLogEntity(schema, this));
    }

    getCommitteeCollaboratorEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createCommitteeCollaboratorEntity>>(schema,
            EntityName.COMMITTEE_COLLABORATOR,
            createCommitteeCollaboratorEntity(schema, this));
    }

    getCommitteeMemberEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createCommitteeMemberEntity>>(schema,
            EntityName.COMMITTEE_MEMBER,
            createCommitteeMemberEntity(schema, this));
    }

    getCommitteeRouterEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createCommitteeRouterEntity>>(schema,
            EntityName.COMMITTEE_ROUTER,
            createCommitteeRouterEntity(schema, this));
    }

    getCommitteeRoutingLogDetailEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createCommitteeRoutingLogDetailEntity>>(schema,
            EntityName.COMMITTEE_ROUTING_LOG_DETAIL,
            createCommitteeRoutingLogDetailEntity(schema, this));
    }

    getCommitteeRoutingLogEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createCommitteeRoutingLogEntity>>(schema,
            EntityName.COMMITTEE_ROUTING_LOG,
            createCommitteeRoutingLogEntity(schema, this));
    }

    getCommitteeEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createCommitteeEntity>>(schema,
            EntityName.COMMITTEE,
            createCommitteeEntity(schema, this));
    }

    getConsentAgendaAuditLogEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createConsentAgendaAuditLogEntity>>(schema,
            EntityName.CONSENT_AGENDA_AUDIT_LOG,
            createConsentAgendaAuditLogEntity(schema, this));
    }

    getConsentAgendaGuestEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createConsentAgendaGuestEntity>>(schema,
            EntityName.CONSENT_AGENDA_GUEST,
            createConsentAgendaGuestEntity(schema, this));
    }

    getConsentAgendaHeadingEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createConsentAgendaHeadingEntity>>(schema,
            EntityName.CONSENT_AGENDA_HEADING,
            createConsentAgendaHeadingEntity(schema, this));
    }

    getConsentAgendaReferenceEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createConsentAgendaReferenceEntity>>(schema,
            EntityName.CONSENT_AGENDA_REFERENCE,
            createConsentAgendaReferenceEntity(schema, this));
    }

    getConsentAgendaTaskEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createConsentAgendaTaskEntity>>(schema,
            EntityName.CONSENT_AGENDA_TASK,
            createConsentAgendaTaskEntity(schema, this));
    }

    getConsentAgendaEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createConsentAgendaEntity>>(schema,
            EntityName.CONSENT_AGENDA,
            createConsentAgendaEntity(schema, this));
    }

    getContactsEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createContactsEntity>>(schema,
            EntityName.CONTACTS,
            createContactsEntity(schema, this));
    }

    getConversationEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createConversationEntity>>(schema,
            EntityName.CONVERSATION,
            createConversationEntity(schema, this));
    }

    getConversationParticipantsEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createConversationParticipantsEntity>>(schema,
            EntityName.CONVERSATION_PARTICIPANTS,
            createConversationParticipantsEntity(schema, this));
    }

    getConversationPendingSolicitsEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createConversationPendingSolicitsEntity>>(schema,
            EntityName.CONVERSATION_PENDING_SOLICITS,
            createConversationPendingSolicitsEntity(schema, this));
    }

    getDocRequestHandlerEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createDocRequestHandlerEntity>>(schema,
            EntityName.DOC_REQUEST_HANDLER,
            createDocRequestHandlerEntity(schema, this));
    }

    getDocRequestEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createDocRequestEntity>>(schema,
            EntityName.DOC_REQUEST,
            createDocRequestEntity(schema, this));
    }

    getDocumentTypePLEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createDocumentTypePLEntity>>(schema,
            EntityName.DOCUMENT_TYPE_PL,
            createDocumentTypePLEntity(schema, this));
    }

    getDocumentEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createDocumentEntity>>(schema,
            EntityName.DOCUMENT,
            createDocumentEntity(schema, this));
    }

    getDocumentRevisionLinkEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createDocumentRevisionLinkEntity>>(schema,
            EntityName.DOCUMENTREVISION_LINK,
            createDocumentRevisionLinkEntity(schema, this));
    }

    getDocumentRevisionLogEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createDocumentRevisionLogEntity>>(schema,
            EntityName.DOCUMENTREVISION_LOG,
            createDocumentRevisionLogEntity(schema, this));
    }

    getDocumentRevisionEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createDocumentRevisionEntity>>(schema,
            EntityName.DOCUMENTREVISION,
            createDocumentRevisionEntity(schema, this));
    }

    getEditRequestEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createEditRequestEntity>>(schema,
            EntityName.EDIT_REQUEST,
            createEditRequestEntity(schema, this));
    }

    getFeedbackEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createFeedbackEntity>>(schema,
            EntityName.FEEDBACK,
            createFeedbackEntity(schema, this));
    }

    getFeedbackTypePLEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createFeedbackTypePLEntity>>(schema,
            EntityName.FEEDBACK_TYPE_PL,
            createFeedbackTypePLEntity(schema, this));
    }

    getFieldEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createFieldEntity>>(schema,
            EntityName.FIELD,
            createFieldEntity(schema, this));
    }

    getFieldTemplateEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createFieldTemplateEntity>>(schema,
            EntityName.FIELDTEMPLATE,
            createFieldTemplateEntity(schema, this));
    }

    getGlobalIdentifierEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createGlobalIdentifierEntity>>(schema,
            EntityName.GLOBAL_IDENTIFIER,
            createGlobalIdentifierEntity(schema, this));
    }

    getHomepageLinkEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createHomepageLinkEntity>>(schema,
            EntityName.HOMEPAGE_LINK,
            createHomepageLinkEntity(schema, this));
    }

    getInformationHierarchyEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createInformationHierarchyEntity>>(schema,
            EntityName.INFORMATION_HIERARCHY,
            createInformationHierarchyEntity(schema, this));
    }

    getInformationIndexEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createInformationIndexEntity>>(schema,
            EntityName.INFORMATION_INDEX,
            createInformationIndexEntity(schema, this));
    }

    getLockedForEditsEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createLockedForEditsEntity>>(schema,
            EntityName.LOCKED_FOR_EDITS,
            createLockedForEditsEntity(schema, this));
    }

    getMessageBoardItemEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createMessageBoardItemEntity>>(schema,
            EntityName.MESSAGE_BOARD_ITEM,
            createMessageBoardItemEntity(schema, this));
    }

    getNotificationWindowEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createNotificationWindowEntity>>(schema,
            EntityName.NOTIFICATION_WINDOW,
            createNotificationWindowEntity(schema, this));
    }

    getPublisherEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createPublisherEntity>>(schema,
            EntityName.PUBLISHER,
            createPublisherEntity(schema, this));
    }

    getReferenceTypePlEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createReferenceTypePlEntity>>(schema,
            EntityName.REFERENCE_TYPE_PL,
            createReferenceTypePlEntity(schema, this));
    }

    getRemovedApprovalSessionStepEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createRemovedApprovalSessionStepEntity>>(schema,
            EntityName.REMOVED_APPROVAL_SESSION_STEP,
            createRemovedApprovalSessionStepEntity(schema, this));
    }

    getReviewerAssignmentEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createReviewerAssignmentEntity>>(schema,
            EntityName.REVIEWERASSIGNMENT,
            createReviewerAssignmentEntity(schema, this));
    }

    getRoutingConfigurationLogEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createRoutingConfigurationLogEntity>>(schema,
            EntityName.ROUTING_CONFIGURATION_LOG,
            createRoutingConfigurationLogEntity(schema, this));
    }

    getRoutingConfigurationEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createRoutingConfigurationEntity>>(schema,
            EntityName.ROUTING_CONFIGURATION,
            createRoutingConfigurationEntity(schema, this));
    }

    getSignatureLogEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createSignatureLogEntity>>(schema,
            EntityName.SIGNATURE_LOG,
            createSignatureLogEntity(schema, this));
    }

    getSignatureRequestEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createSignatureRequestEntity>>(schema,
            EntityName.SIGNATURE_REQUEST,
            createSignatureRequestEntity(schema, this));
    }

    getSignatureSessionEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createSignatureSessionEntity>>(schema,
            EntityName.SIGNATURE_SESSION,
            createSignatureSessionEntity(schema, this));
    }

    getSignerAssignmentLogEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createSignatureAssignmentLog>>(schema,
            EntityName.SIGNERASSIGNMENT_LOG,
            createSignatureAssignmentLog(schema, this));
    }

    getSignerAssignmentEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createSignerAssignmentEntity>>(schema,
            EntityName.SIGNERASSIGNMENT,
            createSignerAssignmentEntity(schema, this));
    }

    getSubRevisionEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createSubRevisionEntity>>(schema,
            EntityName.SUB_REVISION,
            createSubRevisionEntity(schema, this));
    }

    getTemplateEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createTemplateEntity>>(schema,
            EntityName.TEMPLATE,
            createTemplateEntity(schema, this));
    }

    getThesaurusEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createThesaurusEntity>>(schema,
            EntityName.THESAURUS,
            createThesaurusEntity(schema, this));
    }

    getTrackingEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createTrackingEntity>>(schema,
            EntityName.TRACKING,
            createTrackingEntity(schema, this));
    }

    getUserPreferencesEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createUserPreferencesEntity>>(schema,
            EntityName.USER_PREFERENCES,
            createUserPreferencesEntity(schema, this));
    }

    getUserGroupEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createUserGroupEntity>>(schema,
            EntityName.USERGROUP,
            createUserGroupEntity(schema, this));
    }

    getXmdValueEntity(schema: string) {
        return this.getEntity<ReturnType<typeof createXMDValueEntity>>(schema,
            EntityName.XMDVALUE,
            createXMDValueEntity(schema, this));
    }
}