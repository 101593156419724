import UserBundleContext from '@/context/UserBundleContext';
import { useOrgBaseURL } from '@/hooks/useOrgBaseURL';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

interface LandingProps {

}

export const Landing: React.FC<LandingProps> = () => {

    const userBundle = useContext(UserBundleContext);
    const history = useHistory();
    const baseURL = useOrgBaseURL();

    useEffect(() => {
        switch (userBundle.preferences.landingPageFeature) {
            case 'dashboard':
                history.push(`${baseURL}/dashboard`);
                break;
            case 'docmanager':
                window.location.href = '/docmgr2/docmanager.pl';
                break;
            case 'committees':
                history.push(`${baseURL}/committees`);
                break;
            case 'my-education':
                window.location.href = '/cgi/education/overview.pl';
                break;
            default:
                break;
        }
    }, [userBundle]);


    return (
        <p>Redirecting to your configured landing page...</p>
    );
};