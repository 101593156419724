import {
    Column,
    Entity,
    JoinColumn,
    OneToMany,
    PrimaryColumn,
} from 'typeorm';
import { SchemaEntityManager } from '../SchemaEntityManager';
import { CONVERSATION_PARTICIPANTS } from './CONVERSATION_PARTICIPANTS';
import { FEEDBACK } from './FEEDBACK';
import { CONVERSATION_PENDING_SOLICITS } from './CONVERSATION_PENDING_SOLICITS';

export enum ConversationTypes {
    FEEDBACK              = 0,
    FEEDBACK_SOLICITATION = 1,
    SIGNATURE_FEEDBACK    = 2,
    CORPORATE_FEEDBACK    = 3,
    WAIVER_REQUEST        = 4,
    PERSONAL_NOTE         = 5,
}

function createConversationEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('CONVERSATION', { schema: schema })
    class CONVERSATION {
        @PrimaryColumn('number', {
            nullable: false,
            name: 'CONVERSATIONID'
        })
        CONVERSATIONID!: number;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'MANAGER_USERID'
        })
        MANAGER_USERID!: number | null;

        @Column('number', {
            nullable: false,
            name: 'TYPE'
        })
        TYPE!: number;

        @Column('varchar2', {
            nullable: false,
            name: 'TOPIC'
        })
        TOPIC!: string;

        @Column('char', {
            nullable: false,
            default: () => '0',
            name: 'IS_CLOSED'
        })
        IS_CLOSED!: string;

        @Column('char', {
            nullable: false,
            default: () => '0',
            name: 'IS_PUBLIC'
        })
        IS_PUBLIC!: string;

        @Column('number', {
            nullable: false,
            default: () => '0',
            name: 'IS_PERSONAL'
        })
        IS_PERSONAL!: number;

        @OneToMany(
            () => manager.getConversationParticipantsEntity(schema), 
            (conversationParticipants) => conversationParticipants.CONVERSATION
        )
        @JoinColumn({
            name: 'CONVERSATIONID'
        })
        CONVERSATION_PARTICIPANTS!: CONVERSATION_PARTICIPANTS[] | null;

        @OneToMany(
            () => manager.getFeedbackEntity(schema),
            (feedback) => feedback.CONVERSATION
        )
        @JoinColumn({
            name: 'CONVERSATIONID'
        })
        FEEDBACKS!: FEEDBACK[] | null;

        @OneToMany(
            () => manager.getConversationPendingSolicitsEntity(schema),
            (conversationPendingSolicits) => conversationPendingSolicits.CONVERSATION
        )
        @JoinColumn({
            name: 'CONVERSATIONID'
        })
        CONVERSATION_PENDING_SOLICITS!: CONVERSATION_PENDING_SOLICITS[] | null;
    }

        return CONVERSATION;
}

export { createConversationEntity };
export type CONVERSATION = InstanceType<ReturnType<typeof createConversationEntity>>;