import React from 'react';
import { PUBLISHER } from '../../../entities/org/PUBLISHER';
import { INFORMATION_HIERARCHY } from '../../../entities/org/INFORMATION_HIERARCHY';

export interface ContractRequestContextType {
    publishers: PUBLISHER[] // <-- AKA manuals
    departments: INFORMATION_HIERARCHY[] // <-- AKA categories, or INFORMATION_HIERARCHY
}

const ContractRequestContext = React.createContext<ContractRequestContextType>({
    publishers: [],
    departments: []
});

export default ContractRequestContext;
