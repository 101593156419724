import {
    Column,
    Entity,
    JoinColumn,
    ManyToOne,
    OneToMany,
    PrimaryColumn,
} from 'typeorm';
import { APPROVAL_SESSION_STEP,  } from './APPROVAL_SESSION_STEP';
import { COMMITTEE_APPROVAL_REQUEST,  } from './COMMITTEE_APPROVAL_REQUEST';
import { COMMITTEE_DECISION_LOG,  } from './COMMITTEE_DECISION_LOG';
import { CONSENT_AGENDA_TASK,  } from './CONSENT_AGENDA_TASK';
import { DOCUMENTREVISION,  } from './DOCUMENTREVISION';
import { INFORMATION_HIERARCHY,  } from './INFORMATION_HIERARCHY';
import { SchemaEntityManager } from '../SchemaEntityManager';

export const CommitteeApprovalSessionTypeLabels = {
    archive: 'Archive',
    officialize: 'Officialize',
    review: 'Review'
};

export enum CommitteeApprovalSessionTypes {
    archive = 'archive',
    officialize = 'officialize',
    review = 'review'
}

export enum CommitteeApprovalSessionStatuses {
    active = 'active',
    cancelled_by_committee = 'cancelled_by_committee',
    cancelled_by_user = 'cancelled_by_user',
    complete = 'complete',
    pending = 'pending',
    rejected = 'rejected',
    voided = 'voided',
}

// Factory function to create COMMITTEE_APPROVAL_SESSION entity with dynamic schema
function createCommitteeApprovalSessionEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('COMMITTEE_APPROVAL_SESSION', { schema })
    class COMMITTEE_APPROVAL_SESSION {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            name: 'COMMITTEE_APPROVAL_SESSION_ID'
        })
        COMMITTEE_APPROVAL_SESSION_ID!: number;

        @Column('number', {
            nullable: true,
            name: 'DOCREVID'
        })
        DOCREVID!: number | null;

        @ManyToOne(
            () => manager.getDocumentRevisionEntity(schema),
            DOCUMENTREVISION => DOCUMENTREVISION.COMMITTEE_APPROVAL_SESSIONS
        )
        @JoinColumn({
            name: 'DOCREVID'
        })
        DOCUMENTREVISION!: DOCUMENTREVISION | null;

        @Column('number', {
            nullable: true,
            name: 'MANUALID'
        })
        MANUALID!: number | null;

        @ManyToOne(
            () => manager.getInformationHierarchyEntity(schema),
            (informationHierarchy) => informationHierarchy.COMMITTEE_APPROVAL_SESSIONS
        )
        @JoinColumn({
            name: 'MANUALID'
        })
        INFORMATION_HIERARCHY!: INFORMATION_HIERARCHY | null;

        @Column('varchar2', {
            nullable: false,
            length: 20,
            name: 'TYPE'
        })
        TYPE!: string;

        @Column('varchar2', {
            nullable: false,
            length: 25,
            default: () => '\'pending\'',
            name: 'STATUS'
        })
        STATUS!: string;

        @Column('timestamp', {
            nullable: false,
            default: () => 'SYSDATE',
            name: 'START_DATE'
        })
        START_DATE!: Date;

        @Column('timestamp', {
            nullable: true,
            name: 'FINAL_STATUS_DATE'
        })
        FINAL_STATUS_DATE!: Date | null;

        @OneToMany(
            () => manager.getApprovalSessionStepEntity(schema),
            APPROVAL_SESSION_STEP => APPROVAL_SESSION_STEP.COMMITTEE_APPROVAL_SESSION
        )
        APPROVAL_SESSION_STEPS!: APPROVAL_SESSION_STEP[] | null;

        @OneToMany(
            () => manager.getCommitteeApprovalRequestEntity(schema), 
            (committeeApprovalRequest) => committeeApprovalRequest.COMMITTEE_APPROVAL_SESSION
        )
        COMMITTEE_APPROVAL_REQUESTS!: COMMITTEE_APPROVAL_REQUEST[] | null;

        @OneToMany(
            () => manager.getCommitteeDecisionLogEntity(schema),
            (committeeDecisionLog) => committeeDecisionLog.COMMITTEE_APPROVAL_SESSION
        )
        COMMITTEE_DECISION_LOGS!: COMMITTEE_DECISION_LOG[] | null;

        @OneToMany(
            () => manager.getConsentAgendaTaskEntity(schema),
            (consentAgendaTask) => consentAgendaTask.COMMITTEE_APPROVAL_SESSION
        )
        CONSENT_AGENDA_TASKS!: CONSENT_AGENDA_TASK[] | null;

        static Types = CommitteeApprovalSessionTypes;

        static Statuses = CommitteeApprovalSessionStatuses;
    }

    return COMMITTEE_APPROVAL_SESSION;
}

// Export the factory function
export { createCommitteeApprovalSessionEntity };

// Export the type for COMMITTEE_APPROVAL_SESSION entities created by the factory function
export type COMMITTEE_APPROVAL_SESSION = InstanceType<ReturnType<typeof createCommitteeApprovalSessionEntity>>;
