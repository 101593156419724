import { Component } from 'react';
import UserPicker from '../UserPicker/UserPicker';
import IconButton from '../_shared/IconButton/IconButton';
import axios from 'axios';
import ReviewApprovalRoute from './ReviewApprovalRoute';
import './approvalrouteeditor.css';
import { COMMITTEE } from '../../entities/org/COMMITTEE';
import { PERSON } from '../../entities/master/PERSON';
import { USERGROUP } from '../../entities/org/USERGROUP';
import { phaseWithDragAndDropID, COMMITTEESWithDragAndDropID, itemWithDragAndDropID } from './customDataTypes';
import { DragDropContext, Draggable, DragUpdate, Droppable } from 'react-beautiful-dnd';
import '../_shared/CustomModal/custommodal.css';
import sortLastNameThenFirst from '../UserPicker/utils/sortLastNameThenFirstName';
import { LucidocModal } from '../_shared/LucidocModal/LucidocModal';
import { Cancel, Create, Group, GroupAdd, Refresh, Save, Sort, HourglassEmpty, Restore, AssignmentTurnedIn, BorderColor } from '@material-ui/icons';
import * as React from 'react';
import { Tooltip } from '@material-ui/core';
import { constructFullName } from '../UserPicker/utils/constructFullName';

declare global {
    interface Window {
        approvalRouteEditor: ApprovalRouteEditor,
        APPROVAL_ROUTE_EDITOR_LOADED: string;
        APPROVAL_ROUTE_EDITOR_SUCCESSFUL_SAVE: string;
        APPROVAL_ROUTE_EDITOR_SUCCESSFUL_CANCEL: string;
        LUCIDOC_URL_REGEX: RegExp;
    }
}

interface ApprovalRouteEditorProps {
    // if loaded through react with props:
    objectType?: 'docrevid' | 'manualid' // we use the 'id' at the end for database queries, and just always include it for consistency
    objectIDs?: number[]
    // ^ Notice the complexity here, of multiple objectIDs, because you can edit the route of multiple
    // items at the same time; however this only works with props, not via iframe loading with URL params;
    // also it only works with non-active routes, since active is too complicated

    // if loaded via iframe in template toolkit (hopefully deprecated someday). Notice how objectID is singular, but
    // getApprovalRoute() will handle this just fine:
    match?: any // looks like {objectType: 'docrevid' | 'manualid', objectID: number}

    sendApprovalRouteToParent?: (objectType: string, objectIDs: number[], approvalRoute: any) => void // used by ApprovalRouting, to skip an extra API call
}

class ApprovalRouteEditor extends Component<ApprovalRouteEditorProps, any> {
    constructor(props: ApprovalRouteEditorProps) {
        super(props);
        this.state = {
            // used for conditional display:
            loadingStatus: 'loading', // can be 'loading', 'cancelling', 'saving', or 'error'
            mode: 'edit', // 'edit' or 'review'

            // Document/Manual properties:
            objectType: null, // 'docrevid' or 'manualid'; we use the 'id' at the end for the back-end queries so we're just leaving it there
            objectIDs: [],

            // Signers:
            showSignatureApprovals: false,
            signatureApprovalGroup: [], // this is a single array of Users and Groups together
            orderedSigning: false,
            unremovablePeople: {},
            unremovableGroups: {},

            // Committees:
            showCommitteeApprovals: false,
            committeeApprovalGroups: [], // this is an array of objects with an array of committees
            ratification: null,
            useCommittees: false, // whether the organization has any committees at all
            ownersCanRouteCommittees: false,
            hasRouters: false, // does the organization have committee routers (aka approval routers)
            userIsRouter: false, // is the user one of those committee routers (aka approval routers)?
            unremovableCommittees: {},

            // Phases:
            phaseOrder: [], // whether this route has signers and committees, and what order
            originalPhaseOrder: [], // to get back the old one if you click delete and then add back that phase

            // If route is Active:
            isActive: false,
            isPendingApprovalRouting: false,
            approvalSessionID: null,
            approvalSessionType: null, // review, officialize, etc
            phaseStatus: {}, // completion status of entire phase
            requestStatus: {}, // decision of each signer/group/committee
            scheduledTaskDates: {}, // if an item has been scheduled on an agenda

            // homeOrgID is used just for the Perl save() route, which happens only if route is active
            homeOrgID: null,

            // Warning messages:
            showRemoveMessageModal: false,
            removeMessage: '',

            // Drag and drop to disallow moving a committee group to earlier position than the active one
            allowCommitteeGroupDrop: true,
        };
    }

    getApprovalRoute(sendApprovalRouteToParent?: boolean) {
        this.setState({
            loadingStatus: 'loading'
        });

        // the docrevid i was using for testing was: 26780

        axios.get('/api/doc-manager/approval-route-editor/get', {
            params: {
                objectType: this.props.objectType || this.props.match.params.objectType,
                // Notice here how we're getting the approval route of the FIRST object in line; if we're only
                // editing one object, that makes sense; if we're editing multiple, it'll still get the first one
                // anyway; it would sort of make sense to just load a blank one, but we need to know the route saved
                // correctly, which is done by just doing this function (getApprovalRoute) after a save, which then
                // shows that it worked; so loading the first-in-line object after a save of multiple items will show
                // what the route looks like after a successful save
                objectID: this.props.objectIDs && this.props.objectIDs[0]
                    ? this.props.objectIDs[0]          // if loaded via React like normal with props
                    : this.props.match.params.objectID // if loaded via iframe with URL-ish params
            }
        })
            .then(res => {
                if (res.data.error) {
                    this.setState({
                        loadingStatus: 'error'
                    });
                    return;
                }
                if (sendApprovalRouteToParent && this.props.sendApprovalRouteToParent) {
                    this.props.sendApprovalRouteToParent(this.state.objectType, this.state.objectIDs, res.data);
                }

                const unremovableCommittees = this.getUnremovableCommittees((res.data.committeeApprovalGroups as COMMITTEE[][]), res.data.requestStatus, res.data.isActive);
                const { unremovablePeople, unremovableGroups } = this.getUnremovableSigners((res.data.signatureApprovalGroup as (PERSON | USERGROUP)[]), res.data.requestStatus, res.data.isActive);

                this.setState({
                    // data straight from the back end:
                    ...res.data,

                    // extra processing that must happen after load, just for this page:
                    originalPhaseOrder: res.data.phaseOrder,
                    phaseOrder: this.insertDragAndDropIdsForArrayItemsAndReturn('phase', res.data.phaseOrder),
                    committeeApprovalGroups: this.insertDragAndDropIdsForArrayItemsAndReturn('committees', res.data.committeeApprovalGroups),
                    unremovablePeople: unremovablePeople,
                    unremovableGroups: unremovableGroups,
                    unremovableCommittees: unremovableCommittees,
                    loadingStatus: 'ready',

                    // this update just doesn't happen anywhere else, so let's do it on this initial load.
                    // we could just use this.props instead of this.state, but in case someone forgets that,
                    // let's store it in both:
                    objectIDs: this.props.objectIDs || [this.props.match.params.objectID]
                }, () => {
                    this.postMessageToPotentialParentWindow(window.parent.APPROVAL_ROUTE_EDITOR_LOADED);
                    // testing to force a committee group to be complete and one to be active:
                    // let newRequestStatus = this.state.requestStatus;
                    // newRequestStatus.committees.activePhase = 1;
                    // newRequestStatus.committees.lastCompletedPhase = 0;
                    // this.setState({
                    //     requestStatus: newRequestStatus
                    // }, () => {
                    //     console.log(this.state.requestStatus)
                    // })
                }
                );

            }).catch((e: any) => {
                console.log(e);
                this.setState({
                    loadingStatus: 'error'
                });
            });
    }

    componentDidMount() {
        this.getApprovalRoute();
    }

    getUnremovableCommittees(committeeGroups: COMMITTEE[][], requestStatus: any, isActive: boolean) {
        if (!isActive) {
            return {};
        }
        // disable removal of any committee that has approved the document already:
        const unremovableCommittees: {
            [key: string]: string
        } = {};

        committeeGroups.forEach((committeeGroup: COMMITTEE[]) => {
            committeeGroup.forEach((committee: COMMITTEE) => {
                const committeeDecision =
                    requestStatus
                    && requestStatus.committees
                    && requestStatus.committees[committee.COMMITTEE_ID];

                if (committeeDecision) {
                    if (committeeDecision.includes('Approved')) { // includes() will catch 'Approved with Edits'
                        unremovableCommittees[committee.COMMITTEE_ID] = 'Cannot remove a committee that has already approved this item';
                    }
                    else if (committeeDecision === 'Scheduled') {
                        unremovableCommittees[committee.COMMITTEE_ID] = 'Cannot remove a committee that has already scheduled this item';
                    }
                }
            });
        });
        return unremovableCommittees;
    }

    getUnremovableSigners(signers: (PERSON | USERGROUP)[], requestStatus: any, isActive: boolean) {
        if (!isActive) {
            return {
                unremovablePeople: {},
                unremovableGroups: {}
            };
        }

        const unremovablePeople: {
            [key: string]: string
        } = {};
        const unremovableGroups: {
            [key: string]: string
        } = {};

        function getUserDecision(person: PERSON, requestStatus: any): string | undefined {
            return requestStatus
                && requestStatus.signers
                && requestStatus.signers.users
                && requestStatus.signers.users[person.USERID];
        }

        signers.forEach((signer: PERSON | USERGROUP) => {
            // disable removal of any person who has approved the document already:
            if (isPerson(signer)) {
                const userDecision = getUserDecision(signer, requestStatus);
                if (userDecision) {
                    if (userDecision.includes('Approved')) { // includes() will catch 'Approved with Edits'
                        unremovablePeople[signer.USERID] = 'Cannot remove a user who has already signed';
                    }
                    else if (userDecision === 'Requested Edits') {
                        unremovablePeople[signer.USERID] = 'Cannot remove a user who has requested edits';
                    }
                }
            }
            // disable removal of any group if any member has approved the document already:
            else if (isUserGroup(signer)) {
                if (signer.PEOPLE) {
                    signer.PEOPLE.some((person: PERSON) => {
                        const userDecision = getUserDecision(person, requestStatus);
                        if (userDecision) {
                            if (userDecision.includes('Approved')) { // includes() will catch 'Approved with Edits'
                                unremovableGroups[signer.USERGROUPID] = 'Cannot remove a group if a member has already signed';
                                return true;
                            }
                            else if (userDecision === 'Requested Edits') {
                                unremovableGroups[signer.USERGROUPID] = 'Cannot remove a group if a member has requested edits';
                                return true;
                            }
                        }
                        return false;
                    });
                }
            }
        });
        return {
            unremovablePeople,
            unremovableGroups
        };
    }

    insertDragAndDropIdForSingleItemAndReturn(itemKeyName: string, item: any): itemWithDragAndDropID {
        // Required for react-beautiful-dnd! Each draggable item MUST have a uniqueID, and because
        // items can be deleted and recreated, they cannot have the same ID as before (so no title,
        // and no database ID value.
        // This function adds a uniqueID to any given item, including an array (but NOT each item within the array)
        return {
            uniqueID: Math.random().toString() + Date.now().toString(),
            [itemKeyName]: item
        };
    }

    insertDragAndDropIdsForArrayItemsAndReturn(arrayName: string, array: any[]): itemWithDragAndDropID[] {
        // Adds a uniqueID to each item WITHIN a given array, but NOT on the array itself
        return array.map(item => this.insertDragAndDropIdForSingleItemAndReturn(arrayName, item));
    }

    postMessageToPotentialParentWindow(message: string) {
        if (window.parent &&
            window.parent.location.href.match(window.LUCIDOC_URL_REGEX)
        ) { // meaning it was loaded in an iframe, so it has to announce itself
            // as ready so the parent window can access its methods:
            window.approvalRouteEditor = this;
            window.parent.postMessage(message, `https://${window.parent.location.host}`);
        }
    }

    isRouteEmpty() { // used for the external iframe hosting of the Approval Route Editor
        const phaseArray = this.state.phaseOrder.map((phaseObject: phaseWithDragAndDropID) => phaseObject.phase);
        return phaseArray.length === 0;
    }

    addSignaturePhase() {
        // this newPhaseOrder part looks complicated, but it's simply adding a 'signers' entry into the
        // this.state.phaseOrder object, which is how we keep track of whether signers or committees
        // come first, if at all
        const newPhaseOrder = this.state.phaseOrder.slice();
        if (!newPhaseOrder.some((phaseObject: phaseWithDragAndDropID) => phaseObject.phase === 'signers')) {
            let pushOrUnshift = 'push';
            if (this.state.originalPhaseOrder.indexOf('signers') === 0) {
                pushOrUnshift = 'unshift';
            }
            newPhaseOrder[pushOrUnshift](
                this.insertDragAndDropIdForSingleItemAndReturn('phase', 'signers')
            );
        }
        this.setState({
            showSignatureApprovals: true,
            phaseOrder: newPhaseOrder
        }, () => {
            this.falsifyBoardRatificationIfNecessary();
        });
    }

    deleteSignaturePhase() {
        if (this.state.isActive) {
            const hasAnyoneAlreadySigned = Object.keys(this.state.requestStatus.signers.users).some((signerID) => {
                return this.state.requestStatus.signers.users[signerID].slice(0, 8) === 'Approved';
            });
            if (hasAnyoneAlreadySigned) {
                const message = `Warning: This item has already been sent for signature approval.
                    \nRemoving this approval step will void any completed approval assignments. If you resubmit this item for approval, any previously completed approvals will need to be re-gathered.
                    \nAre you sure you wish to proceed?`;
                if (!window.confirm(message)) {
                    return;
                }
            }
        }
        const newPhaseOrder = this.state.phaseOrder.slice().filter((phaseObject: phaseWithDragAndDropID) => {
            return phaseObject.phase !== 'signers';
        });
        this.setState({
            showSignatureApprovals: false,
            phaseOrder: newPhaseOrder
        }, () => {
            this.falsifyBoardRatificationIfNecessary();
        });
    }

    addCommitteeApprovalPhase() {
        const newPhaseOrder = this.state.phaseOrder.slice();
        if (!newPhaseOrder.some((phaseObject: phaseWithDragAndDropID) => phaseObject.phase === 'committees')) {
            let pushOrUnshift = 'push';
            if (this.state.originalPhaseOrder.indexOf('committees') === 0) {
                pushOrUnshift = 'unshift';
            }
            newPhaseOrder[pushOrUnshift](
                this.insertDragAndDropIdForSingleItemAndReturn('phase', 'committees')
            );
        }
        this.setState({
            showCommitteeApprovals: true,
            phaseOrder: newPhaseOrder
        }, () => {
            this.falsifyBoardRatificationIfNecessary();
        });
        // if organization has committee routers, it'll show a message that the routers will handle the rest; otherwise:
        if (!this.state.hasRouters || this.state.userIsRouter) {
            let newCommitteeApprovalGroups: COMMITTEESWithDragAndDropID[];
            // if there was a previous, "hidden" committee approval phase, display it:
            if (this.state.committeeApprovalGroups.length) {
                newCommitteeApprovalGroups = this.state.committeeApprovalGroups;
            }
            // otherwise, just add an empty one:
            else {
                newCommitteeApprovalGroups = [
                    this.insertDragAndDropIdForSingleItemAndReturn('committees', [] as COMMITTEE[]) as COMMITTEESWithDragAndDropID
                ];
            }
            this.setState({
                committeeApprovalGroups: newCommitteeApprovalGroups
            }, () => {
                this.falsifyBoardRatificationIfNecessary();
            });
        }
    }

    deleteCommitteeApprovalPhase() {
        if (this.state.isActive) {
            const hasAnyoneScheduledOrApprovedThisItem = Object.keys(this.state.requestStatus.committees).some((committeeID) => {
                if (committeeID === 'activePhase' || committeeID === 'lastCompletedPhase' || committeeID === 'notifiedPhase') {
                    return false; // because these values are held in the hashmap of committees with actual IDs for some reason
                }
                return (this.state.requestStatus.committees[committeeID].slice(0, 8) === 'Approved'
                    || this.state.requestStatus.committees[committeeID].slice(0, 9) === 'Scheduled'
                );
            });
            if (hasAnyoneScheduledOrApprovedThisItem) {
                const message = `Warning: This item has already been sent for committee approval.
                    \nRemoving this approval step will void any completed approval assignments. If you resubmit this item for approval, any previously completed approvals will need to be re-gathered.
                    \nAre you sure you wish to proceed?`;
                if (!window.confirm(message)) {
                    return;
                }
            }
        }
        const newPhaseOrder = this.state.phaseOrder.slice().filter((phaseObject: phaseWithDragAndDropID) => {
            return phaseObject.phase !== 'committees';
        });
        this.setState({
            showCommitteeApprovals: false,
            committeeApprovalGroups: this.state.committeeApprovalGroups.filter((commGroup: COMMITTEESWithDragAndDropID) => {
                return commGroup.committees.length > 0;
            }),
            phaseOrder: newPhaseOrder
        });
    }

    addCommitteeApprovalGroup() {
        const newCommitteeApprovalGroups = this.state.committeeApprovalGroups.slice();
        newCommitteeApprovalGroups.push(
            this.insertDragAndDropIdForSingleItemAndReturn('committees', [])
        );
        this.setState({
            committeeApprovalGroups: newCommitteeApprovalGroups
        }, () => {
            this.falsifyBoardRatificationIfNecessary();
        });
    }

    deleteCommitteeApprovalGroup(idx: number) {
        this.setState({
            committeeApprovalGroups: this.state.committeeApprovalGroups.filter((item: COMMITTEESWithDragAndDropID, itemIdx: number) => {
                return idx !== itemIdx;
            })
        }, () => {
            if (!this.state.hasRouters && this.state.committeeApprovalGroups.length === 0) {
                // if there are no committee groups left, eliminate the committee approval phase entirely;
                // but only if there are no committee routers, because with routers, users can create a committee
                // phase, but they can leave it empty, to let the routers handle the rest.
                this.deleteCommitteeApprovalPhase();
            }
            this.falsifyBoardRatificationIfNecessary();
        });
    }

    updateSigners(newSignatureGroup: (PERSON | USERGROUP)[]) {
        this.setState({
            signatureApprovalGroup: newSignatureGroup
        }, () => {
            this.falsifyBoardRatificationIfNecessary();
        });
    }

    updateCommitteeGroup(newCommitteeGroup: COMMITTEE[], groupNumber: number) {
        const newCommitteeApprovalGroups = this.state.committeeApprovalGroups.map((commGroup: COMMITTEESWithDragAndDropID) => {
            return {
                uniqueID: commGroup.uniqueID,
                committees: commGroup.committees.slice()
            };
        });
        newCommitteeApprovalGroups[groupNumber].committees = newCommitteeGroup;
        this.setState({
            committeeApprovalGroups: newCommitteeApprovalGroups
        }, () => {
            this.falsifyBoardRatificationIfNecessary();
        });
    }

    didWeDeleteSomeoneWhoWasNotified() {
        if (this.state.isActive) {

            const usersWhoWereNotified: number[] = [];

            this.state.signatureApprovalGroup.forEach((userOrGroup: PERSON | USERGROUP) => {
                if (isPerson(userOrGroup)) {
                    if (this.state.requestStatus.signers.users[userOrGroup.USERID] === 'Notified') {
                        if (!usersWhoWereNotified.includes(userOrGroup.USERID)) {
                            usersWhoWereNotified.push(userOrGroup.USERID);
                        }
                    }
                }
                else if (isUserGroup(userOrGroup)) {
                    userOrGroup.PEOPLE && userOrGroup.PEOPLE.forEach((person: PERSON) => {
                        if (this.state.requestStatus.signers.users[person.USERID] === 'Notified') {
                            if (!usersWhoWereNotified.includes(person.USERID)) {
                                usersWhoWereNotified.push(person.USERID);
                            }
                        }
                    });
                }
            });
            const didWeDeleteSomeoneWhoWasNotified = Object.keys(this.state.requestStatus.signers.users).some((userID) => {
                return this.state.requestStatus.signers.users[userID] === 'Notified' && !usersWhoWereNotified.includes(parseInt(userID));
            });
            if (didWeDeleteSomeoneWhoWasNotified) {
                return true;
            }

            const didWeDeleteAGroupThatWasNotified = Object.keys(this.state.requestStatus.signers.groups).some((groupID) => {
                if (this.state.requestStatus.signers.groups[groupID] === 'Notified') {
                    return !this.state.signatureApprovalGroup.some((personOrGroup: PERSON | USERGROUP) => {
                        if (isUserGroup(personOrGroup)) {
                            return personOrGroup.USERGROUPID.toString() === groupID.toString();
                        }
                        return false;
                    });
                }
                return false;
            });

            if (didWeDeleteAGroupThatWasNotified) {
                return true;
            }

            const didWeDeleteACommitteeThatWasNotified = Object.keys(this.state.requestStatus.committees).some((committeeID) => {
                if (this.state.requestStatus.committees[committeeID] === 'Notified'
                    || this.state.requestStatus.committees[committeeID] === 'Scheduled'
                ) {
                    return !this.state.committeeApprovalGroups.some((commGroup: COMMITTEESWithDragAndDropID) => {
                        return commGroup.committees.some((internalCommittee: COMMITTEE) => {
                            return internalCommittee.COMMITTEE_ID.toString() === committeeID.toString();
                        });
                    });
                }
                return false;
            });

            if (didWeDeleteACommitteeThatWasNotified) {
                return true;
            }

        }
        return false;
    }

    cancelApprovals() {
        if (this.state.objectIDs && this.state.objectIDs.length > 1) {
            // don't allow cancel if we're editing multiple routes at once; too much can go wrong
            return;
        }

        let variableMessageContent = 'stop the document from being archived';

        if (this.state.approvalSessionType === 'officialize') {
            variableMessageContent = 'return the document to In Preparation';
        }
        else if (this.state.approvalSessionType === 'review') {
            variableMessageContent = 'stop the document from being marked as reviewed';
        }

        const message = `Warning: Cancelling this approval route will void any completed approval assignments and ${variableMessageContent}.
            \nIf you resubmit this item for approval, any previously completed approvals will need to be re-gathered.
            \nAre you sure you wish to proceed?`;
        if (!window.confirm(message)) {
            return;
        }

        this.setState({
            loadingStatus: 'cancelling',
            showRemoveMessageModal: false
        });

        const dataForCancelFunction = {
            objectType: this.state.objectType,
            objectID: this.state.objectIDs[0], // if we're at this point, there's only one, because we had a return at the top of this function otherwise
            approvalSessionID: this.state.approvalSessionID
        };

        axios.post('/api/doc-manager/approval-route-editor/cancel', dataForCancelFunction)
            .then(res => {
                if (res.data.error) {
                    this.setState({
                        loadingStatus: 'error'
                    });
                    return;
                }

                if (res.data.email_to.length) {
                    // we are handling this on the front end because of a communication problem between
                    // node and perl. if the node can talk to the perl, then this post request should
                    // happen on the back end instead, as part of the cancel() function.
                    axios.post('/ajax/approval_session.pl', {
                        approvalSessionID: res.data.approvalSessionID,
                        action: 'just_send_emails',
                        email_to: res.data.email_to,
                        email_type: res.data.email_type,
                        doc_title: res.data.doc_title,
                        revno: res.data.revno && res.data.revno.trim() // null check because it might be a manual with no revno
                    })
                        .catch((e) => {
                            console.log(e);
                        });
                }
                // The Manuals page editcat.tt needs to be reloaded after a cancel so its sidebar buttons reflect the
                // active vs inactive state:
                this.postMessageToPotentialParentWindow(window.parent.APPROVAL_ROUTE_EDITOR_SUCCESSFUL_CANCEL);

                this.getApprovalRoute(); // fix this later. this should all be in one trip return from the cancel node route
            })
            .catch(e => {
                console.log(e);
                this.setState({
                    loadingStatus: 'error'
                });
            });

    }

    showSaveConfirmations() {
        this.setState({
            loadingStatus: 'saving'
        }, () => {
            if (this.didWeDeleteSomeoneWhoWasNotified()) {
                this.setState({
                    showRemoveMessageModal: true,
                });
            }
            else {
                this.saveApprovalRoute(false);
            }
        });
    }

    saveApprovalRoute(sendPostMessageToParentIframeOnSave: boolean) {
        if (this.state.isActive
            && !this.state.isPendingApprovalRouting // pending approval routing is in a state of "semi-active," but before any decisions have been made, so that's ok to edit
            && this.state.objectIDs
            && this.state.objectIDs.length > 1
        ) {
            return; // way too complicated to edit/save multiple active routes at once, so just disallow
        }
        this.setState({
            loadingStatus: 'saving',
            showRemoveMessageModal: false,
        });

        this.setState({
            // remove empty committee approval groups (but not the phase itself):
            committeeApprovalGroups: this.state.committeeApprovalGroups.filter((committeeApprovalGroup: COMMITTEESWithDragAndDropID) => {
                return committeeApprovalGroup.committees.length > 0;
            }),
            // remove signature phase if empty:
            showSignatureApprovals: this.state.signatureApprovalGroup.length > 0,
            // update phaseOrder if necessary:
            phaseOrder: this.state.phaseOrder.filter((phaseItem: phaseWithDragAndDropID) => {
                if (phaseItem.phase === 'committees') {
                    return phaseItem;
                }
                else if (phaseItem.phase === 'signers' && this.state.signatureApprovalGroup.length > 0) {
                    return phaseItem;
                }
                return false;
            })
        }, () => {

            if (this.state.isActive && !this.state.isPendingApprovalRouting) {
                // the old code was hitting the Perl route, but only for active approval sessions.
                // we're doing the same thing here because the Perl route is absolutely huge and needs
                // to be rewritten from scratch for TypeScript and TypeORM.

                const signers = this.state.signatureApprovalGroup.map((signer: PERSON | USERGROUP, idx: number) => {
                    if (isPerson(signer)) {
                        const signerStatusValues: Readonly<{
                            [key: number]: string
                        }> = {
                            1: 'active',
                            2: 'inactive'
                        };
                        return {
                            id: signer.USERID,
                            name: constructFullName(signer, 'lastFirstMiddle'),
                            status: signerStatusValues[signer.STATUSID],
                            type: 'user',
                            department_name: signer.DEPARTMENT_NAME,
                            home_organization_name: null, // do we need this? probably not
                            home_organization_id: signer.ORGANIZATIONID,
                            signorder: this.state.orderedSigning ? idx + 1 : 1
                        };
                    }
                    else if (isUserGroup(signer)) {
                        return {
                            home_organizationid: this.state.homeOrgID, // because you can't select groups from another org anyway
                            id: signer.USERGROUPID,
                            name: signer.NAME,
                            type: 'group',
                            signorder: this.state.orderedSigning ? idx + 1 : 1,
                            // for some reason the old perl used both user names and users (which had the IDs).
                            // I assume it only needs the 2nd list with only the userids, but leave it for later
                            // when we switch to node and don't need the Perl route.
                            user_names: signer.PEOPLE
                                ? signer.PEOPLE.map((person: PERSON) => {
                                    return constructFullName(person, 'lastFirstMiddle');
                                })
                                : [],
                            users: signer.PEOPLE
                                ? signer.PEOPLE.map((person: PERSON) => {
                                    return {
                                        name: constructFullName(person, 'lastFirstMiddle'),
                                        userid: person.USERID
                                    };
                                })
                                : []
                        };
                    }
                    return undefined;
                });

                const comittees = this.state.committeeApprovalGroups.map((commGroupPlusDragNDropID: COMMITTEESWithDragAndDropID) => {
                    return commGroupPlusDragNDropID.committees.map((committee: COMMITTEE) => {
                        return {
                            name: committee.NAME,
                            id: committee.COMMITTEE_ID,
                            type: 'committee',
                            home_organizationid: this.state.homeOrgID,
                            // I don't know if we need the usernames here. I think this was probably there
                            // because the GET route for the old version got them to display them as a tooltip,
                            // but it sends them back for the save() function, seemingly for no reason. The code
                            // could simply use the committee id and email the corresponding members, but I'm not
                            // sure if it does that. Look into it.
                            user_names: committee.PEOPLE ?
                                committee.PEOPLE
                                    .sort((a: PERSON, b: PERSON) => {
                                        return sortLastNameThenFirst(a, b);
                                    })
                                    .map((person: PERSON) => {
                                        return constructFullName(person, 'lastFirstMiddle');
                                    })
                                : [],
                        };
                    });
                });

                const formattedDataForPerlSaveRoute = {
                    action: 'save',
                    objectType: this.state.objectType,
                    objectID: this.state.objectIDs[0], // if we're here it's because we only had one; there's a return at the top of the function otherwise
                    approvalSessionID: this.state.approvalSessionID,
                    ratification: this.state.ratification ? 1 : 0, // perl doesn't have booleans
                    orderedSigning: this.state.orderedSigning ? 1 : 0, // perl doesn't have booleans
                    phases: this.state.phaseOrder.map((phaseItem: phaseWithDragAndDropID) => phaseItem.phase),
                    signers: signers,
                    committees: comittees,
                    removeMessage: this.state.removeMessage,
                };
                
                axios('/ajax/approval_session.pl', {
                    method: 'POST',
                    data: formattedDataForPerlSaveRoute
                })
                    .then(res => {
                        if (res.data.error) {
                            this.setState({
                                loadingStatus: 'error'
                            });
                            return;
                        }
                        if (sendPostMessageToParentIframeOnSave) {
                            this.postMessageToPotentialParentWindow(window.APPROVAL_ROUTE_EDITOR_SUCCESSFUL_SAVE);
                        }
                        else {
                            this.getApprovalRoute(!!this.props.sendApprovalRouteToParent);
                        }
                    })
                    .catch(e => {
                        console.log(e);
                        this.setState({
                            loadingStatus: 'error'
                        });
                    });
            }

            else if (!this.state.isActive || this.state.isPendingApprovalRouting) { // AKA neither signatures/committees have started yet
                // this hits the new Node route because it is a lot easier than an active route. Active routes
                // (just above this) use the existing Perl code until we rewrite all of it.
                (this.state.objectIDs as number[]).forEach((objectID, idx) => {

                    const dataToSave = {
                        objectType: this.state.objectType,
                        objectID: objectID,
                        ratification: this.state.ratification,
                        orderedSigning: this.state.orderedSigning,
                        phases: this.state.phaseOrder.map((phaseItem: phaseWithDragAndDropID) => {
                            return phaseItem.phase; // this is to remove the unique ID required for drag and drop
                        }),
                        signers: this.state.signatureApprovalGroup.map((signer: PERSON | USERGROUP) => {
                            if (isPerson(signer)) {
                                return signer;
                            }
                            // remove "PEOPLE" from usergroups before sending:
                            else if (isUserGroup(signer)) {
                                return {
                                    NAME: signer.NAME,
                                    USERGROUPID: signer.USERGROUPID,
                                };
                            }
                            return undefined;
                        }),
                        committees: this.state.committeeApprovalGroups.map((commGroupWithDragAndDropID: COMMITTEESWithDragAndDropID) => {
                            // go down to .committees to remove the drag and drop unique ID:
                            return commGroupWithDragAndDropID.committees.map((committee: COMMITTEE) => {
                                // remove unnecessary data before sending (especially the "people" array which can be huge)
                                return {
                                    COMMITTEE_ID: committee.COMMITTEE_ID
                                };
                            });
                        })
                    };

                    axios.post('/api/doc-manager/approval-route-editor/save/inactive', dataToSave)
                        .then(res => {
                            if (res.data.error) {
                                this.setState({
                                    loadingStatus: 'error'
                                });
                                return;
                            }
                            if (idx > 0) return; // we don't need to reload the approval route for the whole set of simultaneously-edited approval routes; just the sample/first one
                            if (sendPostMessageToParentIframeOnSave) {
                                this.postMessageToPotentialParentWindow(window.parent.APPROVAL_ROUTE_EDITOR_SUCCESSFUL_SAVE);
                            } else {
                                this.getApprovalRoute(!!this.props.sendApprovalRouteToParent);
                            }
                        })
                        .catch(e => {
                            console.log(e);
                            this.setState({
                                loadingStatus: 'error'
                            });
                        });
                });
            }
        });
    }


    toggleOrderedSigning(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            orderedSigning: e.currentTarget.checked
        });
    }

    toggleBoardRatification(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            ratification: e.currentTarget.checked
        });
    }

    toggleReviewMode() {
        this.setState({
            mode: this.state.mode === 'edit' ? 'review' : 'edit'
        });
    }

    toggleRemoveModal() {
        this.setState({
            showRemoveMessageModal: !this.state.showRemoveMessageModal
        }, () => {
            if (!this.state.showRemoveMessageModal) {
                this.setState({
                    loadingStatus: 'ready',
                    // removeMessage: ''
                });
            }
        });
    }

    updateRemoveMessage(e: React.ChangeEvent<HTMLTextAreaElement>) {
        this.setState({
            removeMessage: e.currentTarget.value
        });
    }

    movePersonOrGroup(result: any) {
        const draggableType = result.draggableId.replace(/[0-9]+/, '');
        if (draggableType === 'person' || draggableType === 'group') {
            if (result.destination.droppableId === 'usersPlusGroupsDropZone') {
                const oldIndex = result.source.index;
                const newIndex = result.destination.index;
                if (newIndex < this.state.requestStatus.signers.minimumDragAndDropIndex - 1) {
                    // minimumDragAndDropIndex is based on SIGN_ORDER, which is a database value which thinks arrays start at 1,
                    // so we subtract 1 to match
                    return;
                }
                const newArray = this.state.signatureApprovalGroup.slice();
                const [objectToMove] = newArray.splice(oldIndex, 1);
                newArray.splice(newIndex, 0, objectToMove);

                this.setState({
                    signatureApprovalGroup: newArray
                });
            }
        }
    }

    moveCommittee(result: any) {
        const dropZoneType = result.destination.droppableId.replace(/[0-9]+/, '');
        if (dropZoneType === 'committeeDropZone') {
            const oldIndex = result.source.index;
            const newIndex = result.destination.index;
            const targetGroupNumber = parseInt(result.destination.droppableId.replace(/[a-zA-Z]+/, ''));
            const originalGroupNumber = parseInt(result.source.droppableId.replace(/[a-zA-Z]+/, ''));
            const newGroups: COMMITTEESWithDragAndDropID[] = [];
            this.state.committeeApprovalGroups.forEach((committeeApprovalGroup: COMMITTEESWithDragAndDropID, idx: number) => {
                newGroups[idx] = {
                    uniqueID: committeeApprovalGroup.uniqueID,
                    committees: committeeApprovalGroup.committees.slice()
                };
            });
            const [objectToMove] = newGroups[originalGroupNumber].committees.splice(oldIndex, 1);
            newGroups[targetGroupNumber].committees.splice(newIndex, 0, objectToMove);

            this.setState({
                committeeApprovalGroups: newGroups
            }, () => {
                this.falsifyBoardRatificationIfNecessary();
            });
        }
    }

    hasAnyCommitteeInThisCommitteeGroupScheduledOrApprovedThisItem(committees: COMMITTEE[]) {
        if (!this.state.isActive)
            return false;

        return committees.some(committee => {
            return (
                this.state.requestStatus
                && this.state.requestStatus.committees[committee.COMMITTEE_ID]
                && this.state.requestStatus.committees[committee.COMMITTEE_ID] !== 'Notified'
            );
        });
    }

    isBoardRatificationDisabledAndWhy(): string { // returns an empty string if ratification is enabled; if disabled, returns a string explaining why
        let indexOfCommitteeGroupWithBoard = -1; // <-- also a boolean to see if the board is here at all, but we need the index too
        let isThereAnyCommitteeBesidesTheBoard = false;

        this.state.committeeApprovalGroups.forEach((committeeGroup: COMMITTEESWithDragAndDropID, idx: number) => {
            committeeGroup.committees.forEach((committee: COMMITTEE) => {
                if (committee.IS_BOARD) {
                    indexOfCommitteeGroupWithBoard = idx;
                }
                else {
                    isThereAnyCommitteeBesidesTheBoard = true;
                }
            });
        });

        const isThereASigner: boolean = this.state.showSignatureApprovals && this.state.signatureApprovalGroup.length > 0;
        const isBoardCommitteeInFinalApprovalPhase: boolean = this.state.phaseOrder.length && this.state.phaseOrder[this.state.phaseOrder.length - 1].phase === 'committees';
        const isBoardCommitteeInFinalCommitteeApprovalGroup: boolean = indexOfCommitteeGroupWithBoard === this.state.committeeApprovalGroups!.length - 1;
        const isBoardCommitteeByItselfInFinalCommitteeApprovalGroup: boolean = indexOfCommitteeGroupWithBoard > -1 && this.state.committeeApprovalGroups[indexOfCommitteeGroupWithBoard].committees.length === 1;

        let boardRatificationCheckboxTooltip = '';

        if (!isThereASigner && !isThereAnyCommitteeBesidesTheBoard) {
            boardRatificationCheckboxTooltip = 'Board ratification must be preceded by a signature or committee approval.';
        }
        else if (!isBoardCommitteeInFinalApprovalPhase) {
            boardRatificationCheckboxTooltip = 'Board committee must be in the final approval step to enable board ratification.';
        }
        else if (!isBoardCommitteeInFinalCommitteeApprovalGroup) {
            boardRatificationCheckboxTooltip = 'Board committee must be in the final committee approval group to enable board ratification.';
        }
        else if (!isBoardCommitteeByItselfInFinalCommitteeApprovalGroup) {
            boardRatificationCheckboxTooltip = 'Board committee must be the only committee in the final committee group to enable ratification.';
        }

        return boardRatificationCheckboxTooltip;
    }

    falsifyBoardRatificationIfNecessary() {
        if (this.isBoardRatificationDisabledAndWhy()) {
            this.setState({
                ratification: false
            });
        }
    }

    moveCommitteeGroup(result: any) {
        const oldIndex = result.source.index;
        const newIndex = result.destination.index;

        const isDragConflictingWithActivePhase = (): boolean => (
            newIndex < this.state.requestStatus.committees.activePhase
            || oldIndex < this.state.requestStatus.committees.activePhase
            || this.hasAnyCommitteeInThisCommitteeGroupScheduledOrApprovedThisItem(this.state.committeeApprovalGroups[newIndex].committees)
        );

        if (this.state.isActive && isDragConflictingWithActivePhase())
            return;

        const newCommitteeApprovalGroups: COMMITTEESWithDragAndDropID[] = [];
        this.state.committeeApprovalGroups.forEach((commGroup: COMMITTEESWithDragAndDropID, idx: number) => {
            newCommitteeApprovalGroups[idx] = {
                uniqueID: commGroup.uniqueID,
                committees: commGroup.committees.slice()
            };
        });
        const [committeeGroupToMove] = newCommitteeApprovalGroups.splice(oldIndex, 1);
        newCommitteeApprovalGroups.splice(newIndex, 0, committeeGroupToMove);

        this.setState({
            committeeApprovalGroups: newCommitteeApprovalGroups
        }, () => {
            this.falsifyBoardRatificationIfNecessary();
        });
    }

    reorderSignersVsCommittees(result: any) {
        const oldIndex = result.source.index;
        const newIndex = result.destination.index;
        const newPhaseOrder = this.state.phaseOrder.map((phaseObject: phaseWithDragAndDropID) => {
            return {
                uniqueID: phaseObject.uniqueID,
                phase: phaseObject.phase
            };
        });
        const [signersVsCommittees] = newPhaseOrder.splice(oldIndex, 1);
        newPhaseOrder.splice(newIndex, 0, signersVsCommittees);

        this.setState({
            phaseOrder: newPhaseOrder
        }, () => {
            this.falsifyBoardRatificationIfNecessary();
        });
    }

    onDragUpdate(currentDragState: DragUpdate) {
        if (!this.state.allowCommitteeGroupDrop) {
            this.setState({
                allowCommitteeGroupDrop: true
            });
        }

        if (currentDragState
            && currentDragState.destination
            && currentDragState.destination.droppableId
            && currentDragState.destination.droppableId === 'committeeGroupDropZone'
            && this.state.requestStatus
            && this.state.requestStatus.committees
            && this.state.requestStatus.committees.activePhase
            && currentDragState.destination.index <= this.state.requestStatus.committees.activePhase
            && this.hasAnyCommitteeInThisCommitteeGroupScheduledOrApprovedThisItem(
                this.state.committeeApprovalGroups[currentDragState.destination.index].committees
            )
        ) {
            this.setState({
                allowCommitteeGroupDrop: false
            });
        }
    }

    onDragEnd(result: any) {
        if (!result || !result.destination || !result.source || !result.draggableId) {
            return;
        }

        if (result.type === 'userpicker-person-or-group' || result.type === 'userpicker-committee') {
            const draggableType = result.draggableId.replace(/[0-9]+/, '');

            if (draggableType === 'person' || draggableType === 'group') {
                this.movePersonOrGroup(result);
            }

            else if (draggableType === 'committee') {
                this.moveCommittee(result);
            }
        }
        else if (result.type === 'committeeGroup') {
            this.moveCommitteeGroup(result);
        }
        else if (result.type === 'approvalRouteEditorDropZone') {
            this.reorderSignersVsCommittees(result);
        }
    }

    canEditApprovalRoute() {
        if (!this.state.isActive) {
            return true;
        }

        const isApprovalRouter = !this.state.hasRouters || this.state.userIsRouter;
        return isApprovalRouter;
    }

    render() {
        let signatureApprovals: JSX.Element | null = null;
        if (this.state.showSignatureApprovals) {
            const signersIndex = this.state.phaseOrder.map((phase: phaseWithDragAndDropID) => phase.phase).indexOf('signers');

            if (signersIndex > -1) { // occasionally signers would exist but the approval route template giving the index
                // of their place in the signing order (the variable called phaseOrder) would not; therefore we have this
                // check in addition to showSignatureApprovals, which can be toggled on and off, and therefore must be independent.
                // We need to know the signersIndex to create unique IDs for the drag-and-drop package.
                let statusIndicator: JSX.Element | null = null;
                let isSignaturePhaseActive = false;
                let isSignaturePhaseComplete = false;

                if (this.state.isActive
                    && this.state.phaseStatus
                    && this.state.phaseStatus.signers
                ) {
                    let statusColor: string = 'black';
                    if (this.state.phaseStatus.signers === 'Active') {
                        isSignaturePhaseActive = true;
                        statusColor = 'darkorange';
                    }
                    else if (this.state.phaseStatus.signers === 'Complete') {
                        isSignaturePhaseComplete = true;
                        statusColor = 'green';
                    }
                    statusIndicator = (<span style={{ color: statusColor }}>({this.state.phaseStatus.signers})</span>);
                }
                signatureApprovals = (
                    <Draggable
                        draggableId={'signatureApprovalsDraggable' + this.state.phaseOrder[signersIndex].uniqueID}
                        index={signersIndex}
                        key={'signatureApprovalsDraggable' + this.state.phaseOrder[signersIndex].uniqueID}
                        isDragDisabled={(this.state.isActive && !this.state.isPendingApprovalRouting) || this.state.phaseOrder.length < 2}
                    >
                        {provided => (
                            <div className="approval-route-editor-approval-blockset"
                                {...provided.draggableProps}
                                ref={provided.innerRef}
                            >
                                <div className="approval-route-editor-heading-line">
                                    <h2 {...provided.dragHandleProps} style={{ fontWeight: 'normal' }}>
                                        {this.state.phaseOrder.length > 1 &&
                                            <Tooltip title={this.state.isActive && !this.state.isPendingApprovalRouting ? 'Cannot reorder phases once approvals are active' : ''}>
                                                <Sort
                                                    className={
                                                        'icon ' + (this.state.isActive && !this.state.isPendingApprovalRouting ? 'item-disabled' : '')
                                                    }

                                                />
                                            </Tooltip>
                                        }
                                        Signatures {statusIndicator}
                                    </h2>
                                    <label
                                        htmlFor="orderedSigning"
                                        className="approval-route-editor-heading-label"
                                    >
                                        <input
                                            type="checkbox"
                                            id="orderedSigning"
                                            checked={this.state.orderedSigning}
                                            onChange={(e) => this.toggleOrderedSigning(e)}
                                        />
                                        <span className="approval-route-editor-light-text">
                                            Ordered
                                        </span>
                                    </label>
                                    <Tooltip
                                        title={isSignaturePhaseActive || isSignaturePhaseComplete ? 'Cannot remove an active or completed approval phase.\nPlease cancel approvals first.' : ''}
                                    >
                                        <Cancel
                                            className={'approval-route-editor-cancel-icon' + (isSignaturePhaseActive || isSignaturePhaseComplete ? ' item-disabled' : '')}
                                            onClick={isSignaturePhaseActive || isSignaturePhaseComplete ? () => { } :
                                                this.deleteSignaturePhase.bind(this)
                                            }
                                        />
                                    </Tooltip>
                                </div>
                                <UserPicker
                                    addUserSignature={this.updateSigners.bind(this)}
                                    removeUserSignature={this.updateSigners.bind(this)}
                                    addGroupSignature={this.updateSigners.bind(this)}
                                    removeGroupSignature={this.updateSigners.bind(this)}
                                    selectedUsersPlusGroups={this.state.signatureApprovalGroup}
                                    allowedPrivileges={['signer']}
                                    getUsersWithAdvancedDetails={true}
                                    getGroups={true}
                                    unremovablePeople={this.state.unremovablePeople}
                                    unremovableGroups={this.state.unremovableGroups}
                                    disabled={this.state.phaseStatus && this.state.phaseStatus.signers === 'Complete'}
                                    isDragDisabled={!this.state.orderedSigning}
                                    minimumDragAndDropIndex={this.state.requestStatus.signers.minimumDragAndDropIndex - 1 /* because UserPicker arrays start at 0 */}
                                />
                                {/*{provided.placeholder}*/}
                            </div>
                        )}
                    </Draggable>
                );
            }
        }
        let committeeHasRoutersMessage: JSX.Element | null = null;
        let committeeApprovalGroups: JSX.Element | null = null;

        if (this.state.hasRouters && !this.state.userIsRouter && !this.state.ownersCanRouteCommittees) {
            committeeHasRoutersMessage = (
                <div className="approval-route-editor-light-text center-me approval-route-editor-committee-group-box">
                    <p>Your organization's Approval Router will select the committee approval route for this item.</p>
                    <p>You do not need to take any further action.</p>
                </div>
            );
        }
        else if (this.state.hasRouters && (this.state.userIsRouter || this.state.ownersCanRouteCommittees) && !this.state.committeeApprovalGroups.length) {
            committeeHasRoutersMessage = (
                <div className="approval-route-editor-light-text center-me approval-route-editor-committee-group-box">
                    <p>Your organization's Approval Router will select the committee approval route for this item.</p>
                    <p>You do not need to take any further action. You can specify a route but it is not required.</p>
                </div>
            );
        }
        else {
            committeeApprovalGroups = this.state.committeeApprovalGroups.map((committeeGroup: COMMITTEESWithDragAndDropID, idx: number) => {

                const containsBoard = committeeGroup.committees.some((committee: COMMITTEE) => {
                    return committee.IS_BOARD === 1;
                });

                const isCommitteePhaseComplete = this.state.phaseStatus.committees === 'Complete';

                const isCommitteeGroupComplete = (
                    this.state.isActive
                    && idx < this.state.requestStatus.committees.activePhase
                );

                const isCommitteeGroupCurrentlyActive = (
                    this.state.isActive
                    && idx === this.state.requestStatus.committees.activePhase
                );

                const hasAnyoneMadeADecision = (
                    this.state.isActive
                    && committeeGroup.committees.some((thisCommittee: COMMITTEE) => {
                        return this.state.requestStatus
                            && this.state.requestStatus.committees[thisCommittee.COMMITTEE_ID] === 'Approved'; // todo: check to see how this works because I don't know the numeric status IDs and what they mean
                    })
                );
                const hasAnyoneScheduledThisItem = (
                    this.state.isActive
                    && committeeGroup.committees.some((thisCommittee: COMMITTEE) => {
                        return this.state.requestStatus
                            && this.state.requestStatus.committees[thisCommittee.COMMITTEE_ID] === 'Scheduled';
                    })
                );

                let statusIndicator: JSX.Element | null = null;
                let reasonYouCannotReorderCommitteeGroup = '';
                let reasonYouCannotDeleteCommitteeGroup = '';

                if (isCommitteePhaseComplete || isCommitteeGroupComplete) {
                    statusIndicator = (<span style={{ color: 'green' }}>(Complete)</span>);
                    reasonYouCannotReorderCommitteeGroup = 'Cannot reorder a committee group if approvals are complete';
                    reasonYouCannotDeleteCommitteeGroup = 'Cannot remove a committee group if approvals are complete';
                }
                else if (isCommitteeGroupCurrentlyActive) {
                    statusIndicator = (<span style={{ color: 'darkorange' }}>(Active)</span>);
                    if (hasAnyoneMadeADecision || hasAnyoneScheduledThisItem) {
                        reasonYouCannotReorderCommitteeGroup = 'Cannot reorder a committee group if a committee has approved or scheduled this item';
                        reasonYouCannotDeleteCommitteeGroup = 'Cannot remove a committee group if a committee has approved or scheduled this item';
                    }
                }

                const isCommitteeGroupActiveAndAlsoTheOnlyOneLeft = (
                    this.state.isActive
                    && this.state.phaseStatus.committees === 'Active'
                    && this.state.committeeApprovalGroups.length === 1
                );

                if (isCommitteeGroupActiveAndAlsoTheOnlyOneLeft) {
                    reasonYouCannotDeleteCommitteeGroup = 'Cannot remove the only committee group during an active committee phase.\nPlease cancel approvals first.';
                }

                const isCommitteeGroupDragDisabled = (
                    isCommitteePhaseComplete
                    || isCommitteeGroupComplete
                    || hasAnyoneMadeADecision
                    || hasAnyoneScheduledThisItem
                    || this.state.committeeApprovalGroups.length < 2
                );

                const isCommitteeGroupRemovalDisabled = (
                    isCommitteePhaseComplete
                    || isCommitteeGroupComplete
                    || hasAnyoneMadeADecision
                    || hasAnyoneScheduledThisItem
                    || isCommitteeGroupActiveAndAlsoTheOnlyOneLeft
                );

                const whyRatificationIsDisabled = this.isBoardRatificationDisabledAndWhy();
                const isRatificationDisabled = whyRatificationIsDisabled.length > 0;

                return (
                    <Draggable
                        draggableId={'committee-approval-group' + committeeGroup.uniqueID}
                        index={idx}
                        key={'committee-approval-group' + committeeGroup.uniqueID}
                        isDragDisabled={isCommitteeGroupDragDisabled}
                    >
                        {provided => (
                            <div
                                id={'comm-app-group-' + idx}
                                className="approval-route-editor-committee-group-box"
                                {...provided.draggableProps}
                                ref={provided.innerRef}
                            >
                                <div className="approval-route-editor-committee-group-header-row">
                                    <h4 {...provided.dragHandleProps} style={{ fontWeight: 'normal' }}>
                                        {this.state.committeeApprovalGroups.length > 1 &&
                                            <Tooltip
                                                title={reasonYouCannotReorderCommitteeGroup}
                                            >
                                                <Sort
                                                    className={
                                                        'icon' + (isCommitteeGroupDragDisabled ? ' item-disabled' : '')
                                                    }
                                                />
                                            </Tooltip>
                                        }
                                        &nbsp; Group {idx + 1} {statusIndicator}
                                    </h4>
                                    {containsBoard &&
                                        <label
                                            htmlFor="boardRatification"
                                            className="approval-route-editor-heading-label"
                                            title={whyRatificationIsDisabled}
                                        >
                                            <input
                                                type="checkbox"
                                                id="boardRatification"
                                                checked={this.state.ratification}
                                                onChange={(e) => this.toggleBoardRatification(e)}
                                                disabled={isRatificationDisabled}
                                            />
                                            <span className={'approval-route-editor-light-text' + (isRatificationDisabled ? ' item-disabled' : '')}>
                                                Board Ratification
                                            </span>
                                        </label>
                                    }
                                    <Tooltip
                                        title={reasonYouCannotDeleteCommitteeGroup}
                                    >
                                        <Cancel
                                            className={'approval-route-editor-cancel-icon approval-route-editor-small-icon'
                                                + (isCommitteeGroupRemovalDisabled ? ' item-disabled' : '')
                                            }

                                            onClick={isCommitteeGroupRemovalDisabled ? () => { } :
                                                this.deleteCommitteeApprovalGroup.bind(this, idx)
                                            }
                                        />
                                    </Tooltip>

                                </div>
                                <UserPicker
                                    addCommitteeSignature={this.updateCommitteeGroup.bind(this)}
                                    removeCommitteeSignature={this.updateCommitteeGroup.bind(this)}
                                    selectedCommittees={this.state.committeeApprovalGroups[idx].committees}
                                    committeeApprovalGroupsMasterList={this.state.committeeApprovalGroups.map((commGroup: COMMITTEESWithDragAndDropID) => commGroup.committees)}
                                    groupNumber={idx}
                                    getCommittees={true}
                                    unremovableCommittees={this.state.unremovableCommittees}
                                    disabled={isCommitteePhaseComplete
                                        || (this.state.requestStatus && this.state.requestStatus.committees && idx < this.state.requestStatus.committees.activePhase)
                                    }
                                />
                                {/*{provided.placeholder}*/}
                            </div>
                        )}
                    </Draggable>
                );
            });
        }

        let reasonYouCannotDeleteCommitteePhase = '';
        let isCommitteePhaseActive = false;
        let isCommitteePhaseComplete = false;

        if (this.state.phaseStatus && this.state.phaseStatus.committees) {
            if (this.state.phaseStatus.committees === 'Active') {
                isCommitteePhaseActive = true;
                reasonYouCannotDeleteCommitteePhase = 'Active phases cannot be removed. Please cancel approvals first.';
            }
            else if (this.state.phaseStatus.committees === 'Complete') {
                isCommitteePhaseComplete = true;
                reasonYouCannotDeleteCommitteePhase = 'Complete phases cannot be removed. Please cancel approvals first.';
            }
        }

        const committeeApprovalPhase = (
            <Draggable
                draggableId='committeeApprovalsDraggable'
                index={this.state.phaseOrder[0] && this.state.phaseOrder[0].phase === 'committees' ? 0 : 1}
                key={'committeeApprovalsDraggable'}
                isDragDisabled={(this.state.isActive && !this.state.isPendingApprovalRouting) || this.state.phaseOrder.length < 2}
            >
                {provided => (
                    <div className="approval-route-editor-approval-blockset"
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                    >
                        <div className="approval-route-editor-heading-line">
                            <h2 {...provided.dragHandleProps} style={{ fontWeight: 'normal' }}>
                                {this.state.phaseOrder.length > 1 &&
                                    <Tooltip
                                        title={
                                            this.state.isActive && !this.state.isPendingApprovalRouting ? 'Cannot reorder phases once approvals are active' : ''
                                        }
                                    >
                                        <Sort
                                            className={
                                                'icon ' + (this.state.isActive && !this.state.isPendingApprovalRouting ? 'item-disabled' : '')
                                            }

                                        />
                                    </Tooltip>
                                }
                                Committee Approvals
                            </h2>
                            <IconButton
                                text={'ADD GROUP'}
                                title={isCommitteePhaseComplete ? 'Cannot add commmittees if the committee phase is complete' : ''}
                                icon={<GroupAdd />}
                                iconColor={'green'}
                                hide={this.state.hasRouters && !this.state.userIsRouter && !this.state.ownersCanRouteCommittees}
                                disabled={isCommitteePhaseComplete}
                                onClick={isCommitteePhaseComplete ? () => { } :
                                    this.addCommitteeApprovalGroup.bind(this)
                                }
                            />
                            <Tooltip
                                title={isCommitteePhaseActive || isCommitteePhaseComplete ? reasonYouCannotDeleteCommitteePhase : ''}
                            >
                                <Cancel
                                    className={'approval-route-editor-cancel-icon' + (isCommitteePhaseActive || isCommitteePhaseComplete ? ' item-disabled' : '')}
                                    onClick={isCommitteePhaseActive || isCommitteePhaseComplete ? () => { } :
                                        this.deleteCommitteeApprovalPhase.bind(this)
                                    }
                                />
                            </Tooltip>
                        </div>
                        <Droppable
                            droppableId={'committeeGroupDropZone'}
                            type={'committeeGroup'}
                            isDropDisabled={!this.state.allowCommitteeGroupDrop}
                        >
                            {provided => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    {committeeHasRoutersMessage ? committeeHasRoutersMessage : committeeApprovalGroups}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                        {/*{provided.placeholder}*/}
                    </div>
                )}
            </Draggable>
        );

        const isSignatureSessionActiveButEmpty =
            this.state.phaseStatus
            && this.state.phaseStatus.signers === 'Active'
            && this.state.signatureApprovalGroup.length === 0;

        const isCommitteeApprovalSessionActiveButEmpty = (
            this.state.phaseStatus
            && this.state.phaseStatus.committees === 'Active'
            && !this.state.committeeApprovalGroups.some((commGroup: COMMITTEESWithDragAndDropID) => {
                return commGroup.committees.length > 0;
            })
        );

        let saveButtonTitleText = '';
        if (isSignatureSessionActiveButEmpty) {
            saveButtonTitleText = 'Cannot save an empty, active signature session.';
        }
        else if (isCommitteeApprovalSessionActiveButEmpty) {
            saveButtonTitleText = 'Cannot save an empty, active committee approval session.';
        }

        if (saveButtonTitleText) {
            saveButtonTitleText += '\nPlease cancel approvals first.';
        }

        return (
            <DragDropContext
                onDragUpdate={this.onDragUpdate.bind(this)}
                onDragEnd={this.onDragEnd.bind(this)}
            >
                <div className="approval-route-editor">

                    <ul className="approval-route-editor-menu-items">

                        {this.state.loadingStatus === 'ready' &&
                            <React.Fragment>
                                <IconButton
                                    text={this.state.loadingStatus === 'cancelling' ? 'CANCELLING...' : 'CANCEL APPROVALS'}
                                    icon={<Cancel />}
                                    iconColor={'red'}
                                    hide={this.state.loadingStatus === 'error'}
                                    title={!this.state.isActive ? 'No approvals in progress' : ''}
                                    disabled={
                                        this.state.loadingStatus !== 'ready'
                                        || (!this.state.isPendingApprovalRouting
                                            && !this.state.isActive)
                                    }
                                    onClick={() => this.cancelApprovals()}
                                />
                                <IconButton
                                    text={'UNDO EDITS'}
                                    icon={<Restore />}
                                    iconColor={'darkorange'}
                                    hide={!this.canEditApprovalRoute() || this.state.loadingStatus === 'error'}
                                    disabled={this.state.loadingStatus !== 'ready'}
                                    onClick={() => this.getApprovalRoute()}
                                />
                                {/* Note: once manuals have a mechanism for signing, remove this if-check and it'll all work fine */}
                                {this.state.objectType === 'docrevid' &&
                                    <IconButton
                                        text={'ADD SIGNATURE PHASE'}
                                        icon={<BorderColor />}
                                        iconColor={'dodgerblue'}
                                        hide={!this.canEditApprovalRoute() || this.state.loadingStatus === 'error'}
                                        disabled={this.state.loadingStatus !== 'ready' || this.state.showSignatureApprovals}
                                        onClick={() => this.addSignaturePhase()}
                                    />
                                }
                                <IconButton
                                    text={'ADD COMMITTEE PHASE'}
                                    icon={<Group />}
                                    iconColor={'dodgerblue'}
                                    hide={!this.canEditApprovalRoute() || !this.state.useCommittees || this.state.loadingStatus === 'error'}
                                    disabled={this.state.loadingStatus !== 'ready' || this.state.showCommitteeApprovals}
                                    onClick={() => this.addCommitteeApprovalPhase()}
                                />
                                <IconButton
                                    text={this.state.mode === 'edit' ? 'REVIEW' : 'EDIT      '}
                                    icon={this.state.mode === 'edit' ? <AssignmentTurnedIn /> : <Create />}
                                    iconColor={this.state.mode === 'edit' ? 'blue' : 'grey'}
                                    hide={this.state.loadingStatus === 'error'}
                                    disabled={this.state.loadingStatus !== 'ready'}
                                    onClick={() => this.toggleReviewMode()}
                                />
                                <IconButton
                                    text={this.state.loadingStatus === 'saving' ? 'SAVING...    ' : 'SAVE EDITS'}
                                    icon={<Save />}
                                    iconColor={'green'}
                                    hide={!this.canEditApprovalRoute() || this.state.loadingStatus === 'error'}
                                    title={saveButtonTitleText}
                                    disabled={
                                        this.state.loadingStatus !== 'ready'
                                        || isSignatureSessionActiveButEmpty
                                        || isCommitteeApprovalSessionActiveButEmpty
                                    }
                                    onClick={() => this.showSaveConfirmations()}
                                />
                            </React.Fragment>
                        }

                    </ul>

                    <div className="approval-route-editor-content-area">

                        {this.state.loadingStatus === 'loading' &&
                            <p style={{ color: 'darkgrey' }}>
                                <HourglassEmpty />
                                Loading...
                            </p>
                        }

                        {this.state.loadingStatus === 'saving' &&
                            <p style={{ color: 'darkgrey' }}>
                                <HourglassEmpty />
                                Saving...
                            </p>
                        }

                        {this.state.loadingStatus === 'error' &&
                            <React.Fragment>
                                <p style={{ color: 'red', marginTop: '32px' }}>Something went wrong. Please...</p>
                                <IconButton
                                    text={'TRY AGAIN'}
                                    icon={<Refresh />}
                                    iconColor={'darkorange'}
                                    border={true}
                                    onClick={() => this.getApprovalRoute()}
                                />
                            </React.Fragment>
                        }

                        {this.canEditApprovalRoute() && <Droppable
                            droppableId='approvalRouteEditorDropZone'
                            type='approvalRouteEditorDropZone'
                        >
                            {provided => (
                                <span
                                    className={this.state.mode === 'review' || this.state.loadingStatus !== 'ready' ? 'display-none' : ''}
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >

                                    {this.state.loadingStatus === 'ready' && !this.state.showSignatureApprovals && !this.state.showCommitteeApprovals &&
                                        <span className="approval-route-editor-light-text center-me">
                                            <p>Your approval route is empty.</p>
                                            <p>Click {this.state.objectType === 'docrevid' ? 'ADD SIGNATURES or' : ''} ADD COMMITTEE PHASE to set approvals.</p>
                                        </span>
                                    }

                                    {this.state.showSignatureApprovals && this.state.phaseOrder[0] && this.state.phaseOrder[0].phase === 'signers' &&
                                        signatureApprovals
                                    }

                                    {this.state.showCommitteeApprovals &&
                                        committeeApprovalPhase
                                    }

                                    {this.state.showSignatureApprovals && this.state.phaseOrder[1] && this.state.phaseOrder[1].phase === 'signers' &&
                                        signatureApprovals
                                    }

                                    {provided.placeholder}
                                </span>
                            )}

                        </Droppable>
                        }

                        {!this.canEditApprovalRoute() &&
                            <div className="approval-route-editor-light-text center-me approval-route-editor-committee-group-box">
                                <p>You cannot edit the approval route because this item has been submitted for approval routing or is actively gathering approvals.</p>
                            </div>
                        }


                        {this.state.mode === 'review' && this.state.loadingStatus === 'ready' &&
                            <ReviewApprovalRoute
                                signatureApprovalGroup={this.state.signatureApprovalGroup}
                                showCommitteeApprovals={this.state.showCommitteeApprovals}
                                committeeApprovalGroups={this.state.committeeApprovalGroups.map(
                                    (committeeGroup: COMMITTEESWithDragAndDropID) => committeeGroup.committees
                                )}
                                requestStatus={this.state.requestStatus}
                                scheduledTaskDates={this.state.scheduledTaskDates}
                                phaseStatus={this.state.phaseStatus}
                                orderedSigning={this.state.orderedSigning}
                                phaseOrder={this.state.phaseOrder.map(
                                    (phaseItem: phaseWithDragAndDropID) => phaseItem.phase
                                )}
                                ratification={this.state.ratification}
                                isActive={this.state.isActive}
                            />
                        }

                    </div>

                </div>

                <LucidocModal
                    open={this.state.showRemoveMessageModal}
                    onClose={() => this.toggleRemoveModal()}
                    title={'Notify removed approver(s)'}

                    onSave={this.state.loadingStatus === 'saving' ?
                        () => this.saveApprovalRoute(false) :
                        () => this.cancelApprovals()
                    }
                    isSaveDisabled={!this.state.removeMessage.trim().length}
                    whyIsSaveDisabled={!this.state.removeMessage.trim().length ? 'Be sure to leave a message!' : ''}
                    // saveButtonText={this.state.loadingStatus === 'saving' ? 'Save Approval Route' : 'Cancel Approvals'}

                    onCancel={() => this.toggleRemoveModal()}
                    // cancelButtonText={'Go Back'}
                >
                    <p>
                        You have removed an approver (user/group/committee) that has already been
                        notified that their approval is requested on this document.
                    </p>
                    <p>
                        Please add an explanation as to why their approval is no longer required.
                    </p>
                    <textarea
                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => this.updateRemoveMessage(e)}
                        className='remove-modal-message-field'
                        defaultValue={this.state.removeMessage}
                    >
                    </textarea>
                </LucidocModal>

            </DragDropContext>
        );
    }
}

export default ApprovalRouteEditor;

function isPerson(potentialPerson: PERSON | USERGROUP | unknown): potentialPerson is PERSON {
    return (potentialPerson as PERSON).USERID !== undefined;
}
function isUserGroup(potentialUsergroup: PERSON | USERGROUP | unknown): potentialUsergroup is USERGROUP {
    return (potentialUsergroup as USERGROUP).USERGROUPID !== undefined;
}
