import {
    Column,
    Entity,
    PrimaryColumn,
} from 'typeorm';
import { SchemaEntityManager } from '../SchemaEntityManager';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function createApprovalRouteTemplateLogEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('APPROVAL_ROUTE_TEMPLATE_LOG', { schema: schema })
    class APPROVAL_ROUTE_TEMPLATE_LOG {
        static SCHEMANAME = schema;
        @Column('number', {
            nullable: true,
            name: 'MODIFIER_USERID',
        })
        MODIFIER_USERID!: number | null;

        @Column('varchar2', {
            nullable: false,
            length: 1000,
            name: 'MODIFIER_NAME',
        })
        MODIFIER_NAME!: string;

        @Column('varchar2', {
            nullable: true,
            length: 1000,
            name: 'MODIFIER_TITLE',
        })
        MODIFIER_TITLE!: string | null;

        @Column('timestamp', {
            nullable: false,
            name: 'DATESTAMP',
        })
        DATESTAMP!: Date;

        @Column('number', {
            name: 'DOCREVID',
        })
        DOCREVID!: number | null;

        @Column('number', {
            nullable: true,
            name: 'SAS_TEMPLATE_ID',
        })
        SAS_TEMPLATE_ID!: number | null;

        @Column('number', {
            nullable: true,
            name: 'CAS_TEMPLATE_ID',
        })
        CAS_TEMPLATE_ID!: number | null;

        @Column('number', {
            nullable: true,
            name: 'PHASE',
        })
        PHASE!: number | null;

        @Column('number', {
            name: 'MANUALID',
        })
        MANUALID!: number | null;

        @PrimaryColumn('number', {
            nullable: false,
            name: 'APPROVAL_ROUTE_TEMPLATE_LOG_ID',
        })
        APPROVAL_ROUTE_TEMPLATE_LOG_ID!: number;
    }

    return APPROVAL_ROUTE_TEMPLATE_LOG;
}

export { createApprovalRouteTemplateLogEntity };
export type APPROVAL_ROUTE_TEMPLATE_LOG = InstanceType<ReturnType<typeof createApprovalRouteTemplateLogEntity>>;
