import React from 'react';

interface EyeOutlineProps {
    size: number
}

export const EyeOutlineIcon: React.FC<EyeOutlineProps> = ({ size }) => {
    return (
        <svg width={size.toString()} height={size.toString()} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.243432 8.40572C0.0935948 8.15599 0.0935945 7.84401 0.243431 7.59428L0.427521 7.28746C2.02348 4.62753 4.89801 3 8 3C11.102 3 13.9765 4.62753 15.5725 7.28746L15.7566 7.59428C15.9064 7.84401 15.9064 8.15599 15.7566 8.40572L15.5725 8.71254C13.9765 11.3725 11.102 13 8 13C4.89801 13 2.02348 11.3725 0.427522 8.71254L0.243432 8.40572Z" fill="#6B7280" />
            <path d="M8 5.375C6.55025 5.375 5.375 6.55025 5.375 8C5.375 9.44975 6.55025 10.625 8 10.625C9.44975 10.625 10.625 9.44975 10.625 8C10.625 6.55025 9.44975 5.375 8 5.375Z" fill="#6B7280" stroke="hsl(var(--border))" strokeWidth="1.25" />
        </svg>
    );
};