import React from 'react';

interface CommitteeShadowIconProps {
    size: number;
}

export const CommitteeShadowIcon: React.FC<CommitteeShadowIconProps> = ({ size }) => {

    return (
        <svg width={size.toString()} height={size.toString()} viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.71418 3.72816L8.11621 6.15328L7.73526 6.5379L8.43227 7.24161L14.5924 13.461L14.4761 13.5784L8.32942 7.37264L7.61894 6.65533L7.23799 7.03995L4.83596 4.61482L5.71418 3.72816Z" fill="hsl(var(--muted-foreground))" stroke="hsl(var(--muted-foreground))" stroke-width="2"/>
            <path d="M10.2856 4.3482L7.88357 1.92308L8.38084 1.42103L10.7829 3.84615L10.2856 4.3482Z" stroke="hsl(var(--muted-foreground))" stroke-width="2"/>
            <path d="M4.57149 10.1182L2.16946 7.6931L2.66672 7.19105L5.06875 9.61617L4.57149 10.1182Z" stroke="hsl(var(--muted-foreground))" stroke-width="2"/>
            <path d="M1 13.4424H6.61905C6.75712 13.4424 6.86905 13.5543 6.86905 13.6924V14.2501H0.75V13.6924C0.75 13.5543 0.861928 13.4424 1 13.4424Z" stroke="hsl(var(--muted-foreground))" stroke-width="1.5"/>
        </svg>
    );

};