import React, { useContext, useEffect, useState } from 'react';
import * as queryString from 'query-string';
import axios from 'axios';
import UserBundleContext from '../../../../../context/UserBundleContext';
import { LoadingIndicator } from '../../../../_shared/LoadingIndicator';
import LucidocForm from '../../../../_shared/Forms/LucidocForm/LucidocForm';
import SelectUser from '@/components/_shared/Forms/Select/SelectUser';
import { SelectManual } from '../../../../_shared/Forms/Select/SelectManual';
import { Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import { COMMITTEE, CommitteeStatuses } from '../../../../../entities/org/COMMITTEE';
import SelectUsers from '../../../../_shared/Forms/Select/SelectUsers';
import SaveBar from '../../../../_shared/SaveBar/SaveBar';
import { RightsCodeKey, type PERSON } from '../../../../../entities/master/PERSON';
import { onStorageUpdate } from '../CommitteeEditor';
import { SelectDepartment } from '../../../../_shared/Forms/Select/SelectDepartment';
import { InformationHierarchyStatuses } from '../../../../../entities/org/INFORMATION_HIERARCHY';
import hasRights from '../../../../../_shared/utils/hasRights';
import { RefreshRounded } from '@material-ui/icons';
import { LoadingStatuses } from '../../../../../utils/LoadingStatuses';
import { redirectToHomepage } from '../../../../../utils/savebarUtilities';


interface ICommitteeInfoEditorProps {
    onClose: () => void;
    committeeId: number;
}

const CommitteeInfoEditor: React.FC<ICommitteeInfoEditorProps> = (props: ICommitteeInfoEditorProps) => {
    const context = useContext(UserBundleContext);

    const isCommitteeAdmin = hasRights(
        context,
        [
            RightsCodeKey.DocumentAdministrator,
            RightsCodeKey.OrganizationAdministrator
        ]
    );

    const params = queryString.parse(window.location.search);
    const [loadingStatus, setLoadingStatus] = useState(LoadingStatuses.MOUNTING);

    const [committee, setCommittee] = useState<COMMITTEE>();

    const [name, setName] = useState<string>('');
    const [chairPersonUserId, setChairPersonUserId] = useState<number>();
    const [secretaryUserId, setSecretaryUserId] = useState<number>();
    const [bylawsManualId, setBylawsManualId] = useState<number>();
    const [notes, setNotes] = useState<string>('');
    const [allowPublicMinutes, setAllowPublicMinutes] = useState<boolean>(false);

    const [minutesDepartmentId, setMinutesDepartmentId] = useState<number>();
    const [status, setStatus] = useState<number>();

    const [committeeMembersUserIds, setCommitteeMemberUserIds] = useState<number[]>([]);
    const [committeeCollaboratorUserIds, setCommitteeCollaboratorUserIds] = useState<number[]>([]);


    const loadInitialData = async () => {
        try {
            const res = await axios.get(`/api/committee/${props.committeeId}`);

            setCommittee(res.data.committee);

            setName(res.data.committee.NAME);
            setChairPersonUserId(res.data.committee.CHAIRPERSON_USERID);
            setSecretaryUserId(res.data.committee.SECRETARY_USERID);
            setBylawsManualId(res.data.committee.BYLAWS_MANUALID);
            setNotes(res.data.committee.NOTES);
            setMinutesDepartmentId(res.data.committee.MINUTES_DEPARTMENT_ID);
            setStatus(res.data.committee.STATUS);
            setAllowPublicMinutes(res.data.committee.ALLOW_PUBLIC_MINUTES);

            setCommitteeMemberUserIds(res.data.committee.PEOPLE.map((person: PERSON) => person.USERID));
            setCommitteeCollaboratorUserIds(res.data.committee.COLLABORATORS.map((person: PERSON) => person.USERID));

            setLoadingStatus(LoadingStatuses.READY);
        } catch (e) {
            redirectToHomepage();
        }
    };

    useEffect(() => {
        if (
            loadingStatus === LoadingStatuses.LOADING ||
            loadingStatus === LoadingStatuses.MOUNTING
        ) {
            loadInitialData().then();
        }
    }, [loadingStatus]);

    const saveCommitteeInfoAndMembers = async () => {
        await axios.put(
            `/api/committee/${params.idOfCommitteeBeingEdited}`,
            {
                name,
                chairPersonUserId,
                secretaryUserId,
                bylawsManualId,
                notes,
                committeeMembersUserIds,
                committeeCollaboratorUserIds,
                minutesDepartmentId,
                status,
                allowPublicMinutes
            }
        );
        setLoadingStatus(LoadingStatuses.LOADING);
    };

    const displaySaveButton = committee ? (
        (committee.NAME !== name) ||
        (committee.CHAIRPERSON_USERID !== chairPersonUserId) ||
        (committee.SECRETARY_USERID !== secretaryUserId) ||
        committee.BYLAWS_MANUALID !== bylawsManualId ||
        committee.NOTES !== notes ||
        committee.STATUS !== status ||
        committee.MINUTES_DEPARTMENT_ID !== minutesDepartmentId ||
        committee.PEOPLE!.map(person => person.USERID).length !== committeeMembersUserIds.length ||
        !committee.PEOPLE!.map(person => person.USERID).every(userId => committeeMembersUserIds.includes(userId)) ||
        committee.COLLABORATORS!.map(person => person.USERID).length !== committeeCollaboratorUserIds.length ||
        !committee.COLLABORATORS!.map(person => person.USERID).every(userId => committeeCollaboratorUserIds.includes(userId)) ||
        committee.ALLOW_PUBLIC_MINUTES !== allowPublicMinutes
    ) && (
            name !== '' &&
            chairPersonUserId !== undefined &&
            secretaryUserId !== undefined
        ) : false;

    const isChairPerson = context.user?.USERID === committee?.CHAIRPERSON_USERID || isCommitteeAdmin;
    const isChairPersonOrSecretary = (
        context.user?.USERID === committee?.SECRETARY_USERID ||
        context.user?.USERID === committee?.CHAIRPERSON_USERID ||
        isCommitteeAdmin
    );

    const isCollaborator = (committee?.COLLABORATORS ?? []).some(person => person.USERID === context.user?.USERID);

    useEffect(() => {
        window.addEventListener('storage', onStorageUpdate(setLoadingStatus));
        return () => {
            window.removeEventListener('storage', onStorageUpdate(setLoadingStatus));
        };
    }, []);

    return <>
        <div
            style={{
                maxHeight: 'calc(84vh - 6.125rem)',
                overflowX: 'hidden',
                overflowY: 'auto',
            }}
        >
            {
                loadingStatus !== LoadingStatuses.READY &&
                <LoadingIndicator />
            }
            {
                loadingStatus === LoadingStatuses.READY &&
                <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: '50% 50%'
                    }}
                >
                    <LucidocForm
                        width='80%'
                        title={`Edit committee info for ${committee?.NAME}`}
                        name='Committee Info'
                        fields={[
                            {
                                name: 'Committee Name',
                                description: 'Edit the name of this committee',
                                component: <TextField
                                    disabled={!(isChairPersonOrSecretary || isCollaborator)}
                                    value={name}
                                    onChange={event => setName(event.currentTarget.value)}
                                    placeholder='Committee Name'
                                    variant='outlined'
                                    size='small'
                                />
                            },
                            {
                                name: 'Chairperson',
                                component: <SelectUser
                                    disabled={!isChairPerson}
                                    placeholder='Select chairperson...'
                                    rights={[RightsCodeKey.Author]}
                                    onChange={setChairPersonUserId}
                                    getMappedUsers
                                    userId={chairPersonUserId}
                                />
                            },
                            {
                                name: 'Secretary',
                                component: <SelectUser
                                    disabled={!isChairPersonOrSecretary}
                                    placeholder='Select secretary...'
                                    rights={[RightsCodeKey.Author]}
                                    onChange={setSecretaryUserId}
                                    getMappedUsers
                                    userId={secretaryUserId}
                                />
                            },
                            {
                                name: 'Bylaws Manual',
                                optional: true,
                                component: <SelectManual
                                    disabled={!(isChairPersonOrSecretary || isCollaborator)}
                                    onChange={setBylawsManualId}
                                    placeholder='Select bylaws manual...'
                                    manualId={bylawsManualId}
                                    userID={context.user?.USERID}
                                    isClearable
                                />
                            },
                            isCommitteeAdmin ? {
                                name: 'Minutes Department',
                                component: <SelectDepartment
                                    statuses={[
                                        InformationHierarchyStatuses.active
                                    ]}
                                    placeholder='Select minutes department...'
                                    departmentID={minutesDepartmentId}
                                    onChange={setMinutesDepartmentId}
                                />
                            } : undefined,
                            isCommitteeAdmin ? {
                                name: 'Status',
                                component: <FormControlLabel
                                    control={
                                        <Checkbox
                                            onClick={
                                                () => setStatus(
                                                    prev => prev === CommitteeStatuses.ACTIVE ?
                                                        CommitteeStatuses.INACTIVE :
                                                        CommitteeStatuses.ACTIVE
                                                )
                                            }
                                            checked={status === CommitteeStatuses.ACTIVE}
                                            color='primary'
                                        />
                                    }
                                    label={`Status (${status === CommitteeStatuses.ACTIVE ? 'Active' : 'Archived'})`}
                                />
                            } : undefined,
                            {
                                name: 'Committee Notes',
                                component: <TextField
                                    value={notes}
                                    onChange={event => setNotes(event.currentTarget.value)}
                                    placeholder='Committee Notes'
                                    variant='outlined'
                                    multiline
                                    size='small'
                                    fullWidth
                                    minRows={3}
                                    disabled={!(isChairPersonOrSecretary || isCollaborator)}
                                />
                            },
                            {
                                name: 'Public Committee Minutes',
                                component: <FormControlLabel
                                    control={
                                        <Checkbox
                                            onClick={() => setAllowPublicMinutes(prev => !prev)}
                                            checked={allowPublicMinutes}
                                            color='primary'
                                        />
                                    }
                                    label={'Allow committee\'s official/draft minutes to be public'}
                                />
                            }
                        ]}
                    />
                    <LucidocForm
                        width='40%'
                        fields={[
                            {
                                name: 'Committee Members',
                                component: <SelectUsers
                                    userIds={committeeMembersUserIds}
                                    onChange={setCommitteeMemberUserIds}
                                    exemptUserIds={[
                                        chairPersonUserId || -1,
                                        secretaryUserId || -1,
                                    ]}
                                    rights={[RightsCodeKey.Reader]}
                                    placeholder='Select committee members...'
                                    disabled={!(isChairPersonOrSecretary || isCollaborator)}
                                    getMappedUsers
                                />
                            },
                            {
                                name: 'Committee Collaborators',
                                component: <SelectUsers
                                    userIds={committeeCollaboratorUserIds}
                                    onChange={setCommitteeCollaboratorUserIds}
                                    exemptUserIds={[
                                        chairPersonUserId || -1,
                                        secretaryUserId || -1,
                                    ]}
                                    rights={[RightsCodeKey.Author]}
                                    placeholder='Select committee members...'
                                    disabled={!(isChairPersonOrSecretary || isCollaborator)}
                                    getMappedUsers
                                />
                            }
                        ]}
                    />
                </div>
            }
        </div>
        <SaveBar
            onSave={async () => await saveCommitteeInfoAndMembers()}
            isSaveDisabled={!displaySaveButton}
            // saveButtonText='Save Edits'

            onCancel={() => setLoadingStatus(LoadingStatuses.LOADING)}
            isCancelDisabled={!displaySaveButton}
            // cancelButtonText='Undo Edits'
            cancelIcon={<RefreshRounded />}

            onClose={() => props.onClose()}
        />
    </>;

};

export default CommitteeInfoEditor;
