import {
    Column,
    Entity,
    OneToMany,
    PrimaryColumn,
} from 'typeorm';
import { TEMPLATE } from '../org/TEMPLATE';
import { SchemaEntityManager } from '../SchemaEntityManager';

/**
 * To completely create a link, you must create one of these records, AND ALSO a record
 *  in the DOCUMENTREVISION_LINK table (stored in each org schema, not master).
 * 
 * @param schema - This schema is the organization schema. MIME_TYPE has a decorator specifying schema: MASTER, but still has relations to a parameter specified organization schema
 * 
 * @returns 
 */

function createMimeTypeEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('MIME_TYPE', { schema: 'MASTER' })
    class MIME_TYPE {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'MIMETYPE_ID'
        })
        MIMETYPE_ID!: number;


        @Column('varchar2', {
            nullable: false,
            length: 80,
            name: 'MIME_TYPE'
        })
        MIME_TYPE!: string;


        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'SUPPORT_LEVEL'
        })
        SUPPORT_LEVEL!: number | null;


        @Column('char', {
            nullable: true,
            name: 'ACCEPTABLE'
        })
        ACCEPTABLE!: string | null;


        @Column('char', {
            nullable: true,
            name: 'EDITABLE'
        })
        EDITABLE!: string | null;


        @Column('char', {
            nullable: true,
            name: 'INDEXABLE'
        })
        INDEXABLE!: string | null;


        @Column('char', {
            nullable: true,
            name: 'PRINTABLE'
        })
        PRINTABLE!: string | null;


        @Column('char', {
            nullable: true,
            name: 'SYNTHABLE'
        })
        SYNTHABLE!: string | null;


        @Column('varchar2', {
            nullable: true,
            length: 10,
            name: 'EXTENSION'
        })
        EXTENSION!: string | null;

        @OneToMany(
            () => manager.getTemplateEntity(schema), 
            (template) => template.MIME_TYPE
        )
        TEMPLATES!: TEMPLATE[] | null;
    }

    return MIME_TYPE;
}

export { createMimeTypeEntity };
export type MIME_TYPE = InstanceType<ReturnType<typeof createMimeTypeEntity>>;