import React, { useContext, useEffect, useState } from 'react';
import { DOCUMENTREVISION } from '../../../../../entities/org/DOCUMENTREVISION';
import { LoadingIndicator } from '../../../../_shared/LoadingIndicator';
import { LoadingStatuses } from '../../../../../utils/LoadingStatuses';
import H3WithHelpTip from '../../../../_shared/Forms/H3WithHelpTip';
import { setTitleInAdmin } from '../../../../../utils/setTitleInAdmin';
import { TemporaryFavoritesStar } from '../../../../_shared/TemporaryFavoritesStar/TemporaryFavoritesStar';
import { makeStyles } from '@material-ui/core/styles';
import { redirectToHomepage } from '../../../../../utils/savebarUtilities';
import SaveBar from '../../../../_shared/SaveBar/SaveBar';
import { UpdateDocrevReviewMetadataForm } from './UpdateDocrevReviewMetadataForm/UpdateDocrevReviewMetadataForm';
import { UpdateDocrevReviewMetadataStagedChanges } from './UpdateDocrevReviewMetadataStagedChanges';
import { UpdateDocrevReviewMetadataModal } from './UpdateDocrevReviewMetadataModal';
import axios from 'axios';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { INFORMATION_HIERARCHY } from '../../../../../entities/org/INFORMATION_HIERARCHY';
import { DepartmentDocumentsModal } from './UpdateDocrevReviewMetadataForm/DepartmentDocumentsModal';
import { HelpTopicContext } from '@/context/HelpTopicContext';
import { helpTopicDocIDs } from '@/_shared/constants/HelpSystemIDs';

const pageStyles = {
    pageContainer: {
        position: 'relative' as CSSProperties['position'],
        zIndex: 101,
        padding: '0 1rem 0 1rem',
        marginTop: '1rem',
    },
    favoritesSection: {
        float: 'right' as CSSProperties['float'],
    },
};

const useStyles = makeStyles(pageStyles);

export function UpdateDocrevReviewMetadata() {
    const classes = useStyles();
    const { setHelpTopicID } = useContext(HelpTopicContext);

    const [loadingStatus, setLoadingStatus] = useState(LoadingStatuses.READY);
    const [stagedDocRevs, setStagedDocRevs] = useState<DOCUMENTREVISION[]>([]);
    const [editingDocRevs, setEditingDocRevs] = useState<DOCUMENTREVISION[] | null>(null);
    const [departments, setDepartments] = useState<INFORMATION_HIERARCHY[]>([]);
    const [editingDepartment, setEditingDepartment] = useState<INFORMATION_HIERARCHY | null>(null);
    const [orgDepartmentAlias, setOrgDepartmentAlias] = useState<string>('');

    useEffect(() => {
        const newTitle = 'Edit Document Review Information';
        window.document.title = newTitle;
        setTitleInAdmin(newTitle);
        setHelpTopicID(helpTopicDocIDs.UPDATE_DOC_REVIEW_METADATA);

        axios.get('/api/administration/documents/update-docrev-review-metadata/departments')
            .then((response) => {
                setDepartments(response.data.departments);
                setOrgDepartmentAlias(response.data.orgDepartmentAlias);
            })
            .catch(err => console.log(err));
    }, []);

    function saveDocRevs() {
        const doAction = confirm('Are you sure you want to save these document revisions?');
        if (!doAction) {
            return;
        }

        setLoadingStatus(LoadingStatuses.LOADING);

        axios.post('/api/administration/documents/update-docrev-review-metadata/save', {
            docrevs: stagedDocRevs,
        })
        .then(() => {
            alert('Document revisions saved successfully!');
        })
        .catch(err => {
            alert(`Error saving document revisions: ${err}`);
        })
        .finally(() => {
            setLoadingStatus(LoadingStatuses.READY);
        });
    }

    if (loadingStatus !== LoadingStatuses.READY) {
        return <LoadingIndicator />;
    }

    return (
        <div>
            <div className={classes.pageContainer}>
                <div className={classes.favoritesSection}> {/* must be inside to get the zIndex */}
                    <TemporaryFavoritesStar targetPathName={'admin/admin.pl?repname=update_docrev_review_metadata'} />
                </div>

                <H3WithHelpTip
                    helpText={'Document admins can use this tool to update the review information for document revisions.'}
                    text={'Revise document review cycle or review date'}
                />

                <UpdateDocrevReviewMetadataForm
                    orgDepartmentAlias={orgDepartmentAlias}
                    departments={departments}
                    onEditDocRev={setEditingDocRevs}
                    onEditDepartment={setEditingDepartment}
                />

                <UpdateDocrevReviewMetadataStagedChanges
                    stagedDocRevs={stagedDocRevs}
                    onEdit={setEditingDocRevs}
                    onRemove={(docRevsToRemove: DOCUMENTREVISION[]) => {
                        const newStagedDocRevs = stagedDocRevs.filter(docRev => {
                            return !docRevsToRemove.some(docRevToRemove => docRevToRemove.DOCREVID === docRev.DOCREVID);
                        });

                        setStagedDocRevs(newStagedDocRevs);
                    }}
                />

                <UpdateDocrevReviewMetadataModal
                    docRevs={editingDocRevs}
                    isOpen={editingDocRevs !== null && editingDocRevs.length > 0}
                    onClose={() => setEditingDocRevs(null)}
                    onSave={(updatedDocRevs: DOCUMENTREVISION[]) => {
                        let newStagedDocRevs = stagedDocRevs.filter(docRev => {
                            return !updatedDocRevs.some(updatedDocRev => updatedDocRev.DOCREVID === docRev.DOCREVID);
                        });
                        newStagedDocRevs = newStagedDocRevs.concat(updatedDocRevs);

                        setStagedDocRevs(newStagedDocRevs);
                        setEditingDocRevs(null);
                    }}
                />

                <DepartmentDocumentsModal
                    orgDepartmentAlias={orgDepartmentAlias}
                    department={editingDepartment}
                    isOpen={editingDepartment !== null}
                    onClose={() => setEditingDepartment(null)}
                    onEdit={(incomingDepartmentDocs: DOCUMENTREVISION[]) => {
                        setEditingDocRevs(incomingDepartmentDocs);
                        setEditingDepartment(null);
                    }}
                />
            </div>
            
            <SaveBar
                isSaveDisabled={stagedDocRevs.length === 0}
                onSave={() => saveDocRevs()}
                onClose={() => redirectToHomepage()}
            />
        </div>
    );
}
