import { INFORMATION_HIERARCHIES_PARENT } from '@/entities/org/INFORMATION_HIERARCHY';
import React, { useContext } from 'react';
import UserBundleContext from '@/context/UserBundleContext';
import useSWR from 'swr';
import { InformationHierachyBrowser } from '../../components/informationhierarchy/InformationHierachyBrowser';
import { useOrgBaseURL } from '@/hooks/useOrgBaseURL';

interface DepartmentsProps {

}

export const Departments: React.FC<DepartmentsProps> = () => {
    const userBundle = useContext(UserBundleContext);


    const { data: departmentsData, isLoading } = useSWR<{ parent: INFORMATION_HIERARCHIES_PARENT }>('/api/homepage/card/departments');
    const baseURL = useOrgBaseURL();
    return <InformationHierachyBrowser
        expandAll={userBundle.preferences.departmentsExpandedByDefault}
        browserHierarchies={departmentsData?.parent.CHILDREN || []}
        breadcrumb={{ title: userBundle.organization?.ORGUNITPLURAL ?? 'Departments', href: `${baseURL}/dashboard?homepageTab=departments` }}
        ihType='department'
        loading={isLoading}
    />;
};

