import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as queryString from 'query-string';

export interface QueryParams {
    [key: string]: string | boolean | number | string[] | number[] | undefined;
}

export const useBrowserQuery = <T extends QueryParams>(): [T, (params: T) => void] => {
    const { search } = useLocation();
    const history = useHistory();



    const parseQueryParams = (searchString: string): Record<string, any> => {
        const parsedParams: Record<string, any> = {};
        const qsParsed = queryString.parse(searchString);
    
        if (!qsParsed) return {};
    
        for (const key in qsParsed) {
            const value = qsParsed[key];
            if (value === null) {
                parsedParams[key] = undefined;
            } else if (Array.isArray(value)) {
                parsedParams[key] = value.map(item => parseValue(item));
            } else {
                parsedParams[key] = parseValue(value);
            }
        }
    
        return parsedParams;
    };
    
    const parseValue = (value: any): any => {
        if (typeof value === 'string') {
            if (value.toLowerCase() === 'true' || value.toLowerCase() === 'false') {
                return value.toLowerCase() === 'true';
                // Last check makes sure that the value is a number and not a string representation of a number, without it "14 years" would be parsed as 14
            } else if (!isNaN(parseFloat(value)) && isFinite(parseInt(value)) && Number(value).toString() === value.trim()) {
                return parseFloat(value);
            } else {
                return value;
            }
        } else {
            return value;
        }
    };
    const setQueryParams = (params: T) => {
        history.push({ search: queryString.stringify(params) });
    };

    const queryParams = useMemo(() => {
        return { ...parseQueryParams(search) } as T;
    }, [search]);

    return [queryParams, setQueryParams];
};
export const buildURLSearchParams = (obj: object) => {
    const urlSearchParams = new URLSearchParams();

    Object.entries(obj).forEach(([key, value]) => {
        // Check if the value is not undefined or an empty string
        if (value !== undefined && value !== '') {
            // For boolean values, you may decide to include them only when true/false
            // depending on your requirement, or convert them to strings as shown
            urlSearchParams.append(key, value.toString());
        }
    });


    return urlSearchParams.toString();
};