import {
    Column,
    Entity,
    JoinColumn,
    OneToOne,
    PrimaryColumn,
} from 'typeorm';
import { CE_COURSE_PROTOTYPE,  } from './CE_COURSE_PROTOTYPE';
import { SchemaEntityManager } from '../SchemaEntityManager';

// Factory function to create CE_COURSE_SCHEDULE entity with dynamic schema
function createCeCourseScheduleEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('CE_COURSE_SCHEDULE', { schema })
    class CE_COURSE_SCHEDULE {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            name: 'CE_COURSE_PROTOTYPE_ID'
        })
        CE_COURSE_PROTOTYPE_ID!: number;

        @OneToOne(
            () => manager.getCeCoursePrototypeEntity(schema),
            CE_COURSE_PROTOTYPE => CE_COURSE_PROTOTYPE.CE_COURSE_SCHEDULE
        )
        @JoinColumn({
            name: 'CE_COURSE_PROTOTYPE_ID'
        })
        CE_COURSE_PROTOTYPE!: CE_COURSE_PROTOTYPE | null;

        @Column('number', {
            nullable: true,
            name: 'FREQUENCY_IN_MONTHS'
        })
        FREQUENCY_IN_MONTHS!: number | null;

        @Column('timestamp', {
            nullable: true,
            name: 'NEXT_START_DATE'
        })
        NEXT_START_DATE!: Date | null;

        @Column('number', {
            nullable: false,
            name: 'DURATION_IN_DAYS'
        })
        DURATION_IN_DAYS!: number;
    }

    return CE_COURSE_SCHEDULE;
}

// Export the factory function
export { createCeCourseScheduleEntity };

// Export the type for CE_COURSE_SCHEDULE entities created by the factory function
export type CE_COURSE_SCHEDULE = InstanceType<ReturnType<typeof createCeCourseScheduleEntity>>;
