import {
    Column,
    Entity,
    PrimaryColumn,
} from 'typeorm';
import { SchemaEntityManager } from '../SchemaEntityManager';


// eslint-disable-next-line @typescript-eslint/no-unused-vars
function createSignatureAssignmentLog(schema: string, manager: SchemaEntityManager) {
    @Entity('SIGNERASSIGNMENT_LOG', { schema: schema })
    class SIGNERASSIGNMENT_LOG {
        static SCHEMANAME = schema;
        // Notice how the PrimaryColumn is at the end (since it was created later so it's last in the database)

        @Column('number', {
            nullable: true,
            name: 'MODIFIER_USERID'
        })
        MODIFIER_USERID!: number | null;

        @Column('varchar2', {
            nullable: false,
            length: 1000,
            name: 'MODIFIER_NAME'
        })
        MODIFIER_NAME!: string;

        @Column('varchar2', {
            nullable: true,
            length: 1000,
            name: 'MODIFIER_TITLE'
        })
        MODIFIER_TITLE!: string | null;

        @Column('timestamp', {
            nullable: false,
            name: 'DATESTAMP'
        })
        DATESTAMP!: Date;

        @Column('number', {
            name: 'DOCREVID'
        })
        DOCREVID!: number | null;

        @Column('number', {
            nullable: true,
            name: 'USERID'
        })
        USERID!: number | null;

        @Column('number', {
            nullable: true,
            name: 'USERGROUPID'
        })
        USERGROUPID!: number | null;

        @Column('number', {
            nullable: true,
            name: 'SIGNORDER'
        })
        SIGNORDER!: number | null;

        @Column('varchar2', {
            nullable: true,
            length: 1000,
            name: 'USER_NAME'
        })
        USER_NAME!: string | null;

        @Column('varchar2', {
            nullable: true,
            length: 200,
            name: 'USERGROUP_NAME'
        })
        USERGROUP_NAME!: string | null;

        @Column('number', {
            name: 'MANUALID'
        })
        MANUALID!: number | null;

        @PrimaryColumn('number', {
            nullable: false,
            name: 'SIGNERASSIGNMENT_LOG_ID'
        })
        SIGNERASSIGNMENT_LOG_ID!: number;
    }

    return SIGNERASSIGNMENT_LOG;
}

// Export the factory function
export { createSignatureAssignmentLog };

// Export the type representing an instance of the class generated by the factory function
export type SIGNERASSIGNMENT_LOG = InstanceType<ReturnType<typeof createSignatureAssignmentLog>>;