import React, { useContext, useEffect, useState, useRef } from 'react';
import { DOCUMENTREVISION, DocRevActiveStatuses } from '../../../../entities/org/DOCUMENTREVISION';
import axios from 'axios';
import DataTable from '../../../_shared/DataTable/DataTable';
import { MUIDataTableColumn, MUIDataTableProps } from 'mui-datatables';
import { transformDocRevsToDataTableValues } from '../../../_shared/DataTable/transformers/transformDocRevsToDataTableValues';
import UserBundleContext from '../../../../context/UserBundleContext';
import { ClickableTitleCell } from '../../../_shared/DataTable/ClickableTitleCell';
import { LoadingIndicator } from '../../../_shared/LoadingIndicator';
import { LoadingStatuses } from '../../../../utils/LoadingStatuses';
import { LucidocModal } from '../../../_shared/LucidocModal/LucidocModal';
import H3WithHelpTip from '../../../_shared/Forms/H3WithHelpTip';
import { SelectDocrevs } from '../../../_shared/Forms/Select/SelectDocrevs';
import Button from '@material-ui/core/Button';
import SaveBar from '../../../_shared/SaveBar/SaveBar';
import { Add } from '@material-ui/icons';
import { INFORMATION_HIERARCHY } from '../../../../entities/org/INFORMATION_HIERARCHY';
import { ExpandedDocrevLinks } from '../../../_shared/DataTable/expanders/ExpandedDocrevLinks';
import hasRights from '../../../../_shared/utils/hasRights';
import { RightsCodeKey } from '@/entities/master/PERSON';

type EditIhDocumentsProps = {
    ihType: 'department' | 'manual'
    ih: INFORMATION_HIERARCHY,
    isCollaborator?: boolean,
    officialOnly?: boolean
    isInAdmin: boolean
}

export function EditIhDocuments(props: EditIhDocumentsProps) {
    const context = useContext(UserBundleContext);

    const [ loadingStatus, setLoadingStatus ] = useState<LoadingStatuses>(LoadingStatuses.LOADING);
    const [ ihDocrevs, setIhDocrevs ] = useState<DOCUMENTREVISION[]>([]);
    const [ isModalOpen, setIsModalOpen ] = useState<boolean>(false);
    const [ tableData, setTableData ] = useState<MUIDataTableProps['data']>([]);
    const docrevsToAddRef = useRef<DOCUMENTREVISION[]>([]);

    const isOwner = props.ih.USERID === context.user?.USERID;
    const isOrgAdmin = hasRights(context, RightsCodeKey.OrganizationAdministrator);
    const isCollaborator = props.isCollaborator;

    useEffect(() => {
        loadDocuments().then();
    }, []);

    useEffect(() => {
        // so when you close the modal it clears out the data
        docrevsToAddRef.current = [];
    }, [isModalOpen]);

    async function loadDocuments() {
        setLoadingStatus(LoadingStatuses.LOADING);

        const res = await axios.get('/api/doc-manager/info-hierarchy/edit-info-hierarchy/documents', {
            params: {
                ihType: props.ihType,
                ihID: props.ih.INFORMATION_HIERARCHY_ID,
                activeOnly: false,
                officialOnly: props.officialOnly,
                ignoreDocumentVisibility: props.isInAdmin,
            }
        });

        setIhDocrevs(res.data.docrevs || []);
        setTableData(
            transformDocRevsToDataTableValues(
                res.data.docrevs || [],
                [
                    'Department'
                ],
                [
                    'Official Date',
                    'Source Revision',
                ]
            )
        );

        setLoadingStatus(LoadingStatuses.READY);
    }

    async function saveDocuments() {
        setIsModalOpen(false);
        setLoadingStatus(LoadingStatuses.SAVING);

        await axios.post('/api/doc-manager/info-hierarchy/edit-info-hierarchy/documents/add-documents-to-ih', {
            ihType: props.ihType,
            ihID: props.ih.INFORMATION_HIERARCHY_ID,
            docrevsToAdd: docrevsToAddRef.current,
        });

        await loadDocuments();
    }

    return (
        // calc(dimensions) match EditInfoHierarchy.tsx, just so this modal can be loaded elsewhere, and still be large, so loading data doesn't resize the modal
        <div style={{ margin: '1rem', minHeight: 500, minWidth: 1000 }}>

            {(isOwner || isOrgAdmin || isCollaborator) &&
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        startIcon={<Add />}
                        onClick={() => setIsModalOpen(true)}
                    >
                        Add Documents
                    </Button>
                </div>
            }

            {loadingStatus !== LoadingStatuses.READY &&
                <LoadingIndicator loadingStatus={loadingStatus} />
            }

            {loadingStatus === LoadingStatuses.READY &&
                <>
                    <DataTable
                        muiDataTableProps={{
                            title: `${props.ihType === 'manual' ? context.organization?.MANUALNAME : context.organization?.ORGUNITNAME} Documents`,
                            columns: tableData[0] && Object.keys(tableData[0]).map(key => {
                                const returnObj: MUIDataTableColumn = {
                                    name: key
                                };

                                if (key === 'Title') {
                                    returnObj.options = {
                                        customBodyRender: (value, tableMeta) => {
                                            return ClickableTitleCell(
                                                context.organization?.PREFIX,
                                                tableMeta.rowData[0],
                                                tableMeta.rowData[1],
                                                tableMeta.rowData[2]
                                            );
                                        }
                                    };
                                }

                                if (key === 'Source Revision') {
                                    returnObj.options = {
                                        display: 'false',
                                        filter: true
                                    };
                                }

                                return returnObj;
                            }),
                            data: tableData,
                            options: {
                                expandableRows: true,
                                customSearch: (searchQuery: string, currentRow: string[]) => {
                                    if (!searchQuery) {
                                        return true;
                                    }
        
                                    const query = searchQuery.toLowerCase();
                                    return currentRow.reduce((searchSatisfied: boolean, rowDataElem: string) => {
                                        if (searchSatisfied) {
                                            return true;
                                        }
        
                                        const dataElem = rowDataElem.toString().toLowerCase();
                                        return searchSatisfied || dataElem.indexOf(query) >= 0;
                                    }, false);
                                },
                                renderExpandableRow: (rowData: string[]) => {
                                    const docrevID = ihDocrevs.find(dr => dr.DOCID === parseInt(rowData[0]))!.DOCREVID;

                                    return <ExpandedDocrevLinks docrevID={docrevID} />;
                                }
                            }
                        }}
                    />

                    <LucidocModal
                        open={isModalOpen}
                        onClose={() => {
                            setIsModalOpen(false);
                            docrevsToAddRef.current = [];
                        }}
                        title={`Add Documents to ${props.ihType === 'manual' ? context.organization?.MANUALNAME : context.organization?.ORGUNITNAME}`}
                    >
                        <div style={{ height: 600 }}>
                            <H3WithHelpTip
                                helpText={''}
                                text={`Select Documents that belong in this ${props.ihType}`}
                            />
                            <SelectDocrevs
                                statuses={DocRevActiveStatuses}
                                onChange={docrevsToAdd => {
                                    docrevsToAddRef.current = docrevsToAdd;
                                }}
                                unselectableDocrevs={ihDocrevs}
                                ignoreDocumentVisibility={props.isInAdmin}
                            />

                            <SaveBar
                                onSave={() => saveDocuments()}
                                onCancel={() => setIsModalOpen(false)}
                                styles={{
                                    footerPosition: 'static',
                                }}
                            />
                        </div>
                    </LucidocModal>
                </>
            }
        </div>
    );
}
