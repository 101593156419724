import { useContext, useEffect, useState } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { HomepageRouter } from './components/Homepage/HomepageRouter';
import InternalSupport from './components/InternalSupport/InternalSupport';
import ContractsRouter from './components/Contracts/ContractsRouter';
import Administration from './components/Administration/Administration';
import { DocManager } from './components/DocManager/DocManager';
import UserBundleContext from './context/UserBundleContext';
import { LucidocFamilyOrgIDs } from './_shared/constants/LucidocIDs';
import './stylesheets/lucidocicons.css';
import './App.css';
import './globals.css';
import Committees from './components/Committees/Committees';
import { HelpTopicContext } from './context/HelpTopicContext';
import { FavoritesContext, FavoritesContextStateType } from './context/FavoritesContext';
import { SWRConfig } from 'swr';
import { fetcher } from './utils/swr';
import { Sidebar2 } from './components/Sidebar/Sidebar2';
import { ThemeProvider } from './context/ThemeProvder';
import { UIV2Router } from './v2/UIV2Router';
import Header from './v2/pages/header/Header';
import { Landing } from './components/Homepage/Landing';
import { TooltipProvider } from './ui/ui/tooltip';
const history = createBrowserHistory();

export default function App() {
    const context = useContext(UserBundleContext);
    const [helpTopicID, setHelpTopicID] = useState<number | null>(null);
    const [favoritesCtx, setFavoritesCtx] = useState<FavoritesContextStateType>({
        favorites: [],
        favoritesToDelete: [],
    });

    useEffect(() => {
        // disable cors
        fetch('http://localhost:25565/isAlive', {
            method: 'GET',
            mode: 'no-cors',
        }).then(() => {
            console.log('Edit in Place API is available.');
            window.localStorage.setItem('EditInPlace_enabled', '1');
            window.localStorage.setItem('EditInPlace_2', '1');
        }).catch(() => {
            console.log('Edit in Place API is not available.');
            window.localStorage.removeItem('EditInPlace_enabled');
            window.localStorage.removeItem('EditInPlace_2');
        });
    }, []);


    return (
        <TooltipProvider>
            <Router history={history}>
                <SWRConfig
                    value={{
                        fetcher: fetcher,
                        revalidateOnFocus: true
                    }}
                >
                    <HelpTopicContext.Provider value={{ helpTopicID, setHelpTopicID }}>
                        <FavoritesContext.Provider value={{ favoritesCtx, setFavoritesCtx }}>
                            <div className="app">
                                <Switch>
                                    <Route path='/landing' component={Landing} />
                                    <Route path='/o/:prefix' component={UIV2Router} />
                                    <Route render={() =>
                                        <>
                                            <ThemeProvider defaultTheme='light'>
                                                <Header />
                                                <div className={'main-panel'}>
                                                    <Sidebar2 />
                                                    <div className={'content-area'}>
                                                        {/* NORMAL FEATURES */}
                                                        <Switch>
                                                            <Route path='/administration' component={Administration} />
                                                            <Route path='/homepage' component={HomepageRouter} />
                                                            <Route path='/contracts' component={ContractsRouter} />
                                                            <Route path='/committees' component={Committees} />
                                                            <Route path='/docmanager' component={DocManager} />
                                                        </Switch>

                                                        {/* NEW FEATURES */}
                                                        {LucidocFamilyOrgIDs[context.organization?.ORGANIZATIONID || 0] &&
                                                            <Switch>
                                                            </Switch>
                                                        }

                                                        {/* LUCIDOC-ONLY FEATURES */}
                                                        {context.user?.ORGANIZATIONID === 10440 &&
                                                            <Switch>
                                                                <Route path='/internal-support' component={InternalSupport} />
                                                            </Switch>
                                                        }

                                                    </div>
                                                </div>
                                            </ThemeProvider>
                                        </>
                                    } />
                                </Switch>
                            </div>
                        </FavoritesContext.Provider>
                    </HelpTopicContext.Provider>
                </SWRConfig>
            </Router>
        </TooltipProvider>
    );

}
