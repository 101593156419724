import {
    Entity,
    JoinColumn,
    ManyToOne,
    OneToOne,
    PrimaryColumn,
} from 'typeorm';
import { DOCUMENTREVISION,  } from './DOCUMENTREVISION';
import { LINK,  } from '../master/LINK';
import { SchemaEntityManager } from '../SchemaEntityManager';

// Factory function to create DOCUMENTREVISION_LINK entity with dynamic schema
function createDocumentRevisionLinkEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('DOCUMENTREVISION_LINK', { schema })
    class DOCUMENTREVISION_LINK {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            name: 'DOCREVID'
        })
        DOCREVID!: number;

        @ManyToOne(
            () => manager.getDocumentRevisionEntity(schema),
            DOCUMENTREVISION => DOCUMENTREVISION.DOCUMENTREVISION_LINKS
        )
        @JoinColumn({
            name: 'DOCREVID'
        })
        DOCREV!: DOCUMENTREVISION | null;

        @PrimaryColumn('number', {
            nullable: false,
            name: 'LINK_ID'
        })
        LINK_ID!: number;

        @OneToOne(
            () => manager.getLinkEntity(schema), 
            (link) => link.DOCUMENTREVISION_LINK
        )
        @JoinColumn({
            name: 'LINK_ID'
        })
        LINK!: LINK | null;
    }

    return DOCUMENTREVISION_LINK;
}

export { createDocumentRevisionLinkEntity };
export type DOCUMENTREVISION_LINK = InstanceType<ReturnType<typeof createDocumentRevisionLinkEntity>>;
