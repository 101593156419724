import React, { useContext, useEffect, useState } from 'react';
import { SelectManuals } from '../../../../_shared/Forms/Select/SelectManuals';
import H3WithHelpTip from '../../../../_shared/Forms/H3WithHelpTip';
import { makeStyles } from '@material-ui/core/styles';
import { INFORMATION_HIERARCHY } from '../../../../../entities/org/INFORMATION_HIERARCHY';
import axios from 'axios';
import SaveBar from '../../../../_shared/SaveBar/SaveBar';
import { LoadingIndicator } from '../../../../_shared/LoadingIndicator';
import { LoadingStatuses } from '../../../../../utils/LoadingStatuses';
import SaveIndicator from '../../../../_shared/SaveIndicator';
import UserBundleContext from '../../../../../context/UserBundleContext';
import { RefreshRounded } from '@material-ui/icons';

type EditDocManualsProps = {
    match: any
}

const useStyles = makeStyles({
    editDocManualsMainDiv: {
        padding: '.5rem 1rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
    }
});

export function EditDocManuals(props: EditDocManualsProps) {
    const classes = useStyles();

    const context = useContext(UserBundleContext);
    const userID = context?.user?.USERID;

    const manualNamePlural = context.organization?.MANUALNAMEPLURAL || 'Manuals';

    const [ loadingStatus, setLoadingStatus ] = useState<LoadingStatuses>(LoadingStatuses.LOADING);
    const [ isSaveIndicatorVisible, setIsSaveIndicatorVisible ] = useState(false);
    const [ selectedManuals, setSelectedManuals ] = useState<INFORMATION_HIERARCHY[]>([]);

    useEffect(() => {
        getManuals().then();
    }, []);

    async function getManuals() {
        setIsSaveIndicatorVisible(false);
        setLoadingStatus(LoadingStatuses.LOADING);

        const res = await axios.get('/api/doc-manager/document/edit-doc/manuals', {
            params: {
                docRevID: props.match.params.docrevid
            }
        });

        setSelectedManuals(res.data.manuals || []);
        setLoadingStatus(LoadingStatuses.READY);
    }

    async function saveSelectedManuals() {
        setLoadingStatus(LoadingStatuses.SAVING);

        const res = await axios.post('/api/doc-manager/document/edit-doc/manuals/save-selected-manuals', {
            docRevID: props.match.params.docrevid,
            selectedManuals
        });

        setSelectedManuals(res.data.manuals || []);
        setIsSaveIndicatorVisible(true);
        setLoadingStatus(LoadingStatuses.READY);
    }

    if (loadingStatus !== LoadingStatuses.READY) {
        return <LoadingIndicator loadingStatus={loadingStatus} />;
    }

    return (
        <div className={classes.editDocManualsMainDiv}>
            <H3WithHelpTip
                helpText={`This document will appear in these ${manualNamePlural}`}
                text={`Select ${manualNamePlural}`}
            />
            <SelectManuals
                onChange={manuals => setSelectedManuals(manuals)}
                selectedManuals={selectedManuals}
                userID={userID}
            />

            <SaveBar
                onSave={ () => saveSelectedManuals() }
                // saveButtonText='Save Edits'

                onCancel={ () => getManuals() }
                // cancelButtonText='Undo Edits'
                cancelIcon={ <RefreshRounded /> }

                onClose={ () => window.parent.close() }
            />

            <SaveIndicator
                open={isSaveIndicatorVisible}
                onClose={() => setIsSaveIndicatorVisible(false)}
                message={`${manualNamePlural} saved successfully.`}
            />
        </div>
    );
}
