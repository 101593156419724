import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Clear, InsertDriveFile, MenuBook } from '@material-ui/icons';
import { allowableItems } from './SelectedItemTypes';
import LucidocColors from '../../../../../constants/LucidocColors';
import { IconButton } from '@material-ui/core';

export const ReactSelectMediumGrey = '#666'; // X button color
export const ReactSelectDarkGrey   = '#333'; // X button hover color

const useStyles = makeStyles({
    selectedItemRow: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: '0 1rem 1rem 0',
        justifyContent: 'space-between',
        '&:hover': {
            backgroundColor: LucidocColors.lightLightGray,
        }
    },
    selectedItemIconAndName: {
        display: 'flex',
        alignItems: 'center',
    },
    selectedItemIcon: {
        color: LucidocColors.lightBlue
    },
    selectedItemName: {
        color: ReactSelectDarkGrey,
        marginLeft: '.5rem',
    },
    clearItemButton: {
        fontSize: 24,
        color: ReactSelectMediumGrey,
        '&:hover': {
            color: LucidocColors.red,
            cursor: 'pointer',
        }
    }
});

type SelectedItemProps = {
    type: allowableItems
    ID: number
    name: string
    onRemove: (ID: number) => void
}

export function SelectedItem(props: SelectedItemProps) {
    const classes = useStyles();

    const icons: {
        [key: string]: JSX.Element // || something else, React.component, etc
    } = {
        'manual': <MenuBook className={classes.selectedItemIcon} />,
        'docrev': <InsertDriveFile className={classes.selectedItemIcon} />
        // 'department': some other component,
        // 'person': some other component
    };

    return (
        <div className={classes.selectedItemRow}>
            <div className={classes.selectedItemIconAndName}>
                {icons[props.type]}
                <span className={classes.selectedItemName}>{props.name}</span>
            </div>

            <IconButton
                size={'small'}
                className={classes.clearItemButton}
                onClick={() => props.onRemove(props.ID)}
            >
                <Clear
                    fontSize={'small'}
                />
            </IconButton>
        </div>
    );
}
