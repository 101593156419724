import React from 'react';
import { DOCUMENTREVISION } from '../../../../../entities/org/DOCUMENTREVISION';
import { Button, makeStyles } from '@material-ui/core';
import DataTable from '../../../../_shared/DataTable/DataTable';
import { MUIDataTableColumn } from 'mui-datatables';
import { transformDate, transformReviewCycle } from '../../../../../_shared/utils/documentFunctions';

type UpdateDocrevReviewMetadataStagedChangesProps = {
    stagedDocRevs: DOCUMENTREVISION[];
    onEdit: (docRevs: DOCUMENTREVISION[]) => void;
    onRemove: (docRevs: DOCUMENTREVISION[]) => void;
};

const useStyles = makeStyles({
    container: {
        position: 'relative',
        zIndex: 0,
    },
    editButton: {
        marginRight: '10px',
    },
});

export function UpdateDocrevReviewMetadataStagedChanges(props: UpdateDocrevReviewMetadataStagedChangesProps) {
    const stagedDocRevs = props.stagedDocRevs;
    const classes = useStyles();

    if (stagedDocRevs.length === 0) {
        return <></>;
    }

    const formattedTableData = stagedDocRevs.map(docRev => {
        return {
            'Document ID': docRev.DOCID,
            'Revision Number': docRev.REVNO,
            'Title': docRev.TITLE,
            'Next Review Date': transformDate(docRev.NEXTREVDT),
            'Review Cycle': transformReviewCycle(docRev.REVIEW_CYCLE),
            'Review Cycle Justification': docRev.REVIEW_CYCLE_JUSTIFICATION,
            'Department': docRev.DOCUMENT?.INFORMATION_HIERARCHY?.TITLE,
        };
    });

    const noFilterColumns = [
        'Title',
        'Review Cycle Justification',
    ];

    return (
        <div className={classes.container}>
            <DataTable
                noFilterCount
                muiDataTableProps={{
                    data: formattedTableData,
                    title: 'Staged Changes',
                    columns: formattedTableData[0] && Object.keys(formattedTableData[0]).map(key => {
                        const returnObj: MUIDataTableColumn = {
                            name: key,
                        };
    
                        if (noFilterColumns.includes(key)) {
                            returnObj.options = {
                                filter: false,
                            };
                        }
    
                        return returnObj;
                    }),
                    options: {
                        selectableRows: 'multiple',
                        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
                            <div>
                                <Button
                                    className={classes.editButton}
                                    variant='contained'
                                    onClick={() => {
                                        const selectedDocRevs = selectedRows.data.map(row => stagedDocRevs[row.index]);
                                        props.onEdit(selectedDocRevs);
                                    }}
                                >
                                    Edit Selected
                                </Button>
                                <Button
                                    className={classes.editButton}
                                    variant='contained'
                                    onClick={() => {
                                        const selectedDocRevs = selectedRows.data.map(row => stagedDocRevs[row.index]);
                                        props.onRemove(selectedDocRevs);
                                        setSelectedRows([]);
                                    }}
                                >
                                    Remove
                                </Button>
                            </div>
                        ),
                    },
                }}
            />
        </div>
    );
}
