import React from 'react';

interface RefreshOutlineProps extends React.SVGProps<SVGSVGElement> {
    size: number
    className?: string
}

export const RefreshOutlineIcon: React.FC<RefreshOutlineProps> = ({ size, className, ...props }) => {
    return (
        <svg width={size.toString()} height={(size / 1.25).toString()} className={className} {...props} viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 6.83333L14.7517 6.00564C13.8604 3.03462 11.1156 1 8.01372 1V1C4.14772 1 1 4.13401 1 8V8C1 11.866 4.13401 15 8 15H8.13597C8.81685 15 9.49147 14.8701 10.1236 14.6172L10.1767 14.596C11.425 14.0967 12.5169 13.2723 13.339 12.2084L13.5 12" stroke="#6B7280" stroke-width="2" />
            <path d="M15.5516 6.30764C15.6153 6.26978 15.659 6.20557 15.6708 6.13237L16.0227 3.94232C16.0475 3.78828 16.2057 3.6943 16.3528 3.74626L17.7249 4.23093C17.844 4.273 17.913 4.39733 17.8857 4.52067L16.7921 9.46291C16.761 9.6038 16.6173 9.68912 16.4787 9.64908L11.6156 8.24473C11.4942 8.20968 11.4181 8.0896 11.4381 7.96488L11.669 6.52813C11.6938 6.37409 11.852 6.28011 11.9991 6.33207L14.0906 7.07088C14.1606 7.09558 14.2378 7.08796 14.3016 7.0501L15.5516 6.30764Z" fill="#6B7280" />
        </svg>
    );
};  