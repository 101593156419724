import { MESSAGE_BOARD_ITEM } from '@/entities/org/MESSAGE_BOARD_ITEM';
import { Button } from '@/ui/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogTrigger } from '@/ui/ui/dialog';
import axios from 'axios';
import React, { useState } from 'react';

interface ToggleMessageBoardDialogProps {
    messageToToggle: MESSAGE_BOARD_ITEM;
    refetch: () => void;
}

export const ToggleMessageBoardDialog: React.FC<ToggleMessageBoardDialogProps> = ({ messageToToggle, refetch }) => {

    const [open, setOpen] = useState(false);

    const toggleMessage = async () => {
        const res = await axios.post('/api/homepage/card/message-board/toggle-message-visibility', {
            id: messageToToggle.MESSAGE_BOARD_ITEM_ID,
            visible: !messageToToggle.VISIBLE,
        });

        if (res.status === 200) {
            setOpen(false);
            refetch();
        }
    };

    return (

        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger>
            <Button size={'sm'} variant={'ghost'} className='text-[10px] h-5 px-1'>{messageToToggle.VISIBLE ? 'Delete' : 'Show'}</Button>
            </DialogTrigger>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Are you sure you want to {messageToToggle.VISIBLE ? 'delete' : 'show'} this message?</DialogTitle>
                    <DialogDescription>
                        {messageToToggle.VISIBLE ? 'The message will no longer be visible to other users' : 'The message will be visible to other users'}
                    </DialogDescription>
                </DialogHeader>
                <div className='w-full flex-col space-y-2'>
                    <div className='flex-col'>
                        <p>Message:</p>
                        <p className='text-primary'>{messageToToggle.MESSAGE}</p>
                    </div>
                    <div className='flex flex-row space-x-4 justify-end'>
                        <Button onClick={() => toggleMessage()} variant={'destructive'}>
                            {messageToToggle.VISIBLE ? 'Delete' : 'Show'} Message
                        </Button>
                        <Button onClick={() => setOpen(false)}>Exit</Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};