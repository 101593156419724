import React from 'react';

interface EyeHiddenOutlineProps {
    size: number;
}

export const EyeHiddenOutlineIcon: React.FC<EyeHiddenOutlineProps> = ({ size }) => {
    return (
        <svg width={size.toString()} height={size.toString()} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.243432 8.41622C0.0935948 8.16649 0.0935945 7.85451 0.243431 7.60478L0.427521 7.29796C2.02348 4.63803 4.89801 3.0105 8 3.0105C11.102 3.0105 13.9765 4.63803 15.5725 7.29796L15.7566 7.60478C15.9064 7.85451 15.9064 8.16649 15.7566 8.41622L15.5725 8.72303C13.9765 11.383 11.102 13.0105 8 13.0105C4.89801 13.0105 2.02348 11.383 0.427522 8.72303L0.243432 8.41622Z" fill="#6B7280" />
            <path d="M8 5.3855C6.55025 5.3855 5.375 6.56075 5.375 8.0105C5.375 9.46025 6.55025 10.6355 8 10.6355C9.44975 10.6355 10.625 9.46025 10.625 8.0105C10.625 6.56075 9.44975 5.3855 8 5.3855Z" fill="#6B7280" stroke="white" strokeWidth="1.25" />
            <path d="M1.81297 12.7834C1.52008 13.0763 1.52008 13.5512 1.81297 13.8441L2.16652 14.1976C2.45942 14.4905 2.93429 14.4905 3.22718 14.1976L14.1873 3.23745C14.4802 2.94456 14.4802 2.46968 14.1873 2.17679L13.8338 1.82324C13.5409 1.53034 13.066 1.53034 12.7731 1.82324L1.81297 12.7834Z" fill="#6B7280" stroke="white" />
        </svg>

    );
};