import React from 'react';

interface CurrentLocationProps {
    size: number;
}

export const CurrentLocationIcon: React.FC<CurrentLocationProps> = ({ size }) => {
    return (
        <svg width={size.toString()} height={size.toString()} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" rx="4" fill="hsl(var(--primary))" />
            <path d="M6.82935 12.1389C5.48738 9.83916 6.01521 6.90518 8.07456 5.21732C10.3572 3.34648 13.6428 3.34648 15.9254 5.21732C17.9848 6.90518 18.5126 9.83916 17.1706 12.1389L13.2956 18.7798C12.7167 19.7718 11.2833 19.7718 10.7044 18.7798L6.82935 12.1389Z" fill="hsl(var(--white))" />
            <path d="M9 10C9 8.34315 10.3431 7 12 7C13.6569 7 15 8.34315 15 10C15 11.6569 13.6569 13 12 13C10.3431 13 9 11.6569 9 10Z" fill="hsl(var(--primary))" />
        </svg>


    );
};