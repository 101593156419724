import {
    Column,
    Entity,
    JoinColumn,
    ManyToOne,
    PrimaryColumn,
} from 'typeorm';
import { DOCUMENTREVISION,  } from './DOCUMENTREVISION';
import { PERSON,  } from '../master/PERSON';
import { SchemaEntityManager } from '../SchemaEntityManager';

function createTrackingEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('TRACKING', { schema: schema })
    class TRACKING {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'TRACKINGID'
        })
        TRACKINGID!: number;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'DOCREVID'
        })
        DOCREVID!: number | null;

        @ManyToOne(
            () => manager.getDocumentRevisionEntity(schema), 
            (documentRevision) => documentRevision.TRACKINGS
        )
        @JoinColumn({
            name: 'DOCREVID'
        })
        DOCREV!: DOCUMENTREVISION | null;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'USERID'
        })
        USERID!: number | null;

        @ManyToOne(
            () => manager.getPersonEntity(schema), 
            (person) => person.TRACKINGS    
        )
        @JoinColumn({
            name: 'USERID'
        })
        USER!: PERSON | null;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'ORGANIZATIONID'
        })
        ORGANIZATIONID!: number | null;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'INFORMATION_HIERARCHY_ID'
        })
        INFORMATION_HIERARCHY_ID!: number | null;

        @Column('varchar2', {
            nullable: true,
            length: 2000,
            name: 'MESSAGE'
        })
        MESSAGE!: string | null;

        @Column('timestamp', {
            nullable: true,
            name: 'EVENTTIME'
        })
        EVENTTIME!: Date | null;

        @Column('varchar2', {
            nullable: true,
            length: 20,
            name: 'ACTION'
        })
        ACTION!: string | null;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'ACTIONID'
        })
        ACTIONID!: number | null;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'ACTORID'
        })
        ACTORID!: number | null;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'IMPERSONATOR_USERID'
        })
        IMPERSONATOR_USERID!: number | null;

        @ManyToOne(
            () => manager.getPersonEntity(schema), 
            (person) => person.TRACKINGS_AS_IMPERSONATOR
        )
        @JoinColumn({
            name: 'IMPERSONATOR_USERID'
        })
        IMPERSONATOR!: PERSON | null;
    }

    return TRACKING;
}

export { createTrackingEntity };
export type TRACKING = InstanceType<ReturnType<typeof createTrackingEntity>>;