import { CSSProperties, ReactElement, cloneElement } from 'react'; 
import './iconbutton.css';

interface IconButtonProps {
    // mandatory
    text: string;
    icon: ReactElement;

    // optional
    title?: string;
    iconColor?: string;
    hide?: boolean;
    disabled?: boolean;
    border?: boolean;
    onClick?: () => void; 
}

function IconButton(props: IconButtonProps) {
    if (props.hide) {
        return null;
    }

    const iconStyle: CSSProperties = {
        color: props.iconColor || 'black',
        marginRight: '5px'
    };

    return (
        <li
            onClick={!props.disabled ? props.onClick : undefined}
            title={props.title}
            className={
                'iconbutton'
                + (props.disabled ? ' iconbutton-disabled' : '')
                + (props.border ? ' iconbutton-border' : '')
            }
        >
            {cloneElement(props.icon, { style: iconStyle })}
            {props.text.toUpperCase()}
        </li>
    );
}

export default IconButton;
