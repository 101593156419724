import React from 'react';

interface GraduationHatOutlineProps {
    size: number
}

export const GraduationHatOutlineIcon: React.FC<GraduationHatOutlineProps> = ({ size }) => {
    return (
        <svg width={size.toString()} height={(size / (24/18)).toString()} viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.6 5L11.9038 1.1234C11.9654 1.09776 12.0346 1.09776 12.0962 1.1234L21.4 5L12.0962 8.8766C12.0346 8.90224 11.9654 8.90224 11.9038 8.8766L2.6 5Z" stroke="hsl(var(--foreground))" strokeWidth="2" />
            <path d="M5.75429 13.0141C5.75161 13.0123 5.75 13.0093 5.75 13.0061V7.75H6.25V11.978C6.25 12.582 6.59127 13.1342 7.13152 13.4043C10.1963 14.9367 13.8037 14.9367 16.8685 13.4043C17.4087 13.1342 17.75 12.582 17.75 11.978V7.75H18.25V13.0061C18.25 13.0093 18.2484 13.0123 18.2457 13.0141C14.4523 15.4979 9.54769 15.4979 5.75429 13.0141Z" fill="hsl(var(--foreground))" stroke="hsl(var(--foreground))" strokeWidth="1.5" />
        </svg>

    );
};