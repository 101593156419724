import {
    Column,
    Entity,
    JoinColumn,
    ManyToOne,
    OneToMany,
    PrimaryColumn,
} from 'typeorm';

import { DOCUMENTREVISION,  } from './DOCUMENTREVISION';
import { APPROVAL_SESSION_STEP,  } from './APPROVAL_SESSION_STEP';
import { SIGNATURE_REQUEST,  } from './SIGNATURE_REQUEST';
import { SIGNATURE_LOG,  } from './SIGNATURE_LOG';
import { INFORMATION_HIERARCHY,  } from './INFORMATION_HIERARCHY';
import { SchemaEntityManager } from '../SchemaEntityManager';

export enum SignatureSessionTypes {
    archive     = 'archive',
    officialize = 'officialize',
    review      = 'review'
}

export enum SignatureSessionStatuses {
    active    = 'active',
    cancelled = 'cancelled',
    complete  = 'complete',
    rejected  = 'rejected',
    voided    = 'voided'
}


function createSignatureSessionEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('SIGNATURE_SESSION', { schema: schema })
    class SIGNATURE_SESSION {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'SIGNATURE_SESSION_ID'
        })
        SIGNATURE_SESSION_ID!: number;
    
        @Column('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'DOCREVID'
        })
        DOCREVID!: number;
    
        @ManyToOne(
            () => manager.getDocumentRevisionEntity(schema), 
            (documentRevision) => documentRevision.SIGNATURE_SESSIONS
        )
        @JoinColumn({
            name: 'DOCREVID'
        })
        DOCUMENTREVISION!: DOCUMENTREVISION | null;
    
        @Column('varchar2', {
            nullable: false,
            length: 20,
            name: 'TYPE'
        })
        TYPE!: string;
    
        @Column('varchar2', {
            nullable: false,
            length: 20,
            default: () => '\'active\'',
            name: 'STATUS'
        })
        STATUS!: string;
    
        @Column('timestamp', {
            nullable: false,
            default: () => 'SYSDATE',
            name: 'START_DATE'
        })
        START_DATE!: Date;
    
        @Column('timestamp', {
            nullable: true,
            name: 'FINAL_STATUS_DATE'
        })
        FINAL_STATUS_DATE!: Date | null;
    
        @Column('number', {
            nullable: false,
            name: 'MANUALID',
            default: () => '0',
        })
        MANUALID!: number;
    
        @ManyToOne(
            () => manager.getInformationHierarchyEntity(schema), 
            (informationHierarchy) => informationHierarchy.SIGNATURE_SESSIONS
        )
        @JoinColumn({
            name: 'MANUALID'
        })
        MANUAL!: INFORMATION_HIERARCHY | null;
    
        @OneToMany(
            () => manager.getApprovalSessionStepEntity(schema), 
            (approvalSessionStep) => approvalSessionStep.SIGNATURE_SESSION
        )
        APPROVAL_SESSION_STEPS!: APPROVAL_SESSION_STEP[] | null;
    
        @OneToMany(
            () => manager.getSignatureRequestEntity(schema), 
            (signatureRequest) => signatureRequest.SIGNATURE_SESSION
        )
        SIGNATURE_REQUESTS!: SIGNATURE_REQUEST[] | null;
    
        @OneToMany(
            () => manager.getSignatureLogEntity(schema), 
            (signatureLog) => signatureLog.SIGNATURE_SESSION
        )
        SIGNATURE_LOGS!: SIGNATURE_LOG[] | null;
    
        static Types = SignatureSessionTypes;
    
        static Statuses = SignatureSessionStatuses;
    }
    
    return SIGNATURE_SESSION;
}

export { createSignatureSessionEntity };
export type SIGNATURE_SESSION = InstanceType<ReturnType<typeof createSignatureSessionEntity>>;

