import React, { useRef } from 'react';

import { createSortableDate } from '@/_shared/utils/docManagerFunctions';
import { DataTable, DataTableHandle } from '@/ui/ui/datatable/data-table';
import { PaginationToolsForTable } from '@/ui/ui/datatable/pagination-tools';
import { useMyMeetingsColumns } from '@/v2/components/consentAgendaTable/MyMeetingsTableColumns';
import MyMeetingsTableToolbar from '@/v2/components/consentAgendaTable/MyMeetingsTableToolbar';
import useSWR from 'swr';
import { ConsentAgendaEntry } from '@/v2/components/consentAgendaTable/MyMeetingsTable';

export const CommitteesMyMeetingsTab: React.FC = () => {

    const futureMyMeetingsColumns = useMyMeetingsColumns();
    const pastMyMeetingsColumns = useMyMeetingsColumns();

    const { data: pastConsentAgendas, isLoading: isLoadingPast } = useSWR('/api/consent-agenda/my-meetings/past');
    const { data: futureConsentAgendas, isLoading: isLoadingFuture } = useSWR('/api/consent-agenda/my-meetings/future');

    const dataTableFutureRef = useRef<DataTableHandle<ConsentAgendaEntry>>(null);
    const dataTablePastRef = useRef<DataTableHandle<ConsentAgendaEntry>>(null);

    return (
        <div className='flex pt-4 w-full items-start'>
            <div className='pr-2 grid w-full'>
                <p className='text-lg font-semibold font-primary'>Upcoming Meetings</p>
                <DataTable
                    columns={futureMyMeetingsColumns}
                    data={
                        isLoadingFuture ? [] : futureConsentAgendas.consentAgendas.map(consentAgenda => ({
                            id: consentAgenda.CONSENT_AGENDA_ID,
                            committeeId: consentAgenda.COMMITTEE_ID,
                            committeeName: consentAgenda.COMMITTEE?.NAME,
                            date: new Date(consentAgenda.MEETING_START_DATE),
                            year: createSortableDate(new Date(consentAgenda.MEETING_START_DATE)).split('-')[0],
                            status: consentAgenda.STATUS,
                            committee: consentAgenda.COMMITTEE,
                        }))
                    }
                    toolbar={MyMeetingsTableToolbar}
                    ref={dataTableFutureRef}
                    loading={isLoadingPast}
                    exportAsFileName='upcoming_meetings'
                    renderExpandedRowForTableExport={async () => []}
                    pagination={PaginationToolsForTable}
                    initialColumnVisibility={{
                        'year': false
                    }}
                />
            </div>
            <div className='pl-2 grid w-full'>
                <p className='text-lg font-semibold font-primary'>Past Meetings</p>
                <DataTable
                    columns={pastMyMeetingsColumns}
                    ref={dataTablePastRef}
                    data={
                        isLoadingPast ? [] : pastConsentAgendas.consentAgendas.map(consentAgenda => ({
                            id: consentAgenda.CONSENT_AGENDA_ID,
                            committeeId: consentAgenda.COMMITTEE_ID,
                            committeeName: consentAgenda.COMMITTEE?.NAME,
                            date: new Date(consentAgenda.MEETING_START_DATE),
                            year: createSortableDate(new Date(consentAgenda.MEETING_START_DATE)).split('-')[0]
                        }))
                    }
                    toolbar={MyMeetingsTableToolbar}
                    loading={isLoadingPast}
                    exportAsFileName='past_meetings'
                    renderExpandedRowForTableExport={async () => []}
                    pagination={PaginationToolsForTable}
                    initialColumnVisibility={{
                        'year': false
                    }}
                />
            </div>
        </div>
    );
};