import { PreferencesEditor } from '@/v2/pages/preferences/Preferences';
import { HelpTopicContext } from '@/context/HelpTopicContext';
import { helpTopicDocIDs } from '@/_shared/constants/HelpSystemIDs';
import { useContext, useEffect } from 'react';
import { TemporaryFavoritesStar } from '@/components/_shared/TemporaryFavoritesStar/TemporaryFavoritesStar';
import { formatTitle, useDocumentTitle } from '@/hooks/useDocumentTitle';
import { setTitleInAdmin } from '@/utils/setTitleInAdmin';

interface ManagePreferencesProps {

}

export const ManagePreferences: React.FC<ManagePreferencesProps> = () => {
    const { setHelpTopicID } = useContext(HelpTopicContext);
    const title = formatTitle('Admin', 'Manage User Preferences');
    const perlTitle = 'Manage User Preferences';

    useEffect(() => {
        setHelpTopicID(helpTopicDocIDs.ADM_HOME_PREFERENCES);
        setTitleInAdmin(perlTitle);
    }, []);

    useDocumentTitle(title);

    return (
        <div className='flex flex-col w-full'>
            <div className='p-2'>
                <div className='flex flex-row place-content-between'>
                <p className='text-lg'>Modifying the preferences on this page will change the default preferences.</p>
                <TemporaryFavoritesStar
                    targetPathName={'admin/admin.pl?repname=user_preferences'}
                />
                </div>
                <p className='text-lg'>This will affect:</p>
                <ul className='list-disc'>
                    <li>Any new users created after you hit save.</li>
                    <li>Any users that haven't modified their preferences yet.</li>
                </ul>
            </div>
            <PreferencesEditor mode='organization' />
        </div >
    );
};