import React, { useContext, useEffect, useReducer, useState } from 'react';
import { MUIDataTableProps } from 'mui-datatables';
import { IconButton, makeStyles } from '@material-ui/core';
import { Clear, Send } from '@material-ui/icons';
import ApprovalRouteEditor from '../../ApprovalRouteEditor/ApprovalRouteEditor';
import { DOCUMENTREVISION } from '../../../entities/org/DOCUMENTREVISION';
import axios from 'axios';
import { LoadingIndicator } from '../../_shared/LoadingIndicator';
import { LoadingStatuses } from '../../../utils/LoadingStatuses';
import { ucFirst } from '../../../_shared/utils/docManagerFunctions';
import { INFORMATION_HIERARCHY } from '../../../entities/org/INFORMATION_HIERARCHY';
import ApprovalRoutingTableWrapper from './ApprovalRoutingTableWrapper';
import SaveBar, { saveBarHeight } from '../../_shared/SaveBar/SaveBar';
import { subheaderHeight } from '../../_shared/Subheader/Subheader';
import UserBundleContext from '../../../context/UserBundleContext';
import { constructFullName } from '@/components/UserPicker/utils/constructFullName';

interface PendingProps {
    selectedList: 'pending' | 'active'
}

interface DocRevTableValues {
    DocRevID: number
    DocID: number,
    RevNo: number,
    Title: string
    'Document Type': string
    'Approval Type': string
    Department: string
    Owner: string
}

const useStyles = makeStyles({
    mainContainer: {
        position: 'relative',
        overflowY: 'hidden',
        height: `calc(100vh - ${subheaderHeight})`
    },
    tablesContainer: {
        height: `calc(100% - ${saveBarHeight})`,
        overflow: 'auto'
    },
    sliderBody: {
        top: '100vh',
        position: 'absolute',
        transition: 'top 0.2s',
        width: '100%',
        height: '100%',
        zIndex: 1001
    },
    sliderBodyVisible: {
        top: 0
    },
    sliderHeader: {
        backgroundColor: '#22313f',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: 'white',
        paddingLeft: '1rem',
    },
    sliderHeaderText: {
        margin: '0'
    },
    sliderHeaderIconButton: {
        color: 'white'
    },
    footer: {
        position: 'absolute',
        bottom: '0',
        width: '100%',
        padding: '.5rem',
        boxSizing: 'border-box',
        display: 'flex',
        backgroundColor: 'ddd',
        backgroundImage: 'linear-gradient(#ddd, #ccc)',
        justifyContent: 'center',
    },
});

export default function Pending(props: PendingProps) {
    const classes = useStyles({});

    const context = useContext(UserBundleContext);

    // data for main component
    const [ loadingStatus, setLoadingStatus ] = useState(LoadingStatuses.LOADING);

    // for the Documents table
    const [ docRevTableData, setDocRevTableData ] = useState<MUIDataTableProps['data']>([]);
    const [ docRevApprovalRoutes, setDocRevApprovalRoutes ] = useReducer(
        (state: {[key: string]: any}, newState: {[key: string]: any}) => ({ ...state, ...newState }),
        {}
    );
    const [ selectedDocRevRows, setSelectedDocRevRows ] = useState<number[]>([]); // We need to store selectedRows in state, because MuiDataTables cannot persist the selected rows if there is another unrelated state change elsewhere
    const [ selectedDocRevIDs, setSelectedDocRevIDs ] = useState<number[]>([]);
    const [ isDocTableRefreshing, setIsDocTableRefreshing ] = useState<boolean>(false);
    const [ isDocTableRouting, setIsDocTableRouting ] = useState<boolean>(false);

    // for the Manuals table (slightly out of order because WebStorm says the previous lines for docrevs are a duplicate of these)
    const [ manualTableData, setManualTableData ] = useState<MUIDataTableProps['data']>([]);
    const [ selectedManualRows, setSelectedManualRows ] = useState<number[]>([]);
    const [ selectedManualIDs, setSelectedManualIDs ] = useState<number[]>([]);
    const [ isManualTableRefreshing, setIsManualTableRefreshing ] = useState<boolean>(false);
    const [ isManualTableRouting, setIsManualTableRouting ] = useState<boolean>(false);
    const [ manualApprovalRoutes, setManualApprovalRoutes ] = useReducer(
        (state: {[key: string]: any}, newState: {[key: string]: any}) => ({ ...state, ...newState }),
        {}
    );

    // for the slide-up Approval Route Editor:
    const [ dialogOpen, setDialogOpen ] = useState(false);
    const [ selectedObjectIDs, setSelectedObjectIDs ] = useState<number[] | null>(null);
    const [ selectedObjectType, setSelectedObjectType ] = useState<'docrevid' | 'manualid' | null>(null);
    const [ selectedObjectTitle, setSelectedObjectTitle ] = useState('');

    useEffect(() => {
        loadDocRevs(props.selectedList).then(() => {
            loadManuals(props.selectedList).then(() => setLoadingStatus(LoadingStatuses.READY));
        });
    }, []);

    async function loadDocRevs(status: 'pending' | 'active') {
        setIsDocTableRefreshing(true);
        setSelectedDocRevRows([]);
        setSelectedDocRevIDs([]);

        const res = await axios.get('/api/approval-routing/get-documents-to-route', {
            params: {
                status: status
            }
        });

        if (res.data.docRevs) {
            const dataForTable = (res.data.docRevs as DOCUMENTREVISION[]).map(docRev => {
                const returnObj = {
                    DocRevID: docRev.DOCREVID,
                    DocID: docRev.DOCID,
                    RevNo: docRev.REVNO,
                    Title: docRev.TITLE,
                    HiddenTitle: docRev.TITLE, // we cannot use the regular Title because it'll go through customBodyRender and will become an object, not a string
                    'Document Type': docRev.DOCTYPE?.DESCRIPTION || '',
                    'Approval Type': ucFirst((docRev.APPROVAL_SESSIONS?.[0]?.TYPE) || ''),
                    [context.organization?.ORGUNITNAME || 'Department']: docRev.DOCUMENT?.INFORMATION_HIERARCHY?.TITLE || '',
                    Owner: docRev.DOCUMENT?.DOCOWNER ? constructFullName(docRev.DOCUMENT?.DOCOWNER, 'lastFirstMiddle') : '',
                };

                if (context.organization?.GLOBAL_IDENTIFIER_NAME
                    && context.organization?.GLOBAL_IDENTIFIER_AVAILABILITY !== 'none'
                ) {
                    returnObj[context.organization.GLOBAL_IDENTIFIER_NAME] = docRev?.GLOBAL_IDENTIFIER?.VALUE || null;
                }

                return returnObj;
            });

            setDocRevTableData(dataForTable);
        }
        setIsDocTableRefreshing(false);
    }

    async function loadManuals(status: 'pending' | 'active') {
        setIsManualTableRefreshing(true);
        setSelectedManualRows([]);
        setSelectedManualIDs([]);

        const res = await axios.get('/api/approval-routing/get-manuals-to-route', {
            params: {
                status: status
            }
        });

        if (res.data.manuals) {
            const dataForManualsTable = (res.data.manuals as INFORMATION_HIERARCHY[]).map(manual => {
                const owner = manual.USER
                    ? constructFullName(manual.USER, 'lastFirstMiddle')
                    : '[None]';

                return {
                    ManualID: manual.INFORMATION_HIERARCHY_ID,
                    Title: manual.TITLE,
                    Owner: owner,
                    // these silly extra headers help with lining up when expanding a row to view the approval route; it's not perfect, but it's better
                    ' ': '',
                    '  ': '',
                    '   ': '',
                };
            });

            setManualTableData(dataForManualsTable);
        }
        setIsManualTableRefreshing(false);
    }

    async function getDocRevApprovalRoute(objectType: string, objectID: number) {
        const res = await getApprovalRouteForThisObject(objectType, objectID);
        updateDocRevApprovalRoute(res.data, [res.data.objectID]);
    }

    async function getManualApprovalRoute(objectType: string, objectID: number) {
        const res = await getApprovalRouteForThisObject(objectType, objectID);
        updateManualApprovalRoute(res.data, [res.data.objectID]);
    }

    async function getApprovalRouteForThisObject(objectType: string, objectID: number) {
        return axios.get('/api/doc-manager/approval-route-editor/get', {
            params: {
                objectType: objectType,
                objectID: objectID
            },
        });
    }

    function updateDocRevApprovalRoute(approvalRoute: any, objectIDs: number[]) {
        const newDocRevApprovalRoutes = { ...docRevApprovalRoutes };

        objectIDs.forEach(objectID => {
            newDocRevApprovalRoutes[objectID] = approvalRoute;
        });

        setDocRevApprovalRoutes(newDocRevApprovalRoutes);
    }

    function updateManualApprovalRoute(approvalRoute: any, objectIDs: number[]) {
        const newManualApprovalRoutes = { ...manualApprovalRoutes };

        objectIDs.forEach(objectID => {
            newManualApprovalRoutes[objectID] = approvalRoute;
        });

        setManualApprovalRoutes(newManualApprovalRoutes);
    }

    function editSelectedDocRevs() {
        setSelectedObjectType('docrevid');
        setSelectedObjectIDs(selectedDocRevIDs);
        setSelectedObjectTitle('Editing multiple document revisions');
        setDialogOpen(true);
    }

    function editSelectedManuals() {
        setSelectedObjectType('manualid');
        setSelectedObjectIDs(selectedManualIDs);
        setSelectedObjectTitle('Editing multiple manuals');
        setDialogOpen(true);
    }

    async function routeSelectedDocRevs() {
        if (!selectedDocRevIDs.length) return;

        setIsDocTableRouting(true);
        const res = await axios.post(
            '/api/approval-routing/route-objects-for-approval/',
            {
                objectType: 'docrevid',
                objectIDs: selectedDocRevIDs
            }
        );
        removeTheseDocRevsFromPendingApproval(res.data.successfullyRoutedObjectIDs);
        setIsDocTableRouting(false);

        if (res.data.committeesToEmail) {
            axios.post('/api/approval-routing/route-objects-for-approval/email-committees', {
                committeesToEmail: res.data.committeesToEmail,
                domain: `https://${window.location.host}`,
            })
            .catch((e) => {
                console.log(e);
            });
        }

        res.data.failedObjectsMessage && alert(res.data.failedObjectsMessage);
    }

    async function routeSelectedManuals() {
        if (!selectedManualIDs.length) return;

        setIsManualTableRouting(true);
        const res = await axios.post(
            '/api/approval-routing/route-objects-for-approval/',
            {
                objectType: 'manualid',
                objectIDs: selectedManualIDs
            }
        );
        removeTheseManualsFromPendingApproval(res.data.successfullyRoutedObjectIDs);
        setIsManualTableRouting(false);
        res.data.failedObjectsMessage && alert(res.data.failedObjectsMessage);
    }

    function removeTheseDocRevsFromPendingApproval(docRevsToRemove: number[]) {
        const newTableData = (docRevTableData as DocRevTableValues[]).filter(approval => {
            return !docRevsToRemove.includes(approval.DocRevID);
        });
        setDocRevTableData(newTableData);
        setSelectedDocRevRows([]);
        setSelectedDocRevIDs([]);
    }

    function removeTheseManualsFromPendingApproval(manualsToRemove: number[]) {
        const newTableData = (manualTableData as {ManualID: number}[]).filter(manual => {
            return !manualsToRemove.includes(manual.ManualID);
        });
        setManualTableData(newTableData);
        setSelectedManualRows([]);
        setSelectedManualIDs([]);
    }

    if (loadingStatus === LoadingStatuses.LOADING) {
        return <LoadingIndicator/>;
    }

    return (
        <div className={classes.mainContainer}>

            <div className={classes.tablesContainer}>

                <ApprovalRoutingTableWrapper
                    selectedList={props.selectedList}
                    objectType={'docrevid'}

                    tableTitle={'Documents'}
                    tableData={docRevTableData}
                    selectedRows={selectedDocRevRows}
                    onRefresh={() => loadDocRevs(props.selectedList)}
                    isRefreshing={isDocTableRefreshing}
                    isRouting={isDocTableRouting}

                    approvalRoutes={docRevApprovalRoutes}
                    getApprovalRoute={(objectType, objectID) => getDocRevApprovalRoute(objectType, objectID)}

                    setSelectedRows={rows => setSelectedDocRevRows(rows)}
                    setSelectedIDs={IDs => setSelectedDocRevIDs(IDs)}
                    editSelectedItems={() => editSelectedDocRevs()}
                    routeSelectedItems={() => routeSelectedDocRevs()}

                    setSelectedObjectIDs={objectIDs => setSelectedObjectIDs(objectIDs)}
                    setSelectedObjectType={() => setSelectedObjectType('docrevid')}
                    setSelectedObjectTitle={objectTitle => setSelectedObjectTitle(objectTitle)}
                    setDialogOpen={dialogOpen => setDialogOpen(dialogOpen)}
                />

                <ApprovalRoutingTableWrapper
                    selectedList={props.selectedList}
                    objectType={'manualid'}

                    tableTitle={'Manuals'}
                    tableData={manualTableData}
                    selectedRows={selectedManualRows}
                    onRefresh={() => loadManuals(props.selectedList)}
                    isRefreshing={isManualTableRefreshing}
                    isRouting={isManualTableRouting}

                    approvalRoutes={manualApprovalRoutes}
                    getApprovalRoute={(objectType, objectID) => getManualApprovalRoute(objectType, objectID)}

                    setSelectedRows={rows => setSelectedManualRows(rows)}
                    setSelectedIDs={IDs => setSelectedManualIDs(IDs)}
                    editSelectedItems={() => editSelectedManuals()}
                    routeSelectedItems={() => routeSelectedManuals()}

                    setSelectedObjectIDs={objectIDs => setSelectedObjectIDs(objectIDs)}
                    setSelectedObjectType={() => setSelectedObjectType('manualid')}
                    setSelectedObjectTitle={objectTitle => setSelectedObjectTitle(objectTitle)}
                    setDialogOpen={dialogOpen => setDialogOpen(dialogOpen)}
                />

            </div>

            <div className={`${classes.sliderBody} ${dialogOpen ? classes.sliderBodyVisible : ''}`}>
                <div className={classes.sliderHeader}>
                    <h4 className={classes.sliderHeaderText}>EDIT APPROVAL ROUTE</h4>
                    <p>"{selectedObjectTitle}"</p>
                    <IconButton
                        onClick={() => setDialogOpen(false)}
                        className={classes.sliderHeaderIconButton}
                    >
                        <Clear />
                    </IconButton>
                </div>

                {dialogOpen && selectedObjectType && selectedObjectIDs && selectedObjectIDs.length &&
                    <ApprovalRouteEditor
                        objectType={selectedObjectType}
                        objectIDs={selectedObjectIDs}
                        sendApprovalRouteToParent={(objectType, objectIDs, approvalRoute) => {
                            if (objectType === 'docrevid') {
                                updateDocRevApprovalRoute(approvalRoute, objectIDs);
                            }
                            else if (objectType === 'manualid') {
                                updateManualApprovalRoute(approvalRoute, objectIDs);
                            }
                        }}
                    />
                }
            </div>

            {props.selectedList === 'pending' &&
                <SaveBar
                    onSave={() => {
                        // just doing this sequentially to avoid weird state update sequences
                        routeSelectedDocRevs().then(() => routeSelectedManuals().then());
                    }}
                    saveIcon={<Send />}
                    isSaveDisabled={!selectedDocRevIDs.length && !selectedManualIDs.length}
                    // saveButtonText={'Route All Selected Items'}
                />
            }
        </div>
    );
}
