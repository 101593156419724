import React, { useState } from 'react';
import axios from 'axios';
import { PERSON } from '../../../entities/master/PERSON';
import { buildDocURL } from '../../../_shared/utils/docManagerFunctions';
import { FileInput } from '../../_shared/FileInput';
import { Button, MenuItem, Select, makeStyles } from '@material-ui/core';
import LucidocColors from '../../../constants/LucidocColors';
import { ArrowForward } from '@material-ui/icons';
import SaveBar from '../../_shared/SaveBar/SaveBar';
import { LoadingIndicator } from '../../_shared/LoadingIndicator';
import { LoadingStatuses } from '../../../utils/LoadingStatuses';
import DataTable from '../../_shared/DataTable/DataTable';

const useStyles = makeStyles({
    upperLevel: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '1rem 0 2rem 0',
    },
    buttonContainerDiv: {
        width: '240px',
        '&:last-child': {
            width: '360px' // because the "No file selected" can turn into a long file name and it'll try to be multi-line if the div is too small to fit, which makes the next elements move up or down on file upload
        }
    },
    arrowForward: {
        color: LucidocColors.green,
        margin: '0 2rem',
    },
    optionalColumnsLine: {
        fontSize: '.8rem',
        color: LucidocColors.darkGray
    }
});

export default function UpdateGuids() {
    const classes = useStyles();

    const [loadingStatus, setLoadingStatus] = useState(LoadingStatuses.MOUNTING);

    const [uploadedFile, setUploadedFile] = useState<File | null>(null);

    const [processedUsers, setProcessedUsers] = useState<PERSON[] | undefined>(undefined);

    const [GUIDsAlreadyTaken, setGUIDsAlreadyTaken] = useState<string[] | undefined>(undefined);
    const [GUIDsNotFound, setGUIDsNotFound] = useState<string[] | undefined>(undefined);

    const [source, setSource] = useState('GUID');

    function handleFileUpload(files: FileList | null) {
        if (!files || !files[0])
            return;

        setProcessedUsers(undefined);
        setGUIDsAlreadyTaken(undefined);
        setGUIDsNotFound(undefined);
        setUploadedFile(files[0]);
    }

    async function sendFileToServerToUpdateGuids() {
        if (!uploadedFile) return;

        setLoadingStatus(LoadingStatuses.LOADING);
        setProcessedUsers(undefined);
        setGUIDsAlreadyTaken(undefined);
        setGUIDsNotFound(undefined);

        const formData = new FormData();
        formData.append('file', uploadedFile);
        formData.append('source', source);

        const res = await axios.post(
            '/api/administration/users/update-guids/upload-file',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );

        if (res.data.success) {
            setProcessedUsers(res.data.processedUsers || []);
            setGUIDsAlreadyTaken(res.data.GUIDsAlreadyTaken || []);
            setGUIDsNotFound(res.data.GUIDsNotFound || []);
        }
        else {
            alert('Something went wrong.');
        }

        setUploadedFile(null);
        setLoadingStatus(LoadingStatuses.READY);
    }

    function convertUsersKeysToReadableKeys(people: PERSON[]) {
        return people.map(person => ({
            'UserID': person.USERID,
            'Last Name': person.LASTNAME,
            'First Name': person.FIRSTNAME,
            'Target GUID': person.GUID,
        }));
    }


    const guidUpdateFormURL = buildDocURL(
        'lcorp',
        19213,
        undefined,
        true,
        true
    );

    return (
        <>
            <div className={classes.upperLevel}>
                <div className={classes.buttonContainerDiv}>
                    <p>First, ensure you are logged into your target organization.</p>
                    <p>Then download this Excel template:</p>
                    <Button variant={'contained'}>
                        <a href={guidUpdateFormURL}
                            style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}
                        >
                            Download File
                        </a>
                    </Button>
                </div>

                <ArrowForward className={classes.arrowForward} />

                <div className={classes.buttonContainerDiv}>
                    <p>Fill in Source GUID/USERID and TargetGUID columns</p>
                    <p className={classes.optionalColumnsLine}>(Other columns are optional, to provide legibility)</p>
                    <Select
                        labelId="source-label"
                        id="source"
                        value={source}
                        onChange={(e) => setSource(e.target.value as string)}
                    >
                        <MenuItem value={'GUID'}>GUID</MenuItem>
                        <MenuItem value={'USERID'}>USERID</MenuItem>
                    </Select>
                </div>

                <ArrowForward className={classes.arrowForward} />

                <div className={classes.buttonContainerDiv}>
                    <p>Upload the completed file:</p>
                    <FileInput
                        fileName={uploadedFile?.name}
                        handleFileUpload={(files) => handleFileUpload(files)}
                        uniqueID={'file-input'}
                    />
                </div>
            </div>

            {loadingStatus === LoadingStatuses.LOADING &&
                <LoadingIndicator />
            }

            {processedUsers &&
                <DataTable
                    muiDataTableProps={{
                        title: 'Users Processed Successfully',
                        columns: [
                            { name: 'UserID' },
                            { name: 'Last Name' },
                            { name: 'First Name' },
                            { name: 'Target GUID' },
                        ],
                        data: convertUsersKeysToReadableKeys(processedUsers)
                    }}
                />
            }

            {GUIDsAlreadyTaken &&
                <DataTable
                    muiDataTableProps={{
                        title: 'Target GUIDs already in use',
                        columns: [
                            { name: 'GUID' }
                        ],
                        data: (GUIDsAlreadyTaken || []).map(guid => ({ GUID: guid }))
                    }}
                />
            }

            {GUIDsNotFound &&
                <DataTable
                    muiDataTableProps={{
                        title: 'Source GUIDs not found',
                        columns: [
                            { name: 'GUID' }
                        ],
                        data: (GUIDsNotFound || []).map(guid => ({ GUID: guid }))
                    }}
                />
            }

            <SaveBar
                onSave={() => sendFileToServerToUpdateGuids()}
                // saveButtonText={'Update GUIDs'}
                isSaveDisabled={!uploadedFile}
            />
        </>
    );
}
