import React, { useState } from 'react';
import Subheader from '../../_shared/Subheader/Subheader';
import SubheaderItem from '../../_shared/Subheader/SubheaderItem';
import ContractRequest from './ContractRequest';
import ViewRequestedContracts from './ViewRequestedContracts';
import { TemporaryFavoritesStar } from '../../_shared/TemporaryFavoritesStar/TemporaryFavoritesStar';

export default function ContractRequestOverview() {
    enum Tabs {
        SubmitNew = 'Submit New',
        ViewRequested = 'View Requested'
    }

    const [ selectedTab, setSelectedTab ] = useState<string>(Tabs.SubmitNew);

    return (
        <div>
            <Subheader
                value={selectedTab}
                onChange={value => setSelectedTab(value)}
                lastElement={
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', paddingRight: '1rem' }}>
                        <a href="/cgi/help.pl?ref=tuhelp:36562" target="_blank">Help</a>
                        <TemporaryFavoritesStar targetPathName={'contracts/request?noheader=true'} />
                    </div>
                }
            >
                <SubheaderItem label={Tabs.SubmitNew} value={Tabs.SubmitNew} />
                <SubheaderItem label={Tabs.ViewRequested} value={Tabs.ViewRequested} />
            </Subheader>

            {selectedTab === Tabs.SubmitNew &&
                <ContractRequest />
            }

            {selectedTab === Tabs.ViewRequested &&
                <ViewRequestedContracts />
            }
        </div>
    );
}
