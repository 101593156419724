import {
    Column,
    Entity,
    JoinColumn,
    ManyToOne,
    PrimaryColumn,
} from 'typeorm';
import { DOCUMENTREVISION,  } from './DOCUMENTREVISION';
import { INFORMATION_HIERARCHY,  } from './INFORMATION_HIERARCHY';
import { SchemaEntityManager } from '../SchemaEntityManager';

function createApprovalRouteTemplateEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('APPROVAL_ROUTE_TEMPLATE', { schema: schema })
    class APPROVAL_ROUTE_TEMPLATE {
        static SCHEMANAME = schema;
        @Column('number', {
            name: 'DOCREVID',
        })
        DOCREVID!: number | null;

        @ManyToOne(
            () => manager.getDocumentRevisionEntity(schema),
            (documentRevision) => documentRevision.APPROVAL_ROUTE_TEMPLATES
        )
        @JoinColumn({
            name: 'DOCREVID',
        })
        DOCUMENTREVISION!: DOCUMENTREVISION | null;

        @Column('number', {
            nullable: true,
            name: 'SAS_TEMPLATE_ID',
        })
        SAS_TEMPLATE_ID!: number | null;

        @Column('number', {
            nullable: true,
            name: 'CAS_TEMPLATE_ID',
        })
        CAS_TEMPLATE_ID!: number | null;

        @Column('number', {
            nullable: false,
            name: 'PHASE',
        })
        PHASE!: number;

        @Column('number', {
            name: 'MANUALID',
        })
        MANUALID!: number | null;

        @ManyToOne(
            () => manager.getInformationHierarchyEntity(schema),
            (informationHierarchy) => informationHierarchy.APPROVAL_ROUTE_TEMPLATES
        )
        @JoinColumn({
            name: 'MANUALID',
        })
        MANUAL!: INFORMATION_HIERARCHY | null;

        @PrimaryColumn('number', {
            nullable: false,
            name: 'APPROVAL_ROUTE_TEMPLATE_ID',
        })
        APPROVAL_ROUTE_TEMPLATE_ID!: number;
    }

    return APPROVAL_ROUTE_TEMPLATE;
}

export { createApprovalRouteTemplateEntity };
export type APPROVAL_ROUTE_TEMPLATE = InstanceType<ReturnType<typeof createApprovalRouteTemplateEntity>>;
