import React, { forwardRef, useEffect, useRef, useState }from 'react';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/lib/utils';
import { Badge } from './badge';
import { Popover, PopoverContent, PopoverTrigger } from './popover';
import {  ChevronDown, SearchIcon } from 'lucide-react';
import { Separator } from './separator';
import { Button } from './button';
import { Checkbox } from './checkbox';
import useUserSearch, { UserSearchPerson } from '@/hooks/useUserSearch';
import { PERSON, PersonStatuses, RightsCodeKey } from '@/entities/master/PERSON';
import useDebounce from '@/hooks/useDebounce';
import { Input } from './input';
import { constructFullName } from '@/components/UserPicker/utils/constructFullName';

const userPickerVariants = cva(
    'inline-flex flex-row items-center justify-between whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors disabled:pointer-events-none disabled:opacity-50 cursor-pointer has-[:focus]:border-primary',
    {
        variants: {
            variant: {
                default: 'border-2 border-border bg-background hover:bg-accent hover:text-accent-foreground',
            },
            size: {
                default: 'p-1.5',
            },
        },
        defaultVariants: {
            variant: 'default',
            size: 'default',
        },
    }
);

export interface BaseUserPickerProps extends React.HtmlHTMLAttributes<HTMLDivElement>, VariantProps<typeof userPickerVariants> {
    inputType: 'number' | 'person';
    rights: RightsCodeKey[];
    exemptUsers?: (PERSON | number)[];
    getMappedUsers?: boolean;
    placeholder?: string;
    disabled?: boolean;
    clearable?: boolean;
}

export interface SingleUserPickerProps extends BaseUserPickerProps {
    selectedUser: PERSON | number | undefined;
    setSelectedUser: (user: PERSON | number | undefined) => void;
}

export interface MultipleUserPickerProps extends BaseUserPickerProps {
    selectedUsers: (PERSON | number)[];
    setSelectedUsers: (users: (PERSON | number)[]) => void;
}

// Shared hook for fetching users and handling selection
const useUserPickerLogic = (
    selectedUsersOrUser: (PERSON | number)[] | (PERSON | number | undefined),
    rights: RightsCodeKey[],
    inputType: 'number' | 'person',
    exemptUsers?: (PERSON | number)[],
    getMappedUsers?: boolean
) => {
    const [filterText, setFilterText] = React.useState<string>('');
    const debouncedFilterText = useDebounce<string>(filterText, 300);

    let selectedUserIds: number[] = [];
    if (Array.isArray(selectedUsersOrUser)) {
        selectedUserIds = selectedUsersOrUser.map(u => {
            if (typeof u === 'number') {
                return u;
            } else {
                return u.USERID;
            }
        });
    } else if (selectedUsersOrUser !== undefined) {
        if (typeof selectedUsersOrUser === 'number') {
            selectedUserIds = [selectedUsersOrUser];
        } else {
            selectedUserIds = [selectedUsersOrUser.USERID];
        }
    }

    const exemptUserIds = exemptUsers?.map(u => {
        if (typeof u === 'number') {
            return u;
        } else {
            return u.USERID;
        }
    }) ?? [];

    const [users] = useUserSearch(
        debouncedFilterText,
        selectedUserIds,
        [PersonStatuses.ACTIVE],
        rights,
        undefined,
        exemptUserIds,
        getMappedUsers
    );

    const handleSelectUser = (user: UserSearchPerson) => {
        return inputType === 'number' ? user.person.USERID : user.person;
    };

    return { users, selectedUserIds, filterText, setFilterText, handleSelectUser };
};

interface UserPickerContentProps {
    users: UserSearchPerson[] | undefined;
    selectedUserIds: number[];
    filterText: string;
    setFilterText: (value: string) => void;
    handleUserSelect: (user: UserSearchPerson) => void;
    handleClearSelection: () => void;
    isMultiple: boolean;
    handleClose?: () => void;
    clearable: boolean;
}

// Condensed rendering for user picker content
const UserPickerContent = forwardRef<HTMLDivElement, UserPickerContentProps>(({
    users,
    selectedUserIds,
    filterText,
    setFilterText,
    handleUserSelect,
    handleClearSelection,
    isMultiple,
    handleClose,
    clearable,
}, ref) => {
    return (
        <PopoverContent ref={ref} align='start' className='w-full min-w-[300px] p-0'>
            <div className='text-sm w-full p-2 pb-0 flex flex-row items-center'>
                <SearchIcon className='text-secondary mr-2' size={18} />
                <Input
                    className='border-none w-full outline-none text-secondary'
                    placeholder='Search users...'
                    value={filterText}
                    onChange={(e) => setFilterText(e.target.value)}
                />
            </div>
            <Separator orientation='horizontal' className='my-2' />
            <div className='w-full flex flex-col'>
                {users && (
                    <div className='flex flex-col overflow-y-auto max-h-[275px] space-y-1'>
                        {users.map((user: UserSearchPerson) => {
                            const isSelected = selectedUserIds.includes(user.person.USERID);
                            return (
                                <Button
                                    key={user.person.USERID}
                                    variant='ghost'
                                    className={cn('justify-start', isSelected ? 'text-primary' : 'text-secondary')}
                                    onClick={
                                        () => {
                                            handleUserSelect(user);
                                            if (!isMultiple && handleClose) {
                                                handleClose();
                                            }
                                        }}
                                >
                                    {isMultiple && <Checkbox checked={isSelected} className='mr-2' />}
                                    <span className='text-sm font-medium'>
                                        {constructFullName(user.person, 'firstMiddleLast')}
                                    </span>
                                </Button>
                            );
                        })}
                    </div>
                )}
                {
                    clearable && <>
                        <Separator orientation='horizontal' className='mt-2' />
                        <Button onClick={handleClearSelection} variant={'ghost'}>Clear Selection</Button>
                    </>
                }
            </div>
        </PopoverContent>
    );
});

// Single user picker component
const SingleUserPicker = React.forwardRef<HTMLDivElement, SingleUserPickerProps>(
    ({ className, variant, size, rights, selectedUser, setSelectedUser, inputType, placeholder, disabled, ...props }, ref) => {
        const { users, filterText, setFilterText, handleSelectUser } = useUserPickerLogic(
            selectedUser,
            rights,
            inputType,
            props.exemptUsers,
            props.getMappedUsers
        );

        const getSelectedUser = () => {
            if (typeof selectedUser === 'number') {
                return users?.find(u => u.USERID === selectedUser)?.person;
            }
            return selectedUser;
        };

        const handleClearSelection = () => {
            setSelectedUser(undefined);
        };

        const triggerRef = useRef<HTMLButtonElement>(null);
        const contentRef = useRef<HTMLDivElement>(null);
        const [isOpen, setIsOpen] = useState(false);

        useEffect(() => {
            const handleClickOutside = (event: MouseEvent) => {
                if (
                        contentRef.current && 
                        !contentRef.current.contains(event.target as Node) &&
                        triggerRef.current &&
                        !triggerRef.current.contains(event.target as Node)
                    ) {
                    setIsOpen(false);
                }
            };

            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ref, triggerRef]);

        return (
            <Popover 
                open={isOpen}
            >
                <PopoverTrigger 
                    ref={triggerRef}
                    asChild
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <div className={cn(userPickerVariants({ variant, size, className }))} {...props}>
                        <p>{getSelectedUser() ? constructFullName(getSelectedUser() as PERSON, 'firstMiddleLast') : <span className='text-muted-foreground'>{ placeholder ?? 'Select User...' }</span>}</p>
                        <ChevronDown size={20} />
                    </div>
                </PopoverTrigger>
                {
                    !disabled &&
                        <UserPickerContent
                            ref={contentRef}
                            users={users}
                            selectedUserIds={getSelectedUser() ? [getSelectedUser()!.USERID!] : []}
                            filterText={filterText}
                            setFilterText={setFilterText}
                            handleUserSelect={
                                (user) => {
                                    setSelectedUser(handleSelectUser(user));
                                    setIsOpen(false);
                                }   
                            }
                            handleClearSelection={handleClearSelection}
                            isMultiple={false}
                            clearable={props.clearable ?? true}
                        />
                }
            </Popover>
        );
    }
);

SingleUserPicker.displayName = 'SingleUserPicker';

// Multiple user picker component
const MultipleUserPicker = React.forwardRef<HTMLDivElement, MultipleUserPickerProps>(
    ({ className, variant, size, rights, selectedUsers, setSelectedUsers, inputType, ...props }, ref) => {
        const { users, filterText, setFilterText, handleSelectUser } = useUserPickerLogic(
            selectedUsers,
            rights,
            inputType,
            props.exemptUsers,
            props.getMappedUsers
        );

        const handleUserSelect = (user: UserSearchPerson) => {
            const isSelected = selectedUsers.some((u) =>
                typeof u === 'number' ? u === user.person.USERID : u.USERID === user.person.USERID
            );
            const newSelectedUsers = isSelected
                ? selectedUsers.filter((u) =>
                    typeof u === 'number'
                        ? u !== user.person.USERID
                        : u.USERID !== user.person.USERID
                )
                : [...selectedUsers, handleSelectUser(user)];
            setSelectedUsers(newSelectedUsers);
        };

        const handleClearSelection = () => {
            setSelectedUsers([]);
        };

        return (
            <Popover modal={true}>
                <PopoverTrigger asChild>
                    <div ref={ref} className={cn(userPickerVariants({ variant, size, className }), 'flex-col items-start')} {...props}>
                        {selectedUsers.length === 0 && <p>{props.placeholder ?? 'Pick Users'}</p>}
                        {selectedUsers.length > 0 && (
                            <div className='flex flex-row flex-wrap mt-1'>
                                {selectedUsers.map((user) => {
                                    if (typeof user === 'number') {
                                        const person = users?.find(u => u.USERID === user)?.person;
                                        if (!person) return null;
                                        user = person;
                                    }
                                    const fullName = constructFullName(user, 'firstMiddleLast');
                                    return (
                                        <Badge key={typeof user === 'number' ? user : user.USERID} className='mr-2 mb-2'>
                                            {fullName}
                                        </Badge>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </PopoverTrigger>
                <UserPickerContent
                    users={users}
                    selectedUserIds={selectedUsers.map((u) => (typeof u === 'number' ? u : u.USERID))}
                    filterText={filterText}
                    setFilterText={setFilterText}
                    handleUserSelect={handleUserSelect}
                    handleClearSelection={handleClearSelection}
                    isMultiple={true}
                    clearable={props.clearable ?? true}
                />
            </Popover>
        );
    }
);

MultipleUserPicker.displayName = 'MultipleUserPicker';

export { SingleUserPicker, MultipleUserPicker, userPickerVariants };
