import {
    Column,
    Entity,
    JoinColumn,
    ManyToOne,
    OneToMany,
    OneToOne,
    PrimaryColumn,
} from 'typeorm';

import { PERSON,  } from '../master/PERSON';
import { DOCUMENTREVISION,  } from './DOCUMENTREVISION';
import { ACL,  } from './ACL';
import { ROUTING_CONFIGURATION,  } from './ROUTING_CONFIGURATION';
import { INFORMATION_HIERARCHY,  } from './INFORMATION_HIERARCHY';
import { INFORMATION_INDEX,  } from './INFORMATION_INDEX';
import { DOC_REQUEST,  } from './DOC_REQUEST';
import { nullableStringToBooleanTransformer, numberToBooleanTransformer } from '../_helperFunctions/transformers';
import { SchemaEntityManager } from '../SchemaEntityManager';
import { COMMITTEE } from './COMMITTEE';
import { DOCUMENT_LIMITS } from '../Limits';

// Factory function to create DOCUMENT entity with dynamic schema
function createDocumentEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('DOCUMENT', { schema: schema })
    class DOCUMENT {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'DOCID'
        })
        DOCID!: number;

        @Column('varchar2', {
            nullable: false,
            length: DOCUMENT_LIMITS.TITLE_LENGTH,
            name: 'TITLE'
        })
        TITLE!: string;

        @Column('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'INFORMATION_HIERARCHY_ID'
        })
        INFORMATION_HIERARCHY_ID!: number;

        @OneToOne(
            () => manager.getInformationHierarchyEntity(schema),
            (informationHierarchy) => informationHierarchy.DOCUMENT
        )
        @JoinColumn({
            name: 'INFORMATION_HIERARCHY_ID'
        })
        INFORMATION_HIERARCHY!: INFORMATION_HIERARCHY | null;

        @Column('varchar2', {
            nullable: true,
            length: DOCUMENT_LIMITS.SOURCE_REFERENCE_LENGTH,
            name: 'SOURCE_REFERENCE'
        })
        SOURCE_REFERENCE!: string | null;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'PARENTDOCID'
        })
        PARENTDOCID!: number | null;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'DOCOWNER_ID'
        })
        DOCOWNER_ID!: number | null;

        @ManyToOne(
            () => manager.getPersonEntity(schema), 
            (person) => person.DOCUMENTS
        )
        @JoinColumn({
            name: 'DOCOWNER_ID'
        })
        DOCOWNER?: PERSON | null;

        @Column('number', {
            nullable: true,
            precision: 3,
            scale: 0,
            name: 'ACCESSLEVEL'
        })
        ACCESSLEVEL!: number | null;

        @Column('char', {
            nullable: true,
            name: 'VIEWCONTROL',
            transformer: nullableStringToBooleanTransformer
        })
        VIEWCONTROL!: boolean;

        @Column('char', {
            nullable: true,
            name: 'ISPUBLIC',
            transformer: nullableStringToBooleanTransformer
        })
        ISPUBLIC!: boolean;

        @Column('char', {
            nullable: true,
            name: 'ISDEFAULT'
        })
        ISDEFAULT!: string | null;

        @Column('timestamp', {
            nullable: true,
            name: 'LOCK_DATE'
        })
        LOCK_DATE!: Date | null;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'LOCK_USERID'
        })
        LOCK_USERID!: number | null;

        @Column('number', {
            nullable: false,
            default: () => '0',
            precision: 1,
            scale: 0,
            transformer: numberToBooleanTransformer,
            name: 'IS_VISIBLE_TO_SUBSCRIBER'
        })
        IS_VISIBLE_TO_SUBSCRIBER!: boolean;

        @Column('timestamp', {
            nullable: true,
            name: 'GIVEN_ORIGINAL_EFFECTIVE_DATE'
        })
        GIVEN_ORIGINAL_EFFECTIVE_DATE!: Date | null;

        @OneToMany(
            () => manager.getDocumentRevisionEntity(schema),
            (documentRevision) => documentRevision.DOCUMENT
        )
        REVISIONS!: DOCUMENTREVISION[] | null;

        @OneToMany(
            () => manager.getRoutingConfigurationEntity(schema),
            (routingConfiguration) => routingConfiguration.DOCUMENT
        )
        ROUTING_CONFIGURATIONS!: ROUTING_CONFIGURATION[] | null;

        @OneToMany(
            () => manager.getAclEntity(schema),
            (acl) => acl.DOCUMENT
        )
        ACL!: ACL[] | null;

        @OneToMany(
            () => manager.getInformationIndexEntity(schema),
            (informationIndex) => informationIndex.DOCUMENT
        )
        INFORMATION_INDEXES!: INFORMATION_INDEX[] | null;

        @OneToOne(
            () => manager.getDocRequestEntity(schema),
            (docRequest) => docRequest.DOCUMENT
        )
        DOC_REQUEST!: DOC_REQUEST | null;

        @OneToOne(
            () => manager.getCommitteeEntity(schema),
            (committee) => committee.MINUTES_DEFAULT_CONTENT_DOC
        )
        COMMITTEE!: COMMITTEE | null;
    }

    return DOCUMENT;
}

export { createDocumentEntity };
export type DOCUMENT = InstanceType<ReturnType<typeof createDocumentEntity>>;