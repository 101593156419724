import React, { useContext, useEffect, useState } from 'react';
import { DOCUMENTREVISION, DisplayStatuses as DocRevDisplayStatuses } from '../../../entities/org/DOCUMENTREVISION';
import axios from 'axios';
import DataTable from '../../_shared/DataTable/DataTable';
import { MUIDataTableColumn, MUIDataTableProps } from 'mui-datatables';
import { LoadingIndicator } from '../../_shared/LoadingIndicator';
import { LoadingStatuses } from '../../../utils/LoadingStatuses';
import { buildDocURL, buildFeedbackURL, createSortableDate } from '../../../_shared/utils/docManagerFunctions';
import UserBundleContext from '../../../context/UserBundleContext';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { constructFullName } from '@/components/UserPicker/utils/constructFullName';

const useStyles = makeStyles({
    revisionNoteBox: {
        maxWidth: '400px',
        maxHeight: '100px',
        overflowY: 'scroll',
        marginRight: '-3rem' // this is really silly but I can't figure out why there was a huge marginRight out of nowhere here otherwise
    }
});

export default function ViewRequestedContracts() {
    const classes = useStyles();
    const context = useContext(UserBundleContext);

    const [ loadingStatus, setLoadingStatus ] = useState(LoadingStatuses.LOADING);
    const [ tableData, setTableData ] = useState<MUIDataTableProps['data']>([]);

    useEffect(() => {
        getRequestedContracts().then();
    }, []);

    async function getRequestedContracts() {
        const res = await axios.get('/api/doc-manager/contracts/request/view-requested-contracts');

        if (res.data.requestedContracts) {
            const formattedData = (res.data.requestedContracts as DOCUMENTREVISION[]).map(docrev => {
                return {
                    DocID: docrev.DOCID,
                    RevNo: docrev.REVNO,
                    Title: docrev.TITLE,
                    'Doc Owner': docrev.DOCREV_OWNER
                        ? constructFullName(docrev.DOCREV_OWNER, 'lastFirstMiddle')
                        : '',
                    Requested: docrev.DOCUMENT?.REVISIONS?.[0]?.CREATEDT
                        ? createSortableDate(new Date(docrev.DOCUMENT.REVISIONS[0].CREATEDT))
                        : '',
                    'Latest Rev Status': DocRevDisplayStatuses[docrev.DOCSTATUS_ID],
                    Feedback: 'View Feedback', // this will be made an <a> tag in customBodyRender
                    'Revision Note': docrev.REVISION_NOTE,
                };
            });

            setTableData(formattedData);
            setLoadingStatus(LoadingStatuses.READY);
        }
    }

    if (loadingStatus !== LoadingStatuses.READY) {
        return <LoadingIndicator />;
    }

    return (
        <div>
            <DataTable
                muiDataTableProps={{
                    title: 'Requested Contracts',
                    columns: tableData[0] && Object.keys(tableData[0]).map(key => {
                        const returnObj: MUIDataTableColumn = {
                            name: key,
                        };

                        if (   key === 'DocID'
                            || key === 'RevNo'
                        ) {
                            returnObj.options = {
                                display: 'false',
                                filter: false
                            };
                        }

                        if (key === 'Title') {
                            returnObj.options = {
                                customBodyRender: (value, tableMeta) => {
                                    const docURL = context.organization?.PREFIX
                                        ? buildDocURL(
                                            context.organization.PREFIX,
                                            tableMeta.rowData[0],
                                            parseInt(tableMeta.rowData[1])
                                        ) : '';

                                    return (
                                        <a href={docURL} target={'_blank'} rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                            {value}
                                        </a>
                                    );
                                }
                            };
                        }

                        if (key === 'Feedback') {
                            returnObj.options = {
                                filter: false,
                                customBodyRender: (value, tableMeta) => {
                                    const feedbackURL = context.organization?.PREFIX
                                        ? buildFeedbackURL(
                                            context.organization.PREFIX,
                                            tableMeta.rowData[0],
                                            parseInt(tableMeta.rowData[1])
                                        ) : '';

                                    return (
                                        <a href={feedbackURL} target={'_blank'} rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                            {value}
                                        </a>
                                    );
                                }
                            };
                        }

                        if (key === 'Revision Note') {
                            returnObj.options = {
                                filter: false,
                                customBodyRender: (value) => {
                                    return (
                                        <p className={classes.revisionNoteBox}>
                                            {value}
                                        </p>
                                    );
                                }
                            };
                        }

                        return returnObj;
                    }),
                    data: tableData
                }}
            />
        </div>
    );
}
