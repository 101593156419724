import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { LoadingIndicator } from '../../../../_shared/LoadingIndicator';
import { LoadingStatuses } from '../../../../../utils/LoadingStatuses';
import { MUIDataTableProps } from 'mui-datatables';
import { PERSON } from '../../../../../entities/master/PERSON';
import ProduceBacklogTable from '../ProduceBacklogTable';
import UserBundleContext from '../../../../../context/UserBundleContext';
import { constructFullName } from '@/components/UserPicker/utils/constructFullName';

interface SignerBacklogProps {
    onSelectSigner?: (userID: number) => void
    sendSignersToParent?: (signers: PERSON[]) => void
}

export default function SignerBacklog(props: SignerBacklogProps) {
    const [ loadingStatus, setLoadingStatus ] = useState(LoadingStatuses.MOUNTING);
    const [ tableData, setTableData ] = useState<MUIDataTableProps['data']>([]);

    const context = useContext(UserBundleContext);

    useEffect(() => {
        loadSignerBacklog().then();
    }, []);

    async function loadSignerBacklog() {
        setLoadingStatus(LoadingStatuses.LOADING);

        const res = await axios.get('/api/doc-manager/compliance-reports/get-signer-backlog');

        const isMultiOrg: boolean = !!(context.organization?.PUBLISHING_SCHEMA || context.organization?.SUBSCRIBER_SCHEMA);
        const isAnyoneFromOutsideTheOrg = (res.data.signers as PERSON[]).find(person => person.ORGANIZATION?.ORGANIZATIONID !== context.organization?.ORGANIZATIONID);

        const dataForTable = (res.data.signers as PERSON[]).map(person => {
            const row: {
                [key: string]: string | number
            } = {
                UserID: person.USERID,
                Name: constructFullName(person, 'lastFirstMiddle'),
            };

            if (isMultiOrg || isAnyoneFromOutsideTheOrg) {
                row['Home Organization'] = person.ORGANIZATION?.ORGANIZATIONID !== context.organization?.ORGANIZATIONID ? person.ORGANIZATION?.NAME || '' : '';
            }

            row['To Sign'] = res.data.signatureRequestsPerUser[person.USERID];

            return row;
        });

        setTableData(dataForTable);
        props.sendSignersToParent?.(res.data.signers);

        setLoadingStatus(LoadingStatuses.READY);
    }

    if (loadingStatus !== LoadingStatuses.READY) {
        return (
            <LoadingIndicator/>
        );
    }

    return (
        <ProduceBacklogTable
            title={'Signer'}
            tableData={tableData}
            titleOfHiddenIDColumn={'UserID'}
            onSelectItem={(signerID) => props.onSelectSigner?.(signerID)}
            onRefresh={() => loadSignerBacklog()}
        />
    );
}
