import React, { useContext, useEffect, useState } from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import LucidocColors from '../../../constants/LucidocColors';
import { GLOBAL_IDENTIFIER, GlobalIdentifierStatuses } from '../../../entities/org/GLOBAL_IDENTIFIER';
import { AddCircleOutline, Close, Remove, Replay } from '@material-ui/icons';
import SaveBar from '../../_shared/SaveBar/SaveBar';
import UserBundleContext from '../../../context/UserBundleContext';
import { LoadingIndicator } from '../../_shared/LoadingIndicator';
import { LoadingStatuses } from '../../../utils/LoadingStatuses';
import { TemporaryFavoritesStar } from '../../_shared/TemporaryFavoritesStar/TemporaryFavoritesStar';
import { setTitleInAdmin } from '../../../utils/setTitleInAdmin';
import { redirectToHomepage } from '../../../utils/savebarUtilities';
import setWindowTitle from '../../../utils/setWindowTitle';

const useStyles = makeStyles({
    contentArea: {
        padding: '1rem'
    },
    loadingIndicator: {
        width: '100%',
        padding: '2rem',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center'
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: '1rem',
        borderBottom: `1px solid ${LucidocColors.gray}`,
    },
    rowBlock: {
        flex: '1',
        marginTop: '0.5rem',
    },
    topRow: {
        '& p': {
            paddingRight: '1rem',
            marginTop: '0.75rem',
        }
    },
    nameInputDiv: {
        display: 'flex',
        alignItems: 'baseline',
    },
    availabilityDiv: {
        display: 'flex',
        '& .MuiTypography-body1': {
            fontFamily: 'Quattrocento Sans !important'
        },
        '& .Mui-checked': {
            color: LucidocColors.purple
        }
    },
    updateValuesDiv: {
        '& input': {
            margin: '.2rem .2rem .2rem 0'
        }
    },
    updateValuesInputRow: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '0.5rem',
    },
    purpleText: {
        fontWeight: 'bold',
        color: LucidocColors.purple,
    },
    greyText: {
        color: LucidocColors.gray,
    },
    hoverGreen: {
        '&:hover': {
            color: LucidocColors.green,
            cursor: 'pointer'
        }
    },
    hoverRed: {
        '&:hover': {
            color: LucidocColors.red,
            cursor: 'pointer'
        }
    },
    textInput: {
        border: `1px solid ${LucidocColors.gray}`,
    },
    addGlobaIdentifierButton: {
        marginTop: '0.5rem',
    }
});

export default function GlobalIdentifier() {
    const classes = useStyles();
    const context = useContext(UserBundleContext);
    const isMultiOrg: boolean = (
            !!context.organization
        && (   !!context.organization.PUBLISHING_SCHEMA
            || !!context.organization.SUBSCRIBER_SCHEMA)
    );
    const isPageDisabled: boolean = isMultiOrg && !!context.organization && !!context.organization.SUBSCRIBER_SCHEMA;

    const [ loadingStatus, setLoadingStatus ] = useState(LoadingStatuses.LOADING);

    // top row in the UI
    const [ globalIdentifierName, setGlobalIdentifierName ] = useState<string>('');
    const [ globalIdentifierAvailability, setGlobalIdentifierAvailability ] = useState<string>('');

    // bottom row in the UI
    const [ activeGlobalIdentifiers, setActiveGlobalIdentifiers ] = useState<GLOBAL_IDENTIFIER[]>([]);
    const [ newGlobalIdentifiers, setNewGlobalIdentifiers ] = useState<string[]>([]);
    const [ archivedGlobalIdentifiers, setArchivedGlobalIdentifiers ] = useState<GLOBAL_IDENTIFIER[]>([]);


    useEffect(() => {
        const newTitle = 'Manage Global Identifier';
        setWindowTitle(newTitle);
        setTitleInAdmin(newTitle);
        loadInitialData().then();
    }, []);

    async function loadInitialData() {
        setLoadingStatus(LoadingStatuses.LOADING);
        setNewGlobalIdentifiers([]);

        const res = await axios.get('/api/administration/organization/global-identifier/get', {});

        if (!res || !res.data || !res.data.organization || !res.data.activeGlobalIdentifiers || !res.data.archivedGlobalIdentifiers){
            alert('Error: Unable to load values.');
            return;
        }

        setGlobalIdentifierName(res.data.organization.GLOBAL_IDENTIFIER_NAME || '');
        setGlobalIdentifierAvailability(res.data.organization.GLOBAL_IDENTIFIER_AVAILABILITY|| '');
        setActiveGlobalIdentifiers(res.data.activeGlobalIdentifiers);
        setArchivedGlobalIdentifiers(res.data.archivedGlobalIdentifiers);
        setLoadingStatus(LoadingStatuses.READY);
    }

    if (loadingStatus !== LoadingStatuses.READY) {
        return (
            <LoadingIndicator
                loadingStatus={loadingStatus}
            />
        );
    }

    async function save() {
        setLoadingStatus(LoadingStatuses.SAVING);

        try {
            await axios.post('/api/administration/organization/global-identifier/save',
                {
                    globalIdentifierName,
                    globalIdentifierAvailability,
                    activeGlobalIdentifiers,
                    newGlobalIdentifiers,
                    archivedGlobalIdentifiers
                });
            await loadInitialData();
            setLoadingStatus(LoadingStatuses.READY);
        }
        catch(e) {
            alert('Error: Unable to save changes.');
            console.log(e);
        }
    }

    function cancel() {
        setLoadingStatus(LoadingStatuses.LOADING);
        setNewGlobalIdentifiers([]);
        loadInitialData().then();
    }

    function updateExistingGlobalIdentifier(newGlobalIdentifier: GLOBAL_IDENTIFIER, idx: number) {
        const newActiveGlobalIdentifiers = activeGlobalIdentifiers.slice();
        newActiveGlobalIdentifiers[idx] = newGlobalIdentifier;
        setActiveGlobalIdentifiers(newActiveGlobalIdentifiers);
    }

    function addNewGlobalIdentifier(newValue: string) {
        setNewGlobalIdentifiers(newGlobalIdentifiers.concat(newValue));
    }

    function updateNewGlobalIdentifier(newValue: string, idx: number) {
        const newNewGlobalIdentifiers = newGlobalIdentifiers.slice();
        newNewGlobalIdentifiers[idx] = newValue;
        setNewGlobalIdentifiers(newNewGlobalIdentifiers);
    }

    function removeNewGlobalIdentifier(idx: number) {
        const newNewGlobalIdentifiers = newGlobalIdentifiers.slice();
        newNewGlobalIdentifiers.splice(idx, 1);
        setNewGlobalIdentifiers(newNewGlobalIdentifiers);
    }

    function archiveGlobalIdentifier(gi: GLOBAL_IDENTIFIER, idx: number) {
        const newActiveGlobalIdentifiers = activeGlobalIdentifiers.slice();
        newActiveGlobalIdentifiers.splice(idx, 1);
        setActiveGlobalIdentifiers(newActiveGlobalIdentifiers);

        gi.STATUS = GlobalIdentifierStatuses.archived;
        setArchivedGlobalIdentifiers(archivedGlobalIdentifiers.concat(gi));
    }

    function reinstateGlobalIdentifier(gi: GLOBAL_IDENTIFIER, idx: number) {
        const newArchivedGlobalIdentifiers = archivedGlobalIdentifiers.slice();
        newArchivedGlobalIdentifiers.splice(idx, 1);
        setArchivedGlobalIdentifiers(newArchivedGlobalIdentifiers);

        gi.STATUS = GlobalIdentifierStatuses.active;
        setActiveGlobalIdentifiers(activeGlobalIdentifiers.concat(gi));
    }

    const activeGlobalIdentifiersMapped = activeGlobalIdentifiers.map((gi, idx) => (
        <div className={classes.updateValuesInputRow} key={idx}>
            <input
                type={'text'}
                value={gi.VALUE}
                disabled={isPageDisabled}
                onChange={(e) => updateExistingGlobalIdentifier(
                    {
                        ...gi,
                        VALUE: e.currentTarget.value
                    },
                    idx
                )}
            />
            {!isPageDisabled &&
                <Close
                    className={`${classes.hoverRed} ${classes.greyText}`}
                    onClick={() => archiveGlobalIdentifier(gi, idx)}
                />
            }
        </div>
    ));

    const newGlobalIdentifiersMapped = newGlobalIdentifiers.map((value, idx) => (
        <div className={classes.updateValuesInputRow} key={idx}>
            <input
                type={'text'}
                value={value}
                disabled={isPageDisabled}
                onChange={(e) => updateNewGlobalIdentifier(e.currentTarget.value, idx)}
                className={classes.textInput}
            />
            {!isPageDisabled &&
                <Remove
                    className={`${classes.hoverRed} ${classes.greyText}`}
                    onClick={() => removeNewGlobalIdentifier(idx)}
                />
            }
        </div>
    ));

    const archivedGlobalIdentifiersMapped = archivedGlobalIdentifiers.map((gi, idx) => (
        <div className={classes.updateValuesInputRow} key={idx}>
            <input
                type={'text'}
                value={gi.VALUE}
                disabled={true}
            />
            {!isPageDisabled &&
                <Replay
                    className={`${classes.greyText} ${classes.hoverGreen}`}
                    onClick={() => reinstateGlobalIdentifier(gi, idx)}
                />
            }
        </div>
    ));

    return (
        <div>
            <div style={{ float: 'right' }}>
                <TemporaryFavoritesStar targetPathName={'admin/admin.pl?repname=global_identifier'} />
            </div>

            <div className={classes.contentArea}>
                <h3>Global Identifier</h3>

                <div className={`${classes.row} ${classes.topRow}`}>
                    <span className={`${classes.rowBlock} ${classes.nameInputDiv}`}>
                        <p className={classes.purpleText}>Identifier Name:</p>
                        <input
                            type={'text'}
                            value={globalIdentifierName}
                            disabled={isPageDisabled}
                            onChange={(e) => setGlobalIdentifierName(e.currentTarget.value)}
                        />
                    </span>

                    <span className={`${classes.rowBlock} ${classes.availabilityDiv}`}>
                        <p className={classes.purpleText}>Enable identifier for:</p>
                        <RadioGroup
                            aria-label="global-identifier-availability"
                            name="global-identifier-availability"
                            value={globalIdentifierAvailability}
                            onChange={(e) => setGlobalIdentifierAvailability(e.currentTarget.value)}
                        >
                            {isMultiOrg &&
                                <FormControlLabel value="standard_and_model" control={<Radio />} label="For Corporate Standard and Model Documents only" disabled={isPageDisabled} />
                            }
                            <FormControlLabel value="all"                control={<Radio />} label="For all document types" disabled={isPageDisabled} />
                            <FormControlLabel value="none"               control={<Radio />} label="None (do not enable)" disabled={isPageDisabled} />
                        </RadioGroup>
                    </span>
                </div>

                <div className={`${classes.row}`}>
                    <span className={`${classes.rowBlock} ${classes.updateValuesDiv}`}>
                        <p className={classes.purpleText}>Values:</p>
                        {activeGlobalIdentifiersMapped}

                        {newGlobalIdentifiersMapped}

                        {!isPageDisabled &&
                            <AddCircleOutline
                                className={`${classes.greyText} ${classes.hoverGreen} ${classes.addGlobaIdentifierButton}`}
                                onClick={() => addNewGlobalIdentifier('')}
                            />
                        }
                    </span>

                    <span className={`${classes.rowBlock} ${classes.updateValuesDiv}`}>
                        <p className={classes.purpleText}>Archived Values:</p>
                        {archivedGlobalIdentifiersMapped}
                    </span>
                </div>

            </div>

            <SaveBar
                onSave={isPageDisabled ? undefined : () => save()}
                onCancel={() => cancel()}
                onClose={() => redirectToHomepage()}
            />
        </div>
    );
}
