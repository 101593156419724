import {
    Column,
    Entity,
    PrimaryGeneratedColumn,
} from 'typeorm';
import { SchemaEntityManager } from '../SchemaEntityManager';

// Define the factory function
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function createAuditLogEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('AUDIT_LOG', { schema })
    class AUDIT_LOG {
        static SCHEMANAME = schema;
        @PrimaryGeneratedColumn({
            type: 'number',
            name: 'AUDIT_ID'
        })
        AUDIT_ID!: number;
    
        @Column('number', {
            nullable: true,
            name: 'USERID'
        })
        USERID!: number | null;
    
        @Column('varchar2', {
            nullable: true,
            length: 4000,
            name: 'USER_NAME_AND_TITLE'
        })
        USER_NAME_AND_TITLE!: string | null;
    
        @Column('varchar2', {
            nullable: true,
            length: 4000,
            name: 'TABLE_NAME'
        })
        TABLE_NAME!: string | null;
    
        @Column('varchar2', {
            nullable: true,
            length: 4000,
            name: 'IDENTIFIER_NAME'
        })
        IDENTIFIER_NAME!: string | null;
    
        @Column('number', {
            nullable: true,
            name: 'IDENTIFIER_ID'
        })
        IDENTIFIER_ID!: number | null;
    
        @Column('varchar2', {
            nullable: true,
            length: 4000,
            name: 'ACTION'
        })
        ACTION!: string | null;
    
        @Column('varchar2', {
            nullable: true,
            length: 4000,
            name: 'ATTRIBUTE_NAME'
        })
        ATTRIBUTE_NAME!: string | null;
    
        @Column('varchar2', {
            nullable: true,
            length: 4000,
            name: 'ATTRIBUTE_VALUE'
        })
        ATTRIBUTE_VALUE!: string | null;
    
        @Column('varchar2', {
            nullable: true,
            length: 4000,
            name: 'JUSTIFICATION'
        })
        JUSTIFICATION!: string | null;
    
        @Column('timestamp', {
            nullable: false,
            default: () => 'SYSDATE',
            name: 'MODIFIED_DATE'
        })
        MODIFIED_DATE!: Date;
    }

    return AUDIT_LOG;
}

// Directly exporting a type with the same name as the class from the factory function is not possible,
// but you can export a type alias for the class outside the factory function scope.
// This approach, however, won't work directly as TypeScript does not evaluate the type in this manner for classes.

// A workaround to export a type with structural typing similar to the class
type AUDIT_LOG = InstanceType<ReturnType<typeof createAuditLogEntity>>;

export { createAuditLogEntity };
export type { AUDIT_LOG };  
