import { PencilOutlineIcon } from '@/ui/icons/outline/PencilOutline';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from '@/ui/ui/dialog';
import React, { useState } from 'react';
import { Homepage2DialogContentStyle } from '../../Homepage2';
import { useBrowserQuery } from '@/hooks/useBrowserQuery';
import { Textarea } from '@/ui/ui/textarea';
import { Button } from '@/ui/ui/button';
import { MessageBoardCard2Query } from '../MessageBoardCard2';
import axios from 'axios';
import { useIsUserLoggedInToAMultiOrg } from '@/hooks/useIsUserLoggedInToAMultiOrg';
import { Checkbox } from '@/ui/ui/checkbox';

interface PostMessageBoardDialogProps {
refetch: () => void;
}

interface PostMessageBoardDialogQuery extends MessageBoardCard2Query {
    postMessageBoardDialogOpen?: boolean;
    postMessageBoardDialogIsFromMultiOrg?: boolean;
}

export const PostMessageBoardDialog: React.FC<PostMessageBoardDialogProps> = ({ refetch }) => {

    const [query, setQuery] = useBrowserQuery<PostMessageBoardDialogQuery>();
    const [message, setMessage] = useState<string>('');
    const { isPublisherOrg: publisher } = useIsUserLoggedInToAMultiOrg();



    const sendMessage = async () => {
        if (message.length > 1024) return;

        const res = await axios.post('/api/homepage/card/message-board/add-message', {
            message,
            isFromMultiOrg: query.postMessageBoardDialogIsFromMultiOrg ?? false,
        });



        if (res.status === 200) {
            handleOpenChange(false);
            refetch();
        }
    };

    // This is extracted out of the jsx since I also need to call it from the sendMessage function.
    // The function also handles cleanup, so we don't want to duplicate that.
    const handleOpenChange = (open: boolean) => {
        let editMessageBoardDialogIsFromMultiOrg: boolean | undefined = query.postMessageBoardDialogIsFromMultiOrg ?? false;
        if (!open) {
            // This is a piece of state used in the dialog only.
            // We want to remove it once the dialog is closed.
            // It's a query simply to allow bookmarking the dialog and being able to preseve the state.
            editMessageBoardDialogIsFromMultiOrg = undefined;
        }

        setQuery({
            ...query,
            postMessageBoardDialogOpen: open || undefined,
            postMessageBoardDialogIsFromMultiOrg: editMessageBoardDialogIsFromMultiOrg,
        });
    };


     
    return (
        <Dialog
            open={query.postMessageBoardDialogOpen ?? false}
            onOpenChange={(open) => handleOpenChange(open)}
        >
            <DialogTrigger asChild>
                {/* You have to surround in div, since it fails to forward the ref of PencilOutlineIcon :/ */}
                <div>
                    <PencilOutlineIcon size={18} />
                </div>
            </DialogTrigger>
            <DialogContent className={Homepage2DialogContentStyle}>
                <DialogHeader>
                    <DialogTitle>
                        New Message
                    </DialogTitle>
                    <DialogDescription>
                        Post a message to the message board.
                    </DialogDescription>
                </DialogHeader>
                <div className='w-full flex flex-col h-full space-y-2'>
                    <div className='flex flex-row items-center w-full justify-between'>
                        <p>New Message:</p>
                        <p>Characters: {message.length}/1024</p>
                    </div>
                    <Textarea
                        className='w-full flex-grow'
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                    <div className='flex flex-row w-full items-center justify-between'>
                        <div className='flex items-center'>
                            {publisher && <>
                                <Checkbox
                                    checked={query.postMessageBoardDialogIsFromMultiOrg ?? false}
                                    onCheckedChange={() => {
                                        setQuery({
                                            ...query,
                                            postMessageBoardDialogIsFromMultiOrg:
                                                !(query.postMessageBoardDialogIsFromMultiOrg ?? false),
                                        });
                                    }}
                                />
                                <span className='ml-1'>Send to MultiOrg</span>
                            </>}
                        </div>
                        <Button
                            onClick={() => sendMessage()}
                        >
                            Post Message
                        </Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};