import React, { useContext } from 'react';
import { DashboardCard, DashboardCardContent, DashboardCardTitle, DashboardCardToolbar, DashboardCardToolbarItem } from '../../../../components/Homepage/components/DashboardCard';
import useSWR from 'swr';
import { MESSAGE_BOARD_ITEM } from '@/entities/org/MESSAGE_BOARD_ITEM';
import { EyeOutlineIcon } from '@/ui/icons/outline/EyeOutline';
import { PostMessageBoardDialog } from './messageboard2/PostMessageBoardDialog';
import { Homepage2Query } from '../Homepage2';
import { useBrowserQuery } from '@/hooks/useBrowserQuery';
import { EyeHiddenOutlineIcon } from '@/ui/icons/outline/EyeHiddenOutline';

import { EditMessageBoardDialog } from './messageboard2/EditMessageBoardDialog';
import { BuildingsHiddenOutlineIcon } from '@/ui/icons/outline/BuildingsHiddenOutline';
import { BuildingsOutlineIcon } from '@/ui/icons/outline/BuildingsOutline';
import { ToggleMessageBoardDialog } from './messageboard2/ToggleMessageBoardDialog';
import { SimpleTooltip } from '@/ui/ui/tooltip';
import { useIsUserLoggedInToAMultiOrg } from '@/hooks/useIsUserLoggedInToAMultiOrg';
import UserBundleContext from '@/context/UserBundleContext';
import hasRights from '@/_shared/utils/hasRights';
import { RightsCodeKey } from '@/entities/master/PERSON';
import { constructFullName } from '@/components/UserPicker/utils/constructFullName';

interface MessageBoardCard2Props {

}

export interface MessageBoardCard2Query extends Homepage2Query {
    showDeletedMessageBoardItems?: boolean;
    hideMultiOrgMessageBoardItems?: boolean;
    includeMultiOrg?: boolean;
}

export const MessageBoardCard2: React.FC<MessageBoardCard2Props> = () => {

    interface MessageData {
        latestMessages: MESSAGE_BOARD_ITEM[]
    }

    const [query, setQuery] = useBrowserQuery<MessageBoardCard2Query>();
    const { isSubscriberOrg } = useIsUserLoggedInToAMultiOrg();

    const userBundle = useContext(UserBundleContext);


    const { data: messagesData, isLoading, error, mutate } = useSWR<MessageData>({
        url: '/api/homepage/card/message-board/get-messages',
        params: {
            includeDeleted: query.showDeletedMessageBoardItems ?? false,
            includeMultiOrg: !(query.hideMultiOrgMessageBoardItems ?? false),
        }
    });

    const isOrgAdmin = hasRights(userBundle, RightsCodeKey.OrganizationAdministrator);


    return <DashboardCard disableMinWidth>
        <DashboardCardTitle>
            Message Board
        </DashboardCardTitle>
        <DashboardCardToolbar>
            {(isSubscriberOrg) && <DashboardCardToolbarItem
                onClick={() => setQuery({ ...query, hideMultiOrgMessageBoardItems: !query.hideMultiOrgMessageBoardItems || undefined })}
                tooltip={
                    query.hideMultiOrgMessageBoardItems ? 'Show Multi-Org' : 'Hide Multi-Org'
                }
            >
                {query.hideMultiOrgMessageBoardItems ? <BuildingsHiddenOutlineIcon size={18} /> : <BuildingsOutlineIcon size={18} />}
            </DashboardCardToolbarItem>}
            {isOrgAdmin && <DashboardCardToolbarItem
                onClick={() => setQuery({ ...query, showDeletedMessageBoardItems: !query.showDeletedMessageBoardItems || undefined })}
                tooltip={query.showDeletedMessageBoardItems ? 'Hide Deleted' : 'Show deleted'}
            >
                {query.showDeletedMessageBoardItems ? <EyeHiddenOutlineIcon size={18} /> : <EyeOutlineIcon size={18} />}
            </DashboardCardToolbarItem>}
            {isOrgAdmin && <DashboardCardToolbarItem tooltip={'New message'} >
                <PostMessageBoardDialog refetch={() => mutate()} />
            </DashboardCardToolbarItem>}
        </DashboardCardToolbar>
        <DashboardCardContent>
            {isLoading && <p>Loading...</p>}
            {error && <p>Error loading messages</p>}
            <div className='flex flex-col'>
                {messagesData && messagesData.latestMessages.map((message, idx) => {
                    return <div key={idx} className='mt-1 relative'>
                        <div className='flex flex-row space-x-1 items-end justify-start'>
                            <p className='text-secondary text-[10px]'>{formatDate(message.CREATED_DATE?.toString())} {idx === 0 && '(Latest)'}</p>
                            {message.USERID === userBundle?.user?.USERID && <EditMessageBoardDialog messageToEdit={message} refetch={() => mutate()} />}
                            {isOrgAdmin && <ToggleMessageBoardDialog messageToToggle={message} refetch={() => mutate()} />}
                        </div>
                        <p className='text-primary inline-flex items-center font-semibold text-sm'>
                            {(message.IS_FROM_MULTI_ORG && isSubscriberOrg) && <SimpleTooltip
                                tooltip={'Distributed from Corporate'}>
                                <span className='mr-1'><BuildingsOutlineIcon size={12} /></span>
                            </SimpleTooltip>}
                            {message.USER ? constructFullName(message.USER, 'firstMiddleLast') : ''}
                        </p>
                        <p className='text-secondary text-sm text-wrap'>{message.MESSAGE}</p>
                        {message.EDITED_DATE && <p className='text-secondary text-[10px]'>Edited: {formatDate(message.EDITED_DATE?.toString())}</p>}
                    </div>;
                })}
            </div>
        </DashboardCardContent>
    </DashboardCard>;
};

const formatDate = (dateString?: string) => {
    if (dateString === undefined) return 'CREATED_DATE is NULL';
    const date = new Date(dateString);
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const strTime = `${hours}:${minutes < 10 ? '0' + minutes : minutes} ${ampm}`;

    // Constructing MM/DD/YYYY format
    const month = date.getMonth() + 1; // Months are 0-based in JS
    const day = date.getDate();
    const year = date.getFullYear();

    return `${month}/${day}/${year} | ${strTime}`;
};
