import {
    Entity,
    PrimaryColumn
} from 'typeorm';
import { SchemaEntityManager } from '../SchemaEntityManager';

// Factory function to create COMMITTEE_MEMBER entity with dynamic schema
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function createCommitteeMemberEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('COMMITTEE_MEMBER', { schema })
    class COMMITTEE_MEMBER {
        static SCHEMANAME = schema;
        @PrimaryColumn(
            'number',
            {
                nullable: false,
                name: 'COMMITTEE_ID'
            }
        )
        COMMITTEE_ID!: number;

        @PrimaryColumn(
            'number',
            {
                nullable: false,
                precision: 10,
                scale: 0,
                name: 'USERID'
            }
        )
        USERID!: number;
    }

    return COMMITTEE_MEMBER;
}

// Export the factory function
export { createCommitteeMemberEntity };

// Define and export the type for instances created by the factory function
export type COMMITTEE_MEMBER = InstanceType<ReturnType<typeof createCommitteeMemberEntity>>;
