import React, { useState, useEffect } from 'react';

import ReactSelect from 'react-select';

import { rightsCodes, PersonStatuses as PersonStatuses } from '../../../../entities/master/PERSON';
import useUserSearch from '../../../../hooks/useUserSearch';
import { constructFullName } from '@/components/UserPicker/utils/constructFullName';

interface ISelectUsersProps {
    userIds: number[];
    onChange: (userIds: number[]) => void;
    placeholder?: string;
    rights?: (keyof typeof rightsCodes)[];
    statuses?: PersonStatuses[],
    exemptUserIds?: number[]; // user ids you do not want to display as options
    disabled?: boolean;
    getMappedUsers?: boolean;
}

const SelectUsers: React.FC<ISelectUsersProps> = (props: ISelectUsersProps) => {

    const [input, setInput] = useState('');
    const [users] = useUserSearch(
        input,
        props.userIds,
        props.statuses,
        props.rights,
        undefined,
        props.exemptUserIds,
        props.getMappedUsers
    );

    useEffect(() => {
        if (
            props.exemptUserIds &&
            props.userIds &&
            !props.exemptUserIds.every(exemptUserId => !props.userIds.includes(exemptUserId))
        ) {
            props.onChange(props.userIds.filter(userId => !(props.exemptUserIds || []).includes(userId)));
        }
    }, [props.exemptUserIds]);

    const options = (users || [])
        .map(user => ({
            label: constructFullName(user, 'firstMiddleLast'),
            value: user.USERID
        }))
        .filter(user => !(props.exemptUserIds || []).includes(user.value));

    return <ReactSelect
        isDisabled={props.disabled}
        onInputChange={value => setInput(value)}
        isSearchable
        isMulti
        onChange={
            (selected) => {
                if (selected) {
                    props.onChange(
                        (selected as { label: string; value: number; }[])
                            .map(option => option.value)
                    );
                }
            }
        }
        value={options.filter(o => props.userIds.includes(o.value))}
        options={options}
        placeholder={props.placeholder}
        styles={{
            control: (provided: object) => ({
                ...provided,
                width: '28rem'
            })
        }}
        isClearable
    />;

};

export default SelectUsers;
