import {
    Column,
    Entity,
    OneToMany,
    OneToOne,
    PrimaryColumn,
} from 'typeorm';
import { FIELDTEMPLATE,  } from './FIELDTEMPLATE';
import { THESAURUS,  } from './THESAURUS';
import { SchemaEntityManager } from '../SchemaEntityManager';

export enum FieldTypes {
    text     = '0',
    number   = '1',
    date     = '2',
    yesNoNA  = '3',
    textArea = '4',
    values   = '5', // <-- This is also called "Thesaurus" in Field.pm
    user     = '6',
    category = '7', // <-- AKA department
    manual   = '8'
}

// Define the factory function
function createFieldEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('FIELD', { schema: schema })
    class FIELD {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'FIELDID'
        })
        FIELDID!: number;

        @Column('varchar2', {
            nullable: false,
            length: 10,
            name: 'FIELDTYPEID'
        })
        FIELDTYPEID!: string;

        @Column('varchar2', {
            nullable: false,
            length: 32,
            name: 'NAME'
        })
        NAME!: string;

        @Column('varchar2', {
            nullable: false,
            length: 32,
            name: 'DISPLAY'
        })
        DISPLAY!: string;

        @Column('varchar2', {
            nullable: true,
            length: 400,
            name: 'DESCRIPTION'
        })
        DESCRIPTION!: string | null;

        @Column('char', {
            nullable: true,
            name: 'APPENDABLE'
        })
        APPENDABLE!: string | null;

        @Column('char', {
            nullable: true,
            name: 'REPEATABLE'
        })
        REPEATABLE!: string | null;

        @Column('char', {
            nullable: true,
            name: 'EDITABLE'
        })
        EDITABLE!: string | null;

        @OneToOne(
            () => manager.getFieldTemplateEntity(schema), 
            (fieldTemplate) => fieldTemplate.FIELD
        )
        FIELDTEMPLATE!: FIELDTEMPLATE | null;

        @OneToMany(
            () => manager.getThesaurusEntity(schema), 
            (thesaurus) => thesaurus.FIELD
        )
        THESAURUS_VALUES!: THESAURUS[] | null;
    }

    return FIELD;
}

// Export the factory function
export { createFieldEntity };

// Export the type representing an instance of the class generated by the factory function
export type FIELD = InstanceType<ReturnType<typeof createFieldEntity>>;
