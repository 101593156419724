import {
    Column,
    Entity,
    JoinColumn,
    OneToOne,
    PrimaryColumn
} from 'typeorm';
import { ORGANIZATION } from './ORGANIZATION';
import { numberToBooleanTransformer } from '../_helperFunctions/transformers';
import { SchemaEntityManager } from '../SchemaEntityManager';

export enum SAMLTypes {
    Azure = 'Azure',
    OKTA = 'OKTA'
}

/**
 * To completely create a link, you must create one of these records, AND ALSO a record
 *  in the DOCUMENTREVISION_LINK table (stored in each org schema, not master).
 * 
 * @param schema - This schema is the organization schema. SAML_PROFILE has a decorator specifying schema: MASTER, but still has relations to a parameter specified organization schema
 * 
 * @returns 
 */


function createSamlProfileEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('SAML_PROFILE', { schema: 'MASTER' })
    class SAML_PROFILE {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'SAML_PROFILE_ID',
        })
        SAML_PROFILE_ID!: number | null;

        @Column('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'ORGANIZATIONID',
        })
        ORGANIZATIONID!: number | null;

        @OneToOne(
            () => manager.getOrganizationEntity(schema),
            (organization) => organization.SAML_PROFILE
        )
        @JoinColumn({
            name: 'ORGANIZATIONID',
        })
        ORGANIZATION!: ORGANIZATION | null;

        @Column('varchar2')
        PATH!: string | null;

        @Column('number', {
            transformer: numberToBooleanTransformer,
        })
        FORCE_AUTHN!: boolean | null;

        @Column('varchar2')
        ENTRY_POINT!: string | null;

        @Column('varchar2')
        ISSUER!: string | null;

        @Column('varchar2')
        SIGNATURE_ALGORITHM!: string | null;

        @Column('varchar2')
        PRIVATE_KEY!: string | null;

        @Column('varchar2')
        MSAL_CLIENT_ID!: string | null;

        @Column('varchar2')
        MSAL_CLIENT_SECRET!: string | null;

        @Column('varchar2')
        MSAL_AUTHORITY!: string | null;

        // this is the one we generate with openssl, which then needs to be put INTO Azure
        @Column('varchar2')
        OUR_CERT!: string | null;

        // this is the one FROM Azure
        @Column('varchar2')
        THEIR_CERT!: string | null;

        @Column('varchar2')
        SAML_TYPE!: SAMLTypes | null;

        @Column('varchar2')
        AUDIENCE!: string | null;

        @Column('number', {
            transformer: numberToBooleanTransformer,
        })
        DISABLE_REQUESTED_AUTHN_CONTEXT!: boolean | null;
    }

    return SAML_PROFILE;
}

export { createSamlProfileEntity };
export type SAML_PROFILE = InstanceType<ReturnType<typeof createSamlProfileEntity>>;