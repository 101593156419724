function nullableThingToBooleanNumber(entityValue: string | number | null | undefined): number | null {
    if (entityValue === null || entityValue === undefined) {
        return null;
    }

    return entityValue ? 1 : 0;
}

// Used when the database value is either a '0' or a '1', but stored as strings, meaning
// you can't do a true/false check because the '0' will evaluate to true. This converts
// '0' to false, and '1' to true:
export const stringToBooleanTransformer = {
    from: (dbValue: string) => dbValue === '1',
    to: (entityValue: string) => entityValue ? '1' : '0'
};

// rarely needed, but PERSON_LOGIN_SESSION had a restraint where this had to be null
// sometimes, and not the string '0':
export const nullableStringToBooleanTransformer = {
    from: (dbValue: string) => dbValue === '1',
    to: (entityValue: string) => nullableThingToBooleanNumber(entityValue)?.toString() ?? null
};

export const numberToBooleanTransformer = {
    from: (dbValue: number) => dbValue === 1,
    to: (entityValue: number) => entityValue
};

export const nullableNumberToBooleanTransformer = {
    from: (dbValue: number) => dbValue === 1,
    to: (entityValue: number) => nullableThingToBooleanNumber(entityValue)
};
