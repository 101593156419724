import React from 'react';

interface PreferencesProps {
    size: number
}

export const PreferencesIcon: React.FC<PreferencesProps> = ({ size }) => {
    return (
        <svg width={size.toString()} height={size.toString()} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" rx="4" fill="hsl(var(--primary))" />
            <path d="M4.96973 4.96877C4.9866 4.42891 5.42911 4 5.96924 4H6.03076C6.57089 4 7.0134 4.4289 7.03027 4.96877L7.46777 18.9688C7.48541 19.533 7.03279 20 6.46826 20H5.53174C4.96721 20 4.51459 19.533 4.53223 18.9688L4.96973 4.96877Z" fill="hsl(var(--white))" />
            <path d="M10.5322 5.03124C10.5146 4.46699 10.9672 4 11.5317 4H12.4683C13.0328 4 13.4854 4.46699 13.4678 5.03123L13.0303 19.0312C13.0134 19.5711 12.5709 20 12.0308 20H11.9692C11.4291 20 10.9866 19.5711 10.9697 19.0312L10.5322 5.03124Z" fill="hsl(var(--white))" />
            <path d="M16.5664 5.06238C16.5304 4.48668 16.9876 4 17.5645 4H18.4355C19.0124 4 19.4696 4.48668 19.4336 5.06238L19 12L19.4336 18.9376C19.4696 19.5133 19.0124 20 18.4355 20H17.5645C16.9876 20 16.5304 19.5133 16.5664 18.9376L17 12L16.5664 5.06238Z" fill="hsl(var(--white))" />
            <path d="M14 16C14 17.1046 13.1046 18 12 18C10.8954 18 10 17.1046 10 16C10 14.8954 10.8954 14 12 14C13.1046 14 14 14.8954 14 16Z" fill="hsl(var(--white))" />
            <path d="M8 7.99998C8 9.10455 7.10457 9.99998 6 9.99998C4.89543 9.99998 4 9.10455 4 7.99998C4 6.89541 4.89543 5.99998 6 5.99998C7.10457 5.99998 8 6.89541 8 7.99998Z" fill="hsl(var(--white))" />
            <path d="M20 12C20 13.1046 19.1046 14 18 14C16.8954 14 16 13.1046 16 12C16 10.8954 16.8954 10 18 10C19.1046 10 20 10.8954 20 12Z" fill="hsl(var(--white))" />
        </svg>

    );
};