const DOCUMENT_LIMITS = {
    TITLE_LENGTH: 400,
    SOURCE_REFERENCE_LENGTH: 256,
};

const DOCUMENT_REVISION_LIMITS = {
    TITLE_LENGTH: DOCUMENT_LIMITS.TITLE_LENGTH,
    REVISION_NOTE_LENGTH: 32000,
    KEYWORDS_LENGTH: 4000,
};

export {
    DOCUMENT_LIMITS,
    DOCUMENT_REVISION_LIMITS,
};