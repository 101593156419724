import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import Subheader from '../../../_shared/Subheader/Subheader';
import { LoadingIndicator } from '../../../_shared/LoadingIndicator';
import SubheaderItem from '../../../_shared/Subheader/SubheaderItem';
import CommitteeInfoEditor from './components/CommitteeInfoEditor';
import CommitteeAgendas from './components/CommitteeAgendas';
import CommitteeArchivedAgendas from './components/CommitteeArchivedAgendas';
import UserBundleContext from '../../../../context/UserBundleContext';
import { COMMITTEE } from '../../../../entities/org/COMMITTEE';
import { LucidocModal } from '../../../_shared/LucidocModal/LucidocModal';
import hasRights from '../../../../_shared/utils/hasRights';
import { helpTopicDocIDs } from '../../../../_shared/constants/HelpSystemIDs';
import { buildHelpDocURL } from '../../../../_shared/utils/docManagerFunctions';
import { IconButton } from '@material-ui/core';
import { Help } from '@material-ui/icons';
import { LoadingStatuses } from '../../../../utils/LoadingStatuses';
import { useBrowserQuery } from '../../../../hooks/useBrowserQuery';
import { CommiteesPageQuery } from '../CommitteesPage/CommitteesPage';
import { redirectToHomepage } from '../../../../utils/savebarUtilities';
import { RightsCodeKey } from '@/entities/master/PERSON';
import setWindowTitle from '@/utils/setWindowTitle';
import { formatTitle, useDocumentTitle } from '@/hooks/useDocumentTitle';

export const onStorageUpdate = (setLoadingStatus: Dispatch<SetStateAction<LoadingStatuses>>) => (event: StorageEvent) => {
    const { key, newValue } = event;
    if (
        key === 'refresh' &&
        newValue === 'true'
    ) {
        setLoadingStatus(LoadingStatuses.LOADING);
        localStorage.setItem('refresh', 'false');
    }
};

interface ICommitteeEditorProps {
    open: boolean;
    onClose: () => void;
    committeeId: number;
}

const Tabs = {
    CommitteeInfo: 'Committee Info',
    Agendas: 'Agendas',
    ArchivedAgendas: 'Archived Agendas'
} as const;

type TabValue = typeof Tabs[keyof typeof Tabs];

interface CommitteeEditorQuery extends CommiteesPageQuery {
    committeeEditorTab?: TabValue;
}

const CommitteeEditor: React.FC<ICommitteeEditorProps> = (props: ICommitteeEditorProps) => {
    const context = useContext(UserBundleContext);

    const [loadingStatus, setLoadingStatus] = useState(LoadingStatuses.MOUNTING);

    const [query, setQuery] = useBrowserQuery<CommitteeEditorQuery>();
    const selectedTab = query.committeeEditorTab || Tabs.CommitteeInfo;

    const [committee, setCommittee] = useState<COMMITTEE>();

    useEffect(() => {
        const oldWindowTitle = document.title;
        return () => {
            setWindowTitle(oldWindowTitle);
        };
    }, []);

    useEffect(() => {
        if (loadingStatus == LoadingStatuses.MOUNTING) {
            loadInitialData().then();
        }
    }, [loadingStatus]);

    const loadInitialData = async () => {
        try {
            const res = await axios.get(`/api/committee/${props.committeeId}`);

            setCommittee(res.data.committee);
            setLoadingStatus(LoadingStatuses.READY);
        } catch (e) {
            redirectToHomepage();
        }
    };

    const isChairPersonOrSecretary = (
        context.user?.USERID === committee?.SECRETARY_USERID ||
        context.user?.USERID === committee?.CHAIRPERSON_USERID
    );
    const isCollaborator = (committee?.COLLABORATORS ?? []).some(person => person.USERID === context.user?.USERID);

    const isCommitteeAdmin = hasRights(
        context,
        [
            RightsCodeKey.DocumentAdministrator,
            RightsCodeKey.OrganizationAdministrator
        ]
    );

    const helpTopicId = isCommitteeAdmin ? helpTopicDocIDs.ADMIN_COMMITTEES : helpTopicDocIDs.DOCMGR2_EDITCOMMITTEE;


    const renderTabContent = () => {
        if (loadingStatus !== LoadingStatuses.READY) {
            return <LoadingIndicator />;
        }

        switch (selectedTab) {
            case Tabs.CommitteeInfo:
                return <CommitteeInfoEditor
                    committeeId={props.committeeId}
                    onClose={() => props.onClose()}
                />;
            case Tabs.Agendas:
                return <CommitteeAgendas committeeId={props.committeeId} />;
            case Tabs.ArchivedAgendas:
                return <CommitteeArchivedAgendas committeeId={props.committeeId} />;
            default:
                return <p>Committee Editor selected tab was not found.</p>;
        }
    };

    useDocumentTitle(formatTitle('Committees', committee?.NAME ?? ''));

    return <LucidocModal
        open={props.open}
        onClose={() => props.onClose()}
        title='Edit Committee'
        removePadding
        zIndex={0}
    >
        <div
            style={{
                height: '84vh',
                width: '90vw',
                display: 'grid',
                gridTemplateRows: '3rem calc(84vh - 3.5rem)',
                overflowX: 'hidden',
            }}
        >
            <Subheader
                value={selectedTab}
                onChange={
                    async value => {
                        setLoadingStatus(LoadingStatuses.LOADING);
                        setQuery({
                            ...query,
                            committeeEditorTab: value as TabValue
                        });
                        await new Promise(r => setTimeout(r, 5));
                        setLoadingStatus(LoadingStatuses.READY);
                    }
                }
                lastElement={
                    <a
                        href={buildHelpDocURL(helpTopicId)}
                        target={'_blank'}
                        rel={'noopener noreferrer'}
                    >
                        <IconButton>
                            <Help />
                        </IconButton>
                    </a>
                }
            >
                {
                    Object.keys(Tabs)
                        .map(
                            tab =>
                                <SubheaderItem
                                    key={Tabs[tab]}
                                    label={Tabs[tab]}
                                    value={Tabs[tab]}
                                    disabled={!(isChairPersonOrSecretary || isCollaborator) && tab !== 'CommitteeInfo'}
                                />
                        )
                }
            </Subheader>
            {renderTabContent()}
        </div>
    </LucidocModal>;

};

export default CommitteeEditor;
