import UserBundleContext from '@/context/UserBundleContext';
import { DocumentTypeShadowIcon } from '@/ui/icons/shadow/DocumentTypeShadow';
import { UnknownStatusShadowIcon } from '@/ui/icons/shadow/UnknownStatusShadow';
import { UserShadowIcon } from '@/ui/icons/shadow/UserShadow';
import { Button } from '@/ui/ui/button';
import { DatatableColumnFilter } from '@/ui/ui/datatable/column-filter';
import { DataTableToolbarProps } from '@/ui/ui/datatable/data-table';
import { DataTableFacetedFilter } from '@/ui/ui/datatable/datatable-faceted-filter';
import { DataTableViewOptions } from '@/ui/ui/datatable/view-options';
import { isOnlyReader } from '@/v2/pages/search/Search2';
import { Cross2Icon } from '@radix-ui/react-icons';
import { useContext } from 'react';
import { DocRevEntry } from './DocumentRevisionTable';
import { deepEqual } from '@/utils/deepCompare';

const DocumentRevisionTableToolbar: React.FC<DataTableToolbarProps<DocRevEntry>> = ({ table, datatableRef }) => {
    const isFiltered = table.getState().columnFilters.length > 0 && !deepEqual(table.initialState.columnFilters, table.getState().columnFilters);
    const userBundle = useContext(UserBundleContext);

    return (
        <div className="flex flex-col md:flex-row items-center md:justify-between my-4">
            <div className="flex flex-col w-full md:flex-row md:flex flex-1 md:items-center md:space-x-2 space-y-2 md:space-y-0">
                <DatatableColumnFilter
                    column={table.getColumn('title')}
                    placeholder="Filter Title..."
                />
                {(table.getColumn('status') && !isOnlyReader(userBundle)) && (
                    <DataTableFacetedFilter
                        column={table.getColumn('status')}
                        title="Status"
                        icon={<UnknownStatusShadowIcon size={16} />}
                    />
                )}
                {table.getColumn('owner') && (
                    <DataTableFacetedFilter
                        column={table.getColumn('owner')}
                        title="Owner"
                        icon={<UserShadowIcon size={16} />}
                    /> 
                )}
                {table.getColumn('docType') && (
                    <DataTableFacetedFilter
                        column={table.getColumn('docType')}
                        title="Document Type"
                        icon={<DocumentTypeShadowIcon size={16} />}
                    />
                )}
                {(isFiltered) && (
                    <Button
                        variant="ghost" 
                        onClick={() => table.resetColumnFilters()}
                        className="h-8 px-2 lg:px-3 items-center"
                    >
                        <Cross2Icon className="mr-2 h-4 w-4" />
                        Reset
                    </Button>
                )}
            </div>
            <DataTableViewOptions
                datatableRef={datatableRef}
                table={table}
            />
        </div>
    );
};

export default DocumentRevisionTableToolbar;
