import {
    Column,
    Entity,
    PrimaryColumn,
} from 'typeorm';
import { SchemaEntityManager } from '../SchemaEntityManager';

// Define the enum outside the factory function to ensure it's globally accessible
export enum NotificationWindowTypes {
    review = 'review',
    expiration = 'expiration'
}

// Define the factory function
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function createNotificationWindowEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('NOTIFICATION_WINDOW', { schema: schema })
    class NOTIFICATION_WINDOW {
        static SCHEMANAME = schema;
        @PrimaryColumn('varchar2', {
            nullable: false,
            length: 30,
            name: 'TYPE'
        })
        TYPE!: string;

        @PrimaryColumn('number', {
            nullable: false,
            name: 'WINDOW'
        })
        WINDOW!: number;

        @Column('char', {
            nullable: false,
            default: () => '0',
            name: 'DAILY_SUMMARY'
        })
        DAILY_SUMMARY!: string;

        @Column('char', {
            nullable: false,
            default: () => '0',
            name: 'DAILY_SUMMARY_COLLABORATOR'
        })
        DAILY_SUMMARY_COLLABORATOR!: string;

        static Types = NotificationWindowTypes;
    }

    return NOTIFICATION_WINDOW;
}

// Export the factory function
export { createNotificationWindowEntity };

// Export the type representing an instance of the class generated by the factory function
export type NOTIFICATION_WINDOW = InstanceType<ReturnType<typeof createNotificationWindowEntity>>;
