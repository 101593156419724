import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import UserBundleContext from '@/context/UserBundleContext';
import { useOrgBaseURL } from '@/hooks/useOrgBaseURL';

export interface HomepageCardProps {
    onReady: () => void
}


export default function Homepage() {


    const userBundle = useContext(UserBundleContext);


    const history = useHistory();
    const baseURL = useOrgBaseURL();
    useEffect(() => {
        history.push(`${baseURL}/dashboard`);
    }, [userBundle]);


    // Dont return any JSX on purpose because we dont want to trigger unnecessary components which will then fetch 
    // data from the server, only to be redirected from the code above immediately after firing the fetch call, theorefore
    // wasting resources on the backend.
    return null;
}