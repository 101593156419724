import React from 'react';

interface GearOutlineProps {
    size: number
}

export const GearOutlineIcon: React.FC<GearOutlineProps> = ({ size }) => {
    return (
        <svg width={size.toString()} height={size.toString()} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.23003 3.02059C1.17825 2.9235 1.19603 2.80397 1.27384 2.72616L2.72616 1.27384C2.80397 1.19603 2.9235 1.17825 3.02059 1.23003L5.59419 2.60261C5.6588 2.63707 5.73906 2.60886 5.7679 2.54156L6.79221 0.15152C6.8316 0.0595993 6.92199 0 7.02199 0H8.97801C9.07802 0 9.1684 0.0595992 9.20779 0.15152L10.2321 2.54156C10.2609 2.60886 10.3412 2.63707 10.4058 2.60261L12.9794 1.23003C13.0765 1.17825 13.196 1.19603 13.2738 1.27384L14.7262 2.72616C14.804 2.80397 14.8218 2.9235 14.77 3.02059L13.3974 5.59419C13.3629 5.6588 13.3911 5.73906 13.4584 5.7679L15.8485 6.79221C15.9404 6.8316 16 6.92199 16 7.02199V8.97801C16 9.07802 15.9404 9.1684 15.8485 9.20779L13.4584 10.2321C13.3911 10.2609 13.3629 10.3412 13.3974 10.4058L14.77 12.9794C14.8218 13.0765 14.804 13.196 14.7262 13.2738L13.2738 14.7262C13.196 14.804 13.0765 14.8218 12.9794 14.77L10.4058 13.3974C10.3412 13.3629 10.2609 13.3911 10.2321 13.4584L9.20779 15.8485C9.1684 15.9404 9.07802 16 8.97801 16H7.02199C6.92199 16 6.8316 15.9404 6.79221 15.8485L5.7679 13.4584C5.73906 13.3911 5.6588 13.3629 5.59419 13.3974L3.02059 14.77C2.9235 14.8218 2.80397 14.804 2.72616 14.7262L1.27384 13.2738C1.19603 13.196 1.17825 13.0765 1.23003 12.9794L2.60261 10.4058C2.63707 10.3412 2.60886 10.2609 2.54156 10.2321L0.15152 9.20779C0.0595993 9.1684 0 9.07802 0 8.97801V7.02199C0 6.92199 0.0595992 6.8316 0.15152 6.79221L2.54156 5.7679C2.60886 5.73906 2.63707 5.6588 2.60261 5.59419L1.23003 3.02059Z" fill="#6B7280" />
            <path d="M4 8C4 5.79086 5.79086 4 8 4C10.2091 4 12 5.79086 12 8C12 10.2091 10.2091 12 8 12C5.79086 12 4 10.2091 4 8Z" fill="hsl(var(--background))" />
        </svg>

    );
};