import { DOCUMENTREVISION, DisplayStatuses } from '@/entities/org/DOCUMENTREVISION';
import React, { useContext, useRef } from 'react';
import useSWR from 'swr';
import { DocRevEntry, DocumentRevisionTable } from '../../../../components/documentrevisiontable/DocumentRevisionTable';
import UserBundleContext from '@/context/UserBundleContext';
import { DataTableHandle } from '@/ui/ui/datatable/data-table';
import hasRights from '@/_shared/utils/hasRights';
import { RightsCodeKey } from '@/entities/master/PERSON';
import { useDocumentRevisionTableColumns } from '@/v2/components/documentrevisiontable/DocumentRevisionTableColumns';
import { constructFullName } from '@/components/UserPicker/utils/constructFullName';

interface DepartmentsTableProps {
    loading: boolean
    exportFileName: string
    infoHeirarchyID: number
    ihType: 'department' | 'manual'
    orgID: number
}

export const DepartmentsTable: React.FC<DepartmentsTableProps> = ({ loading, exportFileName, ihType, infoHeirarchyID, orgID }) => {

    interface IHDocRevsData {
        docrevs: DOCUMENTREVISION[]
    }

    const dataTableRef = useRef<DataTableHandle<DocRevEntry>>(null);
    const userBundle = useContext(UserBundleContext);

    const isSigner = hasRights(userBundle, RightsCodeKey.Signer);
    const isMyOrg = userBundle.organization?.ORGANIZATIONID === orgID;

    const { data: ihDocrevs } = useSWR<IHDocRevsData>({
        url: '/api/doc-manager/info-hierarchy/edit-info-hierarchy/documents',
        params: {
            ihType: ihType,
            ihID: infoHeirarchyID,
            activeOnly: false,
            // signers can see non official documents.
            officialOnly: !(isSigner && isMyOrg),
            ignoreDocumentVisibility: false,
            orgID: orgID
        }
    });

    const transformDocRevsToDataTableValues = (): DocRevEntry[] => {
        if (!ihDocrevs) return [];

        return ihDocrevs.docrevs.map(docrev => {
            const entry: DocRevEntry = {
                id: docrev.DOCID,
                revId: docrev.DOCREVID,
                revNo: docrev.REVNO,
                title: docrev.TITLE || 'no title',
                statusId: docrev.DOCSTATUS_ID,
                status: DisplayStatuses[docrev.DOCSTATUS_ID],
                docType: docrev.DOCTYPE?.DESCRIPTION || 'no doc type',
                owner: docrev.DOCUMENT.DOCOWNER ? constructFullName(docrev.DOCUMENT.DOCOWNER, 'lastFirstMiddle') : '',
                officialDate: docrev.OFFICIALDT ? new Date(docrev.OFFICIALDT) : undefined,
                blobId: (docrev as any).BLOB_ID,
                orgName: docrev.ORGANIZATION?.NAME ?? 'no org name',
                orgId: docrev.ORGANIZATION?.ORGANIZATIONID ?? -1,
                orgPrefix: docrev.ORGANIZATION?.PREFIX ?? undefined,
                department: docrev.DOCUMENT.INFORMATION_HIERARCHY?.TITLE ?? undefined
            };

            return entry;
        });
    };

    const columns = useDocumentRevisionTableColumns();

    return (
        <DocumentRevisionTable
            loading={loading}
            docRevs={transformDocRevsToDataTableValues()}
            exportAsFileName={exportFileName}
            columns={columns}
            ref={dataTableRef}
            limitTableHeight={true}
            // initially sets to true, but can be modified by the user.
            officialOnly={true}
        // manualFiltering={fal}
        />
    );
};
