import {
    Column,
    Entity,
    PrimaryColumn,
} from 'typeorm';
import { SchemaEntityManager } from '../SchemaEntityManager';


// eslint-disable-next-line @typescript-eslint/no-unused-vars
function createOrgAccrediationEntity(schemaName: string, manager: SchemaEntityManager) {
    @Entity('ORG_ACCREDITATION', { schema: 'ACCREDITATIONMANAGER' })
    class ORG_ACCREDITATION {
    
        @PrimaryColumn('number', {
            nullable: false,
            name: 'ORG_ACCREDITATION_ID'
        })
        ORG_ACCREDITATION_ID!: number;
    
    
        @Column('number', {
            nullable: false,
            name: 'ACCREDITOR_ID'
        })
        ACCREDITOR_ID!: number;
    
    
        @Column('number', {
            nullable: false,
            name: 'ORGANIZATIONID'
        })
        ORGANIZATIONID!: number;
    
    
        @Column('varchar2', {
            nullable: false,
            length: 32,
            default: () => '\'active\'',
            name: 'STATUS'
        })
        STATUS!: string;
    
    
        @Column('date', {
            nullable: true,
            name: 'WINDOW_START_DATE'
        })
        WINDOW_START_DATE!: Date | null;
    
    
        @Column('date', {
            nullable: true,
            name: 'WINDOW_END_DATE'
        })
        WINDOW_END_DATE!: Date | null;
    
    
        @Column('date', {
            nullable: false,
            default: () => 'SYSDATE',
            name: 'CREATION_DATE'
        })
        CREATION_DATE!: Date;
    
    
        @Column('number', {
            nullable: false,
            name: 'CREATED_BY_USERID'
        })
        CREATED_BY_USERID!: number;
    
    
        @Column('varchar2', {
            nullable: true,
            length: 32,
            name: 'IDENTIFIER'
        })
        IDENTIFIER!: string | null;
    
    
        @Column('varchar2', {
            nullable: true,
            length: 20,
            name: 'ACCREDITOR_NUMBER'
        })
        ACCREDITOR_NUMBER!: string | null;
    
    
        @Column('timestamp', {
            nullable: true,
            name: 'DISCARDED_DATE'
        })
        DISCARDED_DATE!: Date;
    
    }

    return ORG_ACCREDITATION;
}


// Assuming ORG_ACCREDITATION is the type you want to export directly
export { createOrgAccrediationEntity };
export type ORG_ACCREDITATION = InstanceType<ReturnType<typeof createOrgAccrediationEntity>>;
