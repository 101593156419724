import {
    Column,
    Entity,
    JoinColumn,
    ManyToOne,
    OneToMany,
    OneToOne,
    PrimaryColumn,
} from 'typeorm';
import { CONSENT_AGENDA_HEADING,  } from './CONSENT_AGENDA_HEADING';
import { COMMITTEE_APPROVAL_SESSION,  } from './COMMITTEE_APPROVAL_SESSION';
import { COMMITTEE_DECISION_LOG,  } from './COMMITTEE_DECISION_LOG';
import { CONSENT_AGENDA_REFERENCE,  } from './CONSENT_AGENDA_REFERENCE';
import { CONSENT_AGENDA_AUDIT_LOG,  } from './CONSENT_AGENDA_AUDIT_LOG';
import { SchemaEntityManager } from '../SchemaEntityManager';
import { APPROVAL_SESSION_STEP } from './APPROVAL_SESSION_STEP';

export enum ConsentAgendaTaskStatuses {
    ACTIVE = 0,
    REMOVED = 1
}

// Factory function to create CONSENT_AGENDA_TASK entity with dynamic schema
function createConsentAgendaTaskEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('CONSENT_AGENDA_TASK', { schema })
    class CONSENT_AGENDA_TASK {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', { nullable: false, name: 'TASK_ID' })
        TASK_ID!: number;

        @OneToOne(
            () => manager.getCommitteeDecisionLogEntity(schema),
            (COMMITTEE_DECISION_LOG) => COMMITTEE_DECISION_LOG.CONSENT_AGENDA_TASK
        )
        COMMITTEE_DECISION_LOG!: COMMITTEE_DECISION_LOG | null;

        @Column('number', { nullable: false, name: 'HEADING_ID' })
        HEADING_ID!: number;

        @ManyToOne(
            () => manager.getConsentAgendaHeadingEntity(schema),
            (CONSENT_AGENDA_HEADING) => CONSENT_AGENDA_HEADING.CONSENT_AGENDA_TASKS
        )
        @JoinColumn({ name: 'HEADING_ID' })
        CONSENT_AGENDA_HEADING!: CONSENT_AGENDA_HEADING | null;

        @Column('number', { nullable: false, name: 'LIST_INDEX' })
        LIST_INDEX!: number;

        @Column('varchar2', { nullable: true, length: 500, name: 'LABEL' })
        LABEL!: string | null;

        @Column('varchar2', { nullable: true, length: 4000, name: 'ABSTRACT' })
        ABSTRACT!: string | null;

        @Column('timestamp', { nullable: false, default: () => 'SYSDATE', name: 'CREATION_TIMESTAMP' })
        CREATION_TIMESTAMP!: Date;

        @Column('number', { nullable: false, name: 'STATUS' })
        STATUS!: number;

        @Column('number', {
            nullable: true,
            name: 'COMMITTEE_APPROVAL_SESSION_ID'
        })
        COMMITTEE_APPROVAL_SESSION_ID!: number | null;

        @ManyToOne(
            () => manager.getCommitteeApprovalSessionEntity(schema),
            (COMMITTEE_APPROVAL_SESSION) => COMMITTEE_APPROVAL_SESSION.CONSENT_AGENDA_TASKS
        )
        @JoinColumn({ name: 'COMMITTEE_APPROVAL_SESSION_ID' })
        COMMITTEE_APPROVAL_SESSION!: COMMITTEE_APPROVAL_SESSION | null;

        @OneToMany(
            () => manager.getConsentAgendaReferenceEntity(schema),
            (consentAgendaReference) => consentAgendaReference.CONSENT_AGENDA_TASK
        )
        CONSENT_AGENDA_REFERENCES!: CONSENT_AGENDA_REFERENCE[] | null;

        @OneToMany(
            () => manager.getConsentAgendaAuditLogEntity(schema),
            (consentAgendaAuditLog) => consentAgendaAuditLog.CONSENT_AGENDA_TASK
        )
        CONSENT_AGENDA_AUDIT_LOGS!: CONSENT_AGENDA_AUDIT_LOG[];

        @ManyToOne(
            () => manager.getApprovalSessionStepEntity(schema),
            (approvalSessionStep) => approvalSessionStep.CONSENT_AGENDA_TASKS
        )
        @JoinColumn({
            name: 'COMMITTEE_APPROVAL_SESSION_ID',
            referencedColumnName: 'COMMITTEE_APPROVAL_SESSION_ID',
        })
        APPROVAL_SESSION_STEP!: APPROVAL_SESSION_STEP | null;

        static Statuses = ConsentAgendaTaskStatuses;
    }

    return CONSENT_AGENDA_TASK;
}

// Export the factory function and the type
export { createConsentAgendaTaskEntity };
export type CONSENT_AGENDA_TASK = InstanceType<ReturnType<typeof createConsentAgendaTaskEntity>>;
