import React, { useEffect, useState } from 'react';
import { ORGANIZATION } from '../../../entities/master/ORGANIZATION';
import axios from 'axios';

interface OrgSelectMenuProps {
    text?: string
    selectedOrgID: number | undefined
    setSelectedOrgID: (arg0: number | undefined) => void
    disabled?: boolean
    
    // Optional parameters for multi-orgs; pick one or the other of these two, but not both:
    getPublishers?: boolean // will get all orgs with any PUBLISHING_SCHEMA property
    getSubscribersFromThisPublisherOrgID?: number // you'll provide the OrgID of the publishing org and it'll find its subscribers
}

export default function OrgSelectMenu(props: OrgSelectMenuProps) {
    const [ organizations, setOrganizations ] = useState<ORGANIZATION[]>([]);

    useEffect(() => {
        getOrgs().then();
    }, []);

    useEffect(() => { // used in ReleaseMultiOrgDocuments.tsx where one of these components needs to force a re-render of a second one
        getOrgs().then();
    }, [props.getSubscribersFromThisPublisherOrgID]);

    async function getOrgs() {
        const res = await axios.get('/api/internal-support/_shared/org-select-menu', {
            params: {
                getPublishers: props.getPublishers,
                getSubscribersFromThisPublisherOrgID: props.getSubscribersFromThisPublisherOrgID
            }
        });
        setOrganizations(res.data.organizations);
    }

    return (
        <>
            <span>{props.text || 'Select Org'}:  </span>
            <select
                value={props.selectedOrgID}
                disabled={props.disabled}
                onChange={(e) => {
                    if (!e.currentTarget.value) {
                        props.setSelectedOrgID(undefined);
                    }
                    else {
                        props.setSelectedOrgID(parseInt(e.currentTarget.value));
                    }
                }}
            >
                <option value={undefined}> </option>
                {organizations.map((org,idx) => (
                    <option
                        key={idx}
                        value={org.ORGANIZATIONID}
                    >
                        {org.NAME}
                    </option>
                ))}
            </select>
        </>
    );
}
