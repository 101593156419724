import React, { useContext } from 'react';
import { DatatableColumnFilter } from '@/ui/ui/datatable/column-filter';
import { DataTableFacetedFilter } from '@/ui/ui/datatable/datatable-faceted-filter';
import { DataTableToolbarProps } from '@/ui/ui/datatable/data-table';
import { UnscheduledItemEntry } from '@/v2/pages/committees/dialogs/editcommitteesdialog/EditCommitteesDialogAgendasTab';
import { DocumentTypeShadowIcon } from '@/ui/icons/shadow/DocumentTypeShadow';
import { DataTableViewOptions } from '@/ui/ui/datatable/view-options';
import { UnknownStatusShadowIcon } from '@/ui/icons/shadow/UnknownStatusShadow';
import { DepartmentShadowIcon } from '@/ui/icons/shadow/DepartmentShadow';
import UserBundleContext from '@/context/UserBundleContext';

const UnscheduledItemsTableToolbar: React.FC<DataTableToolbarProps<UnscheduledItemEntry>> = ({ table, datatableRef }) => {
    const userBundle = useContext(UserBundleContext);
    const departmentAlias = userBundle.organization?.ORGUNITNAME ?? 'Department';

    return (
        <div className="flex items-center justify-between my-4">
            <div className="flex flex-1 items-center space-x-2">
                <DatatableColumnFilter
                    column={table.getColumn('title')}
                    placeholder="Filter title..."
                />
                {table.getColumn('documentType') && (
                    <DataTableFacetedFilter
                        column={table.getColumn('documentType')}
                        title="Document Type"
                        icon={<DocumentTypeShadowIcon size={16} />}
                    />
                )}
                {table.getColumn('type') && (
                    <DataTableFacetedFilter
                        column={table.getColumn('type')}
                        title="Type"
                        icon={<UnknownStatusShadowIcon size={16} />}
                    />
                )}
                {table.getColumn('departmentName') && (
                    <DataTableFacetedFilter
                        column={table.getColumn('departmentName')}
                        title={departmentAlias}
                        icon={<DepartmentShadowIcon size={16} />}
                    />
                )}
            </div>
            <DataTableViewOptions
                datatableRef={datatableRef}
                table={table}
                noExtraHeaders
            />
        </div>
    );
};

export default UnscheduledItemsTableToolbar;
