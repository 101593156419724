import { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SaveBar from '../../../../../_shared/SaveBar/SaveBar';
import SaveIndicator from '../../../../../_shared/SaveIndicator';
import H3WithHelpTip from '../../../../../_shared/Forms/H3WithHelpTip';
import { RefreshRounded } from '@material-ui/icons';
import { LoadingIndicator } from '../../../../../_shared/LoadingIndicator';
import { LoadingStatuses } from '../../../../../../utils/LoadingStatuses';
import axios from 'axios';
import { buildDocURL } from '../../../../../../_shared/utils/docManagerFunctions';
import UserBundleContext from '../../../../../../context/UserBundleContext';
import { EditDocGeneralForm } from './EditDocGeneralForm';
import { DocProperties, FormInputFlags } from '../../../../../../types/editDoc/generalInfo';

type EditDocGeneralProps = {
    match: {
        params: {
            docid: string;
            docrevid: string;
        }
    }
};

const useStyles = makeStyles({
    editDocGeneralMainDiv: {
        padding: '1rem',
    }
});

export function EditDocGeneral(props: EditDocGeneralProps) {
    const classes = useStyles();
    const { docid, docrevid } = props.match.params;
    const context = useContext(UserBundleContext);

    const [ loadingStatus, setLoadingStatus ] = useState<LoadingStatuses>(LoadingStatuses.LOADING);
    const [ isSaveIndicatorVisible, setIsSaveIndicatorVisible ] = useState(false);
    const [ docRevURL, setDocRevURL ] = useState('');
    const [ docProperties, setDocProperties ] = useState<DocProperties>({} as DocProperties);
    const [ formInputFlags, setFormInputFlags ] = useState<FormInputFlags>({} as FormInputFlags);
    const [ errorMessage, setErrorMessage ] = useState('');

    useEffect(() => {
        getDocumentProperties()
            .then()
            .catch(err => {
                alert(err);
            });

        if (window.parent?.location.href.match(window.LUCIDOC_URL_REGEX)) {
            window.parent.postMessage(
                JSON.stringify({ clearFooter: true }),
                `https://${window.parent.location.host}`
            );
        }
    }, []);

    async function getDocumentProperties() {
        setLoadingStatus(LoadingStatuses.LOADING);

        const res = await axios.get('/api/doc-manager/document/edit-doc/general', {
            params: {
                docID: docid,
                docRevID: docrevid
            }
        });

        if (res.data.failure) {
            throw new Error('Error fetching document properties: ' + res.data.failure);
        }

        const docProperties = res.data.properties;
        docProperties.revisionNote = docProperties.revisionNote.replaceAll('<br>', '\n');

        setDocProperties(docProperties);
        setFormInputFlags(res.data.formInputFlags);
        setDocRevURL(buildDocURL(
            context.organization?.PREFIX ?? '',
            parseInt(docid),
            res.data.properties.revNumber,
            true,
        ));

        setLoadingStatus(LoadingStatuses.READY);
    }

    async function saveDocumentProperties() {
        setLoadingStatus(LoadingStatuses.SAVING);

        // for compatibility with other old stack pages
        const newRevisionNote = docProperties.revisionNote.replaceAll('\n', '<br>');

        const res = await axios.post('/api/doc-manager/document/edit-doc/general/save-properties', {
            docID: docid,
            docRevID: docrevid,
            docProperties: {
                title: docProperties.title,
                sourceReference: docProperties.sourceReference,
                docOwner: {
                    USERID: docProperties.docOwner.USERID,
                },
                docType: {
                    DOCTYPE_ID: docProperties.docType.DOCTYPE_ID,
                },
                template: docProperties.template,
                keywords: docProperties.keywords,
                revisionNote: newRevisionNote,
                revNumber: docProperties.revNumber,
                docGlobalIdentifier: docProperties.docGlobalIdentifier,
            },
        });

        if (res.data.failure) {
            setLoadingStatus(LoadingStatuses.READY);
            throw new Error('Error saving document properties: ' + res.data.failure);
        }

        setIsSaveIndicatorVisible(true);
        setLoadingStatus(LoadingStatuses.READY);
    }

    /**
     * Updates the local data store that the parent window maintains, so that
     * if the user navigates to a different Perl page and clicks "Apply", then
     * stale data is not sent to the Perl backend.
     */
    function sendDataToParentWindow() {
        const payload = {
            docProperties: {
                title: docProperties.title,
                sourceReference: docProperties.sourceReference,
                docOwner: docProperties.docOwner.USERID,
                docType: docProperties.docType.DOCTYPE_ID,
                docTemplate: docProperties.template.id,
                keywords: docProperties.keywords,
                globalIdentifier: docProperties.docGlobalIdentifier?.GLOBAL_IDENTIFIER_ID ?? 0,
                revisionNote: docProperties.revisionNote.replaceAll('\n', '<br>'), // for compatibility with other old stack pages
            },
            formInputFlags: {
                isTitleEditable: formInputFlags.titleEditable,
                isDocOwnerEditable: formInputFlags.isDocOwnerEditable,
                isDocTypeEditable: formInputFlags.isDocTypeEditable,
                isDocTemplateEditable: formInputFlags.isDocTemplateEditable,
                isGlobalIdentifierEditable: formInputFlags.isGlobalIDsAvailable,
                isRevisionNoteEditable: !formInputFlags.isOfficialDoc,
            },
        };

        if (window.parent?.location.href.match(window.LUCIDOC_URL_REGEX)) {
            window.parent?.postMessage(
                JSON.stringify({ generalProperties: payload }),
                `https://${window.parent.location.host}`
            );
        }
    }

    function reloadParentWindow() {
        if (window.parent?.location.href.match(window.LUCIDOC_URL_REGEX)) {
            window.parent?.postMessage(
                JSON.stringify({ saveInReactPage: true }),
                `https://${window.parent.location.host}`
            );
        }
    }

    if (loadingStatus !== LoadingStatuses.READY) {
        return <LoadingIndicator loadingStatus={loadingStatus} />;
    }

    const docURL = buildDocURL(
        context.organization?.PREFIX ?? '',
        parseInt(docid),
        undefined,
        true,
    );

    // valid doc template is selected for the selected doc type
    const docTemplateIsSelected = !formInputFlags.isDocTemplateEditable ||
        docProperties.docTemplateOptions.some(template =>
            template.DOCTYPE_ID === docProperties.docType.DOCTYPE_ID &&
            template.TEMPLATEID === docProperties.template.id
        );

    const isFormValid = (
        docProperties.title.length > 0 &&
        docProperties.docOwner !== null &&
        docProperties.docType !== null &&
        docTemplateIsSelected
    );

    return (
        <div className={classes.editDocGeneralMainDiv}>
            <H3WithHelpTip
                helpText={'General properties of this document.'}
                text={'General Properties'}
            />

            <EditDocGeneralForm
                docURL={docURL}
                docRevURL={docRevURL}
                docID={parseInt(docid)}
                docProperties={docProperties}
                formInputFlags={formInputFlags}
                onInputChange={(propertyName, value) => setDocProperties({ ...docProperties, [propertyName]: value })}
            />

            <SaveBar
                onSave={() => {
                    saveDocumentProperties()
                        .then(() => {
                            setErrorMessage('Document general properties saved successfully.');

                            // TODO: remove this once we migrate the entire EditDoc window to React
                            sendDataToParentWindow();
                            reloadParentWindow();
                        })
                        .catch((err) => {
                            alert(err);
                        });
                }}
                onCancel={() => {
                    getDocumentProperties()
                        .then()
                        .catch(err => {
                            alert(err);
                        });
                }}
                cancelIcon={<RefreshRounded />}
                onClose={() => window.parent.close()}
                isSaveDisabled={!isFormValid}
            />

            <SaveIndicator
                open={isSaveIndicatorVisible}
                onClose={() => setIsSaveIndicatorVisible(false)}
                message={errorMessage}
            />
        </div>
    );
}