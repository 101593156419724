import {
    Column,
    Entity,
    JoinColumn,
    ManyToOne,
    PrimaryColumn,
} from 'typeorm';
import { SchemaEntityManager } from '../SchemaEntityManager';
import { DOCUMENTREVISION } from './DOCUMENTREVISION';
import { CONVERSATION } from './CONVERSATION';

function createFeedbackEntity(schema: string, schemaEntityManager: SchemaEntityManager) {
    @Entity('FEEDBACK', { schema: schema })
    class FEEDBACK {
        @PrimaryColumn('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'FEEDBACKID'
        })
        FEEDBACKID!: number;

        @Column('date', {
            nullable: false,
            name: 'FEEDBACKDT'
        })
        FEEDBACKDT!: Date;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'USERID'
        })
        USERID!: number | null;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'DOCREVID'
        })
        DOCREVID!: number | null;

        @Column('varchar2', {
            nullable: false,
            length: 4000,
            name: 'TEXT'
        })
        TEXT!: string;

        @Column('varchar2', {
            nullable: true,
            length: 200,
            name: 'CARBON'
        })
        CARBON!: string | null;

        @Column('char', {
            nullable: true,
            name: 'READSTATUS'
        })
        READSTATUS!: string | null;

        @Column('varchar2', {
            nullable: true,
            length: 30,
            name: 'ELEMENT_IDENTIFIER'
        })
        ELEMENT_IDENTIFIER!: string | null;

        @Column('varchar2', {
            nullable: true,
            length: 30,
            name: 'USER_NAME'
        })
        USER_NAME!: string | null;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'FEEDBACK_TYPE_ID'
        })
        FEEDBACK_TYPE_ID!: number | null;

        @Column('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'SENDER_USERID'
        })
        SENDER_USERID!: number;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'PARENTID'
        })
        PARENTID!: number | null;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'INFORMATION_HIERARCHY_ID'
        })
        INFORMATION_HIERARCHY_ID!: number | null;

        @Column('varchar2', {
            nullable: true,
            length: 40,
            name: 'PAGENAME'
        })
        PAGENAME!: string | null;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'REVIEWERID'
        })
        REVIEWERID!: number | null;

        @Column('char', {
            nullable: true,
            length: 2,
            name: 'SIGNATURESTATUS'
        })
        SIGNATURESTATUS!: string | null;

        @Column('number', {
            nullable: true,
            name: 'TASK_ID'
        })
        TASK_ID!: number | null;

        @Column('number', {
            nullable: true,
            name: 'CHECKLIST_CONDITION_ID'
        })
        CHECKLIST_CONDITION_ID!: number | null;

        @ManyToOne(
            () => schemaEntityManager.getDocumentRevisionEntity(schema), 
            (docrev) => docrev.FEEDBACKS
        )
        @JoinColumn({
            name: 'DOCREVID'
        })
        DOCUMENTREVISION!: DOCUMENTREVISION | null;

        @ManyToOne(
            () => schemaEntityManager.getConversationEntity(schema),
            (conversation) => conversation.FEEDBACKS
        )
        @JoinColumn({
            name: 'FEEDBACKID'
        })
        CONVERSATION!: CONVERSATION | null;
    }

    return FEEDBACK;
}

export { createFeedbackEntity };
export type FEEDBACK = InstanceType<ReturnType<typeof createFeedbackEntity>>;