import App from './App';
import axios from 'axios';
import UserBundleContext, { UserBundleType } from './context/UserBundleContext';
import { render } from 'react-dom';
import * as Sentry from '@sentry/react';

axios.get('/api/navigation/user-bundle').then(res => {
    const userBundle = res.data.userBundle as UserBundleType;
    const developmentEnvironments = ['alpha-development'];

    if (!developmentEnvironments.includes(userBundle.sentry.environment)) {
        console.log('Sentry enabled');
        Sentry.init({
            dsn: 'https://1b88fb74123dfeb3707d2af008d7b56a@o4506820406018048.ingest.sentry.io/4506837686878208',
            integrations: [],
            environment: userBundle.sentry.environment,
            release: userBundle.sentry.release,
        });
    } else {
        console.log('Sentry is disabled in development environments');
    }
   

    render(
        <UserBundleContext.Provider value={userBundle}>
            <App />
        </UserBundleContext.Provider>,
        document.getElementById('root')
    );
})
    .catch((err) => {
        Sentry.captureException(err);
        console.log(err);
        document.location.href = `/cgi/login.pl?redirecturl=${document.location.href}`;
    });

