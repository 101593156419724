import { type ClassValue, clsx } from 'clsx';
import { extendTailwindMerge } from 'tailwind-merge';


// twmerge cannot be linked to the tailwind config.
// if you're havig class merging issues, please add the class group in here so it won't strip the class out.
const twMerge = extendTailwindMerge({
    extend: {
        classGroups: {
            'font-size': ['text-bubble'],
            'text-color': ['text-primary', 'text-secondary']
        }
    }
});

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
