import H3WithHelpTip from '../H3WithHelpTip';
import { Typography } from '@material-ui/core';

interface ILucidocFormProps {
    name?: string;
    title?: string;
    fields: ({
        name?: string;
        description?: string | React.ReactNode;
        optional?: boolean;
        component: React.ReactNode;
    } | undefined)[];
    width?: string;
    noHeaderSpacing?: boolean;
}

const LucidocForm: React.FC<ILucidocFormProps> = (props: ILucidocFormProps) => {

    return <div
        style={{
            padding: '.5rem 1rem',
            width: props.width
        }}
    >
        <div
            style={{
                height: props.noHeaderSpacing && !props.title ? undefined : '4rem',
                marginTop: props.noHeaderSpacing ? 0 : '1rem',
            }}
        >
            {
                props.name && props.title &&
                    <H3WithHelpTip
                        helpText={ props.title || '' }
                        text={ props.name }
                    />
            }
        </div>
        {
            props.fields.map((field, index) =>
                field ?
                    <div
                        key={ `config-list-${ props.title?.toLowerCase() }-field-${ field.name?.toLowerCase() }-${ index }` }
                        style={{
                            display: 'grid',
                            padding: '.5rem 0'
                        }}
                    >
                        {
                            field.name &&
                                <Typography
                                    variant='caption'
                                >
                                    { field.name }
                                    {
                                        field.optional &&
                                            <>&nbsp;(Optional)</>
                                    }
                                </Typography>
                            }
                        { field.component }
                        {
                            field.description &&
                                <Typography
                                    variant='caption'
                                >
                                    { field.description }
                                </Typography>
                        }
                    </div> : null
            )
        }
    </div>;

};

export default LucidocForm;
