import { Button } from '@/ui/ui/button';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/ui/ui/tooltip';
import React from 'react';
import { EditInPlaceInstallDialog } from './EditInPlaceInstallDialog';




export const EditInPlace2: React.FC = () => {

    const eip2Link = window.location.origin + '/assets/eip2-package.zip';

    // download file.
    const downloadFile = () => {
        window.open(eip2Link);
    };

    return (
        <div className='w-full p-2'>
            <p className='text-xl font-medium'>Edit in Place 2 Install Guide</p>
            <p>1. Download the Edit-in-Place installer. Select the <b>Windows</b> button displayed here.</p>
            <div className='w-full flex flex-row p-2 space-x-2'>
                <Button onClick={() => downloadFile()} size={'md'}>Windows</Button>
                <Tooltip>
                    <TooltipTrigger>
                        <Button disabled size={'md'}>MacOS</Button>
                    </TooltipTrigger>
                    <TooltipContent side='bottom'>
                        <div>
                            <p>MacOS support is coming soon.</p>
                        </div>
                    </TooltipContent>
                </Tooltip>
            </div>
            <p>2. Go to the folder where the installer was downloaded.</p>
            <p>3. Right-click <b>eip2-package.zip</b>, select <b>Extract All</b>, and select <b>Extract</b>.</p>
            <p>4. Browse to the folder to which the files were extracted.</p>
            <p>5. Right-click <b>eip2_installer.exe</b>, and select <b>Run as Administrator</b>.</p>
            <p className='pl-3 border-l-8 text-muted-foreground'>(Note that the “.exe” file name extension may be absent depending on your Windows settings.)
                <br /> At this point, anti-virus software installed on your computer may present a warning.
                <br /> If you receive a warning and use Windows Defender/SmartScreen: Select <b>More info</b>, and select <b>Run anyway</b>.
            </p>
            <p>6. In the <b>User Account Control</b> dialog box, enter the Administrator password if required and select <b>Yes</b>.</p>
            <p>7. When you see the <b>Successfully Installed</b> confirmation message, select <b>OK</b> and restart your computer.</p>
            <p>8. After your computer has restarted, return to this page.</p>
            <p>9. Select <b>Check Install Status</b>. You should see a confirmation message that Edit-in-Place is installed.</p>
            <p>10. In the confirmation message, select <b>Close</b>; then select <b>Edit Document Content</b>.</p>
            <div className='w-full flex flex-row p-2'>
                <EditInPlaceInstallDialog />
            </div>

        </div>
    );
};