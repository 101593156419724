import {
    Column,
    Entity,
    OneToMany,
    PrimaryColumn,
} from 'typeorm';
import {  SUBSCRIPTION } from './SUBSCRIPTION';
import {  EDITION } from './EDITION';
import { SchemaEntityManager } from '../SchemaEntityManager';

function createPublicationEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('PUBLICATION', { schema: schema })
    class PUBLICATION {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            name: 'PUBLICATIONID'
        })
        PUBLICATIONID!: number;

        @Column('timestamp', {
            nullable: true,
            default: () => 'SYSDATE',
            name: 'CREATED_DATE'
        })
        CREATED_DATE!: Date;

        @Column('number', {
            nullable: false,
            name: 'PUBLISHER_ORGID'
        })
        PUBLISHER_ORGID!: number;

        @Column('number', {
            nullable: false,
            name: 'DOCID'
        })
        DOCID!: number;

        @Column('number', {
            nullable: false,
            name: 'DOCOWNER_ID'
        })
        DOCOWNER_ID!: number;

        @Column('number', {
            nullable: false,
            name: 'TEMPLATEID'
        })
        TEMPLATEID!: number;

        @Column('varchar2', {
            nullable: false,
            name: 'DEPARTMENT_NAME'
        })
        DEPARTMENT_NAME!: string;

        @OneToMany(
            () => manager.getSubscriptionEntity(schema),
            (subscription) => subscription.PUBLICATION
        )
        SUBSCRIPTIONS!: SUBSCRIPTION[] | null;

        @OneToMany(
            () => manager.getEditionEntity(schema),
            (edition) => edition.PUBLICATION
        )
        EDITIONS!: EDITION[] | null;
    }

    return PUBLICATION;
}

export { createPublicationEntity };
export type PUBLICATION = InstanceType<ReturnType<typeof createPublicationEntity>>;
