import React from 'react';

interface InPrepProps {
    size: number
}

export const InPrepDocumentIcon: React.FC<InPrepProps> = ({ size }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="16" height="16" rx="4" fill="#6095D4" />
            <path d="M3.80146 4.94916C3.74967 4.85207 3.76746 4.73254 3.84527 4.65473L4.65473 3.84527C4.73254 3.76746 4.85207 3.74967 4.94916 3.80146L6.45133 4.60261C6.51594 4.63707 6.5962 4.60886 6.62505 4.54156L7.22078 3.15152C7.26017 3.0596 7.35056 3 7.45056 3H8.54944C8.64944 3 8.73983 3.0596 8.77922 3.15152L9.37495 4.54156C9.4038 4.60886 9.48406 4.63707 9.54867 4.60261L11.0508 3.80146C11.1479 3.74967 11.2675 3.76746 11.3453 3.84527L12.1547 4.65473C12.2325 4.73254 12.2503 4.85207 12.1985 4.94916L11.3974 6.45133C11.3629 6.51594 11.3911 6.5962 11.4584 6.62505L12.8485 7.22078C12.9404 7.26017 13 7.35056 13 7.45056V8.54944C13 8.64944 12.9404 8.73983 12.8485 8.77922L11.4584 9.37495C11.3911 9.4038 11.3629 9.48406 11.3974 9.54867L12.1985 11.0508C12.2503 11.1479 12.2325 11.2675 12.1547 11.3453L11.3453 12.1547C11.2675 12.2325 11.1479 12.2503 11.0508 12.1985L9.54867 11.3974C9.48406 11.3629 9.4038 11.3911 9.37495 11.4584L8.77922 12.8485C8.73983 12.9404 8.64944 13 8.54944 13H7.45056C7.35056 13 7.26017 12.9404 7.22078 12.8485L6.62505 11.4584C6.5962 11.3911 6.51594 11.3629 6.45133 11.3974L4.94916 12.1985C4.85207 12.2503 4.73254 12.2325 4.65473 12.1547L3.84527 11.3453C3.76746 11.2675 3.74967 11.1479 3.80146 11.0508L4.60261 9.54867C4.63707 9.48406 4.60886 9.4038 4.54156 9.37495L3.15152 8.77922C3.0596 8.73983 3 8.64944 3 8.54944V7.45056C3 7.35056 3.0596 7.26017 3.15152 7.22078L4.54156 6.62505C4.60886 6.5962 4.63707 6.51594 4.60261 6.45133L3.80146 4.94916Z" fill="hsl(var(--white))" />
            <path d="M5.27271 8.00022C5.27271 6.49399 6.49375 5.27295 7.99998 5.27295C9.50621 5.27295 10.7273 6.49399 10.7273 8.00022C10.7273 9.50645 9.50621 10.7275 7.99998 10.7275C6.49375 10.7275 5.27271 9.50645 5.27271 8.00022Z" fill="#6095D4" />
        </svg>

    );
};