export function transformDate(date: Date | string | null): string {
    if (!date) {
        return '';
    }

    if (typeof date === 'string') {
        date = new Date(date);
    }
    
    const [year, month, day] = date.toISOString().split('T')[0].split('-');
    return `${month}/${day}/${year}`;
}

export function transformReviewCycle(reviewCycle: number | null): string {
    if (!reviewCycle) {
        return 'None';
    }

    if (reviewCycle === 1) {
        return `${reviewCycle} year`;
    }

    return `${reviewCycle} years`;
}