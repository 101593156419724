import {
    Column,
    Entity,
    JoinColumn,
    ManyToOne,
    OneToMany,
    PrimaryColumn,
    JoinTable,
    ManyToMany,
    OneToOne,
} from 'typeorm';
import { type PERSON,  } from '../master/PERSON';
import { ROUTING_CONFIGURATION,  } from './ROUTING_CONFIGURATION';
import { COMMITTEE_APPROVAL_REQUEST,  } from './COMMITTEE_APPROVAL_REQUEST';
import { CONSENT_AGENDA,  } from './CONSENT_AGENDA';
import { CONSENT_AGENDA_TASK } from './CONSENT_AGENDA_TASK';
import { INFORMATION_HIERARCHY,  } from './INFORMATION_HIERARCHY';
import { SchemaEntityManager } from '../SchemaEntityManager';
import { DOCUMENT } from './DOCUMENT';
import { stringToBooleanTransformer } from '../_helperFunctions/transformers';

export enum CommitteeStatuses {
    ACTIVE = 0, // yes really
    INACTIVE = 1
}

// Factory function to create COMMITTEE entity with dynamic schema
function createCommitteeEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('COMMITTEE', { schema })
    class COMMITTEE {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            name: 'COMMITTEE_ID'
        })
        COMMITTEE_ID!: number;

        @Column('varchar2', {
            nullable: false,
            length: 100,
            name: 'NAME'
        })
        NAME!: string;

        @Column('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'CHAIRPERSON_USERID'
        })
        CHAIRPERSON_USERID!: number;

        @ManyToOne(
            () => manager.getPersonEntity(schema), 
            PERSON => PERSON.COMMITTEE_CHAIRS
        )
        @JoinColumn({
            name: 'CHAIRPERSON_USERID'
        })
        CHAIRPERSON?: PERSON;

        @Column('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'SECRETARY_USERID'
        })
        SECRETARY_USERID!: number;

        @ManyToOne(
            () => manager.getPersonEntity(schema),
            PERSON => PERSON.COMMITTEE_SECRETARIES
        )
        @JoinColumn({
            name: 'SECRETARY_USERID'
        })
        SECRETARY?: PERSON;

        @Column('number', {
            nullable: true,
            name: 'ACTIVE_CONSENT_AGENDA_ID'
        })
        ACTIVE_CONSENT_AGENDA_ID!: number | null;

        @Column('number', {
            nullable: true,
            name: 'ACTIVE_CA_HEADING_ID'
        })
        ACTIVE_CA_HEADING_ID!: number | null;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'BYLAWS_MANUALID'
        })
        BYLAWS_MANUALID!: number | null;

        @Column('number', {
            nullable: true,
            name: 'IS_BOARD'
        })
        IS_BOARD!: number | null;

        @Column('number', {
            nullable: true,
            name: 'MINUTES_DEFAULT_CONTENT_DOCID'
        })
        MINUTES_DEFAULT_CONTENT_DOCID!: number | null;

        @OneToOne(
            () => manager.getDocumentEntity(schema),
            (doc) => doc.COMMITTEE
        )
        @JoinColumn({
            name: 'MINUTES_DEFAULT_CONTENT_DOCID'
        })
        MINUTES_DEFAULT_CONTENT_DOC!: DOCUMENT | null;

        @Column('number', {
            nullable: true,
            name: 'MINUTES_DEPARTMENT_ID'
        })
        MINUTES_DEPARTMENT_ID!: number | null;

        @ManyToOne(
            () => manager.getInformationHierarchyEntity(schema)
        )
        @JoinColumn({
            name: 'MINUTES_DEPARTMENT_ID'
        })
        MINUTES_DEPARTMENT!: INFORMATION_HIERARCHY | null;

        @Column('number', {
            nullable: true,
            default: () => '0',
            name: 'STATUS'
        })
        STATUS!: number | null;

        @Column('timestamp', {
            nullable: true,
            default: () => 'SYSDATE',
            name: 'STATUS_DATE'
        })
        STATUS_DATE!: Date | null;

        @Column('varchar2', {
            nullable: true,
            name: 'NOTES'
        })
        NOTES!: string | null;

        @Column('char', {
            nullable: false,
            name: 'ALLOW_PUBLIC_MINUTES',
            transformer: stringToBooleanTransformer,
        })
        ALLOW_PUBLIC_MINUTES!: boolean;

        @ManyToMany(
            () => manager.getPersonEntity(schema), 
            (person) => person.COMMITTEES_MEMBER
        )
        @JoinTable({
            // schema: 'MASTER',
            name: 'COMMITTEE_MEMBER',
            joinColumn: {
                name: 'COMMITTEE_ID',
                referencedColumnName: 'COMMITTEE_ID'
            },
            inverseJoinColumn: {
                name: 'USERID',
                referencedColumnName: 'USERID'
            }
        })
        PEOPLE!: PERSON[] | null;

        @ManyToMany(
            () => manager.getPersonEntity(schema), 
            (person) => person.COMMITTEES_COLLABORATE_ON
        )
        @JoinTable({
            // schema: 'MASTER',
            name: 'COMMITTEE_COLLABORATOR',
            joinColumn: {
                name: 'COMMITTEE_ID',
                referencedColumnName: 'COMMITTEE_ID'
            },
            inverseJoinColumn: {
                name: 'USERID',
                referencedColumnName: 'USERID'
            }
        })
        COLLABORATORS!: PERSON[] | null;

        @OneToMany(
            () => manager.getRoutingConfigurationEntity(schema), 
            (routingConfiguration) => routingConfiguration.COMMITTEE
        )
        ROUTING_CONFIGURATIONS!: ROUTING_CONFIGURATION[] | null;

        @OneToMany(
            () => manager.getCommitteeApprovalRequestEntity(schema), 
            (committeeApprovalRequest) => committeeApprovalRequest.COMMITTEE
        )
        COMMITTEE_APPROVAL_REQUESTS!: COMMITTEE_APPROVAL_REQUEST[] | null;

        @OneToMany(
            () => manager.getConsentAgendaEntity(schema), 
            (consentAgenda) => consentAgenda.COMMITTEE
        )
        CONSENT_AGENDAS!: CONSENT_AGENDA[] | null;

        // the following value is not based on a relation between the tables (at least not yet),
        // but is still sometimes useful; it simply requires a manual query for right now to populate,
        // rather than a real TypeORM relation:
        CONSENT_AGENDA_TASKS!: CONSENT_AGENDA_TASK[] | null;
    }

    return COMMITTEE;
}

// Export the factory function
export { createCommitteeEntity };

// Define and export the type for instances created by the factory function
export type COMMITTEE = InstanceType<ReturnType<typeof createCommitteeEntity>>;
