import React from 'react';

interface CreateCommitteeIcon {
    size: number
}

export const CreateCommitteeIcon: React.FC<CreateCommitteeIcon> = ({ size }) => {
    return (
        <svg width={size.toString()} height={size.toString()} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" rx="4" fill="hsl(var(--primary))"/>
            <path d="M7 17V8H17V17H7Z" stroke="white" stroke-width="2"/>
            <path d="M6 8.00003V7H18V8.00003H6Z" stroke="white" stroke-width="2"/>
            <path d="M12.5 10.625C12.5 10.556 12.444 10.5 12.375 10.5H11.625C11.556 10.5 11.5 10.556 11.5 10.625V12H10.125C10.056 12 10 12.056 10 12.125V12.875C10 12.944 10.056 13 10.125 13H11.5V14.375C11.5 14.444 11.556 14.5 11.625 14.5H12.375C12.444 14.5 12.5 14.444 12.5 14.375V13H13.875C13.944 13 14 12.944 14 12.875V12.125C14 12.056 13.944 12 13.875 12H12.5V10.625Z" fill="white"/>
        </svg>
        
    );
};