import UserBundleContext from '@/context/UserBundleContext';
import { GearOutlineIcon } from '@/ui/icons/outline/GearOutline';
import { HybridLink } from '@/ui/ui/hybridlink';
import { NavigationMenuContent, NavigationMenuItem, NavigationMenuTrigger } from '@/ui/ui/navigation-menu';
import React, { useContext, useState } from 'react';

interface HeaderInternalDropdownProps {

}

export const HeaderInternalDropdown: React.FC<HeaderInternalDropdownProps> = () => {

    const userBundle = useContext(UserBundleContext);
    const [show, setShow] = useState(false);
    const isSysAdmin = userBundle.user?.RIGHTSINENGLISH?.systemAdministrator;

    return (
        <NavigationMenuItem>
            <NavigationMenuTrigger onMouseEnter={() => setShow(true)}
                onMouseLeave={() => setShow(false)}
                hideChevron>
                <div>
                    {show ? <GearOutlineIcon size={16} /> : <div className='h-[16[x] w-[16px]' />}
                </div>
            </NavigationMenuTrigger>
            <NavigationMenuContent>
                <div className='flex flex-col w-64 md:w-[250px] space-y-2 p-3'>
                    <HybridLink href={'/customer-service/menu.pl'} external>Customer Service (Old Stack)</HybridLink>
                    <HybridLink href={'/internal-support'} external={false}>Internal Support (New Stack)</HybridLink>
                    {isSysAdmin && <HybridLink href={'/system/menu.pl'} external>System Menu</HybridLink>}
                </div>
            </NavigationMenuContent>
        </NavigationMenuItem>
    );
};