import {
    Column,
    Entity,
    PrimaryColumn,
} from 'typeorm';
import { SchemaEntityManager } from '../SchemaEntityManager';
import { nullableStringToBooleanTransformer } from '../_helperFunctions/transformers';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function createDocumentStatusPLEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('DOCUMENT_STATUS_PL', { schema: 'MASTER' })
    class DOCUMENT_STATUS_PL {
        @PrimaryColumn('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'DOCSTATUS_ID'
        })
        DOCSTATUS_ID!: number;

        @Column('varchar2', {
            nullable: false,
            length: 40,
            name: 'DESCRIPTION'
        })
        DESCRIPTION!: string;

        @Column('varchar2', {
            nullable: true,
            length: 20,
            name: 'ABBREVIATION'
        })
        ABBREVIATION!: string | null;

        @Column('char', {
            nullable: true,
            length: 5,
            name: 'REVISABLE'
        })
        REVISABLE!: string | null;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 5,
            name: 'STATE_SEQUENCE'
        })
        STATE_SEQUENCE!: number | null;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 5,
            name: 'PRIORITY'
        })
        PRIORITY!: number | null;

        @Column('char', {
            nullable: true,
            name: 'PROCOWNER',
            transformer: nullableStringToBooleanTransformer,
        })
        PROCOWNER!: boolean;

        @Column('char', {
            nullable: true,
            name: 'AUTHOR',
            transformer: nullableStringToBooleanTransformer,
        })
        AUTHOR!: boolean;

        @Column('char', {
            nullable: true,
            name: 'OWNER',
            transformer: nullableStringToBooleanTransformer,
        })
        OWNER!: boolean;

        @Column('char', {
            nullable: true,
            name: 'ISOBSOLETE',
            transformer: nullableStringToBooleanTransformer,
        })
        ISOBSOLETE!: boolean;

        @Column('char', {
            nullable: true,
            name: 'ISDRAFT',
            transformer: nullableStringToBooleanTransformer,
        })
        ISDRAFT!: boolean;

        @Column('char', {
            nullable: true,
            name: 'NOFEEDBACK',
            transformer: nullableStringToBooleanTransformer,
        })
        NOFEEDBACK!: boolean;

        @Column('char', {
            nullable: true,
            name: 'ISQUERY',
            transformer: nullableStringToBooleanTransformer,
        })
        ISQUERY!: boolean;

        @Column('char', {
            nullable: true,
            name: 'ISUNPUBLISH',
            transformer: nullableStringToBooleanTransformer,
        })
        ISUNPUBLISH!: boolean;

        @Column('char', {
            nullable: true,
            name: 'ISNOREVNOTICE',
            transformer: nullableStringToBooleanTransformer,
        })
        ISNOREVNOTICE!: boolean;

        @Column('varchar2', {
            nullable: true,
            length: 128,
            name: 'WATERMARK'
        })
        WATERMARK!: string | null;

        @Column('char', {
            nullable: true,
            name: 'ISSUBSCRIBE',
            transformer: nullableStringToBooleanTransformer,
        })
        ISSUBSCRIBE!: boolean;

        @Column('char', {
            nullable: true,
            name: 'ISNOTIFY',
            transformer: nullableStringToBooleanTransformer,
        })
        ISNOTIFY!: boolean;

        @Column('varchar2', {
            nullable: true,
            length: 32,
            name: 'NOTIFYTEMPLATE'
        })
        NOTIFYTEMPLATE!: string | null;

        @Column('char', {
            nullable: true,
            name: 'ISPENDING',
            transformer: nullableStringToBooleanTransformer,
        })
        ISPENDING!: boolean;

        @Column('varchar2', {
            nullable: true,
            length: 30,
            name: 'REVIEW_TYPE'
        })
        REVIEW_TYPE!: string | null;

        @Column('char', {
            nullable: true,
            name: 'TERMINAL',
            transformer: nullableStringToBooleanTransformer,
        })
        TERMINAL!: boolean;
    }

    return DOCUMENT_STATUS_PL;
}


export type DOCUMENT_STATUS_PL = InstanceType<ReturnType<typeof createDocumentStatusPLEntity>>;
