import * as React from 'react';
import { constructFullName } from '@/components/UserPicker/utils/constructFullName';
import { PERSON, PersonStatuses, rightsCodes } from '@/entities/master/PERSON';
import useUserSearch, { UserSearchPerson } from '@/hooks/useUserSearch';
import { useEffect, useRef, useState } from 'react';
import { Popover, PopoverContent, PopoverTrigger } from './popover';
import { userPickerVariants } from './user-picker';
import { cn } from '@/lib/utils';
import { type VariantProps } from 'class-variance-authority';
import useDebounce from '@/hooks/useDebounce';
import { SearchIcon } from 'lucide-react';
import { Input } from './input';
import { Separator } from './separator';
import { Button } from './button';
import { Checkbox } from './checkbox';

export interface SelectUserProps extends React.HtmlHTMLAttributes<HTMLDivElement>, VariantProps<typeof userPickerVariants> {
    selectedUser?: PERSON;
    setSelectedUser: (user?: PERSON) => void;
    placeholder?: string;
    rights?: (keyof typeof rightsCodes)[];
    statuses?: PersonStatuses[],
    exemptUsers?: PERSON[];
    getMappedUsers?: boolean;
    disabled?: boolean;
}

const SelectUser = React.forwardRef<HTMLDivElement, SelectUserProps>(({ className, variant, size, ...props }, ref) => {

    const [filterText, setFilterText] = React.useState<string>('');
    const debouncedFilterText = useDebounce<string>(filterText, 300);

    const triggerRef = useRef<HTMLButtonElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState(false);

    const { selectedUser, setSelectedUser } = props;

    const [users] = useUserSearch(
        debouncedFilterText,
        selectedUser ? [selectedUser.USERID] : [],
        props.statuses ?? [PersonStatuses.ACTIVE],
        props.rights,
        undefined,
        props.exemptUsers?.map(user => user.USERID),
        props.getMappedUsers
    );

    useEffect(() => {
        if (
            props.exemptUsers &&
            props.exemptUsers.some(exemptUser => exemptUser.USERID === selectedUser?.USERID)
        ) {
            props.setSelectedUser(undefined);
        }
    }, [props.exemptUsers]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                    contentRef.current && 
                    !contentRef.current.contains(event.target as Node) &&
                    triggerRef.current &&
                    !triggerRef.current.contains(event.target as Node)
                ) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, triggerRef]);

    const options = (users || []).filter(user => !props.exemptUsers?.some(exemptUser => exemptUser.USERID === user.person.USERID));

    if (props.disabled) {
        return (
            <div
                ref={ref}
                {...props}
            >
                <Input
                    type='text'
                    value={selectedUser ? constructFullName(selectedUser, 'firstMiddleLast') : 'Select User...'}
                    disabled={true}
                />
            </div>
        );
    }

    return (
        <Popover open={isOpen}>
            <PopoverTrigger
                ref={triggerRef}
                asChild
                onClick={() => setIsOpen(!isOpen)}
            >
                <div
                    ref={ref}
                    className={cn(userPickerVariants({ variant, size, className }))}
                    {...props}
                >
                    <p>{selectedUser ? constructFullName(selectedUser, 'firstMiddleLast') : 'Select User...'}</p>
                </div>
            </PopoverTrigger>
            <PopoverContent align='start' className='w-full min-w-[300px] p-0'>
                <div className='text-sm w-full p-2 pb-0 flex flex-row items-center'>
                    <SearchIcon className='text-secondary mr-2' size={18} />
                    <Input
                        className='border-none w-full outline-none text-secondary'
                        placeholder='Search users...'
                        value={filterText}
                        onChange={(e) => setFilterText(e.target.value)}
                    />
                </div>
                <Separator orientation='horizontal' className='my-2' />
                <div
                    className='w-full flex flex-col'
                    ref={contentRef}
                >
                    {users !== undefined && <div className='flex flex-col overflow-y-auto max-h-[275px] space-y-1'>
                        {options.map((user: UserSearchPerson) => {
                            const isSelected = selectedUser?.USERID === user.person.USERID;
                            return (
                                <Button
                                    key={user.USERID}
                                    variant='ghost'
                                    className={cn(
                                        'justify-start',
                                        isSelected ? 'text-primary' : 'text-secondary'
                                    )}
                                    onClick={() => {
                                        if (isSelected) {
                                            setSelectedUser(undefined);
                                        } else {
                                            setSelectedUser(user.person);
                                        }
                                    }}
                                >
                                    <Checkbox checked={isSelected} className='mr-2' />
                                    <span className='text-sm font-medium'>
                                        {constructFullName(user, 'firstMiddleLast')}
                                    </span>
                                </Button>
                            );
                        })}
                    </div>}
                </div>
            </PopoverContent>
        </Popover>
    );
});

SelectUser.displayName = 'SelectUser';

export default SelectUser;
