import React from 'react';
import { makeStyles, Tabs } from '@material-ui/core';
import SubheaderItem from './SubheaderItem';
import LucidocColors from '../../../constants/LucidocColors';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export const subheaderHeight = '48px';

interface SubheaderProps {
    value: string
    onChange: (arg0: string) => void
    children?: React.ReactElement<typeof SubheaderItem>[]
    lastElement?: React.ReactElement
    shrinkLastElement?: boolean
    lastElementZIndex?: number
}

// the <Tabs> element needs special syntax, and cannot have anything else in it, so we need two separate sets of classes:
const useStylesForTabs = makeStyles({
    indicator: {
        backgroundColor: LucidocColors.purple,
    }
});

const useStylesForEverythingElse = makeStyles({
    mainDiv: {
        minHeight: subheaderHeight,
        boxShadow: `0 .2rem .7rem .1rem ${LucidocColors.gray}`,
        backgroundColor: LucidocColors.white,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    lastElementSpan: {
        minWidth: '20%',
        padding: '0 .5rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    }
});

export default function Subheader(props: SubheaderProps) {
    const classesForTabs = useStylesForTabs({});
    const classesForEverythingElse = useStylesForEverythingElse({});

    const spanStyles = {
        position: 'relative' as CSSProperties['position'],
        zIndex: props.lastElementZIndex ?? 10000,
    };

    if (props.shrinkLastElement) {
        spanStyles['width'] = 'auto';
    }

    return (
        <div className={classesForEverythingElse.mainDiv}>
            <Tabs
                value={props.value}
                onChange={(event, value) => props.onChange(value)}
                classes={classesForTabs}
            >
                {props.children}
            </Tabs>

            <span
                className={props.lastElement ? classesForEverythingElse.lastElementSpan : ''}
                style={spanStyles}
            >
                {props.lastElement}
            </span>
        </div>
    );
}
