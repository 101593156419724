import React, { useEffect, useState } from 'react';
import Subheader from '../../../../_shared/Subheader/Subheader';
import SubheaderItem from '../../../../_shared/Subheader/SubheaderItem';
import axios from 'axios';
import { MUIDataTableProps } from 'mui-datatables';
import { LoadingIndicator } from '../../../../_shared/LoadingIndicator';
import DataTable from '../../../../_shared/DataTable/DataTable';
import { BATCH_JOB, BatchJobTypes } from '../../../../../entities/org/BATCH_JOB';
import { createSortableDate } from '../../../../../_shared/utils/docManagerFunctions';
import TransferHistoryDetail from './components/TransferHistoryDetail';
import { TableCell, TableRow } from '@material-ui/core';
import { TemporaryFavoritesStar } from '../../../../_shared/TemporaryFavoritesStar/TemporaryFavoritesStar';
import { setTitleInAdmin } from '../../../../../utils/setTitleInAdmin';
import { LoadingStatuses } from '../../../../../utils/LoadingStatuses';
import SaveBar from '../../../../_shared/SaveBar/SaveBar';
import { redirectToHomepage } from '../../../../../utils/savebarUtilities';
import setWindowTitle from '../../../../../utils/setWindowTitle';
import { constructFullName } from '@/components/UserPicker/utils/constructFullName';

export default function DocumentTransferHistory() {
    const Tabs: { [key: string]: string } = {
        Acknowledgements: 'Acknowledgements',
        Collaborators: 'Collaborators',
        Department: 'Department',
        'Document Type': 'Document Type',
        Owner: 'Owner',
        Signers: 'Signers',
    };

    const [loadingStatus, setLoadingStatus] = useState<LoadingStatuses>(LoadingStatuses.LOADING);
    const [selectedTab, setSelectedTab] = useState<string>(Tabs.Acknowledgements);
    const [tableData, setTableData] = useState<MUIDataTableProps['data']>([]);

    useEffect(() => {
        const newTitle = 'Transfer History';
        setWindowTitle(newTitle);
        setTitleInAdmin(newTitle);
        getTransferHistoryFor().then();
    }, [selectedTab]);

    async function getTransferHistoryFor() {
        setLoadingStatus(LoadingStatuses.LOADING);

        const res = await axios.get('/api/administration/documents/transfer-history', {
            params: {
                jobType: selectedTab === Tabs.Acknowledgements
                    ? BatchJobTypes.transferAckSchedules // abbreviated in the UI, need full string when querying on the back end
                    : `Transfer ${selectedTab}` // all others follow a standard pattern
            }
        });

        const newTableData = (res.data.transferHistory as BATCH_JOB[]).map(job => {
            return {
                ID: job.ID,
                // 'Job Type': job.JOB_TYPE, // maybe include if there's an ALL option
                'Item Count': job.DOCUMENT_CHANGES
                    ? JSON.parse(job.DOCUMENT_CHANGES).length
                    : 0,
                'Transfer Date': job.COMPLETED ? createSortableDate(new Date(job.COMPLETED)) : '',
                'Job Owner': job.JOB_OWNER ? constructFullName(job.JOB_OWNER, 'lastFirstMiddle') : '',
            };
        });

        setTableData(newTableData);
        setLoadingStatus(LoadingStatuses.READY);

    }

    return (
        <div>
            <Subheader
                value={selectedTab}
                onChange={value => setSelectedTab(value)}
                lastElement={
                    <TemporaryFavoritesStar targetPathName={'admin/admin.pl?repname=transfer_history'} />
                }
                shrinkLastElement={true}
            >
                {Object.keys(Tabs).map(tab => <SubheaderItem key={Tabs[tab]} label={Tabs[tab]} value={Tabs[tab]} />)}
            </Subheader>

            {loadingStatus !== LoadingStatuses.READY &&
                <LoadingIndicator />
            }

            {loadingStatus === LoadingStatuses.READY &&
                <DataTable
                    muiDataTableProps={{
                        title: `${selectedTab} Transfer History`,
                        columns: tableData[0] && Object.keys(tableData[0]),
                        data: tableData,
                        options: {
                            expandableRows: true,
                            renderExpandableRow: (rowData: string[]) => {
                                const jobID = rowData[0];

                                return (
                                    <TableRow>
                                        <TableCell colSpan={tableData[0] && Object.keys(tableData[0]).length + 1}>
                                            <TransferHistoryDetail jobID={jobID} />
                                        </TableCell>
                                    </TableRow>
                                );
                            }
                        }
                    }}
                />
            }
            <SaveBar onClose={() => redirectToHomepage()}/>
        </div>
    );
}
