import {
    Entity,
    JoinColumn,
    ManyToOne,
    PrimaryColumn,
} from 'typeorm';
import { PERSON,  } from '../master/PERSON';
import { SchemaEntityManager } from '../SchemaEntityManager';

export enum ContactTypes {
    admin    = 'admin',
    support  = 'support',
    feedback = 'feedback',
    homepage = 'homepage',
}

export type HomepageContactsGroup = {
    subtype: string,
    contacts: CONTACTS[]
}

// Factory function to create CONTACTS entity with dynamic schema
function createContactsEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('CONTACTS', { schema })
    class CONTACTS {
        static SCHEMANAME = schema;
        @PrimaryColumn('varchar2', { nullable: false, length: 20, name: 'TYPE' })
        TYPE!: string;

        @PrimaryColumn('number', { nullable: false, precision: 10, scale: 0, name: 'USERID' })
        USERID!: number;

        @ManyToOne(
            () => manager.getPersonEntity(schema), 
            (person) => person.CONTACTS
        )
        @JoinColumn({ name: 'USERID' })
        USER!: PERSON | null;

        @PrimaryColumn('varchar2', { nullable: false, length: 64, default: () => 'none', name: 'SUBTYPE' })
        SUBTYPE!: string | null;
    }

    return CONTACTS;
}

export { createContactsEntity };
export type CONTACTS = InstanceType<ReturnType<typeof createContactsEntity>>;
