import React, { useContext, useEffect, useState } from 'react';
import H3WithHelpTip from '../../../../_shared/Forms/H3WithHelpTip';
import DataTable from '../../../../_shared/DataTable/DataTable';
import { createSortableDate } from '../../../../../_shared/utils/docManagerFunctions';
import axios from 'axios';
import { LoadingIndicator } from '../../../../_shared/LoadingIndicator';
import { LoadingStatuses } from '../../../../../utils/LoadingStatuses';
import UserBundleContext from '../../../../../context/UserBundleContext';
import { CONSENT_AGENDA } from '../../../../../entities/org/CONSENT_AGENDA';
import { COMMITTEE } from '../../../../../entities/org/COMMITTEE';
import { onStorageUpdate } from '../CommitteeEditor';
import { redirectToHomepage } from '../../../../../utils/savebarUtilities';

interface CommitteeArchivedAgendasProps {
    committeeId: number;
}

const CommitteeArchivedAgendas: React.FC<CommitteeArchivedAgendasProps> = (props: CommitteeArchivedAgendasProps) => {
    const context = useContext(UserBundleContext);
    const organizationID = context.organization?.ORGANIZATIONID;
    const orgPrefix = context.organization?.PREFIX;

    const [loadingStatus, setLoadingStatus] = useState(LoadingStatuses.MOUNTING);

    const [committee, setCommittee] = useState<COMMITTEE>();

    const [archivedConsentAgendas, setArchivedConsentAgendas] = useState<CONSENT_AGENDA[]>([]);


    const loadInitialData = async () => {
        try {
            const res = await axios.get(`/api/committee/${ props.committeeId }`);

            setCommittee(res.data.committee);

            const resArchivedConsentAgendas = await axios.get(`/api/committee/${ props.committeeId }/consent-agenda/archived`);
            setArchivedConsentAgendas(resArchivedConsentAgendas.data.consentAgendas);

            setLoadingStatus(LoadingStatuses.READY);
        } catch (e) {
            redirectToHomepage();
        }
    };

    useEffect(() => {
        if (
            loadingStatus === LoadingStatuses.LOADING ||
            loadingStatus === LoadingStatuses.MOUNTING
        ) {
            loadInitialData().then();
        }
    }, [loadingStatus]);

    const isChairPersonOrSecretary = (
        context.user?.USERID === committee?.SECRETARY_USERID ||
        context.user?.USERID === committee?.CHAIRPERSON_USERID
    );
    const isCollaborator = (committee?.COLLABORATORS ?? []).some(collaborator => collaborator.USERID === context.user?.USERID);

    const formattedArchivedAgendasTableData = archivedConsentAgendas
        .map(consentAgenda => {
            const consentAgendaID = consentAgenda.CONSENT_AGENDA_ID;
            const date = new Date(consentAgenda.MEETING_START_DATE);
            const minutesDocID = consentAgenda.MINUTES_DOCID;
            const consentAgendaHREF = `/docmgr2/consent_agenda_viewing.pl?organizationid=${organizationID}&consent_agenda_id=${consentAgendaID}`;

            return {
                View: <a
                    style={{ textDecoration: 'none' }}
                    target='_blank'
                    href={consentAgendaHREF}
                    onClick={
                        event => {
                            event.preventDefault();
                            window.open(
                                consentAgendaHREF,
                                'consent_agenda_management' + consentAgenda.CONSENT_AGENDA_ID,
                                'dependent,resizable,scrollbars=yes'
                            );
                        }
                    }
                >
                    View Agenda
                </a>,
                Year: date.getFullYear(),
                Date: createSortableDate(date, true),
                Minutes: <a
                    style={{ textDecoration: 'none' }}
                    target='_blank'
                    href={`/cgi/doc-gw.pl?full=1&wnd=1&ref=${orgPrefix}:${minutesDocID}`}
                >
                    View minutes
                </a>,
                Unarchive: <a
                    style={{ textDecoration: 'none' }}
                    target='_blank'
                    href='#'
                    onClick={
                        async event => {
                            event.preventDefault();
                            if (isChairPersonOrSecretary || isCollaborator) {
                                await axios.post(`/api/committee/${props.committeeId}/consent-agenda/${consentAgendaID}/unarchive`);
                                setLoadingStatus(LoadingStatuses.LOADING);
                            }
                        }
                    }
                >
                    Unarchive
                </a>
            };
        });

    useEffect(() => {
        window.addEventListener('storage', onStorageUpdate(setLoadingStatus));
        return () => {
            window.removeEventListener('storage', onStorageUpdate(setLoadingStatus));
        };
    }, []);

    if (loadingStatus !== LoadingStatuses.READY) {
        return <LoadingIndicator />;
    }

    return <div>
        <div
            style={{
                paddingTop: '1.5rem',
                paddingLeft: '1rem'
            }}
        >
            <H3WithHelpTip
                helpText='View archived agendas'
                text='Archived Agendas'
            />
        </div>
        <DataTable
            muiDataTableProps={{
                data: formattedArchivedAgendasTableData,
                title: 'Archived Consent Agendas',
                columns: formattedArchivedAgendasTableData[0] &&
                    Object.keys(formattedArchivedAgendasTableData[0]).map(key => ({
                        options: {
                            filter: key === 'Year'
                        },
                        name: key
                    }))
            }}
        />
    </div>;

};

export default CommitteeArchivedAgendas;
