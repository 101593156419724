import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/lib/utils';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from './dialog';

const buttonVariants
    = cva(
        'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors disabled:pointer-events-none disabled:opacity-50 text-foreground',
        {
            variants: {
                variant: {
                    default: 'bg-primary text-primary-foreground hover:bg-primary/90',
                    destructive:
                        'bg-destructive text-destructive-foreground hover:bg-destructive/90',
                    outline:
                        'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
                    secondary:
                        'bg-secondary text-secondary-foreground hover:bg-secondary/90',
                    ghost: 'hover:bg-accent hover:text-accent-foreground',
                    link: 'text-primary underline-offset-4 hover:underline',
                },
                size: {
                    default: 'h-10 px-4 py-2',
                    sm: 'py-1 rounded-md px-3',
                    md: 'py-1.5 rounded-md px-3',
                    lg: 'h-11 rounded-md px-8',
                    icon: 'h-8 w-8',
                },
            },
            defaultVariants: {
                variant: 'default',
                size: 'default',
            },
        }
    );

export interface ButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
    asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    ({ className, variant, size, asChild = false, ...props }, ref) => {
        const Comp = asChild ? Slot : 'button';
        
        return (
            <Comp
                className={cn(buttonVariants({ variant, size, className }))}
                ref={ref}
                {...props}
            />
        );
    }
);
Button.displayName = 'Button';

export interface ButtonWithConfirmProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
    asChild?: boolean
    confirm?: {
        title: string
        body: string
        onConfirm: () => Promise<void>
    }
}

const ButtonWithConfirm = React.forwardRef<HTMLButtonElement, ButtonWithConfirmProps>(
    ({ className, variant, size, asChild = false, ...props }, ref) => {
        const Comp = asChild ? Slot : 'button';
        
        const [confirmOpen, setConfirmOpen] = React.useState(false);
        
        return (
           <>
                <Button
                    className={cn(buttonVariants({ variant, size, className }))}
                    ref={ref}
                    onClick={ 
                        () => setConfirmOpen(true)
                    }
                >
                    {props.children}
                </Button>
                {
                    props.confirm &&
                    <Dialog
                        open={confirmOpen}
                        onOpenChange={() => {
                            setConfirmOpen(prev => !prev);
                        }}  
                    >
                        <DialogContent>
                            <DialogHeader>
                                <DialogTitle>{props.confirm?.title}</DialogTitle>
                            </DialogHeader>
                            <DialogDescription>{props.confirm?.body}</DialogDescription>
                            <div className='flex flex-row w-full justify-end space-x-2'>
                                <Button 
                                    variant='destructive'
                                    onClick={
                                        () => props.confirm?.onConfirm()
                                            .then(() => setConfirmOpen(false)) 
                                    }
                                    >
                                    Confirm
                                </Button>
                            </div>
                        </DialogContent>
                    </Dialog>
                }
                {
                    !props.confirm &&
                    <Comp
                        className={cn(buttonVariants({ variant, size, className }))}
                        ref={ref}
                        {...props}
                    />
                }
           </> 
        );
    }
);
ButtonWithConfirm.displayName = 'ButtonWithConfirm';

export { Button, ButtonWithConfirm, buttonVariants };
