import {
    Column,
    Entity,
    OneToOne,
    PrimaryColumn,
} from 'typeorm';
import { SUB_REVISION,  } from './SUB_REVISION';
import { INFORMATION_HIERARCHY,  } from './INFORMATION_HIERARCHY';
import { SchemaEntityManager } from '../SchemaEntityManager';

function createAllStoreEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('ALL_STORE', { schema: schema })
    class ALL_STORE {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'BLOBID',
        })
        BLOBID!: number;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'DOCREVID',
        })
        DOCREVID!: number | null;

        @Column('char', {
            nullable: true,
            name: 'PRIMARY',
        })
        PRIMARY!: string | null;

        @Column('char', {
            nullable: true,
            name: 'SOURCEFORM',
        })
        SOURCEFORM!: string | null;

        @Column('char', {
            nullable: true,
            name: 'DISPLAYFORM',
        })
        DISPLAYFORM!: string | null;

        @Column('char', {
            nullable: true,
            name: 'INACTIVE',
        })
        INACTIVE!: string | null;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'MIMETYPE_ID',
        })
        MIMETYPE_ID!: number | null;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'TEMPLATEID',
        })
        TEMPLATEID!: number | null;

        @Column('varchar2', {
            nullable: true,
            length: 256,
            name: 'FILENAME',
        })
        FILENAME!: string | null;

        @Column('blob', {
            nullable: true,
            name: 'INSTANCE',
        })
        INSTANCE!: Buffer | null;

        @Column('blob', {
            nullable: true,
            name: 'GRAPHIC',
        })
        GRAPHIC!: Buffer | null;

        @Column('char', {
            nullable: true,
            length: 2,
            name: 'LANGUAGE_ISO',
        })
        LANGUAGE_ISO!: string | null;

        @Column('date', {
            nullable: true,
            name: 'CHANGEDDT',
        })
        CHANGEDDT!: Date | null;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'USERID',
        })
        USERID!: number | null;

        @Column('varchar2', {
            nullable: true,
            length: 4000,
            name: 'REVISION_NOTE',
        })
        REVISION_NOTE!: string | null;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'DOCSTATUS_ID',
        })
        DOCSTATUS_ID!: number | null;

        @Column('varchar2', {
            nullable: true,
            length: 10,
            name: 'FMT',
        })
        FMT!: string | null;

        HTML!: string | null;

        @OneToOne(
            () => manager.getSubRevisionEntity(schema),
            (subRevision) => subRevision.ALL_STORE
        )
        SUB_REVISION!: SUB_REVISION | null;

        @OneToOne(
            () => manager.getInformationHierarchyEntity(schema),
            (informationHierarchy) => informationHierarchy.IMAGE
        )
        DEPARTMENT!: INFORMATION_HIERARCHY | null;
    }

    return ALL_STORE;
}

export { createAllStoreEntity };
export type ALL_STORE = InstanceType<ReturnType<typeof createAllStoreEntity>>;
