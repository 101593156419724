import { ChevronDownIcon, ChevronRightIcon } from '@radix-ui/react-icons';
import { Table } from '@tanstack/react-table';
import React from 'react';
import { Button } from '../button';

interface DataTableExpanderProps<TData> extends React.HTMLAttributes<HTMLDivElement> {
    table: Table<TData>
}

export const TableExpander = <TData,>({ table }: DataTableExpanderProps<TData>) => {
    return (
            <Button
                className='w-6 h-6'
                variant={'ghost'}
                size={'icon'}
                onClick={() => table.toggleAllRowsExpanded()}
            >
                {table.getIsAllRowsExpanded() ? <ChevronDownIcon width={20} height={20} className='text-secondary' /> : <ChevronRightIcon height={20} width={20} className='text-secondary' />}
            </Button>
    );
};
