import React, { useContext } from 'react';

import UserBundleContext from '@/context/UserBundleContext';

import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/ui/ui/select';

import { Button } from '@/ui/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '@/ui/ui/dialog';
import { useField, useForm } from '@tanstack/react-form';
import axios from 'axios';
import { COMMITTEE } from '@/entities/org/COMMITTEE';
import { Spinner } from '@/ui/ui/spinner';
import { constructFullName } from '@/components/UserPicker/utils/constructFullName';
import { MultipleUserPicker } from '@/ui/ui/user-picker';
import { RightsCodeKey } from '@/entities/master/PERSON';

interface CreateMinutesTemplateDialogProps {
    onCreate: () => void;
    onClose: () => void;
    isOpen: boolean;
    committee: COMMITTEE;
}

const templateOptions = [
    {
        label: 'Committee Minutes (HTML)',
        value: 144
    },
    {
        label: 'Committee Minutes (Word)',
        value: 18
    },
    {
        label: 'Committee Minutes (PDF)',
        value: 1
    }
];

const DEFAULT_MIMETYPE_ID = 144;

export const CreateMinutesTemplateDialog: React.FC<CreateMinutesTemplateDialogProps> = (props: CreateMinutesTemplateDialogProps) => {
    const context = useContext(UserBundleContext);

    const userOptions = [
        {
            label: props.committee.CHAIRPERSON ? constructFullName(props.committee.CHAIRPERSON, 'firstMiddleLast') : '',
            value: props.committee.CHAIRPERSON!.USERID
        },
        {
            label: props.committee.SECRETARY ? constructFullName(props.committee.SECRETARY, 'firstMiddleLast') : '',
            value: props.committee.SECRETARY!.USERID
        }
    ];

    const form = useForm<{
        owner_userid: number
        mimetype_id: number
        collaborator_userids: number[]
    }>({
        defaultValues: {
            owner_userid: props.committee.SECRETARY_USERID,
            mimetype_id: DEFAULT_MIMETYPE_ID,
            collaborator_userids: [],
        },
        onSubmit: async (values) => {
            const data = values.value;

            const formData = new FormData();
            formData.append('ajax', 'create_minutes_default_content_document');
            formData.append('organizationid', `${context.organization!.ORGANIZATIONID}`);
            formData.append('committee_id', `${props.committee.COMMITTEE_ID}`);
            formData.append('owner_userid', data.owner_userid.toString());
            formData.append('mimetype_id', data.mimetype_id.toString());
            formData.append('collaborator_userids', JSON.stringify(data.collaborator_userids));

            const res = await axios.post(
                '/ajax/committee.pl',
                formData,
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }
            );

            window.open(
                res.data.edit_minutes_default_content_document_url,
                '_blank'
            );

            props.onCreate();
        }
    });

    const ownerUserID = useField({ form, name: 'owner_userid' });

    return (
        <Dialog
            open={props.isOpen}
            onOpenChange={() => props.onClose()}
        >
            <DialogTrigger asChild>
            </DialogTrigger>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Create Minutes Template</DialogTitle>
                </DialogHeader>
                <form className='w-full flex flex-col h-full items-start space-y-4'
                    onSubmit={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        form.handleSubmit();
                    }}
                >
                    <Section>
                        <p>Title:</p>
                        <p>{props.committee?.NAME} Minutes Template</p>
                    </Section>
                    <Section>
                        <p>Department:</p>
                        <p>{props.committee?.MINUTES_DEPARTMENT?.TITLE}</p>
                    </Section>
                    <Section>
                        <p>Document Type:</p>
                        <p>Committee Minutes</p>
                    </Section>
                    <Section>
                        <p>Document Owner:</p>
                        <form.Field
                            name={'owner_userid'}
                            children={(field) => {
                                return <Select
                                    onValueChange={(v) => field.handleChange(parseInt(v))}
                                    value={field.getValue().toString()}
                                >
                                    <SelectTrigger className="w-full">
                                        <SelectValue />
                                    </SelectTrigger>
                                    <SelectContent>
                                        {
                                            userOptions.map((o) => {
                                                return <SelectItem key={o.value} value={o.value.toString()}>{o.label}</SelectItem>;
                                            })
                                        }
                                    </SelectContent>
                                </Select>;
                            }}
                        />
                    </Section>
                    <Section>
                        <p>Document Template:</p>
                        <form.Field
                            name={'mimetype_id'}
                            children={(field) => {
                                return <Select
                                    onValueChange={(v) => field.handleChange(parseInt(v))}
                                    value={field.getValue().toString()}
                                >
                                    <SelectTrigger className="w-full">
                                        <SelectValue />
                                    </SelectTrigger>
                                    <SelectContent>
                                        {
                                            templateOptions.map((o) => {
                                                return <SelectItem key={o.value} value={o.value.toString()}>{o.label}</SelectItem>;
                                            })
                                        }
                                    </SelectContent>
                                </Select>;
                            }}
                        />
                    </Section>
                    <Section>
                        <p>Collaborators:</p>
                        <form.Field
                            name={'collaborator_userids'}
                            children={(field) => {
                                return <MultipleUserPicker
                                    inputType='number'
                                    selectedUsers={field.getValue()}
                                    setSelectedUsers={v => field.handleChange(v as number[])}
                                    exemptUsers={[ownerUserID.getValue()]}
                                    rights={[RightsCodeKey.Author]}
                                    placeholder='No Collaborators'
                                    getMappedUsers
                                />;
                            }}
                        />
                    </Section>
                    <div className='pt-4 flex flex-row w-full'>
                        <form.Subscribe>
                            {
                                formState => 
                                    <Button
                                        className='w-full mx-1'
                                        type={'submit'}
                                    >
                                        {
                                            formState.isSubmitting ? <Spinner /> : 'Create'
                                        }
                                    </Button>
                            }
                        </form.Subscribe>
                        <Button 
                            className='w-full mx-1'
                            variant={'secondary'} 
                            type={'button'}
                            onClick={() => props.onClose()}
                        >
                            Close
                        </Button>
                    </div>
                </form>
            </DialogContent>
        </Dialog >
    );
};


const Section = ({ children }) => {
    return <div className='flex flex-col w-full'>
        {children}
    </div>;
};