import React from 'react';

interface ChevronDownProps {
    size: number;
}

export const ChevronDownOutlineIcon: React.FC<ChevronDownProps> = ({ size }) => {
    return (
        <svg width={size.toString()} height={size.toString()} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="16" height="16"  />
            <path d="M4.56641 6L8.06641 8.22222L11.5664 6V7.77778L8.06641 10L4.56641 7.77778V6Z" fill="hsl(var(--foreground))"/>
        </svg>
    );
};