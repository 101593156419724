import React, { useContext, useEffect, useState } from 'react';

import { LoadingIndicator } from '../../../../_shared/LoadingIndicator';
import { LoadingStatuses } from '../../../../../utils/LoadingStatuses';
import axios from 'axios';
import DataTable from '../../../../_shared/DataTable/DataTable';
import { CONSENT_AGENDA, ConsentAgendaStatuses } from '../../../../../entities/org/CONSENT_AGENDA';
import { createSortableDate } from '../../../../../_shared/utils/docManagerFunctions';
import UserBundleContext from '../../../../../context/UserBundleContext';

const MyMeetings: React.FC = () => {
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatuses>(LoadingStatuses.MOUNTING);

    const context = useContext(UserBundleContext);

    const [pastConsentAgendas, setPastConsentAgendas] = useState<CONSENT_AGENDA[]>([]);
    const [futureConsentAgendas, setFutureConsentAgendas] = useState<CONSENT_AGENDA[]>([]);

    const loadInitialData = async () => {
        const resPast = await axios.get('/api/consent-agenda/my-meetings/past');
        const resFuture = await axios.get('/api/consent-agenda/my-meetings/future');
        setPastConsentAgendas(resPast.data.consentAgendas);
        setFutureConsentAgendas(resFuture.data.consentAgendas);
        setLoadingStatus(LoadingStatuses.READY);
    };

    useEffect(() => {
        if (
            loadingStatus === LoadingStatuses.LOADING ||
            loadingStatus === LoadingStatuses.MOUNTING
        ) {
            loadInitialData().then();
        }
    }, [loadingStatus]);

    const formattedPastConsentAgendas = pastConsentAgendas
        .map(
            consentAgenda => {
                return {
                    Committee: <a
                        style={{ textDecoration: 'none' }}
                        target='_blank'
                        href={ `/docmgr2/consent_agenda_viewing.pl?organizationid=${ context.organization?.ORGANIZATIONID }&consent_agenda_id=${ consentAgenda.CONSENT_AGENDA_ID }` }
                    >
                        { consentAgenda.COMMITTEE?.NAME }
                    </a>,
                    Date: createSortableDate(new Date(consentAgenda.MEETING_START_DATE)),
                    Year: createSortableDate(new Date(consentAgenda.MEETING_START_DATE)).split('-')[0]
                };
            }
        );

    const formattedFutureConsentAgendas = futureConsentAgendas
        .map(
            consentAgenda => {
                const notReleasedAndNotChairOrSecretary = (
                    consentAgenda.STATUS === ConsentAgendaStatuses.invisible_and_mutable &&
                    context.user?.USERID !== consentAgenda.COMMITTEE?.CHAIRPERSON_USERID &&
                    context.user?.USERID !== consentAgenda.COMMITTEE?.SECRETARY_USERID
                );

                const releasedOrNotReleasedButChairOrSecretary = (
                    consentAgenda.STATUS !== ConsentAgendaStatuses.invisible_and_mutable ||
                    (
                        consentAgenda.STATUS === ConsentAgendaStatuses.invisible_and_mutable &&
                        (
                            context.user?.USERID === consentAgenda.COMMITTEE?.CHAIRPERSON_USERID ||
                            context.user?.USERID === consentAgenda.COMMITTEE?.SECRETARY_USERID
                        )
                    )
                );

                let link: React.ReactNode;

                if (notReleasedAndNotChairOrSecretary) {
                    link = <span>{ consentAgenda.COMMITTEE?.NAME } (Unreleased)</span>;
                }

                if (releasedOrNotReleasedButChairOrSecretary) {
                    link = <a
                        style={{
                            textDecoration: 'none'
                        }}
                        target='_blank'
                        href={ `/docmgr2/consent_agenda_viewing.pl?organizationid=${ context.organization?.ORGANIZATIONID }&consent_agenda_id=${ consentAgenda.CONSENT_AGENDA_ID }` }
                    >
                        { consentAgenda.COMMITTEE?.NAME } { consentAgenda.STATUS === ConsentAgendaStatuses.invisible_and_mutable && '(Unreleased)' }
                    </a>;
                }

                return {
                    Committee: link,
                    Date: createSortableDate(new Date(consentAgenda.MEETING_START_DATE))
                };
            }
        );

    return <div>
        {
            loadingStatus !== LoadingStatuses.READY &&
                <LoadingIndicator />
        }
        {
            loadingStatus === LoadingStatuses.READY &&
                <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: '50% 50%'
                    }}
                >
                    <DataTable
                        muiDataTableProps={{
                            data: formattedFutureConsentAgendas,
                            title: 'Upcoming Meetings',
                            columns: formattedFutureConsentAgendas[0] &&
                                Object.keys(formattedFutureConsentAgendas[0]).map(key => ({
                                    name: key
                                }))
                        }}
                    />
                    <DataTable
                        muiDataTableProps={{
                            data: formattedPastConsentAgendas,
                            title: 'Past Meetings',
                            columns: formattedPastConsentAgendas[0] &&
                                Object.keys(formattedPastConsentAgendas[0]).map(key => ({
                                    options: {
                                        filter: key === 'Year'
                                    },
                                    name: key
                                }))
                        }}
                    />
                </div>
        }
    </div>;

};

export default MyMeetings;
