import { useEffect, useState } from 'react';

import axios from 'axios';

import { PERSON, rightsCodes, PersonStatuses as PersonStatuses } from '../entities/master/PERSON';

export type UserSearchPerson = Pick<
    PERSON,
    'USERID' |
    'FIRSTNAME' |
    'MIDDLEINITIALS' |
    'LASTNAME' |
    'RIGHTS' |
    'TITLE' |
    'STATUSID'
> & {
    person: PERSON;
};

const useUserSearch = (
    searchQuery: string, // the value given from an input to query against
    userIds: number[], // userIds that must be included in the search results,
    userStatusIds: PersonStatuses[] = [PersonStatuses.ACTIVE],
    rights: (keyof typeof rightsCodes)[] = [],
    selectAmount: number = -1, // defaults to ALL results if not given
    exemptUserIds: number[] = [], // userIds you do not want coming through in the results
    getMappedUsers?: boolean
): [
        UserSearchPerson[] | undefined,
        boolean
    ] => {

    const [prevInput, setPrevInput] = useState<string>('');
    const [prevExemptUserIds, setPrevExemptUserIds] = useState<number[]>([]);
    const [searching, setSearching] = useState(false);

    const [prevUsers, setPrevUsers] = useState<UserSearchPerson[] | undefined>(undefined);
    const [users, setUsers] = useState<UserSearchPerson[] | undefined>(undefined);

    useEffect(() => {
        const getUsers = async () => {
            const exemptHasNotChanged = (
                prevExemptUserIds.length === exemptUserIds.length &&
                prevExemptUserIds.every(userId => exemptUserIds.includes(userId))
            );
            if (
                prevInput.length < searchQuery.length &&
                exemptHasNotChanged &&
                (
                    // This checks if theres no more queried data to the search criteria
                    (
                        users?.length === userIds.length
                    ) ||
                    // This looks to see if we are getting any new results or we can go off old
                    (
                        prevUsers &&
                        users &&
                        prevUsers.length > users.length
                    )
                )
            ) {
                setPrevInput(searchQuery);
                return;
            }
            if (
                searchQuery !== prevInput ||
                (
                    searchQuery === '' &&
                    users === undefined
                ) ||
                !exemptHasNotChanged
            ) {
                setSearching(true);
                const res = await axios.get(
                    '/api/userpicker/search',
                    {
                        params: {
                            userIds,
                            exemptUserIds,
                            userStatusIds,
                            searchQuery,
                            selectAmount,
                            rights,
                            getMappedUsers,
                        }
                    }
                );
                setPrevUsers(users);
                setUsers(res.data.users.map(u => {
                    return {
                        ...u,
                        person: u
                    };
                }) || []);
                setSearching(false);
                setPrevInput(searchQuery);
                setPrevExemptUserIds(exemptUserIds);
            }
        };
        if (!searching) {
            getUsers();
        }
    }, [searchQuery, exemptUserIds]);

    if (!users) {
        return [undefined, searching];
    }

    return [users, searching];
};

export default useUserSearch;
