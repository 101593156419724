import { PERSON } from '../entities/master/PERSON';
import { ORGANIZATION } from '../entities/master/ORGANIZATION';
import { createContext } from 'react';
import { DEFAULT_PREFERENCES, Preferences } from '../_shared/utils/preferences-defs';


/**
 * This dumb file exists because there was originally a file called UserBundleContext.tsx;
 * but the .tsx isn't necessary on the server, and the server's tsconfig doesn't allow .tsx
 * files...so the file was changed from .tsx to .ts, which broke the build process. So now
 * there's this new file with a different name (as far as the computer is concerned) so the
 * extension renaming doesn't matter.
 */

export interface UserBundleType {
    user: PERSON | null,
    impersonator: PERSON | null,
    organization: ORGANIZATION | null,
    useImpersonatedRights: boolean | null,
    sentry: {
        environment: string,
        release: string,
    }
    version?: string | null,
    preferences: Preferences
}

const UserBundleContext = createContext<UserBundleType>({
    user: null,
    impersonator: null,
    organization: null,
    useImpersonatedRights: null,
    sentry: {
        environment: '',
        release: '',
    },
    version: null,
    preferences: DEFAULT_PREFERENCES
});

export default UserBundleContext;
