import React, { useEffect, useState } from 'react';
import { INFORMATION_HIERARCHY } from '../../../entities/org/INFORMATION_HIERARCHY';
import { ACL } from '../../../entities/org/ACL';
import { USERGROUP } from '../../../entities/org/USERGROUP';
import axios from 'axios';
import { AccessListSearchInputForm } from './AccessListSearchInputForm';
import { AccessListSearchResults } from './AccessListSearchResults';

export function AccessListSearchTool() {
    const [departments, setDepartments] = useState<INFORMATION_HIERARCHY[]>([]);
    const [userGroups, setUserGroups] = useState<USERGROUP[]>([]);
    const [manuals, setManuals] = useState<INFORMATION_HIERARCHY[]>([]);
    const [searchResults, setSearchResults] = useState<ACL[] | null>(null);

    useEffect(() => {
        axios.get('/api/internal-support/access-list-search')
            .then((response) => {
                const { departments, userGroups, manuals } = response.data;
                setDepartments(departments);
                setUserGroups(userGroups);
                setManuals(manuals);
            })
            .catch(console.log);
    }, []);

    const handleSearch = (
        departmentIDs: number[],
        manualIDs: number[],
        docID: string,
        userGroupIDs: number[],
        userID: string
    ) => {
        axios.post('/api/internal-support/access-list-search/search', {
            userID,
            userGroupIDs,
            docID,
            manualIDs,
            departmentIDs,
        })
            .then((response) => {
                const newSearchResults = response.data.searchResults;
                setSearchResults(newSearchResults);
            })
            .catch(console.log);
    };

    return (
        <>
            <h1>Access List Search Tool</h1>
            <p>This tool will allow Lucidoc to search through users' access rules for their assets.</p>
            <p>In order to search:</p>
            <ul>
                <li>Input a User ID and/or a Usergroup ID</li>
                <li>Input a Department, Manual, and/or Document</li>
            </ul>
            <AccessListSearchInputForm
                departments={departments}
                userGroups={userGroups}
                manuals={manuals}
                onSearch={handleSearch}
            />
            <br />
            <AccessListSearchResults results={searchResults} />
        </>
    );
}