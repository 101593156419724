import {
    Column,
    Entity,
    JoinColumn,
    ManyToOne,
    OneToMany,
    PrimaryColumn,
} from 'typeorm';

import { CONSENT_AGENDA_TASK,  } from './CONSENT_AGENDA_TASK';
import { CONSENT_AGENDA,  } from './CONSENT_AGENDA';
import { CONSENT_AGENDA_AUDIT_LOG,  } from './CONSENT_AGENDA_AUDIT_LOG';
import { SchemaEntityManager } from '../SchemaEntityManager';

export enum ConsentAgendaHeadingStatuses {
    ACTIVE = 0,
    REMOVED = 1
}

// Factory function to create CONSENT_AGENDA_HEADING entity with dynamic schema
function createConsentAgendaHeadingEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('CONSENT_AGENDA_HEADING', { schema })
    class CONSENT_AGENDA_HEADING {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            name: 'HEADING_ID'
        })
        HEADING_ID!: number;

        @Column('number', {
            nullable: false,
            name: 'CONSENT_AGENDA_ID'
        })
        CONSENT_AGENDA_ID!: number;

        @Column('number', {
            nullable: false,
            name: 'LIST_INDEX'
        })
        LIST_INDEX!: number;

        @Column('varchar2', {
            nullable: false,
            length: 100,
            name: 'LABEL'
        })
        LABEL!: string;

        @Column('varchar2', {
            nullable: true,
            length: 4000,
            name: 'ABSTRACT'
        })
        ABSTRACT!: string | null;

        @Column('number', {
            nullable: true,
            name: 'STATUS'
        })
        STATUS!: number | null;

        @OneToMany(
            () => manager.getConsentAgendaTaskEntity(schema),
            consentAgendaTask => consentAgendaTask.CONSENT_AGENDA_HEADING
        )
        CONSENT_AGENDA_TASKS!: CONSENT_AGENDA_TASK[] | null;

        @ManyToOne(
            () => manager.getConsentAgendaEntity(schema),
            consentAgenda => consentAgenda.CONSENT_AGENDA_HEADINGS
        )
        @JoinColumn({
            name: 'CONSENT_AGENDA_ID'
        })
        CONSENT_AGENDA!: CONSENT_AGENDA | null;

        @OneToMany(
            () => manager.getConsentAgendaAuditLogEntity(schema),
            consentAgendaAuditLog => consentAgendaAuditLog.CONSENT_AGENDA_HEADING
        )
        CONSENT_AGENDA_AUDIT_LOGS!: CONSENT_AGENDA_AUDIT_LOG[];

        static Statuses = ConsentAgendaHeadingStatuses;
    }

    return CONSENT_AGENDA_HEADING;
}

// Export the factory function and the type
export { createConsentAgendaHeadingEntity };
export type CONSENT_AGENDA_HEADING = InstanceType<ReturnType<typeof createConsentAgendaHeadingEntity>>;
