import React from 'react';
import { DOCUMENTREVISION } from '../../../../../../entities/org/DOCUMENTREVISION';
import { makeStyles } from '@material-ui/core/styles';
import { SingularDocumentInputForm } from './SingularDocumentInputForm';
import { DepartmentInputForm } from './DepartmentInputForm';
import { INFORMATION_HIERARCHY } from '../../../../../../entities/org/INFORMATION_HIERARCHY';

type UpdateDocrevReviewMetdataFormProps = {
    orgDepartmentAlias: string;
    departments: INFORMATION_HIERARCHY[];
    onEditDocRev: (docrevs: DOCUMENTREVISION[]) => void;
    onEditDepartment: (department: INFORMATION_HIERARCHY) => void;
};

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: '1rem',
    },
});

export function UpdateDocrevReviewMetadataForm(props: UpdateDocrevReviewMetdataFormProps) {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <SingularDocumentInputForm
                onEdit={(docRev) => props.onEditDocRev([docRev])}
            />
            <DepartmentInputForm
                orgDepartmentAlias={props.orgDepartmentAlias}
                departments={props.departments}
                onEdit={props.onEditDepartment}
            />
        </div>
    );
}
