import {
    Column,
    Entity,
    JoinColumn,
    JoinTable,
    ManyToMany,
    ManyToOne,
    OneToMany,
    OneToOne,
    PrimaryColumn,
} from 'typeorm';
import { CONSENT_AGENDA_HEADING,  } from './CONSENT_AGENDA_HEADING';
import { COMMITTEE,  } from './COMMITTEE';
import { PERSON,  } from '../master/PERSON';
import { CONSENT_AGENDA_AUDIT_LOG,  } from './CONSENT_AGENDA_AUDIT_LOG';
import { type DOCUMENT,  } from './DOCUMENT';
import { SchemaEntityManager } from '../SchemaEntityManager';

export enum ConsentAgendaStatuses {
    invisible_and_mutable = 0,
    visible_and_mutable = 1,
    visible_and_immutable = 2,
}

// Factory function to create CONSENT_AGENDA entity with dynamic schema
function createConsentAgendaEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('CONSENT_AGENDA', { schema })
    class CONSENT_AGENDA {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', { nullable: false, name: 'CONSENT_AGENDA_ID' })
        CONSENT_AGENDA_ID!: number;

        @Column('number', { nullable: false, name: 'COMMITTEE_ID' })
        COMMITTEE_ID!: number;

        @ManyToOne(
            () => manager.getCommitteeEntity(schema),
            (COMMITTEE) => COMMITTEE.CONSENT_AGENDAS
        )
        @JoinColumn({ name: 'COMMITTEE_ID' })
        COMMITTEE!: COMMITTEE | null;

        @Column('timestamp', { nullable: false, name: 'MEETING_START_DATE' })
        MEETING_START_DATE!: Date;

        @Column('number', { nullable: false, name: 'STATUS' })
        STATUS!: number;

        @Column('number', { nullable: true, name: 'MINUTES_DOCID' })
        MINUTES_DOCID!: number | null;

        @OneToOne(
            () => manager.getDocumentEntity(schema)
        )
        @JoinColumn({ name: 'MINUTES_DOCID', referencedColumnName: 'DOCID' })
        MINUTES_DOC!: DOCUMENT;

        @Column('varchar2', { nullable: true, length: 2500, name: 'MEETING_LOCATION' })
        MEETING_LOCATION!: string | null;

        @Column('timestamp', { nullable: true, name: 'MEETING_END_DATE' })
        MEETING_END_DATE!: Date | null;

        @OneToMany(
            () => manager.getConsentAgendaHeadingEntity(schema),
            (CONSENT_AGENDA_HEADING) => CONSENT_AGENDA_HEADING.CONSENT_AGENDA
        )
        CONSENT_AGENDA_HEADINGS!: CONSENT_AGENDA_HEADING[] | null;

        @ManyToMany(
            () => manager.getPersonEntity(schema), 
            (PERSON) => PERSON.GUEST_AGENDAS
        )
        @JoinTable({
            name: 'CONSENT_AGENDA_GUEST',
            joinColumn: { name: 'CONSENT_AGENDA_ID', referencedColumnName: 'CONSENT_AGENDA_ID' },
            inverseJoinColumn: { name: 'USERID', referencedColumnName: 'USERID' }
        })
        GUESTS!: PERSON[];

        @OneToMany(
            () => manager.getConsentAgendaAuditLogEntity(schema),
            (consentAgendaAuditLog) => consentAgendaAuditLog.CONSENT_AGENDA
        )
        CONSENT_AGENDA_AUDIT_LOGS!: CONSENT_AGENDA_AUDIT_LOG[];
    }

    return CONSENT_AGENDA;
}

export { createConsentAgendaEntity };
export type CONSENT_AGENDA = InstanceType<ReturnType<typeof createConsentAgendaEntity>>;
