import {
    Column,
    Entity,
    JoinColumn,
    ManyToOne,
    PrimaryColumn,
} from 'typeorm';
import { SchemaEntityManager } from '../SchemaEntityManager';
import { CONVERSATION } from './CONVERSATION';

function createConversationPendingSolicitsEntity(schema: string, schemaEntityManager: SchemaEntityManager) {
    @Entity('CONVERSATION_PENDING_SOLICITS', { schema: schema })
    class CONVERSATION_PENDING_SOLICITS {
        @PrimaryColumn('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'CONVERSATIONID'
        })
        CONVERSATIONID!: number;

        @Column('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'USERID'
        })
        USERID!: number;

        @ManyToOne(
            () => schemaEntityManager.getConversationEntity(schema),
            (conversation) => conversation.CONVERSATION_PENDING_SOLICITS,
        )
        @JoinColumn({
            name: 'CONVERSATIONID'
        })
        CONVERSATION!: CONVERSATION | null;
    }

    return CONVERSATION_PENDING_SOLICITS;
}

export { createConversationPendingSolicitsEntity };
export type CONVERSATION_PENDING_SOLICITS = InstanceType<ReturnType<typeof createConversationPendingSolicitsEntity>>;