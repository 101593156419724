export function deepEqual(obj1: object, obj2: object): boolean {
    // Check if both are the same reference
    if (obj1 === obj2) {
      return true;
    }
  
    // Check if both are objects (not null) and of the same type
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
      return false;
    }
  
    // Get the keys of both objects
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
  
    // Check if they have the same number of keys
    if (keys1.length !== keys2.length) {
      return false;
    }
  
    // Compare all keys and their corresponding values
    for (const key of keys1) {
      // Type assertion needed since we're working with objects
      const val1 = (obj1 as any)[key];
      const val2 = (obj2 as any)[key];
  
      // Check if the key exists in both objects and compare the values recursively
      if (!keys2.includes(key) || !deepEqual(val1, val2)) {
        return false;
      }
    }
  
    return true;
  }