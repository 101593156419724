import { Button } from '@/ui/ui/button';
import { Input } from '@/ui/ui/input';
import axios from 'axios';
import { ArrowUp } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Remarkable } from 'remarkable';
interface EditDocAskLucyProps {
    match: any
}

export const EditDocAskLucy: React.FC<EditDocAskLucyProps> = ({ match }) => {


    interface EditDocAskLucyProps {
        title: string;
        content: string;
    }

    const [waiting, setWaiting] = useState(false);

    const md = new Remarkable();
    useEffect(() => {
        const fetchData = async () => {
            try {
                setWaiting(true);
                const response = await axios.get<EditDocAskLucyProps>('/api/doc-manager/document/llm/setup', {
                    params: {
                        docid: match.params.docid,
                        docrev: match.params.docrevid
                    }
                });
                setMessages([
                    {
                        role: 'system',
                        content: `Your name is Luci. This is a ChatBot.
                         You're helping someone with this document:
                         title: ${response.data.title}.
                         content: ${response.data.content}.
                         DO NOT discuss anything outside the subject of the document.
                         All responses need to be in markdown.`
                    }
                ]);
            } catch (error) {
                console.error(error);
            } finally {
                setWaiting(false);
            }
        };

        fetchData();
    }, [match.params.docid, match.params.docrevid]);



    interface Message {
        role: 'user' | 'assistant' | 'system';
        content: string;
    }

    const [messages, setMessages] = useState<Message[]>([]);

    const [input, setInput] = useState('');

    const submitMessages = (message: Message) => {
        const newMessages = messages.concat(message);
        axios.post('/api/doc-manager/document/llm/chat', {
            messages: newMessages
        }).then(resp => {
            setMessages([...newMessages, resp.data]);
        });
    };


    return (
        <div className='w-full bg-primary-foreground flex flex-col h-full py-2'>
            <div className='flex flex-row justify-center items-center w-full border-b-2 border-border'>
                <p className='text-xl text-primary p-2 font-semibold '>
                    Chatting With Luci
                </p>
            </div>
            <div className='flex-grow '>
                {/* Skip first message since its sys*/}
                {messages
                    .filter(msg => msg.role !== 'system')
                    .map((message, idx) => {
                        return (
                            <div key={idx} className='flex flex-col justify-center items-start m-4'>
                                <p className='text-xs font-semibold'>{message.role === 'user' ? 'You' : 'Luci'}</p>
                                <div className='text-sm' dangerouslySetInnerHTML={{
                                    __html: md.render(message.content)
                                }}>
                                </div>
                            </div>
                        );
                    })}
                {waiting && <p>Waiting for Lucy...</p>}
            </div>
            <form className='w-full flex justify-center flex-row'
                onSubmit={(e) => {
                    e.preventDefault();
                    setMessages([...messages, { role: 'user', content: input }]);
                    setInput('');
                    submitMessages({
                        role: 'user',
                        content: input
                    });
                }}
            >
                <div className='border-2 p-1 rounded-md w-full md:w-[500px] flex flex-row items-center justify-between'>
                    <Input placeholder='Send a message to Lucy...'
                        className='border-none p-1 bg-transparent w-full'
                        value={input}
                        variant={'ghost'}
                        onChange={e => setInput(e.target.value)}
                    />
                    <Button
                        type={'submit'}
                        variant={'ghost'}
                    >
                        <ArrowUp className='text-primary' />
                    </Button>
                </div>
            </form>
        </div>
    );
};