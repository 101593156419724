import Regexes from '../constants/Regexes';

export function grabBaseDomain(): string {
    try {
        // @ts-ignore
        const matches = window.location.href.match(Regexes.URLs.lucidocDomain);

        if (!matches) {
            return 'https://www.lucidoc.com';
        }

        return matches[0].replace(/\/$/, '');
    } catch (e) {
        // this will error out if this is used from the back-end. this is okay.
        return 'https://www.lucidoc.com';
    }
}

export function buildDocURL(
    prefix: string, // note that prefix is NOT databaseuid!
    docID: number,
    revisionNumber?: number,
    addDomain?: boolean,
    isFileDownload?: boolean
) {
    let docURL = `/cgi/doc-gw.pl?ref=${prefix}:${docID}`;
    if (typeof revisionNumber !== 'undefined') {
        docURL += `$${revisionNumber}`;
    }
    if (addDomain) {
        docURL = grabBaseDomain() + docURL;
    }
    if (isFileDownload) {
        docURL += '&display=download_doc';
    }
    return docURL;
}

export function buildDocEditURL(prefix: string, docID: number, revisionNumber?: number, addDomain?: boolean) {
    // note that prefix is NOT databaseuid!
    let docURL = `/docmgr2/editdoc.pl?wnd=1&dept_archive_report=0&ref=${prefix}:${docID}`;
    if (typeof revisionNumber !== 'undefined') {
        docURL += `$${revisionNumber}`;
    }
    if (addDomain) {
        docURL = grabBaseDomain() + docURL;
    }
    return docURL;
}

export function buildHelpDocURL(helpTopicDocID: number, isJobAid?: boolean, includeDomain?: boolean) {
    const plTarget = isJobAid // job aids are supposed to be printable, so we want to exclude the sidebar
        ? 'doc-gw' // exclude sidebar
        : 'help';   // include sidebar

    const domain = includeDomain
        ? grabBaseDomain()          // if you're sending an email, you'll need this in front
        : '';                       // for use within the application, not an email

    return `${domain}/cgi/${plTarget}.pl?ref=tuhelp:${helpTopicDocID}`;
}

export function buildManualURL(manualID: number) {
    return `/cgi/cat-list.pl?usepub=1&catid=${manualID}`;
}

export function buildFeedbackURL(prefix: string, docID: number, revisionNumber?: number) {
    // note that prefix is NOT databaseuid!
    let feedbackURL = `/cgi/document_feedback_dialog.pl?ref=${prefix}:${docID}`;
    if (typeof revisionNumber !== 'undefined') {
        feedbackURL += `$${revisionNumber}`;
    }
    return feedbackURL;
}

export function buildActiveDirectoryURL(loginURL: string, url: string): string {
    const returnTo = loginURL.search(/\?/) !== -1 ? '&returnto=' : '?returnto=';
    return `${loginURL}${returnTo}${encodeURIComponent(url)}`;
}

export function ucFirst(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function createSortableDate(date: Date, dateOnly?: boolean): string {
    const sliceEndPoint = dateOnly ? 10 : 19;

    // TypeORM (correctly) converts the dates to UTC, but then you can't just use
    // regular getHours or getMonth since it won't take into account daylight savings
    // time. This is the only way I've figured out how to get the dates to display
    // exactly the way they appear in the database.

    // How to tell if a date falls within the daylight savings time of the year:
    function isDateDST(d: Date) {
        const jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
        const jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
        return Math.max(jan, jul) !== d.getTimezoneOffset();
    }

    // This will subtract 8 hours (to cancel UTC which added either 7 or 8):
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());

    // Then we deal with daylight savings time. I cannot figure out a better way.
    // It depends not only on the target date, but also the current date.
    const isNewDateDST = isDateDST(date);
    const isTodayDST   = isDateDST(new Date());

    if (     isNewDateDST
        && ! isTodayDST
    ) {
        date.setHours(date.getHours() - 1);
    }

    else if (   ! isNewDateDST
             &&   isTodayDST
    ) {
        date.setHours(date.getHours() + 1);
    }

    return date
        .toISOString()
        .slice(0,sliceEndPoint)
        .replace('T', '  ');
}
