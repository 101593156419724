import React from 'react';

interface InfoProps {
    size: number
}

export const InfoIcon: React.FC<InfoProps> = ({ size }) => {


    return (
        <svg width={size.toString()} height={size.toString()} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" rx="4" fill='hsl(var(--primary))' />
            <path d="M10.4731 10.7215C10.4882 10.4568 10.7072 10.25 10.9722 10.25H13.0278C13.2928 10.25 13.5118 10.4568 13.5269 10.7215L13.9698 18.4715C13.9862 18.7584 13.758 19 13.4706 19H10.5294C10.242 19 10.0138 18.7584 10.0302 18.4715L10.4731 10.7215Z" fill='hsl(var(--white))' />
            <rect x="10" y="5" width="4" height="4" rx="2" fill='hsl(var(--white))' />
        </svg>

    );
};