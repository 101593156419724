import {
    Column,
    Entity,
    JoinColumn,
    ManyToOne,
    PrimaryColumn,
} from 'typeorm';

import { PERSON,  } from '../master/PERSON';
import { USERGROUP,  } from './USERGROUP';
import { DOCUMENTREVISION,  } from './DOCUMENTREVISION';
import { INFORMATION_HIERARCHY,  } from './INFORMATION_HIERARCHY';
import { SchemaEntityManager } from '../SchemaEntityManager';

function createSignerAssignmentEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('SIGNERASSIGNMENT', { schema: schema })
    class SIGNERASSIGNMENT {
        static SCHEMANAME = schema;
        // Notice how the PrimaryColumn is at the end (since it was created later so it's last in the database)

        @Column('number', {
            precision: 10,
            scale: 0,
            name: 'DOCREVID'
        })
        DOCREVID!: number | null;

        @ManyToOne(
            () => manager.getDocumentRevisionEntity(schema),
            (documentRevision) => documentRevision.SIGNERASSIGNMENTS
        )
        @JoinColumn({
            name: 'DOCREVID'
        })
        DOCUMENTREVISION!: DOCUMENTREVISION | null;

        @Column('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'USERID'
        })
        USERID!: number;

        @ManyToOne(
            () => manager.getPersonEntity(schema), 
            (person) => person.SIGNERASSIGNMENTS
        )
        @JoinColumn({
            name: 'USERID'
        })
        SIGNER!: PERSON | null;

        @Column('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'USERGROUPID'
        })
        USERGROUPID!: number;

        @ManyToOne(
            () => manager.getUserGroupEntity(schema), 
            (userGroup) => userGroup.SIGNERASSIGNMENTS
        )
        @JoinColumn({
            name: 'USERGROUPID'
        })
        USERGROUP!: USERGROUP | null;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'SIGNORDER'
        })
        SIGNORDER!: number | null;

        @Column('number', {
            name: 'MANUALID'
        })
        MANUALID!: number | null;

        @ManyToOne(
            () => manager.getInformationHierarchyEntity(schema), 
            (informationHierarchy) => informationHierarchy.SIGNERASSIGNMENTS
        )
        @JoinColumn({
            name: 'MANUALID'
        })
        INFORMATION_HIERARCHY!: INFORMATION_HIERARCHY | null;

        @PrimaryColumn('number', {
            nullable: false,
            name: 'SIGNERASSIGNMENT_ID'
        })
        SIGNERASSIGNMENT_ID!: number;
    }

    return SIGNERASSIGNMENT;
}

export { createSignerAssignmentEntity };
export type SIGNERASSIGNMENT = InstanceType<ReturnType<typeof createSignerAssignmentEntity>>;