import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactSelect from 'react-select';
import { PUBLISHER, PublisherStatuses } from '../../../../entities/org/PUBLISHER';

// FYI "PUBLISHER" is the database (entity) name, and "Manual Group" is the UI name,

export type SharedSelectPublisherProps = { // also used by SelectPublishers (multi-select)
    // types of publishers to get in the query:
    statuses?: PublisherStatuses[],
    userID?: number,

    // Select menu controls:
    placeholder?: string;
    disabled?: boolean;

    unselectablePublishers?: PUBLISHER[] // this might be usable just when it's by itself, but so far this is the only use case
}

export type SelectPublisherProps = {
    // always used:
    onChange: (publisher?: PUBLISHER) => void;

    // if used alone (not part of SelectPublishers.tsx):
    publisherID?: number;

    isClearable?: boolean

    // useful if you want the first option to say "None" (sometimes more helpful than the isClearable X button)
    dummyValue?: {
        label: string,
        value: number // probably a zero, and you'll handle it on the back end with if (!)
    }
}

type SelectMenuOptionType = {
    label: string,
    value: number
}

export function SelectPublisher(props: SharedSelectPublisherProps & SelectPublisherProps) {
    const [ options, setOptions ] = useState<SelectMenuOptionType[]>([]);
    const [ publishers, setPublishers ] = useState<PUBLISHER[]>([]);

    useEffect(() => {
        getPublishers(
            props.statuses,
        ).then(publishers => {
            setPublishers(publishers);
        });
    }, []);

    useEffect(() => {
        setOptions(transformPublishersToOptions(publishers));
    }, [publishers]);

    function transformPublishersToOptions(publishers: PUBLISHER[]) {
        return publishers.map(publisher => ({
            label: publisher.TITLE || '',
            value: publisher.PUBLISHER_ID
        }));
    }

    const unselectablePublishersHash = props.unselectablePublishers?.reduce((acc: {[key: string]: boolean}, publisher) => {
        acc[publisher.PUBLISHER_ID] = true;
        return acc;
    }, {});

    return (
        <ReactSelect
            onChange={(selected) => {
                const publisher = publishers.find(publisher => publisher.PUBLISHER_ID === (selected as SelectMenuOptionType).value);
                publisher && props.onChange(publisher);
            }}
            value={options.find(option => option.value === props.publisherID)}
            options={options}
            placeholder={props.placeholder}
            isClearable={typeof props.isClearable !== 'undefined' ? props.isClearable : true}
            isDisabled={props.disabled}
            isOptionDisabled={option => !!unselectablePublishersHash?.[option.value]}
        />
    );
}

async function getPublishers(
    statuses: PublisherStatuses[] = [PublisherStatuses.active],
)
    : Promise<PUBLISHER[]>
{
    const res = await axios.get('/api/_shared/forms/select/select-publishers/', {
        params: {
            statuses,
        }
    });

    return res.data.publishers || [];
}
