import React from 'react';

interface QuestionFormProps {
    size: number
}

export const QuestionFormIcon: React.FC<QuestionFormProps> = ({ size }) => {
    return (
        <svg width={size.toString()} height={size.toString()} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" rx="4" fill="hsl(var(--primary))"/>
            <path d="M6 6C6 5.44772 6.44772 5 7 5H13.5858C13.851 5 14.1054 5.10536 14.2929 5.29289L17.7071 8.70711C17.8946 8.89464 18 9.149 18 9.41421V18C18 18.5523 17.5523 19 17 19H7C6.44772 19 6 18.5523 6 18V6Z" fill="hsl(var(--white))"/>
            <path d="M11.1584 14.4286C11.1498 14.4286 11.1429 14.4216 11.1429 14.413V14.1612C11.1429 13.2873 11.6749 12.5015 12.4863 12.1769V12.1769C12.9691 11.9838 13.2857 11.5162 13.2857 10.9962V10.8786C13.2857 10.2356 12.7644 9.71429 12.1214 9.71429H11.9368C11.2616 9.71429 10.7143 10.2616 10.7143 10.9368V10.9368C10.7143 10.9717 10.686 11 10.6511 11H9.15C9.06716 11 9 10.9328 9 10.85V10.85C9 9.27599 10.276 8 11.85 8H12.2474C13.7676 8 15 9.25499 15 10.7752V10.7752C15 11.9368 14.2928 12.9972 13.2143 13.4286V13.4286C12.9986 13.5149 12.8571 13.7238 12.8571 13.9561V14.2427C12.8571 14.3453 12.7739 14.4286 12.6712 14.4286H11.1584Z" fill="hsl(var(--primary))"/>
            <path d="M11.1426 15.6072C11.1426 15.193 11.4784 14.8572 11.8926 14.8572H11.9997H12.1069C12.5211 14.8572 12.8569 15.193 12.8569 15.6072V15.8215C12.8569 16.2357 12.5211 16.5715 12.1069 16.5715H11.8926C11.4784 16.5715 11.1426 16.2357 11.1426 15.8215V15.6072Z" fill="hsl(var(--primary))"/>
        </svg>
    );
};