// to create links to domains, or pages (like old perl pages) which can then be switched to a
// new-stack page by changing one of these strings:

const URLs = {
    domain: {
        lucidoc: 'https://lucidoc.com',
    },
    home: {
        // new stack
        main: '/cgi/home.pl',

        // old stack
        login: '/cgi/login.pl',
        logout: '/cgi/logout.pl',
        password: '/cgi/password.pl',
        about: '/cgi/about.pl',
    },
    search: {
        simple: '/cgi/search.pl'
    },
    docmanager: {

    },
    accreditationManager: {

    },
    admin: {

    },
    help: {

    }
};

export default URLs;
