import React, { useContext } from 'react';
import { DashboardCard, DashboardCardContent, DashboardCardTitle, DashboardCardToolbar, DashboardCardToolbarItem } from '../../../../components/Homepage/components/DashboardCard';
import { HOMEPAGE_LINK } from '@/entities/org/HOMEPAGE_LINK';
import useSWR from 'swr';
import { HybridLink } from '@/ui/ui/hybridlink';
import { EditReferenceLinksDialog } from './referencelinkscard2/EditReferenceLinksDialog';
import hasRights from '@/_shared/utils/hasRights';
import { RightsCodeKey } from '@/entities/master/PERSON';
import UserBundleContext from '@/context/UserBundleContext';

interface ReferenceLinksCard2Props {
}

export const ReferenceLinksCard2: React.FC<ReferenceLinksCard2Props> = () => {

    interface ReferenceLinksData {
        homepageLinks: HOMEPAGE_LINK[]
    }
    const userBundle = useContext(UserBundleContext);

    const { data: homepageLinksData, mutate } = useSWR<ReferenceLinksData>('/api/homepage/card/homepage-links');
    const isOrgAdmin = hasRights(userBundle, RightsCodeKey.OrganizationAdministrator);

    return (
        <DashboardCard disableMinWidth>
            <DashboardCardTitle>
                Reference Links
            </DashboardCardTitle>
            <DashboardCardToolbar>
                {isOrgAdmin && <DashboardCardToolbarItem tooltip='Edit links'>
                    <EditReferenceLinksDialog refetch={() => mutate()} />
                </DashboardCardToolbarItem>}
            </DashboardCardToolbar>
            <DashboardCardContent>
                {homepageLinksData && homepageLinksData.homepageLinks.map((link, idx) => {
                    return <HybridLink openInNewTab external key={idx} href={'https://' + link.URL} className='mt-1 text-base text-primary-accent hover:underline'>
                        {link.TEXT}
                    </HybridLink>;
                })}
            </DashboardCardContent>
        </DashboardCard>
    );
};