import React from 'react';

interface BuildingsHiddenOutlineProps {
    size: number
}

export const BuildingsHiddenOutlineIcon: React.FC<BuildingsHiddenOutlineProps> = ({ size }) => {
    return (
        <svg width={size.toString()} height={size.toString()} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.63916 5.51465H6.48638V13.2091H1.63916C1.57012 13.2091 1.51416 13.1531 1.51416 13.0841V5.63965C1.51416 5.57061 1.57012 5.51465 1.63916 5.51465Z" stroke="#6B7280" strokeWidth="1.25" />
            <path d="M6.52783 2.40332H14.3612C14.4302 2.40332 14.4862 2.45928 14.4862 2.52832V13.4728C14.4862 13.5418 14.4302 13.5978 14.3612 13.5978H6.52783C6.4588 13.5978 6.40283 13.5418 6.40283 13.4728V2.52832C6.40283 2.45928 6.4588 2.40332 6.52783 2.40332Z" fill="white" stroke="#6B7280" strokeWidth="1.25" />
            <path d="M5.3335 4.44531H5.77794V13.7786H5.3335V4.44531Z" fill="white" />
            <path d="M2.66699 7.47266C2.66699 7.33458 2.77892 7.22266 2.91699 7.22266H4.19477C4.33284 7.22266 4.44477 7.33459 4.44477 7.47266V8.52821C4.44477 8.66628 4.33284 8.77821 4.19477 8.77821H2.91699C2.77892 8.77821 2.66699 8.66628 2.66699 8.52821V7.47266Z" fill="#6B7280" />
            <path d="M2.66699 9.80566C2.66699 9.66759 2.77892 9.55566 2.91699 9.55566H4.19477C4.33284 9.55566 4.44477 9.66759 4.44477 9.80566V10.8612C4.44477 10.9993 4.33284 11.1112 4.19477 11.1112H2.91699C2.77892 11.1112 2.66699 10.9993 2.66699 10.8612V9.80566Z" fill="#6B7280" />
            <path d="M8.00049 9.02832C8.00049 8.89025 8.11242 8.77832 8.25049 8.77832H9.52827C9.66634 8.77832 9.77827 8.89025 9.77827 9.02832V10.0839C9.77827 10.2219 9.66634 10.3339 9.52827 10.3339H8.25049C8.11242 10.3339 8.00049 10.2219 8.00049 10.0839V9.02832Z" fill="#6B7280" />
            <path d="M11.1113 9.02832C11.1113 8.89025 11.2233 8.77832 11.3613 8.77832H12.6391C12.7772 8.77832 12.8891 8.89025 12.8891 9.02832V10.0839C12.8891 10.2219 12.7772 10.3339 12.6391 10.3339H11.3613C11.2233 10.3339 11.1113 10.2219 11.1113 10.0839V9.02832Z" fill="#6B7280" />
            <path d="M9.77783 10.9727C9.77783 10.8346 9.88976 10.7227 10.0278 10.7227H10.8612C10.9992 10.7227 11.1112 10.8346 11.1112 10.9727V13.056H9.77783V10.9727Z" fill="#6B7280" />
            <path d="M11.1113 4.36133C11.1113 4.22326 11.2233 4.11133 11.3613 4.11133H12.6391C12.7772 4.11133 12.8891 4.22326 12.8891 4.36133V5.41688C12.8891 5.55496 12.7772 5.66688 12.6391 5.66688H11.3613C11.2233 5.66688 11.1113 5.55495 11.1113 5.41688V4.36133Z" fill="#6B7280" />
            <path d="M8.00049 6.69531C8.00049 6.55724 8.11242 6.44531 8.25049 6.44531H9.52827C9.66634 6.44531 9.77827 6.55724 9.77827 6.69531V7.75087C9.77827 7.88894 9.66634 8.00087 9.52827 8.00087H8.25049C8.11242 8.00087 8.00049 7.88894 8.00049 7.75087V6.69531Z" fill="#6B7280" />
            <path d="M11.1113 6.69531C11.1113 6.55724 11.2233 6.44531 11.3613 6.44531H12.6391C12.7772 6.44531 12.8891 6.55724 12.8891 6.69531V7.75087C12.8891 7.88894 12.7772 8.00087 12.6391 8.00087H11.3613C11.2233 8.00087 11.1113 7.88894 11.1113 7.75087V6.69531Z" fill="#6B7280" />
            <path d="M8.00049 4.36133C8.00049 4.22326 8.11242 4.11133 8.25049 4.11133H9.52827C9.66634 4.11133 9.77827 4.22326 9.77827 4.36133V5.41688C9.77827 5.55496 9.66634 5.66688 9.52827 5.66688H8.25049C8.11242 5.66688 8.00049 5.55495 8.00049 5.41688V4.36133Z" fill="#6B7280" />
            <path d="M14.8434 1.56544L14.5684 1.29045C14.2755 0.997561 13.8006 0.99756 13.5077 1.29045L7.39913 7.39907L1.29051 13.5077C0.997615 13.8006 0.997615 14.2755 1.29051 14.5683L1.56549 14.8433C1.85839 15.1362 2.33326 15.1362 2.62615 14.8433L14.8434 2.6261C15.1363 2.33321 15.1363 1.85833 14.8434 1.56544Z" fill="#6B7280" stroke="white" />
        </svg>
    );
};