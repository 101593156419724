import {
    Column,
    Entity,
    PrimaryColumn,
} from 'typeorm';
import { SchemaEntityManager } from '../SchemaEntityManager';

// Factory function to create CHECKLIST_EDITION entity with dynamic schema
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function createChecklistEditionEntity(schemaName: string, manager: SchemaEntityManager) {
    @Entity('CHECKLIST_EDITION', { schema: 'ACCREDITATIONMANAGER' })
    class CHECKLIST_EDITION {
        static SCHEMANAME = schemaName;
        @PrimaryColumn('number', {
            nullable: false,
            name: 'CHECKLIST_EDITION_ID'
        })
        CHECKLIST_EDITION_ID!: number;

        @Column('number', {
            nullable: false,
            name: 'CHECKLIST_ID'
        })
        CHECKLIST_ID!: number;

        @Column('date', {
            nullable: false,
            name: 'ACCREDITOR_EDITION_DATE'
        })
        ACCREDITOR_EDITION_DATE!: Date;

        @Column('varchar2', {
            nullable: false,
            length: 32,
            default: () => '\'active\'',
            name: 'STATUS'
        })
        STATUS!: string;

        @Column('timestamp', {
            nullable: false,
            default: () => 'SYSDATE',
            name: 'CREATED_DATE'
        })
        CREATED_DATE!: Date;

        @Column('timestamp', {
            nullable: false,
            default: () => 'SYSDATE',
            name: 'LAST_UPLOADED_DATE'
        })
        LAST_UPLOADED_DATE!: Date;

        @Column('number', {
            nullable: true,
            name: 'PRIMARY_CHECKLIST_AUDIT_ID'
        })
        PRIMARY_CHECKLIST_AUDIT_ID!: number | null;

        @Column('number', {
            nullable: true,
            name: 'ACCREDITOR_EDITION_ID'
        })
        ACCREDITOR_EDITION_ID!: number | null;

        @Column('timestamp', {
            nullable: true,
            name: 'DISCARDED_DATE'
        })
        DISCARDED_DATE!: Date | null;
    }

    return CHECKLIST_EDITION;
}

// Type definition for the return type of the factory function
type CHECKLIST_EDITION = InstanceType<ReturnType<typeof createChecklistEditionEntity>>;

// Explicitly export the type and the factory function
export type { CHECKLIST_EDITION };
export { createChecklistEditionEntity };
