import {
    Column,
    Entity,
    JoinColumn,
    ManyToOne,
    OneToMany,
    PrimaryColumn,
} from 'typeorm';
import { CE_COURSE_SESSION,  } from './CE_COURSE_SESSION';
import { CE_COURSE_PROTOTYPE,  } from './CE_COURSE_PROTOTYPE';
import { SchemaEntityManager } from '../SchemaEntityManager';

// Factory function to create CE_COURSE_SNAPSHOT entity with dynamic schema
function createCeCourseSnapshotEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('CE_COURSE_SNAPSHOT', { schema })
    class CE_COURSE_SNAPSHOT {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            name: 'CE_COURSE_SNAPSHOT_ID'
        })
        CE_COURSE_SNAPSHOT_ID!: number;

        @Column('number', {
            nullable: false,
            name: 'CE_COURSE_PROTOTYPE_ID'
        })
        CE_COURSE_PROTOTYPE_ID!: number;

        @ManyToOne(
            () => manager.getCeCoursePrototypeEntity(schema),
            (ceCoursePrototype) => ceCoursePrototype.CE_COURSE_SNAPSHOTS
        )
        @JoinColumn({
            name: 'CE_COURSE_PROTOTYPE_ID'
        })
        CE_COURSE_PROTOTYPE!: CE_COURSE_PROTOTYPE | null;

        @Column('number', {
            nullable: false,
            name: 'OWNER_USERID'
        })
        OWNER_USERID!: number;

        @Column('varchar2', {
            nullable: false,
            length: 500,
            name: 'TITLE'
        })
        TITLE!: string;

        @Column('clob', {
            nullable: true,
            name: 'DESCRIPTION'
        })
        DESCRIPTION!: string | null;

        @Column('timestamp', {
            nullable: true,
            name: 'ARCHIVE_DATE'
        })
        ARCHIVE_DATE!: Date | null;

        @Column('char', {
            nullable: false,
            default: () => '1',
            name: 'ENFORCE_ASSIGNMENT_ORDER'
        })
        ENFORCE_ASSIGNMENT_ORDER!: string;

        @Column('number', {
            nullable: true,
            name: 'CREDIT_HOURS'
        })
        CREDIT_HOURS!: number | null;

        @Column('timestamp', {
            nullable: false,
            name: 'SNAPSHOT_DATE'
        })
        SNAPSHOT_DATE!: Date;

        @Column('char', {
            nullable: false,
            default: () => '1',
            name: 'REQUIRES_PASSING_GRADE'
        })
        REQUIRES_PASSING_GRADE!: string;

        @OneToMany(
            () => manager.getCeCourseSessionEntity(schema),
            (ceCourseSession) => ceCourseSession.CE_COURSE_SNAPSHOT
        )
        CE_COURSE_SESSIONS!: CE_COURSE_SESSION[] | null;
    }

    return CE_COURSE_SNAPSHOT;
}

// Export the factory function
export { createCeCourseSnapshotEntity };

// Export the type for CE_COURSE_SNAPSHOT entities created by the factory function
export type CE_COURSE_SNAPSHOT = InstanceType<ReturnType<typeof createCeCourseSnapshotEntity>>;
