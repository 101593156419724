import React, { useEffect, useState } from 'react';
import { SelectedItemsWindow } from './_shared/SelectedItemsWindow';
import { getManualGroups, SelectManual, ISharedSelectManualProps } from './SelectManual';
import { INFORMATION_HIERARCHY } from '../../../../entities/org/INFORMATION_HIERARCHY';
import { PUBLISHER } from '../../../../entities/org/PUBLISHER';

interface SelectManualMultiProps {
    selectedManuals: INFORMATION_HIERARCHY[]
    onChange: (manuals: INFORMATION_HIERARCHY[]) => void
}

export function SelectManuals(props: SelectManualMultiProps & ISharedSelectManualProps) {

    // forces a rerender of the ReactSelect so the selected items in the main window disappears after being selected
    const [ selectMenuKey, setSelectMenuKey ] = useState<number>(0);

    const [ manualGroups, setManualGroups ] = useState<PUBLISHER[]>([]);

    useEffect(() => {
        getManualGroups(
            props.statuses,
            props.publisherID,
            props.userID,
            props.visibleOnFacilityHomePages
        ).then(manualGroups => setManualGroups(manualGroups));
    }, []);

    function addManual(manualID: number) {
        if (props.selectedManuals.find(manual => manual.INFORMATION_HIERARCHY_ID === manualID)) {
            return;
        }

        function findNestedManual(
            manuals: INFORMATION_HIERARCHY[] | null,
            manualID: number
        )
            : INFORMATION_HIERARCHY | undefined
        {
            if (!manuals) return;

            for (const manual of manuals) {
                if (manual.INFORMATION_HIERARCHY_ID === manualID) return manual;
                const foundManual = findNestedManual(manual.CHILDREN, manualID);
                if (foundManual) return foundManual;
            }
        }

        let manual: INFORMATION_HIERARCHY | undefined;

        for (const manualGroup of manualGroups) {
            manual = findNestedManual(manualGroup.MANUALS, manualID);
            if (manual) break;
        }

        props.onChange(props.selectedManuals.concat(manual || []));
        setSelectMenuKey(selectMenuKey + 1);
    }

    function removeManual(manualID: number) {
        props.onChange(props.selectedManuals.filter(ih => ih.INFORMATION_HIERARCHY_ID !== manualID));
    }

    return (
        <div style={{ width: '28rem', }}>
            <SelectedItemsWindow
                selectedItemType={'manual'}
                selectedItems={props.selectedManuals.map(manual => {
                    return {
                        label: manual.TITLE,
                        value: manual.INFORMATION_HIERARCHY_ID
                    };
                })}
                onRemove={manualID => removeManual(manualID)}
            />

            <SelectManual
                key={selectMenuKey} // incrementing will clear the selected item in the main ReactSelect menu
                onChange={manualID => manualID && addManual(manualID)}
                placeholder={props.placeholder}
                disabled={props.disabled || !manualGroups}
                unselectableManuals={props.unselectableManuals}
                availableManualGroupsFromParent={manualGroups}
            />
        </div>
    );
}