import {
    Column,
    Entity,
    JoinColumn,
    JoinTable,
    ManyToMany,
    ManyToOne,
    PrimaryColumn,
} from 'typeorm';
import { type PERSON } from '../master/PERSON';
import { INFORMATION_HIERARCHY } from './INFORMATION_HIERARCHY';
import { SchemaEntityManager } from '../SchemaEntityManager';

export enum ACKScheduleStatuses {
    active = 'active',
    archived = 'archived',
    inactive = 'inactive',
    discarded = 'discarded',
}

function createAckScheduleEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('ACK_SCHEDULE', { schema })
    class ACK_SCHEDULE {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            name: 'ACK_SCHEDULE_ID',
        })
        ACK_SCHEDULE_ID!: number;

        @Column('varchar2', {
            nullable: false,
            length: 200,
            name: 'TITLE',
        })
        TITLE!: string;

        @Column('varchar2', {
            nullable: false,
            length: 20,
            name: 'STATUS',
        })
        STATUS!: ACKScheduleStatuses;

        @Column('number', {
            nullable: false,
            name: 'OWNER_USERID',
        })
        OWNER_USERID!: number;

        @ManyToOne(
            () => manager.getPersonEntity(schema), 
            (person) => person.ACK_SCHEDULES
        )
        @JoinColumn({
            name: 'OWNER_USERID',
        })
        OWNER!: PERSON;

        @Column('number', {
            nullable: false,
            name: 'GRACE_PERIOD_IN_DAYS',
        })
        GRACE_PERIOD_IN_DAYS!: number;

        @Column('timestamp', {
            nullable: true,
            name: 'NEXT_START_DATE',
        })
        NEXT_START_DATE!: Date | null;

        @Column('timestamp', {
            nullable: true,
            name: 'NEXT_END_DATE',
        })
        NEXT_END_DATE!: Date | null;

        @Column('number', {
            nullable: true,
            name: 'FREQUENCY_IN_MONTHS',
        })
        FREQUENCY_IN_MONTHS!: number | null;

        @Column('timestamp', {
            nullable: false,
            default: () => 'SYSDATE',
            name: 'CREATED_DATE',
        })
        CREATED_DATE!: Date;

        @Column('number', {
            nullable: false,
            name: 'CREATED_BY_USERID',
        })
        CREATED_BY_USERID!: number;

        @Column('timestamp', {
            nullable: true,
            name: 'LAST_MODIFIED_DATE',
        })
        LAST_MODIFIED_DATE!: Date | null;

        @Column('number', {
            nullable: true,
            name: 'LAST_MODIFIED_BY_USERID',
        })
        LAST_MODIFIED_BY_USERID!: number | null;

        @ManyToMany(
            () => manager.getInformationHierarchyEntity(schema),
            (informationHierarchy) => informationHierarchy.ACK_SCHEDULES
        )
        @JoinTable({
            name: 'ACK_SCHEDULE_MANUAL',
            joinColumn: {
                name: 'ACK_SCHEDULE_ID',
                referencedColumnName: 'ACK_SCHEDULE_ID',
            },
            inverseJoinColumn: {
                name: 'INFORMATION_HIERARCHY_ID',
                referencedColumnName: 'INFORMATION_HIERARCHY_ID',
            },
        })
        MANUALS!: INFORMATION_HIERARCHY[] | null;

        static Statuses = ACKScheduleStatuses;
    }

    return ACK_SCHEDULE;
}

export { createAckScheduleEntity };
export type ACK_SCHEDULE = InstanceType<ReturnType<typeof createAckScheduleEntity>>;
