import React from 'react';

interface ReviewIcon {
    size: number
}

export const ReviewIcon: React.FC<ReviewIcon> = ({ size }) => {
    return (
        <svg width={size.toString()} height={size.toString()} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z" fill="hsl(var(--primary))"/>
            <path d="M6 6C6 5.44772 6.44772 5 7 5H13.5858C13.851 5 14.1054 5.10536 14.2929 5.29289L17.7071 8.70711C17.8946 8.89464 18 9.149 18 9.41421V18C18 18.5523 17.5523 19 17 19H7C6.44772 19 6 18.5523 6 18V6Z" fill="white"/>
            <path d="M12.667 14.3333L14.0003 13L15.8605 15.3253C15.94 15.4247 15.9321 15.5681 15.842 15.6582L15.1706 16.3296C15.0755 16.4247 14.9222 16.4276 14.8237 16.3361L12.667 14.3333Z" fill="#A291CE"/>
            <path d="M8.75 12C8.75 10.4812 9.98122 9.25 11.5 9.25C13.0188 9.25 14.25 10.4812 14.25 12C14.25 13.5188 13.0188 14.75 11.5 14.75C9.98122 14.75 8.75 13.5188 8.75 12Z" stroke="#A291CE" stroke-width="1.5"/>
        </svg>
    );
};