// CREATE TABLE LUCIDOC.USER_PREFERENCES (
//     USERID NUMBER,
//     PREFERENCES VARCHAR2(32767),
//     CONSTRAINT fk_userid FOREIGN KEY (USERID)
//         REFERENCES MASTER.PERSON (USERID)
// );

import {
    Column,
    Entity,
    PrimaryColumn,
} from 'typeorm';
import { SchemaEntityManager } from '../SchemaEntityManager';

// ALTER TABLE LUCIDOC.USER_PREFERENCES ADD CONSTRAINT check_json CHECK (PREFERENCES IS JSON);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function createUserPreferencesEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('USER_PREFERENCES', { schema })
    class USER_PREFERENCES {
        static SCHEMANAME = schema;

        @PrimaryColumn('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'USERID'
        })
        USERID!: number;

        @Column('varchar2', {
            nullable: true,
            length: 32767,
            name: 'PREFERENCES'
        })
        PREFERENCES!: string | null;
    }

    return USER_PREFERENCES;
}

export { createUserPreferencesEntity };
export type USER_PREFERENCES = InstanceType<ReturnType<typeof createUserPreferencesEntity>>;