import {
    Column,
    Entity,
    PrimaryColumn,
} from 'typeorm';
import { SchemaEntityManager } from '../SchemaEntityManager';

// Define the factory function
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function createFeedbackTypePLEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('FEEDBACK_TYPE_PL', { schema: schema })
    class FEEDBACK_TYPE_PL {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'FEEDBACK_TYPE_ID'
        })
        FEEDBACK_TYPE_ID!: number;

        @Column('varchar2', {
            nullable: false,
            length: 40,
            name: 'DESCRIPTION'
        })
        DESCRIPTION!: string;

        @Column('varchar2', {
            nullable: true,
            length: 14,
            name: 'ABBREVIATION'
        })
        ABBREVIATION!: string | null;

        @Column('char', {
            nullable: true,
            name: 'REVIEWTYPE'
        })
        REVIEWTYPE!: string | null;

        @Column('char', {
            nullable: true,
            name: 'FEEDBACKTYPE'
        })
        FEEDBACKTYPE!: string | null;

        @Column('char', {
            nullable: true,
            name: 'REQUESTTYPE'
        })
        REQUESTTYPE!: string | null;

        @Column('varchar2', {
            nullable: true,
            length: 20,
            name: 'MAILTEMPLATE'
        })
        MAILTEMPLATE!: string | null;
    }

    return FEEDBACK_TYPE_PL;
}

// Export the factory function
export { createFeedbackTypePLEntity };

// Directly exporting a type with the same name as the class from the factory function is not straightforward in TypeScript,
// because the class name and type name cannot be identical if defined in the same scope. However, you can export a type
// alias if needed outside the factory function scope.
export type FEEDBACK_TYPE_PL = InstanceType<ReturnType<typeof createFeedbackTypePLEntity>>;
