import React from 'react';
import FieldGroup from './FieldGroup';
import { FIELDTEMPLATE } from '../../../entities/org/FIELDTEMPLATE';
import { documentMetadataValues } from './dataTypes';

interface FieldPageProps {
    name: string | null
    groups: FIELDTEMPLATE[]
    updateDocumentMetadata: (arg0: documentMetadataValues) => void
}

export default function FieldPage(props: FieldPageProps) {
    const fieldGroups = props.groups.map((group, idx) => {
        if (!group) {
            return undefined;
        }
        return (
            <FieldGroup
                key={idx}
                group={group}
                updateDocumentMetadata={(data) => props.updateDocumentMetadata(data)}
            />
        );
    });

    return (
        <div>
            <h2>{props.name}</h2>
            {fieldGroups}
        </div>
    );
}
