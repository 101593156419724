import React from 'react';
import { PERSON } from '../../../entities/master/PERSON';
import { USERGROUP } from '../../../entities/org/USERGROUP';
import { COMMITTEE } from '../../../entities/org/COMMITTEE';
import sortLastNameThenFirstName from './sortLastNameThenFirstName';
import { constructFullName } from './constructFullName';

const readablePrivilegeStrings: {[key:string]: string} = {
    reader: 'Reader',
    signer: 'Signer',
    author: 'Author',
    courseWriter: 'Course Writer',
    documentAdministrator: 'Document Administrator',
    userAdministrator: 'User Administrator',
    organizationAdministrator: 'Organization Administrator'
};

export default class TooltipBuilder {
    static generateGroupMembersAsTooltip(group: USERGROUP): string {
        if (!hasMembers(group)) {
            return 'No members';
        }
        return addMembers(group);
    }

    static generateCommitteeMembersAsTooltip(committee: COMMITTEE): string {
        if (   !hasMembers(committee)
            && !hasVIPs(committee)
        ) {
            return 'No members';
        }

        return (
              addChairperson(committee)
            + addSecretary(committee)
            + addMembers(committee)
        );
    }

    static generateMembersLackingPrivilegesAsTooltip(group: USERGROUP, allowedPrivileges: string[] | undefined): string {
        if (!allowedPrivileges || !allowedPrivileges.length)
            return '';

        const membersLackingPrivileges = this.getMembersLackingPrivileges(group, allowedPrivileges);
        if (!membersLackingPrivileges.length)
            return '';

        return 'Users must have one of the following privileges:\n'
            + readablePrivileges(allowedPrivileges).join(',\n')
            + '\n\n'
            + 'The following members lack these privileges:\n'
            + membersLackingPrivileges.join(',\n');
    }

    static getMembersLackingPrivileges(group: USERGROUP, allowedPrivileges: string[] | undefined): string[] {
        if (!group.PEOPLE || !allowedPrivileges || !allowedPrivileges.length)
            return [];

        return group.PEOPLE.reduce((membersLackingPrivileges: string[], person: PERSON) => {
            if (!personHasAtLeastOneAllowedPrivilege(person, allowedPrivileges)) {
                membersLackingPrivileges.push(constructFullName(person, 'lastFirstMiddle'));
            }
            return membersLackingPrivileges;
        }, []);
    }

    static generateHTMLforMembersLackingPrivileges(group: USERGROUP, allowedPrivileges: string[] | undefined) {
        if (!allowedPrivileges || !allowedPrivileges.length)
            return null;

        const membersLackingPrivilegesTooltip = this.generateMembersLackingPrivilegesAsTooltip(group, allowedPrivileges);
        if (!membersLackingPrivilegesTooltip)
            return null;

        return (
            <span
                style={{ color: 'grey' }}
                title={membersLackingPrivilegesTooltip}
            >
                [Some members lack privileges]
            </span>
        );
    }

}

function listMembersLineByLineForTooltip(people: PERSON[]) {
    people = people.sort((a: PERSON, b: PERSON) => {
        return sortLastNameThenFirstName(a, b);
    });
    return people.map((person: PERSON) => {
        return `\n${constructFullName(person, 'lastFirstMiddle')}`;
    });
}

function hasMembers(committeeOrGroup: COMMITTEE | USERGROUP) {
    return committeeOrGroup.PEOPLE && committeeOrGroup.PEOPLE.length;
}

function hasVIPs(committee: COMMITTEE) {
    return committee.CHAIRPERSON !== null || committee.SECRETARY !== null;
}

function addChairperson(committee: COMMITTEE) {
    if (committee.CHAIRPERSON) {
        return `CHAIRPERSON: ${constructFullName(committee.CHAIRPERSON, 'lastFirstMiddle')}\n`;
    }
    return '';
}

function addSecretary(committee: COMMITTEE) {
    if (committee.SECRETARY) {
        return `SECRETARY: ${constructFullName(committee.SECRETARY, 'lastFirstMiddle')}\n\n`;
    }
    return '';
}

function addMembers(committeeOrGroup: COMMITTEE | USERGROUP) {
    if (hasMembers(committeeOrGroup)) {
        return 'MEMBERS:' + listMembersLineByLineForTooltip(committeeOrGroup.PEOPLE!);
    }
    return '';
}

function personHasAtLeastOneAllowedPrivilege(person: PERSON, allowedPrivileges: string[] | undefined) {
    if (!allowedPrivileges || !allowedPrivileges.length)
        return true;

    return allowedPrivileges.some((privilege: string) => {
        return person.RIGHTSINENGLISH && person.RIGHTSINENGLISH[privilege];
    });
}

function readablePrivileges(allowedPrivileges: string[] | undefined): string[] {
    if (!allowedPrivileges || !allowedPrivileges.length)
        return [];

    return allowedPrivileges.map((privilege: string) => {
        return readablePrivilegeStrings[privilege];
    });
}
