import {
    Column,
    Entity,
    JoinColumn,
    ManyToOne,
    OneToMany,
    PrimaryColumn,
} from 'typeorm';
import { APPROVAL_SESSION,  } from './APPROVAL_SESSION';
import { SIGNATURE_SESSION,  } from './SIGNATURE_SESSION';
import { COMMITTEE_APPROVAL_SESSION,  } from './COMMITTEE_APPROVAL_SESSION';
import { SchemaEntityManager } from '../SchemaEntityManager';
import { EDIT_REQUEST } from './EDIT_REQUEST';
import { CONSENT_AGENDA_TASK } from './CONSENT_AGENDA_TASK';

function createApprovalSessionStepEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('APPROVAL_SESSION_STEP', { schema: schema })
    class APPROVAL_SESSION_STEP {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            name: 'APPROVAL_SESSION_ID',
        })
        APPROVAL_SESSION_ID!: number;

        @ManyToOne(
            () => manager.getApprovalSessionEntity(schema),
            (approvalSession) => approvalSession.APPROVAL_SESSION_STEPS
        )
        @JoinColumn({
            name: 'APPROVAL_SESSION_ID',
            referencedColumnName: 'APPROVAL_SESSION_ID',
        })
        APPROVAL_SESSION!: APPROVAL_SESSION | null;

        @Column('number', {
            nullable: true,
            name: 'SIGNATURE_SESSION_ID',
        })
        SIGNATURE_SESSION_ID!: number | null;

        @ManyToOne(
            () => manager.getSignatureSessionEntity(schema),
            (signatureSession) => signatureSession.APPROVAL_SESSION_STEPS
        )
        @JoinColumn({
            name: 'SIGNATURE_SESSION_ID',
        })
        SIGNATURE_SESSION!: SIGNATURE_SESSION | null;

        @Column('number', {
            nullable: true,
            name: 'COMMITTEE_APPROVAL_SESSION_ID',
        })
        COMMITTEE_APPROVAL_SESSION_ID!: number | null;

        @ManyToOne(
            () => manager.getCommitteeApprovalSessionEntity(schema),
            (committeeApprovalSession) => committeeApprovalSession.APPROVAL_SESSION_STEPS
        )
        @JoinColumn({
            name: 'COMMITTEE_APPROVAL_SESSION_ID',
        })
        COMMITTEE_APPROVAL_SESSION!: COMMITTEE_APPROVAL_SESSION | null;

        @PrimaryColumn('number', {
            nullable: false,
            name: 'PHASE',
        })
        PHASE!: number;

        @ManyToOne(
            () => manager.getEditRequestEntity(schema),
            (editRequest) => editRequest.APPROVAL_SESSION_STEPS
        )
        @JoinColumn({
            name: 'APPROVAL_SESSION_ID',
            referencedColumnName: 'APPROVAL_SESSION_ID',
        })
        EDIT_REQUEST!: EDIT_REQUEST | null;

        @OneToMany(
            () => manager.getConsentAgendaTaskEntity(schema),
            (consentAgendaTask) => consentAgendaTask.APPROVAL_SESSION_STEP
        )
        @JoinColumn({
            name: 'COMMITTEE_APPROVAL_SESSION_ID',
            referencedColumnName: 'COMMITTEE_APPROVAL_SESSION_ID',
        })
        CONSENT_AGENDA_TASKS!: CONSENT_AGENDA_TASK[] | null;
    }

    return APPROVAL_SESSION_STEP;
}

export { createApprovalSessionStepEntity };
export type APPROVAL_SESSION_STEP = InstanceType<ReturnType<typeof createApprovalSessionStepEntity>>;
