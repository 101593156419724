import React from 'react';

interface SignatureIcon {
    size: number
}

export const SignatureIcon: React.FC<SignatureIcon> = ({ size }) => {
    return (
        <svg width={size.toString()} height={size.toString()} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z" fill="hsl(var(--primary))"/>
            <path d="M6 6C6 5.44772 6.44772 5 7 5H13.5858C13.851 5 14.1054 5.10536 14.2929 5.29289L17.7071 8.70711C17.8946 8.89464 18 9.149 18 9.41421V18C18 18.5523 17.5523 19 17 19H7C6.44772 19 6 18.5523 6 18V6Z" fill="white"/>
            <path d="M9.01904 13.9132C9.02995 13.8693 9.05255 13.8292 9.08442 13.7972L13.3714 9.48828C13.6823 9.17573 14.106 9 14.5469 9C14.7909 9 15.0253 9.09724 15.1974 9.2702C15.3683 9.44201 15.4643 9.67671 15.4643 9.91906C15.4643 10.3625 15.2887 10.7918 14.976 11.1062L10.6918 15.4123C10.6598 15.4445 10.6196 15.4674 10.5755 15.4785L8.90411 15.8985C8.72107 15.9445 8.55502 15.7788 8.60057 15.5957L9.01904 13.9132Z" fill="#A291CE"/>
            <path d="M9.01904 13.9134C9.02995 13.8695 9.05255 13.8295 9.08442 13.7974L12.6085 10.2553C12.7062 10.1571 12.8652 10.1571 12.9629 10.2553L14.2174 11.5162C14.3145 11.6137 14.3145 11.7713 14.2174 11.8689L10.6918 15.4125C10.6598 15.4447 10.6196 15.4676 10.5755 15.4787L8.90411 15.8987C8.72107 15.9447 8.55502 15.779 8.60057 15.5959L9.01904 13.9134Z" fill="#A291CE"/>
            <path d="M13.3212 9.53857L13.0518 9.80646L14.6589 11.4218L14.9283 11.154L13.3212 9.53857Z" fill="white"/>
            <path d="M13.1864 9.67238L12.917 9.94026L14.5241 11.5556L14.7936 11.2878L13.1864 9.67238Z" fill="white"/>
            <path d="M14.3927 9.53857L13.857 10.077L14.7385 10.9631C14.8602 11.0853 14.9284 11.2508 14.9284 11.4232C14.9284 11.5956 14.8602 11.761 14.7385 11.8833L13.3695 13.2594C13.3428 13.2862 13.3428 13.3294 13.3695 13.3562L13.4986 13.4861C13.6963 13.6847 14.0177 13.6847 14.2154 13.4861L15.1515 12.5451C15.6586 12.0354 15.7844 11.2592 15.4641 10.6155L14.3927 9.53857Z" fill="#A291CE"/>
        </svg>
    );
};