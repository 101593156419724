import {
    Column,
    Entity,
    PrimaryColumn,
} from 'typeorm';
import { nullableStringToBooleanTransformer } from '../_helperFunctions/transformers';
import { SchemaEntityManager } from '../SchemaEntityManager';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function createPersonLoginSessionEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('PERSON_LOGIN_SESSION', { schema: 'MASTER' })
    class PERSON_LOGIN_SESSION {
        static SCHEMANAME = schema;
        @PrimaryColumn('varchar2', {
            nullable: false,
            length: 64,
            name: 'PERSON_LOGIN_SESSION_ID'
        })
        PERSON_LOGIN_SESSION_ID!: string;

        @Column('number', {
            nullable: false,
            name: 'USERID'
        })
        USERID!: number;


        @Column('number', {
            nullable: true,
            name: 'IMPERSONATED_USERID'
        })
        IMPERSONATED_USERID!: number | null;


        @Column('char', {
            nullable: true,
            name: 'USE_IMPERSONATED_RIGHTS',
            transformer: nullableStringToBooleanTransformer,
        })
        USE_IMPERSONATED_RIGHTS!: boolean | null;


        @Column('timestamp', {
            nullable: false,
            default: () => 'SYSDATE',
            name: 'LOGIN_DATE'
        })
        LOGIN_DATE!: Date;


        @Column('timestamp', {
            nullable: true,
            name: 'LOGOUT_DATE'
        })
        LOGOUT_DATE!: Date | null;


        @Column('varchar2', {
            nullable: false,
            length: 50,
            name: 'TYPE'
        })
        TYPE!: string;


        @Column('number', {
            nullable: false,
            name: 'ORGANIZATIONID'
        })
        ORGANIZATIONID!: number;
    }

    return PERSON_LOGIN_SESSION;
}

export { createPersonLoginSessionEntity };
export type PERSON_LOGIN_SESSION = InstanceType<ReturnType<typeof createPersonLoginSessionEntity>>;