import React, { useEffect, useState } from 'react';
import axios from 'axios';
import produceFlattenedOptionsFromNestedInformationHierarchy from '../../../../../utils/produceFlattenedOptionsFromNestedInformationHierarchy';
import { INFORMATION_HIERARCHY } from '../../../../../entities/org/INFORMATION_HIERARCHY';
import { MUIDataTableProps } from 'mui-datatables';
import { LoadingIndicator } from '../../../../_shared/LoadingIndicator';
import { LoadingStatuses } from '../../../../../utils/LoadingStatuses';
import { NOTIFICATION_WINDOW } from '../../../../../entities/org/NOTIFICATION_WINDOW';
import ProduceBacklogTable from '../ProduceBacklogTable';
import { constructFullName } from '@/components/UserPicker/utils/constructFullName';

interface DepartmentBacklogProps {
    onSelectDepartment?: (departmentID: number) => void
    sendDepartmentsToParent?: (deps: INFORMATION_HIERARCHY[]) => void
}

export default function DepartmentBacklog(props: DepartmentBacklogProps) {
    const [ loadingStatus, setLoadingStatus ] = useState(LoadingStatuses.MOUNTING);
    const [ tableData, setTableData ] = useState<MUIDataTableProps['data']>([]);

    useEffect(() => {
        loadDepartmentBacklog().then();
    }, []);

    async function loadDepartmentBacklog() {
        setLoadingStatus(LoadingStatuses.LOADING);

        const res = await axios.get('/api/doc-manager/compliance-reports/get-department-backlog');

        setTableData(processDepartmentsForTable(
            res.data.departments,
            res.data.docRevsOverDueByDepartmentID,
            res.data.docRevsDueSoonByDepartmentID,
            res.data.reviewWindows
        ));

        if (props.sendDepartmentsToParent) {
            props.sendDepartmentsToParent(res.data.departments);
        }

        setLoadingStatus(LoadingStatuses.READY);
    }

    function processDepartmentsForTable(
        departments: INFORMATION_HIERARCHY[],
        docRevsOverDueByDepartmentID: {[key: string]: number},
        docRevsDueSoonByDepartmentID: {
            [key: string]: {
                [key: string]: number
            }
        },
        reviewWindows: NOTIFICATION_WINDOW[]
    ) {
        const flattenedDepartments = produceFlattenedOptionsFromNestedInformationHierarchy(
            departments,
            [],
            0,
            true,
            ' '.repeat(4)
        );

        return flattenedDepartments.map(dep => {
            const tableRow: {[key: string]: string | number} = {
                DepartmentID: dep.INFORMATION_HIERARCHY_ID,
                Department: dep.TITLE,
                'Overdue for Review': docRevsOverDueByDepartmentID[dep.INFORMATION_HIERARCHY_ID],
            };

            reviewWindows.forEach(rw => {
                tableRow[`${rw.WINDOW} Day${rw.WINDOW > 1 ? 's': ''}`] = docRevsDueSoonByDepartmentID?.[dep.INFORMATION_HIERARCHY_ID]?.[rw.WINDOW] || '';
            });

            tableRow['Owner'] = dep.USER ? constructFullName(dep.USER, 'lastFirstMiddle') : '';

            return tableRow;
        });
    }

    if (loadingStatus !== LoadingStatuses.READY) {
        return (
            <LoadingIndicator/>
        );
    }

    return (
        <ProduceBacklogTable
            title={'Department'}
            tableData={tableData}
            titleOfHiddenIDColumn={'DepartmentID'}
            onSelectItem={(departmentID) => props.onSelectDepartment?.(departmentID)}
            onRefresh={() => loadDepartmentBacklog()}
        />
    );
}
