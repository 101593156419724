import React from 'react';
import ReactSelect from 'react-select';
import { ORGANIZATION } from '../../../../entities/master/ORGANIZATION';

type InputOrganizationsProps = {
    isVisible: boolean;
    organizations: ORGANIZATION[];
    selectedOrganizations: number[];
    onChange: (orgIDs: number[]) => void;
};

const selectWidth = '100%';

export default function InputOrganizations(props: InputOrganizationsProps) {
    if (!props.isVisible) {
        return null;
    }

    const options = props.organizations.map(org => {
        return {
            value: org.ORGANIZATIONID,
            label: org.NAME,
        };
    });

    return <>
        <p>{'Input the organizations:'}</p>
        <ReactSelect
            onChange={
                (selected) => {
                    if (!selected) {
                        return;
                    }

                    const newOrgIDs = selected.map(option => option.value);
                    props.onChange(newOrgIDs);
                }
            }
            options={options}
            value={options.filter(option => props.selectedOrganizations.includes(option.value))}
            styles={{
                control: (provided: object) => ({
                    ...provided,
                    width: selectWidth
                })
            }}
            isSearchable
            isClearable
            isMulti
        />
    </>;
}