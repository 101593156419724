import React from 'react';

import { helpTopicDocIDs } from '@/_shared/constants/HelpSystemIDs';
import { SelectDepartment } from '@/ui/ui/advanced-select/select-department';
import { useBrowserQuery } from '@/hooks/useBrowserQuery';
import { Button } from '@/ui/ui/button';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogHelpTopicIcon, DialogTitle, DialogTrigger } from '@/ui/ui/dialog';
import { Input } from '@/ui/ui/input';
import { useForm } from '@tanstack/react-form';
import axios from 'axios';
import { CommitteesQuery, CommitteesTabStates } from '../Committees';
import { SingleUserPicker } from '@/ui/ui/user-picker';
import { Spinner } from '@/ui/ui/spinner';
import { RightsCodeKey } from '@/entities/master/PERSON';

interface CreateCommitteeDialogProps {
    onCreate: () => void;
}

export const CreateCommitteeDialog: React.FC<CreateCommitteeDialogProps> = (props: CreateCommitteeDialogProps) => {

    const [query, setQuery] = useBrowserQuery<CommitteesQuery>();

    const form = useForm<{
        name: string,
        chairPersonUserId: number | undefined,
        secretaryUserId: number | undefined,
        minutesDepartmentId: number | undefined
    }>({
        defaultValues: {
            name: '',
            chairPersonUserId: undefined,
            secretaryUserId: undefined,
            minutesDepartmentId: undefined
        },
        onSubmit: async ({ value: {
            name,
            chairPersonUserId,
            secretaryUserId,
            minutesDepartmentId
        } }) => {
            const res = await axios.post(
                '/api/committee',
                {
                    name,
                    chairPersonUserId,
                    secretaryUserId,
                    minutesDepartmentId
                }
            );
            setQuery({ ...query, createCommitteeDialogOpen: false, committeeTab: CommitteesTabStates.Committees, editingCommitteeId: res.data.committeeId });
            props.onCreate();
        }
    });

    return (
        <Dialog
            open={query.createCommitteeDialogOpen ?? false}
            onOpenChange={(open) => {
                setQuery({ ...query, createCommitteeDialogOpen: open || undefined });
            }}
        >
            <DialogTrigger asChild>
            </DialogTrigger>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Create Committee<DialogHelpTopicIcon helpTopicId={helpTopicDocIDs['ADMIN_COMMITTEES']} />
                    </DialogTitle>
                    <DialogDescription>Create a committee within your organization</DialogDescription>
                </DialogHeader>
                <form className='w-full flex flex-col h-full items-start space-y-4'
                    onSubmit={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        form.handleSubmit();
                    }}
                >
                    <Section>
                        <p>Name:</p>
                        <form.Field
                            name={'name'}
                            children={(field) => {
                                return <Input
                                    placeholder='Enter a name...'
                                    className='w-full'
                                    value={field.getValue()}
                                    onChange={(e) => field.setValue(e.target.value)}
                                />;
                            }}
                        />
                    </Section>
                    <Section>
                        <p>Chairperson:</p>
                        <form.Field
                            name={'chairPersonUserId'}
                            children={(field) => {
                                return <SingleUserPicker 
                                    setSelectedUser={(userId) => field.setValue(userId as number ?? null) }
                                    selectedUser={field.state.value ?? undefined}
                                    placeholder={'Select a chairperson...'}
                                    rights={[RightsCodeKey.Author]}
                                    inputType='number'
                                    getMappedUsers
                                />;
                            }}
                        />
                    </Section>
                    <Section>
                        <p>Secretary:</p>
                        <form.Field
                            name={'secretaryUserId'}
                            children={(field) => {
                                return <SingleUserPicker 
                                    setSelectedUser={(userId) => field.setValue(userId as number ?? null) }
                                    selectedUser={field.state.value ?? undefined}
                                    placeholder={'Select a secretary...'}
                                    rights={[RightsCodeKey.Author]}
                                    inputType='number'
                                    getMappedUsers
                                />;
                            }}
                        />
                    </Section>
                    <Section>
                        <p>Minutes Department:</p>
                        <form.Field
                            name={'minutesDepartmentId'}
                            children={(field) => {
                                return <SelectDepartment 
                                    onChange={(userId) => field.setValue(userId) }
                                    departmentID={field.getValue()}
                                    placeholder={'Select a minutes department...'}
                                />;
                            }}
                        />
                    </Section>
                    <div className='pt-4 flex flex-row w-full'>
                        <form.Subscribe>
                            { 
                                formState => { 
                                    return <Button
                                        className='w-full mx-1'
                                        type={'submit'}
                                        variant={'default'}
                                        disabled={
                                            formState.isSubmitting || (
                                                !formState.values.name || 
                                                !formState.values.chairPersonUserId || 
                                                !formState.values.secretaryUserId || 
                                                !formState.values.minutesDepartmentId
                                            )
                                        }
                                    >
                                        {
                                            formState.isSubmitting ?  
                                                <Spinner />
                                                : 'Create'    
                                        }
                                    </Button>;
                                }
                            }
                        </form.Subscribe>
                        <Button 
                            className='w-full mx-1'
                            variant={'secondary'} 
                            onClick={
                                () => setQuery({ ...query, createCommitteeDialogOpen: undefined })
                            }
                        >
                            Close
                        </Button>
                    </div>
                </form>
            </DialogContent>
        </Dialog >
    );
};


const Section = ({ children }) => {
    return <div className='flex flex-col w-full'>
        {children}
    </div>;
};