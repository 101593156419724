import React, { useContext, useEffect, useState } from 'react';

import { QueryParams, useBrowserQuery } from '@/hooks/useBrowserQuery';
import { useDocumentTitle } from '@/hooks/useDocumentTitle';
import { Tabs, TabsList, TabsTrigger } from '@/ui/ui/tabs';
import { TabsContent } from '@radix-ui/react-tabs';
import Calendar from '@/components/Committees/components/CommitteesPage/components/Calendar';
import { CommitteesTab } from './tabs/CommitteesTab';
import { CommitteesMyMeetingsTab } from './tabs/MyMeetingsTab';
import { EditCommitteeTabStates } from './dialogs/EditCommitteeDialog';
import { HelpTopicContext } from '@/context/HelpTopicContext';
import { helpTopicDocIDs } from '@/_shared/constants/HelpSystemIDs';
import { DialogHelpTopicIcon } from '@/ui/ui/dialog';

export enum CommitteesTabStates {
    MyMeetings = 'myMeetings',
    Calendar = 'calendar',
    Committees = 'committees'
}

export interface CommitteesQuery extends QueryParams {
    committeeTab?: CommitteesTabStates
    editCommitteeTab?: EditCommitteeTabStates
    editingCommitteeId?: number
    createCommitteeDialogOpen?: boolean
    activeCommitteesOnly?: boolean
}

export const Committees: React.FC = () => {
    useDocumentTitle('Committees');

    const { helpTopicID, setHelpTopicID } = useContext(HelpTopicContext);

    const [query, setQuery] = useBrowserQuery<CommitteesQuery>();

    const [tab, setTab] = useState(CommitteesTabStates.MyMeetings);

    const handleTabChange = (value: string) => {
        // not reusing/spreading old query here on purpose, since the UI elements on that tab will no longer be in use.
        setQuery({ committeeTab: value as CommitteesTabStates });
        setTab(value as CommitteesTabStates);
    };

    useEffect(() => {
        // THIS IS WHERE THE ACTUAL INITIAL TAB IS SET.
        // The useEffect will run at the end after the useState and other hooks are run.
        setTab(query.committeeTab ?? CommitteesTabStates.MyMeetings);
    }, [query]);

    useEffect(() => {
        if (tab === CommitteesTabStates.MyMeetings || tab === CommitteesTabStates.Calendar) {
            setHelpTopicID(helpTopicDocIDs.COMMITTEES_MY_MEETINGS_AND_CALENDAR);
        } else if (tab === CommitteesTabStates.Committees) {
            setHelpTopicID(helpTopicDocIDs.COMMITTEES_COMMITTEES);
        }
    }, [tab]);

    if (!helpTopicID) {
        return null;
    }

    return (
        <div className='w-full p-4'>
            <p className='flex items-center text-2xl font-semibold font-primary'>
                Committees
                <DialogHelpTopicIcon helpTopicId={helpTopicID} />
            </p>
            <Tabs defaultValue={CommitteesTabStates.MyMeetings} value={tab} onValueChange={handleTabChange} className='mt-3'>
                <TabsList
                    defaultValue={CommitteesTabStates.MyMeetings}
                >
                    <TabsTrigger value={CommitteesTabStates.MyMeetings}>My Meetings</TabsTrigger>
                    <TabsTrigger value={CommitteesTabStates.Calendar}>Calendar</TabsTrigger>
                    <TabsTrigger value={CommitteesTabStates.Committees}>Committees</TabsTrigger>
                </TabsList>
                <TabsContent value={CommitteesTabStates.MyMeetings}>
                    <CommitteesMyMeetingsTab />
                </TabsContent>
                <TabsContent value={CommitteesTabStates.Calendar}>
                    <Calendar />
                </TabsContent>
                <TabsContent value={CommitteesTabStates.Committees}>
                    <CommitteesTab />
                </TabsContent>
            </Tabs>
        </div>
    );
};