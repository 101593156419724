import { useEffect, useState } from 'react';
import axios from 'axios';
import { LoadingIndicator } from '../_shared/LoadingIndicator';
import { LoadingStatuses } from '../../utils/LoadingStatuses';
import { MUIDataTableColumn, MUIDataTableProps } from 'mui-datatables';
import { DocRevStandardValueStrings, transformDocRevsToDataTableValues } from '../_shared/DataTable/transformers/transformDocRevsToDataTableValues';
import DataTable from '../_shared/DataTable/DataTable';
import { ClickableTitleCell } from '../_shared/DataTable/ClickableTitleCell';
import { SearchValues } from '../../context/SearchContext';
import LucidocColors from '../../constants/LucidocColors';
import { ExpandedDocrevLinks } from '../_shared/DataTable/expanders/ExpandedDocrevLinks';
import { useBrowserQuery } from '../../hooks/useBrowserQuery';
import { DOCUMENTREVISION } from '../../entities/org/DOCUMENTREVISION';
import setWindowTitle from '../../utils/setWindowTitle';

export function Search() {
    const [query] = useBrowserQuery<SearchValues>();

    const [loadingStatus, setLoadingStatus] = useState(LoadingStatuses.MOUNTING);
    const [tableData, setTableData] = useState<MUIDataTableProps['data']>([]);
    const [docRevIDs, setDocRevIDs] = useState<number[]>([]);

    useEffect(() => {
        setWindowTitle('Lucidoc Home - Search');
    }, []);

    useEffect(() => {
        const hasFilter = Object.values(query).some(value => value !== undefined);
        if (hasFilter) {
            getDocuments();
        }
    }, [query]);

    const prefixes = ['title', 'id', 'text', 'content', 'keyword', 'type'];

    // This could be re-implemented as a sliding window for better performance.
    // This implementation is just more readable, and inputs are quite small.
    function parseAdvancedSearch(inputStr: string): Record<string, string> {

        const parsedData: Record<string, string> = {};
        const words = inputStr.split(' ');

        let currentKey = '';
        let currentValue = '';

        for (const word of words) {
            // Skip empty words aka trim empty spaces.
            if (word === '') continue;
            if (prefixes.includes(word.replace(':', ''))) {
                if (currentKey) {
                    // Save the previous key-value pair
                    parsedData[currentKey] = currentValue.trim();
                }
                // Start a new key-value pair
                currentKey = word.replace(':', '');
                currentValue = '';
            } else {
                currentValue += word + ' ';
            }
        }

        // Add the last key-value pair
        if (currentKey) {
            parsedData[currentKey] = currentValue.trim();
        }

        return parsedData;
    }

    async function getDocuments() {
        setLoadingStatus(LoadingStatuses.LOADING);

        // Parse shorthand query params from title search.
        const title = query.title?.toString();
        if (title) {
            // Parse out prefixes like "title:", "id:"., "text:", etc.
            const parsedData = parseAdvancedSearch(title);
            for (const [key, value] of Object.entries(parsedData)) {
                if (value === undefined) continue;
                if (key === 'text') {
                    // New SearchValues struct uses "content" which is probably a better name.
                    // I have kept the text: prefix to avoid confusing users coming from legacy search.
                    query['content'] = value;
                    continue;
                }

                if (key === 'keyword') {
                    // New SearchValues struct uses title for title AND keyword searches.
                    query['title'] = value;
                    continue;
                }

                query[key] = value;
            }

            // If we parsed out some params, we don't need the original title param.
            // If we do not clear it the query will end up looking like this:
            // { title: 'title:id: 123', id: '123' }
            // However, if the parsedData has a title/keyword property, the query's title property was already overriden.
            if (Object.keys(parsedData).length > 0 &&
                (parsedData['title'] === undefined && parsedData['keyword'] === undefined)) {
                query.title = undefined;
            }
        }

        const res = await axios.get('/api/homepage/search', {
            params: {
                searchValues: query
            }
        });

        // Org & Prefix are only relevant in multiorg searches, but since the data is
        // held in index positions, we're just putting them here every time, so that way
        // the ClickableTitleCell function doesn't have to vary which index positions to
        // look at to get the right data
        const columnsToAdd: DocRevStandardValueStrings[] = [
            'Organization',
            'Prefix',
            'Official Date',
            'Effective Date',
            'Source Revision',
        ];

        if (query.content) {
            columnsToAdd.push('Snippet');
        }

        const docRevs = res.data.docrevs as DOCUMENTREVISION[];
        setDocRevIDs(docRevs.map(docRev => docRev.DOCREVID));
        setTableData(
            transformDocRevsToDataTableValues(
                docRevs,
                ['Effective/Official Date'],
                columnsToAdd
            )
        );

        setLoadingStatus(LoadingStatuses.READY);
    }

    if (loadingStatus === LoadingStatuses.MOUNTING) {
        return (
            <p style={{ textAlign: 'center', color: LucidocColors.darkGray, marginLeft: -48 }}>
                Enter some search terms...
            </p>
        );
    }

    if (loadingStatus === LoadingStatuses.LOADING) {
        return <LoadingIndicator />;
    }


    return (
        <div>
            {/* <div style={{ display: 'flex', justifyContent: 'space-between', padding: '1rem 1rem 0 1rem' }}>
                <Button
                    startIcon={<ArrowBack />}
                    variant={'contained'}
                    onClick={() => history.push(buildSearchURL({
                        ...searchValues,
                        pageCount: searchValues.pageCount -1
                    }))}
                    disabled={searchValues.pageCount === 0}
                >
                    Previous 20 results
                </Button>
                <Button
                    endIcon={<ArrowForward />}
                    variant={'contained'}
                    onClick={() => history.push(buildSearchURL({
                        ...searchValues,
                        pageCount: searchValues.pageCount + 1
                    }))}
                    // this is a roundabout way of disabling the button when it has
                    // no further results; this isn't "correct" (we're not getting a
                    // real count) but it's correct in 19/20 cases
                    disabled={tableData.length < 20}
                >
                    Next 20 results
                </Button>
            </div> */}

            <DataTable
                muiDataTableProps={{
                    title: 'Documents Found',
                    columns: tableData[0] && Object.keys(tableData[0]).map(key => {
                        const returnObj: MUIDataTableColumn = {
                            name: key
                        };

                        if (key == 'Source Revision') {
                            returnObj.options = {
                                display: 'false',
                                filter: true
                            };
                        }

                        if (key === 'Prefix') {
                            returnObj.options = {
                                display: 'false',
                                filter: false
                            };
                        }

                        if (key === 'Organization' && !query.isMultiOrgSearch) {
                            returnObj.options = {
                                display: 'false',
                                filter: false
                            };
                        }

                        if (key === 'Title') {
                            returnObj.options = {
                                customBodyRender: (value, tableMeta) => {
                                    return ClickableTitleCell(
                                        tableMeta.rowData[8],
                                        tableMeta.rowData[0],
                                        tableMeta.rowData[1],
                                        tableMeta.rowData[2]
                                    );
                                }
                            };
                        }

                        if (key === 'Snippet') {
                            returnObj.options = {
                                customBodyRender: value => {
                                    // FYI dangerouslySetInnerHTML doesn't work here, and this method is safer
                                    const delimiter = 'delimiterForStringSplit';

                                    value = value
                                        .replace(/<b>/g, `${delimiter}<b>`)
                                        .replace(/<\/b>/g, `</b>${delimiter}`);

                                    return value.split(delimiter).map((segment: string) => {
                                        if (segment.includes('<b>')) {
                                            return <b>{segment.replace(/<(\/)?b>/g, '')}</b>;
                                        }
                                        else return segment;
                                    });
                                }
                            };
                        }

                        return returnObj;
                    }),
                    data: tableData,
                    options: {
                        rowsPerPage: 100,
                        expandableRows: true,
                        customSearch: (searchQuery: string, currentRow: string[]) => {
                            if (!searchQuery) {
                                return true;
                            }

                            const query = searchQuery.toLowerCase();
                            return currentRow.reduce((searchSatisfied: boolean, rowDataElem: string) => {
                                if (searchSatisfied) {
                                    return true;
                                }

                                const dataElem = rowDataElem.toString().toLowerCase();
                                return searchSatisfied || dataElem.indexOf(query) >= 0;
                            }, false);
                        },
                        renderExpandableRow: (rowData, rowMeta) => {
                            const docrevID = docRevIDs[rowMeta.rowIndex];

                            return <ExpandedDocrevLinks docrevID={docrevID} />;
                        }
                    }
                }}
            />
        </div>
    );
}

export default Search;
