import React from 'react';

interface RequestContractIcon {
    size: number
}

export const RequestContractIcon: React.FC<RequestContractIcon> = ({ size }) => {
    return (
        <svg width={size.toString()} height={size.toString()} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" rx="4" fill="hsl(var(--primary))"/>
            <path d="M6 6C6 5.44772 6.44772 5 7 5H13.5858C13.851 5 14.1054 5.10536 14.2929 5.29289L17.7071 8.70711C17.8946 8.89464 18 9.149 18 9.41421V18C18 18.5523 17.5523 19 17 19H7C6.44772 19 6 18.5523 6 18V6Z" fill="white"/>
            <path d="M11.9307 15.9538C11.9727 15.9818 12.0274 15.9818 12.0693 15.9538L14.9443 14.0371C14.9791 14.0139 15 13.9749 15 13.9331V12.2336C15 12.1337 14.8887 12.0742 14.8057 12.1296L12.0693 13.9538C12.0273 13.9818 11.9726 13.9818 11.9307 13.9538L9.19434 12.1296C9.11127 12.0742 9 12.1337 9 12.2336V13.9331C9 13.9749 9.02089 14.0139 9.05566 14.0371L11.9307 15.9538Z" fill="#A291CE"/>
            <rect x="11" y="9" width="2" height="6" rx="0.125" fill="#A291CE"/>
        </svg>
    );
};