import { helpTopicDocIDs } from '@/_shared/constants/HelpSystemIDs';
import { ucFirst } from '@/_shared/utils/docManagerFunctions';
import { constructFullName } from '@/components/UserPicker/utils/constructFullName';
import { HelpTopicContext } from '@/context/HelpTopicContext';
import UserBundleContext from '@/context/UserBundleContext';
import { DisplayStatuses, DOCUMENTREVISION, DocumentRevisionStatuses } from '@/entities/org/DOCUMENTREVISION';
import { useDocumentTitle } from '@/hooks/useDocumentTitle';
import { DataTableColumnHeader } from '@/ui/ui/datatable/column-header';
import { DataTableHandle } from '@/ui/ui/datatable/data-table';
import { DocRevEntry, DocumentRevisionTable } from '@/v2/components/documentrevisiontable/DocumentRevisionTable';
import fixOfficialDocumentLinks, { useDocumentRevisionTableColumns } from '@/v2/components/documentrevisiontable/DocumentRevisionTableColumns';
import { Cell } from '@tanstack/react-table';
import axios from 'axios';
import React, { useContext, useEffect, useRef } from 'react';

interface WhatsNewProps {

}

export const WhatsNew: React.FC<WhatsNewProps> = () => {

    const [loading, setLoading] = React.useState(false);
    const [data, setData] = React.useState<DocRevEntry[]>([]);


    useDocumentTitle('What\'s New');

    const userBundle = useContext(UserBundleContext);

    const { setHelpTopicID } = useContext(HelpTopicContext);

    const fetchData = async () => {
        setLoading(true);
        const resDocRevs = await axios.get('/api/homepage/card/whats-new/docrevs')
            .then(res => {
                const data = res.data;
                if (!data) {
                    throw new Error('Could not fetch What\'s New Documents');
                }

                if (data.documentRevisions) {
                    return data.documentRevisions as DOCUMENTREVISION[];
                }

                const error = data.failure ?? data.error;
                if (error) {
                    throw new Error(`Could not fetch What's New documents: ${error}`);
                }

                return [] as DOCUMENTREVISION[];
            })
            .catch(err => {
                console.log(err);
                return [] as DOCUMENTREVISION[];
            });

        const docrevs = resDocRevs.map(docrev => {
            let displayStatus = DisplayStatuses[docrev.DOCSTATUS_ID];
            let applicableDate: Date | undefined = undefined;


            switch (docrev.DOCSTATUS_ID) {
                case DocumentRevisionStatuses.official:
                case DocumentRevisionStatuses.pending_committee_approval:
                case DocumentRevisionStatuses.prepsig:
                    if (docrev.APPROVAL_SESSIONS && docrev.APPROVAL_SESSIONS.length > 0) {
                        const approvalSessions = docrev.APPROVAL_SESSIONS
                            .sort((a, b) => new Date(a.START_DATE).getTime() - new Date(b.START_DATE).getTime());

                        const latestApprovalSession = approvalSessions[approvalSessions.length - 1];

                        if (docrev.DOCSTATUS_ID === DocumentRevisionStatuses.official) {
                            const type = latestApprovalSession.TYPE;
                            const approvalSessionSteps = latestApprovalSession.APPROVAL_SESSION_STEPS ?? [];
                            const isGatheringSignatures = approvalSessionSteps.some(step => step.SIGNATURE_SESSION_ID != null);
                            const isGatheringCommitteeApprovals = approvalSessionSteps.some(step => step.COMMITTEE_APPROVAL_SESSION_ID != null);

                            const messages: string[] = [];

                            if (isGatheringCommitteeApprovals) {
                                messages.push('Committee Approval');
                            }

                            if (isGatheringSignatures) {
                                messages.push('Signature');
                            }

                            const pendingMessage = `Pending ${messages.join(' and ')} `.trim();
                            displayStatus = `${displayStatus} (${ucFirst(type)}: ${pendingMessage})`;
                        }

                        applicableDate = latestApprovalSession.START_DATE;
                    } else {
                        applicableDate = docrev.OFFICIALDT ?? undefined;
                    }
                    break;
                case DocumentRevisionStatuses.draft:
                    applicableDate = docrev.DRAFTISSUEDT ?? undefined;
                    break;
                case DocumentRevisionStatuses.archive:
                    applicableDate = docrev.OBSOLETEDT ?? undefined;
                    break;
                default:
                    break;
            }


            const entry: DocRevEntry = {
                id: docrev.DOCID,
                revId: docrev.DOCREVID,
                revNo: docrev.REVNO,
                title: docrev.TITLE || 'no title',
                statusId: docrev.DOCSTATUS_ID,
                status: displayStatus,
                docType: docrev.DOCTYPE?.DESCRIPTION || 'no doc type',
                owner: docrev.DOCUMENT.DOCOWNER ? constructFullName(docrev.DOCUMENT.DOCOWNER, 'lastFirstMiddle') : '',
                officialDate: docrev.OFFICIALDT ? new Date(docrev.OFFICIALDT) : undefined,
                blobId: (docrev as any).BLOBID,
                orgName: undefined,
                orgPrefix: docrev.ORGANIZATION?.PREFIX ?? undefined,
                applicableDate: applicableDate ? new Date(applicableDate) : undefined,
                orgId: undefined,
                department: undefined
            };

            return entry;
        });

        setData(docrevs);
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
        setHelpTopicID(helpTopicDocIDs.WHATS_NEW);
    }, []);

    let columns = fixOfficialDocumentLinks(userBundle, useDocumentRevisionTableColumns());

    columns = columns.filter(column => column.id !== 'officialDate');

    columns.push(
        {
            id: 'applicableDate',
            accessorKey: 'applicableDate',
            enableSorting: true,
            header: ({ column }) => {
                return (
                    <DataTableColumnHeader column={column} title='Date' />
                );
            },
            cell: ({ cell }) => {

                return <p className='text-secondary'>{cell.getValue<Date>()?.toDateString() ?? '-- -- --'}</p>;
            },
            meta: {
                label: 'Date',
                exportValue: (cell: Cell<DocRevEntry, unknown>) => {
                    const value = cell.getValue<Date>();
                    if (value) {
                        return value.toDateString();
                    }

                    return '-- -- --';
                },
            }

        }
    );

    const dataTableRef = useRef<DataTableHandle<DocRevEntry>>(null);

    return (
        <div className='w-full p-4'>
            <p className='text-2xl font-semibold font-primary'>What's New</p>
            <DocumentRevisionTable
                loading={loading}
                docRevs={data}
                exportAsFileName={'Whats New'}
                columns={columns}
                ref={dataTableRef}
                limitTableHeight={false}
                officialOnly={true}
            />
        </div>
    );
};
