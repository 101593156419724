import {
    Column,
    Entity,
    JoinColumn,
    ManyToOne,
    PrimaryColumn,
} from 'typeorm';
import { PERSON,  } from '../master/PERSON';
import { USERGROUP,  } from './USERGROUP';
import { SchemaEntityManager } from '../SchemaEntityManager';

export enum ReviewTypes {
    Review = 'Review',
    Notif  = 'Notif',
}

// Define the factory function
function createReviewerAssignmentEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('REVIEWERASSIGNMENT', { schema: schema })
    class REVIEWERASSIGNMENT {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'REVIEWER_ID'
        })
        REVIEWER_ID!: number;

        @Column('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'DOCREVID'
        })
        DOCREVID!: number;

        @Column('varchar2', {
            nullable: false,
            length: 30,
            name: 'REVIEW_TYPE'
        })
        REVIEW_TYPE!: string;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'USERID'
        })
        USERID!: number | null;

        @ManyToOne(
            () => manager.getPersonEntity(schema), 
            (person) => person.REVIEWERASSIGNMENTS
        )
        @JoinColumn({
            name: 'USERID'
        })
        USER!: PERSON | null;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'USERGROUPID'
        })
        USERGROUPID!: number | null;

        @ManyToOne(
            () => manager.getUserGroupEntity(schema), 
            (userGroup) => userGroup.REVIEWERASSIGNMENTS
        )
        @JoinColumn({
            name: 'USERGROUPID'
        })
        USERGROUP!: USERGROUP | null;

        static ReviewTypes = ReviewTypes;
    }

    return REVIEWERASSIGNMENT;
}

// Export the factory function
export { createReviewerAssignmentEntity };

// Export the type representing an instance of the class generated by the factory function
export type REVIEWERASSIGNMENT = InstanceType<ReturnType<typeof createReviewerAssignmentEntity>>;
