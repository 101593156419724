import { MESSAGE_BOARD_ITEM } from '@/entities/org/MESSAGE_BOARD_ITEM';
import { Dialog, DialogTrigger, DialogContent, DialogTitle, DialogHeader, DialogDescription } from '@/ui/ui/dialog';
import React, { useState } from 'react';
import { Homepage2DialogContentStyle } from '../../Homepage2';
import { Textarea } from '@/ui/ui/textarea';
import { useIsUserLoggedInToAMultiOrg } from '@/hooks/useIsUserLoggedInToAMultiOrg';
import { Checkbox } from '@/ui/ui/checkbox';
import { Button } from '@/ui/ui/button';
import axios from 'axios';

interface EditMessageBoardDialogProps {
    messageToEdit: MESSAGE_BOARD_ITEM;
    refetch: () => void;
}

// This dialog doesn't need to be stored as a bookmark so I haven't bothered with the query style implementation of dialog state management.
export const EditMessageBoardDialog: React.FC<EditMessageBoardDialogProps> = ({ messageToEdit, refetch }) => {

    const [message, setMessage] = useState(messageToEdit.MESSAGE ?? '');
    const [isFromMultiOrg, setIsFromMultiOrg] = useState(messageToEdit.IS_FROM_MULTI_ORG ?? false);
    const [open, setOpen] = useState(false);
    const { isPublisherOrg } = useIsUserLoggedInToAMultiOrg();

    const editMessage = async () => {
        if (message.length > 1024) return;

        const res = await axios.post('/api/homepage/card/message-board/edit-message', {
            id: messageToEdit.MESSAGE_BOARD_ITEM_ID,
            newMessage: message,
            isFromMultiOrg,
        });

        if (res.status === 200) {
            setOpen(false);
            refetch();
        }
    };

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger>
                <Button size={'sm'} variant={'ghost'} className='text-[10px] h-5 px-1'>Edit</Button>
            </DialogTrigger>
            <DialogContent className={Homepage2DialogContentStyle}>
                <DialogHeader>
                    <DialogTitle>
                        Edit Message Board Item
                    </DialogTitle>
                    <DialogDescription>
                        You are editing a message board item.
                    </DialogDescription>
                </DialogHeader>
                <div className='w-full flex flex-col h-full space-y-2'>
                    <div className='flex flex-row items-center w-full justify-between'>
                        <p>Editing Message:</p>
                        <p>Characters: {message.length}/1024</p>
                    </div>
                    <Textarea
                        className='w-full flex-grow'
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                    <div className='flex flex-row w-full items-center justify-between'>
                        <div className='flex items-center'>
                            {isPublisherOrg && <>
                                <Checkbox
                                    checked={isFromMultiOrg}
                                    onCheckedChange={() => {
                                        setIsFromMultiOrg(!isFromMultiOrg);
                                    }}
                                />
                                <span className='ml-1'>Send to MultiOrg</span>
                            </>}
                        </div>
                        <Button
                            onClick={() => editMessage()}
                        >
                            Save Message
                        </Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};