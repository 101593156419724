import {
    Entity,
    JoinColumn,
    OneToOne,
    PrimaryColumn,
} from 'typeorm';
import { PERSON,  } from '../master/PERSON';
import { SchemaEntityManager } from '../SchemaEntityManager';

// Factory function to create COMMITTEE_ROUTER entity with dynamic schema
function createCommitteeRouterEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('COMMITTEE_ROUTER', { schema })
    class COMMITTEE_ROUTER {
        static SCHEMANAME = schema;
        @PrimaryColumn(
            'number',
            {
                nullable: false,
                precision: 10,
                scale: 0,
                name: 'USERID'
            }
        )
        USERID!: number;

        @OneToOne(
            () => manager.getPersonEntity(schema), 
            (person) => person.ROUTER
        )
        @JoinColumn({
            name: 'USERID'
        })
        PERSON!: PERSON | null;
    }

    return COMMITTEE_ROUTER;
}

// Export the factory function
export { createCommitteeRouterEntity };

// Define and export the type for instances created by the factory function
export type COMMITTEE_ROUTER = InstanceType<ReturnType<typeof createCommitteeRouterEntity>>;
