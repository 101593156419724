import React from 'react';

interface ArchivedProps {
    size: number
}

export const ArchivedDocumentIcon: React.FC<ArchivedProps> = ({ size }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="16" height="16" rx="4" fill="#DB8181" />
            <rect x="5" y="5" width="6" height="6" rx="3" fill="hsl(var(--white))" />
        </svg>

    );
};