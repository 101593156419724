import {
    Column,
    Entity,
    JoinColumn,
    ManyToOne,
    PrimaryColumn,
} from 'typeorm';

import { SIGNATURE_SESSION,  } from './SIGNATURE_SESSION';
import { SchemaEntityManager } from '../SchemaEntityManager';

// Define the enum for decisions
export enum SignatureLogDecisions {
    APPROVED            = 'approved',
    APPROVED_WITH_EDITS = 'approved with edits',
    REJECTED            = 'rejected'
}

// Define the factory function
function createSignatureLogEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('SIGNATURE_LOG', { schema: schema })
    class SIGNATURE_LOG {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'SIGNATURE_SESSION_ID'
        })
        SIGNATURE_SESSION_ID!: number;

        @ManyToOne(
            () => manager.getSignatureSessionEntity(schema), 
            (signatureSession) => signatureSession.SIGNATURE_LOGS
        )
        @JoinColumn({
            name: 'SIGNATURE_SESSION_ID',
        })
        SIGNATURE_SESSION!: SIGNATURE_SESSION | null;

        @PrimaryColumn('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'USERID'
        })
        USERID!: number;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'VIAUSERID'
        })
        VIAUSERID!: number | null;

        @Column('varchar2', {
            nullable: false,
            length: 200,
            name: 'USER_NAME'
        })
        USER_NAME!: string;

        @Column('varchar2', {
            nullable: false,
            length: 20,
            name: 'DECISION'
        })
        DECISION!: string;

        @PrimaryColumn('timestamp', {
            nullable: false,
            default: () => 'SYSDATE',
            name: 'DECISION_DATE'
        })
        DECISION_DATE!: Date;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'CONVERSATIONID'
        })
        CONVERSATIONID!: number | null;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'SUBREVNO'
        })
        SUBREVNO!: number | null;

        @Column('number', {
            nullable: true,
            name: 'ELECTRONIC_SIGNATURE_ID'
        })
        ELECTRONIC_SIGNATURE_ID!: number | null;

        static Decisions = SignatureLogDecisions;
    }

    return SIGNATURE_LOG;
}

// Export the factory function
export { createSignatureLogEntity };

// Export the type representing an instance of the class generated by the factory function
export type SIGNATURE_LOG = InstanceType<ReturnType<typeof createSignatureLogEntity>>;
