import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import DataTable from '../../../../_shared/DataTable/DataTable';
import { DOCUMENTREVISION } from '../../../../../entities/org/DOCUMENTREVISION';
import { MUIDataTableProps } from 'mui-datatables';
import { LoadingIndicator } from '../../../../_shared/LoadingIndicator';
import { LoadingStatuses } from '../../../../../utils/LoadingStatuses';
import UserBundleContext from '../../../../../context/UserBundleContext';
import { createSortableDate } from '../../../../../_shared/utils/docManagerFunctions';
import { TableRow, TableCell } from '@material-ui/core';
import { constructFullName } from '@/components/UserPicker/utils/constructFullName';

interface FacilityImplementationByOrgProps {
    selectedOrgID: number | undefined
    getActiveDocsOnly: boolean
    getLatestRevisionsOnly: boolean

    // used when loaded as a subcomponent of FacilityImplementationSystemWide:
    selectedDocRevID?: number
    selectedDocRevTitle?: string
}

type TableData = string | number | null;

export function FacilityImplementationByOrg(props: FacilityImplementationByOrgProps) {
    const [ loadingStatus, setLoadingStatus ] = useState(LoadingStatuses.LOADING);
    const [ tableData, setTableData ] = useState<MUIDataTableProps['data']>([]);

    const context = useContext(UserBundleContext);

    useEffect(() => {
        loadInitialData().then();
    }, [
        props.selectedOrgID,
        props.getActiveDocsOnly,
        props.getLatestRevisionsOnly
    ]);

    async function loadInitialData() {
        setLoadingStatus(LoadingStatuses.LOADING);

        const res = await axios.get('/api/doc-manager/multiorg/facility-implementation/get-multiorg-docs-by-org', {
            params: {
                selectedOrgID:          props.selectedOrgID,
                getActiveDocsOnly:      props.getActiveDocsOnly,
                getLatestRevisionsOnly: props.getLatestRevisionsOnly,
                selectedDocRevID:       props.selectedDocRevID
            }
        });

        if (res.data.multiorgDocsForChildOrgs && res.data.orgTitlesHashedByOrgID) {
            const formattedTableData = (res.data.multiorgDocsForChildOrgs as DOCUMENTREVISION[]).map(docrev => {
                let returnObj: {[key: string]: string | number | null | undefined} = {
                    Organization: res.data.orgTitlesHashedByOrgID[docrev.INVENTORY?.SUBSCRIPTION?.SUBSCRIBER_ORGID || ''],

                    DocID: docrev.DOCID,
                    'Rev#': docrev.REVNO,
                    'Feedback': constructWaiverFeedbackConversationLink(
                        docrev.DOCREVID,
                        docrev.INVENTORY?.SUBSCRIPTION?.SUBSCRIBER_ORGID as number,
                        docrev.INVENTORY?.CONVERSATIONID ?? null
                    ),
                };

                if (!props.selectedDocRevID) { // this is just a space-saver when this feature is used as a subcomponent of FacilityImplementationSystemWide
                    returnObj.Title = docrev.TITLE;
                }

                returnObj = {
                    ...returnObj,

                    Status: docrev.INVENTORY?.ADOPTION_STATUS === 'Adopted'
                        ? 'Implemented'
                        : docrev.INVENTORY?.ADOPTION_STATUS,
                    DocType: docrev.DOCTYPE?.DESCRIPTION,

                    ['Facility ' + context.organization?.ORGUNITNAME || 'Department']: docrev.DOCUMENT?.INFORMATION_HIERARCHY?.TITLE,
                    'Facility Owner': docrev.DOCUMENT?.DOCOWNER ? constructFullName(docrev.DOCUMENT?.DOCOWNER, 'lastFirstMiddle') : '',

                    'Creation Date': docrev.INVENTORY?.CREATED_DATE ? createSortableDate(new Date(docrev.INVENTORY?.CREATED_DATE)) : null,
                    'Action Date': docrev.INVENTORY?.ACTION_DATE ? createSortableDate(new Date(docrev.INVENTORY?.ACTION_DATE)) : null,

                    [context.organization?.GLOBAL_IDENTIFIER_NAME || 'Global Identifier']: docrev.GLOBAL_IDENTIFIER?.VALUE,
                };

                return returnObj;
            });

            setTableData(formattedTableData);
            setLoadingStatus(LoadingStatuses.READY);
        }
    }

    function constructWaiverFeedbackConversationLink(
        docRevID: number,
        orgID: number,
        conversationID: number | null,
    ) {
        if (!conversationID) {
            return '';
        }

        const conversationType = 4; // waiver feedback
        const params = [
            `docrevid=${docRevID}`,
            `orgid=${orgID}`,
            `conversationid=${conversationID}`,
            `conversation_type=${conversationType}`,
            'is_publisher_feedback=1',
            'corporate_audit=1'
        ];
        return `/docmgr2/document_feedback_page.pl?${params.join('&')}`;
    }

    return (
        <div>
            {loadingStatus !== LoadingStatuses.READY &&
                <LoadingIndicator />
            }

            {loadingStatus === LoadingStatuses.READY &&
                <DataTable
                    muiDataTableProps={{
                        title: `Facility Documents${props.selectedDocRevTitle ? ` for: ${props.selectedDocRevTitle}` : ''}`,
                        columns: tableData[0] && Object.keys(tableData[0]).map(column => {
                            return {
                                name: column,
                                options: {
                                    filter: column !== 'Feedback',
                                }
                            };
                        }),
                        data: tableData,
                        options: {
                            selectableRows: 'none',
                            customRowRender: (data: TableData[]) => {
                                const docID = data[1];
                                const revNo = data[2];
                                const key = `${docID}-${revNo}`;

                                return (
                                    <TableRow key={key}>
                                        {data.map((value, idx) => {
                                            if (idx === 3) {
                                                if (!value) {
                                                    return (
                                                        <TableCell key={idx}>
                                                            {value}
                                                        </TableCell>
                                                    );
                                                }

                                                return (
                                                    <TableCell key={idx}>
                                                        <a href={value as string} target='_blank'>Conversation</a>
                                                    </TableCell>
                                                );
                                            } else {
                                                return (
                                                    <TableCell key={idx}>
                                                        {value}
                                                    </TableCell>
                                                );
                                            }
                                        })}
                                    </TableRow>
                                );
                            }
                        }
                    }}
                />
            }
        </div>
    );
}
