import {
    Column,
    Entity,
    OneToMany,
    PrimaryColumn,
} from 'typeorm';
import { DOCUMENTREVISION, } from './DOCUMENTREVISION';
import { SchemaEntityManager } from '../SchemaEntityManager';

export enum GlobalIdentifierStatuses {
    active = 'active',
    archived = 'archived'
}

// Define the factory function for GLOBAL_IDENTIFIER
function createGlobalIdentifierEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('GLOBAL_IDENTIFIER', { schema: schema })
    class GLOBAL_IDENTIFIER {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            name: 'GLOBAL_IDENTIFIER_ID'
        })
        GLOBAL_IDENTIFIER_ID!: number;

        @Column('varchar2', {
            nullable: false,
            name: 'VALUE'
        })
        VALUE!: string;

        @Column('varchar2', {
            nullable: false,
            name: 'STATUS'
        })
        STATUS!: string;

        @OneToMany(
            () => manager.getDocumentRevisionEntity(schema), 
            (documentRevision) => documentRevision.GLOBAL_IDENTIFIER
        )
        DOCUMENTREVISIONS!: DOCUMENTREVISION[] | null;
    }

    return GLOBAL_IDENTIFIER;
}

// Export the factory function
export { createGlobalIdentifierEntity };

// Export the type representing an instance of the class generated by the factory function
export type GLOBAL_IDENTIFIER = InstanceType<ReturnType<typeof createGlobalIdentifierEntity>>;
