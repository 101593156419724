import { Column, Entity, PrimaryColumn } from 'typeorm';
import { SchemaEntityManager } from '../SchemaEntityManager';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function createDefaultUserPreferencesEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('DEFAULT_USER_PREFERENCES', { schema: 'MASTER' })
    class DEFAULT_USER_PREFERENCES {
        static SCHEMANAME = 'MASTER';

        @PrimaryColumn('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'ORGANIZATIONID'
        })
        ORGANIZATIONID!: number;

        @Column('varchar2', {
            nullable: true,
            length: 32767,
            name: 'PREFERENCES'
        })
        PREFERENCES!: string | null;
        
    }

    return DEFAULT_USER_PREFERENCES;
}

export { createDefaultUserPreferencesEntity };
export type DEFAULT_USER_PREFERENCES = InstanceType<ReturnType<typeof createDefaultUserPreferencesEntity>>;