
import React from 'react';

interface WhatsNewIcon {
    size: number
}

export const WhatsNewIcon: React.FC<WhatsNewIcon> = ({ size }) => {
    return (
        <svg width={size.toString()} height={size.toString()} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" rx="4" fill="hsl(var(--primary))"/>
            <path d="M4 8.5C4 7.94772 4.44772 7.5 5 7.5H9.75C9.88807 7.5 10 7.38807 10 7.25C10 7.11193 10.1119 7 10.25 7H19C19.5523 7 20 7.44772 20 8V18C20 18.5523 19.5523 19 19 19H5C4.44772 19 4 18.5523 4 18V8.5Z" fill="white"/>
            <path d="M4 6.5C4 6.22386 4.22386 6 4.5 6H9C9.27614 6 9.5 6.22386 9.5 6.5V7H4V6.5Z" fill="white"/>
            <path d="M11.7692 9.55498C11.8546 9.34966 12.1454 9.34966 12.2308 9.55498L12.9994 11.4028C13.0354 11.4893 13.1168 11.5485 13.2102 11.556L15.2051 11.7159C15.4267 11.7337 15.5166 12.0103 15.3477 12.155L13.8279 13.4569C13.7567 13.5179 13.7256 13.6136 13.7473 13.7048L14.2117 15.6514C14.2633 15.8677 14.028 16.0387 13.8382 15.9228L12.1303 14.8796C12.0503 14.8307 11.9497 14.8307 11.8697 14.8796L10.1618 15.9228C9.97204 16.0387 9.73673 15.8677 9.78832 15.6514L10.2527 13.7048C10.2744 13.6136 10.2433 13.5179 10.1721 13.4569L8.65226 12.155C8.48338 12.0103 8.57326 11.7337 8.79492 11.7159L10.7898 11.556C10.8832 11.5485 10.9646 11.4893 11.0006 11.4028L11.7692 9.55498Z" fill="#A291CE"/>
        </svg>
    );
};