import { constructFullName } from '@/components/UserPicker/utils/constructFullName';
import { PERSON, isPerson } from '../../../entities/master/PERSON';
import { USERGROUP, isUserGroup } from '../../../entities/org/USERGROUP';


function generateIntersectionErrorHelper(
    entity1: PERSON | USERGROUP,
    entity2: PERSON | USERGROUP,
    entity1UserPickerName: 'Limited Collaboration / Limited Visibility' | 'Limited Collaboration / Full Visibility' | 'Full Collaboration',
    entity2UserPickerName: 'Limited Collaboration / Limited Visibility' | 'Limited Collaboration / Full Visibility' | 'Full Collaboration',
): string {
    const entity1Name = isPerson(entity1) ? constructFullName(entity1, 'firstMiddleLast') : entity1.NAME;
    const entity2Name = isPerson(entity2) ? constructFullName(entity2, 'firstMiddleLast') : entity2.NAME;

    if (isPerson(entity1) && isPerson(entity2)) {
        return `${entity1Name} cannot be in both [${entity1UserPickerName}, ${entity2UserPickerName}].`;
    }

    if (isPerson(entity1) && isUserGroup(entity2)) {
        return `${entity1Name} cannot be in both [${entity1UserPickerName}, ${entity2UserPickerName} (via '${entity2Name}')].`;
    }

    if (isUserGroup(entity1) && isPerson(entity2)) {
        return `${entity2Name} cannot be in both [${entity1UserPickerName} (via '${entity1Name}'), ${entity2UserPickerName}]`;
    }

    if (isUserGroup(entity1) && isUserGroup(entity2)) {
        if (entity1.USERGROUPID === entity2.USERGROUPID) {
            return `'${entity1Name}' cannot be in both [${entity1UserPickerName}, ${entity2UserPickerName}].`;
        }

        const entity1Members = new Set(entity1.PEOPLE || []);
        const entity2Members = new Set(entity2.PEOPLE || []);
        const intersectedMembers = new Set<PERSON>();

        // set intersection is not fully browser compatible, hence the manual check
        entity1Members.forEach(member => {
            if (entity2Members.has(member)) {
                intersectedMembers.add(member);
            }
        });

        entity2Members.forEach(member => {
            if (entity1Members.has(member)) {
                intersectedMembers.add(member);
            }
        });

        const intersectedMembersArray = Array.from(intersectedMembers).map(member => constructFullName(member, 'firstLast')).join(', ');
        return `The following members cannot be in both [${entity1UserPickerName}, ${entity2UserPickerName}] (via '${entity1Name}' and '${entity2Name}'): ${intersectedMembersArray}.`;
    }

    return '';
}

function generateIntersectionError(
    limitedVisibilityLimitedCollaborator: PERSON | USERGROUP | undefined, 
    fullVisibilityLimitedCollaborator: PERSON | USERGROUP | undefined,
    fullCollaborator: PERSON | USERGROUP | undefined,
): string {
    if (limitedVisibilityLimitedCollaborator === undefined) {
        return generateIntersectionErrorHelper(
            fullVisibilityLimitedCollaborator!, fullCollaborator!,
            'Limited Collaboration / Full Visibility', 'Full Collaboration'
        );
    }

    if (fullVisibilityLimitedCollaborator === undefined) {
        return generateIntersectionErrorHelper(
            limitedVisibilityLimitedCollaborator!, fullCollaborator!, 
            'Limited Collaboration / Limited Visibility', 'Full Collaboration'
        );
    }

    if (fullCollaborator === undefined) {
        return generateIntersectionErrorHelper(
            limitedVisibilityLimitedCollaborator!, fullVisibilityLimitedCollaborator!,
            'Limited Collaboration / Limited Visibility', 'Limited Collaboration / Full Visibility'
        );
    }

    return '';
}

export { generateIntersectionError };