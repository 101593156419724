import React from 'react';

interface OpenBookProps {
    size: number;
}

export const OpenBookIcon: React.FC<OpenBookProps> = ({ size }) => {
    return (
        <svg width={size.toString()} height={size.toString()} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" rx="4" fill="hsl(var(--primary))" />
            <path d="M4.5 8.18136C4.5 7.89681 4.666 7.6384 4.92479 7.5201C7.0365 6.55474 9.4635 6.55474 11.5752 7.5201L11.6875 7.57143C11.886 7.66215 12.114 7.66215 12.3125 7.57143L12.4248 7.5201C14.5365 6.55474 16.9635 6.55474 19.0752 7.5201C19.334 7.6384 19.5 7.8968 19.5 8.18136V17.0253C19.5 17.4813 19.0282 17.7843 18.6135 17.5948C16.795 16.7634 14.705 16.7634 12.8865 17.5948L12.3125 17.8571C12.114 17.9479 11.886 17.9479 11.6875 17.8571L11.1135 17.5948C9.29502 16.7634 7.20498 16.7634 5.38646 17.5948C4.97177 17.7843 4.5 17.4813 4.5 17.0253V8.18136Z" fill="hsl(var(--white))" />
            <path d="M18.7812 8.73111C18.7812 8.38427 18.5471 8.08114 18.2116 7.99348C16.4517 7.53376 14.5901 7.65868 12.9074 8.34941L12.2188 8.6321V17.1423L13.1814 16.7651C14.6883 16.1746 16.3435 16.0769 17.9094 16.486C18.3504 16.6012 18.7812 16.2684 18.7812 15.8126V8.73111Z" fill="hsl(var(--primary))" />
        </svg>

    );
};