import {
    Column,
    Entity,
    PrimaryColumn,
} from 'typeorm';
import { SchemaEntityManager } from '../SchemaEntityManager';

// Define the factory function for HOMEPAGE_LINK
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function createHomepageLinkEntity(schema: string, manager?: SchemaEntityManager) {
    @Entity('HOMEPAGE_LINK', { schema: schema })
    class HOMEPAGE_LINK {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
        })
        HOMEPAGE_LINK_ID!: number;

        @Column('varchar2', {
            nullable: false,
            length: 64,
        })
        TEXT!: string;

        @Column('varchar2', {
            nullable: false,
            length: 512,
        })
        URL!: string;
    }

    return HOMEPAGE_LINK;
}

// Export the factory function
export { createHomepageLinkEntity };

// Export the type representing an instance of the class generated by the factory function
export type HOMEPAGE_LINK = InstanceType<ReturnType<typeof createHomepageLinkEntity>>;
