import React, { useContext, useEffect, useState } from 'react';
import LucidocForm from '../../../../_shared/Forms/LucidocForm/LucidocForm';
import { CONSENT_AGENDA } from '../../../../../entities/org/CONSENT_AGENDA';
import axios from 'axios';
import UserBundleContext from '../../../../../context/UserBundleContext';
import ReactSelect from 'react-select';
import { LucidocModal } from '../../../../_shared/LucidocModal/LucidocModal';
import SelectConsentAgenda from '../../../../_shared/Forms/Select/SelectConsentAgenda';

interface ICreateMeetingMinutesProps {
    consentAgenda: CONSENT_AGENDA;
    onCreateMeetingMinutes: () => void;
    onClose: () => void;
    committeeId: number;
}

const CreateMeetingMinutes: React.FC<ICreateMeetingMinutesProps> = (props: ICreateMeetingMinutesProps) => {
    const context = useContext(UserBundleContext);

    const [loading, setLoading] = useState(false);

    const [copyFrom, setCopyFrom] = useState<string>('default_content');
    const [prototypeConsentAgenda, setPrototypeConsentAgenda] = useState<CONSENT_AGENDA | undefined>();

    const [isSaveIndicatorVisible, setIsSaveIndicatorVisible] = useState(false);

    useEffect(() => {
        setCopyFrom('default_content');
    }, []);

    useEffect(() => {
        if (loading) {
            createMeetingMinutes();
        }
    }, [loading]);

    const createMeetingMinutes = async () => {
        if (context.organization) {
            const formData = new FormData();
            formData.append('ajax', 'create_minutes_document');
            formData.append('organizationid', `${ context.organization.ORGANIZATIONID }`);
            formData.append('consent_agenda_id', `${ props.consentAgenda.CONSENT_AGENDA_ID }`);
            formData.append('copy_from', copyFrom);
            if (prototypeConsentAgenda && copyFrom === 'other_minutes') {
                formData.append('copy_from_consent_agenda_id', `${ prototypeConsentAgenda.CONSENT_AGENDA_ID }`);
            }

            await axios.post(
                '/ajax/consent_agenda_management.pl',
                formData,
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }
            );

            setLoading(false);
            props.onCreateMeetingMinutes();
        }
    };

    const createButtonDisabled = (
        copyFrom === 'other_minutes' &&
        !prototypeConsentAgenda
    );

    const dateStr = (new Date(props.consentAgenda.MEETING_START_DATE)).toISOString().split('T')[0];

    const copyFromOptions = [
        {
            value: 'default_content',
            label: 'This committee\'s minutes template'
        },
        {
            value: 'other_minutes',
            label: 'Another meeting\'s minutes'
        },
        {
            value: 'none',
            label: 'No, thanks'
        }
    ];

    return <LucidocModal
        open
        onClose={ () => props.onClose() }
        title={ `Create minutes for agenda ${ dateStr }` }

        onSave={ () => setLoading(true) }
        isSaveDisabled={ createButtonDisabled }
        whyIsSaveDisabled='You must select a prototype consent agenda to copy from'
        // saveButtonText='Create'

        onCancel={ () => props.onClose() }

        isSaveIndicatorVisible={ isSaveIndicatorVisible }
        setIsSaveIndicatorVisible={ setIsSaveIndicatorVisible }
        saveIndicatorMessage='Meeting minutes created successfully'
    >
        <div
            style={{
                backgroundColor: '#fff',
                width: '80vw',
                height: '70vh',
                borderRadius: '.5rem',
                position: 'relative'
            }}
        >
            <LucidocForm
                noHeaderSpacing
                fields={[
                    {
                        name: 'Select the source for the minutes',
                        description: 'Would you like to start these minutes with content coped from elsewhere?',
                        component: <ReactSelect
                            onChange={
                                (value) => {
                                    if (!value) return;
                                    setCopyFrom(value.value);
                                }
                            }
                            styles={{
                                control: (provided: object) => ({
                                    ...provided,
                                    width: '28rem'
                                })
                            }}
                            options={ copyFromOptions }
                            value={ copyFromOptions.find(o => o.value === copyFrom) }
                        />
                    },
                    copyFrom === 'other_minutes' ? {
                        name: 'Select the agenda\'s meeting date',
                        description: 'Would you like to start these minutes with content coped from elsewhere?',
                        component: <SelectConsentAgenda
                            committeeId={ props.committeeId }
                            onChange={ setPrototypeConsentAgenda }
                            consentAgendaId={ prototypeConsentAgenda?.CONSENT_AGENDA_ID }
                            placeholder='This committees minutes template'
                            exemptConsentAgendaIds={ [props.consentAgenda.CONSENT_AGENDA_ID] }
                            hasMeetingMinutesDocId
                        />
                    } : undefined
                ]}
            />
        </div>
    </LucidocModal>;

};

export default CreateMeetingMinutes;
