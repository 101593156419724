import React, { useContext } from 'react';
import { Section } from '../EditCommitteeDialog';
import { useForm } from '@tanstack/react-form';
import axios from 'axios';
import { useBrowserQuery } from '@/hooks/useBrowserQuery';
import { CommitteesQuery } from '../../Committees';
import { COMMITTEE } from '@/entities/org/COMMITTEE';
import { Input } from '@/ui/ui/input';
import { SelectManual } from '@/ui/ui/advanced-select/select-manual';
import { SelectDepartment } from '@/ui/ui/advanced-select/select-department';
import { Textarea } from '@/ui/ui/textarea';
import { Button } from '@/ui/ui/button';
import { PERSON, RightsCodeKey } from '@/entities/master/PERSON';
import { MultipleUserPicker, SingleUserPicker } from '@/ui/ui/user-picker';
import UserBundleContext from '@/context/UserBundleContext';
import hasRights from '@/_shared/utils/hasRights';
import useSWR from 'swr';
import { Spinner } from '@/ui/ui/spinner';
import { Checkbox } from '@/ui/ui/checkbox';

interface EditCommitteesDialogInfoTabProps {
    onEdit: () => void;
}

export const EditCommitteesDialogInfoTab: React.FC<EditCommitteesDialogInfoTabProps> = ({ onEdit }) => {
    const [query, setQuery] = useBrowserQuery<CommitteesQuery>();

    const userBundle = useContext(UserBundleContext);

    const { data: committeeData, mutate: refetchCommittee } =
    useSWR<{ committee: COMMITTEE }>(`/api/committee/${query.editingCommitteeId}`);

    const committee = committeeData?.committee;

    const isChairperson = userBundle.user?.USERID === committee?.CHAIRPERSON_USERID;
    const isSecretary = userBundle.user?.USERID === committee?.SECRETARY_USERID;
    const isCollaborator = committee?.COLLABORATORS?.some((person: PERSON) => person.USERID === userBundle.user?.USERID);

    const isCommitteeAdmin = hasRights(
        userBundle,
        [
            RightsCodeKey.DocumentAdministrator,
            RightsCodeKey.OrganizationAdministrator,
        ]
    );

    const form = useForm<{
        name: string,
        chairPersonUserId: number | null,
        secretaryUserId: number | null,
        minutesDepartmentId: number | null,
        bylawsManualId: number | null,
        notes: string,
        committeeMembersUserIds: number[],
        committeeCollaboratorUserIds: number[],
        status: number | null,
        allowPublicMinutes: boolean,
    }>({
        defaultValues: {
            name: committee?.NAME ?? '',
            chairPersonUserId: committee?.CHAIRPERSON_USERID ?? null,
            secretaryUserId: committee?.SECRETARY_USERID ?? null,
            minutesDepartmentId: committee?.MINUTES_DEPARTMENT_ID ?? null,
            bylawsManualId: committee?.BYLAWS_MANUALID ?? null,
            notes: committee?.NOTES ?? '',
            committeeMembersUserIds: committee?.PEOPLE?.map((person: PERSON) => person.USERID) ?? [],
            committeeCollaboratorUserIds: committee?.COLLABORATORS?.map((person: PERSON) => person.USERID) ?? [],
            status: committee?.STATUS ?? null,
            allowPublicMinutes: !!committee?.ALLOW_PUBLIC_MINUTES,
        },
        onSubmit: async (values) => {
            await axios.put(
                `/api/committee/${query.editingCommitteeId}`,
                values.value
            );
            refetchCommittee();
            onEdit();
            form.reset();
            
        }
    });

    const formState = form.useStore((state) => {
        return {
            chairPersonUserId: state.values.chairPersonUserId,
            secretaryUserId: state.values.secretaryUserId,
        };
    });

    return (
        <form className='w-full flex flex-col h-full items-start space-y-4 pt-2'
            onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
                form.handleSubmit();
            }}
        >
            <Section>
                <p>Name:</p>
                <form.Field
                    name={'name'}
                    children={(field) => {
                        return <Input
                            placeholder='Enter a name...'
                            className='w-full'
                            value={field.state.value}
                            onChange={(e) => field.handleChange(e.target.value)}
                            disabled={!isChairperson && !isSecretary && !isCollaborator && !isCommitteeAdmin}
                        />;
                    }}
                />
            </Section>
            <Section>
                <p>Chairperson:</p>
                <form.Field
                    name={'chairPersonUserId'}
                    children={(field) => {
                        return <SingleUserPicker
                            inputType='number'
                            disabled={!isChairperson && !isCommitteeAdmin}
                            placeholder={'Select a secretary...'}
                            rights={[RightsCodeKey.Author]}
                            selectedUser={field.state.value ?? undefined}
                            setSelectedUser={(user) => field.handleChange(user as number ?? null)}
                            getMappedUsers
                            clearable={false}
                        />;
                    }}
                />
            </Section>
            <Section>
                <p>Secretary:</p>
                <form.Field
                    name={'secretaryUserId'}
                    children={(field) => {
                        return <SingleUserPicker
                            inputType='number'
                            placeholder={'Select a secretary...'}
                            rights={[RightsCodeKey.Author]}
                            selectedUser={field.state.value ?? undefined}
                            setSelectedUser={(user) => field.handleChange(user as number ?? null)}
                            disabled={!isChairperson && !isSecretary && !isCommitteeAdmin}
                            getMappedUsers
                            clearable={false}
                        />;
                    }}
                />
            </Section>
            <Section>
                <p>Bylaws Manual:</p>
                <form.Field
                    name={'bylawsManualId'}
                    children={(field) => {
                        return <>
                            <SelectManual
                                onChange={(manualId) => field.handleChange(manualId ?? null)}
                                manualId={field.state.value ?? undefined}
                                placeholder={'Select a bylaws manual...'}
                                disabled={!isChairperson && !isSecretary && !isCollaborator && !isCommitteeAdmin}
                            />
                        </>;
                    }}
                />
            </Section>
            <Section>
                <p>Minutes Department:</p>
                <form.Field
                    name={'minutesDepartmentId'}
                    children={(field) => {
                        return <SelectDepartment
                            onChange={(departmentId) => field.handleChange(departmentId ?? null)}
                            departmentID={field.state.value ?? undefined}
                            placeholder={'Select a minutes department...'}
                            disabled={!isCommitteeAdmin}
                        />;
                    }}
                />
            </Section>
            <Section>
                <p>Members:</p>
                <form.Field
                    name={'committeeMembersUserIds'}
                    children={(field) => {
                        return <MultipleUserPicker
                            inputType='number'
                            rights={[RightsCodeKey.Reader]}
                            selectedUsers={field.state.value ?? []}
                            setSelectedUsers={(users) => field.handleChange(users as number[])}
                            disabled={!isChairperson && !isSecretary && !isCollaborator && !isCommitteeAdmin}
                            exemptUsers={[
                                formState.chairPersonUserId || -1,
                                formState.secretaryUserId || -1,
                            ]}
                            getMappedUsers
                        />;
                    }}
                />
            </Section>
            <Section>
                <p>Collaborators:</p>
                <form.Field
                    name={'committeeCollaboratorUserIds'}
                    children={(field) => {
                        return <MultipleUserPicker
                            inputType='number'
                            rights={[RightsCodeKey.Reader]}
                            selectedUsers={field.state.value ?? []}
                            setSelectedUsers={(users) => field.handleChange(users as number[])}
                            disabled={!isChairperson && !isSecretary && !isCollaborator && !isCommitteeAdmin}
                            exemptUsers={[
                                formState.chairPersonUserId || -1,
                                formState.secretaryUserId || -1,
                            ]}
                            getMappedUsers
                        />;
                    }}
                />
            </Section>
            <Section>
                <p>Notes:</p>
                <form.Field
                    name={'notes'}
                    children={(field) => {
                        return <Textarea
                            value={field.state.value}
                            onChange={(e) => field.handleChange(e.target.value)}
                            placeholder='Enter notes...'
                            disabled={!isChairperson && !isSecretary && !isCollaborator && !isCommitteeAdmin}
                        />;
                    }}
                />
            </Section>
            <Section>
                <form.Field
                    name={'allowPublicMinutes'}
                    children={(field) => {
                        return <div className='flex flex-row'>
                            <Checkbox
                                className='mr-2'
                                checked={field.state.value}
                                onCheckedChange={(checkedState) => {
                                    field.handleChange(!!checkedState.valueOf());
                                }}
                                disabled={!isChairperson && !isSecretary && !isCollaborator && !isCommitteeAdmin}
                            />
                            <span>Allow committee's official/draft minutes to be public</span>
                        </div>;
                    }}
                />
            </Section>
            <div className='pt-4 flex flex-row w-full'>
                <form.Subscribe>
                    { 
                        formState => {
                            return <Button
                                className='w-full mx-1'
                                type={'submit'}
                                variant={'secondary'}
                                disabled={ !formState.isDirty || formState.isSubmitting }
                            >
                                {
                                    formState.isSubmitting ?  
                                        <Spinner />
                                        : 'Save'    
                                }
                            </Button>;
                        }
                    }
                </form.Subscribe>
                <form.Subscribe>
                    { 
                        formState => {
                            return <Button
                                className='w-full mx-1'
                                onClick={ () => form.reset() }
                                variant={'secondary'}
                                disabled={ !formState.isDirty || formState.isSubmitting }
                            >
                                Undo
                            </Button>;
                        }
                    }
                </form.Subscribe>
                <Button
                    className='w-full mx-1'
                    variant={'secondary'}
                    type='button'
                    onClick={
                        () => setQuery({ ...query, editingCommitteeId: undefined })
                    }
                >
                    Close
                </Button>
            </div>
        </form>
    );
};
