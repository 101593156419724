/**
 * This exists because in admin.pl, a lot of React pages load inside iframes, and need
 * to set a title bar in the Perl, which needs to be communicated from one iframe to
 * another. The file admin.tt has the event listener corresponding to this function,
 * listening with the function handleMessageFromChildIframe(e).
 *
 * @param newTitle
 */
export function setTitleInAdmin(newTitle: string) {
    if (window.parent?.location.href.match(window.LUCIDOC_URL_REGEX) ) {
        window.parent.postMessage(
            JSON.stringify({ emitTitle: newTitle }),
            `https://${window.parent.location.host}`
        );
    }
}
