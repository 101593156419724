import { PlusCircledIcon } from '@radix-ui/react-icons';
import { Popover, PopoverContent, PopoverTrigger } from './popover';
import { Button, buttonVariants } from './button';
import { Separator } from './separator';
import { Badge } from './badge';
import { SearchIcon } from 'lucide-react';
import { useState } from 'react';
import { Checkbox } from './checkbox';
import { VariantProps } from 'class-variance-authority';
import { cn } from '@/lib/utils';
import { Input } from './input';
import { INFORMATION_HIERARCHIES_PARENT, INFORMATION_HIERARCHY_WITH_ORG, isInformationHierarchyParent } from '@/entities/org/INFORMATION_HIERARCHY';
import { SearchInfoHierarchies } from '@/v2/components/informationhierarchy/InformationHierachyBrowser';
import { ChevronRightOutlineIcon } from '../icons/outline/ChevronRightOutline';
import { ChevronDownOutlineIcon } from '../icons/outline/ChevronDownOutline';

interface InfoHierarchyFacetedFilterProps {
    title?: string;
    disabled?: boolean;
    options?: INFORMATION_HIERARCHIES_PARENT[];
    icon?: React.ReactNode;
    selectedValues: Set<string>;
    setSelectedValues: (values: Set<string>) => void;
    size?: VariantProps<typeof buttonVariants>['size'];
    // The component uses an internal filter text state if these are not provided
    filterText?: string; // Controlled filter text
    setFilterText?: (text: string) => void; // Controlled filter text setter
    singleParentMode?: boolean;
}

export function InformationHierarchyFacetedFilter({
    title,
    selectedValues,
    setSelectedValues,
    icon,
    options = [],
    size = 'sm',
    disabled,
    filterText: controlledFilterText,
    setFilterText: setControlledFilterText,
    singleParentMode = false
}: InfoHierarchyFacetedFilterProps) {
    const [internalFilterText, setInternalFilterText] = useState('');
    const filterText = controlledFilterText !== undefined ? controlledFilterText : internalFilterText;
    const setFilterText = setControlledFilterText !== undefined ? setControlledFilterText : setInternalFilterText;

    const buildValue = (value: INFORMATION_HIERARCHY_WITH_ORG) => {
        return `org:${value.ORG_ID}-ih:${value.INFORMATION_HIERARCHY_ID.toString()}`;
    };

    const toggleSelection = (value: INFORMATION_HIERARCHY_WITH_ORG) => {
        const newSelectedValues = new Set(selectedValues);
        if (newSelectedValues.has(buildValue(value))) {
            newSelectedValues.delete(buildValue(value));
        } else {
            newSelectedValues.add(buildValue(value));
        }
        setSelectedValues(newSelectedValues);
    };

    const clearFilters = () => {
        setSelectedValues(new Set());
    };

    const filteredOptions = SearchInfoHierarchies(options, filterText);

    const [expandedParents, setExpandedParents] = useState<Record<string, boolean>>({});

    const toggleParent = (title: string) => {
        setExpandedParents(prev => ({ ...prev, [title]: !prev[title] }));
    };

    const renderOptions = (options: (INFORMATION_HIERARCHY_WITH_ORG | INFORMATION_HIERARCHIES_PARENT)[], level = 0) => {
        return options.map((option) => {
            // Check if the option is an INFORMATION_HIERARCHIES_PARENT (it has CHILDREN but no ID or selectable option)
            if (isInformationHierarchyParent(option)) {

                if (singleParentMode) {
                    if (option.CHILDREN) {
                        return renderOptions(option.CHILDREN, level);
                    }
                    return null;
                }

                const isExpanded = expandedParents[option.TITLE] || false;
                return (
                    <div key={option.TITLE} className={cn('w-full flex flex-col', level > 0 && 'border-l border-border pl-3')}>
                        {/* Render the parent label (collapsible) */}
                        <div
                            className="text-primary text-sm font-semibold pl-1 py-2 cursor-pointer flex items-center"
                            onClick={() => toggleParent(option.TITLE)}
                        >
                            {isExpanded ? <ChevronDownOutlineIcon size={16} /> : <ChevronRightOutlineIcon size={16} />}
                            <span className="ml-2">{option.TITLE}</span>
                        </div>
                        {/* Recursively render the children of this parent if expanded */}
                        {isExpanded && option.CHILDREN && renderOptions(option.CHILDREN, level + 1)}
                    </div>
                );
            }

            // Handle normal INFORMATION_HIERARCHY_WITH_ORG items
            const isSelected = selectedValues?.has(buildValue(option as INFORMATION_HIERARCHY_WITH_ORG));

            return (
                <div
                    key={(option as INFORMATION_HIERARCHY_WITH_ORG).ORG_ID + '-' + (option as INFORMATION_HIERARCHY_WITH_ORG).INFORMATION_HIERARCHY_ID + '-' + option.TITLE}
                    className={cn('w-full flex flex-col', level > 0 && 'border-l border-border pl-3')}
                    style={{ paddingLeft: `${(level + 1) * 5}px` }}
                // Add left border for nested options
                >
                    <Button
                        variant="ghost"
                        className={cn(
                            'justify-start w-full',
                            isSelected ? 'text-primary' : 'text-secondary'
                        )}
                        onClick={() => toggleSelection(option as INFORMATION_HIERARCHY_WITH_ORG)}
                    >
                        <Checkbox checked={isSelected} className="mr-2" />
                        <span className="text-sm">{option.TITLE}</span>
                    </Button>
                    {(option as INFORMATION_HIERARCHY_WITH_ORG).CHILDREN && renderOptions((option as INFORMATION_HIERARCHY_WITH_ORG).CHILDREN ?? [], level + 1)}
                </div>
            );
        });
    };

    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button disabled={disabled} variant="outline" size={size} className="h-8 border-dashed text-secondary w-full sm:w-auto">
                    <div className='mr-2'>
                        {icon ?? <PlusCircledIcon className="h-4 w-4" />}
                    </div>
                    {title}
                    {selectedValues?.size > 0 && (
                        <>
                            <Separator orientation="vertical" className="mx-2 h-4" />
                            <Badge variant="default" className="rounded-sm px-1 font-normal lg:hidden">
                                {selectedValues.size}
                            </Badge>
                            <div className="hidden space-x-1 lg:flex">
                                {(
                                    <Badge variant="default" className="rounded-sm px-1 font-normal">
                                        {selectedValues.size} selected
                                    </Badge>
                                )}
                            </div>
                        </>
                    )}
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-full min-w-[300px] p-0" align="start">
                <div className='text-sm w-full p-2 pb-0 flex flex-row items-center'>
                    <SearchIcon className='text-secondary mr-2' size={18} />
                    <Input
                        className='border-none w-full outline-none text-secondary'
                        placeholder={`Search ${title}...`}
                        value={filterText}
                        onChange={e => setFilterText(e.target.value)}
                    />
                </div>
                <Separator orientation='horizontal' className='my-2' />
                <div className='w-full flex flex-col'>
                    <div className='flex flex-col overflow-y-auto max-h-[275px] overflow-x-hidden'>
                        {renderOptions(filteredOptions)}
                    </div>
                    <Separator orientation='horizontal' className='mt-2' />
                    <Button variant={'ghost'} disabled={selectedValues?.size === 0} onClick={clearFilters}>
                        Clear Filters
                    </Button>
                </div>
            </PopoverContent>
        </Popover>
    );
}