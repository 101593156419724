import {
    Column,
    Entity,
    PrimaryColumn,
} from 'typeorm';
import { SchemaEntityManager } from '../SchemaEntityManager';

// Define the factory function
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function createRoutingConfigurationLogEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('ROUTING_CONFIGURATION_LOG', { schema: schema })
    class ROUTING_CONFIGURATION_LOG {
        static SCHEMANAME = schema;
        @Column('number', {
            nullable: true,
            name: 'MODIFIER_USERID'
        })
        MODIFIER_USERID!: number | null;

        @PrimaryColumn('varchar2', {
            nullable: false,
            length: 1000,
            name: 'MODIFIER_NAME'
        })
        MODIFIER_NAME!: string;

        @Column('varchar2', {
            nullable: true,
            length: 1000,
            name: 'MODIFIER_TITLE'
        })
        MODIFIER_TITLE!: string | null;

        @PrimaryColumn('timestamp', {
            nullable: false,
            name: 'DATESTAMP'
        })
        DATESTAMP!: Date;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'DOCID'
        })
        DOCID!: number | null;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'MANUALID'
        })
        MANUALID!: number | null;

        @PrimaryColumn('number', {
            nullable: false,
            name: 'COMMITTEE_ID'
        })
        COMMITTEE_ID!: number;

        @Column('number', {
            nullable: true,
            name: 'PHASE'
        })
        PHASE!: number | null;

        @Column('varchar2', {
            nullable: true,
            length: 100,
            name: 'COMMITTEE_NAME'
        })
        COMMITTEE_NAME!: string | null;
    }

    return ROUTING_CONFIGURATION_LOG;
}

// Export the factory function
export { createRoutingConfigurationLogEntity };

// Export the type representing an instance of the class generated by the factory function
export type ROUTING_CONFIGURATION_LOG = InstanceType<ReturnType<typeof createRoutingConfigurationLogEntity>>;
