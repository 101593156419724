import UserPicker from '../../../UserPicker/UserPicker';
import { isPerson, PERSON } from '../../../../entities/master/PERSON';
import { isUserGroup, USERGROUP } from '../../../../entities/org/USERGROUP';
import { DragDropContext } from 'react-beautiful-dnd';
import React, { useState } from 'react';
import { documentMetadataValues } from '../dataTypes';
import { FIELD } from '../../../../entities/org/FIELD';
import { constructFullName } from '@/components/UserPicker/utils/constructFullName';

interface WrappedUserPickerFieldProps {
    field: FIELD
    updateDocumentMetadata: (arg0: documentMetadataValues) => void
}

export default function WrappedUserPickerField(props: WrappedUserPickerFieldProps) {
    const [ userPickerItems, setUserPickerItems ] = useState<(PERSON|USERGROUP)[]>([]);

    async function processUserPickerDataForDocumentMetadata(selectedItems: (PERSON | USERGROUP)[]): Promise<documentMetadataValues | undefined> {
        // the data we send up to go into the eventual XMD field in the DOCUMENTREVISION database
        // has to be in a certain format, and is different if the field is designated as a multi-select
        // value (which is the REPEATABLE value). Here is for single values:
        if (props.field!.REPEATABLE !== '1') {
            if (selectedItems.length === 0) {
                return undefined;
            }

            const item = selectedItems[0];

            if (isPerson(item)) {
                return {
                    fieldName: props.field!.NAME,
                    value: constructFullName(item, 'lastFirstMiddle'),
                    attrs: {
                        ID: item.USERID
                    }
                };
            }
            else if (isUserGroup(item)) {
                return {
                    fieldName: props.field!.NAME,
                    value: item.NAME,
                    attrs: {
                        ID: item.USERGROUPID
                    }
                };
            }
        }
        // here is for multi-values, which must be nested in this manner, with these keys and values:
        else {
            const mappedItems = selectedItems.map(item => {
                if (isPerson(item)) {
                    return {
                        name: props.field!.NAME,
                        text: constructFullName(item, 'lastFirstMiddle'),
                        attrs: {
                            ID: item.USERID
                        }
                    };
                }
                else if (isUserGroup(item)) {
                    return {
                        name: props.field!.NAME,
                        text: item.NAME,
                        attrs: {
                            ID: item.USERGROUPID
                        }
                    };
                }
                return undefined; // just a TypeScript syntax warning fix
            });
            return {
                fieldName: `_${props.field!.NAME}`,
                children: mappedItems
            };
        }
    }

    return (
        <DragDropContext onDragEnd={() => {}}>
            <UserPicker
                getUsers={true}
                selectedUsersPlusGroups={userPickerItems}
                addUserSignature={(data: (PERSON | USERGROUP)[]) => {
                    // if field is not REPEATABLE (meaning it handles single values, not multiple)
                    // we change the data so it's just the most recently added user that will be
                    // the selected user (essentially making this into a single-select rather than
                    // multi-select)
                    if (props.field.REPEATABLE !== '1') {
                        data = [data[data.length - 1]];
                    }
                    setUserPickerItems(data);
                    processUserPickerDataForDocumentMetadata(data)
                        .then(processedData => processedData && props.updateDocumentMetadata(processedData));
                }}
                removeUserSignature={(data: (PERSON | USERGROUP)[]) => {
                    setUserPickerItems(data);
                    processUserPickerDataForDocumentMetadata(data)
                        .then(processedData => processedData && props.updateDocumentMetadata(processedData));
                }}
            />
        </DragDropContext>
    );
}
