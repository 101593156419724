import { useContext, useEffect, useState } from 'react';

import { FeatureFlagClient } from '@/components/InternalSupport/FeatureFlagManagement/FeatureFlagClient';
import UserBundleContext from '@/context/UserBundleContext';

/**
 * 
 * @param feature Feature to query for
 * @returns boolean indicating if the feature is enabled
 */
export const useQueryFeature = (feature: string) => {

    const userBundle = useContext(UserBundleContext);

    const [featureEnabled, setFeatureEnabled] = useState(false);

    useEffect(() => {
        if (userBundle.organization) {
            FeatureFlagClient.queryFeature(feature, userBundle.organization?.ORGANIZATIONID.toString())
                .then((enabled) => setFeatureEnabled(enabled));
        }
    }, [feature, userBundle]);

    return featureEnabled;
};