import React, { Component } from 'react';
import './userpicker.css';
import sortLastNameThenFirstName from './utils/sortLastNameThenFirstName';
import TooltipBuilder from './utils/TooltipBuilder';
import axios from 'axios';

import adjourn from '../../images/adjourn.png';

import { COMMITTEE } from '../../entities/org/COMMITTEE';
import { PERSON, PersonStatuses, isPerson } from '../../entities/master/PERSON';
import { USERGROUP, isUserGroup } from '../../entities/org/USERGROUP';
import { ORGANIZATION } from '../../entities/master/ORGANIZATION';
import UserBundleContext from '../../context/UserBundleContext';

import { Draggable, Droppable } from 'react-beautiful-dnd';
import { produceRightsObjectFromString } from '../../entities/_helperFunctions/rights/produceRightsObjectFromString';
import { Cancel, Group, Person, Settings } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import { constructFullName } from './utils/constructFullName';

const ADD = 'ADD';
const REMOVE = 'REMOVE';
const leftMouseButtonClick = 1;

interface UserPickerProps {
    addUserSignature?: any
    removeUserSignature?: any

    addGroupSignature?: any
    removeGroupSignature?: any

    selectedUsersPlusGroups?: (PERSON | USERGROUP)[] | []
    unremovablePeople?: { [key: string]: string }
    unremovableGroups?: { [key: string]: string }

    allowedPrivileges?: string[]
    allowedStatuses?: number[]

    addCommitteeSignature?: any
    removeCommitteeSignature?: any
    selectedCommittees?: COMMITTEE[] | []
    committeeApprovalGroupsMasterList?: COMMITTEE[][] | []
    groupNumber?: number
    unremovableCommittees?: { [key: string]: string }

    getUsers?: boolean
    getUsersWithAdvancedDetails?: boolean
    getGroups?: boolean
    getCommittees?: boolean

    disabled?: boolean // disables everything, including drag and drop
    isDragDisabled?: boolean
    minimumDragAndDropIndex?: number

    hideArchivedGroups?: boolean
}

class UserPicker extends Component<UserPickerProps, any> {
    private searchResultsWindow = React.createRef<HTMLDivElement>();
    static contextType = UserBundleContext;
    declare context: React.ContextType<typeof UserBundleContext>;

    constructor(props: UserPickerProps) {
        super(props);
        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.state = {
            loading: true,

            originalUsers: [],
            filteredUsers: [],

            originalGroups: [],
            filteredGroups: [],

            selectedUsersPlusGroups: this.props.selectedUsersPlusGroups || [],
            unremovablePeople: this.props.unremovablePeople || {},
            unremovableGroups: this.props.unremovableGroups || {},

            originalCommittees: [],
            filteredCommittees: [],
            selectedCommittees: this.props.selectedCommittees || [],
            unremovableCommittees: this.props.unremovableCommittees || {},

            organizations: [],
            organizationsHash: {},
            departments: {},
            departmentsToDisplay: [],
            titles: {},
            titlesToDisplay: [],
            resultLimit: 200,

            searchValue: '',
            selectedOrganization: '',
            selectedDepartment: '',
            selectedStatus: PersonStatuses.ACTIVE.toString(),
            selectedPrivilege: '',
            selectedTitle: '',
            usersOrGroups: '',

            addVsRemove: ADD,

            alreadyGotInactiveUsers: false,

            showAdvancedVersion: false,
            showHideyResults: false,
            alreadyGotUsersWithAdvancedDetails: false,
            minimumDragAndDropIndex: this.props.minimumDragAndDropIndex || -1,

            lastActionText: 'Make a selection...',
            lastActionColor: 'darkgrey',
            undo: {
                item: null,
                lastAction: null
            }
        };
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleMouseDown);
        this.props.selectedUsersPlusGroups?.forEach(userOrGroup => {
            if (isPerson(userOrGroup)) {
                if (!userOrGroup.RIGHTSINENGLISH) {
                    userOrGroup.RIGHTSINENGLISH = produceRightsObjectFromString(userOrGroup.RIGHTS);
                }
            }
            if (isUserGroup(userOrGroup)) {
                userOrGroup.PEOPLE?.forEach(person => {
                    if (!person.RIGHTSINENGLISH) {
                        person.RIGHTSINENGLISH = produceRightsObjectFromString(person.RIGHTS);
                    }
                });
            }
        });

        const promisesToFulfill: Promise<any>[] = [];

        if (this.props.getUsers) {
            promisesToFulfill.push(this.getUsers());
        }
        if (this.props.getUsersWithAdvancedDetails) {
            promisesToFulfill.push(this.getUsersWithAdvancedDetails());
        }
        if (this.props.getCommittees) {
            promisesToFulfill.push(this.getCommittees());
        }
        if (this.props.getGroups) {
            promisesToFulfill.push(this.getUserGroups());
        }

        Promise.all(promisesToFulfill)
            .then(results => {
                const loadingData = results.reduce((acc, item) => ({ ...acc, ...item }), {});

                this.setState({
                    loading: false,
                    ...loadingData
                }, () => {
                    this.updateDepartmentsAndTitles();
                    this.filterEverything();
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleMouseDown);
    }

    componentDidUpdate(prevProps: UserPickerProps) {
        if (prevProps.selectedUsersPlusGroups !== this.props.selectedUsersPlusGroups) {
            this.setState({
                selectedUsersPlusGroups: this.props.selectedUsersPlusGroups
            });
        }
        if (prevProps.selectedCommittees !== this.props.selectedCommittees) {
            this.setState({
                selectedCommittees: this.props.selectedCommittees
            });
        }
    }

    getUsers() {
        return axios.get('/api/userpicker')
            .then(res => {
                if (res.data.failure) {
                    throw new Error('Something went wrong');
                }
                const results = (res.data.success.users as PERSON[])
                    .sort((a: PERSON, b: PERSON) => {
                        return sortLastNameThenFirstName(a, b);
                    });
                return { originalUsers: results };
            });
    }

    processIncomingAdvancedUsers(users: PERSON[]) {
        users.sort((a: PERSON, b: PERSON) => {
            return sortLastNameThenFirstName(a, b);
        });
        return {
            loading: false,
            originalUsers: users,
            filteredUsers: users,
        };
    }

    getUsersWithAdvancedDetails() {
        this.setState({
            // this first setState eliminates an edge case in which loading the component with advanced details
            // immediately, then clicking the gear icon to expand the user picker to the advanced version (while the
            // first API call was still in progress), would get the user details twice.
            alreadyGotUsersWithAdvancedDetails: true
        });
        return axios.get('/api/userpicker', {
            params: {
                detail: 'advanced',
                privileges: this.props.allowedPrivileges,
            }
        })
            .then(res => {
                if (res.data.failure) {
                    throw new Error('Something went wrong');
                }
                const advancedUserData = this.processIncomingAdvancedUsers(res.data.success.users);

                return {
                    ...advancedUserData,
                    organizations: res.data.success.organizations,
                    organizationsHash: res.data.success.organizationsHash,
                    departments: res.data.success.departments,
                    titles: res.data.success.titles,
                };
            })
            .catch(() => {
                this.setState({
                    // because we set it true at the beginning of this function
                    alreadyGotUsersWithAdvancedDetails: false
                });
            });
    }

    getInactiveUsers() {
        this.setState({
            loading: true
        });
        return axios.get('/api/userpicker', {
            params: {
                detail: 'advanced',
                privileges: this.props.allowedPrivileges,
                includeInactiveUsers: true,
            }
        })
            .then((res) => {
                if (res.data.failure) {
                    throw new Error('Something went wrong');
                }
                this.setState({
                    alreadyGotInactiveUsers: true
                });
                return this.processIncomingAdvancedUsers(this.state.originalUsers.concat(res.data.success.users));
            });
    }

    getCommittees() {
        return axios.get('/api/userpicker/committees')
            .then(res => {
                if (res.data.failure) {
                    throw new Error('Something went wrong');
                }
                return { originalCommittees: res.data.success };
            });
    }

    getUserGroups() {
        return axios.get('/api/userpicker/groups')
            .then(res => {
                if (res.data.failure) {
                    throw new Error('Something went wrong');
                }
                return { originalGroups: res.data.success.map(group => {
                    const people = group.PEOPLE.map(person => {
                        return {
                            ...person,
                            RIGHTSINENGLISH: produceRightsObjectFromString(person.RIGHTS)
                        };
                    });
                    return {
                        ...group,
                        PEOPLE: people
                    };
                }) };
            });
    }

    filterUsers() {
        if (this.state.usersOrGroups === 'Groups') {
            this.setState({
                filteredUsers: []
            });
            return;
        }

        const {
            searchValue,
            selectedOrganization,
            selectedDepartment,
            selectedPrivilege,
            selectedStatus,
            selectedTitle,
        } = this.state;

        const searchTerms = searchValue.toLowerCase().split(' ');

        const listToUse = this.state.addVsRemove === ADD ?
            this.state.originalUsers :
            this.state.selectedUsersPlusGroups.filter((userOrGroup: any) => {
                return userOrGroup.USERID;
            });

        this.setState({
            filteredUsers: listToUse.filter((result: any) => {

                if (this.state.addVsRemove === REMOVE) {
                    if (result.USERID) {
                        if (this.state.unremovablePeople[result.USERID]) {
                            return false;
                        }
                    }
                    else if (result.USERGROUPID) {
                        if (this.state.unremovableGroups[result.USERGROUPID]) {
                            return false;
                        }
                    }
                }

                if (selectedOrganization) {
                    if (result.ORGANIZATIONID && result.ORGANIZATIONID.toString() !== selectedOrganization.toString()) {
                        return false;
                    }
                }

                if (this.state.showAdvancedVersion) {
                    if (selectedDepartment) {
                        if (result.DEPARTMENT_NAME !== selectedDepartment) {
                            return false;
                        }
                    }
                    if (selectedPrivilege) {
                        if (
                            // they don't have the required privilege in their own org:
                            (!result.RIGHTSINENGLISH
                                || !result.RIGHTSINENGLISH[selectedPrivilege])
                            &&
                            // and they also don't have the required privilege in the current org:
                            (!result.PERSON_ORGANIZATIONS
                                || !result.PERSON_ORGANIZATIONS[0]
                                || !result.PERSON_ORGANIZATIONS[0].RIGHTSINENGLISH
                                || !result.PERSON_ORGANIZATIONS[0].RIGHTSINENGLISH[selectedPrivilege])
                        ) {
                            return false;
                        }
                    }
                    if (selectedStatus) {
                        if (!result.STATUSID || result.STATUSID.toString() !== selectedStatus) {
                            return false;
                        }
                    }
                    if (selectedTitle) {
                        if (result.TITLE !== selectedTitle) {
                            return false;
                        }
                    }
                }

                let passedAllTests = true;

                let fullName = '';
                if (result.FIRSTNAME) {
                    fullName += result.FIRSTNAME.toLowerCase();
                }
                if (result.LASTNAME) {
                    fullName += ' ' + result.LASTNAME.toLowerCase();
                }

                searchTerms.forEach((term: string) => {
                    if (!fullName && fullName !== searchValue) {
                        passedAllTests = false;
                    }
                    if (fullName && fullName.indexOf(term.toLowerCase()) === -1) {
                        passedAllTests = false;
                    }
                });

                return passedAllTests;

            })
        });

    }

    filterCommittees() {
        const searchTerms = this.state.searchValue.toLowerCase().split(' ');

        const listToUse = this.state.addVsRemove === ADD ?
            this.state.originalCommittees :
            this.state.selectedCommittees;

        this.setState({
            filteredCommittees: listToUse.filter((committee: COMMITTEE) => {

                if (this.state.addVsRemove === REMOVE && this.state.unremovableCommittees[committee.COMMITTEE_ID]) {
                    return false;
                }

                let passedAllTests = true;

                searchTerms.forEach((term: string) => {
                    if (committee.NAME && committee.NAME.toLowerCase().indexOf(term.toLowerCase()) === -1) {
                        passedAllTests = false;
                    }
                });

                return passedAllTests;
            })
        });
    }

    filterGroups() {
        if (this.state.usersOrGroups === 'Users'
            ||
            // when selecting a different org, hide all the groups:
            (this.context.organization?.ORGANIZATIONID
                && this.state.selectedOrganization
                && this.state.selectedOrganization.toString() !== this.context.organization.ORGANIZATIONID.toString()
            )
        ) {
            this.setState({
                filteredGroups: []
            });
            return;
        }

        const searchTerms = this.state.searchValue.toLowerCase().split(' ');

        const listToUse = this.state.addVsRemove === ADD ?
            this.state.originalGroups :
            this.state.selectedUsersPlusGroups.filter((userOrGroup: any) => {
                return userOrGroup.USERGROUPID;
            });

        this.setState({
            filteredGroups: listToUse.filter((group: USERGROUP) => {

                if (this.state.addVsRemove === REMOVE && this.state.unremovableGroups[group.USERGROUPID]) {
                    return false;
                }

                let passedAllTests = true;

                searchTerms.forEach((term: string) => {
                    if (group.NAME && group.NAME.toLowerCase().indexOf(term.toLowerCase()) === -1) {
                        passedAllTests = false;
                    }
                });

                return passedAllTests;
            })
        });
    }

    filterEverything() {
        if (this.state.originalUsers.length) {
            this.filterUsers();
        }
        if (this.state.originalCommittees.length) {
            this.filterCommittees();
        }
        if (this.state.originalGroups.length) {
            this.filterGroups();
        }
    }

    updateSearchValue(e: React.FormEvent<HTMLInputElement>) {
        this.setState({
            searchValue: e.currentTarget.value,
            resultLimit: 200,
        }, () => {
            this.filterEverything();
            if (this.searchResultsWindow.current) {
                this.searchResultsWindow.current.scrollTop = 0;
            }
        });
    }

    updateDepartmentsAndTitles() {
        let departmentsToDisplay: string[] = [];
        let titlesToDisplay: string[] = [];

        if (this.state.selectedOrganization === '') {
            // user has selected "Any Organization", so we have to get
            // all departments and titles from the entire multi-org network:
            const uniqueDepartments: { [key: string]: boolean } = {};
            Object.keys(this.state.departments).forEach((orgID: string) => {
                this.state.departments[orgID].forEach((dept: string) => {
                    if (!uniqueDepartments[dept]) {
                        departmentsToDisplay.push(dept);
                        uniqueDepartments[dept] = true;
                    }
                });
            });
            departmentsToDisplay = departmentsToDisplay
                .sort((a, b) => ('' + a).localeCompare(b));

            const uniqueTitles: { [key: string]: boolean } = {};
            Object.keys(this.state.titles).forEach((orgID: string) => {
                this.state.titles[orgID].forEach((title: string) => {
                    if (!uniqueTitles[title]) {
                        titlesToDisplay.push(title);
                        uniqueTitles[title] = true;
                    }
                });
            });
            titlesToDisplay = titlesToDisplay
                .sort((a, b) => ('' + a).localeCompare(b));
        }
        else if (this.state.selectedOrganization && this.state.departments[this.state.selectedOrganization]) {
            departmentsToDisplay = this.state.departments[this.state.selectedOrganization];
            titlesToDisplay = this.state.titles[this.state.selectedOrganization];
        }
        this.setState({
            departmentsToDisplay: departmentsToDisplay,
            titlesToDisplay: titlesToDisplay
        });
    }

    selectOrganization(e: React.FormEvent<HTMLSelectElement>) {
        const targetOrgID = e.currentTarget.value;
        this.setState({
            selectedOrganization: targetOrgID,
            selectedDepartment: '',
            selectedTitle: '',
            resultLimit: 200,
        }, async () => {
            this.updateDepartmentsAndTitles();
            this.filterEverything();
        });
    }

    checkToShowUsersOnly() {
        if (this.state.selectedDepartment !== ''
            || this.state.selectedPrivilege !== ''
            || this.state.selectedTitle !== ''
        ) {
            this.setState({
                usersOrGroups: 'Users'
            }, () => {
                this.filterEverything();
            });
        }
    }

    selectDepartment(e: React.FormEvent<HTMLSelectElement>) {
        this.setState({
            selectedDepartment: e.currentTarget.value,
            resultLimit: 200,
        }, () => {
            this.filterUsers();
            this.checkToShowUsersOnly();
        });
    }

    selectPrivilege(e: React.FormEvent<HTMLSelectElement>) {
        this.setState({
            selectedPrivilege: e.currentTarget.value,
            resultLimit: 200
        }, () => {
            this.filterUsers();
            this.checkToShowUsersOnly();
        });
    }

    selectStatus(e: React.FormEvent<HTMLSelectElement>) {
        const eventValue: string = e.currentTarget.value;
        // Assigning the event value to a new variable because React will get mad
        // if we try to use e.currentTarget.value in a .then() callback
        this.setState({
            selectedStatus: eventValue,
            resultLimit: 200,
        }, () => {
            if (!this.state.alreadyGotInactiveUsers && eventValue !== PersonStatuses.ACTIVE.toString()) {
                this.getInactiveUsers().then((result) => {
                    this.setState({
                        ...result
                    }, () => {
                        this.filterUsers();
                    });
                });
            } else {
                this.filterUsers();
            }
        });
    }

    selectUsersOrGroups(e: React.FormEvent<HTMLSelectElement>) {
        this.setState({
            usersOrGroups: e.currentTarget.value,
            resultLimit: 200
        }, () => {
            if (this.state.usersOrGroups === '' || this.state.usersOrGroups === 'Groups') {
                this.setState({
                    selectedDepartment: '',
                    selectedPrivilege: '',
                    selectedTitle: ''
                }, () => {
                    this.filterEverything();
                });
            }
            else {
                this.filterEverything();
            }
        });
    }

    selectTitle(e: React.FormEvent<HTMLSelectElement>) {
        this.setState({
            selectedTitle: e.currentTarget.value,
            resultLimit: 200
        }, () => {
            this.filterUsers();
            this.checkToShowUsersOnly();
        });
    }

    async addUser(addedUser: PERSON) {
        await this.props.addUserSignature(this.state.selectedUsersPlusGroups.concat(addedUser));
        this.setState({
            lastActionText: `Added "${constructFullName(addedUser, 'lastFirstMiddle')}"`,
            lastActionColor: 'limegreen',
            undo: {
                item: addedUser,
                lastAction: ADD
            }
        });
        if (!this.state.showAdvancedVersion) {
            // clear the search field because you can't see the results anymore
            this.setState({
                searchValue: ''
            }, () => {
                this.filterEverything();
            });
        }
    }

    async undoAddUser(removedUser: PERSON) {
        const newSelectedUsersPlusGroups = this.state.selectedUsersPlusGroups.filter((userOrGroup: any) => {
            if (userOrGroup.USERID) {
                return removedUser.USERID !== userOrGroup.USERID;
            }
            else {
                return true;
            }
        });
        await this.props.removeUserSignature(newSelectedUsersPlusGroups);
        this.setState({
            lastActionText: `Removed "${constructFullName(removedUser, 'lastFirstMiddle')}"`,
            lastActionColor: 'red',
            undo: {
                item: removedUser,
                lastAction: REMOVE
            }
        });
        if (this.state.addVsRemove === REMOVE) {
            this.filterUsers();
        }
    }

    async addCommittee(addedCommittee: COMMITTEE) {
        await this.props.addCommitteeSignature(
            this.state.selectedCommittees.concat(addedCommittee),
            this.props.groupNumber);
        this.setState({
            lastActionText: `Added "${addedCommittee.NAME}"`,
            lastActionColor: 'limegreen',
            undo: {
                item: addedCommittee,
                lastAction: ADD
            }
        });

        if (!this.state.showAdvancedVersion) {
            // clear the search field because you can't see the results anymore
            this.setState({
                searchValue: ''
            }, () => {
                this.filterEverything();
            });
        }
    }

    async undoAddCommittee(removedCommittee: COMMITTEE) {
        const newCommitteeGroup = this.state.selectedCommittees.filter((committee: COMMITTEE) => {
            return removedCommittee.COMMITTEE_ID !== committee.COMMITTEE_ID;
        });
        await this.props.removeCommitteeSignature(newCommitteeGroup, this.props.groupNumber);
        this.setState({
            lastActionText: `Removed "${removedCommittee.NAME}"`,
            lastActionColor: 'red',
            undo: {
                item: removedCommittee,
                lastAction: REMOVE
            }
        });
        if (this.state.addVsRemove === REMOVE) {
            this.filterCommittees();
        }
    }

    async addGroup(addedGroup: USERGROUP) {
        await this.props.addGroupSignature(this.state.selectedUsersPlusGroups.concat(addedGroup));
        this.setState({
            lastActionText: `Added "${addedGroup.NAME}"`,
            lastActionColor: 'limegreen',
            undo: {
                item: addedGroup,
                lastAction: ADD
            }
        });
        if (!this.state.showAdvancedVersion) {
            // clear the search field because you can't see the results anymore
            this.setState({
                searchValue: ''
            }, () => {
                this.filterEverything();
            });
        }
    }

    async undoAddGroup(removedGroup: USERGROUP) {
        const newSelectedUsersPlusGroups = this.state.selectedUsersPlusGroups.filter((userOrGroup: any) => {
            if (userOrGroup.USERGROUPID) {
                return removedGroup.USERGROUPID !== userOrGroup.USERGROUPID;
            }
            else {
                return true;
            }
        });
        await this.props.removeGroupSignature(newSelectedUsersPlusGroups);
        this.setState({
            lastActionText: `Removed "${removedGroup.NAME}"`,
            lastActionColor: 'red',
            undo: {
                item: removedGroup,
                lastAction: REMOVE
            }
        });
        if (this.state.addVsRemove === REMOVE) {
            this.filterGroups();
        }
    }

    async undo() {
        let savedList: any;
        if (Array.isArray(this.state.undo.item)) {
            savedList = this.state.undo.item.slice();
        }
        else {
            savedList = [this.state.undo.item];
        }

        const usersPlusGroups: any = [];
        const committees: COMMITTEE[] = [];

        savedList.forEach((itemOfSomeKind: any) => {
            if (itemOfSomeKind.USERID || itemOfSomeKind.USERGROUPID) {
                usersPlusGroups.push(itemOfSomeKind);
            }
            else if (itemOfSomeKind.COMMITTEE_ID) {
                committees.push(itemOfSomeKind);
            }
        });

        if (usersPlusGroups.length) {
            if (this.state.undo.lastAction === ADD) {
                const newUsersPlusGroups = this.state.selectedUsersPlusGroups.filter((selectedUserOrGroup: any) => {
                    return !usersPlusGroups.some((userOrGroup: any) => {
                        if (selectedUserOrGroup.USERID && userOrGroup.USERID) {
                            return selectedUserOrGroup.USERID === userOrGroup.USERID;
                        }
                        else if (selectedUserOrGroup.USERGROUPID && userOrGroup.USERGROUPID) {
                            return selectedUserOrGroup.USERGROUPID === userOrGroup.USERGROUPID;
                        }
                        return false;
                    });
                });
                await this.props.removeUserSignature(newUsersPlusGroups);
            }
            else if (this.state.undo.lastAction === REMOVE) {
                await this.props.addUserSignature(this.state.selectedUsersPlusGroups.concat(usersPlusGroups));
            }
        }

        if (committees.length) {
            if (this.state.undo.lastAction === ADD) {
                const newCommittees = this.state.selectedCommittees.filter((selectedCommittee: COMMITTEE) => {
                    return !committees.some((committee: COMMITTEE) => {
                        return committee.COMMITTEE_ID === selectedCommittee.COMMITTEE_ID;
                    });
                });
                await this.props.removeCommitteeSignature(newCommittees, this.props.groupNumber);
            }
            else if (this.state.undo.lastAction === REMOVE) {
                await this.props.addCommitteeSignature(this.state.selectedCommittees.concat(committees), this.props.groupNumber);
            }
        }

        if (this.state.addVsRemove === REMOVE) {
            this.filterEverything();
        }

        this.setState({
            lastActionText: `${this.state.undo.lastAction === ADD ? 'Removed' : 'Added'} ${savedList.length} item(s).`,
            lastActionColor: this.state.undo.lastAction === ADD ? 'red' : 'limegreen',
            undo: {
                item: savedList,
                lastAction: this.state.undo.lastAction === ADD ? REMOVE : ADD,
            }
        });
    }

    async selectAll() {
        let newUsersToAdd = [];
        let newGroupsToAdd = [];
        let newCommitteesToAdd = [];

        if (this.props.getUsers || this.props.getUsersWithAdvancedDetails || this.props.getGroups) {
            if (this.state.filteredUsers.length) {
                newUsersToAdd = this.state.filteredUsers.filter((user: PERSON) => {
                    return !this.state.selectedUsersPlusGroups.some((userOrGroup: any) => {
                        return userOrGroup.USERID && userOrGroup.USERID === user.USERID;
                    });
                });
                await this.props.addUserSignature(this.state.selectedUsersPlusGroups.concat(newUsersToAdd));
            }

            if (this.state.filteredGroups.length) {
                newGroupsToAdd = this.state.filteredGroups.filter((group: USERGROUP) => {
                    return !this.state.selectedUsersPlusGroups.some((userOrGroup: any) => {
                        return userOrGroup.USERGROUPID && userOrGroup.USERGROUPID === group.USERGROUPID;
                    }) && !TooltipBuilder.getMembersLackingPrivileges(group, this.props.allowedPrivileges).length;
                });
                await this.props.addGroupSignature(this.state.selectedUsersPlusGroups.concat(newGroupsToAdd));
            }
        }

        else if (this.props.getCommittees) {
            if (this.state.filteredCommittees.length) {
                newCommitteesToAdd = this.state.filteredCommittees.filter((committee: COMMITTEE) => {
                    if (this.props.committeeApprovalGroupsMasterList) {
                        return !this.props.committeeApprovalGroupsMasterList.some((committeeGroup: COMMITTEE[]) => {
                            return committeeGroup.some((selectedCommittee: COMMITTEE) => {
                                return committee.COMMITTEE_ID === selectedCommittee.COMMITTEE_ID;
                            });
                        });
                    }
                    else {
                        return !this.state.selectedCommittees.some((selectedCommittee: COMMITTEE) => {
                            return committee.COMMITTEE_ID === selectedCommittee.COMMITTEE_ID;
                        });
                    }
                });
                await this.props.addCommitteeSignature(
                    this.state.selectedCommittees.concat(newCommitteesToAdd),
                    this.props.groupNumber);
            }
        }

        this.setState({
            lastActionText: `Added ${newUsersToAdd.length + newGroupsToAdd.length + newCommitteesToAdd.length} item(s).`,
            lastActionColor: 'limegreen',
            undo: {
                item: newUsersToAdd.concat(newGroupsToAdd).concat(newCommitteesToAdd),
                lastAction: ADD
            }
        });
    }

    async removeAll() {
        const newUsersToRemove = this.state.filteredUsers.slice();
        const newGroupsToRemove = this.state.filteredGroups.slice();
        const newCommitteesToRemove = this.state.filteredCommittees.slice();

        if (this.props.getUsers || this.props.getUsersWithAdvancedDetails || this.props.getGroups) {
            const newUsersPlusGroups = this.state.selectedUsersPlusGroups.filter((userOrGroup: any) => {
                if (userOrGroup.USERID) {
                    return !this.state.filteredUsers.some((user: PERSON) => {
                        return userOrGroup.USERID === user.USERID;
                    }) && !this.state.unremovablePeople[userOrGroup.USERID];
                }
                else if (userOrGroup.USERGROUPID) {
                    return !this.state.filteredGroups.some((group: USERGROUP) => {
                        return userOrGroup.USERGROUPID === group.USERGROUPID;
                    }) && !this.state.unremovableGroups[userOrGroup.USERGROUPID];
                }
                return false;
            });
            await this.props.removeUserSignature(newUsersPlusGroups);
        }
        else if (this.props.getCommittees) {
            const newCommittees = this.state.selectedCommittees.filter((committee: COMMITTEE) => {
                return !this.state.filteredCommittees.some((innerCommittee: COMMITTEE) => {
                    return committee.COMMITTEE_ID === innerCommittee.COMMITTEE_ID;
                }) && !this.state.unremovableCommittees[committee.COMMITTEE_ID];
            });
            await this.props.removeCommitteeSignature(newCommittees, this.props.groupNumber);
        }
        this.filterEverything();

        this.setState({
            lastActionText: `Removed ${newUsersToRemove.length + newGroupsToRemove.length + newCommitteesToRemove.length} item(s).`,
            lastActionColor: 'red',
            undo: {
                item: newUsersToRemove.concat(newGroupsToRemove).concat(newCommitteesToRemove),
                lastAction: REMOVE
            }
        });
    }

    addVsRemove(e: React.FormEvent<HTMLSelectElement>) {
        this.setState({
            addVsRemove: e.currentTarget.value
        }, () => {
            this.filterEverything();
        });
    }

    handleScroll() {
        const resultsDiv = this.searchResultsWindow.current;
        if (resultsDiv) {
            if (resultsDiv.scrollTop > (resultsDiv.scrollHeight - resultsDiv.offsetHeight - 40)) {
                if (this.state.resultLimit + 200 >= this.state.filteredUsers.length) {
                    this.setState({
                        resultLimit: this.state.filteredUsers.length
                    });
                } else {
                    this.setState({
                        resultLimit: this.state.resultLimit + 200
                    });
                }
            }
        }
    }

    openAdvancedVersion() {
        if (this.props.getUsers || this.props.getUsersWithAdvancedDetails) {
            if (!this.state.alreadyGotUsersWithAdvancedDetails) {
                this.getUsersWithAdvancedDetails().then((result) => {
                    this.setState({
                        ...result
                    }, () => {
                        this.updateDepartmentsAndTitles();
                        this.filterUsers();
                    });
                });
            }
        }
        this.setState({
            showAdvancedVersion: true
        });
    }

    closeAdvancedVersion() {
        this.setState({
            showAdvancedVersion: false,
            selectedOrganization: '',
            selectedDepartment: '',
            selectedPrivilege: '',
            selectedTitle: '',
            usersOrGroups: '',
            addVsRemove: ADD
        }, () => {
            if (this.state.alreadyGotInactiveUsers) {
                this.setState({
                    selectedStatus: ''
                }, () => {
                    this.filterEverything();
                });
            } else {
                this.filterEverything();
            }
        });
    }

    searchFieldFocus() {
        if (!this.state.showAdvancedVersion) {
            this.setState({
                showHideyResults: true
            });
        }
    }

    handleMouseDown(event: MouseEvent) {
        if (this.state.showAdvancedVersion || !this.state.showHideyResults) {
            return;
        }

        // if we're not clicking the scrollbar (i.e. clicking a search result, outside the search results window, etc.), then hide the search results window.
        const clickingScrollBar = event.target === this.searchResultsWindow.current;
        if (!clickingScrollBar) {
            this.setState({
                showHideyResults: false
            });
        }
    }

    render() {

        let users = null;
        if (this.state.usersOrGroups === '' || this.state.usersOrGroups === 'Users') {
            users = this.state.filteredUsers.slice(0, this.state.resultLimit).map((result: PERSON, idx: number) => {
                let status: JSX.Element | null = null;
                let assignedInGroup: any = null;
                let alreadySelected = false;
                let homeOrg: JSX.Element | null = null;
                if (result.ORGANIZATIONID && this.context.organization?.ORGANIZATIONID && result.ORGANIZATIONID.toString() !== this.context.organization.ORGANIZATIONID.toString()) {
                    homeOrg = <>
                        <span style={{ color: 'darkgrey' }}>[{this.state.organizationsHash[result.ORGANIZATIONID]}]</span>
                    </>;
                }

                this.state.selectedUsersPlusGroups.forEach((selectedUserOrGroup: any) => {
                    if (selectedUserOrGroup.USERID) {
                        if (result.USERID === selectedUserOrGroup.USERID && this.state.addVsRemove === ADD) {
                            alreadySelected = true;
                        }
                    }
                    else if (selectedUserOrGroup.USERGROUPID && !assignedInGroup) {
                        selectedUserOrGroup.PEOPLE.some((personInAGroup: any) => {
                            if (result.USERID === personInAGroup.USERID && this.state.addVsRemove === ADD) {
                                assignedInGroup = <span style={{ color: 'darkorange' }}>(Assigned in Group: "{selectedUserOrGroup.NAME}")</span>;
                                alreadySelected = true;
                                return true;
                            }
                            return false;
                        });
                    }
                });
                const isDisabled = alreadySelected ? 'userpicker-disabled-result' : '';

                if (result.STATUSID === PersonStatuses.INACTIVE) {
                    status = <>
                        <span style={alreadySelected ? { color: 'darkgrey' } : { color: 'red' }}>  (inactive)</span>
                    </>;
                }

                return (
                    <li
                        key={idx}
                        onMouseDown={
                            // using onMouseDown because it runs before onBlur, which is used
                            // elsewhere to hide the popup window for search results. An onClick would
                            // happen too late and the window disappears before the click can work.
                            alreadySelected ? () => { } : (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
                                if (e.buttons === leftMouseButtonClick) {
                                    if (this.state.addVsRemove === ADD) {
                                        this.addUser(result);
                                    }
                                    else {
                                        this.undoAddUser(result);
                                    }

                                }
                            }
                        }
                        className={isDisabled}
                    >

                        <Person className={`userpicker-lucidocicon-user ${isDisabled}`} />

                        {constructFullName(result, 'lastFirstMiddle')}{status} {assignedInGroup} {homeOrg}
                    </li>
                );
            });
        }

        let committees = null;
        if (this.state.usersOrGroups === '' || this.state.usersOrGroups === 'Committees') {
            committees = this.state.filteredCommittees.map((committee: COMMITTEE, idx: number) => {

                let alreadySelected = this.state.selectedCommittees.some((selectedCommittee: COMMITTEE) => {
                    return committee.COMMITTEE_ID === selectedCommittee.COMMITTEE_ID && this.state.addVsRemove === ADD;
                });

                let selectedInGroupNumber: JSX.Element | null = null;
                if (!alreadySelected && this.props.committeeApprovalGroupsMasterList) {
                    // what happens with two user pickers on the page? the userpicker has to map through
                    // all of THOSE user pickers to see if something has been selected.
                    alreadySelected = this.props.committeeApprovalGroupsMasterList.some((committeeGroup: COMMITTEE[], idx: number) => {
                        return (committeeGroup.some((committeeFromAnotherUserPicker: COMMITTEE) => {
                            if (committee.COMMITTEE_ID === committeeFromAnotherUserPicker.COMMITTEE_ID && this.state.addVsRemove === ADD) {
                                selectedInGroupNumber = (<>
                                    <span style={{ color: 'darkorange' }}> (Already in Group {idx + 1})</span>
                                </>);
                                return true;
                            }
                            return false;
                        }));
                    });
                }

                const isDisabled = alreadySelected ? 'userpicker-disabled-result' : '';
                const members = TooltipBuilder.generateCommitteeMembersAsTooltip(committee);
                const isBoard = committee.IS_BOARD ? (<span style={{ color: alreadySelected ? 'darkgrey' : 'grey' }}>(Board)</span>) : null;

                return (
                    <li
                        key={idx}
                        onMouseDown={
                            alreadySelected ? () => { } : (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
                                if (e.buttons === leftMouseButtonClick) {
                                    if (this.state.addVsRemove === ADD) {
                                        this.addCommittee(committee);
                                    }
                                    else {
                                        this.undoAddCommittee(committee);
                                    }
                                }
                            }
                        }
                        className={isDisabled}
                    >
                        <img
                            src={adjourn}
                            title={members}
                            className={`userpicker-gavel-icon ${isDisabled}`}
                            alt="gavel icon"
                        />
                        {committee.NAME} {isBoard} {selectedInGroupNumber}
                    </li>
                );
            });
        }

        const selectedCommittees = this.state.selectedCommittees.map((committee: COMMITTEE, idx: number) => {
            const members = TooltipBuilder.generateCommitteeMembersAsTooltip(committee);
            const isBoard = committee.IS_BOARD ? (<span style={{ color: 'grey' }}>(Board)</span>) : null;
            const unremovable = this.state.unremovableCommittees[committee.COMMITTEE_ID] || '';

            return (
                <Draggable
                    draggableId={'committee' + committee.COMMITTEE_ID}
                    index={idx}
                    key={'committee-' + committee.COMMITTEE_ID}
                    isDragDisabled={true || /* always disabled because committee signing isn't ordered, so it doesn't matter; if desirable, remove "true" and just check these: */
                        this.props.isDragDisabled
                        || unremovable
                        || idx < this.state.minimumDragAndDropIndex
                    }
                >
                    {provided => (
                        <li
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            title={unremovable}
                            className={unremovable ? 'userpicker-disabled-result' : ''}
                        >
                            <span>
                                <img
                                    src={adjourn}
                                    title={members}
                                    className={'userpicker-gavel-icon' + (unremovable ? ' userpicker-disabled-result' : '')}
                                    alt="gavel icon"
                                />
                                {committee.NAME} {isBoard}
                            </span>

                            {!unremovable &&
                                <Cancel
                                    className={'icon ' + (unremovable ? 'userpicker-disabled-result' : 'userpicker-lucidocicon-cancel')}
                                    onClick={unremovable ? () => { } :
                                        this.undoAddCommittee.bind(this, committee)
                                    }
                                />
                            }
                        </li>
                    )}
                </Draggable>
            );
        });

        let groups = null;
        if (this.state.usersOrGroups === '' || this.state.usersOrGroups === 'Groups') {
            groups = this.state.filteredGroups.map((group: USERGROUP, idx: number) => {

                if (this.props.hideArchivedGroups && group.STATUS === 'archived') {
                    return null;
                }


                const alreadySelected = this.state.selectedUsersPlusGroups.some((selectedUserOrGroup: any) => {
                    if (selectedUserOrGroup.USERGROUPID) {
                        if (group.USERGROUPID === selectedUserOrGroup.USERGROUPID && this.state.addVsRemove === ADD) {
                            return true;
                        }
                    }
                    return false;
                });
                const members = TooltipBuilder.generateGroupMembersAsTooltip(group);
                const membersLackingPrivilegesWarning = TooltipBuilder.generateHTMLforMembersLackingPrivileges(group, this.props.allowedPrivileges);
                const emptyGroupWarning = group.PEOPLE && group.PEOPLE.length ? null : (<span style={{ color: 'orange' }}>[No members]</span>);

                const isDisabled = alreadySelected || ((membersLackingPrivilegesWarning || emptyGroupWarning) && this.state.addVsRemove === ADD) ?
                    'userpicker-disabled-result' : '';

                return (
                    <li
                        key={idx}
                        onMouseDown={
                            isDisabled ? () => { } : (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
                                if (e.buttons === leftMouseButtonClick) {
                                    if (this.state.addVsRemove === ADD) {
                                        this.addGroup(group);
                                    }
                                    else {
                                        this.undoAddGroup(group);
                                    }
                                }
                            }
                        }
                        className={isDisabled}
                    >
                        <Tooltip title={members}>
                            <Group className={`userpicker-lucidocicon-group ${isDisabled}`} />
                        </Tooltip>

                        {group.NAME} {membersLackingPrivilegesWarning} {emptyGroupWarning}
                    </li>
                );
            });
        }

        const selectedUsersPlusGroups = this.state.selectedUsersPlusGroups.map((userOrGroup: any, idx: number) => {
            if (userOrGroup.USERID) {
                let status: any = null;
                if (userOrGroup.STATUSID === PersonStatuses.INACTIVE) {
                    status = (<span style={{ color: 'red' }}>  (inactive)</span>);
                }
                let userDoublyAssigned: any = null;
                this.state.selectedUsersPlusGroups.some((userOrGroupInTheSomeFunction: any) => {
                    if (userOrGroupInTheSomeFunction.USERGROUPID) {
                        userOrGroupInTheSomeFunction.PEOPLE.some((person: PERSON) => {
                            if (person.USERID === userOrGroup.USERID) {
                                userDoublyAssigned = (<span style={{ color: 'darkorange' }}>{` (Also in Group: "${userOrGroupInTheSomeFunction.NAME}")`}</span>);
                                return true;
                            }
                            return false;
                        });
                    }
                    return false;
                });
                const unremovable = this.state.unremovablePeople[userOrGroup.USERID] || '';

                return (
                    <Draggable
                        draggableId={'person' + userOrGroup.USERID}
                        index={idx}
                        key={'person' + userOrGroup.USERID}
                        isDragDisabled={this.props.isDragDisabled || idx < this.state.minimumDragAndDropIndex}
                    >
                        {provided => (
                            <li
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                                title={unremovable}
                                className={unremovable ? 'userpicker-disabled-result' : ''}
                            >
                                <span>
                                    <Tooltip title="User Icon Tooltip">
                                        <Person className={`userpicker-lucidocicon-user ${unremovable ? 'userpicker-disabled-result' : ''}`} />
                                    </Tooltip>

                                    {constructFullName(userOrGroup, 'lastFirstMiddle')}{status}{userDoublyAssigned}
                                </span>

                                {!unremovable &&
                                    <Cancel
                                        className={'icon' + (unremovable ? ' userpicker-disabled-result' : ' userpicker-lucidocicon-cancel')}
                                        onClick={unremovable ? () => { } : this.undoAddUser.bind(this, userOrGroup)}
                                    />

                                }

                            </li>
                        )}
                    </Draggable>
                );
            }
            if (userOrGroup.USERGROUPID) {

                const members = TooltipBuilder.generateGroupMembersAsTooltip(userOrGroup);
                const membersLackingPrivilegesWarning = TooltipBuilder.generateHTMLforMembersLackingPrivileges(userOrGroup, this.props.allowedPrivileges);
                const emptyGroupWarning = userOrGroup.PEOPLE && userOrGroup.PEOPLE.length ? null : (<span style={{ color: 'orange' }}>[No members]</span>);
                const unremovable = this.state.unremovableGroups[userOrGroup.USERGROUPID] || '';

                return (
                    <Draggable
                        draggableId={'group' + userOrGroup.USERGROUPID}
                        index={idx}
                        key={'usergroup-' + userOrGroup.USERGROUPID}
                        isDragDisabled={this.props.isDragDisabled || idx < this.state.minimumDragAndDropIndex}
                    >
                        {provided => (
                            <li
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                                title={unremovable}
                                className={unremovable ? 'userpicker-disabled-result' : ''}
                            >
                                <span>
                                    <Tooltip title={members}>
                                        <Group className={`userpicker-lucidocicon-group ${unremovable ? 'userpicker-disabled-result' : ''}`} />
                                    </Tooltip>
                                    {userOrGroup.NAME} {membersLackingPrivilegesWarning} {emptyGroupWarning}
                                </span>

                                {!unremovable &&
                                    <Cancel
                                        className={'icon' + (unremovable ? ' userpicker-disabled-result' : ' userpicker-lucidocicon-cancel')}
                                        onClick={unremovable ? () => { } : this.undoAddGroup.bind(this, userOrGroup)}
                                    />
                                }

                            </li>
                        )}
                    </Draggable>
                );
            }
            return undefined; // just a TypeScript return value check
        });

        const organizations = this.state.organizations.map((org: ORGANIZATION, idx: number) => {
            return (
                <option key={idx} value={org.ORGANIZATIONID}>{org.NAME}</option>
            );
        });

        const departments = this.state.departmentsToDisplay.map((department: string, idx: number) => {
            return (
                <option key={idx} value={department}>{department}</option>
            );
        });

        const titles = this.state.titlesToDisplay.map((title: string, idx: number) => {
            return (
                <option key={idx} value={title}>{title}</option>
            );
        });

        return (
            <div
                className={'userpicker' + (this.props.disabled ? ' userpicker-disabled-entirely' : '')}
                title={this.props.disabled ? 'Disabled' : ''}
            >

                <Droppable
                    droppableId={(typeof this.props.groupNumber === 'undefined' ? 'usersPlusGroupsDropZone' : 'committeeDropZone' + this.props.groupNumber)}
                    type={
                        this.props.getCommittees ?
                            'userpicker-committee' + (this.props.disabled ? '-disabled' : '') : // the "-disabled" part will make it impossible to drag a committee from another group and drop it into this one
                            'userpicker-person-or-group'}
                >
                    {provided => (
                        <div
                            className="userpicker-selected-results-view"
                            data-cy={'userpicker-selected-results'}
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >

                            {this.state.selectedCommittees.length > 0 &&
                                <ul>
                                    {selectedCommittees}
                                </ul>
                            }

                            {this.state.selectedUsersPlusGroups.length > 0 &&
                                <ul>
                                    {selectedUsersPlusGroups}
                                </ul>
                            }

                            {provided.placeholder}

                        </div>
                    )}
                </Droppable>

                <div className="userpicker-search-row">
                    <input
                        type="text"
                        value={this.state.searchValue}
                        className={'userpicker-input' + (this.state.searchValue ? ' userpicker-selected' : ' userpicker-unselected')}
                        placeholder="Search me..."
                        onChange={(e) => this.updateSearchValue(e)}
                        onFocus={this.searchFieldFocus.bind(this)}
                        data-cy={'userpicker-input'}
                        autoComplete="off"
                    />
                    {this.state.showAdvancedVersion &&
                        <select
                            value={this.state.addVsRemove}
                            onChange={(e) => this.addVsRemove(e)}
                            data-cy={'userpicker-add-vs-remove-menu'}
                            className={this.state.addVsRemove === REMOVE ? 'userpicker-selected' : 'userpicker-unselected'}
                        >
                            <option value={ADD}>Add</option>
                            <option value={REMOVE}>Remove</option>
                        </select>
                    }

                    <Tooltip title={this.state.showAdvancedVersion ? 'Close advanced options' : 'Open advanced options'}>
                        <Settings
                            className="userpicker-search-gear"
                            data-cy={'userpicker-advanced-version-gear'}
                            onClick={this.state.showAdvancedVersion ?
                                this.closeAdvancedVersion.bind(this) : this.openAdvancedVersion.bind(this)
                            }
                        />
                    </Tooltip>

                </div>

                {this.state.showAdvancedVersion && !this.props.getCommittees &&
                    <React.Fragment>

                        <div className="userpicker-select-container">
                            <select
                                data-cy={'userpicker-organization-select-menu'}
                                value={this.state.selectedOrganization}
                                onChange={(e) => this.selectOrganization(e)}
                                className={this.state.selectedOrganization ? 'userpicker-selected' : 'userpicker-unselected'}
                            >
                                <option value="" disabled={organizations.length === 1}>Any Organization</option>
                                {organizations}
                            </select>

                            <select
                                data-cy={'userpicker-department-select-menu'}
                                value={this.state.selectedDepartment}
                                onChange={(e) => this.selectDepartment(e)}
                                className={this.state.selectedDepartment ? 'userpicker-selected' : 'userpicker-unselected'}
                            >
                                <option value="">Any Department</option>
                                {departments}
                            </select>

                            <select
                                data-cy={'userpicker-privilege-select-menu'}
                                value={this.state.selectedPrivilege}
                                onChange={(e) => this.selectPrivilege(e)}
                                className={this.state.selectedPrivilege ? 'userpicker-selected' : 'userpicker-unselected'}
                            >
                                <option value="">Any Privilege</option>
                                <option value="reader">Reader</option>
                                <option value="signer">Signer</option>
                                <option value="author">Author</option>
                                <option value="courseWriter">Course Writer</option>
                                <option value="documentAdministrator">Document Administrator</option>
                                <option value="userAdministrator">User Administrator</option>
                                <option value="organizationAdministrator">Organization Administrator</option>
                            </select>
                        </div>

                        <div className="userpicker-select-container">
                            <select
                                data-cy={'userpicker-status-select-menu'}
                                value={this.state.selectedStatus}
                                onChange={(e) => this.selectStatus(e)}
                                className={this.state.selectedStatus ? 'userpicker-selected' : 'userpicker-unselected'}
                            >
                                <option value="" disabled={!this.props.allowedStatuses || (this.props.allowedStatuses && this.props.allowedStatuses.length === 1)}>Any Status</option>
                                <option value={PersonStatuses.ACTIVE}>Active</option>
                                <option value={PersonStatuses.INACTIVE} disabled={!this.props.allowedStatuses || (this.props.allowedStatuses && !this.props.allowedStatuses.includes(PersonStatuses.INACTIVE))}>Inactive</option>
                            </select>

                            <select
                                data-cy={'userpicker-usersorgroups-select-menu'}
                                value={this.state.usersOrGroups}
                                className={this.state.usersOrGroups ? 'userpicker-selected' : 'userpicker-unselected'}
                                onChange={(e) => this.selectUsersOrGroups(e)}
                            >
                                <option value="">Any Grouping</option>
                                <option value="Users">Users</option>
                                <option value="Groups">Groups</option>
                            </select>

                            <select
                                data-cy={'userpicker-title-select-menu'}
                                value={this.state.selectedTitle}
                                onChange={(e) => this.selectTitle(e)}
                                className={this.state.selectedTitle ? 'userpicker-selected' : 'userpicker-unselected'}
                            >
                                <option value="">Any Title</option>
                                {titles}
                            </select>
                        </div>

                    </React.Fragment>
                }

                <div
                    ref={this.searchResultsWindow}
                    className=
                    {'userpicker-results-view'
                        + (this.state.showAdvancedVersion ? '' : ' userpicker-results-view-hidey-results')
                        + (!this.state.showHideyResults && !this.state.showAdvancedVersion ? ' userpicker-results-view-hiding' : '')
                    }
                    onScroll={this.handleScroll.bind(this)}
                    data-cy={'userpicker-search-results'}
                >
                    {this.state.loading &&
                        <p className="userpicker-loading-indicator">Loading...</p>
                    }

                    {!this.state.loading && this.state.filteredCommittees.length > 0 &&
                        <ul>
                            {committees}
                        </ul>
                    }

                    {!this.state.loading && this.state.filteredGroups.length > 0 &&
                        <ul>
                            {groups}
                        </ul>
                    }

                    {!this.state.loading && this.state.filteredUsers.length > 0 &&
                        <ul>
                            {users}
                        </ul>
                    }

                </div>

                {this.state.showAdvancedVersion &&
                    <span className="userpicker-last-action-outer-row">
                        <span className="userpicker-last-action-inner-row">
                            <button
                                disabled={!this.state.undo.item}
                                className="userpicker-undo-button"
                                data-cy={'userpicker-undo-button'}
                                onClick={this.undo.bind(this)}
                            >
                                Undo
                            </button>
                            <p className="userpicker-last-action-text"
                                style={{ color: this.state.lastActionColor }}
                            >
                                {this.state.lastActionText.length > 50 ?
                                    this.state.lastActionText.slice(0, 50) + '..."' :
                                    this.state.lastActionText
                                }
                            </p>
                        </span>

                        {this.state.addVsRemove === ADD ?
                            <button
                                onClick={this.selectAll.bind(this)}
                                data-cy={'userpicker-select-or-remove-all-button'}
                            >Select All</button>
                            :
                            <button
                                onClick={this.removeAll.bind(this)}
                                data-cy={'userpicker-select-or-remove-all-button'}
                            >Remove All</button>
                        }

                    </span>
                }

            </div>
        );
    }
}

export default UserPicker;

