import React, { useContext, useRef } from 'react';

import { CONSENT_AGENDA } from '@/entities/org/CONSENT_AGENDA';
import { useBrowserQuery } from '@/hooks/useBrowserQuery';
import { DataTable, DataTableHandle } from '@/ui/ui/datatable/data-table';
import { CommitteesQuery } from '../../Committees';
import ArchivedAgendasTableToolbar from '@/v2/components/consentAgendaTable/ArchivedAgendasTableToolbar';
import { createSortableDate } from '@/_shared/utils/docManagerFunctions';
import { PaginationToolsForTable } from '@/ui/ui/datatable/pagination-tools';
import useSWR from 'swr';
import { ConsentAgendaEntry } from '@/v2/components/consentAgendaTable/MyMeetingsTable';
import { ColumnDef } from '@tanstack/react-table';
import UserBundleContext from '@/context/UserBundleContext';
import { DataTableColumnHeader } from '@/ui/ui/datatable/column-header';
import axios from 'axios';
import { Button, ButtonWithConfirm } from '@/ui/ui/button';

interface EditCommitteesDialogArchivedAgendasProps {

}

export const EditCommitteesDialogArchivedAgendas: React.FC<EditCommitteesDialogArchivedAgendasProps> = () => {
    const [query] = useBrowserQuery<CommitteesQuery>();

    const userBundle = useContext(UserBundleContext);

    const { data: consentAgendasData, isLoading: isLoadingArchivedAgendas, mutate: refetchActiveAgendas } =
    useSWR<{ consentAgendas: CONSENT_AGENDA[] }>(`/api/committee/${query.editingCommitteeId}/consent-agenda/archived`);

    const dataTableRef = useRef<DataTableHandle<ConsentAgendaEntry>>(null);

    const columns: ColumnDef<ConsentAgendaEntry>[] = [
        {
            id: 'date',
            accessorKey: 'date',
            enableSorting: true,
            cell: ({ cell }) => {
                return <p className='text-secondary'>{cell.getValue<Date>()?.toDateString() ?? '-'}</p>;
            },
            header: ({ column }) => {
                return (
                    <DataTableColumnHeader column={column} title='Date' />
                );
            },
            filterFn: (row, id, value) => {
                return value.includes(row.getValue(id));
            },
            meta: {
                label: 'Date',
                exportValue: (cell) => `<a href='/docmgr2/consent_agenda_viewing.pl?organizationid=${userBundle.organization?.ORGANIZATIONID}&consent_agenda_id=${cell.row.original.id}'>${cell.getValue<Date>()?.toDateString()}</a>`
            }
        },
        {
            id: 'year',
            accessorKey: 'year',
            enableSorting: true,
            
            cell: ({ cell }) => {
                return <p className='text-secondary'>{cell.getValue<string>() ?? '-'}</p>;
            },
            header: ({ column }) => {
                return (
                    <DataTableColumnHeader column={column} title='Year' />
                );
            },
            filterFn: (row, id, value) => {
                return value.includes(row.getValue(id));
            },
            meta: {
                label: 'Year',
            }
        },
        {
            id: 'toolbar',
            // accessorKey: '',
            enableSorting: false,
            cell: ({ cell }) => {
                return (
                    <div className='flex flex-row space-x-2'>
                        <Button
                            variant={'outline'}
                            className='h-8 p-2'
                            size={'sm'}
                            onClick={
                                () => {
                                    window.open(
                                        `/docmgr2/consent_agenda_viewing.pl?organizationid=${userBundle.organization?.ORGANIZATIONID}&consent_agenda_id=${cell.row.original.id}`,
                                        '_blank'
                                    );
                                }
                            }
                        >
                            View Agenda
                        </Button>
                        {
                            cell.row.original.minutesDocumentId &&
                            <Button
                                variant={'outline'}
                                className='h-8 p-2'
                                size={'sm'}
                                onClick={
                                    () => {
                                        window.open(
                                            `/cgi/doc-gw.pl?full=1&wnd=1&ref=${userBundle.organization?.PREFIX}:${cell.row.original.minutesDocumentId}`,
                                            '_blank'
                                        );
                                    }
                                }
                            >
                                View Minutes
                            </Button>
                        }
                        <ButtonWithConfirm
                            key={`unarchive-${ cell.row.original.id }`}
                            className='h-8 p-2'
                            variant={'destructive'}
                            size={'sm'}
                            confirm={{

                                title: 'Unarchive Consent Agenda',
                                body: 'Are you sure you want to unarchive this consent agenda?',
                                onConfirm: async () => {
                                    await axios.post(`/api/committee/${query.editingCommitteeId}/consent-agenda/${cell.row.original.id}/unarchive`);
                                    refetchActiveAgendas();
                                }
                            }}
                        >
                            Unarchive
                        </ButtonWithConfirm>
                    </div>
                );
            },
            header: ({ column }) => {
                return (
                    <DataTableColumnHeader column={column} title='More Info' />
                );
            },
            meta: {
                label: 'Unarchive',
                skipExport: true
            }
        },
    ];

    const getData = (): ConsentAgendaEntry[] => {
        return consentAgendasData ? consentAgendasData.
            consentAgendas.map(consentAgenda => {
                const entry: ConsentAgendaEntry = {
                    id: consentAgenda.CONSENT_AGENDA_ID,
                    committeeName: consentAgenda.COMMITTEE?.NAME ?? '-',
                    committeeId: consentAgenda.COMMITTEE_ID,
                    date: new Date(consentAgenda.MEETING_START_DATE),
                    year: createSortableDate(new Date(consentAgenda.MEETING_START_DATE)).split('-')[0],
                    minutesDocumentId: consentAgenda.MINUTES_DOCID ?? undefined,
                    status: consentAgenda.STATUS,
                    committee: consentAgenda.COMMITTEE,
                };

                return entry;
            }) : [];
    };

    return (
        <div
            className='pt-4'
        >
            <DataTable
                columns={columns}
                toolbar={ArchivedAgendasTableToolbar}
                ref={dataTableRef}
                data={getData()}
                loading={isLoadingArchivedAgendas}
                exportAsFileName='archived_agendas'
                renderExpandedRowForTableExport={async () => []}
                pagination={PaginationToolsForTable}
                initialColumnVisibility={{
                    'year': false
                }}
            />
        </div>
    );
};