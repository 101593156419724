import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import CommitteeEditor from './components/CommitteeEditor/CommitteeEditor';
import CommitteesPage from './components/CommitteesPage/CommitteesPage';
import setWindowTitle from '../../utils/setWindowTitle';
import { setTitleInAdmin } from '../../utils/setTitleInAdmin';

const Committees: React.FC = () => {
    useEffect(() => {
        setWindowTitle('Committees');
        setTitleInAdmin('Manage Committees');
    }, []);

    return <>
        <Switch>
            <Route
                exact
                path='/committees/committee'
                component={ CommitteeEditor }
            />
            <Route
                exact
                path='/committees'
                component={ CommitteesPage }
            />
        </Switch>
    </>;

};

export default Committees;
