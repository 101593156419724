import React, { useEffect, useState } from 'react';
import Subheader from '../_shared/Subheader/Subheader';
import SubheaderItem from '../_shared/Subheader/SubheaderItem';
import { TrainingWebinars } from './TrainingWebinars';
import { LoadingIndicator } from '../_shared/LoadingIndicator';
import { LoadingStatuses } from '../../utils/LoadingStatuses';
import axios from 'axios';
import { DOCUMENTREVISION } from '../../entities/org/DOCUMENTREVISION';
import { TrainingVideos } from './TrainingVideos';
import setWindowTitle from '../../utils/setWindowTitle';

export function Training() {
    const Tabs: {[key: string]: string} = {
        Webinars : 'Webinars',
        //Videos   : 'Videos',
    };

    const [ loadingStatus, setLoadingStatus ] = useState(LoadingStatuses.LOADING);
    const [ selectedTab, setSelectedTab ] = useState<string>(Tabs.Webinars);
    const [ trainingWebinarDocs, setTrainingWebinarDocs ] = useState<DOCUMENTREVISION[]>([]);

    useEffect(() => {
        setWindowTitle('Training Catalog');
        loadTrainingCatalog().then();
    }, []);

    async function loadTrainingCatalog() {
        setLoadingStatus(LoadingStatuses.LOADING);

        const res = await axios.get('/api/homepage/training');
        setTrainingWebinarDocs(res.data.trainingWebinarDocs);

        setLoadingStatus(LoadingStatuses.READY);
    }

    if (loadingStatus !== LoadingStatuses.READY) {
        return <LoadingIndicator />;
    }

    return (
        <div>
            <Subheader
                value={selectedTab}
                onChange={value => setSelectedTab(value)}
            >
                {Object.keys(Tabs).map(tab => <SubheaderItem key={Tabs[tab]} label={Tabs[tab]} value={Tabs[tab]} />)}
            </Subheader>

            {selectedTab === Tabs.Webinars &&
                <TrainingWebinars trainingWebinarDocs={trainingWebinarDocs} />
            }

            {selectedTab === Tabs.Videos &&
                <TrainingVideos trainingWebinarDocs={trainingWebinarDocs} />
            }

        </div>
    );
}
