import React from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import { buildHelpDocURL } from '../../../_shared/utils/docManagerFunctions';

const useStyles = makeStyles({
    tempHelpIcon: {
        color: 'black',
        opacity: .8,
    }
});

type TemporaryHelpIconProps = {
    helpTopicID: number
}

export function TemporaryHelpIcon(props: TemporaryHelpIconProps) {
    const classes = useStyles();

    const helpURL = buildHelpDocURL(props.helpTopicID);

    return (
        <a
            href={helpURL}
            target={'_blank'}
        >
            <IconButton
                className={classes.tempHelpIcon}
            >
                <HelpOutline />
            </IconButton>
        </a>
    );
}
