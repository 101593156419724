import React from 'react';

interface PendingReleaseProps {
    size: number
}

export const PendingReleaseDocumentIcon: React.FC<PendingReleaseProps> = ({ size }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="16" height="16" rx="4" fill="#F9A6F0" />
            <path d="M5.68334 8.38462C5.38739 8.38462 5.20807 8.05785 5.36702 7.8082L7.73725 4.08573C7.93873 3.76931 8.42857 3.91202 8.42857 4.28714V7.24039C8.42857 7.44749 8.59646 7.61538 8.80357 7.61538H10.3167C10.6126 7.61538 10.7919 7.94215 10.633 8.1918L8.26275 11.9143C8.06127 12.2307 7.57143 12.088 7.57143 11.7129V8.75962C7.57143 8.55251 7.40354 8.38462 7.19643 8.38462H5.68334Z" fill="hsl(var(--white))" />
        </svg>
    );
};