import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, IconButton, makeStyles, TableCell, TableRow } from '@material-ui/core';
import { HighlightOff } from '@material-ui/icons';
import DataTable from '../_shared/DataTable/DataTable';
import { MUIDataTableColumn, MUIDataTableProps } from 'mui-datatables';
import { DOCUMENTREVISION } from '../../entities/org/DOCUMENTREVISION';
import { LoadingIndicator } from '../_shared/LoadingIndicator';
import { LoadingStatuses } from '../../utils/LoadingStatuses';
import { TrainingVideosByDocID } from '../../_shared/constants/TrainingVideosByDocID';

const useStyles = makeStyles({
    videoSetDiv: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
    },
    videoThumbnail: {
        margin: '.5rem',
        cursor: 'pointer',
    },
    trainingDialogPaper: {
        maxWidth: '100%',
    },
    titleSpan: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#22313f',
        color: 'white'
    },
    videoContentArea: {
        padding: '1rem',
        boxSizing: 'border-box'
    }
});

type TrainingWebinarProps = {
    trainingWebinarDocs: DOCUMENTREVISION[]
}

export function TrainingVideos(props: TrainingWebinarProps) {
    const classes = useStyles();

    const [ loadingStatus, setLoadingStatus ] = useState(LoadingStatuses.LOADING);
    const [ selectedVideoID, setSelectedVideoID ] = useState<number>();
    const [ tableData, setTableData ] = useState<MUIDataTableProps['data']>([]);

    useEffect(() => {
        setLoadingStatus(LoadingStatuses.LOADING);

        const newTableData = ((props.trainingWebinarDocs || []) as DOCUMENTREVISION[]).map(docrev => {
            return {
                DocID: docrev.DOCID,
                Title: (docrev.TITLE || '').replace(/(.*)- /g, ''),
            };
        });

        setTableData(newTableData);

        setLoadingStatus(LoadingStatuses.READY);
    }, [props.trainingWebinarDocs]);



    function handleDialogOpen(videoID: number) {
        setSelectedVideoID(videoID);
    }

    function handleDialogClose() {
        setSelectedVideoID(undefined);
    }

    if (loadingStatus !== LoadingStatuses.READY) {
        return <LoadingIndicator />;
    }

    return (
        <div>
            <DataTable
                muiDataTableProps={{
                    title: 'Training Videos',
                    columns: tableData[0] && Object.keys(tableData[0]).map(key => {
                        const returnObj: MUIDataTableColumn = {
                            name: key
                        };

                        if (key === 'DocID') {
                            returnObj.options = {
                                display: false
                            };
                        }

                        return returnObj;
                    }),
                    data: tableData,
                    options: {
                        expandableRows: true,
                        renderExpandableRow: (rowData: string[]) => {
                            const docID = rowData[0];
                            const videoIDs = TrainingVideosByDocID[docID];

                            return (
                                <TableRow>
                                    <TableCell colSpan={2}>
                                        <div className={classes.videoSetDiv}>
                                            {videoIDs.map(videoID => {
                                                return (
                                                    <img
                                                        key={videoID}
                                                        alt={'Training Video Thumbnail'}
                                                        width={200}
                                                        height={120}
                                                        className={classes.videoThumbnail}
                                                        src={`/images/thumbnails/${videoID}.png`}
                                                        onClick={() => handleDialogOpen(videoID)}
                                                    />
                                                );
                                            })}

                                            {!videoIDs.length && 'No videos found for this category'}
                                        </div>
                                    </TableCell>
                                </TableRow>
                            );
                        }
                    }
                }}
            />

            <Dialog
                open={!!selectedVideoID}
                onClose={() => handleDialogClose()}
                classes={{
                    paper: classes.trainingDialogPaper
                }}
            >
                <span className={classes.titleSpan}>
                    <DialogTitle>Training Video</DialogTitle>
                    <IconButton style={{ color: 'white' }} onClick={() => handleDialogClose()}>
                        <HighlightOff fontSize={'large'} />
                    </IconButton>
                </span>

                <div className={classes.videoContentArea}>
                    {selectedVideoID &&
                        <video
                            width={'800'}
                            height={'450'}
                            controls
                        >
                            <source
                                src={`/api/homepage/videos/${selectedVideoID}`}
                                type={'video/mp4'}
                            />
                            Your browser does not support video.
                        </video>
                    }
                </div>
            </Dialog>

        </div>
    );
}
