import React from 'react';

interface DraftProps {
    size: number
}

export const DraftDocumentIcon: React.FC<DraftProps> = ({ size }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="16" height="16" rx="4" fill="#81BD93" />
            <path d="M5 4.75C5 4.33579 5.33579 4 5.75 4H9L11 6V11.25C11 11.6642 10.6642 12 10.25 12H5.75C5.33579 12 5 11.6642 5 11.25V4.75Z" fill="hsl(var(--white))" />
        </svg>

    );
};