import { ORGANIZATION } from '../../entities/master/ORGANIZATION';
import { UserBundleType } from '../../context/UserBundleContext';
import { rightsCodes, PERSON, RightsCodeKey } from '../../entities/master/PERSON';

/**
 * This function assumes the context object of UserBundleType already produced the RIGHTSINENGLISH
 * property, for the user, and all the user's PERSON_ORGANIZATION entries.
 * 
 * It doesn't do any database calls because the context object should already have all this.
 */

export default function hasRights(
    context: UserBundleType,
    rights: RightsCodeKey | RightsCodeKey[],
    excludeImpersonationRights: boolean = false,
)
    : boolean
{
    if (typeof rights === 'string') rights = [rights];

    return (rights as RightsCodeKey[]).some(right => hasRight(context, right, excludeImpersonationRights));
}

function checkIfAgentHasRight(agent: PERSON, organization: ORGANIZATION, right: RightsCodeKey) {
    // If the agent is signed in their home org, we check the rights the agent has in their home org
    if (agent.ORGANIZATIONID === organization.ORGANIZATIONID) {
        if (agent.RIGHTSINENGLISH?.[right]) {
            return true;
        }
    }

    // If agent is signed in a different org, we check the rights the agent has in that organization
    else if (agent.ORGANIZATIONID !== organization.ORGANIZATIONID) {
        const agentOrg = agent.PERSON_ORGANIZATIONS?.find(personOrg => {
            return personOrg.ORGANIZATIONID === organization?.ORGANIZATIONID;
        });
        if (agentOrg?.RIGHTSINENGLISH?.[right]) {
            return true;
        }
    }

    return false;
}

/**
 * Checks to see if a user has a specific right
 * 
 * @param context The user session object
 * @param right The right to check for
 * @param excludeImpersonationRights An optional flag to ignore the impersonator's rights when partially impersonating a user
 * @returns true if the right is found, false otherwise
 */
export function hasRight(
    context: UserBundleType,
    right: keyof typeof rightsCodes,
    excludeImpersonationRights: boolean = false,
) {
    const { user, impersonator, organization } = context;

    if (!user || !organization) return false;

    if (impersonator === null || !!context.useImpersonatedRights) {
        // we sign in purely as ourselves, or "Show only the impersonated user's experience" is checked
        return checkIfAgentHasRight(user, organization, right);
    } else {
        // "Show only the impersonated user's experience" is NOT checked (partial impersonation)

        if (excludeImpersonationRights) {
            return checkIfAgentHasRight(user, organization, right);
        }

        return checkIfAgentHasRight(impersonator, organization, right) || checkIfAgentHasRight(user, organization, right);
    }
}
