import React from 'react';
import { makeStyles } from '@material-ui/core';
import Tab, { TabProps } from '@material-ui/core/Tab';
import LucidocColors from '../../../constants/LucidocColors';

const useStyles = makeStyles({
    root: {
        fontSize: '.8rem',
        padding: '.8rem',
        '&:hover': {
            color: LucidocColors.purple,
        },
        minWidth: (props: any) => props.buttonWidth,
        '& span': {
            width: (props: any) => props.buttonWidth
        },
    },
    selected: {
        color: LucidocColors.purple,
    }
});

interface SubheaderItemProps extends TabProps {
    buttonWidth?: string
}

export default function SubheaderItem(props: SubheaderItemProps) {
    const classes = useStyles(props);

    return (
        <Tab
            {...props}
            classes={classes}
        />
    );
}
