import {
    Column,
    Entity,
    JoinColumn,
    ManyToOne,
    PrimaryColumn,
} from 'typeorm';
import { DOCUMENT,  } from './DOCUMENT';
import { USERGROUP,  } from './USERGROUP';
import { PERSON,  } from '../master/PERSON';
import { stringToBooleanTransformer } from '../_helperFunctions/transformers';
import { INFORMATION_HIERARCHY,  } from './INFORMATION_HIERARCHY';
import { PUBLISHER,  } from './PUBLISHER';
import { SchemaEntityManager } from '../SchemaEntityManager';

function createACLEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('ACL', { schema: schema })
    class ACL {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'ACLID',
        })
        ACLID!: number;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'USERID',
        })
        USERID!: number | null;

        @ManyToOne(
            () => manager.getPersonEntity(schema),
             (person) => person.ACL
        )
        @JoinColumn({
            name: 'USERID',
        })
        OWNERORWHATEVER?: PERSON | null;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'USERGROUPID',
        })
        USERGROUPID!: number | null;

        @ManyToOne(
            () => manager.getUserGroupEntity(schema),
            (usergroup) => usergroup.ACL
        )
        @JoinColumn({
            name: 'USERGROUPID',
        })
        USERGROUP!: USERGROUP | null;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'DOCID',
        })
        DOCID!: number | null;

        @ManyToOne(
            () => manager.getDocumentEntity(schema),
            (document) => document.ACL
        )
        @JoinColumn({
            name: 'DOCID',
        })
        DOCUMENT!: DOCUMENT | null;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'INFORMATION_HIERARCHY_ID',
        })
        INFORMATION_HIERARCHY_ID!: number | null;

        @ManyToOne(
            () => manager.getInformationHierarchyEntity(schema),
            (informationHierarchy) => informationHierarchy.ACL
        )
        @JoinColumn({
            name: 'INFORMATION_HIERARCHY_ID',
        })
        INFORMATION_HIERARCHY!: INFORMATION_HIERARCHY | null;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'INHERIT_IH_ID'
        })
        INHERIT_IH_ID!: number | null;

        @ManyToOne(
            () => manager.getInformationHierarchyEntity(schema),
            (informationHierarchy) => informationHierarchy.ACL
        )
        @JoinColumn({
            name: 'INHERIT_IH_ID',
        })
        INHERIT_IH!: INFORMATION_HIERARCHY | null;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'PUBLISHER_ID'
        })
        PUBLISHER_ID!: number | null;

        @ManyToOne(
            () => manager.getPublisherEntity(schema),
            PUBLISHER => PUBLISHER.ACL
        )
        @JoinColumn({
            name: 'PUBLISHER_ID'
        })
        PUBLISHER!: PUBLISHER | null;


        @Column('char', {
            nullable: true,
            name: 'CONTROL_OWNER',
            transformer: stringToBooleanTransformer,
        })
        CONTROL_OWNER!: boolean;

        @Column('char', {
            nullable: true,
            name: 'ASSIGN_AUTHOR',
            transformer: stringToBooleanTransformer
        })
        ASSIGN_AUTHOR!: boolean;

        @Column('char', {
            nullable: true,
            name: 'WRITE',
            transformer: stringToBooleanTransformer
        })
        WRITE!: boolean;

        @Column('char', {
            nullable: true,
            name: 'VISIBLE',
            transformer: stringToBooleanTransformer
        })
        VISIBLE!: boolean;

        @Column('char', {
            nullable: true,
            name: 'REVIEW',
            transformer: stringToBooleanTransformer
        })
        REVIEW!: boolean;

        @Column('char', {
            nullable: true,
            name: 'REVISE',
            transformer: stringToBooleanTransformer
        })
        REVISE!: boolean;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'LOCATIONID'
        })
        LOCATIONID!: number | null;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'REPORTID'
        })
        REPORTID!: number | null;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'ORGCHARTID'
        })
        ORGCHARTID!: number | null;

        @Column('char', {
            nullable: true,
            name: 'VISIBLE_LIM',
            transformer: stringToBooleanTransformer
        })
        VISIBLE_LIM!: boolean;
    
        @Column('char', {
            nullable: true,
            name: 'WRITE_LIM',
            transformer: stringToBooleanTransformer
        })
        WRITE_LIM!: boolean;
    }

    return ACL;
}

export { createACLEntity };
export type ACL = InstanceType<ReturnType<typeof createACLEntity>>;
