import React from 'react';

interface OfficialProps {
    size: number
}

export const OfficialDocumentIcon: React.FC<OfficialProps> = ({ size }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="16" height="16" rx="4" fill="#A291CE" />
            <path d="M4 5.82353C4 5.62892 4.12072 5.45473 4.30293 5.3864L7.64888 4.13167C7.87526 4.04678 8.12474 4.04678 8.35112 4.13167L11.6971 5.3864C11.8793 5.45473 12 5.62892 12 5.82353V6.728C12 9.29764 10.406 11.5977 8 12.5C5.59397 11.5977 4 9.29764 4 6.728V5.82353Z" fill="hsl(var(--white))" />
        </svg>
    );
};