import * as React from 'react';
import * as NavigationMenuPrimitive from '@radix-ui/react-navigation-menu';
import { VariantProps, cva } from 'class-variance-authority';
import { ChevronDown } from 'lucide-react';

import { cn } from '@/lib/utils';
import { HybridLink } from './hybridlink';

export interface NavigationMenuProps extends
    React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Root> {
    alignment: 'alignLeft' | 'alignRight';
}

const NavigationMenu = React.forwardRef<
    React.ElementRef<typeof NavigationMenuPrimitive.Root>,
    NavigationMenuProps
>(({ className, children, alignment, ...props }, ref) => (
    <NavigationMenuPrimitive.Root
        ref={ref}
        // The z index is really high because MUI has some conflicting ones.
        // Once we strip MUI out, set z to 10.
        className={cn(
            'relative z-[101] flex max-w-max flex-1 items-center justify-center',
            className
        )}
        {...props}
    >
        {children}
        <NavigationMenuViewport alignment={alignment} />
    </NavigationMenuPrimitive.Root>
));
NavigationMenu.displayName = NavigationMenuPrimitive.Root.displayName;

const NavigationMenuList = React.forwardRef<
    React.ElementRef<typeof NavigationMenuPrimitive.List>,
    React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.List>
>(({ className, ...props }, ref) => (
    <NavigationMenuPrimitive.List
        ref={ref}
        className={cn(
            'group flex flex-1 list-none items-center justify-center space-x-1',
            className
        )}
        {...props}
    />
));
NavigationMenuList.displayName = NavigationMenuPrimitive.List.displayName;

const NavigationMenuItem = NavigationMenuPrimitive.Item;

// Used to prevent people who will click on the trigger right after hovering, which will cause the menu to close immediately.
function useTriggerFix() {
    const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.maxTouchPoints > 0;

    return React.useMemo(() => {
        if (isTouchDevice) return {}; // Return an empty object for touch devices

        let disable;
        return {
            onClick: (e) => {
                disable && e.preventDefault();
            },
            onPointerEnter: () => {
                clearTimeout(disable);
                disable = setTimeout(() => {
                    disable = false;
                }, 1000);
            },
        };
    }, []);
}



const navigationMenuTriggerStyle = cva(
    'group text-primary inline-flex h-10 w-max items-center justify-center rounded-md bg-background px-2 py-2 text-sm font-medium transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground focus:outline-none disabled:pointer-events-none disabled:opacity-50 data-[active]:bg-accent/50 data-[state=open]:bg-accent/50'
);

export interface NavigationMenuTriggerProps extends
    React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Trigger> {
    hideChevron?: boolean;
}

const NavigationMenuTrigger = React.forwardRef<
    React.ElementRef<typeof NavigationMenuPrimitive.Trigger>,
    NavigationMenuTriggerProps
>(({ className, children, hideChevron = false, ...props }, ref) => {
    const fix = useTriggerFix();
    return <NavigationMenuPrimitive.Trigger
        {...fix}
        ref={ref}
        className={cn(navigationMenuTriggerStyle(), 'group', className)}
        {...props}
    >
        {children}{' '}
        <ChevronDown

            className={cn('relative top-[1px] ml-1 h-3 w-3 transition duration-200 group-data-[state=open]:rotate-180', hideChevron && 'hidden')}
            aria-hidden="true"
        />
    </NavigationMenuPrimitive.Trigger>;
});
NavigationMenuTrigger.displayName = NavigationMenuPrimitive.Trigger.displayName;

const NavigationMenuContent = React.forwardRef<
    React.ElementRef<typeof NavigationMenuPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Content>
>(({ className, ...props }, ref) => (
    <NavigationMenuPrimitive.Content
        ref={ref}
        className={cn(
            'left-0 top-0 w-full data-[motion^=from-]:animate-in data-[motion^=to-]:animate-out data-[motion^=from-]:fade-in data-[motion^=to-]:fade-out data-[motion=from-end]:slide-in-from-right-52 data-[motion=from-start]:slide-in-from-left-52 data-[motion=to-end]:slide-out-to-right-52 data-[motion=to-start]:slide-out-to-left-52 md:absolute md:w-auto bg-background',
            className
        )}
        {...props}
    />
));
NavigationMenuContent.displayName = NavigationMenuPrimitive.Content.displayName;



const NavigationMenuLink = React.forwardRef<
    React.ElementRef<typeof NavigationMenuPrimitive.Link>,
    React.ComponentPropsWithoutRef<typeof HybridLink>
>((props) => (
    <HybridLink
        // ref={ref}
        {...props}
        href={props.href}
        className={cn('text-primary', props.className)}
    >
        {props.children}
    </HybridLink>
));



const navigationMenuViewportVariants = cva(
    'absolute top-full flex justify-center',
    {
        variants: {
            alignment: {
                alignLeft: 'left-0',
                alignRight: 'right-0',
            },
        },
        defaultVariants: {
            alignment: 'alignLeft',
        }
    },
);

export interface NavigationMenuViewportProps
    extends React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Viewport>,
    VariantProps<typeof navigationMenuViewportVariants> {

}

const NavigationMenuViewport = React.forwardRef<
    React.ElementRef<typeof NavigationMenuPrimitive.Viewport>,
    NavigationMenuViewportProps
>(({ className, alignment, ...props }, ref) => (
    <div className={cn(navigationMenuViewportVariants({ alignment }))}>
        <NavigationMenuPrimitive.Viewport
            className={cn(
                'origin-top-center relative mt-1.5 h-[var(--radix-navigation-menu-viewport-height)] w-full overflow-hidden rounded-md border bg-popover text-popover-foreground shadow-lg data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:zoom-out-[.98] data-[state=open]:zoom-in-[.98] md:w-[var(--radix-navigation-menu-viewport-width)]',
                className
            )}
            ref={ref}
            {...props}
        />
    </div>
));
NavigationMenuViewport.displayName =
    NavigationMenuPrimitive.Viewport.displayName;

const NavigationMenuIndicator = React.forwardRef<
    React.ElementRef<typeof NavigationMenuPrimitive.Indicator>,
    React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Indicator>
>(({ className, ...props }, ref) => (
    <NavigationMenuPrimitive.Indicator
        ref={ref}
        className={cn(
            'top-full z-[1] flex h-1.5 items-end justify-center overflow-hidden data-[state=visible]:animate-in data-[state=hidden]:animate-out data-[state=hidden]:fade-out data-[state=visible]:fade-in',
            className
        )}
        {...props}
    >
        <div className="relative top-[60%] h-2 w-2 rotate-45 rounded-tl-sm bg-border shadow-md" />
    </NavigationMenuPrimitive.Indicator>
));
NavigationMenuIndicator.displayName =
    NavigationMenuPrimitive.Indicator.displayName;

export {
    navigationMenuTriggerStyle,
    NavigationMenu,
    NavigationMenuList,
    NavigationMenuItem,
    NavigationMenuContent,
    NavigationMenuTrigger,
    NavigationMenuLink,
    NavigationMenuIndicator,
    NavigationMenuViewport,
};
