import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, IconButton, Tooltip } from '@material-ui/core';
import { CheckCircleOutline, Help, HighlightOff } from '@material-ui/icons';
import { buildHelpDocURL } from '../../../_shared/utils/docManagerFunctions';
import LucidocColors from '../../../constants/LucidocColors';

export const saveBarHeight = '50px';

const useStyles = makeStyles({
    footer: {
        position: (props?: SaveBarStyles) => props?.footerPosition || 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        minHeight: saveBarHeight,
        boxSizing: 'border-box',
        display: 'flex',
        backgroundColor: '#ddd',
        backgroundImage: 'linear-gradient(#ddd, #aaa)',
        justifyContent: 'space-between',
        alignItems: 'center',
        zIndex: 1000
    },
    // this solves a problem with the footer div blocking out content behind it,
    // in a way that bottom padding or margin did not:
    spacerDiv: {
        height: saveBarHeight
    },
    actionButton: {
        margin: '.25rem 1rem .25rem 0',
    },
    helpButton: {
        color: LucidocColors.purple,
    }
});

type SaveBarStyles = {
    footerPosition?: React.CSSProperties['position'];
}

interface SaveBarProps {
    onSave?: () => void
    isSaveDisabled?: boolean
    whyIsSaveDisabled?: string
    // saveButtonText?: string
    saveIcon?: React.ReactElement

    onCancel?: () => void
    isCancelDisabled?: boolean
    whyIsCancelDisabled?: string
    // cancelButtonText?: string
    cancelIcon?: React.ReactElement

    onClose?: () => void
    isCloseDisabled?: boolean
    whyIsCloseDisabled?: string
    // closeButtonText?: string
    closeIcon?: React.ReactElement

    children?: React.ReactElement | React.ReactElement[]

    helpTopicID?: number
    styles?: SaveBarStyles
}

export default function SaveBar(props: SaveBarProps) {
    const classes = useStyles(props.styles);

    return (
        <>
            <div className={classes.footer}>
                {props.helpTopicID
                    ? <a
                        href={buildHelpDocURL(props.helpTopicID)}
                        target={'_blank'}
                        rel={'noopener noreferrer'}
                    >
                        <IconButton className={classes.helpButton}>
                            <Help />
                        </IconButton>
                    </a>
                    : <span />
                }

                <span>
                    {props.onSave &&
                        <Tooltip title={props.whyIsSaveDisabled || ''} arrow>
                            <span> {/* means tooltip will render even if button is disabled */}
                                <Button
                                    className={classes.actionButton}
                                    variant={'contained'}
                                    startIcon={props.saveIcon || <CheckCircleOutline />}
                                    disabled={props.isSaveDisabled}
                                    onClick={() => props.onSave && props.onSave()}
                                >
                                    {/* {props.saveButtonText || 'Save'} */}
                                    Save
                                </Button>
                            </span>
                        </Tooltip>
                    }

                    {props.onCancel &&
                        <Tooltip title={props.whyIsCancelDisabled || ''} arrow>
                            <span> {/* means tooltip will render even if button is disabled */}
                                <Button
                                    className={classes.actionButton}
                                    variant={'contained'}
                                    startIcon={props.cancelIcon || <HighlightOff />}
                                    disabled={props.isCancelDisabled}
                                    onClick={() => props.onCancel && props.onCancel()}
                                >
                                    {/* {props.cancelButtonText || 'Undo'} */}
                                    Undo
                                </Button>
                            </span>
                        </Tooltip>
                    }

                    {props.onClose &&
                        <Tooltip title={props.whyIsCloseDisabled || ''} arrow>
                            <span> {/* means tooltip will render even if button is disabled */}
                                <Button
                                    className={classes.actionButton}
                                    variant={'contained'}
                                    startIcon={props.closeIcon || <HighlightOff />}
                                    disabled={props.isCloseDisabled}
                                    onClick={() => props.onClose && props.onClose()}
                                >
                                    {/* {props.closeButtonText || 'Close'} */}
                                    Close
                                </Button>
                            </span>
                        </Tooltip>
                    }
                </span>

                {/* Just for alignItems: 'space-between' to balance out: */}
                {!props.children && <span />}

                {props.children}
            </div>
            <div className={classes.spacerDiv}> </div>
        </>
    );
}
