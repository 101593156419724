import React, { useContext } from 'react';
import { DatatableColumnFilter } from '@/ui/ui/datatable/column-filter';
import { DataTableToolbarProps } from '@/ui/ui/datatable/data-table';
import { DataTableFacetedFilter } from '@/ui/ui/datatable/datatable-faceted-filter';
import { DataTableViewOptions } from '@/ui/ui/datatable/view-options';
import { UserShadowIcon } from '@/ui/icons/shadow/UserShadow';
import { CommitteeEntry } from './CommitteeTable';
import { DepartmentShadowIcon } from '@/ui/icons/shadow/DepartmentShadow';
import { UnknownStatusShadowIcon } from '@/ui/icons/shadow/UnknownStatusShadow';
import { Button } from '@/ui/ui/button';
import { useBrowserQuery } from '@/hooks/useBrowserQuery';
import { CommitteesQuery } from '@/v2/pages/committees/Committees';
import UserBundleContext from '@/context/UserBundleContext';
import { hasRight } from '@/_shared/utils/hasRights';
import { RightsCodeKey } from '@/entities/master/PERSON';

const CommitteeTableToolbar: React.FC<DataTableToolbarProps<CommitteeEntry>> = ({ table, datatableRef }) => {

    const [query, setQuery] = useBrowserQuery<CommitteesQuery>();

    const userBundle = useContext(UserBundleContext);

    const hasDocumentAdminRights = hasRight(userBundle, RightsCodeKey.DocumentAdministrator);

    return (
        <div className="flex items-center justify-between my-4 ">
            <div className="flex flex-1 items-center space-y-2 sm:space-x-2 sm:space-y-0 flex-wrap">
                <DatatableColumnFilter
                    column={table.getColumn('name')}
                    placeholder="Filter committee..."
                />
                {
                    table.getColumn('chairPerson') && (
                        <DataTableFacetedFilter
                            column={table.getColumn('chairPerson')}
                            title="Chairperson"
                            icon={<UserShadowIcon size={16} />}
                        /> 
                    )
                }
                {
                    table.getColumn('secretary') && (
                        <DataTableFacetedFilter
                            column={table.getColumn('secretary')}
                            title="Secretary"
                            icon={<UserShadowIcon size={16} />}
                        /> 
                    )
                }
                {
                    table.getColumn('status') && (
                        <DataTableFacetedFilter
                            column={table.getColumn('status')}
                            title="Status"
                            icon={<UnknownStatusShadowIcon size={16} />}
                        /> 
                    )
                }
                {
                    table.getColumn('minutesDepartment') && (
                        <DataTableFacetedFilter
                            column={table.getColumn('minutesDepartment')}
                            title="Minutes Department"
                            icon={<DepartmentShadowIcon size={16} />}
                        /> 
                    )
                }
                {
                    hasDocumentAdminRights &&
                        <Button
                            size={'sm'}
                            onClick={() => setQuery({ ...query, createCommitteeDialogOpen: true })}
                            className='h-8 p-2'
                        >
                            Create
                        </Button>    
                }
            </div>
            <DataTableViewOptions
            
                datatableRef={datatableRef}
                table={table}
            />
        </div>
    );
};

export default CommitteeTableToolbar;
