import { Tabs, TabsList, TabsTrigger } from '@/ui/ui/tabs';
import { TabsContent } from '@radix-ui/react-tabs';
import React, { useState, useContext, useEffect } from 'react';
import { Overview } from './Overview';
import { Departments } from './Departments';
import { QueryParams, useBrowserQuery } from '@/hooks/useBrowserQuery';
import { Manuals } from './Manuals';
import { formatTitle, useDocumentTitle } from '@/hooks/useDocumentTitle';
import UserBundleContext from '@/context/UserBundleContext';
import hasRights from '@/_shared/utils/hasRights';
import { RightsCodeKey } from '@/entities/master/PERSON';
import { helpTopicDocIDs } from '@/_shared/constants/HelpSystemIDs';
import { HelpTopicContext } from '@/context/HelpTopicContext';

interface Homepage2Props {

}

// If you're going to add one, you need to add it to the preferences system as well.
// check preferences-def.ts
export enum TabStates {
    Overview = 'overview',
    Departments = 'departments',
    Manuals = 'manuals'
}

export interface Homepage2Query extends QueryParams {
    homepageTab?: TabStates
}



export const Homepage2DialogContentStyle = 'w-full max-w-none flex flex-col items-start sm:w-[32rem] md:w-[46rem] lg:w-[64rem] h-full sm:max-h-[40rem] overflow-y-auto';

export const Homepage2: React.FC<Homepage2Props> = () => {

    const userBundle = useContext(UserBundleContext);

    const [query, setQuery] = useBrowserQuery<Homepage2Query>();

    const [tab, setTab] = useState<TabStates>(TabStates.Overview);

    const { setHelpTopicID } = useContext(HelpTopicContext);

    useEffect(() => {
        // THIS IS WHERE THE ACTUAL INITIAL TAB IS SET.
        // The useEffect will run at the end after the useState and other hooks are run.
        setTab(query.homepageTab || userBundle.preferences.homepageTab as TabStates);
        switch (tab) {
            case TabStates.Overview:
                setHelpTopicID(getDashboardTopic());
                break;
            case TabStates.Departments:
                setHelpTopicID(helpTopicDocIDs.HOME_DASHBOARD_DEPARTMENTS);
                break;
            case TabStates.Manuals:
                setHelpTopicID(helpTopicDocIDs.HOME_DASHBOARD_MANUALS);
                break;
        }
    }, [query, userBundle.preferences]);

    const handleTabChange = (value: string) => {
        // not reusing/spreading old query here on purpose, since the UI elements on that tab will no longer be in use.
        setQuery({ homepageTab: value as TabStates });
        setTab(value as TabStates);
    };


    const getDashboardTopic = () => {
        // if admin or better, show admin help
        if (hasRights(userBundle,
            [
                RightsCodeKey.UserAdministrator,
                RightsCodeKey.DocumentAdministrator,
                RightsCodeKey.SystemAdministrator,
                RightsCodeKey.OrganizationAdministrator
            ])) {
            return helpTopicDocIDs.HOME_ADMIN;
        } else if (hasRights(userBundle, [RightsCodeKey.Signer, RightsCodeKey.Author])) {
            return helpTopicDocIDs.HOME_AUTHOR;
        } else if (hasRights(userBundle, RightsCodeKey.Reader)) {
            return helpTopicDocIDs.HOME_READER;
        } else {
            return helpTopicDocIDs.HOME_ANON;
        }
    };


    const getTabName = () => {
        switch (tab) {
            case TabStates.Overview:
                return 'Overview';
            case TabStates.Departments:
                return userBundle.organization?.ORGUNITPLURAL ?? 'Departments';
            case TabStates.Manuals:
                return userBundle.organization?.MANUALNAMEPLURAL ?? 'Manuals';
            default:
                throw new Error('Tab name not defined!');
        }
    };

    useDocumentTitle(formatTitle(`${getTabName()}`));

    // calc(100vh - 16rem)

    return (
        <div
            className='px-6 py-2 w-full h-full'
        >
            {/* <p className='text-3xl text-primary font-semibold'>Dashboard</p> */}
            <Tabs defaultValue={TabStates.Overview} value={tab} onValueChange={handleTabChange} className='mt-3'>
                <TabsList defaultValue={TabStates.Overview}>
                    <TabsTrigger value={TabStates.Overview}>Overview</TabsTrigger>
                    <TabsTrigger value={TabStates.Departments}>{userBundle.organization?.ORGUNITPLURAL ?? 'Departments'}</TabsTrigger>
                    <TabsTrigger value={TabStates.Manuals}>{userBundle.organization?.MANUALNAMEPLURAL ?? 'Manuals'}</TabsTrigger>
                </TabsList>
                <TabsContent value={TabStates.Overview}>
                    <Overview />
                </TabsContent>
                <TabsContent value={TabStates.Departments}>
                    <Departments />
                </TabsContent>
                <TabsContent value={TabStates.Manuals}>
                    <Manuals />
                </TabsContent>
            </Tabs>
        </div>
    );
};