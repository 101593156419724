import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import OrgSelectMenu from '../OrgSelectMenu/OrgSelectMenu';
import axios from 'axios';
import { DOCUMENT_TYPE_PL } from '../../../entities/org/DOCUMENT_TYPE_PL';
import { DOCUMENTREVISION } from '../../../entities/org/DOCUMENTREVISION';
import { GLOBAL_IDENTIFIER } from '../../../entities/org/GLOBAL_IDENTIFIER';
import SaveBar from '../../_shared/SaveBar/SaveBar';
import UserBundleContext from '../../../context/UserBundleContext';

export function RenameMultiOrgDocuments() {
    const context = useContext(UserBundleContext);

    const [ isSaving, setIsSaving ] = useState<boolean>(false);

    // values we use to select:
    const [ publisherOrgID, setPublisherOrgID ] = useState<number | undefined>();
    const [ selectedDocID, setSelectedDocID ] = useState<string>('');
    const [ selectedRevNo, setSelectedRevno ] = useState<string>('');
    const [ docrevs, setDocrevs ] = useState<DOCUMENTREVISION[]>([]);
    const [ doctypes, setDoctypes ] = useState<DOCUMENT_TYPE_PL[]>([]);
    const [ globalIdentifiers, setGlobalIdentifiers ] = useState<GLOBAL_IDENTIFIER[]>([]);

    // values we will update:
    const [ selectedDocRevTitle, setSelectedDocRevTitle ] = useState<string>('');
    const [ selectedDocTypeID, setSelectedDocTypeID ] = useState<string>('');
    const [ selectedGlobalIdentifierID, setSelectedGlobalIdentifierID ] = useState<string>('');

    useEffect(() => {
        // clear everything
        setSelectedDocID('');
        setSelectedRevno('');
        setDocrevs([]);
        setDoctypes([]);
        setGlobalIdentifiers([]);
        setSelectedDocRevTitle('');
        setSelectedDocTypeID('');
        setSelectedGlobalIdentifierID('');

        // query org-specific data
        getDocTypesAndGlobalIdentifiers().then();
    }, [publisherOrgID]);

    useEffect(() => {
        setSelectedRevno('');
        setSelectedDocRevTitle('');
        setSelectedDocTypeID('');
        setSelectedGlobalIdentifierID('');
        setDocrevs([]);
    }, [selectedDocID]);

    useEffect(() => {
        const targetDocrev = docrevs.find(docrev => docrev.REVNO === selectedRevNo);

        if (targetDocrev) {
            setSelectedDocRevTitle(targetDocrev.TITLE || '');
            setSelectedDocTypeID((targetDocrev.DOCTYPE_ID || '').toString());
            setSelectedGlobalIdentifierID(targetDocrev.GLOBAL_IDENTIFIER_ID?.toString() || '');
        }
        else {
            setSelectedDocRevTitle('');
            setSelectedDocTypeID('');
            setSelectedGlobalIdentifierID('');
        }

    }, [selectedRevNo]);

    async function getDocTypesAndGlobalIdentifiers() {
        const res = await axios.get('/api/internal-support/rename-multi-org-documents/get-doctypes-and-global-identifiers', {
            params: {
                publisherOrgID
            }
        });

        setDoctypes(res.data.doctypes || []);
        setGlobalIdentifiers(res.data.globalIdentifiers || []);
    }

    async function getRevisionsFromDocID() {
        const res = await axios.get('/api/internal-support/rename-multi-org-documents/get-revisions', {
            params: {
                publisherOrgID,
                selectedDocID
            }
        });

        setDocrevs(res.data.docrevs);
    }

    async function renameMultiOrgDocuments() {
        const warningMessage = ''
            + `Change title of DocID ${selectedDocID}, Rev ${selectedRevNo.trim()} to:\n`
            + `    "${selectedDocRevTitle}"\n`
            + 'And doctype to:\n'
            + `    "${doctypes.find(doctype => doctype.DOCTYPE_ID.toString() === selectedDocTypeID)?.DESCRIPTION}"\n`
            + 'And global identifier to:\n'
            + `    "${globalIdentifiers.find(gi => gi.GLOBAL_IDENTIFIER_ID.toString() === selectedGlobalIdentifierID)?.VALUE}"`;

        if (!window.confirm(warningMessage)) {
            return;
        }

        setIsSaving(true);

        const res = await axios.post('/api/internal-support/rename-multi-org-documents/rename-multiorg-documents', {
            publisherOrgID,
            selectedDocID,
            selectedRevNo,
            selectedDocTypeID,
            selectedDocRevTitle,
            selectedGlobalIdentifierID,
        });

        if (res.data.success) {
            alert('Success');
        }
        else if (res.data.error) {
            alert(res.data.error);
        }

        setIsSaving(false);
    }

    return (
        <div>
            <p>
                <Link to={'/internal-support'}>
                    Back
                </Link>
            </p>

            <h3>Rename Multi-Org Documents</h3>

            <h4>Select DocRev:</h4>

            <div style={{ margin: '0 0 1rem 1rem' }}>
                <OrgSelectMenu
                    text={'Select Publisher Org'}
                    selectedOrgID={publisherOrgID}
                    setSelectedOrgID={orgID => setPublisherOrgID(orgID)}
                    disabled={isSaving}
                    getPublishers={true}
                />
            </div>

            <div style={{ margin: '0 0 1rem 1rem' }}>
                <label htmlFor={'doc-id-input'}>Select a DocID: </label>
                <input
                    id={'doc-id-input'}
                    type={'number'}
                    value={selectedDocID}
                    disabled={isSaving || !publisherOrgID}
                    onChange={e => setSelectedDocID(e.currentTarget.value)}
                />

                <button
                    disabled={isSaving || !publisherOrgID || !selectedDocID}
                    onClick={() => getRevisionsFromDocID()}
                >
                    Select
                </button>
            </div>

            <div style={{ margin: '0 0 1rem 1rem' }}>
                <label>Select a RevNo: </label>
                <select
                    value={selectedRevNo}
                    disabled={isSaving || !publisherOrgID || !selectedDocID || !docrevs.length}
                    onChange={e => setSelectedRevno(e.currentTarget.value)}
                >
                    <option value={''} key={-1}>Select</option>
                    {docrevs.map((docrev, idx) => {
                        return (
                            <option value={docrev.REVNO} key={idx}>{docrev.REVNO}</option>
                        );
                    })}
                </select>
            </div>

            <h4>Edit DocRev:</h4>

            <div style={{ margin: '0 0 1rem 1rem' }}>
                <label htmlFor={'docrev-title-input'}>Title: </label>
                <input
                    style={{ width: '600px' }}
                    id={'docrev-title-input'}
                    type={'text'}
                    value={selectedDocRevTitle}
                    disabled={isSaving || !publisherOrgID || !selectedDocID || !docrevs.length || !selectedRevNo || !selectedDocRevTitle}
                    onChange={e => e.currentTarget.value && setSelectedDocRevTitle(e.currentTarget.value)}
                />
            </div>

            <div style={{ margin: '0 0 1rem 1rem' }}>
                <label>Select a DocType: </label>
                <select
                    value={selectedDocTypeID}
                    onChange={e => setSelectedDocTypeID(e.currentTarget.value)}
                    disabled={isSaving || !publisherOrgID || !selectedDocID || !docrevs.length || !selectedRevNo}
                >
                    <option value={''} key={-1}>Select</option>
                    {doctypes.map((doctype, idx) => {
                        return (
                            <option value={doctype.DOCTYPE_ID} key={idx}>{doctype.DESCRIPTION}</option>
                        );
                    })}
                </select>

                <ul style={{ color: 'dimgrey', fontSize: '.9rem' }}>
                    <li>Note this will NOT update the TemplateID (which is separate),</li>
                    <li>NOR will it update the doctype in a facility WITHOUT A PERFECT TITLE MATCH FOR THAT DOCTYPE (but it will work on the ones that DO have that doctype)</li>
                </ul>
            </div>

            <div style={{ margin: '0 0 1rem 1rem' }}>
                <label>Select {context.organization?.GLOBAL_IDENTIFIER_NAME || 'Global Identifier'}: </label>
                <select
                    value={selectedGlobalIdentifierID}
                    onChange={e => setSelectedGlobalIdentifierID(e.currentTarget.value)}
                    disabled={isSaving || !publisherOrgID || !selectedDocID || !docrevs.length || !selectedRevNo}
                >
                    <option value={''} key={-1}>Select</option>
                    {globalIdentifiers.map((gi, idx) => {
                        return (
                            <option value={gi.GLOBAL_IDENTIFIER_ID} key={idx}>{gi.VALUE}</option>
                        );
                    })}
                </select>
            </div>

            <SaveBar
                onSave={() => renameMultiOrgDocuments()}
                isSaveDisabled={isSaving || !publisherOrgID || !selectedDocID || !docrevs.length || !selectedRevNo || !selectedDocRevTitle || !selectedDocTypeID}
                // saveButtonText={'Update DocRevs'}
            />
        </div>
    );
}
