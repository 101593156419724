import React from 'react';

interface DisplayProps {
    size: number
}

export const DisplayIcon: React.FC<DisplayProps> = ({ size }) => {
    return (
        <svg width={size.toString()} height={size.toString()} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" rx="4" fill="hsl(var(--primary))" />
            <path d="M4.5 7C4.5 6.17157 5.17157 5.5 6 5.5H18C18.8284 5.5 19.5 6.17157 19.5 7V14C19.5 14.8284 18.8284 15.5 18 15.5H13.5V16.5L15.5981 16.9196C15.8318 16.9664 16 17.1716 16 17.4099V18C16 18.2761 15.7761 18.5 15.5 18.5H8.5C8.22386 18.5 8 18.2761 8 18V17.4099C8 17.1716 8.16823 16.9664 8.40194 16.9196L10.5 16.5V15.5H6C5.17157 15.5 4.5 14.8284 4.5 14V7Z" fill="hsl(var(--white))" />
            <path d="M6 7.5C6 7.22386 6.22386 7 6.5 7H17.5C17.7761 7 18 7.22386 18 7.5V13.5C18 13.7761 17.7761 14 17.5 14H6.5C6.22386 14 6 13.7761 6 13.5V7.5Z" fill="hsl(var(--primary))" />
        </svg>

    );
};