import axios from 'axios';

type FeatureFlagMap = { [key: string]: string[] };

class FeatureFlagClient {
    static get baseEndpoint() {
        return '/api/internal-support/feature-flag';
    }

    /**
     * Checks to see if a user/org has access to a feature.
     * 
     * @param feature Feature to query for
     * @param id Unique ID for the affected user/org
     * @returns A promise that resolves to true if the user/org has access to the feature
     */
    static queryFeature(feature: string, id: string): Promise<boolean> {
        return axios.get('/api/feature-flag/query-feature-flag', {
            params: {
                feature,
                id,
            }
        })
        .then((res) => res.data.hasAccess);
    }

    /**
     * Fetches the latest version of the feature flag map.
     * 
     * @returns A promise that resolves to the feature flag map
     */
    static getFeatureFlagFile(): Promise<FeatureFlagMap> {
        return axios.get(FeatureFlagClient.baseEndpoint + '/grab-feature-flag')
            .then((res) => res.data.featureFlagMap);
    }

    /**
     * Resyncs the dev feature flag file with the latest production feature flag file.
     * 
     * @returns A promise that resolves to true if the operation was successful
     */
    static resyncFeatureFlagFile(): Promise<boolean> {
        return axios.get(FeatureFlagClient.baseEndpoint + '/resync-feature-flag')
            .then((res) => res.data.success);
    }

    /**
     * Enables/Disables a feature for given ids.
     * Can use this to create a new feature as well.
     * 
     * @param feature The feature to enable/disable
     * @param ids List of ids to enable/disable the feature for
     * @param enabled Indicates whether the feature should be enabled or disabled
     * @returns A promise resolving to true if the operation was successful
     */
    static setFeature(feature: string, ids: string[], enabled: boolean): Promise<boolean> {
        return axios.post(FeatureFlagClient.baseEndpoint + '/set-feature', {
            feature,
            ids,
            enabled,
        })
        .then((res) => res.data.successful);
    }

    /**
     * Removes a feature from the feature flag map.
     * 
     * @param feature The feature to remove
     * @returns A promise resolving to true if the operation was successful
     */
    static removeFeature(feature: string): Promise<boolean> {
        return axios.post(FeatureFlagClient.baseEndpoint + '/remove-feature', {
            feature,
        })
        .then((res) => res.data.successful);
    }

    /**
     * Enables a feature for *ALL* users.
     * 
     * @param feature The feature to globally enable
     * @returns A promise resolving to true if the operation was successful
     */
    static enableFeatureForAll(feature: string): Promise<boolean> {
        return axios.post(FeatureFlagClient.baseEndpoint + '/enable-feature-for-all', {
            feature,
        })
        .then((res) => res.data.successful);
    }
}

export { FeatureFlagClient };
export type { FeatureFlagMap };