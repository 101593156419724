import React, { useContext } from 'react';
import useSWR from 'swr';
import { SideBarItem } from './SidebarItems';
import UserBundleContext from '@/context/UserBundleContext';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/ui/ui/tooltip';
import { Button } from '@/ui/ui/button';
import qs from 'query-string';
import { HybridLink } from '@/ui/ui/hybridlink';
import { useOrgBaseURL } from '@/hooks/useOrgBaseURL';
import { HomeOutlineIcon } from '@/ui/icons/outline/HomeOutline';
import { DocumentOutlineIcon } from '@/ui/icons/outline/DocumentOutline';
import hasRights from '@/_shared/utils/hasRights';
import { GraduationHatOutlineIcon } from '@/ui/icons/outline/GraduationHatOutline';
import { GavelOutlineIcon } from '@/ui/icons/outline/GavelOutline';
import { RightsCodeKey } from '@/entities/master/PERSON';
import { ClipboardOutlineIcon } from '@/ui/icons/outline/ClipboardOutline';

interface Sidebar2Props {

}

export const Sidebar2: React.FC<Sidebar2Props> = () => {


    const { data: hasCommitteesData, } = useSWR('/api/committee/has-committees');
    const { data: hasAccManPrivilegesData } = useSWR({
        url: '/ajax/accreditation_manager.pl',
        params: {
            action: 'has_any_privileges'
        }
    });

    const orgBaseURL = useOrgBaseURL();

    const hasCommittees = hasCommitteesData?.hasCommittees === true;
    const hasAccManPrivileges = hasAccManPrivilegesData?.hasAccManPrivileges === 1;

    const userBundle = useContext(UserBundleContext);

    const hasContinuingEducation = userBundle.organization?.MY_EDUCATION_IS_ENABLED && hasRights(userBundle, RightsCodeKey.CourseWriter);


    const queryParams = qs.parse(window.location.search);
    if (queryParams.noheader) {
        return null;
    }

    const SideBarItems: SideBarItem[] = [
        {
            path: `${orgBaseURL}/dashboard`,
            name: 'Home',
            icon: <HomeOutlineIcon size={32} />,
            external: false,
            isVisible: () => true,
            divider: 'below',
            isSelected: window.location.pathname === `${orgBaseURL}/dashboard`
        },
        // Keep all items in alphabetical order below this line
        {
            path: '/accreditationmanager/accreditation_manager.pl',
            name: 'Accreditation Manager',
            icon: <ClipboardOutlineIcon size={20} />,
            external: true,
            isVisible: () => true // handled within the component itself
        },
        // THIS WILL BE IN THE PRIORITIES CARD, NOT THE SIDEBAR, UNTIL ACKNOWLEDGEMENTS IS REWRITTEN
        // {
        //     path: '/cgi/my_acknowledgements.pl',
        //     name: 'My Acknowledgements',
        //     icon: <Check />,
        //     external: true,
        //     isVisible: () => true
        // },
        {
            path: `${orgBaseURL}/committees`,
            name: 'Committees',
            icon: <GavelOutlineIcon size={23} />,
            external: false,
            isVisible: () => true,
            isSelected: window.location.pathname === `${orgBaseURL}/committees`
        },
        {
            path: '/docmgr2/docmanager.pl?repname=CourseMgmt',
            name: 'Continuing Education',
            icon: <GraduationHatOutlineIcon size={28} />,
            external: true,
            isVisible: () => hasContinuingEducation ?? false
        },
        {
            path: '/docmgr2/docmanager.pl',
            name: 'DocManager',
            icon: <DocumentOutlineIcon size={20} />,
            external: true,
            isVisible: (context) => hasRights(context, [RightsCodeKey.Author, RightsCodeKey.Signer, RightsCodeKey.CourseWriter])
        },




        // THIS WILL BE IN THE PRIORITIES CARD, NOT THE SIDEBAR, UNTIL THESE ARE REWRITTEN
        // {
        //     path: '/docmgr2/createrep.pl',
        //     name: 'Report',
        //     icon: <FindInPage />,
        //     external: true,
        //     isVisible: (context) => hasRights(context, Object.keys(rightsCodes).filter(right => right !== 'reader'), !!context.useImpersonatedRights)
        // },
        // {
        //     path: '/admin/admin.pl',
        //     name: 'Administration',
        //     icon: <Settings />,
        //     external: true,
        //     isVisible: (context) => hasRights(context, ['documentAdministrator', 'userAdministrator', 'organizationAdministrator'], !!context.useImpersonatedRights),
        //     divider: 'above',
        // },
    ];


    return (
        <div
            className='flex flex-row xs:flex-col justify-between items-center xs:justify-start px-2 py-2 xs:p-2 xs:border-r-2 border-border xs:space-y-2'
        >
            {SideBarItems.map((item) => {
                if (!item.isVisible(userBundle)) return null;
                if (item.name === 'Accreditation Manager' && !hasAccManPrivileges) return null;
                if (item.name === 'Committees' && !hasCommittees) return null;

                return (
                    <Tooltip delayDuration={100}>
                        <TooltipTrigger>
                            <HybridLink external={item.external} href={item.path}>
                                <Button 
                                    variant={ item.isSelected ? 'secondary' : 'ghost' }
                                    size={'icon'}
                                >
                                    {item.icon}
                                </Button>
                            </HybridLink>
                        </TooltipTrigger>
                        <TooltipContent side='right' sideOffset={10}>
                            <p>{item.name}</p>
                        </TooltipContent>
                    </Tooltip>
                );
            })}
        </div>
    );
};