import {
    Column,
    Entity,
    JoinColumn,
    ManyToOne,
    PrimaryColumn,
} from 'typeorm';
import { CONSENT_AGENDA_TASK,  } from './CONSENT_AGENDA_TASK';
import { SchemaEntityManager } from '../SchemaEntityManager';

// Factory function to create CONSENT_AGENDA_REFERENCE entity with dynamic schema
function createConsentAgendaReferenceEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('CONSENT_AGENDA_REFERENCE', { schema })
    class CONSENT_AGENDA_REFERENCE {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            name: 'REFERENCE_ID'
        })
        REFERENCE_ID!: number;

        @Column('number', {
            nullable: false,
            name: 'TASK_ID'
        })
        TASK_ID!: number;

        @ManyToOne(
            () => manager.getConsentAgendaTaskEntity(schema),
            consentAgendaTask => consentAgendaTask.CONSENT_AGENDA_REFERENCES
        )
        @JoinColumn({
            name: 'TASK_ID',
            referencedColumnName: 'TASK_ID'
        })
        CONSENT_AGENDA_TASK!: CONSENT_AGENDA_TASK | null;

        @Column('number', {
            nullable: false,
            name: 'LIST_INDEX'
        })
        LIST_INDEX!: number;

        @Column('varchar2', {
            nullable: true,
            length: 500,
            name: 'TEXT'
        })
        TEXT!: string | null;

        @Column('varchar2', {
            nullable: false,
            length: 500,
            name: 'HREF'
        })
        HREF!: string;

        @Column('varchar2', {
            nullable: false,
            length: 50,
            default: () => '\'active\'',
            name: 'STATUS'
        })
        STATUS!: string;
    }

    return CONSENT_AGENDA_REFERENCE;
}

// Export the factory function and the type
export { createConsentAgendaReferenceEntity };
export type CONSENT_AGENDA_REFERENCE = InstanceType<ReturnType<typeof createConsentAgendaReferenceEntity>>;
