import {
    Entity,
    PrimaryColumn
} from 'typeorm';
import { SchemaEntityManager } from '../SchemaEntityManager';

// Factory function to create COMMITTEE_COLLABORATOR entity with dynamic schema
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function createCommitteeCollaboratorEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('COMMITTEE_COLLABORATOR', { schema })
    class COMMITTEE_COLLABORATOR {
        static SCHEMANAME = schema;
        @PrimaryColumn(
            'number',
            {
                nullable: false,
                name: 'COMMITTEE_ID'
            }
        )
        COMMITTEE_ID!: number;

        @PrimaryColumn(
            'number',
            {
                nullable: false,
                precision: 10,
                scale: 0,
                name: 'USERID'
            }
        )
        USERID!: number;
    }

    return COMMITTEE_COLLABORATOR;
}

// Export the factory function
export { createCommitteeCollaboratorEntity };

// Define and export the type for instances created by the factory function
export type COMMITTEE_COLLABORATOR = InstanceType<ReturnType<typeof createCommitteeCollaboratorEntity>>;
