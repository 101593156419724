import React from 'react';
import { TextField } from '@material-ui/core';

type InputFeatureProps = {
    isVisible: boolean;
    value: string;
    onChange: (feature: string) => void;
};

export default function InputFeature(props: InputFeatureProps) {
    if (!props.isVisible) {
        return null;
    }

    return <>
        <p>{'Input the feature flag:'}</p>
        <TextField
            label='Feature'
            name='feature'
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
            fullWidth
        />
    </>;
}