import React from 'react';
import { SelectedItem } from './SelectedItem';
import { makeStyles } from '@material-ui/core/styles';
import { allowableItems, selectedItems } from './SelectedItemTypes';

export const ReactSelectLightGrey  = '#ccc';

const useStyles = makeStyles({
    selectedItemsWindow: {
        height: '8rem',
        border: `1px solid ${ReactSelectLightGrey}`,
        borderRadius: '4px',
        overflowY: 'auto',
        boxSizing: 'border-box',
        padding: '8px',
        marginBottom: '.5rem',
        resize: 'vertical',
    }
});

type SelectedItemsWindowProps = {
    selectedItemType: allowableItems
    selectedItems: selectedItems
    onRemove: (ID: number) => void
}

export function SelectedItemsWindow(props: SelectedItemsWindowProps) {
    const classes = useStyles();

    return (
        <div className={classes.selectedItemsWindow}>
            {props.selectedItems.map(item => (
                <SelectedItem
                    key={`${props.selectedItemType}-${item.label}-${item.value}`}
                    type={props.selectedItemType}
                    ID={item.value}
                    name={item.label}
                    onRemove={ID => props.onRemove(ID)}
                />
            ))}
        </div>
    );
}
