import React from 'react';

interface StarOutlineProps {
    size: number;
    fill?: boolean;
}

export const StarOutline: React.FC<StarOutlineProps> = ({ size, fill }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 18 16" fill={ fill ? '#FFD600' : 'none' } xmlns="http://www.w3.org/2000/svg">
            <path d="M8.65375 1.45995C8.78185 1.15196 9.21815 1.15196 9.34625 1.45995L10.8334 5.03548C11.0674 5.59812 11.5965 5.98254 12.2039 6.03124L16.064 6.3407C16.3965 6.36735 16.5313 6.78229 16.278 6.99929L13.337 9.51856C12.8742 9.91498 12.6721 10.537 12.8135 11.1297L13.712 14.8965C13.7894 15.221 13.4365 15.4774 13.1518 15.3035L9.84704 13.285C9.32701 12.9674 8.67299 12.9674 8.15296 13.285L4.84819 15.3035C4.56352 15.4774 4.21056 15.221 4.28795 14.8965L5.18647 11.1297C5.32786 10.537 5.12575 9.91498 4.66297 9.51856L1.72199 6.99929C1.46866 6.78229 1.60349 6.36735 1.93598 6.3407L5.79607 6.03124C6.40348 5.98254 6.93259 5.59811 7.16661 5.03548L8.65375 1.45995Z" fill={ fill ? '#FFD600' : 'none' } stroke={ fill ? '#FFD600' : '#C1C1C1' } stroke-width="1.25" />
        </svg>

    );
};