import { INFORMATION_HIERARCHY } from '@/entities/org/INFORMATION_HIERARCHY';
import React from 'react';
import { SelectedInfoHierachyContext, sortDepartments } from './InformationHierachyBrowser';
import { ChevronDownOutlineIcon } from '@/ui/icons/outline/ChevronDownOutline';
import { ChevronRightOutlineIcon } from '@/ui/icons/outline/ChevronRightOutline';
import { cn } from '@/lib/utils';
import { Button } from '@/ui/ui/button';

interface IHSidebarEntry {
    expandAll?: boolean
    entry: INFORMATION_HIERARCHY
    // Organization ID can change for example in parent manuals. So we always pass it down.
    // Departments feature has a top level entry for the organization, so it doesn't matter in that case.
    // in Manuals we have multiple top level entries, and some can be a parent org.
    orgID: number
    topLevel?: boolean
    padLeft?: boolean
}


export const IHSidebarEntry: React.FC<IHSidebarEntry> = ({ expandAll, entry, orgID, topLevel = false, padLeft = false }) => {
    const [expanded, setExpanded] = React.useState(expandAll || topLevel);
    const [ihQuery, setIHQuery] = React.useContext(SelectedInfoHierachyContext);

    React.useEffect(() => {
        setExpanded(expandAll || topLevel);
    }, [expandAll, topLevel]);

    const isCurrentEntrySelected = () => {
        return ihQuery.infoHierarchyID === entry.INFORMATION_HIERARCHY_ID && ihQuery.orgID === orgID;
    };

    // Helper function to check if the current entry or any of its children are selected
    const isChildSelected = (entry: INFORMATION_HIERARCHY): boolean => {
        // Check if the current entry is selected
        if (isCurrentEntrySelected()) {
            return true;
        }
        // Recursively check if any of the children are selected
        return entry.CHILDREN ? entry.CHILDREN.some(isChildSelected) : false;
    };
    const renderChevron = () => {
        if (entry.CHILDREN && entry.CHILDREN.length > 0) {
            return expanded ? <ChevronDownOutlineIcon size={20} /> : <ChevronRightOutlineIcon size={20} />;
        }
        return <div style={{ minWidth: 20 }} />;
    };

    // Determine if the current entry or any of its children are selected
    const hasSelectedChild = isChildSelected(entry);

    return (
        <div className={cn('flex flex-col items-start w-full', padLeft && 'pl-0.5')}>
            <Button
                data-selected={isCurrentEntrySelected()}
                className='flex flex-row w-full justify-start hover:text-primary-accent data-[selected=true]:bg-accent data-[selected=true]:text-primary-accent px-0'
                onClick={() => {
                    setExpanded(!expanded);
                    setIHQuery({
                        ...ihQuery,
                        infoHierarchyID: entry.INFORMATION_HIERARCHY_ID,
                        orgID: orgID
                    });
                }}
                size={'md'}
                variant={'ghost'}
            >
                {renderChevron()}
                <div className='flex flex-col items-start'>
                    <p className='whitespace-break-spaces text-left'>{entry.TITLE}</p>
                    {entry['isParentOrg'] === true && (
                        <p className='text-xs text-secondary'>
                            [{entry['organizationName']}]
                        </p>
                    )}
                </div>
            </Button>
            {expanded && entry.CHILDREN && (
                <div
                    className={`border-l pl-2 ml-2 w-full ${
                        hasSelectedChild ? 'border-primary' : 'border-border'
                    }`}
                >
                    {sortDepartments(entry.CHILDREN).map((child, idx) => (
                        <IHSidebarEntry
                            key={idx}
                            entry={child}
                            padLeft={true}
                            expandAll={expandAll}
                            orgID={orgID}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};