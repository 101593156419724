import {
    Column,
    Entity,
    JoinColumn,
    ManyToOne,
    OneToMany,
    PrimaryColumn,
} from 'typeorm';
import { TEMPLATE,  } from './TEMPLATE';
import { DOCUMENTREVISION,  } from './DOCUMENTREVISION';
import { INFORMATION_HIERARCHY,  } from './INFORMATION_HIERARCHY';
import { SchemaEntityManager } from '../SchemaEntityManager';

export enum DocumentTypePlStatuses {
    active = 'active',
    archived = 'archived',
}

// Factory function to create DOCUMENT_TYPE_PL entity with dynamic schema
function createDocumentTypePLEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('DOCUMENT_TYPE_PL', { schema: schema })
    class DOCUMENT_TYPE_PL {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'DOCTYPE_ID'
        })
        DOCTYPE_ID!: number;

        @Column('varchar2', {
            nullable: false,
            length: 50,
            name: 'DESCRIPTION'
        })
        DESCRIPTION!: string;

        @Column('varchar2', {
            nullable: true,
            length: 20,
            name: 'ABBREVIATION'
        })
        ABBREVIATION!: string | null;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'ORGANIZATIONID'
        })
        ORGANIZATIONID!: number | null;

        @Column('char', {
            nullable: true,
            length: 5,
            name: 'REVISABLE'
        })
        REVISABLE!: string | null;

        @Column('char', {
            nullable: true,
            name: 'QUERIABLE'
        })
        QUERIABLE!: string | null;

        @Column('char', {
            nullable: false,
            default: () => '0',
            name: 'REVISION_EFFECTIVE_DATE'
        })
        REVISION_EFFECTIVE_DATE!: string;

        @Column('char', {
            nullable: false,
            default: () => '0',
            name: 'FUTURE_ORIGINAL_EFFECTIVE_DATE'
        })
        FUTURE_ORIGINAL_EFFECTIVE_DATE!: string;

        @Column('varchar2', {
            nullable: true,
            length: 20,
            default: () => DocumentTypePlStatuses.active,
            name: 'STATUS'
        })
        STATUS!: string | null;

        @Column('char', {
            nullable: false,
            default: () => '0',
            name: 'INCLUDE_ON_LINKS_PAGE'
        })
        INCLUDE_ON_LINKS_PAGE!: string;

        @Column('number', {
            nullable: false,
            default: () => 0,
            name: 'DOC_REQUEST_RECEIVING_DEPARTMENT_ID',
        })
        DOC_REQUEST_RECEIVING_DEPARTMENT_ID!: number;

        @ManyToOne(
            () => manager.getInformationHierarchyEntity(schema),
            (informationHierarchy) => informationHierarchy.DOC_REQUEST_RECEIVED_TYPES
        )
        @JoinColumn({
            name: 'DOC_REQUEST_RECEIVING_DEPARTMENT_ID'
        })
        DOC_REQUEST_RECEIVING_DEPARTMENT!: INFORMATION_HIERARCHY | null;

        @OneToMany(() => manager.getTemplateEntity(schema),
            (template) => template.DOCTYPE
        )
        TEMPLATES!: TEMPLATE[] | null;

        @OneToMany(
            () => manager.getDocumentRevisionEntity(schema),
            (documentRevision) => documentRevision.DOCTYPE
        )
        DOCUMENTREVISIONS!: DOCUMENTREVISION[] | null;

        static Statuses = DocumentTypePlStatuses;
    }

    return DOCUMENT_TYPE_PL;
}

export { createDocumentTypePLEntity };
export type DOCUMENT_TYPE_PL = InstanceType<ReturnType<typeof createDocumentTypePLEntity>>;
