import {
    Column,
    Entity,
    OneToMany,
    PrimaryColumn
} from 'typeorm';
import { numberToBooleanTransformer } from '../_helperFunctions/transformers';
import { SchemaEntityManager } from '../SchemaEntityManager';
import { SUBSCRIBER_TEMPLATE } from './SUBSCRIBER_TEMPLATE';

function createPublisherTemplateEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('PUBLISHER_TEMPLATE', { schema: schema })
    class PUBLISHER_TEMPLATE {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            name: 'TEMPLATEID'
        })
        TEMPLATEID!: number;

        @Column('varchar2', {
            nullable: false,
            name: 'TEMPLATE_NAME',
        })
        TEMPLATE_NAME!: string;

        @Column('number', {
            name: 'IS_MODEL_TEMPLATE',
            transformer: numberToBooleanTransformer
        })
        IS_MODEL_TEMPLATE!: boolean;

        @OneToMany(
            () => manager.getSubscriberTemplateEntity(schema),
            (subscriberTemplate) => subscriberTemplate.PUBLISHER_TEMPLATE
        )
        SUBSCRIBER_TEMPLATES!: SUBSCRIBER_TEMPLATE[] | null;
    }

    return PUBLISHER_TEMPLATE;
}

export { createPublisherTemplateEntity };
export type PUBLISHER_TEMPLATE = InstanceType<ReturnType<typeof createPublisherTemplateEntity>>;
