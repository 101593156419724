import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { DOCUMENTREVISION } from '../../../entities/org/DOCUMENTREVISION';
import DataTable from '../../_shared/DataTable/DataTable';
import UserBundleContext from '../../../context/UserBundleContext';
import { MUIDataTableColumn } from 'mui-datatables';
import { ClickableTitleCell } from '../../_shared/DataTable/ClickableTitleCell';
import Subheader from '../../_shared/Subheader/Subheader';
import SubheaderItem from '../../_shared/Subheader/SubheaderItem';
import { LoadingIndicator } from '../../_shared/LoadingIndicator';
import { LoadingStatuses } from '../../../utils/LoadingStatuses';
import hasRights from '../../../_shared/utils/hasRights';
import { createSortableDate } from '../../../_shared/utils/docManagerFunctions';
import { TableCell, TableRow } from '@material-ui/core';
import WhatsNewLinkedDocRevs from './WhatsNewLinkedDocRevs';
import { RightsCodeKey } from '@/entities/master/PERSON';
import setWindowTitle from '../../../utils/setWindowTitle';
import { constructFullName } from '@/components/UserPicker/utils/constructFullName';

export default function WhatsNew() {
    const context = useContext(UserBundleContext);

    const Tabs: { [key: string]: string } = !hasRights(
        context,
        [
            RightsCodeKey.Signer,
            RightsCodeKey.Author,
            RightsCodeKey.DocumentAdministrator,
            RightsCodeKey.CourseWriter,
            RightsCodeKey.OrganizationAdministrator,
            RightsCodeKey.UserAdministrator
        ]
    ) ? {
        Official: 'Official'
    } : {
        Official: 'Official',
        Draft: 'Draft',
        Archive: 'Archive',
        Pending: 'Pending',
        All: 'All'
    };

    const [loadingStatus, setLoadingStatus] = useState(LoadingStatuses.MOUNTING);
    const [selectedTab, setSelectedTab] = useState<string>(Tabs.Official);
    const [newDocumentRevisions, setNewDocumentRevisions] = useState<DOCUMENTREVISION[]>([]);

    const [officialDocumentRevisions, setOfficialDocumentRevisions] = useState<DOCUMENTREVISION[]>([]);
    const [archiveDocumentRevisions, setArchiveDocumentRevisions] = useState<DOCUMENTREVISION[]>([]);
    const [draftDocumentRevisions, setDraftDocumentRevisions] = useState<DOCUMENTREVISION[]>([]);
    const [pendingDocumentRevisions, setPendingDocumentRevisions] = useState<DOCUMENTREVISION[]>([]);

    const [refresh, setRefresh] = useState(true);

    useEffect(() => {
        setWindowTitle('What\'s New');
    }, []);

    useEffect(() => {
        if (refresh) {
            loadInitialData().then();
        }
    }, [refresh]);

    async function loadInitialData() {

        const resOfficial = await axios.get('/api/homepage/card/whats-new/official');
        const resArchive = await axios.get('/api/homepage/card/whats-new/archive');
        const resDraft = await axios.get('/api/homepage/card/whats-new/draft');
        const resPending = await axios.get('/api/homepage/card/whats-new/pending');
        if (
            resOfficial.data &&
            resArchive.data &&
            resDraft.data &&
            resPending.data
        ) {
            setLoadingStatus(LoadingStatuses.LOADING);
            setOfficialDocumentRevisions(resOfficial.data.documentRevisions);
            setArchiveDocumentRevisions(resArchive.data.documentRevisions);
            setPendingDocumentRevisions(resPending.data.documentRevisions);
            setDraftDocumentRevisions(resDraft.data.documentRevisions);
            if (selectedTab === 'Official') {
                setNewDocumentRevisions(resOfficial.data.documentRevisions);
            } else if (selectedTab === 'Archive') {
                setNewDocumentRevisions(resArchive.data.documentRevisions);
            } else if (selectedTab === 'Draft') {
                setNewDocumentRevisions(resDraft.data.documentRevisions);
            } else if (selectedTab === 'Pending') {
                setNewDocumentRevisions(resPending.data.documentRevisions);
            } else {
                setNewDocumentRevisions([
                    ...resOfficial.data.documentRevisions,
                    ...resDraft.data.documentRevisions,
                    ...resArchive.data.documentRevisions,
                    ...resPending.data.documentRevisions
                ]);
            }
            setLoadingStatus(LoadingStatuses.READY);
            setRefresh(false);
        }
    }

    function setData() {
        if (selectedTab === 'Official') {
            setNewDocumentRevisions(officialDocumentRevisions);
        } else if (selectedTab === 'Archive') {
            setNewDocumentRevisions(archiveDocumentRevisions);
        } else if (selectedTab === 'Draft') {
            setNewDocumentRevisions(draftDocumentRevisions);
        } else if (selectedTab === 'Pending') {
            setNewDocumentRevisions(pendingDocumentRevisions);
        } else {
            setNewDocumentRevisions([
                ...officialDocumentRevisions,
                ...draftDocumentRevisions,
                ...archiveDocumentRevisions,
                ...pendingDocumentRevisions
            ]);
        }
    }

    useEffect(() => {
        setData();
    }, [selectedTab]);

    const formattedTableData = (newDocumentRevisions || [])
        .map(docrev => {
            const returnObj: {
                [key: string]: string | React.ReactNode;
            } = {
                DocID: docrev.DOCID,
                RevNo: docrev.REVNO,
                Title: docrev.TITLE,
                DocType: docrev.DOCTYPE?.DESCRIPTION,
                Owner: docrev.DOCUMENT?.DOCOWNER ? constructFullName(docrev.DOCUMENT?.DOCOWNER, 'firstMiddleLast') : '',
                [context.organization?.ORGUNITNAME || 'Department']: docrev.DOCUMENT?.INFORMATION_HIERARCHY?.TITLE,
            };

            returnObj['Official Date'] = docrev.OFFICIALDT
                ? createSortableDate(new Date(docrev.OFFICIALDT))
                : '-';

            returnObj.Archived = docrev.OBSOLETEDT
                ? createSortableDate(new Date(docrev.OBSOLETEDT))
                : null;

            return returnObj;
        });



    return <div>
        <Subheader
            value={selectedTab}
            onChange={
                async value => {
                    setLoadingStatus(LoadingStatuses.LOADING);
                    setSelectedTab(value);
                    // otherwise, it will happen to fast and the count won't update
                    await new Promise(r => setTimeout(r, 5));
                    setLoadingStatus(LoadingStatuses.READY);
                }
            }
        >
            {Object.keys(Tabs).map(tab => <SubheaderItem key={Tabs[tab]} label={Tabs[tab]} value={Tabs[tab]} />)}
        </Subheader>
        {
            loadingStatus !== LoadingStatuses.READY &&
            <LoadingIndicator />
        }
        {
            loadingStatus === LoadingStatuses.READY &&
            <DataTable
                onRefresh={() => setRefresh(true)}
                isRefreshing={refresh}
                muiDataTableProps={{
                    data: formattedTableData,
                    title: 'What\'s New',
                    options: {
                        expandableRows: true,
                        renderExpandableRow: (rowData, rowMeta) => {
                            const dr = newDocumentRevisions[rowMeta.dataIndex];

                            return <TableRow>
                                <TableCell />
                                <TableCell
                                    colSpan={2}
                                >
                                    Revision Notes:
                                    <br />
                                    {dr.REVISION_NOTE && dr.REVISION_NOTE.length > 0 ? <span dangerouslySetInnerHTML={{ __html: dr.REVISION_NOTE }} /> : ' No revision notes'}
                                </TableCell>
                                <TableCell
                                    colSpan={formattedTableData[0] && Object.keys(formattedTableData[0]).length - 1}
                                >
                                    <WhatsNewLinkedDocRevs docRevId={dr.DOCREVID} />
                                </TableCell>
                            </TableRow>;
                        }
                    },
                    columns: formattedTableData[0] && Object.keys(formattedTableData[0]).map(key => {
                        const returnObj: MUIDataTableColumn = {
                            name: key
                        };

                        if (key === 'Title') {
                            returnObj.options = {
                                customBodyRender: (value, tableMeta) => ClickableTitleCell(
                                    context.organization?.PREFIX,
                                    tableMeta.rowData[0],
                                    tableMeta.rowData[1],
                                    value
                                )
                            };
                        }

                        return returnObj;
                    })
                }}
            />
        }
    </div>;
}
