import React from 'react';

interface BuildingsOutlineProps {
    size: number;
}

export const BuildingsOutlineIcon: React.FC<BuildingsOutlineProps> = ({ size }) => {
    return (
        <svg width={size.toString()} height={size.toString()} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.63867 5.51367H6.48589V13.2081H1.63867C1.56964 13.2081 1.51367 13.1522 1.51367 13.0831V5.63867C1.51367 5.56964 1.56964 5.51367 1.63867 5.51367Z" stroke="#6B7280" strokeWidth="1.25"/>
        <path d="M6.52783 2.40234H14.3612C14.4302 2.40234 14.4862 2.45831 14.4862 2.52734V13.4718C14.4862 13.5408 14.4302 13.5968 14.3612 13.5968H6.52783C6.4588 13.5968 6.40283 13.5408 6.40283 13.4718V2.52734C6.40283 2.45831 6.4588 2.40234 6.52783 2.40234Z" fill="white" stroke="#6B7280" strokeWidth="1.25"/>
        <path d="M5.3335 4.44434H5.77794V13.7777H5.3335V4.44434Z" fill="white"/>
        <path d="M2.6665 7.47266C2.6665 7.33458 2.77843 7.22266 2.9165 7.22266H4.19428C4.33235 7.22266 4.44428 7.33459 4.44428 7.47266V8.52821C4.44428 8.66628 4.33235 8.77821 4.19428 8.77821H2.9165C2.77843 8.77821 2.6665 8.66628 2.6665 8.52821V7.47266Z" fill="#6B7280"/>
        <path d="M2.6665 9.80566C2.6665 9.66759 2.77843 9.55566 2.9165 9.55566H4.19428C4.33235 9.55566 4.44428 9.66759 4.44428 9.80566V10.8612C4.44428 10.9993 4.33235 11.1112 4.19428 11.1112H2.9165C2.77843 11.1112 2.6665 10.9993 2.6665 10.8612V9.80566Z" fill="#6B7280"/>
        <path d="M8 9.02734C8 8.88927 8.11193 8.77734 8.25 8.77734H9.52778C9.66585 8.77734 9.77778 8.88927 9.77778 9.02734V10.0829C9.77778 10.221 9.66585 10.3329 9.52778 10.3329H8.25C8.11193 10.3329 8 10.221 8 10.0829V9.02734Z" fill="#6B7280"/>
        <path d="M11.1113 9.02734C11.1113 8.88927 11.2233 8.77734 11.3613 8.77734H12.6391C12.7772 8.77734 12.8891 8.88927 12.8891 9.02734V10.0829C12.8891 10.221 12.7772 10.3329 12.6391 10.3329H11.3613C11.2233 10.3329 11.1113 10.221 11.1113 10.0829V9.02734Z" fill="#6B7280"/>
        <path d="M9.77783 10.9727C9.77783 10.8346 9.88976 10.7227 10.0278 10.7227H10.8612C10.9992 10.7227 11.1112 10.8346 11.1112 10.9727V13.056H9.77783V10.9727Z" fill="#6B7280"/>
        <path d="M11.1113 4.36133C11.1113 4.22326 11.2233 4.11133 11.3613 4.11133H12.6391C12.7772 4.11133 12.8891 4.22326 12.8891 4.36133V5.41688C12.8891 5.55496 12.7772 5.66688 12.6391 5.66688H11.3613C11.2233 5.66688 11.1113 5.55495 11.1113 5.41688V4.36133Z" fill="#6B7280"/>
        <path d="M8 6.69434C8 6.55626 8.11193 6.44434 8.25 6.44434H9.52778C9.66585 6.44434 9.77778 6.55626 9.77778 6.69434V7.74989C9.77778 7.88796 9.66585 7.99989 9.52778 7.99989H8.25C8.11193 7.99989 8 7.88796 8 7.74989V6.69434Z" fill="#6B7280"/>
        <path d="M11.1113 6.69434C11.1113 6.55626 11.2233 6.44434 11.3613 6.44434H12.6391C12.7772 6.44434 12.8891 6.55626 12.8891 6.69434V7.74989C12.8891 7.88796 12.7772 7.99989 12.6391 7.99989H11.3613C11.2233 7.99989 11.1113 7.88796 11.1113 7.74989V6.69434Z" fill="#6B7280"/>
        <path d="M8 4.36133C8 4.22326 8.11193 4.11133 8.25 4.11133H9.52778C9.66585 4.11133 9.77778 4.22326 9.77778 4.36133V5.41688C9.77778 5.55496 9.66585 5.66688 9.52778 5.66688H8.25C8.11193 5.66688 8 5.55495 8 5.41688V4.36133Z" fill="#6B7280"/>
        </svg>
    );
};