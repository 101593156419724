import React from 'react';
import { EditItemVisibility } from '../../../../_shared/Forms/EditItemVisibility/EditItemVisibility';
import { closeWindow } from '../../../../../utils/savebarUtilities';

type EditDocVisibilityProps = {
    match: any
}

// this intermediary function is only necessary for now because we need the match object
// to pass the docid to EditItemVisibility; once everything is loaded in React, the docid
// could be passed as a prop without the URL, and this can be skipped to go straight to
// EditItemVisibility:
export function EditDocVisibility(props: EditDocVisibilityProps) {
    return (
        <EditItemVisibility
            objectType={'DOCID'}
            objectID={props.match.params.docid}
            closeModal={() => closeWindow()}
        />
    );
}
