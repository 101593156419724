import {
    Column,
    Entity,
    JoinColumn,
    ManyToOne,
    PrimaryColumn
} from 'typeorm';
import { numberToBooleanTransformer } from '../_helperFunctions/transformers';
import { SchemaEntityManager } from '../SchemaEntityManager';
import { type PUBLISHER_TEMPLATE } from './PUBLISHER_TEMPLATE';

function createSubscriberTemplateEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('SUBSCRIBER_TEMPLATE', { schema: schema })
    class SUBSCRIBER_TEMPLATE {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            name: 'SUBSCRIBER_ORGID'
        })
        SUBSCRIBER_ORGID!: number;

        @Column('number', {
            nullable: false,
            name: 'TEMPLATEID'
        })
        TEMPLATEID!: number;

        @PrimaryColumn('number', {
            nullable: false,
            name: 'PUBLISHER_TEMPLATEID'
        })
        PUBLISHER_TEMPLATEID!: number;

        @Column('varchar2', {
            nullable: false,
            name: 'TEMPLATE_NAME',
        })
        TEMPLATE_NAME!: string;

        @Column('number', {
            name: 'IS_MODEL_TEMPLATE',
            transformer: numberToBooleanTransformer
        })
        IS_MODEL_TEMPLATE!: boolean;

        @ManyToOne(
            () => manager.getPublisherTemplateEntity(schema),
            (publisherTemplate) => publisherTemplate.SUBSCRIBER_TEMPLATES
        )
        @JoinColumn({
            name: 'PUBLISHER_TEMPLATEID'
        })
        PUBLISHER_TEMPLATE!: PUBLISHER_TEMPLATE;
    }

    return SUBSCRIBER_TEMPLATE;
}

export { createSubscriberTemplateEntity };
export type SUBSCRIBER_TEMPLATE = InstanceType<ReturnType<typeof createSubscriberTemplateEntity>>;
