import {
    Table as TanStackTable,
    ColumnDef,
    flexRender,
    getCoreRowModel,
    useReactTable,
    getPaginationRowModel,
    SortingState,
    getSortedRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    VisibilityState,
    ColumnFiltersState,
    getFilteredRowModel,
    getExpandedRowModel,
    Row,
    ExpandedState,
} from '@tanstack/react-table';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from '@/ui/ui/table';

import React, { Ref, forwardRef, useEffect, useImperativeHandle } from 'react';
import { cn } from '@/lib/utils';
import { ExportModal, ExportTablePhase } from '@/v2/pages/search/ExportModal';
import { exportTableRequest } from '@/v2/components/documentrevisiontable/utils';
import { Skeleton } from '../skeleton';


export type TableExportOptions = 'csv' | 'pdf' | 'xlsx';


export interface DataTableSubComponentProps<TData> {
    table: TanStackTable<TData>
}

export interface DataTableToolbarProps<TData> extends DataTableSubComponentProps<TData> {
    datatableRef: React.RefObject<DataTableHandle<TData>>;
    noExtraHeaders?: boolean
}


export interface DataTableProps<TData, TValue> {
    loading: boolean
    columns: ColumnDef<TData, TValue>[]
    data: TData[],
    toolbar?: React.ComponentType<DataTableToolbarProps<TData>>
    pagination?: React.ComponentType<DataTableSubComponentProps<TData>>
    exportAsFileName: string
    renderRowSubComponent?: (row: Row<TData>, isExporting: boolean) => React.ReactNode
    limitTableHeight?: boolean
    // manualmode is used to turn off most of the features of the table.
    // This is used for when the data is being sorted/paginated/faceted outside of the table (on the server for example).
    manualMode?: boolean,
    // The sorting + onSortingChange can be used to hoist the state outside of the table.
    // This is useful in manualMode, since the table won't manage sorting for you
    // but will still have controls to manage sorting, which you need to be able to watch for changes.
    sorting?: SortingState
    onSortingChange?: React.Dispatch<React.SetStateAction<SortingState>>
    ref?: Ref<DataTableHandle<TData>>,
    initialFilterColumns?: {
        id: string,
        value: unknown
    }[],
    initialColumnVisibility?: VisibilityState,
    renderExpandedRowForTableExport: RenderExpandedRowFunction<TData>
}

export type RenderExpandedRowFunction<TData> = (entry: TData, exportType: TableExportOptions) => Promise<string[]>

export interface DataTableHandle<TData> {
    // The fetchData function is used to refetch the data from the server in case it needs to be changed for export purposes.
    // We use this to un-paginate the data for export in manual mode tables (Search2.tsx)
    // The cleanupAndReset function is used to reset the table to its original state after export.
    // BE CAREFUL WITH THESE - You can essentially do a Denial of Service attack on the server by exporting a huge dataset.
    // All of the rows are creating a request each, and the server gets bogged down handling them all, and other users can't use the system.
    // The current system ensures that only 1 request is sent at a time, and the unpaginated version of the data is never rendered on the screen to avoid sending these requests.
    exportTable: (exportType: TableExportOptions, expandedHeaders: string[], fetchData?: () => Promise<void>, cleanupAndReset?: () => Promise<void>) => Promise<void>
    getTable: () => TanStackTable<TData>
}


const DataTableInner = <TData, TValue>({
    loading,
    columns,
    data,
    toolbar: Toolbar,
    pagination: Pagination,
    exportAsFileName,
    renderRowSubComponent: renderRowSubComponent,
    sorting,
    onSortingChange,
    limitTableHeight = true,
    manualMode = false,
    initialFilterColumns,
    initialColumnVisibility,
    renderExpandedRowForTableExport
}: DataTableProps<TData, TValue>, ref: React.Ref<DataTableHandle<TData>>) => {


    const [internalSorting, setInternalSorting] = React.useState<SortingState>([]);
    const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({});
    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
        []
    );
    const [expanded, setExpanded] = React.useState<ExpandedState>({});

    // Reset all filters when data changes.
    // This is required for when we change the data source... e.g. when we switch between tabs on the IH explorer.
    useEffect(() => {
        setInternalSorting([]);
        setColumnVisibility({});
        setColumnFilters([]);
        setExpanded({});
        if (initialFilterColumns && initialFilterColumns.length > 0) {
            setColumnFilters(initialFilterColumns);
        }

        if (initialColumnVisibility) {
            setColumnVisibility(initialColumnVisibility);
        }
    }, [data]);


    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: Pagination !== undefined ? getPaginationRowModel() : undefined,

        onSortingChange: onSortingChange ?? setInternalSorting,
        getSortedRowModel: getSortedRowModel(),

        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),

        onColumnVisibilityChange: setColumnVisibility,
        onColumnFiltersChange: setColumnFilters,

        getFilteredRowModel: getFilteredRowModel(),

        getExpandedRowModel: getExpandedRowModel(),
        onExpandedChange: setExpanded,

        manualFiltering: manualMode,
        manualPagination: manualMode,
        manualSorting: manualMode,
        state: {
            sorting: sorting ?? internalSorting,
            columnVisibility,
            columnFilters,
            expanded
        },
        initialState: {
            columnFilters: initialFilterColumns,
            columnVisibility: initialColumnVisibility
        }
    });

    const renderLoading = () => {

        const rows: JSX.Element[] = [];
        for (let i = 0; i < 10; i++) {
            rows.push(<TableRow key={i}>
                {columns.map(c => <TableCell key={c.id}>
                   <Skeleton className='m-2'/>
                </TableCell>)}
            </TableRow>);
        }


        return rows;
    };

    const [exportModalOpen, setExportModalOpen] = React.useState(false);
    const [rowsRendered, setRowsRendered] = React.useState(0);
    const [exportPhase, setExportPhase] = React.useState<ExportTablePhase>(ExportTablePhase.COMPLETE);


    useImperativeHandle(ref, () => ({
        getTable: (): TanStackTable<TData> => {
            return table;
        },
        exportTable: async (exportType: TableExportOptions, expandedHeaders: string[], fetchData?: () => Promise<void>, cleanupAndReset?: () => Promise<void>) => {
            setExportPhase(ExportTablePhase.PREPARING);

            if (fetchData !== undefined) {
                await fetchData();
            }

            const extraHeaders = table.getIsSomeRowsExpanded() ? expandedHeaders : [];
            const rows = table.getPrePaginationRowModel().rows;

            setExportModalOpen(true);
            setExportPhase(ExportTablePhase.RENDERING);
            setRowsRendered(0);
            let rendered = 0;
            const exportedRows: string[][] = [];
            for (const row of rows) {
                const cells: string[] = [];
                for (const cell of row.getAllCells()) {
                    if (!cell.column.columnDef.meta?.skipExport) {
                        let cellValue: string | Promise<string> = '';
                        if (exportType === 'pdf' && cell.column.columnDef.meta?.exportValue !== undefined) {
                            cellValue = cell.column.columnDef.meta?.exportValue(cell);
                        } else {
                            cellValue = cell.getValue<string>();
                        }
                        cells.push(await cellValue);
                    }
                }
                if (row.getIsExpanded()) {
                    const expandedRow = await renderExpandedRowForTableExport(row.original, exportType);
                    exportedRows.push(cells.concat(expandedRow));
                } else {
                    exportedRows.push(cells);
                }
                rendered++;
                setRowsRendered(rendered);
            }

            const headers = table.getAllColumns()
                .filter(c => !c.columnDef.meta?.skipExport)
                .map(column => column.columnDef.meta?.['label'] ?? column.id)
                .concat(extraHeaders);

            setExportPhase(ExportTablePhase.CONVERTING);
            exportTableRequest(exportType, headers, exportedRows, exportAsFileName);

            if (cleanupAndReset !== undefined) {
                await cleanupAndReset();
            }

            setExportPhase(ExportTablePhase.COMPLETE);
            // setExportModalOpen(false);
        }
    }));

    const renderBody = () => {
        if (loading) {
            return renderLoading();
        }

        // not loading....

        // table is processing
        // if (table.getRowModel().rows.length === 0 && data.length > 0) {
        //     return renderLoading();
        // }

        if (table.getRowModel().rows.length === 0) {
            return <TableRow>
                <TableCell colSpan={columns.length} className="text-center">
                    No results.
                </TableCell>
            </TableRow>;
        }

        return table.getRowModel().rows.map((row) => {
            return <>
                <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && 'selected'}
                >
                    {row.getVisibleCells().map((cell) => (
                        <TableCell key={cell.id}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </TableCell>
                    ))}
                </TableRow>
                {(row.getIsExpanded() && renderRowSubComponent) && <TableRow key={`${row.id}-expanded`}>
                    {renderRowSubComponent(row, exportPhase !== ExportTablePhase.COMPLETE)}
                </TableRow>}
            </>;
        });
    };


    return (
        <>
            {Toolbar && <Toolbar datatableRef={ref as React.RefObject<DataTableHandle<TData>>} table={table} />}
            <div className={cn('rounded-md border overflow-x-auto', limitTableHeight && 'max-h-[38rem] overflow-y-auto')}>
                <Table>
                    <TableHeader>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <TableRow key={headerGroup.id} >
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <TableHead key={header.id}>
                                            {header.isPlaceholder
                                                ? null
                                                : flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )}
                                        </TableHead>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableHeader>
                    <TableBody >
                        {renderBody()}
                    </TableBody>
                </Table>
            </div>
            <ExportModal exportModalOpen={exportModalOpen} onExportModalOpenChange={setExportModalOpen}
                rowsRendered={rowsRendered} totalRows={table.getRowCount()} phase={exportPhase} />
            {Pagination && <div className='py-2 w-full'>
                <Pagination table={table} />
            </div>}
            {/* <pre>{JSON.stringify(table.getState(), undefined, 4)}</pre> */}
        </>
    );
};

export const DataTable = forwardRef(DataTableInner) as
    <TData, TValue>(props: DataTableProps<TData, TValue> & { ref?: React.Ref<DataTableHandle<TData>> }) => ReturnType<typeof DataTableInner>;