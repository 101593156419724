import { DataTableColumnHeader } from '@/ui/ui/datatable/column-header';
import UserBundleContext, { UserBundleType } from '@/context/UserBundleContext';
import { useContext } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { ConsentAgendaEntry } from './MyMeetingsTable';
import { ConsentAgendaStatuses } from '@/entities/org/CONSENT_AGENDA';

export const useMyMeetingsColumns = () => {
    const userBundle: UserBundleType = useContext(UserBundleContext);
    const userID = userBundle.user?.USERID ?? -1;
    const orgID = userBundle.organization?.ORGANIZATIONID ?? -1;

    const columns: ColumnDef<ConsentAgendaEntry>[] = [
        {
            id: 'committeeName',
            accessorKey: 'committeeName',
            enableSorting: true,
            cell: ({ cell }) => {
                const committee = cell.row.original.committee;
                const consentAgendaID = cell.row.original.id;

                const agendaNotReleased = cell.row.original.status === ConsentAgendaStatuses.invisible_and_mutable;
                const isSecretaryOrChairperson = [committee?.SECRETARY_USERID, committee?.CHAIRPERSON_USERID].includes(userID);
                const isCommitteeCollaborator = committee?.COLLABORATORS?.some(collaborator => collaborator.USERID === userID);
                const hasAccess = !agendaNotReleased || (isSecretaryOrChairperson || isCommitteeCollaborator);

                return <p
                    onClick={ 
                        () => {
                            hasAccess && window.open(
                                `/docmgr2/consent_agenda_viewing.pl?organizationid=${orgID}&consent_agenda_id=${consentAgendaID}`,
                                '_blank'
                            );
                        }
                    }
                    className='inline-block text-secondary whitespace-break-spaces font-medium text-left px-2 p-1.5 hover:bg-accent  hover:cursor-pointer rounded-sm'
                >
                    {cell.getValue<string>() + (agendaNotReleased ? ' (Unreleased)' : '')}
                </p>;
            },
            header: ({ column }) => {
                return (
                    <DataTableColumnHeader column={column} title='Committee' />
                );
            },
            filterFn: (row, id, value) => {
                return value.includes(row.getValue(id));
            },
            meta: {
                label: 'Committee'
            }
        },
        {
            id: 'date',
            accessorKey: 'date',
            enableSorting: true,
            cell: ({ cell }) => {
                return <p className='text-secondary'>{cell.getValue<Date>()?.toDateString() ?? '-'}</p>;
            },
            header: ({ column }) => {
                return (
                    <DataTableColumnHeader column={column} title='Date' />
                );
            },
            meta: {
                label: 'Date'
            }
        },
        {
            id: 'year',
            accessorKey: 'year',
            enableSorting: true,  
            cell: ({ cell }) => {
                return <p className='text-secondary'>{cell.getValue<string>() ?? '-'}</p>;
            },
            header: ({ column }) => {
                return (
                    <DataTableColumnHeader column={column} title='Year' />
                );
            },
            filterFn: (row, id, value) => {
                return value.includes(row.getValue(id));
            },
            meta: {
                label: 'Year'
            }
        }
    ];
    
    
    
    return columns;
};