import { makeStyles } from '@material-ui/core';
import { PERSON } from '../../../../entities/master/PERSON';
import { USERGROUP } from '../../../../entities/org/USERGROUP';
import { DragDropContext } from 'react-beautiful-dnd';
import UserPicker from '../../../UserPicker/UserPicker';
import H3WithHelpTip from '../H3WithHelpTip';

type EditItemVisibilityUserPickerProps = {
    objectType: 'DOCID' | 'INFORMATION_HIERARCHY_ID' | 'PUBLISHER_ID'
    objectSubType?: 'DEPARTMENT' | 'MANUAL'
    updateUsers: (users: (PERSON | USERGROUP)[]) => void
    updateGroups: (groups: (PERSON | USERGROUP)[]) => void
    updateLimitedUsers: (users: (PERSON | USERGROUP)[]) => void
    updateLimitedGroups: (groups: (PERSON | USERGROUP)[]) => void
    updateFullUsers: (users: (PERSON | USERGROUP)[]) => void
    updateFullGroups: (groups: (PERSON | USERGROUP)[]) => void
    viewers: (PERSON | USERGROUP)[]
    limitedViewers: (PERSON | USERGROUP)[]
    fullViewers: (PERSON | USERGROUP)[]
    unremovablePeople: ({ [key: string]: string })
    unremovableGroups: ({ [key: string]: string })
    disabled: boolean
    userPickerClass: string
}

const useStyles = makeStyles({
    visibilityUserPickerContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
    },
});

export function EditItemVisibilityUserPicker(props: EditItemVisibilityUserPickerProps) {    
    const classes = useStyles();
    const isDepartment = props.objectType === 'INFORMATION_HIERARCHY_ID' && props.objectSubType === 'DEPARTMENT';

    return (
        <div className={classes.visibilityUserPickerContainer}>
            {isDepartment &&
                <>
                    <div>  
                        <H3WithHelpTip
                            helpText={'Assigned users or groups can view the visibility-restricted department on the Home page.'}
                            text={'Limited Visibility'}
                        />
                        <div className={props.userPickerClass}>
                            <DragDropContext onDragEnd={() => {}}>
                                <UserPicker
                                    getUsers={true}
                                    getGroups={true}
                                    addUserSignature={(newUsers: (PERSON | USERGROUP)[]) => props.updateLimitedUsers(newUsers)}
                                    removeUserSignature={(newUsers: (PERSON | USERGROUP)[]) => props.updateLimitedUsers(newUsers)}
                                    addGroupSignature={(newUsers: (PERSON | USERGROUP)[]) => props.updateLimitedGroups(newUsers)}
                                    removeGroupSignature={(newUsers: (PERSON | USERGROUP)[]) => props.updateLimitedGroups(newUsers)}
                                    selectedUsersPlusGroups={props.limitedViewers}
                                    unremovablePeople={props.unremovablePeople}
                                    unremovableGroups={props.unremovableGroups}
                                    isDragDisabled={true}
                                    disabled={props.disabled}
                                    hideArchivedGroups={true}
                                />
                            </DragDropContext>
                        </div>
                    </div>
                    <div>
                        <H3WithHelpTip
                            helpText={'Assigned users or groups can view the visibility-restricted department on the Home page and view visibility-restricted documents in the department.'}
                            text={'Full Visibility'}
                        />
                        <div className={props.userPickerClass}>
                            <DragDropContext onDragEnd={() => {}}>
                                <UserPicker
                                    getUsers={true}
                                    getGroups={true}
                                    addUserSignature={(newUsers: (PERSON | USERGROUP)[]) => props.updateFullUsers(newUsers as PERSON[])}
                                    removeUserSignature={(newUsers: (PERSON | USERGROUP)[]) => props.updateFullUsers(newUsers as PERSON[])}
                                    addGroupSignature={(newUsers: (PERSON | USERGROUP)[]) => props.updateFullGroups(newUsers as PERSON[])}
                                    removeGroupSignature={(newUsers: (PERSON | USERGROUP)[]) => props.updateFullGroups(newUsers as PERSON[])}
                                    selectedUsersPlusGroups={props.fullViewers}
                                    unremovablePeople={props.unremovablePeople}
                                    unremovableGroups={props.unremovableGroups}
                                    isDragDisabled={true}
                                    disabled={props.disabled}
                                    hideArchivedGroups={true}
                                    />
                            </DragDropContext>
                        </div>
                    </div>
                </>
            }

            {!isDepartment &&
                <>
                    <div className={props.userPickerClass}>
                        <DragDropContext onDragEnd={() => {}}>
                            <UserPicker
                                getUsers={true}
                                getGroups={true}
                                addUserSignature={(newUsers: (PERSON | USERGROUP)[]) => props.updateUsers(newUsers as PERSON[])}
                                removeUserSignature={(newUsers: (PERSON | USERGROUP)[]) => props.updateUsers(newUsers as PERSON[])}
                                addGroupSignature={(newUsers: (PERSON | USERGROUP)[]) => props.updateGroups(newUsers as PERSON[])}
                                removeGroupSignature={(newUsers: (PERSON | USERGROUP)[]) => props.updateGroups(newUsers as PERSON[])}
                                selectedUsersPlusGroups={props.viewers}
                                unremovablePeople={props.unremovablePeople}
                                unremovableGroups={props.unremovableGroups}
                                isDragDisabled={true}
                                disabled={props.disabled}
                                hideArchivedGroups={true}
                                />
                        </DragDropContext>
                    </div>
                </>
            }
        </div>
    );
}
