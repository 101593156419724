import React, { useEffect, useState } from 'react';
import { createMuiTheme, MuiThemeProvider, Paper } from '@material-ui/core';
import MUIDataTable, { MUIDataTableProps } from 'mui-datatables';
import DataTableFooter from './DataTableFooter';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    dataTablePaper: {
        margin: '1rem'
    },
    blankToolbar: {
        height: '3rem',
        display: 'flex',
        alignItems: 'center',
        paddingRight: '1rem'
    },
    isDisabledDiv: {
        pointerEvents: 'none',
        '-webkit-filter': 'grayscale(50%) opacity(50%)',
        filter: 'grayscale(50%) opacity(50%)'
    }
});

interface DataTableProps {
    // Custom props for this wrapper:
    onRefresh?: () => void
    isRefreshing?: boolean
    customFooter?: JSX.Element
    noFooter?: boolean
    isDisabled?: boolean
    noFilterCount?: boolean

    // Props for MUIDataTables, the 3rd party package:
    muiDataTableProps: MUIDataTableProps
}

export default function DataTable(props: DataTableProps) {
    const classes = useStyles({});

    const theme = createMuiTheme({
        overrides: {
            // this override allows select menu dropdowns to appear outside of the table,
            // without getting clipped:
            MUIDataTable: {
                responsiveStacked: {
                    overflow: 'visible',
                    overflowX: 'visible'
                },
                // Mui-Datatables seems to have changed a CSS classname from responsiveStacked to responsiveBase;
                // keeping both in case they're both still in use somehow.
                responsiveBase: {
                    overflow: 'visible',
                    overflowX: 'visible'
                },
            },
            // the MuiPaper recursive '& *' will override the global * selector in App.css
            // which makes everything Quattrocento Sans; if we move away from Quattrocento Sans,
            // we can remove this override (which sets it back to the table default, which is Roboto:
            MuiPaper: {
                root: {
                    '& *': {
                        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                    }
                }
            },
            MUIDataTableToolbar: {
                titleText: {
                    fontSize: '1rem'
                }
            },
            // This one also overrides the "font-weight: normal" in App.css, and can be
            // removed if that is removed as well:
            MUIDataTableHeadCell: {
                data: {
                    fontWeight: 'bold',
                    fontSize: '.75rem'
                }
            },
            MuiTableRow: {
                root: {
                    '&:nth-child(odd)': {
                        backgroundColor: '#fff'
                    },
                    '&:nth-child(even)': {
                        backgroundColor: '#f7f7f7'
                    },
                    '&:hover': {
                        // this will ensure that a customRowRender will retain the hover highlighting:
                        backgroundColor: '#f1f1f1'
                    }
                }
            },
            MuiTableCell: {
                root: {
                    padding: '6px 16px 4px',
                    fontSize: '.75rem'
                }
            }
        }
    } as any);

    const { title, columns, data, options } = props.muiDataTableProps;

    const [ numberOfFilteredItems, setNumberOfFilteredItems ] = useState<number>(data.length);

    // MUIDataTable won't re-render itself when receiving new props; giving it a new key after
    // each state update will force a refresh.
    const [key, setKey] = useState(0);
    function forceTableRefresh() {
        setKey(key + 1);
    }

    const [rowsExpanded, setRowsExpanded] = useState<number[]>([]);
    const areAllRowsExpanded  = rowsExpanded.length === data.length;
    const areAllRowsCollapsed = rowsExpanded.length === 0;

    function expandAll() {
        const arrayOfJustIndexes = data.map((item, idx) => idx);
        setRowsExpanded(arrayOfJustIndexes);
        forceTableRefresh();
    }

    function collapseAll() {
        setRowsExpanded([]);
        forceTableRefresh();
    }

    useEffect(() => {
        forceTableRefresh();
    }, [props.muiDataTableProps.options?.rowsExpanded]);

    const defaultOptions: MUIDataTableProps['options'] = {
        viewColumns: false, // LEAVE THIS FALSE. Lots of tables need index position to do special things for certain cells, and this screws it up
        elevation: 0,
        filterType: 'multiselect',
        selectableRows: 'none',
        rowsExpanded: rowsExpanded,
        downloadOptions: {
            filterOptions: {
                useDisplayedRowsOnly: true,
                useDisplayedColumnsOnly: true
            }
        },
        onRowExpansionChange: (currentRowsExpanded, allRowsExpanded) => {
            const newRows = allRowsExpanded.map(item => item.dataIndex);
            setRowsExpanded(newRows);
            // notice how we do NOT forceTableRefresh here, since this callback function
            // seems to include it by default
        },
        customToolbarSelect: () => (
            // allow passing of a custom toolbar select:
            props.muiDataTableProps['options']?.customToolbarSelect ||
            // or override it with a blank one, thus getting rid of MuiDataTable's default, which has a Delete button:
            <span className={classes.blankToolbar}>
            </span>
        ),
        onFilterChange: (changedColumn, filterList, type, changedColumnIndex, displayData) => {
            // for some reason if you have any filters set, you get displayData (the newly filtered table items) and therefore:
            if (displayData) {
                setNumberOfFilteredItems(displayData.length);
            }
            // but if you click the "RESET" button in the filter menu, displayData is undefined, and therefore we revert back to the original count:
            else {
                setNumberOfFilteredItems(data.length);
            }
        }
    };

    const tableTitle = props.noFilterCount ? title : `${title} (${numberOfFilteredItems})`;

    return (
        <Paper
            elevation={5}
            classes={{
                root: classes.dataTablePaper
            }}
        >
            <div className={props.isDisabled ? classes.isDisabledDiv : ''}>
                <MuiThemeProvider theme={theme}>
                    <MUIDataTable
                        key={key}
                        title={tableTitle}
                        columns={columns}
                        data={data}
                        options={{ ...defaultOptions, ...options }}
                    />

                    {!props.noFooter && ( props.onRefresh || ( options && options.expandableRows ) ) &&
                        <DataTableFooter
                            onRefresh={props.onRefresh ? () => props.onRefresh?.() : undefined}
                            isRefreshing={props.isRefreshing}

                            expandableRows={options && options.expandableRows}
                            expandAll={() => expandAll()}
                            collapseAll={() => collapseAll()}
                            areAllRowsExpanded={areAllRowsExpanded}
                            areAllRowsCollapsed={areAllRowsCollapsed}
                        >
                            {props.customFooter}
                        </DataTableFooter>
                    }
                </MuiThemeProvider>
            </div>
        </Paper>
    );
}
