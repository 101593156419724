import React, { useContext, useState } from 'react';
import { CONSENT_AGENDA } from '../../../../../../entities/org/CONSENT_AGENDA';
import { Button, Typography } from '@material-ui/core';
import CreateNewMeeting from './CreateNewMeeting';
import { LucidocModal } from '../../../../../_shared/LucidocModal/LucidocModal';
import { COMMITTEE } from '../../../../../../entities/org/COMMITTEE';
import CreateMeetingMinutes from '../CreateMeetingMinutes';
import CreateMinutesTemplate from '../CreateMinutesTemplate';
import { buildDocURL, createSortableDate } from '../../../../../../_shared/utils/docManagerFunctions';
import UserBundleContext from '../../../../../../context/UserBundleContext';
import { DocumentRevisionStatuses } from '../../../../../../entities/org/DOCUMENTREVISION';
import { LoadingStatuses } from '../../../../../../utils/LoadingStatuses';
import { makeStyles } from '@material-ui/core/styles';

interface CommiteeAgendaMeetingsProps {
    isChairPersonOrSecretary: boolean;
    isCollaborator: boolean;
    committee: COMMITTEE | undefined;
    setLoadingStatus(status: LoadingStatuses): void;
    activeConsentAgendas: CONSENT_AGENDA[];
}

const useStyles = makeStyles({
    defaultActiveAgendaDescription: {
        marginTop: '1rem',
        marginBottom: '1rem',
    },
});

export const CommiteeAgendaMeetings: React.FC<CommiteeAgendaMeetingsProps> = ({
    isChairPersonOrSecretary,
    isCollaborator,
    committee,
    setLoadingStatus,
    activeConsentAgendas,
}) => {

    const userBundleContext = useContext(UserBundleContext);
    const [addMeetingOpen, setAddMeetingOpen] = useState(false);
    const [createMinutesConsentAgenda, setCreateMinutesConsentAgenda] = useState<CONSENT_AGENDA>();

    const [createDefaultMinutesTemplateOpen, setCreateDefaultMinutesTemplateOpen] = useState(false);

    const classes = useStyles();

    return (
        <div>
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: 'auto auto'
                }}
            >
                <div>
                    <Button
                        disabled={!(isChairPersonOrSecretary || isCollaborator)}
                        variant='outlined'
                        onClick={() => setAddMeetingOpen(prev => !prev)}
                    >
                        Add Meeting
                    </Button>
                    {
                        addMeetingOpen && committee &&
                        <CreateNewMeeting
                            onCreateMeeting={
                                () => {
                                    setAddMeetingOpen(false);
                                    setLoadingStatus(LoadingStatuses.LOADING);
                                }
                            }
                            onClose={() => setAddMeetingOpen(false)}
                            committeeId={committee.COMMITTEE_ID}
                        />
                    }
                    {
                        createMinutesConsentAgenda &&
                        committee?.MINUTES_DEFAULT_CONTENT_DOCID &&
                        <CreateMeetingMinutes
                            consentAgenda={createMinutesConsentAgenda}
                            onCreateMeetingMinutes={
                                () => {
                                    setCreateMinutesConsentAgenda(undefined);
                                    setLoadingStatus(LoadingStatuses.LOADING);
                                }
                            }
                            committeeId={committee.COMMITTEE_ID}
                            onClose={() => setCreateMinutesConsentAgenda(undefined)}
                        />
                    }
                    {
                        createMinutesConsentAgenda &&
                        !committee?.MINUTES_DEFAULT_CONTENT_DOCID &&
                        <LucidocModal
                            open={!!createMinutesConsentAgenda && !committee?.MINUTES_DEFAULT_CONTENT_DOCID}
                            onClose={() => setCreateMinutesConsentAgenda(undefined)}
                            title='Cannot Create Meeting Minutes'
                        >
                            <Typography>
                                This committee has no minutes template specified, and therefore no minutes can <br />
                                be created based on such a template.
                            </Typography>
                        </LucidocModal>
                    }
                    {
                        createDefaultMinutesTemplateOpen && committee &&
                        <CreateMinutesTemplate
                            onCreateMinutesTemplate={
                                () => {
                                    setCreateDefaultMinutesTemplateOpen(false);
                                    setLoadingStatus(LoadingStatuses.LOADING);
                                }
                            }
                            onClose={() => setCreateDefaultMinutesTemplateOpen(false)}
                            committee={committee}
                        />
                    }
                    <p className={classes.defaultActiveAgendaDescription}>
                        When a document revision or manual is submitted to a committee <br />
                        for approval, it is added to the pool of unscheduled items.
                        <br /><br />
                        Incoming document revisions and manuals can instead be <br />
                        automatically added to an 'active' consent agenda. If you select <br />
                        an active consent agenda, you must also select one of its agenda <br />
                        items under which incoming document revisions and manuals will <br />
                        be placed.
                    </p>
                    {
                        committee?.MINUTES_DEFAULT_CONTENT_DOCID &&
                        <a
                            target='_blank'
                            href={
                                buildDocURL(
                                    userBundleContext.organization!.PREFIX!,
                                    committee.MINUTES_DEFAULT_CONTENT_DOCID
                                )
                            }
                        >
                            Edit minutes template
                        </a>
                    }
                    {
                        committee?.MINUTES_DEFAULT_CONTENT_DOCID === null &&
                        <a
                            href='#'
                            onClick={
                                event => {
                                    event.stopPropagation();
                                    setCreateDefaultMinutesTemplateOpen(true);
                                }
                            }
                        >
                            Create minutes template
                        </a>
                    }
                </div>
                <div>
                    <Typography
                        variant='body1'
                    >
                        Active Consent Agendas
                    </Typography>
                    <br />
                    {
                        activeConsentAgendas.length === 0 &&
                        <Typography
                            variant='caption'
                        >
                            No active consent agendas
                        </Typography>
                    }
                    <table>
                        <tbody>
                            {
                                activeConsentAgendas.map((consentAgenda, index) => {
                                    const dateStr = createSortableDate(new Date(consentAgenda.MEETING_START_DATE), true);
                                    const docRev = consentAgenda.MINUTES_DOC && consentAgenda.MINUTES_DOC.REVISIONS ?
                                        consentAgenda.MINUTES_DOC.REVISIONS[0] : undefined;
                                    return <tr
                                        key={`active-consent-agenda-${index}-${dateStr}`}
                                    >
                                        <td>
                                            <a
                                                target='_blank'
                                                href={`/docmgr2/consent_agenda_management.pl?organizationid=${userBundleContext.organization?.ORGANIZATIONID}&consent_agenda_id=${consentAgenda.CONSENT_AGENDA_ID}`}
                                                style={{
                                                    fontFamily: 'Helvetica, monospace'
                                                }}
                                                onClick={
                                                    event => {
                                                        event.preventDefault();
                                                        window.open(
                                                            `/docmgr2/consent_agenda_management.pl?organizationid=${userBundleContext.organization?.ORGANIZATIONID}&consent_agenda_id=${consentAgenda.CONSENT_AGENDA_ID}`,
                                                            'consent_agenda_management' + consentAgenda.CONSENT_AGENDA_ID,
                                                            'dependent,resizable,scrollbars=yes'
                                                        );
                                                    }
                                                }
                                            >
                                                {dateStr}
                                            </a>
                                        </td>
                                        <td>
                                            {
                                                docRev &&
                                                [
                                                    DocumentRevisionStatuses.draft,
                                                    DocumentRevisionStatuses.invisible,
                                                    DocumentRevisionStatuses.prep,
                                                    DocumentRevisionStatuses.preprel,
                                                ].includes(docRev.DOCSTATUS_ID) &&
                                                (isChairPersonOrSecretary || isCollaborator) &&
                                                <a
                                                    target='_blank'
                                                    href={`/docmgr2/editdoc.pl?&wnd=1&ref=${userBundleContext.organization?.PREFIX}:${consentAgenda.MINUTES_DOCID}$${docRev.REVNO}`}
                                                    style={{
                                                        fontFamily: 'Helvetica, monospace'
                                                    }}
                                                >
                                                    (Edit minutes)
                                                </a>
                                            }
                                            {
                                                docRev &&
                                                [
                                                    DocumentRevisionStatuses.official,
                                                    DocumentRevisionStatuses.pending_committee_approval
                                                ].includes(docRev.DOCSTATUS_ID) &&
                                                <a
                                                    target='_blank'
                                                    href={`/cgi/doc-gw.pl?full=1&wnd=1&ref=${userBundleContext.organization?.PREFIX}:${consentAgenda.MINUTES_DOCID}`}
                                                    style={{
                                                        fontFamily: 'Helvetica, monospace'
                                                    }}
                                                >
                                                    (View minutes)
                                                </a>
                                            }
                                            {
                                                !consentAgenda.MINUTES_DOCID &&
                                                (isChairPersonOrSecretary || isCollaborator) &&
                                                <a
                                                    onClick={
                                                        () => {
                                                            if (isChairPersonOrSecretary || isCollaborator) {
                                                                setCreateMinutesConsentAgenda(consentAgenda);
                                                            }
                                                        }
                                                    }
                                                    href='#'
                                                    style={{
                                                        fontFamily: 'Helvetica, monospace',
                                                        color: 'blue'
                                                    }}
                                                >
                                                    (Create minutes)
                                                </a>
                                            }
                                        </td>
                                    </tr>;
                                })
                            }
                        </tbody>
                    </table>

                </div>
            </div>
            <hr />
        </div>
    );
};