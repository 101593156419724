import { Route, Switch } from 'react-router-dom';
import Homepage from './Homepage';
import WhatsNew from './components/WhatsNew';
import React from 'react';
import { Training } from '../Training/Training';
import Search from '../Search/Search';
import { Homepage2 } from '@/v2/pages/homepage/Homepage2';

export function HomepageRouter() {
    return (
        <Switch>
            <Route exact path='/homepage' component={Homepage}/>
            <Route exact path='/homepage/new' component={Homepage2}/>
            <Route exact path='/homepage/whats-new' component={WhatsNew}/>
            <Route exact path='/homepage/search' component={Search}/>
            <Route exact path='/homepage/training' component={Training}/>
        </Switch>
    );
}
