import {
    Column,
    Entity,
    PrimaryColumn,
} from 'typeorm';
import { SchemaEntityManager } from '../SchemaEntityManager';


export enum AckRequestStatuses {
    active     = 'active',
    cancelled  = 'cancelled',
    complete   = 'complete',
    superseded = 'superseded'
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function createAckRequestEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('ACK_REQUEST', { schema: schema })
    class ACK_REQUEST {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            name: 'ACK_REQUEST_ID'
        })
        ACK_REQUEST_ID!: number;

        @Column('number', {
            nullable: true,
            name: 'ACK_SCHEDULE_SESSION_ID'
        })
        ACK_SCHEDULE_SESSION_ID!: number | null;

        @Column('number', {
            nullable: false,
            name: 'USERID'
        })
        USERID!: number;

        @Column('number', {
            nullable: false,
            name: 'DOCREVID'
        })
        DOCREVID!: number;

        @Column('timestamp', {
            nullable: false,
            default: () => 'SYSDATE',
            name: 'START_DATE'
        })
        START_DATE!: Date;

        @Column('timestamp', {
            nullable: false,
            name: 'END_DATE'
        })
        END_DATE!: Date;

        @Column('varchar2', {
            nullable: false,
            length: 20,
            default: () => '\'active\'',
            name: 'STATUS'
        })
        STATUS!: string;

        @Column('timestamp', {
            nullable: true,
            name: 'FINAL_STATUS_DATE'
        })
        FINAL_STATUS_DATE!: Date | null;

        @Column('varchar2', {
            nullable: true,
            length: 600,
            name: 'FINAL_STATUS_COMMENT'
        })
        FINAL_STATUS_COMMENT!: string | null;

        @Column('number', {
            nullable: true,
            name: 'VIA_USERID'
        })
        VIA_USERID!: number | null;

        @Column('timestamp', {
            nullable: true,
            name: 'LAST_MODIFIED_DATE'
        })
        LAST_MODIFIED_DATE!: Date | null;

        @Column('number', {
            nullable: true,
            name: 'LAST_MODIFIED_BY_USERID'
        })
        LAST_MODIFIED_BY_USERID!: number | null;
    }

    return ACK_REQUEST;
}

export { createAckRequestEntity };
export type ACK_REQUEST = InstanceType<ReturnType<typeof createAckRequestEntity>>;
