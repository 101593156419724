import { buildDocURL } from '@/_shared/utils/docManagerFunctions';
import { ORGANIZATION } from '@/entities/master/ORGANIZATION';
import { DOCUMENTREVISION } from '@/entities/org/DOCUMENTREVISION';
import { DOCUMENTREVISION_LINK } from '@/entities/org/DOCUMENTREVISION_LINK';
import { HybridLink } from '@/ui/ui/hybridlink';
import { TableCell } from '@/ui/ui/table';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import { DocRevEntry } from './DocumentRevisionTable';
import { Row } from '@tanstack/react-table';
import { Skeleton } from '@/ui/ui/skeleton';

export const DocumentsTableSubRow: React.FC<{ row: Row<DocRevEntry>, contentFilter?: string }> = ({ row, contentFilter }) => {

    interface LinkData {
        links: { docRev: DOCUMENTREVISION, organization: ORGANIZATION }[]
        revision_note: string,
        docsThatIncludeThisDocumentAsALink: {
            DOCUMENTREVISION_LINK: DOCUMENTREVISION_LINK
            REFERENCED_ORG: ORGANIZATION
        }[]
    }

    const { data: linkData } = useSWR<LinkData>({
        url: '/api/_shared/tables/metadata-for-docrev',
        params: {
            docrevID: row.original.revId,
            orgID: row.original.orgId
        }
    });

    const [snippet, setSnippet] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (!contentFilter) return;
        axios.post('/api/homepage/search/get-snippet', {
            blobId: row.original.blobId,
            content: contentFilter,
            orgID: row.original.orgId
        }).then(res => setSnippet(res.data.snippet));
    }, [row, contentFilter]);

    const renderLinks = (data?: { docURL: string, title: string }[]) => {

        if (data === undefined) {
            return <>
                <Skeleton className='max-w-72' />
            </>;
        }

        if (data.length === 0) {
            return <p>No Links Found.</p>;
        }

        return data?.map(link => {
            return (
                <ul key={link.docURL}>
                    <li>
                        <HybridLink
                            href={link.docURL}
                            external={true}
                            className='text-primary-accent'
                        >
                            {/* Handle <br/> or <br> tags. */}
                            {link.title}
                        </HybridLink>
                    </li>
                </ul>
            );
        });
    };

    const renderRevNotes = () => {
        if (linkData === undefined) {
            return <Skeleton />;
        }

        if (linkData.revision_note === undefined) {
            return <p>No notes.</p>;
        }

        return format(linkData.revision_note);
    };

    const format = (str: string) => {
        return str.split(/<br\s*\/?>/g).map(line => {
            return <p key={line} className=''>{line}</p>;
        });
    };


    const getReferencedByData = () => {
        return linkData?.links.map(link => {
            return {
                docURL: buildDocURL(link.organization.PREFIX!, link.docRev.DOCID),
                title: link.docRev.TITLE ?? ''
            };
        }
        ) ?? undefined;
    };

    const getDocsWhichIncludeThisDocumentAsALinkData = () => {
        return linkData?.docsThatIncludeThisDocumentAsALink.map(link => {
            return {
                docURL: buildDocURL(link.REFERENCED_ORG.PREFIX!, link.DOCUMENTREVISION_LINK.DOCREV?.DOCID ?? 0),
                title: link.DOCUMENTREVISION_LINK.DOCREV?.TITLE ?? ''
            };
        }) ?? undefined;
    };

    // Data will get offset since we only have 7 columns, so we need to make it line up atleast a little bit.
    let linksColSpan = 6;
    if (contentFilter) {
        linksColSpan = 5;
    }


    const renderSnippet = () => {
        if (!snippet) {
            return <Skeleton className='min-w-72' />;
        }
        return <div className='max-w-72' dangerouslySetInnerHTML={{ __html: snippet }}></div>;
    };

    return (
        <>
            <TableCell />
            <TableCell colSpan={1} className='text-secondary align-top'>
                <p>Revision Note:</p>
                {/* It is common to use <br/> tags for spacing, so we handle those. */}
                <div className='max-w-[36rem]'>{renderRevNotes()}</div>
            </TableCell>
            <TableCell className='text-secondary align-top' colSpan={linksColSpan}>
                <p>Links referenced by this document:</p>
                {renderLinks(getReferencedByData())}
                <p>Documents which include this document as a link:</p>
                {renderLinks(getDocsWhichIncludeThisDocumentAsALinkData())}
            </TableCell>
            {contentFilter && <TableCell className='text-secondary align-top'>
                <p>Snippet:</p>
                {renderSnippet()}
            </TableCell>}
        </>
    );
};