import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import OrgSelectMenu from '../OrgSelectMenu/OrgSelectMenu';
import axios from 'axios';
import { PUBLICATION } from '../../../entities/multiorg/PUBLICATION';
import { DOCUMENTREVISION } from '../../../entities/org/DOCUMENTREVISION';

interface DocRevWithPub { // these are different schemas; we can't SQL join them, so we stick them together here
    docrev: DOCUMENTREVISION
    publication: PUBLICATION
}

interface PublicationReleaseStatusHash {
    [key: string]: 'sending'
                 | 'released'
                 | 'unreleased'
                 | 'error'
}

export default function ReleaseMultiOrgDocuments() {
    const [ publisherOrgID, setPublisherOrgID ] = useState<number | undefined>(undefined);
    const [ publishingSchema, setPublishingSchema ] = useState<string | undefined>(undefined);
    const [ subscriberOrgID, setSubscriberOrgID ] = useState<number | undefined>(undefined);
    const [ departments, setDepartments ] = useState<string[]>([]);
    const [ selectedDepartment, setSelectedDepartment ] = useState<string>('');
    const [ docrevsWithPublications, setDocrevsWithPublications ] = useState<DocRevWithPub[]>([]);
    const [ publicationReleaseStatus, setPublicationReleaseStatus ] = useState<PublicationReleaseStatusHash>({});
    
    function clearEverything() {
        setPublisherOrgID(undefined);
        setPublishingSchema(undefined);
        setSubscriberOrgID(undefined);
        setDepartments([]);
        setSelectedDepartment('');
        setDocrevsWithPublications([]);
        setPublicationReleaseStatus({});
    }
    
    async function getDocs() {
        setDocrevsWithPublications([]);
        
        const res = await axios.post('/api/internal-support/release-multi-org-documents/get-docs', {
            publisherOrgID,
            subscriberOrgID
        });
        
        if (res.data.error) {
            alert(res.data.error);
            return;
        }
        
        if (res) {
            setDepartments(res.data.departments || []);
            setDocrevsWithPublications(res.data.docrevsWithPublications || []);
            setPublishingSchema(res.data.publishingSchema);
            
            const newPublicationReleaseStatus: PublicationReleaseStatusHash = {};

            res.data.docrevsWithPublications.forEach((docRevWithPub: DocRevWithPub) => {
                if (docRevWithPub.publication.SUBSCRIPTIONS && docRevWithPub.publication.SUBSCRIPTIONS[0]) {
                    // the back end makes sure SUBSCRIPTIONS[0] is the subscription for this specific org, so we can
                    // just check if it's there at all and skip any === check
                    newPublicationReleaseStatus[docRevWithPub.publication.PUBLICATIONID] = 'released';
                }
                else {
                    newPublicationReleaseStatus[docRevWithPub.publication.PUBLICATIONID] = 'unreleased';
                }
            });
            
            setPublicationReleaseStatus(newPublicationReleaseStatus);
        }
    }
    
    function releaseDocs(publicationIDs: number[]) {
        const newPublicationReleaseStatus = Object.assign({}, publicationReleaseStatus);
        
        publicationIDs.forEach(pubID => {
            newPublicationReleaseStatus[pubID] = 'sending';
        });
        
        setPublicationReleaseStatus(newPublicationReleaseStatus);
        
        axios
            .post('/api/internal-support/release-multi-org-documents/release-docs', {
                publishingSchema,
                subscriberOrgID,
                publicationIDs
            })
            .then((res) => {
                const newPublicationReleaseStatus: PublicationReleaseStatusHash = res.data.publicationReleaseStatus;
                
                setPublicationReleaseStatus({
                    ...publicationReleaseStatus,
                    ...newPublicationReleaseStatus
                });
            })
            .catch(() => {
                // figure this out when this becomes a customer-facing feature
            });
    }
    
    const docs = docrevsWithPublications.length ? (
        <table>
            <thead>
                <tr>
                    <th>PublicationID</th>
                    <th>Created Date</th>
                    <th>Publisher Org ID</th>
                    <th>DocID</th>
                    <th>DocownerID</th>
                    <th>TemplateID</th>
                    <th>Department Name</th>
                    <th>Doc Title</th>
                    <th>Release!!!</th>
                </tr>
            </thead>
            <tbody>
                {docrevsWithPublications
                    .filter(docRevWithPub => !selectedDepartment || selectedDepartment === docRevWithPub.publication.DEPARTMENT_NAME)
                    .map((docWithPub, idx) => (
                        <tr key={idx}>
                            <td>{docWithPub.publication.PUBLICATIONID}</td>
                            <td>{docWithPub.publication.CREATED_DATE}</td>
                            <td>{docWithPub.publication.PUBLISHER_ORGID}</td>
                            <td>{docWithPub.publication.DOCID}</td>
                            <td>{docWithPub.publication.DOCOWNER_ID}</td>
                            <td>{docWithPub.publication.TEMPLATEID}</td>
                            <td>{docWithPub.publication.DEPARTMENT_NAME}</td>
                            <td>{docWithPub.docrev.TITLE}</td>
                            <td>
                                {publicationReleaseStatus[docWithPub.publication.PUBLICATIONID] === 'unreleased'
                                    ? (
                                        <button
                                            onClick={() => releaseDocs(
                                                [docWithPub.publication.PUBLICATIONID]
                                            )}
                                        >
                                            Release me!
                                        </button>
                                    )
                                    : publicationReleaseStatus[docWithPub.publication.PUBLICATIONID]
                                }
                            </td>
                        </tr>
                ))}
            </tbody>
        </table>
    ) : null;
    
    
    return (
        <div>
            <p>
                <Link to={'/internal-support'}>
                    Back
                </Link>
            </p>
            
            <h3>Release Multi-Org Documents</h3>

            <div style={{ marginBottom: '1rem' }}>
                <OrgSelectMenu
                    text={'Select Publisher Org'}
                    selectedOrgID={publisherOrgID}
                    setSelectedOrgID={(orgID) => {
                        clearEverything();
                        setPublisherOrgID(orgID);
                    }}
                    getPublishers={true}
                />
            </div>
            
            {publisherOrgID &&
                <>
                    <div>
                        <OrgSelectMenu
                            text={'Select Subscriber Org'}
                            selectedOrgID={subscriberOrgID}
                            setSelectedOrgID={(orgID) => setSubscriberOrgID(orgID)}
                            getSubscribersFromThisPublisherOrgID={publisherOrgID}
                        />
                        <p style={{ color: 'darkgrey', fontSize: '12px' }}>
                            If you don't see an org on this list that should be there, ask the devs to change
                            its 'SUBSCRIBER_SCHEMA' value in the MASTER.ORGANIZATION table.
                        </p>
                    </div>
                </>
            }
            
            {publisherOrgID && subscriberOrgID &&
                <>
                    <div style={{ marginBottom: '1rem' }}>
                        <button onClick={() => getDocs()}>
                            getDocs
                        </button>
                    </div>
                    {docrevsWithPublications.length > 0 &&
                        <p>Found {docrevsWithPublications.length} documents.</p>
                    }
                    
                    {departments.length > 0 &&
                        <div style={{ marginBottom: '1rem', display: 'flex', justifyContent: 'space-between' }}>
                            <div>
                                <span>Filter by department:  </span>
                                <select onChange={(e) => setSelectedDepartment(e.currentTarget.value)}>
                                    <option> </option>
                                    {departments.map((dep, idx) => (
                                        <option
                                            key={idx}
                                            value={dep}
                                        >
                                            {dep}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                {selectedDepartment &&
                                    <button
                                        onClick={() => releaseDocs(
                                            docrevsWithPublications
                                                .filter(docRevWithPub => {
                                                    return docRevWithPub.publication.DEPARTMENT_NAME === selectedDepartment;
                                                })
                                                .filter(docRevWithPub => {
                                                    return publicationReleaseStatus[docRevWithPub.publication.PUBLICATIONID] === 'unreleased';
                                                })
                                                .map(docRevWithPub => {
                                                    return docRevWithPub.publication.PUBLICATIONID;
                                                })
                                        )}
                                    >
                                        Send all in this department
                                    </button>
                                }
                            </div>
                        </div>
                    }
        
                    {docs}
                </>
            }
        </div>
    );
}
