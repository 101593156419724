import React, { useContext, useEffect, useState } from 'react';
import DataTable from '../../../../_shared/DataTable/DataTable';
import axios from 'axios';
import { MUIDataTableColumn, MUIDataTableProps } from 'mui-datatables';
import { TextField } from '@material-ui/core';
import { DOCUMENTREVISION } from '../../../../../entities/org/DOCUMENTREVISION';
import { Button } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { LoadingIndicator } from '../../../../_shared/LoadingIndicator';
import { LoadingStatuses } from '../../../../../utils/LoadingStatuses';
import UserBundleContext from '../../../../../context/UserBundleContext';
import { buildDocURL, createSortableDate } from '../../../../../_shared/utils/docManagerFunctions';
import { DatePicker } from '../../../../_shared/DatePicker/DatePicker';
import H3WithHelpTip from '../../../../_shared/Forms/H3WithHelpTip';
import { setTitleInAdmin } from '../../../../../utils/setTitleInAdmin';
import { TemporaryFavoritesStar } from '../../../../_shared/TemporaryFavoritesStar/TemporaryFavoritesStar';
import { mobileViewBreakpointSmall } from '../../../../Header/HeaderSharedStyles';
import { makeStyles } from '@material-ui/core/styles';
import { redirectToHomepage } from '../../../../../utils/savebarUtilities';
import SaveBar from '../../../../_shared/SaveBar/SaveBar';
import setWindowTitle from '../../../../../utils/setWindowTitle';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { constructFullName } from '@/components/UserPicker/utils/constructFullName';

const searchBarStyles = {
    container: {
        marginTop: '1rem',
    },
    searchInput: {
        fontSize: '.9rem',
        width: 240,
        backgroundColor: 'white',
        paddingRight: '1.5rem',
        [`@media (max-width:${mobileViewBreakpointSmall}px)`]: {
            width: 160,
        },
    },
    inputContainer: {
        position: 'relative' as CSSProperties['position'],
        zIndex: 101,
        padding: '0 1rem 0 1rem',
    },
    inputRow: {
        marginTop: '1rem',
    },
};

const useStyles = makeStyles({
    ...searchBarStyles
});

export function OfficialDocsInDateRange() {
    const classes = useStyles();

    const [loadingStatus, setLoadingStatus] = useState(LoadingStatuses.MOUNTING);
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [contentSearchText, setContentSearchText] = useState<string>('');
    const [titleSearchText, setTitleSearchText] = useState<string>('');
    const [tableData, setTableData] = useState<MUIDataTableProps['data']>([]);

    const context = useContext(UserBundleContext);

    useEffect(() => {
        const newTitle = 'Date Range Report';
        setWindowTitle(newTitle);
        setTitleInAdmin(newTitle);
    }, []);

    async function getOfficialDocsInDateRange() {
        if (!startDate || !endDate) return; // button disabling should take care of this anyway though

        setLoadingStatus(LoadingStatuses.LOADING);

        const res = await axios.get('/api/administration/documents/official-docs-in-date-range', {
            params: {
                startDate,
                endDate,
                titleSearchText,
                contentSearchText,
            }
        });

        const formattedTableData = ((res.data.officialDocrevsInDateRange || []) as DOCUMENTREVISION[]).map(docrev => {
            const returnObj = {
                Title: docrev.TITLE,
                DocID: docrev.DOCID,
                RevNo: docrev.REVNO,
                DocType: docrev.DOCTYPE?.DESCRIPTION,
                Owner: docrev.DOCUMENT.DOCOWNER ? constructFullName(docrev.DOCUMENT.DOCOWNER, 'lastFirstMiddle') : '',
                [context.organization?.ORGUNITNAME || 'Department']: docrev.DOCUMENT?.INFORMATION_HIERARCHY?.TITLE,
            };

            if (context.organization?.GLOBAL_IDENTIFIER_NAME
                && context.organization?.GLOBAL_IDENTIFIER_AVAILABILITY !== 'none'
            ) {
                returnObj[context.organization.GLOBAL_IDENTIFIER_NAME] = docrev.GLOBAL_IDENTIFIER?.VALUE;
            }

            returnObj['Owner at Publication'] = docrev.OWNER_NAME_ON_OFFICIAL;

            returnObj.Officialized = docrev.OFFICIALDT
                ? createSortableDate(new Date(docrev.OFFICIALDT))
                : null;

            returnObj.Obsoleted = docrev.OBSOLETEDT
                ? createSortableDate(new Date(docrev.OBSOLETEDT))
                : null;

            return returnObj;
        });

        setTableData(formattedTableData);
        setLoadingStatus(LoadingStatuses.READY);
    }

    return (
        <div className={classes.container}>
            <div className={classes.inputContainer}>
                <div style={{ float: 'right' }}> {/* must be inside to get the zIndex */}
                    <TemporaryFavoritesStar targetPathName={'admin/admin.pl?repname=date_range_report'} />
                </div>

                <H3WithHelpTip
                    helpText={'Enter beginning and ending dates to find all documents that were Official on any date within the specified range'}
                    text={'Report of Official documents within a date range'}
                />

                <div className={classes.inputRow}>
                    <span style={{ marginRight: '1rem' }}>
                        <DatePicker
                            label={'Start Date'}
                            value={startDate}
                            onChange={newDateString => setStartDate(newDateString)}
                        />
                    </span>

                    <span style={{ marginRight: '1rem' }}>
                        <DatePicker
                            label={'End Date'}
                            value={endDate}
                            onChange={newDateString => setEndDate(newDateString)}
                        />
                    </span>

                    <TextField
                        name={'search_input'}
                        label={'Title'}
                        placeholder={'Search Text...'}
                        value={titleSearchText}
                        variant={'outlined'}
                        size={'small'}
                        onChange={e => setTitleSearchText(e.currentTarget.value)}
                        style={{ marginRight: '1rem' }}
                        InputProps={{
                            className: classes.searchInput,
                            autoComplete: 'off',
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                    <TextField
                        name={'search_input'}
                        label={'Content'}
                        placeholder={'Search Text...'}
                        value={contentSearchText}
                        variant={'outlined'}
                        size={'small'}
                        onChange={e => setContentSearchText(e.currentTarget.value)}
                        style={{ marginRight: '1rem' }}
                        InputProps={{
                            className: classes.searchInput,
                            autoComplete: 'off',
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                    <Button
                        startIcon={<Search />}
                        variant={'contained'}
                        disabled={!startDate || !endDate}
                        onClick={() => getOfficialDocsInDateRange()}
                    >
                        Find Documents
                    </Button>
                </div>
            </div>

            {loadingStatus === LoadingStatuses.LOADING &&
                <LoadingIndicator />
            }

            {loadingStatus === LoadingStatuses.READY &&
                <DataTable
                    muiDataTableProps={{
                        title: 'Official Document Revisions in Date Range',
                        data: tableData,
                        columns: tableData[0] && Object.keys(tableData[0]).map(key => {
                            const returnObj: MUIDataTableColumn = {
                                name: key
                            };

                            if (key === 'Title') {
                                returnObj.options = {
                                    customBodyRender: (value, tableMeta) => {
                                        const docURL = context.organization?.PREFIX
                                            ? buildDocURL(
                                                context.organization.PREFIX,
                                                tableMeta.rowData[1],
                                                parseInt(tableMeta.rowData[2])
                                            ) : '';

                                        return (
                                            <a href={docURL} target={'_blank'} rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                                {value}
                                            </a>
                                        );
                                    }
                                };
                            }

                            return returnObj;
                        })
                    }}
                />
            }
            <SaveBar onClose={() => redirectToHomepage()} />
        </div>
    );
}
