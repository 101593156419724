import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { COMMITTEE_ROUTING_LOG_DETAIL } from '../../../entities/org/COMMITTEE_ROUTING_LOG_DETAIL';
import { COMMITTEE } from '../../../entities/org/COMMITTEE';
import DataTable from '../../_shared/DataTable/DataTable';
import { MUIDataTableColumn, MUIDataTableProps } from 'mui-datatables';
import { buildDocURL, buildManualURL, createSortableDate } from '../../../_shared/utils/docManagerFunctions';
import { LoadingIndicator } from '../../_shared/LoadingIndicator';
import { LoadingStatuses } from '../../../utils/LoadingStatuses';
import UserBundleContext from '../../../context/UserBundleContext';
import { USERGROUP } from '../../../entities/org/USERGROUP';
import { PERSON } from '../../../entities/master/PERSON';
import { setTitleInAdmin } from '../../../utils/setTitleInAdmin';
import { TemporaryFavoritesStar } from '../../_shared/TemporaryFavoritesStar/TemporaryFavoritesStar';
import { redirectToHomepage } from '../../../utils/savebarUtilities';
import SaveBar from '../../_shared/SaveBar/SaveBar';
import setWindowTitle from '../../../utils/setWindowTitle';
import { constructFullName } from '@/components/UserPicker/utils/constructFullName';

export default function RoutingHistory() {
    const context = useContext(UserBundleContext);

    const [loadingStatus, setLoadingStatus] = useState(LoadingStatuses.MOUNTING);

    const [docRevTableData, setDocRevTableData] = useState<MUIDataTableProps['data']>([]);
    const [manualTableData, setManualTableData] = useState<MUIDataTableProps['data']>([]);

    useEffect(() => {
        const newTitle = 'Routing History';
        setWindowTitle(newTitle);
        setTitleInAdmin(newTitle);
        getRoutingHistory().then();
    }, []);

    async function getRoutingHistory() {
        const res = await axios.get('/api/approval-routing/get-routing-history/');

        setDocRevTableData(formatDocRevTableData(res.data.docRevRoutingHistory, res.data.usersHash, res.data.groupsHash, res.data.committeesHash));
        setManualTableData(formatManualTableData(res.data.manualRoutingHistory, res.data.usersHash, res.data.groupsHash, res.data.committeesHash));

        setLoadingStatus(LoadingStatuses.READY);
    }

    function formatDocRevTableData(
        routingHistory: COMMITTEE_ROUTING_LOG_DETAIL[],
        usersHash: { [key: string]: PERSON },
        groupsHash: { [key: string]: USERGROUP },
        committeesHash: { [key: string]: COMMITTEE }
    ) {
        return routingHistory.map(crld => {
            const router = crld.COMMITTEE_ROUTING_LOG?.ROUTER;
            const impersonator = crld.COMMITTEE_ROUTING_LOG?.IMPERSONATOR;
            const owner = crld.DOCUMENTREVISION?.DOCUMENT?.DOCOWNER;

            const route = produceRouteAsReadableString(
                crld.APPROVAL_ROUTE_TEMPLATE,
                crld.SIGNERASSIGNMENT,
                crld.ROUTING_CONFIGURATION,
                usersHash,
                groupsHash,
                committeesHash);

            let returnObj: { [key: string]: string | number | null | undefined } = {
                DocRevID: crld.DOCUMENTREVISION?.DOCREVID,
                DocID: crld.DOCUMENTREVISION?.DOCID,
                RevNo: crld.DOCUMENTREVISION?.REVNO,
                Title: crld.DOCUMENTREVISION?.TITLE,
                DocType: crld.DOCUMENTREVISION?.DOCTYPE?.DESCRIPTION,
                Department: crld.DOCUMENTREVISION?.DOCUMENT?.INFORMATION_HIERARCHY?.TITLE,
                Owner: owner ? constructFullName(owner, 'lastFirstMiddle') : '',
            };

            if (context.organization?.GLOBAL_IDENTIFIER_NAME
                && context.organization?.GLOBAL_IDENTIFIER_AVAILABILITY !== 'none'
            ) {
                returnObj[context.organization.GLOBAL_IDENTIFIER_NAME] = crld.DOCUMENTREVISION?.GLOBAL_IDENTIFIER?.VALUE || null;
            }

            returnObj = {
                ...returnObj,

                Router: router ? constructFullName(router, 'lastFirstMiddle') : '',
                Impersonator: impersonator
                    ? constructFullName(impersonator, 'lastFirstMiddle')
                    : '',
                'Date/Time': crld.COMMITTEE_ROUTING_LOG?.TIMESTAMP
                    ? createSortableDate(new Date(crld.COMMITTEE_ROUTING_LOG?.TIMESTAMP))
                    : '',
                Route: route
            };

            return returnObj;
        });
    }

    function formatManualTableData(
        routingHistory: COMMITTEE_ROUTING_LOG_DETAIL[],
        usersHash: { [key: string]: PERSON },
        groupsHash: { [key: string]: USERGROUP },
        committeesHash: { [key: string]: COMMITTEE }
    ) {
        return routingHistory.map(crld => {
            const router = crld.COMMITTEE_ROUTING_LOG?.ROUTER;
            const impersonator = crld.COMMITTEE_ROUTING_LOG?.IMPERSONATOR;
            const owner = crld.MANUAL?.USER;

            const route = produceRouteAsReadableString(
                crld.APPROVAL_ROUTE_TEMPLATE,
                crld.SIGNERASSIGNMENT,
                crld.ROUTING_CONFIGURATION,
                usersHash,
                groupsHash,
                committeesHash);

            return {
                ManualID: crld.MANUALID,
                Title: crld.MANUAL?.TITLE,
                Owner: owner ? constructFullName(owner, 'lastFirstMiddle') : '',
                Router: router ? constructFullName(router, 'lastFirstMiddle') : '',
                Impersonator: impersonator
                    ? constructFullName(impersonator, 'lastFirstMiddle')
                    : '',
                'Date/Time': crld.COMMITTEE_ROUTING_LOG?.TIMESTAMP
                    ? createSortableDate(new Date(crld.COMMITTEE_ROUTING_LOG?.TIMESTAMP))
                    : '',
                Route: route
            };
        });
    }

    function produceRouteAsReadableString(
        approvalRouteTemplate: string | null,
        signerAssignment: string | null,
        routingConfiguration: string | null,
        usersHash: { [key: string]: PERSON },
        groupsHash: { [key: string]: USERGROUP },
        committeesHash: { [key: string]: COMMITTEE }
    ) {
        const approvalRouteArray = approvalRouteTemplate
            ? JSON.parse(approvalRouteTemplate)
            : ['committees']; // legacy for when routing only cared about committees

        return approvalRouteArray.reduce((acc: string[], phase: string) => {
            if (phase === 'signers') {
                const signersString = produceSignerAssignmentAsReadableString(signerAssignment, usersHash, groupsHash);
                if (signersString) {
                    acc.push(signersString);
                }
            }
            else if (phase === 'committees') {
                const committeesString = produceRoutingConfigurationAsReadableString(routingConfiguration, committeesHash);
                if (committeesString) {
                    acc.push(committeesString);
                }
            }
            return acc;
        }, []).join('\n          ---\n');
    }

    function produceSignerAssignmentAsReadableString(signerAssignment: string | null, usersHash: { [key: string]: PERSON }, groupsHash: { [key: string]: USERGROUP }) {
        if (!signerAssignment) return '';

        const routeArray = JSON.parse(signerAssignment);

        return routeArray.reduce((acc: string[], signerTypeWithID: string) => {
            if (signerTypeWithID.includes('userid_')) {
                const userID = signerTypeWithID.replace('userid_', '');
                const person = usersHash[userID];
                acc.push(constructFullName(person, 'lastFirstMiddle'));
            }
            else if (signerTypeWithID.includes('usergroupid_')) {
                const userGroupID = signerTypeWithID.replace('usergroupid_', '');
                acc.push(groupsHash[userGroupID].NAME);
            }
            return acc;
        }, []).join('\n');
    }

    function produceRoutingConfigurationAsReadableString(routingConfiguration: string | null, committeesHash: { [key: string]: COMMITTEE }) {
        if (!routingConfiguration) return '';

        const routeArray = JSON.parse(routingConfiguration);

        return routeArray.reduce((acc: string[], innerArray: string[], idx: number) => {
            const innerString = innerArray.map(committeeID => committeesHash[committeeID].NAME).join(', ');
            acc.push(`Group ${idx + 1}: ${innerString}`);
            return acc;
        }, []).join('\n');
    }

    if (loadingStatus !== LoadingStatuses.READY) {
        return <LoadingIndicator />;
    }

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <TemporaryFavoritesStar targetPathName={'admin/admin.pl?repname=routing_history'} />
            </div>

            <DataTable
                muiDataTableProps={{
                    title: 'Document Routing History',
                    columns: docRevTableData[0] && Object.keys(docRevTableData[0]).map(key => {
                        const returnObj: MUIDataTableColumn = {
                            name: key,
                        };

                        if (key === 'DocRevID'
                            || key === 'DocID'
                            || key === 'RevNo'
                        ) {
                            returnObj.options = {
                                display: 'false',
                                filter: false
                            };
                        }
                        if (key === 'Title') {
                            returnObj.options = {
                                customBodyRender: (value, tableMeta) => {
                                    const docURL = context.organization?.PREFIX
                                        ? buildDocURL(
                                            context.organization.PREFIX,
                                            tableMeta.rowData[1],
                                            parseInt(tableMeta.rowData[2])
                                        ) : '';

                                    return (
                                        <a href={docURL} target={'_blank'} rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                            {value}
                                        </a>
                                    );
                                }
                            };
                        }
                        if (key === 'Route') {
                            returnObj.options = {
                                customBodyRender: (value) => {
                                    return (
                                        // to get the Group 1 vs Group 2 on their own lines
                                        <span style={{ whiteSpace: 'pre' }}>{value}</span>
                                    );
                                }
                            };
                        }

                        return returnObj;
                    }),
                    data: docRevTableData
                }}
            />

            <DataTable
                muiDataTableProps={{
                    title: 'Manual Routing History',
                    columns: manualTableData[0] && Object.keys(manualTableData[0]).map(key => {
                        const returnObj: MUIDataTableColumn = {
                            name: key,
                        };

                        if (key === 'ManualID') {
                            returnObj.options = {
                                display: 'false',
                                filter: false
                            };
                        }

                        if (key === 'Title') {
                            returnObj.options = {
                                customBodyRender: (value, tableMeta) => {
                                    const manualID = tableMeta.rowData[0];
                                    const manualURL = buildManualURL(manualID);

                                    return (
                                        <a href={manualURL} target={'_blank'} rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                            {value}
                                        </a>
                                    );
                                }
                            };
                        }

                        return returnObj;
                    }),
                    data: manualTableData
                }}
            />

            <SaveBar onClose={() => redirectToHomepage()} />
        </>
    );
}
