import {
    Entity,
    PrimaryColumn
} from 'typeorm';
import { SchemaEntityManager } from '../SchemaEntityManager';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function createRepresentativeContactEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('REPRESENTATIVE_CONTACT', { schema: schema })
    class REPRESENTATIVE_CONTACT {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            name: 'ORGANIZATIONID'
        })
        ORGANIZATIONID!: number;

        @PrimaryColumn('number', {
            nullable: false,
            name: 'USERID'
        })
        USERID!: number;
    }

    return REPRESENTATIVE_CONTACT;
}

export { createRepresentativeContactEntity };
export type REPRESENTATIVE_CONTACT = InstanceType<ReturnType<typeof createRepresentativeContactEntity>>;
