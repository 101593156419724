import {
    Column,
    Entity,
    JoinColumn,
    ManyToMany,
    ManyToOne,
    OneToMany,
    OneToOne,
    PrimaryColumn,
} from 'typeorm';
import { COMMITTEE_APPROVAL_SESSION,  } from './COMMITTEE_APPROVAL_SESSION';
import { DOCUMENT_TYPE_PL,  } from './DOCUMENT_TYPE_PL';
import { DOCUMENT,  } from './DOCUMENT';
import { INFORMATION_INDEX,  } from './INFORMATION_INDEX';
import { ACL,  } from './ACL';
import { nullableStringToBooleanTransformer, numberToBooleanTransformer } from '../_helperFunctions/transformers';
import { ROUTING_CONFIGURATION,  } from './ROUTING_CONFIGURATION';
import { PERSON,  } from '../master/PERSON';
import { APPROVAL_SESSION,  } from './APPROVAL_SESSION';
import { SIGNERASSIGNMENT,  } from './SIGNERASSIGNMENT';
import { APPROVAL_ROUTE_TEMPLATE,  } from './APPROVAL_ROUTE_TEMPLATE';
import { COMMITTEE_ROUTING_LOG_DETAIL,  } from './COMMITTEE_ROUTING_LOG_DETAIL';
import { SIGNATURE_SESSION,  } from './SIGNATURE_SESSION';
import { PUBLISHER,  } from './PUBLISHER';
import { ALL_STORE,  } from './ALL_STORE';
import { ACK_SCHEDULE,  } from './ACK_SCHEDULE';
import { SchemaEntityManager } from '../SchemaEntityManager';

export enum InformationHierarchyStatuses {
    active = 'active',
    archived = 'archived',
}

function createInformationHierarchyEntity(schema: string, manager: SchemaEntityManager) {

    @Entity('INFORMATION_HIERARCHY', { schema: schema })
    class INFORMATION_HIERARCHY {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'INFORMATION_HIERARCHY_ID'
        })
        INFORMATION_HIERARCHY_ID!: number;

        @Column('varchar2', {
            nullable: true,
            length: 20,
            name: 'CODE'
        })
        CODE!: string | null;

        @Column('varchar2', {
            nullable: false,
            length: 90,
            name: 'TITLE'
        })
        TITLE!: string;

        @Column('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'RECURSION_LEVEL_ID'
        })
        RECURSION_LEVEL_ID!: number;

        @Column('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'USERID'
        })
        USERID!: number;

        @ManyToOne(
            () => manager.getPersonEntity(schema), 
            (person) => person.INFORMATION_HIERARCHIES
        )
        @JoinColumn({
            name: 'USERID'
        })
        USER!: PERSON | null;


        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'PARENTID'
        })
        PARENTID!: number | null;

        @Column('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'PUBLISHER_ID'
        })
        PUBLISHER_ID!: number;

        @Column('number', {
            nullable: true,
            precision: 1,
            scale: 0,
            name: 'PRINTABLE'
        })
        PRINTABLE!: number | null;

        @Column('number', {
            nullable: true,
            precision: 3,
            scale: 0,
            name: 'ACCESSLEVEL'
        })
        ACCESSLEVEL!: number | null;

        @Column('char', {
            nullable: true,
            name: 'VIEWCONTROL',
            transformer: nullableStringToBooleanTransformer
        })
        VIEWCONTROL!: boolean;

        @Column('char', {
            nullable: true,
            name: 'SHOWAPPLICABLE'
        })
        SHOWAPPLICABLE!: string | null;

        @Column('char', {
            nullable: true,
            name: 'INHERITCOLLABORATION',
            transformer: nullableStringToBooleanTransformer
        })
        INHERITCOLLABORATION!: boolean;

        @Column('char', {
            nullable: true,
            name: 'INHERITVISIBILITY',
            transformer: nullableStringToBooleanTransformer
        })
        INHERITVISIBILITY!: boolean;

        @Column('varchar2', {
            nullable: true,
            length: 4000,
            name: 'PROPERTYHASH'
        })
        PROPERTYHASH!: string | null;

        // This is another column that only exists in certain schemas and not others.
        // It is known to be missing in AMC (AH Portland), perhaps others.
        // @Column('number', {
        //     nullable: true,
        //     precision: 10,
        //     scale: 0,
        //     name: 'ORGCHARTID'
        // })
        // ORGCHARTID!: number | null;

        // Same as above.
        // It is known to be missing in AMC (AH Portland), perhaps others.
        // @Column('char', {
        //     nullable: true,
        //     name: 'ISORGOWNED'
        // })
        // ISORGOWNED!: string | null;

        @Column('timestamp', {
            nullable: true,
            default: () => 'SYSDATE',
            name: 'LAST_EDITED_DATE'
        })
        LAST_EDITED_DATE!: Date | null;

        @Column('number', {
            nullable: true,
            name: 'IMAGE_BLOBID'
        })
        IMAGE_BLOBID!: number | null;

        @OneToOne(
            () => manager.getAllStoreEntity(schema),
            (allStore) => allStore.DEPARTMENT
        )
        @JoinColumn({
            name: 'IMAGE_BLOBID'
        })
        IMAGE!: ALL_STORE | null; // actual image is at INFORMATION_HIERARCHY.IMAGE.GRAPHIC (since ALL_STORE can have INSTANCE (a doc) or a GRAPHIC)

        @Column('varchar2', {
            nullable: false,
            length: 32,
            default: () => '\'active\'',
            name: 'STATUS'
        })
        STATUS!: string;

        @Column('number', {
            nullable: false,
            precision: 1,
            scale: 0,
            default: () => '0',
            transformer: numberToBooleanTransformer,
            name: 'ORDEREDREVIEW'
        })
        ORDEREDREVIEW!: number | null;

        @Column('number', {
            nullable: false,
            precision: 1,
            scale: 0,
            default: () => '0',
            transformer: numberToBooleanTransformer,
            name: 'DELAYED_RATIFICATION'
        })
        DELAYED_RATIFICATION!: number | null;

        @Column('number', {
            nullable: true,
            precision: 1,
            scale: 0,
            transformer: numberToBooleanTransformer,
            name: 'VISIBLE_ON_FACILITY_HOMEPAGES'
        })
        VISIBLE_ON_FACILITY_HOMEPAGES!: boolean | null;

        @OneToMany(
            () => manager.getApprovalRouteTemplateEntity(schema),
            (approvalRouteTemplate) => approvalRouteTemplate.MANUAL
        )
        APPROVAL_ROUTE_TEMPLATES!: APPROVAL_ROUTE_TEMPLATE[] | null;

        @OneToMany(
            () => manager.getApprovalSessionEntity(schema),
            (approvalSession) => approvalSession.MANUAL
        )
        APPROVAL_SESSIONS!: APPROVAL_SESSION[] | null;

        @OneToMany(
            () => manager.getSignatureSessionEntity(schema),
            (signatureSession) => signatureSession.MANUAL
        )
        SIGNATURE_SESSIONS!: SIGNATURE_SESSION[] | null;

        @OneToMany(
            () => manager.getCommitteeApprovalSessionEntity(schema),
            (committeeApprovalSession) => committeeApprovalSession.INFORMATION_HIERARCHY
        )
        COMMITTEE_APPROVAL_SESSIONS!: COMMITTEE_APPROVAL_SESSION[] | null;

        @OneToOne(
            () => manager.getDocumentEntity(schema),
            (document) => document.INFORMATION_HIERARCHY
        )
        DOCUMENT!: DOCUMENT | null;

        // Kind of a weird name, but when a document request is made, the document goes to a default department;
        // each doc type has a specified department, so this is a list of doc types that are automatically placed
        // into this department:
        @OneToMany(
            () => manager.getDocumentTypePLEntity(schema),
            (documentTypePL) => documentTypePL.DOC_REQUEST_RECEIVING_DEPARTMENT
        )
        DOC_REQUEST_RECEIVED_TYPES!: DOCUMENT_TYPE_PL[] | null;

        @OneToMany(
            () => manager.getAclEntity(schema),
            (acl) => acl.INFORMATION_HIERARCHY
        )
        ACL!: ACL[] | null;

        @OneToMany(
            () => manager.getAclEntity(schema),
            (acl) => acl.INHERIT_IH
        )
        INHERIT_ACLS!: ACL[] | null;

        // The yellow brick road from manuals to documents
        @OneToMany(
            () => manager.getInformationIndexEntity(schema),
            (informationIndex) => informationIndex.INFORMATION_HIERARCHY
        )
        INFORMATION_INDEXES!: INFORMATION_INDEX[] | null;

        @OneToMany(
            () => manager.getSignerAssignmentEntity(schema),
            (signerAssignment) => signerAssignment.INFORMATION_HIERARCHY
        )
        SIGNERASSIGNMENTS!: SIGNERASSIGNMENT[] | null;

        @OneToMany(
            () => manager.getRoutingConfigurationEntity(schema),
            (routingConfiguration) => routingConfiguration.MANUAL
        )
        ROUTING_CONFIGURATIONS!: ROUTING_CONFIGURATION[] | null;

        @OneToMany(
            () => manager.getCommitteeRoutingLogDetailEntity(schema),
            (committeeRoutingLogDetail) => committeeRoutingLogDetail.MANUAL
        )
        COMMITTEE_ROUTING_LOG_DETAILS!: COMMITTEE_ROUTING_LOG_DETAIL[] | null;

        // These are called Manual Groups in the UI
        @ManyToOne(
            () => manager.getPublisherEntity(schema),
            (publisher) => publisher.MANUALS
        )
        @JoinColumn({
            name: 'PUBLISHER_ID'
        })
        PUBLISHER!: PUBLISHER | null;

        @ManyToMany(
            () => manager.getAckScheduleEntity(schema),
            (ackSchedule) => ackSchedule.MANUALS
        )
        ACK_SCHEDULES!: ACK_SCHEDULE[] | null;

        // This entity relates back to itself, with these two relations:
        PARENT!: INFORMATION_HIERARCHY | null;
        CHILDREN!: INFORMATION_HIERARCHY[] | null;

        // these are not relations, but they are useful as the ACL list contains both, and they
        // need to be split up to display them as separate lists. See getNestedInformationHierarchy.ts
        // for more info, or use getViewers() or getCollaborators() if you only need the values for
        // just for one info hierarchy:
        VIEWERS!: ACL[] | null;
        COLLABORATORS!: ACL[] | null;
    }

    return INFORMATION_HIERARCHY;
}

// Not a perfect type check; just useful for determining if something is a DocRev or Manual when dealing with both at once
export function isInformationHierarchy(infoHierarchy: unknown): infoHierarchy is INFORMATION_HIERARCHY {
    return (infoHierarchy as INFORMATION_HIERARCHY).INFORMATION_HIERARCHY_ID !== undefined;
}


export { createInformationHierarchyEntity };

// Export the type representing an instance of the class generated by the factory function
export type INFORMATION_HIERARCHY = InstanceType<ReturnType<typeof createInformationHierarchyEntity>>;

export interface INFORMATION_HIERARCHY_WITH_ORG extends INFORMATION_HIERARCHY {
    ORG_ID: number;
    CHILDREN: INFORMATION_HIERARCHY_WITH_ORG[] | null;
}

// Im using this because I need to group the hierachies with an unselectable parent.
// Didn't want to do something hacky like creating a fake info hierarchy with a special ID.
// created for information hierarchy faceted filer.
export interface INFORMATION_HIERARCHIES_PARENT {
    TITLE: string;
    ORGID: number;
    CHILDREN: INFORMATION_HIERARCHY_WITH_ORG[];
}

export const isInformationHierarchyParent = (thing: INFORMATION_HIERARCHY | INFORMATION_HIERARCHY_WITH_ORG | INFORMATION_HIERARCHIES_PARENT): boolean => {
    return 'TITLE' in thing && !('INFORMATION_HIERARCHY_ID' in thing);
};

// To share between frontend and backend.
export interface ManualGroup {
    publisher: PUBLISHER;
    informationHierarchies: INFORMATION_HIERARCHY[];
    organizationId: number;
    organizationName: string;
    isParentOrg: boolean;
}
