import {
    Entity,
    JoinColumn,
    OneToOne,
    PrimaryColumn,
} from 'typeorm';
import { PERSON, } from '../master/PERSON';
import { SchemaEntityManager } from '../SchemaEntityManager';

// Factory function to create DOC_REQUEST_HANDLER entity with dynamic schema
function createDocRequestHandlerEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('DOC_REQUEST_HANDLER', { schema: schema })
    class DOC_REQUEST_HANDLER {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            scale: 0,
            name: 'USERID'
        })
        USERID!: number;

        @OneToOne(
            () => manager.getPersonEntity(schema), 
            (person) => person.DOC_REQUEST_HANDLER
        )
        @JoinColumn({
            name: 'USERID'
        })
        PERSON!: PERSON | null;
    }

    return DOC_REQUEST_HANDLER;
}

export { createDocRequestHandlerEntity };
export type DOC_REQUEST_HANDLER = InstanceType<ReturnType<typeof createDocRequestHandlerEntity>>;
