import {
    Column,
    Entity,
    PrimaryColumn,
} from 'typeorm';
import { SchemaEntityManager } from '../SchemaEntityManager';

// Factory function to create CHECKLIST entity with dynamic schema
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function createChecklistEntity(schemaName: string, manager: SchemaEntityManager) {
    @Entity('CHECKLIST', { schema: 'ACCREDITATIONMANAGER' })
    class CHECKLIST {
        static SCHEMANAME = schemaName;
        @PrimaryColumn('number', {
            nullable: false,
            name: 'CHECKLIST_ID'
        })
        CHECKLIST_ID!: number;

        @Column('number', {
            nullable: false,
            name: 'ORG_ACCREDITATION_ID'
        })
        ORG_ACCREDITATION_ID!: number;

        @Column('number', {
            nullable: false,
            name: 'DEPARTMENT_ID'
        })
        DEPARTMENT_ID!: number;

        @Column('varchar2', {
            nullable: true,
            length: 200,
            name: 'TITLE'
        })
        TITLE!: string | null;

        @Column('clob', {
            nullable: true,
            name: 'ACCREDITOR_CHECKLIST_DATA'
        })
        ACCREDITOR_CHECKLIST_DATA!: string | null;
    }

    return CHECKLIST;
}

// Type definition for the return type of the factory function
type CHECKLIST = InstanceType<ReturnType<typeof createChecklistEntity>>;

// Explicitly export the type and the factory function
export type { CHECKLIST };
export { createChecklistEntity };
