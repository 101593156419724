import React from 'react';
import { FeatureFlagMap } from './FeatureFlagClient';
import { ORGANIZATION } from '../../../entities/master/ORGANIZATION';
import { TableCell, TableRow } from '@material-ui/core';
import DataTable from '../../_shared/DataTable/DataTable';

const ANY_MATCH_TOKEN = '*';

type FeatureFlagMapDisplayProps = {
    featureFlagMap: FeatureFlagMap;
    organizationMap: [number: ORGANIZATION];
};

export default function FeatureFlagMapDisplay(props: FeatureFlagMapDisplayProps) {
    const { featureFlagMap, organizationMap } = props;
    const features = Object.keys(featureFlagMap);

    const formatFeatureFlagMapping = (feature: string) => {
        let mappedOrgs = featureFlagMap[feature];

        if (mappedOrgs.length === 0) {
            return 'Feature is not mapped to any organization.';
        }

        if (mappedOrgs.length === 1 && mappedOrgs[0] === ANY_MATCH_TOKEN) {
            return 'Feature is mapped to all organizations.';
        }

        mappedOrgs = featureFlagMap[feature].map((orgID) => organizationMap[orgID].NAME);
        mappedOrgs.sort();

        return mappedOrgs.map((org, idx) => {
            return (
                <li key={idx}>
                    {org}
                </li>
            );
        });
    };

    const formattedTableData = features.map(feature => {
        return {
            Feature: feature,
        };
    });

    return (
        <>
            <DataTable
                muiDataTableProps={{
                    data: formattedTableData,
                    title: 'Active Feature Flags',
                    options: {
                        expandableRows: true,
                        renderExpandableRow: (rowData, rowMeta) => {
                            const feature = features[rowMeta.dataIndex];

                            return <TableRow>
                                <TableCell />
                                <TableCell
                                    colSpan={formattedTableData[0] && Object.keys(formattedTableData[0]).length - 1}
                                >
                                    {formatFeatureFlagMapping(feature)}
                                </TableCell>
                            </TableRow>;
                        }
                    },
                    columns: formattedTableData[0] && Object.keys(formattedTableData[0]).map(key => {
                        return {
                            name: key,
                        };
                    })
                }}
            />
        </>
    );
}