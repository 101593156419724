import React from 'react';

interface MyMeetingsIcon {
    size: number
}

export const MyMeetingsIcon: React.FC<MyMeetingsIcon> = ({ size }) => {
    return (
        <svg width={size.toString()} height={size.toString()} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" rx="4" fill="hsl(var(--primary))"/>
            <circle cx="12" cy="12" r="7" stroke="white" stroke-width="2"/>
            <path d="M11 7.5H12.5V11.5H16V13H13H11V7.5Z" fill="white"/>
        </svg>  
    );
};