import {
    Column,
    Entity,
    JoinColumn,
    ManyToOne,
    OneToMany,
    PrimaryColumn,
} from 'typeorm';
import { COMMITTEE_ROUTING_LOG_DETAIL,  } from './COMMITTEE_ROUTING_LOG_DETAIL';
import { PERSON,  } from '../master/PERSON';
import { numberToBooleanTransformer } from '../_helperFunctions/transformers';
import { SchemaEntityManager } from '../SchemaEntityManager';

// Factory function to create COMMITTEE_ROUTING_LOG entity with dynamic schema
function createCommitteeRoutingLogEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('COMMITTEE_ROUTING_LOG', { schema })
    class COMMITTEE_ROUTING_LOG {
        static SCHEMANAME = schema;
        @PrimaryColumn(
            'number',
            {
                nullable: false,
                name: 'ROUTING_ID'
            }
        )
        ROUTING_ID!: number;

        @Column(
            'number',
            {
                nullable: false,
                name: 'ROUTER_USERID'
            }
        )
        ROUTER_USERID!: number;

        @ManyToOne(
            () => manager.getPersonEntity(schema), 
            (person) => person.COMMITTEE_ROUTING_LOGS
        )
        @JoinColumn({
            name: 'ROUTER_USERID'
        })
        ROUTER!: PERSON | null;

        @Column(
            'number',
            {
                nullable: true,
                name: 'ROUTER_PERSONALUSERID'
            }
        )
        ROUTER_PERSONALUSERID!: number | null;

        @ManyToOne(
            () => manager.getPersonEntity(schema), 
            (person) => person.COMMITTEE_ROUTING_LOGS_AS_IMPERSONATOR
        )
        @JoinColumn({
            name: 'ROUTER_PERSONALUSERID'
        })
        IMPERSONATOR!: PERSON | null;

        @Column(
            'number',
            {
                nullable: false,
                transformer: numberToBooleanTransformer,
                name: 'USE_SAVED_ROUTES'
            }
        )
        USE_SAVED_ROUTES!: boolean;

        @Column(
            'timestamp',
            {
                nullable: false,
                default: () => 'SYSDATE',
                name: 'TIMESTAMP'
            }
        )
        TIMESTAMP!: Date;

        @OneToMany(
            () => manager.getCommitteeRoutingLogDetailEntity(schema), 
            (committeeRoutingLogDetail) => committeeRoutingLogDetail.COMMITTEE_ROUTING_LOG
        )
        COMMITTEE_ROUTING_LOG_DETAILS!: COMMITTEE_ROUTING_LOG_DETAIL[] | null;
    }

    return COMMITTEE_ROUTING_LOG;
}

// Export the factory function
export { createCommitteeRoutingLogEntity };

// Define and export the type for instances created by the factory function
export type COMMITTEE_ROUTING_LOG = InstanceType<ReturnType<typeof createCommitteeRoutingLogEntity>>;
