import { DOCUMENT_TYPE_PL } from '@/entities/org/DOCUMENT_TYPE_PL';
import useSWR from 'swr';

export const useDocumentTypes = () => {
    interface DocTypesData {
        doctypes: DOCUMENT_TYPE_PL[]
    }



    const { data: docTypesData } = useSWR<DocTypesData>('/api/homepage/header/get-doc-types');
  

    return docTypesData?.doctypes;
};