import UserBundleContext from '@/context/UserBundleContext';
import { ORGANIZATION } from '@/entities/master/ORGANIZATION';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';

export const useIsUserLoggedInToAMultiOrg = () => {
    const userBundle = useContext(UserBundleContext);

    if (!userBundle.organization) {
        return { isPublisherOrg: false, isSubscriberOrg: false, canDoMultiOrgSearches: false };
    }

    const isPublisherOrg = userBundle.organization.PUBLISHING_SCHEMA !== null;

    return {
        isPublisherOrg: isPublisherOrg,
        isSubscriberOrg: userBundle.organization.SUBSCRIBER_SCHEMA !== null,
        canDoMultiOrgSearches: userBundle.user?.SEARCH_USER?.USERID != null && isPublisherOrg
    };
};

export const useMultiOrgSubscriberOrgs = (includePublisher?: boolean) => {
    if (includePublisher === undefined) includePublisher = false;

    const userBundle = useContext(UserBundleContext);

    const [subscriberOrgs, setSubscriberOrgs] = useState<ORGANIZATION[]>([]);
    const notPublisher = userBundle.organization?.PUBLISHING_SCHEMA === null;

    useEffect(() => {
        if (notPublisher) return;
        const fetch = async () => {
            const res = await axios.get('/api/_shared/multiorg/suborgs');
            setSubscriberOrgs(res.data.childOrgs);
        };
        fetch();
    }, [userBundle]);

    if (!userBundle.organization || notPublisher) {
        return [];
    }

    if (includePublisher) {
        return [userBundle.organization, ...subscriberOrgs];
    } else {
        return subscriberOrgs;
    }
};