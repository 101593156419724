import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { LoadingIndicator } from '../../../_shared/LoadingIndicator';
import { LoadingStatuses } from '../../../../utils/LoadingStatuses';
import { ALL_STORE } from '../../../../entities/org/ALL_STORE';
import { createSortableDate } from '../../../../_shared/utils/docManagerFunctions';
import { FormControlLabel, makeStyles, Radio, RadioGroup, TextField } from '@material-ui/core';
import ReactSelect from 'react-select';
import SaveBar from '../../../_shared/SaveBar/SaveBar';
import { FileInput } from '../../../_shared/FileInput';
import LucidocColors from '../../../../constants/LucidocColors';
import H3WithHelpTip from '../../../_shared/Forms/H3WithHelpTip';
import { sleep } from '../../../../_shared/utils/jsUtils/sleep';
import { RefreshRounded } from '@material-ui/icons';

type EditIhImageProps = {
    ihID: number,
    closeModal?: () => void,
}

const useStyles = makeStyles({
    imageInfoDiv: {
        margin: '1rem 1rem 1rem 1rem',
        display: 'grid',
        gridTemplateColumns: '1fr 5fr',
        alignItems: 'start',
        gridGap: '2rem',
    },
});

export function EditIhImage(props: EditIhImageProps) {
    const classes = useStyles();

    const [ loadingStatus, setLoadingStatus ] = useState(LoadingStatuses.LOADING);
    const [ imageContentLoadingStatus, setImageContentLoadingStatus ] = useState(LoadingStatuses.LOADING);
    const [ allIhImages, setAllIhImages ] = useState<ALL_STORE[]>([]);

    const [ actionType, setActionType ] = useState<'Corporate' | 'Existing' | 'New'>();
    const [ selectedImage, setSelectedImage ] = useState<ALL_STORE>();

    // special values just for creating a new image, which needs a FormData post request
    const [ newImageUpload, setNewImageUpload ] = useState<File>();
    const [ newImageName, setNewImageName ] = useState<string>('');

    useEffect(() => {
        loadInitialData().then();
    }, []);

    useEffect(() => {
        // any change in the dropdown should clear out previous file upload values:
        setNewImageUpload(undefined);
        setNewImageName('');
    }, [selectedImage]);

    async function loadInitialData(imageShouldBeDifferent?: boolean) {
        setLoadingStatus(LoadingStatuses.LOADING);

        const res = await axios.get('/api/doc-manager/info-hierarchy/edit-info-hierarchy/image/get-all-ih-image-names', {
            params: {
                ihID: props.ihID
            }
        });

        if (   imageShouldBeDifferent
            && res.data.selectedImage?.BLOBID === selectedImage?.BLOBID
        ) {
            // this is a very bizarre way to handle this, because for some reason saving
            // a new image takes a while (even with the awaits on the back end) and it
            // loads the old image (and then if you refresh it'll be correct; seems like
            // a timing issue, but I can't otherwise figure out a solution other than
            // detecting whether the ID is indeed different, and just trying again with
            // a new (and delayed) request:
            await sleep(200);
            await loadInitialData(imageShouldBeDifferent);
        }
        else {
            setAllIhImages(res.data.allIhImages);
            setSelectedImage(res.data.selectedImage);
            setActionType(res.data.selectedImage ? 'Existing' : 'Corporate');
            setImageContentLoadingStatus(LoadingStatuses.READY);
            setLoadingStatus(LoadingStatuses.READY);
        }
    }

    async function getImage(blobID: number) {
        setImageContentLoadingStatus(LoadingStatuses.LOADING);

        const res = await axios.get('/api/doc-manager/info-hierarchy/edit-info-hierarchy/image/get-ih-image-by-blobid', {
            params: {
                blobID
            }
        });

        setSelectedImage(res.data.selectedImage);
        setImageContentLoadingStatus(LoadingStatuses.READY);
    }

    async function saveUpdatedImage() {
        setLoadingStatus(LoadingStatuses.SAVING);

        // Special exception for adding new images, which requires formData method
        if (actionType === 'New') {
            if (!newImageUpload) return; // mostly just a TypeScript check

            const formData = new FormData();
            formData.append('file', newImageUpload);
            formData.append('newImageName', newImageName);
            formData.append('ihID', props.ihID.toString());

            const res = await axios.post(
                '/api/doc-manager/info-hierarchy/edit-info-hierarchy/image/save-new-ih-image',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                }
            });

            if (res.data.success) {
                loadInitialData(true).then();
            }
        }
        // update image (which includes removing the image altogether) is normal:
        else {
            const res = await axios.post('/api/doc-manager/info-hierarchy/edit-info-hierarchy/image/update-ih-image', {
                ihID: props.ihID,
                selectedImageBlobID: actionType === 'Existing' && selectedImage?.BLOBID,
            });

            if (res.data.success) {
                loadInitialData().then();
            }
        }
    }

    function handleFileUpload(files: FileList | null) {
        if (!files || !files[0]) {
            return;
        }

        const file = files[0];
        const fileSizeInMB = file.size / 1024 / 1024;

        if (fileSizeInMB > 10) {
            window.alert(`File size is ${fileSizeInMB.toFixed(2)}mb. Current maximum is 10mb.\nPlease reduce the file size and try again.`);
            return;
        }

        setNewImageUpload(file);
    }

    if (loadingStatus !== LoadingStatuses.READY) {
        return <LoadingIndicator loadingStatus={loadingStatus} />;
    }

    const optionsForSelectMenu = allIhImages.map(allStore => ({
        label: allStore.REVISION_NOTE || '',
        value: allStore.BLOBID
    }));

    const selectedOption = selectedImage ? {
        label: selectedImage?.REVISION_NOTE,
        value: selectedImage?.BLOBID
    } : undefined;

    return (
        <div>
            <div className={classes.imageInfoDiv}>
                <H3WithHelpTip
                    text={'Select Image'}
                    helpText={'Logo selected here appears at the top of HTML documents and on the Info page for all documents'}
                />
                {/* for CSS grid: */}
                <span> </span>

                <p>Image Source</p>
                <div>
                    <RadioGroup
                        value={actionType}
                        onChange={e => setActionType(e.currentTarget.value as 'Corporate' | 'Existing' | 'New')}
                    >
                        <FormControlLabel value="Corporate" control={<Radio style={{ color: LucidocColors.purple }} />} label="Apply Corporate Image" />
                        <FormControlLabel value="Existing"  control={<Radio style={{ color: LucidocColors.purple }} />} label="Use existing image"    />
                        <FormControlLabel value="New"       control={<Radio style={{ color: LucidocColors.purple }} />} label="Add new image"         />
                    </RadioGroup>
                </div>

                {actionType === 'Corporate' &&
                    <>
                        <p>Content</p>
                        <p>Documents use the template image if available; otherwise, they use the organization image.</p>
                    </>
                }

                {actionType === 'Existing' &&
                    <>
                        <p>Select Image</p>
                        <ReactSelect
                            placeholder={'Select image...'}
                            value={selectedOption}
                            options={optionsForSelectMenu}
                            onChange={(option: any) => {
                                // this line is just to set the title in the select menu while the image loads separately:
                                setSelectedImage(allIhImages?.find(image => image.BLOBID === option.value));
                                getImage(option.value).then();
                            }}
                        />

                        {imageContentLoadingStatus !== LoadingStatuses.READY &&
                            <>
                                <span> </span> {/* dummy element for the CSS grid */}
                                <LoadingIndicator loadingStatus={imageContentLoadingStatus} />
                            </>
                        }

                        {imageContentLoadingStatus === LoadingStatuses.READY && selectedImage?.BLOBID &&
                            <>
                                <p>Created Date</p>
                                <p>{selectedImage?.CHANGEDDT && createSortableDate(new Date(selectedImage?.CHANGEDDT), true)}</p>

                                <p>Content</p>
                                <img
                                    width={200}
                                    src={`data:image/jpg;base64,${Buffer.from(selectedImage?.GRAPHIC || new Buffer('')).toString('base64')}`}
                                    alt={selectedImage?.REVISION_NOTE || ''}
                                />
                            </>
                        }
                    </>
                }

                {actionType === 'New' &&
                    <>
                        <p>New Image Name</p>
                        <TextField
                            size={'small'}
                            variant={'outlined'}
                            value={newImageName}
                            onChange={e => setNewImageName(e.currentTarget.value)}
                        />

                        <p>Content</p>
                        <FileInput
                            fileName={newImageUpload?.name}
                            handleFileUpload={files => handleFileUpload(files)}
                            uniqueID={'file-input-for-new-ih-image'}
                        />

                        <span> </span> {/* dummy span for CSS grid*/}
                        {newImageUpload &&
                            <img
                                style={{ marginTop: '.5rem' }}
                                width={200}
                                src={URL.createObjectURL(newImageUpload)}
                                alt={selectedImage?.REVISION_NOTE || ''}
                            />
                        }
                    </>
                }
            </div>

            <SaveBar
                onSave={() => saveUpdatedImage()}
                isSaveDisabled={
                        (         actionType === 'New'
                         && (   ! newImageName
                             || ! newImageUpload
                        ))
                    ||
                        (actionType === 'Existing' && !selectedImage)
                }
                onCancel={() => loadInitialData()}
                cancelIcon={ <RefreshRounded /> }
                onClose={() => props.closeModal?.()}
            />
        </div>
    );
}
