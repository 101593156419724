import React from 'react';
import { TextField } from '@material-ui/core';

interface DatePickerProps {
    label: string
    value: string // initialize as '' instead of undefined or React will complain you're going from a controlled input to uncontrolled or vice versa
    disabled?: boolean
    fullWidth?: boolean
    defaultValue?: string
    onChange: (newDateString: string) => void
}

export function DatePicker(props: DatePickerProps) {
    return (
        <TextField
            // PROPS
            label={props.label}
            value={props.value}
            onChange={e => props.onChange(e.currentTarget.value)}

            // NON-PROPS
            type={'date'}
            variant={'outlined'}
            size={'small'}
            InputLabelProps={{
                shrink: true,
            }}
            disabled={props.disabled}
            fullWidth={props.fullWidth}
            defaultValue={props.defaultValue}
        />
    );
}
