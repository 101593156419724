import {
    Column,
    Entity,
    JoinColumn,
    ManyToOne,
    OneToOne,
    PrimaryColumn,
} from 'typeorm';

import { type DOCUMENTREVISION,  } from './DOCUMENTREVISION';
import { ALL_STORE,  } from './ALL_STORE';
import { SchemaEntityManager } from '../SchemaEntityManager';


function createSubRevisionEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('SUB_REVISION', { schema: schema })
    class SUB_REVISION {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'SUBREVID'
        })
        SUBREVID!: number;

        @Column('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'DOCREVID'
        })
        DOCREVID!: number;

        @ManyToOne(
            () => manager.getDocumentRevisionEntity(schema),
            (documentRevision) => documentRevision.SUB_REVISIONS
        )
        @JoinColumn({
            name: 'DOCREVID'
        })
        DOCREV!: DOCUMENTREVISION;

        @Column('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'SUBREVNO'
        })
        SUBREVNO!: number;

        @Column('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'STATUS'
        })
        STATUS!: number;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'OWNER_ID'
        })
        OWNER_ID!: number | null;

        @Column('date', {
            nullable: true,
            name: 'CREATEDT'
        })
        CREATEDT!: Date | null;

        @Column('date', {
            nullable: true,
            name: 'LASTMODDT'
        })
        LASTMODDT!: Date | null;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'PRIMARY_BLOBID'
        })
        PRIMARY_BLOBID!: number | null;

        @OneToOne(
            () => manager.getAllStoreEntity(schema), 
            (allStore) => allStore.SUB_REVISION
        )
        @JoinColumn({
            name: 'PRIMARY_BLOBID'
        })
        ALL_STORE!: ALL_STORE | null;

        @Column('varchar2', {
            nullable: true,
            length: 4000,
            name: 'OWNER_NOTE'
        })
        OWNER_NOTE!: string | null;
    }
    return SUB_REVISION;
}


export { createSubRevisionEntity };
export type SUB_REVISION = InstanceType<ReturnType<typeof createSubRevisionEntity>>;