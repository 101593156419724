import { cn } from '@/lib/utils';
import React from 'react';
import { NavigationMenuLink } from './navigation-menu';

interface NavigationDropdownProps {
    children?: React.ReactNode
    footer?: React.ReactNode
    className?: string
}

export const NavigationMenuContentDropdown: React.FC<NavigationDropdownProps> = ({ children, className, footer }) => {
    return (
        <div className={cn('flex flex-col w-full md:w-[450px]', className)}>
            <div className='flex flex-col [&>div]:py-1 border-b border-border w-full py-2'>
                {children}
            </div>
            {footer}
        </div>
    );
};

export const NavigationMenuFooter: React.FC<{ children: React.ReactNode, href: string, className?: string }> = ({ children, className, href }) => {
    return (
        <NavigationMenuLink href={href} external className={cn('hover:bg-accent p-3 px-5 text-sm', className)}>
            {children}
        </NavigationMenuLink>
    );
};

export const NavigationDropdownTitle: React.FC<{ children: React.ReactNode, className?: string }> = ({ children, className }) => {
    return (
        <p className={cn('text-2xl mt-5 px-5 text-primary', className)}>{children}</p>
    );
};

interface NavigationDropdownSectionEntryProps {
    icon?: React.ReactNode,
    title: string,
    description: string,
    href?: string,
    newTab?: boolean
    onClick?: () => void,
    external: boolean
}

export const NavigationDropdownSectionEntry: React.FC<NavigationDropdownSectionEntryProps>
    = ({ icon, title, description, href, onClick, external, newTab = false }) => {

        const content = <>
            {icon}
            <div className='flex flex-col items-start'>
                <p className='text-sm font-normal text-primary'>{title}</p>
                <p className='text-xs text-secondary'>{description}</p>
            </div>
        </>;

        const style = 'flex flex-row space-x-4 items-center py-1 hover:bg-accent w-full cursor-pointer px-5';

        if (href) {
            return <NavigationMenuLink external={external} onClick={onClick} href={href} openInNewTab={newTab} className={style}>
                {content}
            </NavigationMenuLink>;
        } else {
            return (
                <div onClick={onClick} className={style}>
                    {content}
                </div>
            );
        }
    };

export const NavigationDropdownSectionEntryLabel: React.FC<{ children: React.ReactNode, className?: string }> = ({ children, className }) => {
    return <p className={cn('uppercase text-xs font-semibold text-muted-foreground mt-2 px-5', className)}>{children}</p>;
};
