import React, { ReactNode } from 'react';
import FieldField from './FieldField';
import { FIELDTEMPLATE } from '../../../entities/org/FIELDTEMPLATE';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { documentMetadataValues } from './dataTypes';

interface FieldGroupProps {
    group: FIELDTEMPLATE
    updateDocumentMetadata: (arg0: documentMetadataValues) => void
}

const useStyles = makeStyles({
    fieldGroup: {
        borderBottom: '1px solid lightgrey',
        marginBottom: '1rem',
    },
    fieldGroupHeading: {
        color: 'blue',
    },
    columnOfFields: {
        display: 'inline-block',
        verticalAlign: 'top',
        width: '50%',
        padding: '0 1rem',
        boxSizing: 'border-box'
    },
    emptyGroupWarning: {
        color: 'red'
    }
});

export default function FieldGroup(props: FieldGroupProps) {
    const classes = useStyles();

    const allFields = props.group.CHILDREN ? props.group.CHILDREN
        .map((fieldTemplate, idx) => {
            if (!fieldTemplate) {
                return undefined;
            }
            return (
                <FieldField
                    key={idx}
                    fieldTemplate={fieldTemplate}
                    field={fieldTemplate.FIELD}
                    updateDocumentMetadata={(data) => props.updateDocumentMetadata(data)}
                />
            );
        }) : [];

    const fieldsFirstHalf = props.group.CHILDREN && allFields
        .slice(0, Math.ceil(props.group.CHILDREN.length / 2));

    const fieldsSecondHalf = props.group.CHILDREN && allFields
        .slice(Math.ceil(props.group.CHILDREN.length / 2), props.group.CHILDREN.length);

    let emptyGroupWarning: ReactNode = null;
    if (props.group.CHILDREN && !props.group.CHILDREN.length) {
        emptyGroupWarning = (
            <p className={classes.emptyGroupWarning}>
                This group is empty. You may want to add fields, or remove this group.
            </p>
        );
    }

    return (
        <div className={classes.fieldGroup}>
            <h3 className={classes.fieldGroupHeading}>
                {props.group.NAME}:
            </h3>

            {emptyGroupWarning}

            <div className={classes.columnOfFields}>
                {fieldsFirstHalf}
            </div>

            <div className={classes.columnOfFields}>
                {fieldsSecondHalf}
            </div>
        </div>
    );
}
