import {
    Column,
    Entity,
    JoinColumn,
    ManyToOne,
    OneToMany,
    PrimaryColumn,
} from 'typeorm';

import { type PUBLICATION } from './PUBLICATION';
import { numberToBooleanTransformer } from '../_helperFunctions/transformers';
import { INVENTORY } from './INVENTORY';
import { SchemaEntityManager } from '../SchemaEntityManager';

function createSubscriptionEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('SUBSCRIPTION', { schema: schema })
    class SUBSCRIPTION {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            name: 'SUBSCRIPTIONID'
        })
        SUBSCRIPTIONID!: number;

        @Column('timestamp', {
            nullable: true,
            name: 'CREATED_DATE'
        })
        CREATED_DATE!: Date;

        @Column('number', {
            nullable: false,
            name: 'SUBSCRIBER_ORGID'
        })
        SUBSCRIBER_ORGID!: number;

        @Column('number', {
            nullable: false,
            name: 'PUBLICATIONID'
        })
        PUBLICATIONID!: number;

        @ManyToOne(
            () => manager.getPublicationEntity(schema),
            (publication) => publication.SUBSCRIPTIONS
        )
        @JoinColumn({
            name: 'PUBLICATIONID'
        })
        PUBLICATION!: PUBLICATION;

        @Column('number', {
            nullable: true,
            name: 'DOCOWNER_ID'
        })
        DOCOWNER_ID!: number;

        @Column('number', {
            nullable: true,
            name: 'DOCID'
        })
        DOCID!: number;

        @Column('varchar2', {
            nullable: true,
            name: 'DEPARTMENT_NAME'
        })
        DEPARTMENT_NAME!: string;

        @Column('number', {
            nullable: true,
            default: () => 0,
            transformer: numberToBooleanTransformer,
            name: 'IS_ACTIVE'
        })
        IS_ACTIVE!: boolean;

        @OneToMany(
            () => manager.getInventoryEntity(schema),
            (inventory) => inventory.SUBSCRIPTION
        )
        INVENTORIES!: INVENTORY[] | null;
    }

    return SUBSCRIPTION;
}

export { createSubscriptionEntity };
export type SUBSCRIPTION = InstanceType<ReturnType<typeof createSubscriptionEntity>>;
