import React from 'react';
import { EditItemCollaborators } from '../../../../_shared/Forms/EditItemCollaborators/EditItemCollaborators';
import { closeWindow } from '../../../../../utils/savebarUtilities';

type EditDocCollaboratorsProps = {
    match: any
}

// this intermediary function is only necessary for now because we need the match object
// to pass the docid to EditItemCollaborators; once everything is loaded in React, the docid
// could be passed as a prop without the URL, and this can be skipped to go straight to
// EditItemCollaborators:
export function EditDocCollaborators(props: EditDocCollaboratorsProps) {

    return (
        <EditItemCollaborators
            objectType={'DOCID'}
            objectID={props.match.params.docid}
            closeModal={() => closeWindow()}
        />
    );
}
