import React from 'react';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuSub, DropdownMenuSubContent, DropdownMenuSubTrigger, DropdownMenuTrigger } from '../dropdown-menu';
import { Button } from '../button';
import { MixerHorizontalIcon } from '@radix-ui/react-icons';
import { Table } from '@tanstack/react-table';

interface ServerSideViewOptionsProps<TData> {
    exportTable: (exportType: 'csv' | 'pdf' | 'xlsx') => void;
    table?: Table<TData>;
}

export const ServerSideViewOptions = <TData,>({
    exportTable,
    table
}: ServerSideViewOptionsProps<TData>) => {
        
    const isAnyColumnHidden = table?.getAllColumns().some((column) => !column.getIsVisible());
        
        return (<DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button
                    variant="outline"
                    size="sm"
                    className="ml-auto mt-2 sm:mt-0 sm:ml-4 h-8 lg:flex"
                >
                    <MixerHorizontalIcon className="h-4 w-4" />
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="w-[200px]">
                <DropdownMenuItem
                    onSelect={() => {
                        // https://tanstack.com/table/v8/docs/guide/row-models#the-order-of-row-model-execution
                        // Row models are executed in an order, view at the link above.
                        // table.getPrePaginationRowModel().rows.forEach((row) => {
                        //     row.getAllCells().map(cell => cell.).join()
                        // })
                        exportTable('csv');
                    }}
                >
                    Export to CSV File
                </DropdownMenuItem>
                <DropdownMenuItem
                    onSelect={() => {
                        exportTable('xlsx');
                    }}
                >
                    Export to Excel File
                </DropdownMenuItem>
                <DropdownMenuItem
                    onSelect={() => {
                        exportTable('pdf');
                    }}
                >
                    Export to PDF
                </DropdownMenuItem>
                <DropdownMenuSub>
                    <DropdownMenuSubTrigger>
                        Toggle Columns
                    </DropdownMenuSubTrigger>
                    <DropdownMenuSubContent>
                        {table?.getAllColumns()
                            .filter(
                                (column) =>
                                    typeof column.accessorFn !== 'undefined' && column.getCanHide()
                            )
                            .map((column) => {
                                return (
                                    <DropdownMenuItem
                                        key={column.id}
                                        // Prevents the dropdown from closing when visibility options are toggled.
                                        onSelect={(e) => e.preventDefault()}
                                        onClick={() => column.toggleVisibility()}
                                    >
                                        {column.columnDef.meta?.['label'] ?? `No meta.label provided in column definition (${column.id})`}
                                    </DropdownMenuItem>
                                );
                            })}
                        {isAnyColumnHidden && <>
                            <DropdownMenuSeparator />
                            <DropdownMenuItem className='items-center justify-center' onSelect={() => {
                                table?.getAllColumns().forEach((column) => {
                                    column.toggleVisibility(true);
                                });
                            }}>

                                Reset
                            </DropdownMenuItem>
                        </>}
                    </DropdownMenuSubContent>
                </DropdownMenuSub>
            </DropdownMenuContent>
        </DropdownMenu >);
};