import React, { useContext, useRef, useState } from 'react';

import { Button } from '@/ui/ui/button';
import { CommitteesQuery } from '../../Committees';
import { useBrowserQuery } from '@/hooks/useBrowserQuery';
import useSWR from 'swr';
import { CONSENT_AGENDA } from '@/entities/org/CONSENT_AGENDA';
import { buildDocURL, createSortableDate } from '@/_shared/utils/docManagerFunctions';
import { DocumentRevisionStatuses } from '@/entities/org/DOCUMENTREVISION';
import UserBundleContext from '@/context/UserBundleContext';
import { Section } from '../EditCommitteeDialog';
import { useField, useForm } from '@tanstack/react-form';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/ui/ui/select'; 
import { DataTableColumnHeader } from '@/ui/ui/datatable/column-header';
import { decodeUTF8String } from '@/v2/components/documentrevisiontable/utils';
import UnscheduledItemsTableToolbar from '@/v2/components/consentAgendaTable/UnscheduledItemsTableToolbar';
import { PaginationToolsForTable } from '@/ui/ui/datatable/pagination-tools';
import { DataTable, DataTableHandle } from '@/ui/ui/datatable/data-table';
import { ColumnDef } from '@tanstack/react-table';
import { CreateAgendaDialog } from './CreateAgendaDialog';
import { CreateMinutesDialog } from './CreateMinutesDialog';
import { Spinner } from '@/ui/ui/spinner';
import axios from 'axios';
import { COMMITTEE } from '@/entities/org/COMMITTEE';
import { HybridLink } from '@/ui/ui/hybridlink';
import { CreateMinutesTemplateDialog } from './CreateMinutesTemplateDialog';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from '@/ui/ui/dialog';

export interface UnscheduledItemEntry {
    title: string;
    documentId?: number;
    manualId?: number;
    revNo: number;
    documentType: string;
    type: string;
    departmentName: string;
}

export const EditCommitteesDialogAgendasTab: React.FC = () => {

    const [query, setQuery] = useBrowserQuery<CommitteesQuery>();
    const userBundle = useContext(UserBundleContext);
    const [validActiveConsentAgendaItem, setValidActiveConsentAgendaItem] = useState(true);

    const { data: committeeData } = useSWR<{ committee: COMMITTEE }>(`/api/committee/${query.editingCommitteeId}`);

    const committee = committeeData?.committee;
    const departmentAlias = userBundle.organization?.ORGUNITNAME ?? 'Department';

    const isChairPersonOrSecretary = userBundle.user?.USERID === committee?.CHAIRPERSON_USERID || userBundle.user?.USERID === committee?.SECRETARY_USERID;
    const isCollaborator = (committeeData?.committee.COLLABORATORS ?? []).some(person => person.USERID === userBundle.user?.USERID);

    const {
        data: activeConsentAgendasData,
        mutate: refetchActiveAgendas
    } = useSWR<{ consentAgendas: CONSENT_AGENDA[] }>(`/api/committee/${query.editingCommitteeId}/consent-agenda/active`);

    const {
        data: unscheduledItemData,
        isLoading: isLoadingUnscheduledItems,
        mutate: refetchUnscheduledItems
    } = useSWR<{ unscheduledCommitteeApprovalRequests }>(`/api/committee/${query.editingCommitteeId}/unscheduled-items`);

    const formattedUnscheduledItemsTableData = unscheduledItemData?.unscheduledCommitteeApprovalRequests ? unscheduledItemData.unscheduledCommitteeApprovalRequests
        .map(car => ({
            title: car.docRev_TITLE ? car.docRev_TITLE : car.mih_TITLE,
            documentId: car.docRev_DOCID,
            manualId: car.mih_INFORMATION_HIERARCHY_ID,
            revNo: car.docRev_REVNO,
            documentType: car.docRev_TITLE ? car.docType_DESCRIPTION : 'Manual',
            type: car.cas_TYPE,
            departmentName: car.informationHierarchy_TITLE ?? 'N/A'
        })) : [];
    formattedUnscheduledItemsTableData.sort((a, b) => a.title.localeCompare(b.title));

    const form = useForm<{
        activeConsentAgendaId: number | null
        activeConsentAgendaHeadingId: number | null
    }>({
        defaultValues: {
            activeConsentAgendaId: committee?.ACTIVE_CONSENT_AGENDA_ID ?? null,
            activeConsentAgendaHeadingId: committee?.ACTIVE_CA_HEADING_ID ?? null
        },
        onSubmit: async (values) => {
            const data = values.value;
            data.activeConsentAgendaId = data.activeConsentAgendaId === -1 ? null : data.activeConsentAgendaId;
            data.activeConsentAgendaHeadingId = data.activeConsentAgendaHeadingId === -1 ? null : data.activeConsentAgendaHeadingId;

            if (data.activeConsentAgendaId === null) {
                data.activeConsentAgendaHeadingId = null;
            }

            const headings = activeConsentAgendasData?.consentAgendas.find(aca => aca.CONSENT_AGENDA_ID === data.activeConsentAgendaId)?.CONSENT_AGENDA_HEADINGS ?? [];
            const selectedHeading = headings.find(heading => heading.HEADING_ID === data.activeConsentAgendaHeadingId);
            if (!selectedHeading) {
                data.activeConsentAgendaHeadingId = null;
            }

            const valid = (data.activeConsentAgendaId && data.activeConsentAgendaHeadingId) || (!data.activeConsentAgendaId && !data.activeConsentAgendaHeadingId);

            if (valid) {
                await axios.put(
                    `/api/committee/${query.editingCommitteeId}/active-consent-agenda`,
                    data
                );
                refetchUnscheduledItems();
            }

            // The only reason the form input is invalid if you select an active consent agenda, but no consent agenda heading
            setValidActiveConsentAgendaItem(!!valid);
        },
    });

    const activeConsentAgendaId = useField({ form, name: 'activeConsentAgendaId' });

    const activeAgendaHeaderOptions = !activeConsentAgendaId.getValue() ? [] : activeConsentAgendasData?.consentAgendas
        .find(aca => aca.CONSENT_AGENDA_ID === activeConsentAgendaId.getValue())?.CONSENT_AGENDA_HEADINGS?.sort((a, b) => a.LIST_INDEX - b.LIST_INDEX)
        .map((cah, index) => ({
            label: `${index + 1}. ${cah.LABEL}`,
            value: cah.HEADING_ID
        })) || [];

    const activeAgendaOptions = activeConsentAgendasData?.consentAgendas?.map((consentAgenda) => {
        return <SelectItem value={`${consentAgenda.CONSENT_AGENDA_ID}`}>
            {createSortableDate(new Date(consentAgenda.MEETING_START_DATE), true)}
        </SelectItem>;
    }) || [];

    const dataTableRef = useRef<DataTableHandle<UnscheduledItemEntry>>(null);

    const columns: ColumnDef<UnscheduledItemEntry>[] = [
        {
            id: 'title',
            accessorKey: 'title',
            header: ({ column }) => {
                return (
                    <DataTableColumnHeader column={column} title='Title' />
                );
            },
            meta: {
                label: 'Title',
            },
            cell: ({ cell }) => {
                const url = 
                    cell.row.original.documentId ?
                        buildDocURL(userBundle.organization!.PREFIX!, cell.row.original.documentId, cell.row.original.revNo, true) : 
                        `/o/${userBundle.organization?.PREFIX}/dashboard?homepageTab=manuals&infoHierarchyID=${cell.row.original.manualId}`;
                return <div>
                    <p
                        onClick={() => {
                            window.open(
                                url
                            );
                        }}

                        className='inline-block text-secondary whitespace-break-spaces font-medium text-left px-2 p-1.5 hover:bg-accent  hover:cursor-pointer rounded-sm'>
                        {decodeUTF8String(cell.getValue<string>())}
                    </p>
                </div>;
            }
        },
        {
            id: 'documentId',
            accessorKey: 'documentId',
            enableSorting: false,
            header: ({ column }) => {
                return (
                    <DataTableColumnHeader column={column} title='DOCID' />
                );
            },
            cell: ({ cell }) => {
                return <code className='text-secondary'>{cell.getValue() ?? '-'}</code>;
            },
            meta: {
                label: 'Document ID',
            }
        },
        {
            id: 'manualId',
            accessorKey: 'manualId',
            enableSorting: false,
            header: ({ column }) => {
                return (
                    <DataTableColumnHeader column={column} title={`${userBundle.organization?.MANUALNAME} ID`} />
                );
            },
            cell: ({ cell }) => {
                return <code className='text-secondary'>{cell.getValue() ?? '-'}</code>;
            },
            meta: {
                label: `${userBundle.organization?.MANUALNAME} ID`,
            }
        },
        {
            id: 'revNo',
            accessorKey: 'revNo',
            enableSorting: false,
            header: ({ column }) => {
                return (
                    <DataTableColumnHeader column={column} title='RevNo' />
                );
            },
            cell: ({ cell }) => {
                return <p className='text-secondary'>{cell.getValue() ?? '-'}</p>;
            },
            meta: {
                label: 'RevNo',
            }
        },
        {
            id: 'documentType',
            accessorKey: 'documentType',
            enableSorting: false,
            header: ({ column }) => {
                return (
                    <DataTableColumnHeader column={column} title='Document Type' />
                );
            },
            cell: ({ cell }) => {
                return <p className='text-secondary'>{cell.getValue() ?? '-'}</p>;
            },
            meta: {
                label: 'Document Type',
            },
            filterFn: (row, id, value) => {
                return value.includes(row.getValue(id));
            },
        },
        {
            id: 'type',
            accessorKey: 'type',
            enableSorting: false,
            header: ({ column }) => {
                return (
                    <DataTableColumnHeader column={column} title='Type' />
                );
            },
            cell: ({ cell }) => {
                return <code className='text-secondary'>{cell.getValue()}</code>;
            },
            meta: {
                label: 'Type',
            },
            filterFn: (row, id, value) => {
                return value.includes(row.getValue(id));
            },
        },
        {
            id: 'departmentName',
            accessorKey: 'departmentName',
            enableSorting: false,
            header: ({ column }) => {
                return (
                    <DataTableColumnHeader column={column} title={departmentAlias} />
                );
            },
            cell: ({ cell }) => {
                return <p className='text-secondary'>{cell.getValue() ?? '-'}</p>;
            },
            meta: {
                label: departmentAlias,
            },
            filterFn: (row, id, value) => {
                return value.includes(row.getValue(id));
            },
        },
    ];

    const [noMinutesDepartmentId, setNoMinutesDepartmentId] = useState(false);

    return (
        <>
            <div className='flex pt-4'>
                <div className='flex '>
                    <div>
                        <Button
                            className='h-8 p-2'
                            onClick={
                                () => {
                                    setQuery({ ...query, createAgendaDialogOpen: true });
                                }
                            }
                        >
                            Add Meeting
                        </Button>
                        <p className='pt-4 pb-4'>
                            When a document revision or manual is submitted to a committee <br />
                            for approval, it is added to the pool of unscheduled items.
                            <br />
                            <br />
                            Incoming document revisions and manuals can instead be <br />
                            automatically added to an 'active' consent agenda. If you select <br />
                            an active consent agenda, you must also select one of its agenda <br />
                            items under which incoming document revisions and manuals will <br />
                            be placed.
                        </p>
                        <div>
                            {
                                committee?.MINUTES_DEFAULT_CONTENT_DOCID &&
                                <HybridLink
                                    external
                                    openInNewTab
                                    href={buildDocURL(userBundle.organization!.PREFIX!, committee.MINUTES_DEFAULT_CONTENT_DOCID)}
                                >
                                    <Button
                                        className='h-8 p-2'
                                    >
                                        Edit Minutes Template
                                    </Button>
                                </HybridLink>
                            }
                            {
                                !committee?.MINUTES_DEFAULT_CONTENT_DOCID && committee?.COMMITTEE_ID &&
                                <Button
                                    className='h-8 p-2'
                                    onClick={
                                        () => {
                                            if (!committee.MINUTES_DEPARTMENT_ID) {
                                                setNoMinutesDepartmentId(true);
                                            } else {
                                                setQuery({ ...query, createMinutesTemplate: true });
                                            }
                                        }
                                    }
                                >
                                    Create Minutes Template
                                </Button>
                            }
                        </div>
                    </div>
                </div>
                <div className='pl-24'>
                    <h3>Active Agendas:</h3>
                    <table>
                        <tbody>
                            {
                                activeConsentAgendasData?.consentAgendas.map((consentAgenda, index) => {
                                    const dateStr = createSortableDate(new Date(consentAgenda.MEETING_START_DATE), true);
                                    const docRev = consentAgenda.MINUTES_DOC && consentAgenda.MINUTES_DOC.REVISIONS ?
                                        consentAgenda.MINUTES_DOC.REVISIONS[0] : undefined;
                                    const href = `/docmgr2/consent_agenda_management.pl?organizationid=${userBundle.organization?.ORGANIZATIONID}&consent_agenda_id=${consentAgenda.CONSENT_AGENDA_ID}`;
                                    return <tr
                                        key={`active-consent-agenda-${index}-${dateStr}`}
                                    >
                                        <td>
                                            <a
                                                target='_blank'
                                                href={href}
                                                style={{
                                                    fontFamily: 'Helvetica, monospace'
                                                }}
                                                onClick={
                                                    event => {
                                                        event.preventDefault();
                                                        window.open(
                                                            href,
                                                            'consent_agenda_management' + consentAgenda.CONSENT_AGENDA_ID,
                                                            'dependent,resizable,scrollbars=yes'
                                                        );
                                                    }
                                                }
                                            >
                                                {dateStr}
                                            </a>
                                        </td>
                                        <td>
                                            {
                                                docRev &&
                                                [
                                                    DocumentRevisionStatuses.draft,
                                                    DocumentRevisionStatuses.invisible,
                                                    DocumentRevisionStatuses.prep,
                                                    DocumentRevisionStatuses.preprel,
                                                ].includes(docRev.DOCSTATUS_ID) &&
                                                (isChairPersonOrSecretary || isCollaborator) &&
                                                <a
                                                    target='_blank'
                                                    href={`/docmgr2/editdoc.pl?&wnd=1&ref=${userBundle.organization?.PREFIX}:${consentAgenda.MINUTES_DOCID}$${docRev.REVNO}`}
                                                    style={{
                                                        fontFamily: 'Helvetica, monospace'
                                                    }}
                                                >
                                                    (Edit minutes)
                                                </a>
                                            }
                                            {
                                                docRev &&
                                                [
                                                    DocumentRevisionStatuses.official,
                                                    DocumentRevisionStatuses.pending_committee_approval
                                                ].includes(docRev.DOCSTATUS_ID) &&
                                                <a
                                                    target='_blank'
                                                    href={`/cgi/doc-gw.pl?full=1&wnd=1&ref=${userBundle.organization?.PREFIX}:${consentAgenda.MINUTES_DOCID}`}
                                                    style={{
                                                        fontFamily: 'Helvetica, monospace'
                                                    }}
                                                >
                                                    (View minutes)
                                                </a>
                                            }
                                            {
                                                !consentAgenda.MINUTES_DOCID &&
                                                (isChairPersonOrSecretary || isCollaborator) &&
                                                <p
                                                    onClick={
                                                        () => {
                                                            setQuery({ 
                                                                ...query,
                                                                createAgendaMinutesForAgendaId: consentAgenda.CONSENT_AGENDA_ID
                                                            });                                                            
                                                        }
                                                    }
                                                    style={{
                                                        fontFamily: 'Helvetica, monospace',
                                                        cursor: 'pointer'
                                                    }}
                                                >
                                                    (Create minutes)
                                                </p>
                                            }
                                        </td>
                                    </tr>;
                                })
                            }
                        </tbody>
                    </table>
                </div>
                
            </div>
            <br />
            <div>
                <form className='w-full flex flex-col h-full items-start space-y-4 pt-2'
                    onSubmit={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        form.handleSubmit();
                    }}
                >
                    <Section>
                        <p>Active Consent Agenda:</p>
                        <form.Field
                            name='activeConsentAgendaId'
                            children={(field) => {
                                return <Select
                                    // v is always a string, and the value of the select is parsed.
                                    onValueChange={v => field.handleChange(parseInt(v))}
                                    value={field.getValue()?.toString() ?? '-1'}
                                >
                                    <SelectTrigger 
                                        className="w-full"  
                                        placeholder='Select an active consent agenda...' 
                                    >
                                        <SelectValue placeholder='Select active consent agenda...' />
                                    </SelectTrigger>
                                    <SelectContent>
                                        {activeAgendaOptions.concat([<SelectItem value={'-1'}>None</SelectItem>])}
                                    </SelectContent>
                                </Select>;
                            }}
                        />
                    </Section>
                    <Section>
                        <p>Active Consent Agenda Item:</p>
                        <form.Field
                            name='activeConsentAgendaHeadingId'
                            children={(field) => {
                                return <Select
                                    // v is always a string, and the value of the select is parsed.
                                    onValueChange={v => field.handleChange(parseInt(v))}
                                    value={field.getValue()?.toString() ?? '-1'}
                                >
                                    <SelectTrigger 
                                        className="w-full"
                                        placeholder='Select a section to put the documents under...'    
                                    >
                                        <SelectValue placeholder='Select consent agenda item...' />
                                    </SelectTrigger>
                                    <SelectContent>
                                        {activeAgendaHeaderOptions.map((activeAgendaHeaderOption) => {
                                            return <SelectItem value={`${activeAgendaHeaderOption.value}`}>
                                                {activeAgendaHeaderOption.label}
                                            </SelectItem>;
                                        })}
                                        { activeAgendaHeaderOptions.length === 0 && <p>No agenda items to choose from</p>}
                                    </SelectContent>
                                </Select>;
                            }}
                        />
                        {!validActiveConsentAgendaItem && <p className='text-red-500'>Please select an active consent agenda item</p>}
                    </Section>
                    
                    <form.Subscribe>
                        { 
                            formState => {
                                return <Button
                                    size='sm'
                                    className='h-8 p-2'
                                    type={'submit'}
                                    variant={'default'}
                                    disabled={ formState.isSubmitting }
                                >
                                    {
                                        formState.isSubmitting ?  
                                            <Spinner />
                                            : 'Submit'    
                                    }
                                </Button>;
                            }
                        }
                    </form.Subscribe>
                        
                </form>
                <DataTable
                    columns={columns}
                    toolbar={UnscheduledItemsTableToolbar}
                    ref={dataTableRef}
                    data={formattedUnscheduledItemsTableData}
                    loading={isLoadingUnscheduledItems}
                    renderExpandedRowForTableExport={async () => []}
                    exportAsFileName='unscheduled_items'
                    pagination={PaginationToolsForTable}
                />
            </div>
            <Dialog
                open={noMinutesDepartmentId}
                onOpenChange={() => setNoMinutesDepartmentId(false)}
            >
                <DialogTrigger asChild>
                </DialogTrigger>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>
                            Cannot Create Minutes Template
                        </DialogTitle>
                        <DialogDescription>
                            This committee has no minutes department specified and therefore cannot create a minutes template
                        </DialogDescription>
                    </DialogHeader>
                </DialogContent>
            </Dialog>
            <CreateAgendaDialog 
                onCreate={ () => {
                    refetchActiveAgendas();
                    setQuery({ ...query, createAgendaDialogOpen: undefined });
                } }
            />
            <CreateMinutesDialog 
                onCreate={ 
                    () => {
                        refetchActiveAgendas();
                        setQuery({ ...query, createAgendaMinutesForAgendaId: undefined });
                    }
                }
                onClose={ 
                    () => {
                        refetchActiveAgendas();
                        setQuery({ ...query, createAgendaMinutesForAgendaId: undefined });
                    }
                }
                isOpen={!!query.createAgendaMinutesForAgendaId}
                committee={committee}
            />
            {committee && <CreateMinutesTemplateDialog
                onCreate={
                    () => {
                        refetchActiveAgendas();
                        setQuery({ ...query, createMinutesTemplate: undefined });
                    }
                }
                onClose={
                    () => {
                        refetchActiveAgendas();
                        setQuery({ ...query, createMinutesTemplate: undefined });
                    }
                }
                committee={committee}
                isOpen={!!query.createMinutesTemplate}
            />}
        </>
    );
};
