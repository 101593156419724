import React, { useContext, useEffect, useState } from 'react';

import { LucidocModal } from '../../../../_shared/LucidocModal/LucidocModal';
import { COMMITTEE } from '../../../../../entities/org/COMMITTEE';
import LucidocForm from '../../../../_shared/Forms/LucidocForm/LucidocForm';
import { Typography } from '@material-ui/core';
import Select from 'react-select';
import axios from 'axios';
import { ValueType } from 'react-select/src/types';
import UserBundleContext from '../../../../../context/UserBundleContext';
import SelectUsers from '../../../../_shared/Forms/Select/SelectUsers';
import { RightsCodeKey } from '@/entities/master/PERSON';
import { constructFullName } from '@/components/UserPicker/utils/constructFullName';

interface ICreateMinutesTemplateProps {
    onCreateMinutesTemplate: () => void;
    onClose: () => void;
    committee: COMMITTEE;
}

const templateOptions = [
    {
        label: 'Committee Minutes (HTML)',
        value: 144
    },
    {
        label: 'Committee Minutes (Word)',
        value: 18
    },
    {
        label: 'Committee Minutes (PDF)',
        value: 1
    }
];

const CreateMinutesTemplate: React.FC<ICreateMinutesTemplateProps> = (props: ICreateMinutesTemplateProps) => {
    const context = useContext(UserBundleContext);

    const [loading, setLoading] = useState(false);

    const [title, setTitle] = useState<string>();
    const [ownerUserId, setOwnerUserId] = useState<number>();
    const [mimeTypeId, setMimeTypeId] = useState(144);
    const [collaborators, setCollaborators] = useState<number[]>([]);

    const userOptions = [
        {
            label: props.committee.CHAIRPERSON ? constructFullName(props.committee.CHAIRPERSON, 'firstMiddleLast') : '',
            value: props.committee.CHAIRPERSON!.USERID
        },
        {
            label: props.committee.SECRETARY ? constructFullName(props.committee.SECRETARY, 'firstMiddleLast') : '',
            value: props.committee.SECRETARY!.USERID
        }
    ];

    useEffect(() => {
        if (props.committee && props.committee.MINUTES_DEPARTMENT_ID) {
            setTitle(`${props.committee.NAME} Minutes Template`);
            setOwnerUserId(props.committee.SECRETARY_USERID);
        }
    }, [props.committee]);

    useEffect(() => {
        setCollaborators(userOptions.filter(o => o.value !== ownerUserId).map(o => o.value));
    }, [ownerUserId]);

    const createDefaultMinutesTemplate = async () => {
        const formData = new FormData();
        formData.append('ajax', 'create_minutes_default_content_document');
        formData.append('organizationid', `${context.organization!.ORGANIZATIONID}`);
        formData.append('committee_id', `${props.committee.COMMITTEE_ID}`);
        formData.append('owner_userid', `${ownerUserId}`);
        formData.append('mimetype_id', `${mimeTypeId}`);
        formData.append('collaborator_userids', JSON.stringify(collaborators));

        axios.post(
            '/ajax/committee.pl',
            formData,
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        )
        .then(response => {
            const data = response.data;
            if (data.failure) {
                throw new Error(data.failure);
            }

            if (data.edit_minutes_default_content_document_url) {
                window.open(data.edit_minutes_default_content_document_url, '_blank');
            }

            props.onCreateMinutesTemplate();
        })
        .catch(err => alert(err));
    };

    useEffect(() => {
        if (loading) {
            createDefaultMinutesTemplate().then();
        }
    }, [loading]);

    if (props.committee && !props.committee.MINUTES_DEPARTMENT_ID) {
        return <LucidocModal
            open
            onClose={() => props.onClose()}
            title='Create Minutes Template'
        >
            <Typography>
                This committee has no minutes department specified <br />
                and therefore cannot create a minutes template.
            </Typography>
        </LucidocModal>;
    }

    return <LucidocModal
        open
        onClose={() => props.onClose()}
        title='Create Minutes Template'

        onSave={() => setLoading(true)}

        onCancel={() => props.onClose()}
        isSaveIndicatorVisible={loading}
        setIsSaveIndicatorVisible={setLoading}
        saveIndicatorMessage='Successfully created minutes template'
    >
        <div
            style={{
                backgroundColor: '#fff',
                width: '80vw',
                height: '70vh',
                borderRadius: '.5rem',
                position: 'relative'
            }}
        >
            <LucidocForm
                noHeaderSpacing
                fields={[
                    {
                        name: 'Title',
                        component: <Typography>
                            {title}
                        </Typography>
                    },
                    {
                        name: 'Document Owner',
                        component: <Select
                            key='select-document-owner'
                            value={userOptions.find(o => o.value === ownerUserId)}
                            onChange={
                                (selected: ValueType<{ label: string; value: number; }, false>) => {
                                    setOwnerUserId(!selected ? undefined : selected.value as (number | undefined));
                                }
                            }
                            styles={{
                                control: (provided: object) => ({
                                    ...provided,
                                    width: '28rem'
                                })
                            }}
                            options={userOptions}
                        />
                    },
                    {
                        name: 'Department',
                        component: <Typography>
                            {props.committee.MINUTES_DEPARTMENT?.TITLE}
                        </Typography>
                    },
                    {
                        name: 'Document Type',
                        component: <Typography>
                            Committee Minutes
                        </Typography>
                    },
                    {
                        name: 'Template',
                        component: <Select
                            key='select-document-template-mimetype'
                            value={templateOptions.find(o => o.value === mimeTypeId)}
                            onChange={
                                (selected) => {
                                    if (selected === null) return;
                                    setMimeTypeId(selected.value as number);
                                }
                            }
                            styles={{
                                control: (provided: object) => ({
                                    ...provided,
                                    width: '28rem'
                                })
                            }}
                            options={templateOptions}
                        />
                    },
                    {
                        name: 'Collaborators',
                        component: <SelectUsers
                            userIds={collaborators}
                            onChange={(newCollaborators) => setCollaborators(newCollaborators)}
                            exemptUserIds={ownerUserId ? [ownerUserId] : []}
                            rights={[RightsCodeKey.Author]}
                            getMappedUsers
                        />
                    }
                ]}
            />
        </div>
    </LucidocModal>;

};

export default CreateMinutesTemplate;
