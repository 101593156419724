import { helpTopicDocIDs } from '@/_shared/constants/HelpSystemIDs';
import { HelpTopicContext } from '@/context/HelpTopicContext';
import { DOCUMENTREVISION } from '@/entities/org/DOCUMENTREVISION';
import { useDocumentTitle } from '@/hooks/useDocumentTitle';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/ui/ui/accordion';
import React, { useContext, useEffect } from 'react';
import useSWR from 'swr';

interface TrainingProps {

}



export const Training2: React.FC<TrainingProps> = () => {

    interface TrainingWebinarData {
        trainingWebinarDocs: DOCUMENTREVISION[]
    }

    const { data: trainingWebinarData } = useSWR<TrainingWebinarData>('/api/homepage/training');

    useDocumentTitle('Training Webinars');
    const { setHelpTopicID } = useContext(HelpTopicContext);
    useEffect(() => {
        setHelpTopicID(helpTopicDocIDs.TRAINING);
    }, []);

    return (
        <div className='max-w-4xl pt-4 px-2'>
            <h1 className='text-2xl font-medium'>Training Webinars</h1>
            <Accordion type={'single'} collapsible>
                {trainingWebinarData?.trainingWebinarDocs.map((docrev) => {
                    return (
                        <AccordionItem value={docrev.DOCID.toString()}>
                            <AccordionTrigger>
                                {docrev.TITLE}
                            </AccordionTrigger>
                            <AccordionContent>
                                <div className='text-lg [&>p]:mb-2 [&>p>a]:text-primary-accent' dangerouslySetInnerHTML={{ __html: docrev.SUB_REVISIONS?.[0].ALL_STORE?.HTML || '' }} />
                            </AccordionContent>
                        </AccordionItem>
                    );
                })}
            </Accordion>
          
        </div>

    );
};