import React from 'react';

interface DocumentOutlineProps {
    size: number
}

export const DocumentOutlineIcon: React.FC<DocumentOutlineProps> = ({ size }) => {
    return (
        <svg width={size.toString()} height={(size / 0.9).toString()} viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 4C1 2.34315 2.34315 1 4 1H11.3682C12.0885 1 12.7848 1.25917 13.3298 1.73016L15.9616 4.00453C16.621 4.5744 17 5.40284 17 6.27437V16C17 17.6569 15.6569 19 14 19H4C2.34315 19 1 17.6569 1 16V4Z" stroke="hsl(var(--foreground))" strokeWidth="2" />
            <line x1="5" y1="8.5" x2="13" y2="8.5" stroke="hsl(var(--foreground))" />
            <line x1="5" y1="6.5" x2="7" y2="6.5" stroke="hsl(var(--foreground))" />
            <line x1="10" y1="6.5" x2="13" y2="6.5" stroke="hsl(var(--foreground))" />
            <line x1="5" y1="10.5" x2="13" y2="10.5" stroke="hsl(var(--foreground))" />
            <line x1="5" y1="12.5" x2="13" y2="12.5" stroke="hsl(var(--foreground))" />
            <line x1="5" y1="14.5" x2="13" y2="14.5" stroke="hsl(var(--foreground))" />
        </svg>

    );
};