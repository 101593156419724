import React from 'react';
import { Route, Switch } from 'react-router';
import CopyApprovals from './CopyApprovals/CopyApprovals';
import UserImport from './UserImport/UserImport';
import ReleaseMultiOrgDocuments from './ReleaseMultiOrgDocuments/ReleaseMultiOrgDocuments';
import { Link } from 'react-router-dom';
import './internal-support.css';
import { RenameMultiOrgDocuments } from './RenameMultiOrgDocuments/RenameMultiOrgDocuments';
import { HandleOrgSecurityAccess } from './EnableCertificateLogin/HandleOrgSecurityAccess';
import { FeatureFlagManagement } from './FeatureFlagManagement/FeatureFlagManagement';
import { UpdateAccrediationManagerChecklists } from './UpdateAccrediationManagerChecklists/UpdateAccrediationManagerChecklists';
import { RenameNonMultiOrgDocuments } from './RenameNonMultiOrgDocuments/RenameNonMultiOrgDocuments';
import UpdateGuids from './UpdateGuids/UpdateGuids';
import { AccessListSearchTool } from './AccessListSearchTool/AccessListSearchTool';

function Support() {
    return (
        <div
            className={'support-page-main'}
        >
            <Switch>
                <Route exact path={'/internal-support'} component={SupportMenu} />

                <Route exact path={'/internal-support/copy-approvals'} component={CopyApprovals}/>
                <Route exact path={'/internal-support/handle-org-security-access'} component={HandleOrgSecurityAccess}/>
                <Route exact path={'/internal-support/user-import'} component={UserImport} />
                <Route exact path={'/internal-support/update-guids'} component={UpdateGuids}/>
                <Route exact path={'/internal-support/update-accreditation-manager-checklists'} component={UpdateAccrediationManagerChecklists}/>

                <Route exact path={'/internal-support/release-multi-org-documents'} component={ReleaseMultiOrgDocuments}/>
                <Route exact path={'/internal-support/rename-multi-org-documents'} component={RenameMultiOrgDocuments}/>
                <Route exact path={'/internal-support/rename-documents'} component={RenameNonMultiOrgDocuments}/>

                <Route exact path={'/internal-support/access-list-search'} component={AccessListSearchTool} />
                {/* Route to cause on error on purpose */}
                <Route exact path={'/internal-support/sentry'} component={() => {
                    throw new Error('This is a test error');
                }}/>

                {/* featureflag management needs to exist in react router, but the link is available in system menu. */}
                <Route exact path={'/internal-support/feature-flag-management'} component={FeatureFlagManagement} />

            </Switch>
        </div>
    );
}

interface MenuItemContent {
    name: string
    url: string
}

const menuItems: MenuItemContent[] = [
    {
        name: 'Copy Approvals',
        url: 'copy-approvals'
    },
    {
        name: 'Handle Org Security Access (Beta Access and Certificate Login)',
        url: 'handle-org-security-access'
    },
    {
        name: 'Update GUIDs',
        url: 'update-guids'
    },
    {
        name: 'User Import',
        url: 'user-import'
    },
    {
        name: 'Rename Non-Multi-Org Documents',
        url: 'rename-documents'
    },
    {
        name: 'Access List Search Tool',
        url: 'access-list-search'
    },
];

const multiOrgMenuItems: MenuItemContent[] = [
    {
        name: 'Release Multi-Org Documents',
        url: 'release-multi-org-documents'
    },
    {
        name: 'Rename Multi-Org Documents (and change DocType)',
        url: 'rename-multi-org-documents'
    }
];


function SupportMenu() {
    return (
        <div>
            <h3>Support</h3>
            <ul>
                {menuItems.map(item => (
                    <li>
                        <Link to={`/internal-support/${item.url}`}>
                            {item.name}
                        </Link>
                    </li>
                ))}
            </ul>

            <h3>Multi-Org Functions:</h3>
            <ul>
                {multiOrgMenuItems.map(item => (
                    <li>
                        <Link to={`/internal-support/${item.url}`}>
                            {item.name}
                        </Link>
                    </li>
                ))}
                
            </ul>
        </div>
    );
}

export default Support;
