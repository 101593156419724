import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { DragDropContext } from 'react-beautiful-dnd';
import UserPicker from '../../../UserPicker/UserPicker';
import { PERSON } from '../../../../entities/master/PERSON';
import { USERGROUP } from '../../../../entities/org/USERGROUP';
import { makeStyles } from '@material-ui/core/styles';
import SaveBar from '../../../_shared/SaveBar/SaveBar';
import { LoadingIndicator } from '../../../_shared/LoadingIndicator';
import { LoadingStatuses } from '../../../../utils/LoadingStatuses';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import LucidocColors from '../../../../constants/LucidocColors';
import { TemporaryFavoritesStar } from '../../../_shared/TemporaryFavoritesStar/TemporaryFavoritesStar';
import { setTitleInAdmin } from '../../../../utils/setTitleInAdmin';
import { redirectToHomepage } from '../../../../utils/savebarUtilities';
import setWindowTitle from '../../../../utils/setWindowTitle';

const useStyles = makeStyles({
    mainDiv: {
        padding: '1rem',
        width: '532px',
        boxSizing: 'border-box',
    },
    smallHelpText: {
        fontSize: '.85rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
        marginBottom: '1rem',
    },
    hr: {
        margin: '1rem 0',
        color: LucidocColors.gray
    },
    selectApprovalRoutersHeader: {
        marginBottom: '1rem',
    },
});

export default function EditApprovalRouters() {
    const classes = useStyles();


    const [ loadingStatus, setLoadingStatus ] = useState(LoadingStatuses.LOADING);
    const [ approvalRouters, setApprovalRouters ] = useState<PERSON[]>([]);
    const [ routingReminderEnabled, setRoutingReminderEnabled ] = useState<boolean>(false);
    const [ docownerCanEditRoute, setDocownerCanEditRoute ] = useState<boolean>(false);

    useEffect(() => {
        const newTitle = 'Manage Approval Routing';
        setWindowTitle(newTitle);
        setTitleInAdmin(newTitle);
        loadApprovalRouters().then();
    }, []);

    async function loadApprovalRouters() {
        setLoadingStatus(LoadingStatuses.LOADING);
        const res = await axios.get('/api/administration/organization/approval-routers/get-routers');

        if (   res.data.routers
            && res.data.organization
        ) {
            setApprovalRouters(res.data.routers);
            setRoutingReminderEnabled(res.data.organization.ROUTING_REMINDER_ENABLED);
            setDocownerCanEditRoute(res.data.organization.DOCOWNER_CAN_EDIT_ROUTE);
        }

        setLoadingStatus(LoadingStatuses.READY);
    }

    async function updateApprovalRouters() {
        setLoadingStatus(LoadingStatuses.SAVING);
        const res = await axios.post('/api/administration/organization/approval-routers/update-routers', {
            approvalRouters,
            routingReminderEnabled,
            docownerCanEditRoute
        });

        res.data.success && await loadApprovalRouters();
    }

    if (loadingStatus !== LoadingStatuses.READY) {
        return (
            <LoadingIndicator
                loadingStatus={loadingStatus}
            />
        );
    }

    return (
        <div>
            <div style={{ float: 'right' }}>
                <TemporaryFavoritesStar targetPathName={'admin/admin.pl?repname=edit_approval_routers'} />
            </div>

            <div className={classes.mainDiv}>
                <span className={classes.smallHelpText}>
                    <p>
                        Approval Routers decide which signers and committees a document revision
                        must seek approval from when the document owner sends the document revision
                        for approval.
                    </p>
                    <p>
                        The responsibility of routing falls to document owners if no Approval Routers
                        are specified.
                    </p>
                </span>

                <h3 className={classes.selectApprovalRoutersHeader}>Select Approval Routers:</h3>

                <DragDropContext onDragEnd={() => {}}>
                    <UserPicker
                        getUsers={true}
                        addUserSignature={(newUsers: (PERSON | USERGROUP)[]) => setApprovalRouters(newUsers as PERSON[])}
                        removeUserSignature={(newUsers: (PERSON | USERGROUP)[]) => setApprovalRouters(newUsers as PERSON[])}
                        selectedUsersPlusGroups={approvalRouters}
                        isDragDisabled={true}
                    />
                </DragDropContext>

                <hr className={classes.hr} />

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={routingReminderEnabled}
                            onChange={() => setRoutingReminderEnabled(!routingReminderEnabled)}
                            style={{ color: LucidocColors.purple }}
                        />
                    }
                    label={
                        <span style={{ fontFamily: 'Quattrocento Sans' }}>
                            Send nightly reminders for items pending routing
                        </span>
                    }
                />

                <br />
                <br />

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={docownerCanEditRoute}
                            onChange={() => setDocownerCanEditRoute(!docownerCanEditRoute)}
                            style={{ color: LucidocColors.purple }}
                        />
                    }
                    label={
                        <span style={{ fontFamily: 'Quattrocento Sans' }}>
                            Enable document and manual owners to configure approval routes in the Approval Route Editor
                        </span>
                    }
                />

            </div>

            <SaveBar
                onSave={() => updateApprovalRouters()}
                onCancel={() => loadApprovalRouters()}
                onClose={() => redirectToHomepage()}
            />
        </div>
    );
}
