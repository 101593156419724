import {
    Column,
    Entity,
    OneToMany,
    PrimaryColumn,
} from 'typeorm';
import { CHECKLIST_CONDITION_AUDIT,  } from './CHECKLIST_CONDITION_AUDIT';
import { SchemaEntityManager } from '../SchemaEntityManager';

export enum ChecklistConditionStatuses {
    'Failed' = 'Failed',
    'Failed, Pending Corrections' = 'Failed, Pending Corrections',
    'Failed, Pending QA' = 'Failed, Pending QA',
    'Inspection Ready' = 'Inspection Ready',
    'Needs Editing' = 'Needs Editing',
    'Not Applicable' = 'Not Applicable',
    'Passed' = 'Passed'
}

// Factory function to create CHECKLIST_CONDITION entity with dynamic schema
function createChecklistConditionEntity(schemaName: string, manager: SchemaEntityManager) {
    @Entity('CHECKLIST_CONDITION', { schema: 'ACCREDITATIONMANAGER' })
    class CHECKLIST_CONDITION {
        static SCHEMANAME = schemaName;
        @PrimaryColumn('number', {
            nullable: false,
            name: 'CHECKLIST_CONDITION_ID'
        })
        CHECKLIST_CONDITION_ID!: number;

        @Column('number', {
            nullable: false,
            name: 'CHECKLIST_EDITION_ID'
        })
        CHECKLIST_EDITION_ID!: number;

        @Column('number', {
            nullable: true,
            name: 'ACCREDITOR_CONDITION_ID'
        })
        ACCREDITOR_CONDITION_ID!: number | null;

        @Column('varchar2', {
            nullable: false,
            length: 32,
            name: 'EDITION_STATUS'
        })
        EDITION_STATUS!: string;

        @Column('varchar2', {
            nullable: false,
            length: 32,
            name: 'AUDIT_STATUS'
        })
        AUDIT_STATUS!: string;

        @Column('number', {
            nullable: true,
            name: 'PREV_CHECKLIST_CONDITION_ID'
        })
        PREV_CHECKLIST_CONDITION_ID!: number | null;

        @Column('clob', {
            nullable: true,
            name: 'EVIDENCE'
        })
        EVIDENCE!: string | null;

        @Column('clob', {
            nullable: true,
            name: 'DIFFERENCES'
        })
        DIFFERENCES!: string | null;

        @OneToMany(
            () => {
                return manager.getChecklistConditionAuditEntity(schemaName);
            },
            (checklistConditionAudit) => checklistConditionAudit.CHECKLIST_CONDITION
        )
        CHECKLIST_CONDITION_AUDITS!: CHECKLIST_CONDITION_AUDIT[] | null;
    }
    return CHECKLIST_CONDITION;
}

// Type definition for the return type of the factory function
export type CHECKLIST_CONDITION = InstanceType<ReturnType<typeof createChecklistConditionEntity>>;

export { createChecklistConditionEntity };
