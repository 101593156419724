import * as React from 'react';

import { cn } from '@/lib/utils';
import { VariantProps, cva } from 'class-variance-authority';


const inputVariants = cva(
    'flex h-10 w-full bg-background px-2 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent focus-visible:outline-none file:text-sm file:font-medium placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:bg-muted',
    {
        variants: {
            hSize: {
                md: 'h-7',
                lg: 'h-10'
            },
            variant: {
                default: '',
                ghost: 'bg-transparent',
            }
        },
        defaultVariants: {
            hSize: 'md'
        }
    }
);


export interface InputProps
    extends React.InputHTMLAttributes<HTMLInputElement>, VariantProps<typeof inputVariants> {
    icon?: React.ReactNode;

}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
    ({ className, type, icon, ...props }, ref) => {
        return (
            <div
                className={
                    cn(
                        'bg-background border-2 border-border rounded-md flex flex-row items-center px-1 has-[:focus]:border-primary has-[:focus]:ring-ring has-[:focus]:ring-offset-2 transition-colors duration-200 ease-in-out',
                        props.disabled && 'cursor-not-allowed bg-muted',
                        className
                    )
                }
            >
                {icon && icon}
                <input
                    type={type}
                    className={cn(
                        inputVariants({ hSize: props.hSize, variant: props.variant }),
                    )}
                    ref={ref}
                    {...props}
                />
            </div>
        );
    }
);
Input.displayName = 'Input';

interface InputWithLabelProps extends InputProps {
    label: string
    containerClassName?: string
}

const InputWithLabel = React.forwardRef<HTMLInputElement, InputWithLabelProps>
    (({ label, containerClassName, ...props }, ref) => {
        return <div className={cn('w-full', containerClassName)}>
            <p className='text-xs font-semibold uppercase text-secondary'>{label}</p>
            <Input ref={ref} {...props} />
        </div>;
    }
    );

export { Input, InputWithLabel };
