import React, { useEffect, useState } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import axios from 'axios';
import { DOCUMENTREVISION } from '../../../../entities/org/DOCUMENTREVISION';
import { ORGANIZATION } from '../../../../entities/master/ORGANIZATION';
import { LoadingIndicator } from '../../LoadingIndicator';
import { LoadingStatuses } from '../../../../utils/LoadingStatuses';
import { buildDocURL } from '../../../../_shared/utils/docManagerFunctions';
import { LINK } from '../../../../entities/master/LINK';

type ExpandedDocrevLinksProps = {
    docrevID: number
}

type DocRevLink = {
    docRev: DOCUMENTREVISION
    organization: ORGANIZATION
}

export function ExpandedDocrevLinks(props: ExpandedDocrevLinksProps) {

    const [ loadingStatus, setLoadingStatus ] = useState<LoadingStatuses>(LoadingStatuses.LOADING);
    const [links, setLinks] = useState<DocRevLink[]>([]);
    const [regularLinks, setRegularLinks] = useState<LINK[]>([]);

    useEffect(() => {
        loadLinks().then();
    }, []);

    async function loadLinks() {
        const res = await axios.get('/api/_shared/tables/get-docrev-links-for-table', {
            params: {
                docrevID: props.docrevID
            }
        });

        setLinks(res.data.links);
        setRegularLinks(res.data.regularLinks);
        setLoadingStatus(LoadingStatuses.READY);
    }

    const totalNumberOfLinks = links.length + regularLinks.length;

    return (
        <TableRow>
            <TableCell> </TableCell>
            <TableCell> </TableCell>
            <TableCell> </TableCell>

            <TableCell>
                {loadingStatus !== LoadingStatuses.READY &&
                    <LoadingIndicator />
                }

                {loadingStatus === LoadingStatuses.READY && totalNumberOfLinks === 0 &&
                    <span style={{ color: 'grey' }}>(No links found)</span>
                }

                {loadingStatus === LoadingStatuses.READY && totalNumberOfLinks > 0 &&
                    <div style={{ marginBottom: '1rem' }}>
                        <p>Links:</p>
                        <ul>

                        {links.map((link, idx) => {
                            const docURL = buildDocURL(link.organization.PREFIX!, link.docRev.DOCID);

                            return (
                                <li key={idx}>
                                    <a
                                        href={docURL}
                                        target={'_blank'}
                                        rel={'noopener noreferrer'}
                                        style={{ textDecoration: 'none' }}
                                    >
                                        {link.docRev.TITLE}
                                    </a>
                                </li>
                            );
                        })}

                        {regularLinks.map((link, idx) => {
                            if (!link.REFERENCED_URL) {
                                return null;
                            }

                            return (
                                <li key={links.length + idx}>
                                    <a
                                        href={link.REFERENCED_URL}
                                        target={'_blank'}
                                        rel={'noopener noreferrer'}
                                        style={{ textDecoration: 'none' }}
                                    >
                                        {link.REFERENCED_URL_TITLE}
                                    </a>
                                </li>
                            );
                        })}
                        </ul>
                    </div>
                }
            </TableCell>

            {/* colSpan can be too high a number and it'll work fine to fill in the TableRow all the way to the end */}
            <TableCell colSpan={10}> </TableCell>
        </TableRow>
    );
}
