import React from 'react';

export interface HelpTopicContextType {
    helpTopicID: number | null
    setHelpTopicID: (helpTopicID: number | null) => void
}

export const HelpTopicContext = React.createContext<HelpTopicContextType>({
    helpTopicID: null,
    setHelpTopicID: () => {},
});
