import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { LoadingIndicator } from '../../_shared/LoadingIndicator';
import { LoadingStatuses } from '../../../utils/LoadingStatuses';
import { makeStyles, TextField } from '@material-ui/core';
import SaveBar from '../../_shared/SaveBar/SaveBar';
import H3WithHelpTip from '../../_shared/Forms/H3WithHelpTip';

const useStyles = makeStyles({
    mainDiv: {
        padding: '1rem',
        margin: '0 auto',
    },
    domainsGridContainer: {
        width: '1000px',
        border: '1px solid lightgrey',
        padding: '0 1rem',
    },
    domainInfoRow: {
        display: 'grid',
        gridTemplateColumns: '3fr 1fr 3fr'
    }
});

export function EmailManagement() {
    const classes = useStyles();

    const [ loadingStatus, setLoadingStatus ] = useState<LoadingStatuses>(LoadingStatuses.LOADING);
    const [ domainCounts, setDomainCounts ] = useState<{Domain: string, Count: number}[]>([]);
    const [ domainsToUpdate, setDomainsToUpdate ] = useState<{[key:string]: string}>({});

    useEffect(() => {
        getDomains().then();
    }, []);

    async function getDomains() {
        setLoadingStatus(LoadingStatuses.LOADING);

        const res = await axios.get('/api/administration/users/manage-emails/get-email-domains');

        setDomainCounts(res.data.emailDomainCounts);

        setLoadingStatus(LoadingStatuses.READY);
    }

    function handleChangeToDomainsToUpdate(oldDomain: string, newDomain: string) {
        const newObj = { ...domainsToUpdate };

        newObj[oldDomain] = newDomain.trim();

        // empty strings will be handled on teh back end. React doesn't seem to update
        // TextField value={something} if the value is deleted from the domainsToUpdate
        // object altogether; storing them as blank strings does control the TextField
        // input, so we'll let it do that.
        setDomainsToUpdate(newObj);
    }

    async function saveDomainUpdates() {
        setLoadingStatus(LoadingStatuses.SAVING);

        const res = await axios.post('/api/administration/users/manage-emails/save-email-domain-updates', {
            domainsToUpdate
        });

        if (res.data.error) alert('Unable to save');

        setDomainsToUpdate({});
        await getDomains(); // will set loading status ready
    }

    if (loadingStatus !== LoadingStatuses.READY) {
        return <LoadingIndicator loadingStatus={loadingStatus} />;
    }

    return (
        <div className={classes.mainDiv}>
            <H3WithHelpTip
                helpText={'Edit these emails to remove outdated or incorrect email domains'}
                text={'Email domains currently in use'}
            />

            <div className={classes.domainsGridContainer}>
                <div className={classes.domainInfoRow}>
                    <h4>Domain</h4>
                    <h4>Count</h4>
                    <h4>Set New Domain</h4>
                </div>

                {domainCounts.map((row, idx) => {
                    const oldDomain = row.Domain;

                    return (
                        <div className={classes.domainInfoRow} key={idx}>
                            <p>{row.Domain}</p>
                            <p>{row.Count}</p>
                            <TextField
                                value={domainsToUpdate[oldDomain]}
                                variant={'outlined'}
                                size={'small'}
                                onChange={e => handleChangeToDomainsToUpdate(oldDomain, e.currentTarget.value)}
                            />
                        </div>
                    );
                })}
            </div>

            <SaveBar
                onSave={() => saveDomainUpdates()}
                isSaveDisabled={!Object.keys(domainsToUpdate).find(domain => !!domainsToUpdate[domain])}

                onCancel={() => getDomains()}
            />
        </div>
    );
}
