import {
    Column,
    Entity,
    JoinTable,
    ManyToMany,
    OneToMany,
    PrimaryColumn,

} from 'typeorm';

import { PERSON,  } from '../master/PERSON';
import { ACL,  } from './ACL';
import { SIGNERASSIGNMENT,  } from './SIGNERASSIGNMENT';
import { REVIEWERASSIGNMENT,  } from './REVIEWERASSIGNMENT';
import { SchemaEntityManager } from '../SchemaEntityManager';

function createUserGroupEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('USERGROUP', { schema: schema })
    class USERGROUP {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'USERGROUPID'
        })
        USERGROUPID!: number;

        @Column('varchar2', {
            nullable: false,
            length: 50,
            default: () => '\'active\'',
            name: 'STATUS'
        })
        STATUS!: string;

        @Column('varchar2', {
            nullable: false,
            length: 40,
            name: 'NAME'
        })
        NAME!: string;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'PARENT_USERGROUPID'
        })
        PARENT_USERGROUPID!: number | null;

        @Column('varchar2', {
            nullable: true,
            length: 90,
            name: 'DESCRIPTION'
        })
        DESCRIPTION!: string | null;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'USERID'
        })
        USERID!: number | null;

        @Column('char', {
            nullable: true,
            length: 32,
            name: 'RIGHTS'
        })
        RIGHTS!: string | null;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'LOCATIONID'
        })
        LOCATIONID!: number | null;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'ORGCHARTID'
        })
        ORGCHARTID!: number | null;

        @Column('char', {
            nullable: true,
            name: 'ISORGOWNER'
        })
        ISORGOWNER!: string | null;

        @Column('raw', {
            nullable: true,
            length: 32,
            name: 'GUID'
        })
        GUID!: Buffer | null;

        @ManyToMany(
            () => manager.getPersonEntity(schema),
            (person) => person.USERGROUPS
        )
        @JoinTable({
            name: 'PERSONUSERGROUP',
            joinColumn: {
                name: 'USERGROUPID',
                referencedColumnName: 'USERGROUPID'
            },
            inverseJoinColumn: {
                name: 'USERID',
                referencedColumnName: 'USERID'
            }
        })
        PEOPLE!: PERSON[] | null;

        @OneToMany(
            () => manager.getAclEntity(schema), 
            (acl) => acl.USERGROUP
        )
        ACL!: ACL[] | null;

        @OneToMany(
            () => manager.getSignerAssignmentEntity(schema),
            (signerAssignment) => signerAssignment.USERGROUP
        )
        SIGNERASSIGNMENTS!: SIGNERASSIGNMENT[] | null;

        @OneToMany(
            () => manager.getReviewerAssignmentEntity(schema), 
            (reviewerAssignment) => reviewerAssignment.USERGROUP
        )
        REVIEWERASSIGNMENTS!: REVIEWERASSIGNMENT[] | null;

    }

    return USERGROUP;
}

function isUserGroup(potentialGroup: PERSON | USERGROUP): potentialGroup is USERGROUP {
    return (potentialGroup as USERGROUP).USERGROUPID !== undefined;
}

export { createUserGroupEntity, isUserGroup };
export type USERGROUP = InstanceType<ReturnType<typeof createUserGroupEntity>>;