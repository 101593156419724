import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import DataTable from '../../../_shared/DataTable/DataTable';
import { MUIDataTableColumn, MUIDataTableProps } from 'mui-datatables';
import UserBundleContext from '../../../../context/UserBundleContext';
import produceFlattenedOptionsFromNestedInformationHierarchy from '../../../../utils/produceFlattenedOptionsFromNestedInformationHierarchy';
import { Button, Checkbox, FormControlLabel, makeStyles } from '@material-ui/core';
import { LoadingIndicator } from '../../../_shared/LoadingIndicator';
import { LoadingStatuses } from '../../../../utils/LoadingStatuses';
import Subheader from '../../../_shared/Subheader/Subheader';
import SubheaderItem from '../../../_shared/Subheader/SubheaderItem';
import { transformManualsToReadableDataTableValues } from '../../../_shared/DataTable/transformers/transformManualsToReadableDataTableValues';
import { setTitleInAdmin } from '../../../../utils/setTitleInAdmin';
import { LucidocModal } from '../../../_shared/LucidocModal/LucidocModal';
import { EditInfoHierarchy } from '../InfoHierarchy/EditInfoHierarchy';
import { INFORMATION_HIERARCHY, createInformationHierarchyEntity } from '../../../../entities/org/INFORMATION_HIERARCHY';
import { findNestedIH } from '../../../../utils/findNestedIH';
import { Add } from '@material-ui/icons';
import LucidocColors from '../../../../constants/LucidocColors';
import SaveBar from '../../../_shared/SaveBar/SaveBar';
import { redirectToHomepage } from '../../../../utils/savebarUtilities';
import { schemaEntityManager } from '../../../../utils/emInstance';
import setWindowTitle from '../../../../utils/setWindowTitle';

const useStyles = makeStyles({
    manualTitle: {
        cursor: 'pointer',
        color: 'blue',
        whiteSpace: 'pre'
    },
    lastElementsDiv: {
        display: 'flex',
        alignItems: 'center',
    }
});

type ManageManualsProps = {
    isInAdmin?: boolean
}

export function ManageManuals(props: ManageManualsProps) {
    const classes = useStyles();

    const context = useContext(UserBundleContext);

    const Tabs: { [key: string]: string } = props.isInAdmin
        ? {
            'All (Admin)': 'All (Admin)'
        }
        : {
            'All': 'All',
            'Owned By Me': 'Owned By Me',
            'Collaborate On': 'Collaborate On',
        };

    const [selectedTab, setSelectedTab] = useState<string>(props.isInAdmin
        ? Tabs['All (Admin)']
        : Tabs['All']
    );

    const [loadingStatus, setLoadingStatus] = useState(LoadingStatuses.READY);
    const [activeOnly, setActiveOnly] = useState<boolean>(true);
    const [manuals, setManuals] = useState<INFORMATION_HIERARCHY[]>([]);
    const [selectedManual, setSelectedManual] = useState<INFORMATION_HIERARCHY>();
    const [tableData, setTableData] = useState<MUIDataTableProps['data']>([]);

    const [allViewers, setAllViewers] = useState<string[]>([]);
    const [allCollaborators, setAllCollaborators] = useState<string[]>([]);

    useEffect(() => {
        const newTitle = `Manage ${context.organization?.MANUALNAMEPLURAL || 'Manuals'}`;
        setWindowTitle(newTitle);
        setTitleInAdmin(newTitle);
    }, []);

    useEffect(() => {
        loadManuals().then();
    }, [
        selectedTab,
        activeOnly
    ]);

    useEffect(() => {
        const viewerSet = new Set<string>();
        const collaboratorSet = new Set<string>();

        tableData.forEach(row => {
            (row as any).Viewers?.split('\n').forEach((name: string) => {
                if (!viewerSet.has(name)) viewerSet.add(name);
            });
            (row as any).Collaborators?.split('\n').forEach((name: string) => {
                if (!collaboratorSet.has(name)) collaboratorSet.add(name);
            });
        });

        setAllViewers(Array.from(viewerSet));
        setAllCollaborators(Array.from(collaboratorSet));

    }, [tableData]);

    async function loadManuals() {
        setLoadingStatus(LoadingStatuses.LOADING);

        const res = props.isInAdmin
            ? await axios.get('/api/administration/manuals/get-manuals', {
                params: {
                    activeOnly
                }
            })
            : await axios.get('/api/doc-manager/manual/manage-manuals/get-manuals', {
                params: {
                    selectedTab
                }
            });

        setManuals(res.data.manuals || []);

        setTableData(
            transformManualsToReadableDataTableValues(
                produceFlattenedOptionsFromNestedInformationHierarchy(
                    res.data.manuals || [],
                    [],
                    0,
                    true,
                    ' '.repeat(4)
                ),
                res.data.manualIDtoManualMap,
                res.data.approvalsFromManualID,
            )
        );

        setLoadingStatus(LoadingStatuses.READY);
    }

    return (
        <div>
            <Subheader
                value={selectedTab}
                onChange={value => setSelectedTab(value)}
                lastElement={props.isInAdmin
                    ? (
                        <div className={classes.lastElementsDiv}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={activeOnly}
                                        onChange={() => setActiveOnly(!activeOnly)}
                                        style={{ color: LucidocColors.purple }}
                                    />
                                }
                                label={
                                    <span style={{ fontFamily: 'Quattrocento Sans' }}>
                                        Active Only
                                    </span>
                                }
                            />
                            <Button
                                startIcon={<Add />}
                                onClick={() => setSelectedManual(new (createInformationHierarchyEntity('', schemaEntityManager)))}
                            >
                                Create New
                            </Button>
                        </div>
                    ) : undefined
                }
            >
                {Object.keys(Tabs).map(tab => <SubheaderItem key={Tabs[tab]} label={Tabs[tab]} value={Tabs[tab]} />)}
            </Subheader>

            {loadingStatus !== LoadingStatuses.READY &&
                <LoadingIndicator loadingStatus={loadingStatus} />
            }

            {loadingStatus === LoadingStatuses.READY &&
                <>
                    <DataTable
                        muiDataTableProps={{
                            title: context.organization?.MANUALNAMEPLURAL || 'Manuals',
                            columns: tableData[0] && Object.keys(tableData[0]).map(key => {
                                const returnObj: MUIDataTableColumn = {
                                    name: key
                                };

                                if (key === 'ID') {
                                    returnObj.options = {
                                        display: false,
                                        filter: false,
                                    };
                                }

                                if (key === 'Name') {
                                    returnObj.options = {
                                        customBodyRender: (value, tableMeta) => {
                                            const manualID = tableMeta.rowData[0];

                                            return (
                                                <span
                                                    className={classes.manualTitle}
                                                    onClick={() => {
                                                        if (props.isInAdmin) {
                                                            setSelectedManual(findNestedIH(manuals, manualID));
                                                        }
                                                        else {
                                                            // this is mostly a stopgap until the EditInfoHierarchy.tsx modal popup adds
                                                            // the last couple items present in editcat.pl (for manuals specifically), which
                                                            // are Topical Index and Acknowledgements; then we can get rid of editcat.pl
                                                            // and load via setSelectedManual as shown above:
                                                            window.open(
                                                                '/docmgr2/editcat.pl?cat=' + manualID
                                                                , 'editcat_' + manualID
                                                                , 'menubar=no,dependent,resizable'
                                                            );
                                                        }
                                                    }}
                                                >
                                                    {value}
                                                </span>
                                            );
                                        }
                                    };
                                }

                                if (key === 'Collaborators') {
                                    returnObj.options = {
                                        filterOptions: {
                                            names: allCollaborators,
                                            logic: (value, selectedCollaborators) => {
                                                return !selectedCollaborators.find(collaborator => value.includes(collaborator));
                                            },
                                        },
                                        customBodyRender: (value, tableMeta) => {
                                            const collaborators = tableMeta.rowData[4];

                                            return (
                                                <span style={{ whiteSpace: 'pre' }}>
                                                    {collaborators}
                                                </span>
                                            );
                                        }
                                    };
                                }

                                if (key === 'Viewers') {
                                    returnObj.options = {
                                        filterOptions: {
                                            names: allViewers,
                                            logic: (value, selectedViewers) => {
                                                return !selectedViewers.find(viewer => value.includes(viewer));
                                            },
                                        },
                                        customBodyRender: (value, tableMeta) => {
                                            const viewers = tableMeta.rowData[6];

                                            return (
                                                <span style={{ whiteSpace: 'pre' }}>
                                                    {viewers}
                                                </span>
                                            );
                                        }
                                    };
                                }

                                if (key === 'View Approvals') {
                                    returnObj.options = {
                                        filter: false,
                                        customBodyRender: (value, tableMeta) => {
                                            const approvals = tableMeta.rowData[9];

                                            return (
                                                <span style={{ whiteSpace: 'pre' }}>
                                                    {approvals}
                                                </span>
                                            );
                                        }
                                    };
                                }

                                return returnObj;
                            }),
                            data: tableData,
                            options: {
                                rowsPerPage: 100,
                                downloadOptions: {
                                    filterOptions: {
                                        useDisplayedColumnsOnly: false
                                    }
                                },
                            }
                        }}
                        onRefresh={() => loadManuals()}
                    />
                    <SaveBar
                        onClose={() => redirectToHomepage()}
                    />
                </>
            }

            {selectedManual &&
                <LucidocModal
                    title={`Edit ${context.organization?.MANUALNAME || 'Manual'}: ${selectedManual.INFORMATION_HIERARCHY_ID ? selectedManual?.TITLE : 'Create new'}`}
                    open={!!selectedManual}
                    onClose={() => setSelectedManual(undefined)}
                    removePadding={true}
                >
                    <EditInfoHierarchy
                        ihType={'manual'}
                        ih={selectedManual}
                        sendIhToParent={manual => {
                            setSelectedManual(manual);
                            loadManuals().then();
                        }}
                        isInAdmin={props.isInAdmin ?? false}
                        closeModal={() => setSelectedManual(undefined)}
                    />
                </LucidocModal>
            }
        </div>
    );
}
