import React, { useContext, useEffect, useState } from 'react';
import Subheader from '../../../../_shared/Subheader/Subheader';
import SubheaderItem from '../../../../_shared/Subheader/SubheaderItem';
import DepartmentBacklog from './DepartmentBacklog';
import ItemsPendingApproval from '../ItemsPendingApproval';
import { ApprovalSessionStatuses, ApprovalSessionTypes } from '../../../../../entities/org/APPROVAL_SESSION';
import { DocumentRevisionStatuses } from '../../../../../entities/org/DOCUMENTREVISION';
import { INFORMATION_HIERARCHY } from '../../../../../entities/org/INFORMATION_HIERARCHY';
import Select from 'react-select';
import produceFlattenedOptionsFromNestedInformationHierarchy
    from '../../../../../utils/produceFlattenedOptionsFromNestedInformationHierarchy';
import { NotificationWindowTypes } from '../../../../../entities/org/NOTIFICATION_WINDOW';
import UserBundleContext from '../../../../../context/UserBundleContext';
import hasRights from '../../../../../_shared/utils/hasRights';
import axios from 'axios';
import { redirectToHomepage } from '../../../../../utils/savebarUtilities';
import SaveBar from '../../../../_shared/SaveBar/SaveBar';
import { RightsCodeKey } from '@/entities/master/PERSON';

export default function CRDepartments() {
    const Tabs: { [key: string]: string } = {
        DepartmentBacklog: 'Department Backlog',
        PendingSignature: 'Pending Signature',
        PendingApproval: 'Pending Approval',
        PendingAdditional: 'Pending Additional',
        PendingRelease: 'Pending Release',
        PendingRouting: 'Pending Routing',
        PendingReview: 'Pending Review',
        PendingArchive: 'Pending Archive',
        PendingExpiration: 'Pending Expiration',
    };

    const context = useContext(UserBundleContext);
    const userIsAdmin = hasRights(context, [RightsCodeKey.DocumentAdministrator, RightsCodeKey.UserAdministrator, RightsCodeKey.OrganizationAdministrator]);

    const [selectedTab, setSelectedTab] = useState<string>(userIsAdmin ? Tabs.DepartmentBacklog : Tabs.PendingSignature);
    const [flattenedDepartments, setFlattenedDepartments] = useState<INFORMATION_HIERARCHY[]>([]);
    const [selectedDepartment, setSelectedDepartment] = useState<INFORMATION_HIERARCHY | undefined>(undefined);

    async function getDepartments() {
        const res = await axios.get('/api/doc-manager/compliance-reports/get-department-backlog/get-departments-only');

        setFlattenedDepartments(produceFlattenedOptionsFromNestedInformationHierarchy(
            res.data.departments,
            [],
            0,
            true
        ));
    }

    useEffect(() => {
        // Backlog loads this data anyway, so we only need to load it if we're not loading the backlog
        if (!userIsAdmin) {
            getDepartments().then();
        }
    }, []);

    const selectMenu = selectedTab !== Tabs.DepartmentBacklog ? (
        <Select
            value={selectedDepartment
                ? {
                    label: selectedDepartment.TITLE || '',
                    value: selectedDepartment.INFORMATION_HIERARCHY_ID
                }
                : {
                    label: 'All Departments',
                    value: 0
                }
            }
            options={
                [{ TITLE: 'All Departments', INFORMATION_HIERARCHY_ID: 0 }]
                    .concat(flattenedDepartments)
                    .map(department => {
                        return {
                            label: department.TITLE,
                            value: department.INFORMATION_HIERARCHY_ID
                        };
                    })
            }
            onChange={(selectMenuOption: any) => setSelectedDepartment(flattenedDepartments.find(dep => dep.INFORMATION_HIERARCHY_ID === selectMenuOption.value))}
        />
    ) : undefined;

    return (
        <div>
            <Subheader
                value={selectedTab}
                onChange={value => setSelectedTab(value)}
                lastElement={
                    <div style={{ width: '12rem' }}>
                        {selectMenu}
                    </div>
                }
            >
                {Object.keys(Tabs)
                    .filter(tab => userIsAdmin ? true : !tab.includes('Backlog'))
                    .map(tab => <SubheaderItem key={Tabs[tab]} label={Tabs[tab]} value={Tabs[tab]} buttonWidth={'6rem'} />)
                }
            </Subheader>

            {selectedTab === Tabs.DepartmentBacklog &&
                <DepartmentBacklog
                    sendDepartmentsToParent={(departments) => setFlattenedDepartments(produceFlattenedOptionsFromNestedInformationHierarchy(
                        departments,
                        [],
                        0,
                        true
                    ))
                    }
                    onSelectDepartment={(departmentID) => {
                        setSelectedDepartment(flattenedDepartments.find(department => department.INFORMATION_HIERARCHY_ID === departmentID));
                        setSelectedTab(Tabs.PendingApproval);
                    }}
                />
            }

            {selectedTab === Tabs.PendingSignature &&
                <ItemsPendingApproval
                    departmentID={selectedDepartment && selectedDepartment.INFORMATION_HIERARCHY_ID}
                    docStatusID={DocumentRevisionStatuses.prepsig}
                />
            }

            {selectedTab === Tabs.PendingApproval &&
                <ItemsPendingApproval
                    departmentID={selectedDepartment && selectedDepartment.INFORMATION_HIERARCHY_ID}
                    docStatusID={DocumentRevisionStatuses.pending_committee_approval}
                />
            }

            {selectedTab === Tabs.PendingAdditional &&
                <ItemsPendingApproval
                    departmentID={selectedDepartment && selectedDepartment.INFORMATION_HIERARCHY_ID}
                    pendingAdditional={'any'}
                />
            }

            {selectedTab === Tabs.PendingRelease &&
                <ItemsPendingApproval
                    departmentID={selectedDepartment && selectedDepartment.INFORMATION_HIERARCHY_ID}
                    docStatusID={DocumentRevisionStatuses.preprel}
                />
            }

            {selectedTab === Tabs.PendingRouting &&
                <ItemsPendingApproval
                    departmentID={selectedDepartment && selectedDepartment.INFORMATION_HIERARCHY_ID}
                    docStatusID={DocumentRevisionStatuses.pending_committee_routing}
                    approvalSessionStatus={ApprovalSessionStatuses.pending_approval_routing}
                />
            }

            {selectedTab === Tabs.PendingReview &&
                <ItemsPendingApproval
                    departmentID={selectedDepartment && selectedDepartment.INFORMATION_HIERARCHY_ID}
                    approvalSessionType={ApprovalSessionTypes.review}
                    getItemsComingUpFor={NotificationWindowTypes.review}
                />
            }

            {selectedTab === Tabs.PendingArchive &&
                <ItemsPendingApproval
                    departmentID={selectedDepartment && selectedDepartment.INFORMATION_HIERARCHY_ID}
                    approvalSessionType={ApprovalSessionTypes.archive}
                />
            }

            {selectedTab === Tabs.PendingExpiration &&
                <ItemsPendingApproval
                    departmentID={selectedDepartment && selectedDepartment.INFORMATION_HIERARCHY_ID}
                    getItemsComingUpFor={NotificationWindowTypes.expiration}
                />
            }
            <SaveBar onClose={() => redirectToHomepage()} />
        </div>
    );
}
