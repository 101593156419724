import {
    Column,
    Entity,
    PrimaryColumn,
} from 'typeorm';
import { SchemaEntityManager } from '../SchemaEntityManager';

// Define the factory function
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function createRemovedApprovalSessionStepEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('REMOVED_APPROVAL_SESSION_STEP', { schema: schema })
    class REMOVED_APPROVAL_SESSION_STEP {
        static SCHEMANAME = schema;
        @Column('number', {
            nullable: true,
            name: 'MODIFIER_USERID'
        })
        MODIFIER_USERID!: number | null;

        @PrimaryColumn('varchar2', {
            nullable: false,
            length: 1000,
            name: 'MODIFIER_NAME'
        })
        MODIFIER_NAME!: string;

        @Column('varchar2', {
            nullable: true,
            length: 1000,
            name: 'MODIFIER_TITLE'
        })
        MODIFIER_TITLE!: string | null;

        @PrimaryColumn('timestamp', {
            nullable: false,
            name: 'DATESTAMP'
        })
        DATESTAMP!: Date;

        @PrimaryColumn('number', {
            nullable: false,
            name: 'APPROVAL_SESSION_ID'
        })
        APPROVAL_SESSION_ID!: number;

        @Column('number', {
            nullable: true,
            name: 'SIGNATURE_SESSION_ID'
        })
        SIGNATURE_SESSION_ID!: number | null;

        @Column('number', {
            nullable: true,
            name: 'COMMITTEE_APPROVAL_SESSION_ID'
        })
        COMMITTEE_APPROVAL_SESSION_ID!: number | null;

        @Column('varchar2', {
            nullable: false,
            length: 25,
            name: 'STATUS'
        })
        STATUS!: string;
    }

    return REMOVED_APPROVAL_SESSION_STEP;
}

// Export the factory function
export { createRemovedApprovalSessionStepEntity };

// Export the type representing an instance of the class generated by the factory function
export type REMOVED_APPROVAL_SESSION_STEP = InstanceType<ReturnType<typeof createRemovedApprovalSessionStepEntity>>;
