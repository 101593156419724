import DataTable from '../../_shared/DataTable/DataTable';
import { MUIDataTableColumn } from 'mui-datatables';
import { ACL } from '../../../entities/org/ACL';
import { constructFullName } from '@/components/UserPicker/utils/constructFullName';

type AccessListSearchResultsProps = {
    results: ACL[] | null;
};

export function AccessListSearchResults(props: AccessListSearchResultsProps) {
    const { results } = props;

    if (results === null) {
        return (<></>);
    }

    const formattedTableData = results.map(result => {
        const fullName = result.OWNERORWHATEVER ? constructFullName(result.OWNERORWHATEVER, 'firstMiddleLast') : 'N/A';
        const returnObj = {
            'ACL ID': result.ACLID,
            'User': fullName,
            'Usergroup': result.USERGROUP?.NAME ?? 'N/A',
            'Document': result.DOCUMENT?.TITLE ?? 'N/A',
            'Department/Manual': result.INFORMATION_HIERARCHY?.TITLE ?? 'N/A',
            'Department Inherited From': result.INHERIT_IH?.TITLE ?? 'N/A',
            'Control Owner': result.CONTROL_OWNER ? 'Yes' : 'No',
            'Assign Author': result.ASSIGN_AUTHOR ? 'Yes' : 'No',
            'Write': result.WRITE ? 'Yes' : 'No',
            'Visible': result.VISIBLE ? 'Yes' : 'No',
            'Review': result.REVIEW ? 'Yes' : 'No',
            'Revise': result.REVISE ? 'Yes' : 'No',
            'Limited Write': result.WRITE_LIM ? 'Yes' : 'No',
            'Limited Visible': result.VISIBLE_LIM ? 'Yes' : 'No',
        };

        return returnObj;
    });

    const noFilterColumns = ['ACL ID', 'Document'];

    return (
        <DataTable
            muiDataTableProps={{
                data: formattedTableData,
                title: 'Search Results',
                columns: formattedTableData[0] && Object.keys(formattedTableData[0]).map(key => {
                    const returnObj: MUIDataTableColumn = {
                        name: key,
                    };

                    if (noFilterColumns.includes(key)) {
                        returnObj.options = {
                            filter: false,
                        };
                    }

                    return returnObj;
                }),
            }}
        />
    );
}