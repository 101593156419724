import ApprovalRouteWithinTableRow from './ApprovalRouteWithinTableRow';
import React, { useContext, useReducer } from 'react';
import DataTable from '../../_shared/DataTable/DataTable';
import { MUIDataTableColumn, MUIDataTableProps } from 'mui-datatables';
import { Button } from '@material-ui/core';
import { Edit, Send } from '@material-ui/icons';
import { buildDocURL, buildManualURL } from '../../../_shared/utils/docManagerFunctions';
import UserBundleContext from '../../../context/UserBundleContext';

interface ApprovalRoutingTableWrapperProps {
    // what type of data we're loading
    selectedList: 'pending' | 'active'
    objectType: 'docrevid' | 'manualid'

    // props for the DataTable component
    tableTitle: string
    tableData: MUIDataTableProps['data']
    selectedRows: number[]
    onRefresh: () => void
    isRefreshing: boolean
    isRouting: boolean

    // props for the individual DataTable rows
    approvalRoutes: {[key: string]: any}
    getApprovalRoute: (objectType: string, objectID: number) => void

    // actions to send to parent
    setSelectedRows: (rows: number[]) => void
    setSelectedIDs: (IDs: number[]) => void
    editSelectedItems: () => void
    routeSelectedItems: () => void

    // actions to send to parent to manipulate the popup Approval Route Editor window
    setSelectedObjectIDs: (objectIDs: number[]) => void
    setSelectedObjectType: (objectType: 'docrevid' | 'manualid') => void
    setSelectedObjectTitle: (objectTitle: string) => void
    setDialogOpen: (dialogOpen: boolean) => void
}

export default function ApprovalRoutingTableWrapper(props: ApprovalRoutingTableWrapperProps) {
    const [ approvalRoutesAlreadyQueried, setApprovalRoutesAlreadyQueried ] = useReducer(
        (state: {[key: string]: boolean}, newState: {[key: string]: boolean}) => ({ ...state, ...newState }),
        {}
    );

    const context = useContext(UserBundleContext);

    return (
        <DataTable
            onRefresh={() => props.onRefresh()}
            isRefreshing={props.isRefreshing}
            isDisabled={props.isRefreshing || props.isRouting}
            muiDataTableProps={{
                data: props.tableData,
                title: props.tableTitle,
                columns: props.tableData[0] && Object.keys(props.tableData[0]).map((key, idx) => {
                    const returnObj: MUIDataTableColumn = {
                        name: key,
                    };

                    // this part is unfortunately quite complicated, because we need to create clickable titles,
                    // with href targets that differ between docrevs and manuals:
                    if (props.objectType === 'docrevid') {
                        if (   key === 'DocRevID'
                            || key === 'RevNo'
                            || key === 'HiddenTitle'
                        ) {
                            returnObj.options = {
                                display: false,
                                filter: false
                            };
                        }
                        if (key === 'Title') { // the clickable DocTitle column
                            returnObj.options = {
                                customBodyRender: (value, tableMeta) => {
                                    const docURL = context.organization?.PREFIX
                                        ? buildDocURL(
                                            context.organization.PREFIX,
                                            tableMeta.rowData[1],
                                            parseInt(tableMeta.rowData[2])
                                        ) : '';

                                    return (
                                        <a href={docURL} target={'_blank'} rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                            {value}
                                        </a>
                                    );
                                }
                            };
                        }
                    }
                    else if (props.objectType === 'manualid') {
                        if (idx === 1) { // the clickable Manual Title column
                            returnObj.options = {
                                customBodyRender: (value, tableMeta) => {
                                    const manualID = tableMeta.rowData[0];
                                    const manualURL = buildManualURL(manualID);

                                    return (
                                        <a href={manualURL} target={'_blank'} rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                            {value}
                                        </a>
                                    );
                                }
                            };
                        }
                    }

                    return returnObj;
                }),
                options: {
                    selectableRows: props.selectedList === 'active' ? 'none' : 'multiple',
                    rowsSelected: props.selectedRows,
                    onRowSelectionChange: (currentRowsSelected: any[], allRowsSelected: any[]) => {
                        const hashOfSelectedIndexes: {[key: string]: true} = {};
                        allRowsSelected.forEach(row => {
                            hashOfSelectedIndexes[row.dataIndex] = true;
                        });

                        const newlySelectedIDs: number[] = [];

                        const objectTypeKeyName = props.objectType === 'docrevid'
                            ? 'DocRevID'
                            : 'ManualID';

                        props.tableData.forEach((tableRow, idx) => {
                            if (hashOfSelectedIndexes[idx]) {
                                newlySelectedIDs.push((tableRow as {[key: string]: number})[objectTypeKeyName]);
                            }
                        });
                        props.setSelectedRows(allRowsSelected.map(row => row.dataIndex));
                        props.setSelectedIDs(newlySelectedIDs);
                    },
                    expandableRows: true,
                    renderExpandableRow: (rowData: string[]) => {
                        const titleIndex = props.objectType === 'docrevid'
                            ? 4 // NOTICE HOW SENSITIVE THIS IS!!!! And we can't use the regular title cell because it's a custom body render and is therefore an object, not just a string
                            : 1;

                        const objectID = parseInt(rowData[0]);
                        const objectTitle = rowData[titleIndex];
                        const approvalRoute = props.approvalRoutes[objectID];

                        if (!approvalRoutesAlreadyQueried[objectID]) {
                            setApprovalRoutesAlreadyQueried({ [objectID]: true });
                            props.getApprovalRoute(props.objectType, objectID);
                        }

                        return (
                            <ApprovalRouteWithinTableRow
                                objectType={props.objectType}
                                objectID={objectID}
                                objectTitle={objectTitle}
                                setSelectedObjectIDs={objectIDs => props.setSelectedObjectIDs(objectIDs)}
                                setSelectedObjectType={objectType => props.setSelectedObjectType(objectType)}
                                setSelectedObjectTitle={objectTitle => props.setSelectedObjectTitle(objectTitle)}
                                setDialogOpen={dialogOpen => props.setDialogOpen(dialogOpen)}
                                approvalRoute={approvalRoute}
                            />
                        );
                    },
                    customToolbarSelect: () => (
                        // this will override the default toolbar, which has a delete button,
                        // which we don't want
                        <span style={{ height: '48px', display: 'flex', alignItems: 'center', paddingRight: '1rem', justifyContent: 'space-between' }}>
                            {props.selectedList === 'pending' &&
                                <>
                                    <Button
                                        startIcon={<Edit />}
                                        onClick={() => props.editSelectedItems()}
                                        disabled={!props.selectedRows.length}
                                    >
                                        {'Edit Selected'}
                                    </Button>

                                    <Button
                                        startIcon={<Send />}
                                        onClick={() => props.routeSelectedItems()}
                                        disabled={!props.selectedRows.length || props.isRouting}
                                    >
                                        {'Route Selected'}
                                    </Button>
                                </>
                            }
                        </span>
                    ),
                }
            }}
        />
    );
}
