import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import axios from 'axios';
import { PERSON } from '../../../../entities/master/PERSON';
import { makeStyles } from '@material-ui/core';
import { ACK_SCHEDULE } from '../../../../entities/org/ACK_SCHEDULE';
import DataTable from '../../../_shared/DataTable/DataTable';
import { MUIDataTableColumn, MUIDataTableProps } from 'mui-datatables';
import  { LoadingIndicator  } from '../../../_shared/LoadingIndicator';
import { LoadingStatuses } from '../../../../utils/LoadingStatuses';
import { ArrowForward } from '@material-ui/icons';
import LucidocColors from '../../../../constants/LucidocColors';
import SaveBar from '../../../_shared/SaveBar/SaveBar';
import SaveIndicator from '../../../_shared/SaveIndicator';
import { setTitleInAdmin } from '../../../../utils/setTitleInAdmin';
import { TemporaryFavoritesStar } from '../../../_shared/TemporaryFavoritesStar/TemporaryFavoritesStar';
import { redirectToHomepage } from '../../../../utils/savebarUtilities';
import setWindowTitle from '../../../../utils/setWindowTitle';
import { constructFullName } from '@/components/UserPicker/utils/constructFullName';

interface ReactSelectOptionType {
    label: string
    value: number
}

const useStyles = makeStyles({
    headingContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        padding: '1rem',
    },
    selectMenuBlockSet: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'end',
        margin: '0 auto',
    },
    selectMenuBlock: {
        margin: '1rem',
        width: '300px',
    },
    arrow: {
        margin: '1rem',
    },
    favoritesStar: {
        marginLeft: 'auto',
    },
    schedulesTable: {
        position: 'relative',
        zIndex: 0,
    },
});

export default function TransferAcknowledgementSchedules() {
    const classes = useStyles();

    const SelectMenuDummyValue: ReactSelectOptionType = {
        label: '',
        value: 0
    };

    // Loading Status is used for DataTable only (that's why it starts out as READY)
    const [ loadingStatus, setLoadingStatus ] = useState(LoadingStatuses.READY);

    // SaveIndicator
    const [ isSaveIndicatorVisible, setIsSaveIndicatorVisible ] = useState(false);

    // Select Menu Options
    const [ ackScheduleOwnersMappedForSelectMenu, setAckScheduleOwnersMappedForSelectMenu ] = useState<ReactSelectOptionType[]>([]);
    const [ ackScheduleReceiversMappedForSelectMenu, setAckScheduleReceiversMappedForSelectMenu ] = useState<ReactSelectOptionType[]>([]);

    // Transfer To/From (selected items in Select Menus)
    const [ transferFromThisOwner, setTransferFromThisOwner ] = useState<ReactSelectOptionType>(SelectMenuDummyValue);
    const [ transferToThisOwner, setTransferToThisOwner ] = useState<ReactSelectOptionType>(SelectMenuDummyValue);

    // DataTable
    const [ tableData, setTableData ] = useState<MUIDataTableProps['data']>([]);
    const [ selectedRows, setSelectedRows ] = useState<number[]>([]);

    // Acknowledgement Schedule IDs (for the actual transfer)
    const [ selectedAckScheduleIDs, setSelectedAckScheduleIDs ] = useState<number[]>([]);

    useEffect(() => {
        const newTitle = 'Transfer Acknowledgement Schedules';
        setWindowTitle(newTitle);
        setTitleInAdmin(newTitle);
        getAcknowledgementScheduleOwnersAndReceivers().then();
    }, []);

    useEffect(() => {
        if (transferFromThisOwner.value === transferToThisOwner.value) {
            setTransferToThisOwner(SelectMenuDummyValue);
        }
    }, [transferFromThisOwner]);

    async function getAcknowledgementScheduleOwnersAndReceivers() {
        const res = await axios.get('/api/administration/acknowledgements/transfer-schedules/get-all-schedule-owners');

        const mappedOwners = (res.data.acknowledgementScheduleOwners as PERSON[]).map(person => ({
            label: constructFullName(person, 'lastFirstMiddle'),
            value: person.USERID
        }));
        const mappedReceivers = (res.data.acknowledgementScheduleReceivers as PERSON[]).map(person => ({
            label: constructFullName(person, 'lastFirstMiddle'),
            value: person.USERID
        }));

        setAckScheduleOwnersMappedForSelectMenu(mappedOwners);
        setAckScheduleReceiversMappedForSelectMenu(mappedReceivers);
    }

    async function updateTransferFromThisOwner(option: ReactSelectOptionType) {
        setTransferFromThisOwner(option);
        setLoadingStatus(LoadingStatuses.LOADING);
        setSelectedAckScheduleIDs([]);
        setSelectedRows([]);

        const res = await axios.get('/api/administration/acknowledgements/transfer-schedules/get-schedules-by-owner', {
            params: {
                ownerUserID: option.value
            }
        });

        if (res.data.acknowledgementSchedules) {
            const formattedTableData = (res.data.acknowledgementSchedules as ACK_SCHEDULE[]).map(schedule => {
                return {
                    Title: schedule.TITLE,
                    AckScheduleID: schedule.ACK_SCHEDULE_ID
                };
            });
            setTableData(formattedTableData);
        }

        // Note this behavior is handled in a useEffect, detailed here to let you know
        // if (transferFromThisOwner.value === transferToThisOwner.value) {
        //     setTransferToThisOwner(SelectMenuDummyValue)
        // }

        setLoadingStatus(LoadingStatuses.READY);
    }

    async function handleTransfer() {
        setLoadingStatus(LoadingStatuses.SAVING);

        // if we transfer to a person who previously had no acknowledgement schedules, that person
        // won't appear in the Transfer From list, so we'll update that list so the user doesn't
        // have to refresh the page:
        const alsoUpdateAckScheduleOwners: boolean = !ackScheduleOwnersMappedForSelectMenu.find(item => item.value === transferToThisOwner.value);

        const res = await axios.post('/api/administration/acknowledgements/transfer-schedules/transfer-schedules', {
            transferFromThisOwnerID: transferFromThisOwner.value,
            transferToThisOwnerID:   transferToThisOwner.value,
            selectedAckScheduleIDs,
            alsoUpdateAckScheduleOwners
        });

        if (res.data.acknowledgementSchedules) {
            const formattedTableData = (res.data.acknowledgementSchedules as ACK_SCHEDULE[]).map(schedule => {
                return {
                    Title: schedule.TITLE,
                    AckScheduleID: schedule.ACK_SCHEDULE_ID
                };
            });
            setTableData(formattedTableData);
            setSelectedRows([]);
            setSelectedAckScheduleIDs([]);
            setIsSaveIndicatorVisible(true);

            if (alsoUpdateAckScheduleOwners && res.data.acknowledgementScheduleOwners) {
                const mappedOwners = (res.data.acknowledgementScheduleOwners as PERSON[]).map(person => ({
                    label: constructFullName(person, 'lastFirstMiddle'),
                    value: person.USERID
                }));
                setAckScheduleOwnersMappedForSelectMenu(mappedOwners);
            }
        }

        setLoadingStatus(LoadingStatuses.READY);
    }

    return (
        <div>
            <div className={classes.headingContainer}>
                <div className={classes.selectMenuBlockSet}>
                    <div className={classes.selectMenuBlock}>
                        <p>Transfer from:</p>
                        <Select
                            isDisabled={!ackScheduleOwnersMappedForSelectMenu.length}
                            value={transferFromThisOwner}
                            options={ackScheduleOwnersMappedForSelectMenu}
                            onChange={(option: any) => updateTransferFromThisOwner(option)}
                        />
                    </div>

                    <ArrowForward
                        className={classes.arrow}
                        style={{ color: transferFromThisOwner.value && (transferFromThisOwner.value !== transferToThisOwner.value)
                            ? LucidocColors.green
                            : 'lightgrey'
                        }}
                    />

                    <div className={classes.selectMenuBlock}>
                        <p>Transfer to:</p>
                        <Select
                            isDisabled={!transferFromThisOwner.value}
                            value={transferToThisOwner}
                            options={ackScheduleReceiversMappedForSelectMenu}
                            onChange={(option: any) => option.value !== transferFromThisOwner.value && setTransferToThisOwner(option)}
                        />
                    </div>
                </div>

                <div className={classes.favoritesStar}>
                    <TemporaryFavoritesStar targetPathName={'admin/admin.pl?repname=transfer_ack_schedule'} />
                </div>
            </div>

            {transferFromThisOwner.value > 0 && loadingStatus === LoadingStatuses.READY &&
                <div className={classes.schedulesTable}>
                <DataTable
                    muiDataTableProps={{
                        title: 'Acknowledgement Schedules',
                        columns: tableData[0] && Object.keys(tableData[0]).map(key => {
                            const returnObj: MUIDataTableColumn = {
                                name: key,
                            };
                            if (key === 'AckScheduleID') {
                                returnObj.options = {
                                    display: false,
                                    filter: false
                                };
                            }

                            return returnObj;
                        }),
                        data: tableData,
                        options: {
                            selectableRows: 'multiple',
                            rowsSelected: selectedRows,
                            onRowSelectionChange: (currentRowsSelected: any[], allRowsSelected: any[]) => {
                                const hashOfSelectedIndexes: {[key: string]: true} = {};
                                allRowsSelected.forEach(row => {
                                    hashOfSelectedIndexes[row.dataIndex] = true;
                                });

                                const newlySelectedIDs: number[] = [];

                                tableData.forEach((tableRow, idx) => {
                                    if (hashOfSelectedIndexes[idx]) {
                                        newlySelectedIDs.push((tableRow as {[key: string]: any}).AckScheduleID);
                                    }
                                });

                                setSelectedRows(allRowsSelected.map(row => row.dataIndex));
                                setSelectedAckScheduleIDs(newlySelectedIDs);
                            }
                        }
                    }}
                />
                </div>
            }

            {loadingStatus !== LoadingStatuses.READY &&
                <LoadingIndicator loadingStatus={loadingStatus}/>
            }

            <SaveBar
                onSave={() => handleTransfer()}
                // saveButtonText={'Transfer Selected'}
                onClose={() => redirectToHomepage()}
                isSaveDisabled={
                    ! transferFromThisOwner.value
                    || ! transferToThisOwner.value
                    || ! selectedAckScheduleIDs.length
                    ||   loadingStatus               !== LoadingStatuses.READY
                    ||   transferFromThisOwner.value === transferToThisOwner.value
                }
            />

            <SaveIndicator
                open={isSaveIndicatorVisible}
                onClose={() => setIsSaveIndicatorVisible(false)}
                message={'Items transferred successfully.'}
            />
        </div>
    );
}
