import React, { useEffect, useState } from 'react';
import DataTable from '../../../_shared/DataTable/DataTable';
import axios from 'axios';
import { TRACKING } from '../../../../entities/org/TRACKING';
import { MUIDataTableProps } from 'mui-datatables';
import queryString from 'query-string';
import { LoadingIndicator } from '../../../_shared/LoadingIndicator';
import { LoadingStatuses } from '../../../../utils/LoadingStatuses';
import { createSortableDate } from '../../../../_shared/utils/docManagerFunctions';
import { setTitleInAdmin } from '../../../../utils/setTitleInAdmin';
import { TemporaryFavoritesStar } from '../../../_shared/TemporaryFavoritesStar/TemporaryFavoritesStar';
import setWindowTitle from '../../../../utils/setWindowTitle';
import { makeStyles } from '@material-ui/core/styles';
import { constructFullName } from '@/components/UserPicker/utils/constructFullName';

const useStyles = makeStyles({
    disclaimerArea: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    disclaimerMessage: {
        color: 'grey',
        fontSize: '.75rem',
    },
    docIDInput: {
        border: '1px solid gray',
        fontSize: '1rem',
        padding: '.25rem',
        margin: '0 1rem',
    },
    submitButton: {
        border: '1px solid gray',
        fontSize: '1rem',
        padding: '.25rem .5rem',
    },
    headingContainer: {
        padding: '1rem 1rem 0 1rem',
    },
});

export default function ViewingHistory() {
    const [ loadingStatus, setLoadingStatus ] = useState(LoadingStatuses.MOUNTING);
    const [ selectedDocID, setSelectedDocID ] = useState<number | undefined>(undefined);
    const [ tableData, setTableData ] = useState<MUIDataTableProps['data']>([]);

    const classes = useStyles();

    useEffect(() => {
        const newTitle = 'Viewing History';
        setWindowTitle(newTitle);
        setTitleInAdmin(newTitle);

        const urlParamDocID = queryString.parse(window.location.search)?.docid; // used in docmanager via URL params
        if (urlParamDocID) {
            setSelectedDocID(urlParamDocID as unknown as number);
            getViewingHistory().then();
        }
    }, []);

    async function getViewingHistory() {
        setLoadingStatus(LoadingStatuses.LOADING);
        const res = await axios.get('/api/administration/documents/viewing-history', {
            params: {
                docID: selectedDocID
            }
        });

        const formattedTableData = (res.data.viewingHistory as TRACKING[]).map(tracking => {
            return {
                DocID: tracking.DOCREV?.DOCID,
                'Rev#': tracking.DOCREV?.REVNO,
                Title: tracking.DOCREV?.TITLE || '',
                'Viewed By': tracking.USER ? constructFullName(tracking.USER, 'lastFirstMiddle') : '',
                Date: tracking.EVENTTIME
                    ? createSortableDate(new Date(tracking.EVENTTIME))
                    : '',
                Impersonator: tracking.IMPERSONATOR
                    ? constructFullName(tracking.IMPERSONATOR, 'lastFirstMiddle')
                    : '',
            };
        });

        setTableData(formattedTableData || []);
        setLoadingStatus(LoadingStatuses.READY);
    }

    return (
        <div>
            <div className={classes.headingContainer}>
                <div className={classes.disclaimerArea}>
                    <p className={classes.disclaimerMessage}>Please note records prior to 2021/12/09 may not adequately reflect user impersonation.</p>
                    <TemporaryFavoritesStar targetPathName={'admin/admin.pl?repname=viewing_history'} />
                </div>
                <div>
                    <span>DocID:</span>
                    <input
                        className={classes.docIDInput}
                        type={'number'}
                        value={selectedDocID}
                        onChange={(e) => setSelectedDocID(parseInt(e.currentTarget.value))}
                        onKeyUp={(e) => e.key === 'Enter' && getViewingHistory()}
                    />
                    <button
                        className={classes.submitButton}
                        onClick={() => getViewingHistory()}
                    >
                        Go
                    </button>
                </div>
            </div>

            {loadingStatus === LoadingStatuses.READY &&
                <DataTable
                    muiDataTableProps={{
                        title: 'Viewing History',
                        data: tableData,
                        columns: tableData[0] && Object.keys(tableData[0])
                    }}
                />
            }

            {loadingStatus === LoadingStatuses.LOADING &&
                <LoadingIndicator />
            }

        </div>
    );
}
