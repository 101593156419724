import React from 'react';
import { ORGANIZATION } from '../../../../entities/master/ORGANIZATION';

type StagedChangesProps = {
    isVisible: boolean;
    feature: string;
    selectedOrganizationIDs: number[];
    organizations: ORGANIZATION[];
};

export default function StagedChanges(props: StagedChangesProps) {
    if (!props.isVisible) {
        return null;
    }

    const formatSelectedOrganizations = () => {
        if (props.selectedOrganizationIDs.length === 0) {
            return null;
        }

        return <>
            <p>Selected Organizations:</p>
            <ul>
                {props.selectedOrganizationIDs.map((selectedOrgID, idx) => {
                    const orgName = props.organizations.find((org) => org.ORGANIZATIONID === selectedOrgID)?.NAME;
                    return (
                        <li key={idx}>
                            {orgName}
                        </li>
                    );
                })}
            </ul>
        </>;
    };

    return <>
        <p>Are you sure you want to submit?</p>
        <br />
        <p>Feature: {props.feature}</p>
        {formatSelectedOrganizations()}
    </>;
}