import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { DOCUMENT } from '../../../entities/org/DOCUMENT';
import { DOCUMENTREVISION } from '../../../entities/org/DOCUMENTREVISION';
import { ORGANIZATION } from '../../../entities/master/ORGANIZATION';
import './copy-approvals.css';
import UserBundleContext from '../../../context/UserBundleContext';

export default function CopyApprovals() {
    const context = useContext(UserBundleContext);

    const [ allOrganizations, setAllOrganizations ] = useState<ORGANIZATION[]>([]);
    const [ selectedOrganizationDatabaseUID, setSelectedOrganizationDatabaseUID ] = useState<string>('');
    const [ docID, setDocID ] = useState<string>('');
    const [ docNotFoundWarning, setDocNotFoundWarning ] = useState(false);
    const [ selectedDocument, setSelectedDocument ] = useState<DOCUMENT | undefined>(undefined);

    // these are the targets; we will come FROM a docRev TO another docRev:
    const [ fromDocRev, setFromDocRev ] = useState<DOCUMENTREVISION | undefined>(undefined);
    const [ toDocRev, setToDocRev ] = useState<DOCUMENTREVISION | undefined>(undefined);

    async function getInstanceNames() {
        const response = await axios.get('/api/internal-support/copy-approvals/get-instance-names');
        return response.data.success.organizations;
    }

    useEffect(() => {
        getInstanceNames().then(organizations => setAllOrganizations(organizations));
    }, []);

    function clearDocRevArea() {
        // this will clear everything that dynamically appears when you select a docID
        setSelectedDocument(undefined);
        setFromDocRev(undefined);
        setToDocRev(undefined);
    }

    function handleOrgChange(databaseUID: string) {
        // clear everything out first:
        setDocID('');
        setDocNotFoundWarning(false);
        clearDocRevArea();

        // now update the org:
        setSelectedOrganizationDatabaseUID(databaseUID);
    }

    async function getDocumentFromDocID(docIdAsString: string) {
        clearDocRevArea();
        const response = await axios.get('/api/internal-support/copy-approvals/get-doc-from-id', {
            params: {
                    selectedOrganizationDatabaseUID: selectedOrganizationDatabaseUID,
                    docID: parseInt(docIdAsString),
                    // this one is needed if you're signed into Lucidoc but updating data in another org:
                    useSchemaConnection: context.organization && context.organization.DATABASEUID !== selectedOrganizationDatabaseUID
                }
            }
        );
        if (!response || response.data.failure || response.data.error) {
            setDocNotFoundWarning(true);
            return;
        }
        setDocNotFoundWarning(false);
        setSelectedDocument(response.data.success.document);
    }

    function checkIfNumerical(value: string) {
        const numbers = new RegExp(/^[0-9]*$/);
        return numbers.test(value);
    }

    async function sendDocRevToCopyApprovals() {
        if (!fromDocRev || !toDocRev) {
            return;
        }
        await axios.get('/api/internal-support/copy-approvals/copy-approvals-from-one-docrev-to-another', {
            params: {
                selectedOrganizationDatabaseUID: selectedOrganizationDatabaseUID,
                fromDocRevID: fromDocRev.DOCREVID,
                toDocRevID: toDocRev.DOCREVID,
                // this one is needed if you're signed into Lucidoc but updating data in another org:
                useSchemaConnection: context.organization && context.organization.DATABASEUID !== selectedOrganizationDatabaseUID
            }
        });
        await getDocumentFromDocID(docID);
    }

    const organizations = allOrganizations.map((org, idx) => {
        return (
            <option
                key={idx}
                value={org.DATABASEUID || ''}
            >
                {org.NAME}
            </option>
        );
    });

    const revisions = selectedDocument && selectedDocument.REVISIONS && selectedDocument.REVISIONS
        .sort((a, b) => {
            // sort by revision numbers, taking into consideration X or Y revisions (so X_4 comes before 5, etc):
            return parseInt(a.REVNO.split('').filter(letter => checkIfNumerical(letter)).join('')) -
                   parseInt(b.REVNO.split('').filter(letter => checkIfNumerical(letter)).join(''));
        })
        .map((revision, idx) => {
            let borderColor: string = 'lightgrey';
            if (fromDocRev && fromDocRev.DOCREVID === revision.DOCREVID) {
                borderColor = 'limegreen';
            }
            else if (toDocRev && toDocRev.DOCREVID === revision.DOCREVID) {
                borderColor = 'skyblue';
            }

            return (
                <React.Fragment key={idx}>

                    <div
                        className={'copy-approvals-docrev-block'}
                        style={{
                            border: `4px solid ${borderColor}`,
                        }}
                    >
                        <h3 className={'copy-approvals-docrev-title'}>${revision.REVNO.trim()} - {revision.TITLE || selectedDocument.TITLE} (DocRevID: {revision.DOCREVID})</h3>
                        <p>Official Date: {revision.OFFICIALDT && new Date(revision.OFFICIALDT).toLocaleString()}</p>
                        <p>Obsolete Date: {revision.OBSOLETEDT && new Date(revision.OBSOLETEDT).toLocaleString()}</p>
                        <p className={'copy-approvals-font-weight-bold'}>Approval Sessions:</p>
                        <ul>
                        {revision.APPROVAL_SESSIONS && revision.APPROVAL_SESSIONS
                            .sort((a, b) => a.APPROVAL_SESSION_ID - b.APPROVAL_SESSION_ID)
                            .map((approvalSession, idx) => (
                                <li className={'copy-approvals-approval-session-block'} key={idx}>
                                    <p># {idx}: {approvalSession.TYPE} -
                                        <span className={'copy-approvals-font-weight-bold'}> from</span> {new Date(approvalSession.START_DATE).toLocaleString()}
                                        <span className={'copy-approvals-font-weight-bold'}> to</span> {approvalSession.COMPLETION_DATE ? new Date(approvalSession.COMPLETION_DATE).toLocaleString() : 'none'}
                                    </p>

                                    <p className={'copy-approvals-font-weight-bold'}>Signatures:</p>
                                    <ul>
                                    {approvalSession.APPROVAL_SESSION_STEPS && approvalSession.APPROVAL_SESSION_STEPS.map(step => (
                                        step.SIGNATURE_SESSION && step.SIGNATURE_SESSION.SIGNATURE_LOGS && step.SIGNATURE_SESSION.SIGNATURE_LOGS.map((log, idx) => (
                                            <li key={idx}>
                                                {log.USER_NAME}: {log.DECISION} ({new Date(log.DECISION_DATE).toLocaleString()})
                                            </li>
                                        ))
                                    ))}
                                    </ul>

                                    <p className={'copy-approvals-font-weight-bold'}>Committee Approvals:</p>
                                    <ul>
                                    {approvalSession.APPROVAL_SESSION_STEPS && approvalSession.APPROVAL_SESSION_STEPS.map(step => (
                                        step.COMMITTEE_APPROVAL_SESSION && step.COMMITTEE_APPROVAL_SESSION.COMMITTEE_DECISION_LOGS && step.COMMITTEE_APPROVAL_SESSION.COMMITTEE_DECISION_LOGS.map((log, idx) => (
                                            <li key={idx}>
                                                {log.COMMITTEE_NAME}: {log.DECISION} ({new Date(log.DECISION_DATE).toLocaleString()})
                                            </li>
                                        ))
                                    ))}
                                    </ul>
                                </li>
                            ))
                        }
                        </ul>
                    </div>

                    <span>
                        <input
                            type={'radio'}
                            disabled={revision.REVNO.includes('X') || revision.REVNO.includes('Y')}
                            checked={fromDocRev === revision}
                            onChange={() => {
                                setFromDocRev(revision);
                                setToDocRev(undefined);
                            }}
                        />
                    </span>

                    <span>
                        <input
                            type={'radio'}
                            disabled={!fromDocRev|| fromDocRev.REVNO === revision.REVNO}
                            checked={toDocRev === revision}
                            onChange={() => setToDocRev(revision)}
                        />
                    </span>

                </React.Fragment>
            );
        });

    let warningMessage = '';

    if (fromDocRev && toDocRev && selectedDocument) {
        warningMessage =
            'You are about to copy approvals...'
            + '\n'
            + '\n'
            + 'FROM:'
            + '\n'
            + `DocID ${fromDocRev.DOCID}, Revision ${fromDocRev.REVNO.trim()} (DocRevID ${fromDocRev.DOCREVID}),`
            + '\n'
            + `'${fromDocRev.TITLE || selectedDocument.TITLE}'`
            + '\n'
            + '\n'
            + 'TO:'
            + '\n'
            + `DocID ${toDocRev.DOCID}, Revision ${toDocRev.REVNO.trim()} (DocRevID ${toDocRev.DOCREVID}),`
            + '\n'
            + `'${toDocRev.TITLE || selectedDocument.TITLE}'`
            + '\n'
            + '\n'
            + 'Are you sure you wish to continue?';
    }

    return (
        <div className={'copy-approvals'}>
            <p>
                <Link to={'/internal-support'}>
                    Back
                </Link>
            </p>

            <div className={'copy-approvals-warning-list'}>
                <p>Please Note:</p>
                <ul>
                    <li>Approval sessions will go from "officialize" to "review" ONLY if you are copying FROM a new docrev TO an older one! Otherwise it'll stay as officialize.</li>
                    <li>This will DUPLICATE approvals; it will NOT check for duplicates and exclude them</li>
                    <li>It'll look weird in IE11</li>
                </ul>
            </div>
            <br />

            <h3>Copy Approvals</h3>

            <p>Select an instance:</p>
            <select
                value={selectedOrganizationDatabaseUID}
                onChange={(e) => handleOrgChange(e.currentTarget.value)}
            >
                <option> </option>
                {organizations}
            </select>

            <p>Select a DocID:</p>
            <input
                type="text"
                value={docID}
                disabled={!selectedOrganizationDatabaseUID}
                onChange={(e) => checkIfNumerical(e.currentTarget.value) && setDocID(e.currentTarget.value)}
            />
            <button
                disabled={!selectedOrganizationDatabaseUID}
                onClick={() => getDocumentFromDocID(docID)}
            >
                Select
            </button>

            {docNotFoundWarning &&
                <span>Document not found.</span>
            }

            {selectedDocument && revisions &&
                <>
                    <h3
                        className={'copy-approvals-doc-title-line'}
                    >
                        Document: {selectedDocument.TITLE}
                    </h3>
                    <div
                        className={'copy-approvals-revision-grid'}
                    >
                        <h3>Revisions:</h3>
                        <h3>Copy FROM this DocRev:</h3>
                        <h3>Copy TO this DocRev:</h3>
                        {revisions}
                    </div>
                </>
            }

            <button
                className={'copy-approvals-copy-approvals-button'}
                disabled={!fromDocRev || !toDocRev}
                onClick={() => {
                    window.confirm(warningMessage) && sendDocRevToCopyApprovals();
                }}
            >
                Copy Approvals
            </button>

        </div>
    );
}
