import React, { useEffect, useState } from 'react';

import axios from 'axios';

import { COMMITTEE, CommitteeStatuses } from '../../../../entities/org/COMMITTEE';

import ReactSelect from 'react-select';

interface ISelectCommitteeProps {
    placeholder?: string;
    onChange: (committeeId?: number) => void;
    committeeId?: number;
    statuses?: CommitteeStatuses[];
}

const SelectCommittee: React.FC<ISelectCommitteeProps> = (props: ISelectCommitteeProps) => {

    const [committees, setCommittees] = useState<COMMITTEE[]>([]);

    const loadInitialData = async () => {
        const res = await axios.get(
            '/api/committee',
            props.statuses ? {
                params: {
                    statuses: props.statuses
                }
            } : undefined
        );

        setCommittees(res.data.committees);
    };

    useEffect(() => {
        loadInitialData().then();
    }, []);

    const committeeOptions = committees
        .map(committee => ({
            label: committee.NAME,
            value: committee.COMMITTEE_ID
        }));

    return <ReactSelect
        placeholder={props.placeholder || 'Select committee...'}
        onChange={
            (selected) => {
                if (!selected) {
                    props.onChange(undefined);
                } else {
                    const comittee = committees.find(committee => committee.COMMITTEE_ID === (selected.value as number));
                    props.onChange(comittee?.COMMITTEE_ID);
                }
            }
        }
        options={committeeOptions}
        value={committeeOptions.find(option => option.value === props.committeeId)}
        styles={{
            control: (provided: object) => ({
                ...provided,
                width: '28rem'
            })
        }}
        isClearable
    />;

};

export default SelectCommittee;
