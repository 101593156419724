import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { TEMPLATE } from '../../../entities/org/TEMPLATE';
import ContractRequestContext, { ContractRequestContextType } from './ContractRequestContext';
import FieldPage from './FieldPage';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { PUBLISHER } from '../../../entities/org/PUBLISHER';
import { INFORMATION_HIERARCHY } from '../../../entities/org/INFORMATION_HIERARCHY';
import Select from 'react-select';
import { FIELDTEMPLATE } from '../../../entities/org/FIELDTEMPLATE';
import { documentMetadataValues } from './dataTypes';
import { Button, Step, StepLabel, Stepper, Tooltip } from '@material-ui/core';
import { CheckCircleOutline, InsertDriveFileOutlined } from '@material-ui/icons';
import { DOCUMENT } from '../../../entities/org/DOCUMENT';
import { Input } from '@/ui/ui/input';

const useStyles = makeStyles({
    contractForm: {
        padding: '1rem',
        boxSizing: 'border-box',
        marginBottom: '10rem',
    },
    invisibleFieldSet: {
        border: 'none'
    },
    fileUploadButton: {
        marginBottom: '2rem'
    },
    contractTypeSelectMenuContainerDiv: {
        display: 'inline-block',
        verticalAlign: 'middle',
        width: '25%'
    },
    docTitleDiv: {
        marginTop: '1rem',
        borderTop: '1px solid lightgrey',
        borderBottom: '1px solid lightgrey',
        paddingBottom: '1rem',
        '& > h2': {
            marginBottom: '0.25rem'
        }
    },
    linkSelectorDiv: {
        borderBottom: '1px solid lightgrey',
        paddingBottom: '1rem',
        marginBottom: '1rem',
        '& > h3': {
            color: 'blue'
        },
    },
    linkSelectorDivHalfWidthColumn: {
        width: '50%',
        padding: '0 1rem',
        boxSizing: 'border-box'
    },
    errorMessage: {
        color: 'red'
    },
    successfulUploadDialogDiv: {
        textAlign: 'center'
    }
});

export default function ContractRequest() {
    const classes = useStyles();

    // The stepper:
    const [ activeStep, setActiveStep ] = useState<number>(0);

    // The file itself:
    const [ uploadedFile, setUploadedFile ] = useState<File | null>(null);

    // The contract type (template) select menu:
    const [ selectedTemplate, setSelectedTemplate ] = useState<TEMPLATE | undefined>(undefined);
    const [ allTemplates, setAllTemplates ] = useState<TEMPLATE[]>([]);

    // Certain fields need these values, so we're loading them here so we do it just once, then providing them through context:
    const [ publishers, setPublishers ] = useState<PUBLISHER[]>([]); // FYI, Publishers are Manuals
    const [ departments, setDepartments ] = useState<INFORMATION_HIERARCHY[]>([]);

    // Document Info:
    const [ documentTitle, setDocumentTitle ] = useState<string>('');
    const [ documentMetadata, setDocumentMetadata ] = useState<any[]>([]);

    // Dynamically-rendered form inputs (which will update the doc info):
    const [ pagesGroupsAndFields, setPagesGroupsAndFields ] = useState<FIELDTEMPLATE[]>([]);

    // Used to build links to existing Lucidoc documents:
    const [ documentsMappedForSelectMenu, setDocumentsMappedForSelectMenu ] = useState<{label: string, value: number}[]>([]);
    const [ docIDsToBecomeLinks, setDocIDsToBecomeLinks ] = useState<number[]>([]);

    // After a user clicks "submit"
    const [ isSaving, setIsSaving ] = useState<boolean>(false);

    useEffect(() => {
        document.title = 'Contract Request';
        getInitialData().then();
    }, []);

    async function getInitialData() {
        const res = await axios.get('/api/doc-manager/contracts/request/get-initial-data');
        setAllTemplates(res.data.success.contractDocTypes);
        setPublishers(res.data.success.publishers);
        setDepartments(res.data.success.departments);
        setDocumentsMappedForSelectMenu((res.data.success.documents as DOCUMENT[]).map(doc => ({
            label: doc.TITLE,
            value: doc.DOCID
        })));
    }

    function clearAnyFilledOutData() {
        setSelectedTemplate(undefined);
        setDocumentTitle('');
        setDocumentMetadata([]);
        setPagesGroupsAndFields([]);
        setDocIDsToBecomeLinks([]);
    }

    function handleFileUpload(files: FileList | null) {
        if (!files || !files[0]) {
            return;
        }

        const file = files[0];
        const fileSizeInMB = file.size / 1024 / 1024;

        if (fileSizeInMB > 10) {
            window.alert(`File size is ${fileSizeInMB.toFixed(2)}mb. Current maximum is 10mb.\nPlease reduce the file size and try again.`);
            return;
        }

        setUploadedFile(file);
        setActiveStep(1);
    }

    async function handleContractTypeChange(templateID: string) {
        clearAnyFilledOutData();
        const res = await axios.get('/api/doc-manager/contracts/request/get-fields', {
            params: {
                templateID: templateID
            }
        });
        if (res.data.success) {
            const templateFromTemplateID = allTemplates.find(template => template.TEMPLATEID === parseInt(templateID));
            if (templateFromTemplateID) {
                setActiveStep(2);
                setSelectedTemplate(templateFromTemplateID);
                setPagesGroupsAndFields(res.data.success);
            }
        }
    }

    function updateDocumentMetadata(data: documentMetadataValues) {
        // this function will modify the data in such a way that Lucidoc's XML parser
        // can handle it on the back-end (specifically with the CDATA insertion):
        const objectToInsert:{[key: string]: string | object} = {
            name: data.fieldName,
        };
        if (data.value) {
            objectToInsert.value = `<![CDATA[${data.value}]]>`;
        }
        else if (data.children) {
            objectToInsert.children = data.children.map(item => {
                return {
                    ...item,
                    text: `<![CDATA[${item.text}]]>`
                };
            });
        }
        if (data.attrs) {
            objectToInsert.attrs = data.attrs;
        }
        // documentMetadata is stored as an array, so on each update, we either overwrite
        // the previous value, or, if there is no previous value, simply add the new value
        // to the array:
        let indexOfExistingFieldWeWillOverWrite: number = -1;
        documentMetadata.some((xmdValue: any, idx: number) => {
            if (xmdValue.name === data.fieldName) {
                indexOfExistingFieldWeWillOverWrite = idx;
                return true;
            }
            return false;
        });
        if (indexOfExistingFieldWeWillOverWrite > -1) {
            const newDocumentMetadata = documentMetadata.slice();
            newDocumentMetadata[indexOfExistingFieldWeWillOverWrite] = objectToInsert;
            setDocumentMetadata(newDocumentMetadata);
        }
        else {
            setDocumentMetadata(documentMetadata.concat(objectToInsert));
        }
    }

    function isMimeTypeDocButFileIsDocX(mimeType: string, fileType: string) {
        // special circumstance for .doc vs .docx files, so a user can use the Word template (which is a doc file)
        // but still upload a docx file:
        return (
               mimeType === 'application/msword'
            && fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        );
    }

    async function submitDocument() {
        if (!uploadedFile) {
            // this is mostly just a null check for the formData.append();
            // the submit button is also disabled if there's no file.
            return;
        }
        setIsSaving(true);

        const formData = new FormData();
        formData.append('file', uploadedFile);
        formData.append('template', JSON.stringify(selectedTemplate));
        formData.append('documentTitle', JSON.stringify(documentTitle));
        formData.append('targetDepartmentID', JSON.stringify(
            selectedTemplate
            && selectedTemplate.DOCTYPE
            && selectedTemplate.DOCTYPE.DOC_REQUEST_RECEIVING_DEPARTMENT_ID
        ));
        formData.append('documentMetadata', JSON.stringify(documentMetadata));
        formData.append('docIDsToBecomeLinks', JSON.stringify(docIDsToBecomeLinks));

        const res = await axios.post(
            '/api/doc-manager/contracts/request/upload-file',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
        if (res.data.success) {
            clearAnyFilledOutData();
            setUploadedFile(null);
            setActiveStep(3);
        }
        else {
            alert('Something went wrong.');
        }
        setIsSaving(false);
    }

    const stepsText = [
        'Upload a File',
        'Select a Contract Type',
        'Enter Contract Information',
        'Finish'
    ];

    const steps = stepsText.map((text, idx) => (
        <Step key={idx}>
            <StepLabel>{text}</StepLabel>
        </Step>
    ));

    const stepper = (
        <Stepper
            activeStep={activeStep}
            alternativeLabel
        >
            {steps}
        </Stepper>
    );

    const documentUploadButton = (
        <div className={classes.fileUploadButton}>
            <input
                type={'file'}
                onChange={(e) => handleFileUpload(e.currentTarget.files)}
            />
        </div>
    );

    const contractTemplateSelectMenuOptions = allTemplates
        .filter(template => {
            return (
                    uploadedFile
                &&  template.MIME_TYPE
                && (template.MIME_TYPE.MIME_TYPE === uploadedFile.type
                    || isMimeTypeDocButFileIsDocX(template.MIME_TYPE.MIME_TYPE, uploadedFile.type))
            );
        })
        .map(template => {
            return {
                label: template.NAME,
                value: template.TEMPLATEID.toString()
            };
    });

    const templateSelector = contractTemplateSelectMenuOptions.length ? (
        <>
            <span>Contract Type: </span>
            <div className={classes.contractTypeSelectMenuContainerDiv}>
                <Select
                    options={(contractTemplateSelectMenuOptions as any[])}
                    onChange={(option: any) => handleContractTypeChange(option.value)}
                />
            </div>
        </>
    ) : (
        <p className={classes.errorMessage}>No available contract templates match this file type.</p>
    );

    const pagesGroupsAndFieldsRendered = pagesGroupsAndFields.map((page, idx) => {
        return (
            <FieldPage
                key={idx}
                name={page.NAME}
                groups={page.CHILDREN || []}
                updateDocumentMetadata={(data) => updateDocumentMetadata(data)}
            />
        );
    });

    const linkSelector = (
        <div className={classes.linkSelectorDiv}>
            <h3>Link to existing Lucidoc documents:</h3>
            <div className={classes.linkSelectorDivHalfWidthColumn}>
                <Select
                    isMulti={true}
                    options={documentsMappedForSelectMenu}
                    onChange={(values: any) => {
                        const justDocIDs = values.map((value: any) => value.value);
                        setDocIDsToBecomeLinks(justDocIDs);
                    }}
                />
            </div>
        </div>
    );

    const documentTitleInput = (
        <div className={classes.docTitleDiv}>
            <h2>
                Document Title:
            </h2>
            <Input
                type={'text'}
                value={documentTitle}
                onChange={(e) => setDocumentTitle(e.currentTarget.value)}
            />
        </div>
    );

    let submitButtonDisabledText = '';
    if (!documentTitle) {
        submitButtonDisabledText = 'Please enter a document title';
    }

    const submitButton = (
        <div>
            <Tooltip title={submitButtonDisabledText}>
                <span>
                    {/* Weird span required due to Material UI's combination Tooltip/Button problem */}
                    <Button
                        variant={'contained'}
                        disabled={!!submitButtonDisabledText}
                        onClick={() => submitDocument().then()}
                    >
                        Submit
                    </Button>
                </span>
            </Tooltip>

            {isSaving &&
                <span>  Saving...</span>
            }

            <br />
            <br />
        </div>
    );

    const successfulUploadDialog = (
        <div className={classes.successfulUploadDialogDiv}>
            <h3>Document uploaded successfully!</h3>
            <p>From here you can...</p>

            <Button
                variant={'contained'}
                startIcon={<InsertDriveFileOutlined />}
                onClick={() => {
                    setUploadedFile(null);
                    setActiveStep(0);
                }}
            >
                Submit Another Document
            </Button>
            <br />
            <br />

            <Button
                variant={'contained'}
                startIcon={<CheckCircleOutline />}
                onClick={() => window.close()}
            >
                Finish
            </Button>

        </div>
    );

    const defaultContextValue: ContractRequestContextType = {
        publishers: publishers,
        departments: departments
    };

    return (
        <ContractRequestContext.Provider value={defaultContextValue}>
            <div className={classes.contractForm}>

                {stepper}

                <fieldset
                    disabled={isSaving}
                    className={classes.invisibleFieldSet}
                >
                    {activeStep <= 2 &&
                        documentUploadButton
                    }

                    {(activeStep === 1 || activeStep === 2) &&
                        templateSelector
                    }

                    {activeStep === 2 &&
                        <>
                            {documentTitleInput}
                            {pagesGroupsAndFieldsRendered}
                            {linkSelector}
                            {submitButton}
                        </>
                    }

                    {activeStep === 3 &&
                        successfulUploadDialog
                    }
                </fieldset>
            </div>
        </ContractRequestContext.Provider>
    );
}
