import {
    Column,
    Entity,
    JoinColumn,
    ManyToOne,
    OneToOne,
    PrimaryColumn,
} from 'typeorm';
import { stringToBooleanTransformer } from '../_helperFunctions/transformers';
import { DOCUMENTREVISION_LINK, } from '../org/DOCUMENTREVISION_LINK';
import { ORGANIZATION, } from './ORGANIZATION';
import { PERSON_LINK, } from './PERSON_LINK';
import { SchemaEntityManager } from '../SchemaEntityManager';
import { REFERENCE_TYPE_PL } from '../org/REFERENCE_TYPE_PL';

/** IMPORTANT!
 *  To completely create a link, you must create one of these records, AND ALSO a record
 *  in the DOCUMENTREVISION_LINK table (stored in each org schema, not master).
 */

/**
 * To completely create a link, you must create one of these records, AND ALSO a record
 *  in the DOCUMENTREVISION_LINK table (stored in each org schema, not master).
 * 
 * @param schema - This schema is the organization schema. LINK has a decorator specifying schema: MASTER, but still has relations to a parameter specified organization schema
 * 
 * @returns 
 */
function createLinkEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('LINK', { schema: 'MASTER' })
    class LINK {
        static SCHEMANAME = schema;

        @PrimaryColumn('number', {
            nullable: false,
            name: 'LINK_ID'
        })
        LINK_ID!: number;


        @Column('varchar2', {
            nullable: true,
            length: 1000,
            name: 'REFERENCED_URL'
        })
        REFERENCED_URL!: string | null;


        @Column('varchar2', {
            nullable: true,
            length: 500,
            name: 'REFERENCED_URL_TITLE'
        })
        REFERENCED_URL_TITLE!: string | null;


        @Column('number', {
            nullable: true,
            name: 'REFERENCED_ORGANIZATIONID'
        })
        REFERENCED_ORGANIZATIONID!: number | null;

        @ManyToOne(
            () => manager.getOrganizationEntity(schema),
            (organization) => organization.LINKS
        )
        @JoinColumn({
            name: 'REFERENCED_ORGANIZATIONID'
        })
        REFERENCED_ORG!: ORGANIZATION | null;


        @Column('number', {
            nullable: true,
            name: 'REFERENCED_DOCID'
        })
        REFERENCED_DOCID!: number | null;

        // Links live in the Master table, but the documents they refer to are stored in org-specific
        // schemas, often requiring cross-schema communication. To get the latest title of a linked document, run getLatestDocTitleFromLink(), then store it in
        // this value if necessary:
        LATEST_REFERENCED_DOC_TITLE?: string | null;


        @Column('number', {
            nullable: true,
            name: 'REFERENCE_TYPE_ID'
        })
        REFERENCE_TYPE_ID!: number | null;


        @Column('varchar2', {
            nullable: true,
            length: 2000,
            name: 'NOTES'
        })
        NOTES!: string | null;


        @Column('number', {
            nullable: true,
            name: 'LIST_INDEX'
        })
        LIST_INDEX!: number | null;


        @Column('char', {
            nullable: false,
            default: () => '0',
            name: 'IS_VISIBLE',
            transformer: stringToBooleanTransformer
        })
        IS_VISIBLE!: boolean;


        @Column('number', {
            nullable: true,
            name: 'PUBLISHER_LINK_ID'
        })
        PUBLISHER_LINK_ID!: number | null;

        @OneToOne(
            () => manager.getDocumentRevisionLinkEntity(schema),
            (documentRevisionLink) => documentRevisionLink.LINK
        )
        DOCUMENTREVISION_LINK!: DOCUMENTREVISION_LINK | null;



        @ManyToOne(
            () => manager.getReferenceTypePlEntity(schema),
            (referenceType) => referenceType.LINKS
        )
        @JoinColumn({
            name: 'REFERENCE_TYPE_ID'
        })
        REFERENCETYPEPL!: REFERENCE_TYPE_PL | null;

        @OneToOne(
            () => manager.getPersonLinkEntity(schema),
            (personLink) => personLink.LINK
        )
        PERSON_LINK!: PERSON_LINK | null;
    }

    return LINK;
}

export { createLinkEntity };
export type LINK = InstanceType<ReturnType<typeof createLinkEntity>>;


export interface FavoriteLink extends LINK {
    archived?: boolean;
}