import React, { useContext } from 'react';
import { ORGANIZATION } from '@/entities/master/ORGANIZATION';
import { NavigationMenuContent, NavigationMenuItem, NavigationMenuTrigger } from '@/ui/ui/navigation-menu';
import { NavigationMenuContentDropdown, NavigationDropdownSectionEntry, NavigationDropdownSectionEntryLabel, NavigationDropdownTitle, NavigationMenuFooter } from '@/ui/ui/navigation-dropdown';
import { CreateDocumentIcon } from '@/ui/icons/box/CreateDocument';
import { RequestContractIcon } from '@/ui/icons/box/RequestContract';
import { AcknowledgementIcon } from '@/ui/icons/box/AcknowledgementIcon';
import { ReviewIcon } from '@/ui/icons/box/ReviewIcon';
import { SignatureIcon } from '@/ui/icons/box/SignatureIcon';
import { CreateReportIcon } from '@/ui/icons/box/CreateReport';
import { WhatsNewIcon } from '@/ui/icons/box/WhatsNewIcon';
import { useOrgBaseURL } from '@/hooks/useOrgBaseURL';
import UserBundleContext from '@/context/UserBundleContext';
import { RightsCodeKey } from '@/entities/master/PERSON';
import { hasRight } from '@/_shared/utils/hasRights';

interface HeaderDocumentsDropdownProps {
    organization: ORGANIZATION | null;
}

export const HeaderDocumentsDropdown: React.FC<HeaderDocumentsDropdownProps> = ({ organization }) => {

    const orgBaseURL = useOrgBaseURL();

    const userBundle = useContext(UserBundleContext);
    
    const hasReaderRights = hasRight(userBundle, RightsCodeKey.Reader);
    const hasAuthorRights = hasRight(userBundle, RightsCodeKey.Author);
    const hasSignerRights = hasRight(userBundle, RightsCodeKey.Signer);
    const hasContractRequestRights = userBundle.organization?.ALLOW_DOC_REQUESTS && hasRight(userBundle, RightsCodeKey.Reader);

    return (
        <NavigationMenuItem>
            <NavigationMenuTrigger>
                <p className='text-primary'>Documents</p>
            </NavigationMenuTrigger>
            <NavigationMenuContent>
                <NavigationMenuContentDropdown
                    footer={
                        <NavigationMenuFooter href='/docmgr2/docmanager.pl'>
                        Go to document manager...
                    </NavigationMenuFooter>
                    }
                >
                    <NavigationDropdownTitle>
                        Documents
                    </NavigationDropdownTitle>
                    <NavigationDropdownSectionEntryLabel>
                        documents
                    </NavigationDropdownSectionEntryLabel>
                    {
                        hasAuthorRights &&
                            <NavigationDropdownSectionEntry
                                icon={<CreateDocumentIcon size={28} />}
                                href='/docmgr2/createdoc.pl'
                                title='Create Document'
                                description={`Create a document in ${ organization?.NAME}`}
                                external
                            />
                    }
                    {
                        hasContractRequestRights &&
                            <NavigationDropdownSectionEntry
                                icon={<RequestContractIcon size={28} />}
                                href='/contracts/request'
                                title='Request Contract'
                                description={`Request a contract in ${ organization?.NAME}`}
                                external={false}
                            />
                    }
                    {
                        hasReaderRights &&
                            <NavigationDropdownSectionEntry
                                icon={<AcknowledgementIcon size={28} />}
                                href='/cgi/my_acknowledgements.pl'
                                title='To Acknowledge'
                                description='View current, past, and future acknowledgement requests'
                                external
                            />
                    }
                    {
                        hasAuthorRights &&
                            <NavigationDropdownSectionEntry
                                icon={<ReviewIcon size={28} />}
                                href='/docmgr2/docmanager.pl?repname=Expiring'
                                title='To Review/Revise'
                                description='View your to review/revise folder'
                                external
                            />
                    }
                    {
                        hasSignerRights &&
                            <NavigationDropdownSectionEntry
                                icon={<SignatureIcon size={28} />}
                                href='/docmgr2/docmanager.pl?repname=Sign'
                                title='To Sign'
                                description='View documents requesting your signature'
                                external
                            />
                    }
                    <NavigationDropdownSectionEntryLabel>
                        reports
                    </NavigationDropdownSectionEntryLabel>
                    {
                        hasReaderRights &&
                            <NavigationDropdownSectionEntry
                                icon={<CreateReportIcon size={28} />}
                                href='/docmgr2/createrep.pl'
                                title='Create Report'
                                description='Create a document report with criteria of your choosing'
                                external
                            />
                    }
                    {
                        hasReaderRights &&
                            <NavigationDropdownSectionEntry
                                icon={<WhatsNewIcon size={28} />}
                                href={`${orgBaseURL}/whatsnew`}
                                title='What’s New'
                                description='See a report of newly created documents'
                                external={false}
                            />
                    }
                </NavigationMenuContentDropdown>
            </NavigationMenuContent>
        </NavigationMenuItem>
    );
};
