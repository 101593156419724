import {
    Entity,
    JoinColumn,
    ManyToOne,
    PrimaryColumn,
} from 'typeorm';

import { COMMITTEE_APPROVAL_SESSION,  } from './COMMITTEE_APPROVAL_SESSION';
import { COMMITTEE,  } from './COMMITTEE';
import { SchemaEntityManager } from '../SchemaEntityManager';


function createCommitteeApprovalRequestEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('COMMITTEE_APPROVAL_REQUEST', { schema })
    class COMMITTEE_APPROVAL_REQUEST {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            name: 'COMMITTEE_APPROVAL_SESSION_ID'
        })
        COMMITTEE_APPROVAL_SESSION_ID!: number;

        @ManyToOne(
            () => manager.getCommitteeApprovalSessionEntity(schema),
            COMMITTEE_APPROVAL_SESSION => COMMITTEE_APPROVAL_SESSION.COMMITTEE_APPROVAL_REQUESTS
        )
        @JoinColumn({
            name: 'COMMITTEE_APPROVAL_SESSION_ID',
            // referencedColumnName: 'COMMITTEE_APPROVAL_SESSION_ID'
        })
        COMMITTEE_APPROVAL_SESSION!: COMMITTEE_APPROVAL_SESSION | null;

        @PrimaryColumn('number', {
            nullable: false,
            name: 'COMMITTEE_ID'
        })
        COMMITTEE_ID!: number;

        @ManyToOne(
            () => manager.getCommitteeEntity(schema),
            COMMITTEE => COMMITTEE.COMMITTEE_APPROVAL_REQUESTS
        )
        @JoinColumn({
            name: 'COMMITTEE_ID'
        })
        COMMITTEE!: COMMITTEE | null;

        @PrimaryColumn('timestamp', {
            nullable: false,
            default: () => 'SYSDATE',
            name: 'REQUEST_DATE'
        })
        REQUEST_DATE!: Date;

        @PrimaryColumn('number', {
            nullable: false,
            name: 'PHASE'
        })
        PHASE!: number;
    }

    return COMMITTEE_APPROVAL_REQUEST;
}

export { createCommitteeApprovalRequestEntity };
export type COMMITTEE_APPROVAL_REQUEST = InstanceType<ReturnType<typeof createCommitteeApprovalRequestEntity>>;