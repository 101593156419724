import {
    Column,
    Entity,
    JoinColumn,
    ManyToOne,
    PrimaryColumn,
} from 'typeorm';

import { ORGANIZATION,  } from './ORGANIZATION';
import { PERSON, rightsCodes,  } from './PERSON';
import { produceRightsObjectFromString } from '../_helperFunctions/rights/produceRightsObjectFromString';
import { SchemaEntityManager } from '../SchemaEntityManager';

export enum PersonOrganizationStatuses {
    ACTIVE = 1,
    INACTIVE = 2,
    INVISIBLE = 3,
}

/**
 * To completely create a link, you must create one of these records, AND ALSO a record
 *  in the DOCUMENTREVISION_LINK table (stored in each org schema, not master).
 * 
 * @param schema - This schema is the organization schema. PERSON_ORGANIZATION has a decorator specifying schema: MASTER, but still has relations to a parameter specified organization schema
 * 
 * @returns 
 */

function createPersonOrganizationEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('PERSON_ORGANIZATION', { schema: 'MASTER' })
    class PERSON_ORGANIZATION {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'PERSON_ORGANIZATIONID'
        })
        PERSON_ORGANIZATIONID!: number;


        @Column('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'USERID'
        })
        USERID!: number;

        @ManyToOne(
            () => manager.getPersonEntity(schema),
            PERSON => PERSON.PERSON_ORGANIZATIONS
        )
        @JoinColumn({
            name: 'USERID'
        })
        PERSON!: PERSON | null;

        @Column('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'ORGANIZATIONID'
        })
        ORGANIZATIONID!: number;

        @ManyToOne(
            () => manager.getOrganizationEntity(schema),
            (organization) => organization.PERSON_ORGANIZATIONS
        )
        @JoinColumn({
            name: 'ORGANIZATIONID'
        })
        ORGANIZATION!: ORGANIZATION | null;


        @Column('char', {
            nullable: true,
            length: 32,
            name: 'RIGHTS'
        })
        RIGHTS!: string | null;


        RIGHTSINENGLISH?: { [key in keyof typeof rightsCodes]: boolean };

        getRightsInEnglish() {
            this.RIGHTSINENGLISH = produceRightsObjectFromString(this.RIGHTS);
        }


        @Column('number', {
            nullable: true,
            precision: 3,
            scale: 0,
            name: 'ACCESSLEVEL'
        })
        ACCESSLEVEL!: number | null;


        @Column('number', {
            nullable: true,
            default: () => '1',
            precision: 10,
            scale: 0,
            name: 'STATUSID'
        })
        STATUSID!: number | null;


        @Column('date', {
            nullable: true,
            name: 'STATUSDT'
        })
        STATUSDT!: Date | null;

    }

    return PERSON_ORGANIZATION;
}

export { createPersonOrganizationEntity };
export type PERSON_ORGANIZATION = InstanceType<ReturnType<typeof createPersonOrganizationEntity>>;