import {
    Column,
    Entity,
    JoinColumn,
    ManyToOne,
    PrimaryColumn,
} from 'typeorm';

import { type CONSENT_AGENDA,  } from './CONSENT_AGENDA';
import { type CONSENT_AGENDA_TASK,  } from './CONSENT_AGENDA_TASK';
import { type CONSENT_AGENDA_HEADING,  } from './CONSENT_AGENDA_HEADING';
import { SchemaEntityManager } from '../SchemaEntityManager';

export enum ConsentAgendaAuditLogActions {
    add = 'add',
    adjourn = 'adjourn',
    approve = 'approve',
    approve_with_edits = 'approve_with_edits',
    finalize = 'finalize',
    pdf_request = 'pdf_request',
    reject = 'reject',
    release = 'release',
    remove = 'remove',
    rerelease = 'rerelease',
    unadjourn = 'unadjourn',
    unfinalize = 'unfinalize',
}

// Factory function to create CONSENT_AGENDA_AUDIT_LOG entity with dynamic schema
function createConsentAgendaAuditLogEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('CONSENT_AGENDA_AUDIT_LOG', { schema })
    class CONSENT_AGENDA_AUDIT_LOG {
        static SCHEMANAME = schema;
        @Column('number', {
            nullable: true,
            name: 'CONSENT_AGENDA_ID'
        })
        CONSENT_AGENDA_ID!: number | null;

        @ManyToOne(
            () => manager.getConsentAgendaEntity(schema),
            consentAgenda => consentAgenda.CONSENT_AGENDA_AUDIT_LOGS
        )
        @JoinColumn({
            name: 'CONSENT_AGENDA_ID',
            referencedColumnName: 'CONSENT_AGENDA_ID'
        })
        CONSENT_AGENDA!: CONSENT_AGENDA;

        @Column('number', {
            nullable: true,
            name: 'HEADING_ID'
        })
        HEADING_ID!: number | null;

        @Column('number', {
            nullable: true,
            name: 'TASK_ID'
        })
        TASK_ID!: number | null;

        @PrimaryColumn('varchar2', {
            nullable: false,
            length: 30,
            name: 'ACTION'
        })
        ACTION!: string;

        @PrimaryColumn('number', {
            nullable: false,
            name: 'ACTOR_USERID'
        })
        ACTOR_USERID!: number;

        @PrimaryColumn('date', {
            nullable: false,
            default: () => 'SYSDATE',
            name: 'TIMESTAMP'
        })
        TIMESTAMP!: Date;

        @Column('number', {
            nullable: true,
            name: 'REFERENCE_ID'
        })
        REFERENCE_ID!: number | null;

        @ManyToOne(
            () => manager.getConsentAgendaHeadingEntity(schema),
            consentAgendaHeading => consentAgendaHeading
        )
        @JoinColumn({
            name: 'HEADING_ID',
            referencedColumnName: 'HEADING_ID'
        })
        CONSENT_AGENDA_HEADING!: CONSENT_AGENDA_HEADING;

        @ManyToOne(
            () => manager.getConsentAgendaTaskEntity(schema),
            consentAgendaTask => consentAgendaTask.CONSENT_AGENDA_AUDIT_LOGS
        )
        @JoinColumn({
            name: 'TASK_ID',
            referencedColumnName: 'TASK_ID'
        })
        CONSENT_AGENDA_TASK!: CONSENT_AGENDA_TASK;

        static Actions = ConsentAgendaAuditLogActions;
    }

    return CONSENT_AGENDA_AUDIT_LOG;
}

// Export the factory function
export { createConsentAgendaAuditLogEntity };

// Define and export the type for instances created by the factory function
export type CONSENT_AGENDA_AUDIT_LOG = InstanceType<ReturnType<typeof createConsentAgendaAuditLogEntity>>;
