import {
    Column,
    Entity,
    JoinColumn,
    ManyToOne,
    OneToMany,
    PrimaryColumn,
} from 'typeorm';
import { CE_COURSE_SESSION,  } from './CE_COURSE_SESSION';
import { CE_COURSE_ATTEMPT,  } from './CE_COURSE_ATTEMPT';
import { SchemaEntityManager } from '../SchemaEntityManager';

// Factory function to create CE_ENROLLMENT_SNAPSHOT entity with dynamic schema
function createCeEnrollmentSnapshotEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('CE_ENROLLMENT_SNAPSHOT', { schema })
    class CE_ENROLLMENT_SNAPSHOT {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            name: 'CE_ENROLLMENT_SNAPSHOT_ID'
        })
        CE_ENROLLMENT_SNAPSHOT_ID!: number;

        @Column('timestamp', {
            nullable: false,
            name: 'START_DATE'
        })
        START_DATE!: Date;

        @Column('timestamp', {
            nullable: true,
            name: 'END_DATE'
        })
        END_DATE!: Date | null;

        @Column('timestamp', {
            nullable: true,
            name: 'LAST_MODIFIED_DATE'
        })
        LAST_MODIFIED_DATE!: Date | null;

        @Column('number', {
            nullable: true,
            name: 'LAST_MODIFIED_BY_USERID'
        })
        LAST_MODIFIED_BY_USERID!: number | null;

        @Column('number', {
            nullable: false,
            name: 'CE_COURSE_SESSION_ID'
        })
        CE_COURSE_SESSION_ID!: number;

        @ManyToOne(
            () => manager.getCeCourseSessionEntity(schema),
            (ceCourseSession) => ceCourseSession.CE_ENROLLMENT_SNAPSHOTS
        )
        @JoinColumn({
            name: 'CE_COURSE_SESSION_ID'
        })
        CE_COURSE_SESSION!: CE_COURSE_SESSION | null;

        @Column('number', {
            nullable: false,
            name: 'USERID'
        })
        USERID!: number;

        @Column('number', {
            nullable: true,
            name: 'VIA_USERGROUPID'
        })
        VIA_USERGROUPID!: number | null;

        @OneToMany(
            () => manager.getCeCourseAttemptEntity(schema),
            (ceCourseAttempt) => ceCourseAttempt.CE_ENROLLMENT_SNAPSHOT
        )
        CE_COURSE_ATTEMPTS!: CE_COURSE_ATTEMPT[] | null;
    }

    return CE_ENROLLMENT_SNAPSHOT;
}

// Export the factory function
export { createCeEnrollmentSnapshotEntity };

// Export the type for CE_ENROLLMENT_SNAPSHOT entities created by the factory function
export type CE_ENROLLMENT_SNAPSHOT = InstanceType<ReturnType<typeof createCeEnrollmentSnapshotEntity>>;
