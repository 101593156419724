import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import { MultiOrgComponents } from './components/MultiOrg/MultiOrgComponents';
import UserBundleContext from '../../context/UserBundleContext';
import ApprovalRouting from '../ApprovalRouting/ApprovalRouting';
import ApprovalRouteEditor from '../ApprovalRouteEditor/ApprovalRouteEditor';
import ComplianceReports from '../Administration/components/ComplianceReports/ComplianceReports';
import hasRights from '../../_shared/utils/hasRights';
import { EditDoc } from './components/EditDoc/EditDoc';
import { ManageDepartments } from './components/Departments/ManageDepartments';
import { ManageManuals } from './components/Manuals/ManageManuals';
import { RightsCodeKey } from '@/entities/master/PERSON';

export function DocManager() {
    const context = useContext(UserBundleContext);
    const hasDocManagerAccess = hasRights(context, [RightsCodeKey.Signer, RightsCodeKey.Author, RightsCodeKey.CourseWriter, RightsCodeKey.DocumentAdministrator]);
    const isAdmin = hasRights(context, [RightsCodeKey.DocumentAdministrator, RightsCodeKey.UserAdministrator, RightsCodeKey.OrganizationAdministrator]);

    const routes = hasDocManagerAccess ? [
        <Route path='/docmanager/editdoc' component={EditDoc}/>,
        <Route exact path='/docmanager/approval-route-editor/:objectType/:objectID' component={ApprovalRouteEditor}/>,
        <Route path={'/docmanager/departments'} component={ManageDepartments}/>,
        <Route path={'/docmanager/manuals'} component={ManageManuals}/>,
    ] : [];

    if (hasDocManagerAccess || isAdmin) {
        routes.push(<Route path={'/docmanager/compliance-reports'} component={ComplianceReports}/>);
    }

    if (hasDocManagerAccess && context.user?.ROUTER) {
        routes.push(<Route path='/docmanager/approval-routing' component={ApprovalRouting}/>);
    }

    if (hasDocManagerAccess && context.user?.REPRESENTATIVE_CONTACT) {
        routes.push(<Route path={'/docmanager/multiorg'} component={MultiOrgComponents} />);
    }

    return (
        <div>
            <Switch>
                {routes}
            </Switch>
        </div>
    );
}
