import {
    Column,
    Entity,
    JoinColumn,
    ManyToOne,
    PrimaryColumn,
} from 'typeorm';
import { numberToBooleanTransformer } from '../_helperFunctions/transformers';
import { PERSON,  } from '../master/PERSON';
import { SchemaEntityManager } from '../SchemaEntityManager';

// Define the factory function
function createMessageBoardItemEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('MESSAGE_BOARD_ITEM', { schema: schema })
    class MESSAGE_BOARD_ITEM {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'MESSAGE_BOARD_ITEM_ID'
        })
        MESSAGE_BOARD_ITEM_ID!: number;

        @Column('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'USERID'
        })
        USERID!: number | null;

        @ManyToOne(
            () => manager.getPersonEntity(schema), 
            (person) => person.MESSAGE_BOARD_ITEMS
        )
        @JoinColumn({
            name: 'USERID'
        })
        USER!: PERSON | null;

        @Column('varchar2', {
            nullable: false,
            length: 1024,
            name: 'MESSAGE',
        })
        MESSAGE!: string | null;

        @Column('timestamp', {
            nullable: false,
            default: () => 'SYSDATE',
            name: 'CREATED_DATE',
        })
        CREATED_DATE!: Date | null;

        @Column('timestamp', {
            nullable: true,
            name: 'EDITED_DATE',
        })
        EDITED_DATE!: Date | null;

        @Column('number', {
            nullable: false,
            precision: 1,
            scale: 0,
            default: () => 1,
            transformer: numberToBooleanTransformer,
            name: 'VISIBLE',
        })
        VISIBLE!: boolean | null;

        @Column('number', {
            nullable: true,
            precision: 1,
            scale: 0,
            transformer: numberToBooleanTransformer,
            name: 'IS_FROM_MULTI_ORG'
        })
        IS_FROM_MULTI_ORG!: boolean | null;
    }

    return MESSAGE_BOARD_ITEM;
}

// Export the factory function
export { createMessageBoardItemEntity };

// Export the type representing an instance of the class generated by the factory function
export type MESSAGE_BOARD_ITEM = InstanceType<ReturnType<typeof createMessageBoardItemEntity>>;