import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import LucidocColors from '../../../constants/LucidocColors';

type FeatureFlagModalProps = {
    open: boolean
    title: string
    paperClass?: string
    children: JSX.Element | JSX.Element[]
    onSave?: (formData: FormData) => void
    onClose: () => void
};

export default function FeatureFlagModal(props: FeatureFlagModalProps) {
    return (
        <Dialog
            open={props.open}
            onClose={() => props.onClose()}
            color={LucidocColors.lightGray}
            classes={{
                paper: props.paperClass
            }}
            PaperProps={{
                component: 'form',
                onSubmit: (event) => {
                    event.preventDefault();

                    const currentTarget = event.currentTarget as unknown as HTMLFormElement;
                    const formData = new FormData(currentTarget);

                    props.onSave?.(formData);
                    props.onClose();
                }
            }}
        >
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>
                {props.children}
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={() => props.onClose()}>Cancel</Button>
                {props.onSave && <Button variant='contained' type='submit'>Submit</Button>}
            </DialogActions>
        </Dialog>
    );
}