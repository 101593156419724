import { cn } from '@/lib/utils';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/ui/ui/tooltip';
import React from 'react';

interface DashboardCardProps {
    children: React.ReactNode;
    disableMinWidth?: boolean;
    disableMaxHeight?: boolean;
}

export const DashboardCard: React.FC<DashboardCardProps> = ({ children, disableMinWidth, disableMaxHeight }) => {
    return (
        <div className={cn('flex flex-col relative shadow-md p-4 rounded-md border border-border md:min-w-[25rem] mr-4 mt-4 bg-card',
            !disableMinWidth ? 'min-w-full' : 'min-w-full flex-shrink md:flex-grow', 
            !disableMaxHeight ? 'h-[22.25rem]' : 'h-auto')}>
            {children}
        </div>
    );
};

interface DashboardCardToolbarProps {  
    children: React.ReactNode;  
    className?: string;  
}  

export const DashboardCardToolbar: React.FC<DashboardCardToolbarProps> = ({ children, className = '' }) => {
    return (
        <div className={cn('flex flex-row space-x-4 p-4 absolute right-0 top-0', className)}>
            {children}
        </div>
    );
};

interface DashboardCardToolbarItemProps {
    tooltip: string;
    onClick?: () => void;
}

export const DashboardCardToolbarItem: React.FC<DashboardCardToolbarItemProps> = ({ children, onClick, tooltip }) => {
    return <TooltipProvider>
        <Tooltip
            delayDuration={100}
        >
            <TooltipTrigger onClick={onClick}>
                {children}
            </TooltipTrigger>
            <TooltipContent side='bottom' >
                <p>{tooltip}</p>
            </TooltipContent>
        </Tooltip>
    </TooltipProvider>;
};

export const DashboardCardTitle: React.FC = ({ children }) => {
    return (
        <p className='text-lg font-semibold text-primary'>
            {children}
        </p>
    );
};

export const DashboardCardContent: React.FC = ({ children }) => {
    return (
        <div className='flex flex-col mt-2 w-full overflow-auto h-full'>
            {children}
        </div>
    );
};
