import axios from 'axios';
import { DocRevEntry } from './DocumentRevisionTable';
import { buildDocURL } from '@/_shared/utils/docManagerFunctions';
import { TableExportOptions } from '@/ui/ui/datatable/data-table';
import { DocumentRevisionStatuses } from '@/entities/org/DOCUMENTREVISION';

export const getDocumentsTableExtraHeaders = (isContentSearch: boolean) => {
    const extraHeaders = [
        'Revision Note',
        'Links Referenced By This Document',
        'Documents which include this document as a link',
    ];


    if (isContentSearch) {
        extraHeaders.push('Snippet');
    }

    return extraHeaders;
};

export const renderDocumentsTableExpandedRow = async (entry: DocRevEntry, extraHeaders: string[], contentFilter: string, exportType: TableExportOptions): Promise<string[]> => {
    const metadataResp = await axios.get('/api/_shared/tables/metadata-for-docrev', {
        params: {
            docrevID: entry.revId
        }
    });

    const metadata = metadataResp.data;


    const links = metadata.links.length > 0 ? metadata.links?.map(l => {
        return `${l.docRev.TITLE} - ${window.location.origin}${buildDocURL(l.organization.PREFIX!, l.docRev.DOCID)}`;
    }) : 'No links found.';
    const docsThatIncludeThisDocumentAsALink = metadata.docsThatIncludeThisDocumentAsALink.links > 0 ?
        metadata.docsThatIncludeThisDocumentAsALink?.map(link => {
            return `${link.DOCUMENTREVISION_LINK.DOCREV?.TITLE} - ${window.location.origin}${buildDocURL(link.REFERENCED_ORG.PREFIX!, link.DOCUMENTREVISION_LINK.DOCREV?.DOCID)}`;
        }) : 'No links found.';


    const data = [
        metadata.revisionNote ?? 'No revision note found.',
        links,
        docsThatIncludeThisDocumentAsALink
    ];

    if (extraHeaders.length === 4) {
        const snippet = await axios.post('/api/homepage/search/get-snippet', {
            blobId: entry.blobId,
            content: contentFilter,
            orgID: entry.orgId
        });


        let snippetData = snippet.data.snippet;

        if (exportType !== 'pdf') {
            // We need to strip data out because the pdf render is actually html -> pdf.
            // So the html is fine for pdf, but for rest, we need to extract text.
            const parser = new DOMParser();
            const parsedSnippet = parser.parseFromString(snippetData, 'text/html');
            snippetData = parsedSnippet.body.textContent ?? '';
        }

        data.push(snippetData);
    }

    return data;
};


export const exportTableRequest = (exportType: TableExportOptions, headers: string[], rows: string[][], exportAsFileName: string) => {
    axios.post(`/api/_shared/tables/export/${exportType}`, {
        headers: headers,
        rows: rows
    }, {
        responseType: 'blob'
    }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${exportAsFileName}.${exportType}`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url); // free up resources
    }).catch((error) => alert(`Failed to export table to ${exportType}\n` + error));
};

export const FacetedFilterDocumentStatuses = [
    {
        label: 'In Preparation',
        value: DocumentRevisionStatuses.prep.toString()
    },
    {
        label: 'Draft',
        value: DocumentRevisionStatuses.draft.toString()
    },
    {
        label: 'Official',
        value: DocumentRevisionStatuses.official.toString()
    },
    {
        label: 'Obsolete',
        value: DocumentRevisionStatuses.obsolete.toString()
    },
    {
        label: 'Archived',
        value: DocumentRevisionStatuses.archive.toString()
    },
    {
        label: 'Invisible',
        value: DocumentRevisionStatuses.invisible.toString()
    },
    {
        label: 'Pending Signature',
        value: DocumentRevisionStatuses.prepsig.toString()
    },
    {
        label: 'Discard',
        value: DocumentRevisionStatuses.discard.toString()
    },
    {
        label: 'Pending Release',
        value: DocumentRevisionStatuses.preprel.toString()
    },
    {
        label: 'Pending Committee Routing',
        value: DocumentRevisionStatuses.pending_committee_routing.toString()
    },
    {
        label: 'Pending Committee Approval',
        value: DocumentRevisionStatuses.pending_committee_approval.toString()
    },
    {
        label: 'Pending Assignment',
        value: DocumentRevisionStatuses.pending_assignment.toString()
    },
    {
        label: 'Pending Approval Routing',
        value: DocumentRevisionStatuses.pending_approval_routing.toString()
    }
];

export function decodeUTF8String(str: string) {
    const encoder = new TextEncoder();
    const decoder = new TextDecoder('utf-8');
    const encoded = encoder.encode(str);
    return decoder.decode(encoded);
}