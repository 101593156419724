import React, { useState } from 'react';

import axios from 'axios';

import { LucidocModal } from '../../../../_shared/LucidocModal/LucidocModal';
import LucidocForm from '../../../../_shared/Forms/LucidocForm/LucidocForm';
import { TextField } from '@material-ui/core';
import SelectUser from '../../../../_shared/Forms/Select/SelectUser';
import { SelectDepartment } from '../../../../../ui/ui/advanced-select/select-department';
import { RightsCodeKey } from '@/entities/master/PERSON';

interface ICreateCommitteeProps {
    onCommitteeCreate: () => void;
    onClose: () => void;
}

const CreateCommittee: React.FC<ICreateCommitteeProps> = (props: ICreateCommitteeProps) => {
    const [name, setName] = useState<string>('');
    const [chairPersonUserId, setChairPersonUserId] = useState<number>();
    const [secretaryUserId, setSecretaryUserId] = useState<number>();
    const [minutesDepartmentId, setMinutesDepartmentId] = useState<number>();

    const createNewCommittee = async () => {
        await axios.post(
            '/api/committee',
            {
                name,
                chairPersonUserId,
                secretaryUserId,
                minutesDepartmentId
            }
        );
        props.onCommitteeCreate();
        props.onClose();
    };

    const displayCreateButton = (
        name !== '' &&
        chairPersonUserId &&
        secretaryUserId
    );

    return <LucidocModal
        open
        onClose={ props.onClose }
        title='Create Committee'
        onSave={ () => createNewCommittee() }
        isSaveDisabled={ !displayCreateButton }
        zIndex={0}
    >
        <div
            style={{
                width: '74vw',
                height: '74vh'
            }}
        >
            <LucidocForm
                width='40%'
                noHeaderSpacing
                fields={[
                    {
                        name: 'Committee Name',
                        description: 'Edit the name of this committee',
                        component: <TextField
                            value={ name }
                            onChange={ event => setName(event.currentTarget.value) }
                            placeholder='Committee Name'
                            variant='outlined'
                            size='small'
                            style={{
                                width: '28rem',
                            }}
                        />
                    },
                    {
                        name: 'Chairperson',
                        component: <SelectUser
                            placeholder='Select chairperson...'
                            rights={[RightsCodeKey.Author]}
                            onChange={ setChairPersonUserId }
                            userId={ chairPersonUserId }
                            getMappedUsers
                        />
                    },
                    {
                        name: 'Secretary',
                        component: <SelectUser
                            placeholder='Select secretary...'
                            rights={[RightsCodeKey.Author]}
                            onChange={ setSecretaryUserId }
                            userId={ secretaryUserId }
                            getMappedUsers
                        />
                    },

                    {
                        name: 'Minutes Department',
                        component: <SelectDepartment
                            placeholder='Select minutes department...'
                            departmentID={ minutesDepartmentId }
                            onChange={ setMinutesDepartmentId }
                        />
                    }
                ]}
            />
        </div>
    </LucidocModal>;

};

export default CreateCommittee;
