import React from 'react';
import { Button, TableCell, TableRow } from '@material-ui/core';
import ReviewApprovalRoute from '../../ApprovalRouteEditor/ReviewApprovalRoute';
import { Edit } from '@material-ui/icons';
import { LoadingIndicator } from '../../_shared/LoadingIndicator';
interface ApprovalRouteWithinTableRowProps {
    objectType: 'docrevid' | 'manualid'
    objectID: number
    objectTitle: string
    setSelectedObjectIDs: (objectIDs: number[]) => void
    setSelectedObjectType: (objectType: 'docrevid' | 'manualid') => void
    setSelectedObjectTitle: (titleToDisplay: string) => void
    setDialogOpen: (dialogOpen: boolean) => void
    approvalRoute?: any
}

export default function ApprovalRouteWithinTableRow(props: ApprovalRouteWithinTableRowProps) {
    if (!props.approvalRoute) {
        return (
            <TableRow>
                <TableCell>
                    {/*action buttons column; leave this blank*/}
                </TableCell>
                <TableCell> </TableCell>
                <TableCell>
                    <LoadingIndicator />
                </TableCell>
                <TableCell> </TableCell>
                <TableCell> </TableCell>
                <TableCell> </TableCell>
                <TableCell> </TableCell>
            </TableRow>
        );
    }

    return (
        <TableRow>
            <TableCell>
                {/*action buttons column; leave this blank*/}
            </TableCell>
            <TableCell> </TableCell>
            <TableCell colSpan={2}>
                <ReviewApprovalRoute
                    signatureApprovalGroup={props.approvalRoute.signatureApprovalGroup}
                    showCommitteeApprovals={props.approvalRoute.showCommitteeApprovals}
                    committeeApprovalGroups={props.approvalRoute.committeeApprovalGroups}
                    requestStatus={props.approvalRoute.requestStatus}
                    scheduledTaskDates={props.approvalRoute.scheduledTaskDates}
                    phaseStatus={props.approvalRoute.phaseStatus}
                    orderedSigning={props.approvalRoute.orderedSigning}
                    phaseOrder={props.approvalRoute.phaseOrder}
                    ratification={props.approvalRoute.ratification}
                    isActive={props.approvalRoute.isActive}
                />
            </TableCell>
            <TableCell style={{ verticalAlign: 'top' }}>
                <Button
                    startIcon={<Edit />}
                    onClick={() => {
                        props.setSelectedObjectType(props.objectType);
                        props.setSelectedObjectIDs([props.objectID]);
                        props.setSelectedObjectTitle(props.objectTitle);
                        props.setDialogOpen(true);}
                    }
                >
                    Edit
                </Button>
            </TableCell>
            <TableCell> </TableCell>
            <TableCell> </TableCell>
        </TableRow>
    );
}
