import UserBundleContext from '@/context/UserBundleContext';
import { DisplayIcon } from '@/ui/icons/box/Display';
import { LockIcon } from '@/ui/icons/box/Lock';
import { OpenBookIcon } from '@/ui/icons/box/OpenBook';
import { PhoneIcon } from '@/ui/icons/box/Phone';
import { ProfileIcon } from '@/ui/icons/box/Profile';
import { NavigationDropdownSectionEntry, NavigationDropdownSectionEntryLabel, NavigationDropdownTitle, NavigationMenuContentDropdown, NavigationMenuFooter } from '@/ui/ui/navigation-dropdown';
import { NavigationMenuItem, NavigationMenuTrigger, NavigationMenuContent } from '@/ui/ui/navigation-menu';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/ui/ui/tabs';
import React, { useContext } from 'react';
import { HeaderUserDropdownFavorites } from './Favorites';
import { HelpTopicContext } from '@/context/HelpTopicContext';
import { helpTopicDocIDs } from '@/_shared/constants/HelpSystemIDs';
import { buildHelpDocURL } from '@/_shared/utils/docManagerFunctions';
import { buildUserProfileURL } from '@/_shared/utils/linkBuilders';
import URLs from '@/_shared/constants/URLs';
import { PreferencesIcon } from '@/ui/icons/box/Preferences';
import { useOrgBaseURL } from '@/hooks/useOrgBaseURL';
import { InfoIcon } from '@/ui/icons/box/Info';
import { constructFullName } from '@/components/UserPicker/utils/constructFullName';
import { FavoritesStar } from '@/ui/ui/favorites-star';

interface HeaderUserDropdownProps {

}

export const HeaderUserDropdown: React.FC<HeaderUserDropdownProps> = () => {

    const userBundle = useContext(UserBundleContext);
    const { helpTopicID } = useContext(HelpTopicContext);
    
    // If GUID is present, SAML logins are enabled. In this case, the user should not be able to change their password.
    const isGUIDPresent = userBundle.user?.GUID !== undefined;

    const helpTopicHref = buildHelpDocURL(helpTopicID ?? helpTopicDocIDs.HOME_ANON);
    const userProfileHref = buildUserProfileURL(userBundle.organization?.ORGANIZATIONID, userBundle.user?.USERID);

    const user = userBundle.user;

    enum TabState {
        User = 'user',
        Favorites = 'favoites'
    }


    const orgBaseUrl = useOrgBaseURL();


    return (
        <NavigationMenuItem>
            <NavigationMenuTrigger>
                <p className='hidden xs:block'>{user ? constructFullName(user, 'firstMiddleLast') : 'user is NULL'}</p>
                <p className='block xs:hidden'>{user ? constructFullName(user, 'firstLastShortened') : 'no user'}</p>
            </NavigationMenuTrigger>
            <NavigationMenuContent>
                <NavigationMenuContentDropdown
                    footer={
                        <NavigationMenuFooter href={'/cgi/login.pl'}>
                            Logout
                        </NavigationMenuFooter>
                    }
                >
                    <FavoritesStar />
                    <NavigationDropdownTitle>
                        {user ? constructFullName(user, 'firstMiddleLast') : 'user is NULL'}
                    </NavigationDropdownTitle>
                    <Tabs defaultValue={TabState.User}>
                        <TabsList variant={'minimal'} className='px-5'>
                            <TabsTrigger value={TabState.User}>User</TabsTrigger>
                            <TabsTrigger value={TabState.Favorites}>Favorites</TabsTrigger>
                        </TabsList>
                        <TabsContent value={TabState.User} className='flex flex-col'>
                            <NavigationDropdownSectionEntryLabel >
                                Support
                            </NavigationDropdownSectionEntryLabel>
                            <NavigationDropdownSectionEntry
                                external={true}
                                icon={<InfoIcon size={28} />}
                                title={'About Lucidoc'}
                                description={'Lucidoc Version ' + userBundle.version}
                                href='/cgi/about.pl'
                                newTab
                            />
                            <NavigationDropdownSectionEntry
                                external={true}
                                icon={<OpenBookIcon size={28} />}
                                title='Read help articles'
                                description='Learn how to use Lucidoc with help articles'
                                href={helpTopicHref}
                                newTab
                            />
                            <NavigationDropdownSectionEntry
                                external={true}
                                icon={<PhoneIcon size={28} />}
                                title='Lucidoc Support Portal'
                                description='Contact a customer support representative for help'
                                href='/admin/support_portal.pl'
                                newTab
                            />
                            <NavigationDropdownSectionEntry
                                external={false}
                                icon={<DisplayIcon size={28} />}
                                title='Training'
                                description='Sign up for a training webinar'
                                href={`${orgBaseUrl}/training`}
                            />
                            <NavigationDropdownSectionEntryLabel>
                                My lucidoc
                            </NavigationDropdownSectionEntryLabel>
                            <NavigationDropdownSectionEntry
                                external={true}
                                icon={<ProfileIcon size={28} />}
                                title='Profile'
                                description='View your profile'
                                href={userProfileHref}
                            />
                            <NavigationDropdownSectionEntry
                                external={false}
                                icon={<PreferencesIcon size={28} />}
                                title='Preferences'
                                description='Configure your preferences'
                                href={`${orgBaseUrl}/preferences`}
                            />
                            {!isGUIDPresent && <NavigationDropdownSectionEntry
                                external={true}
                                icon={<LockIcon size={28} />}
                                title='Security'
                                description='Update your password for Lucidoc'
                                href={URLs.home.password}
                            />}
                        </TabsContent>
                        <TabsContent value={TabState.Favorites}>
                            <HeaderUserDropdownFavorites />
                        </TabsContent>
                    </Tabs>
                </NavigationMenuContentDropdown>
            </NavigationMenuContent>
        </NavigationMenuItem>
    );
};