import { useState } from 'react';
import { INFORMATION_HIERARCHY, InformationHierarchyStatuses } from '../../../entities/org/INFORMATION_HIERARCHY';
import { Button } from '@/ui/ui/button';
import { findNestedIH } from '@/utils/findNestedIH';
import useSWR from 'swr';
import { cn } from '@/lib/utils';
import { AdvancedSelect } from './advanced-select';
import { SearchInfoHierarchies } from '@/v2/components/informationhierarchy/InformationHierachyBrowser';

export type SharedSelectDepartmentProps = {
    // types of manuals to get in the query:
    statuses?: InformationHierarchyStatuses[],
    userID?: number,

    // Select menu controls:
    placeholder?: string;
    disabled?: boolean;

    unselectableDepartments?: INFORMATION_HIERARCHY[];
}

type SelectDepartmentProps = {
    // always used:
    onChange: (depID?: number) => void

    // if used alone (not part of SelectDepartments.tsx):
    departmentID?: number,

    // if used by SelectDepartments.tsx:
    availableDepartmentsFromParent?: INFORMATION_HIERARCHY[] // used by SelectManualMulti so we only do one DB query and the parent hands the info down to the child

    isClearable?: boolean

    // useful if you want the first option to say "None" (sometimes more helpful than the isClearable X button)
    dummyValue?: {
        label: string,
        value: number // probably a zero and you'll handle it on the back end with if (!)
    }
}

export function SelectDepartment(props: SelectDepartmentProps & SharedSelectDepartmentProps) {

    interface Departments {
        departments: INFORMATION_HIERARCHY[]
    }

    const { data: departments } = useSWR<Departments>({
        url: '/api/_shared/forms/select/select-departments/',
        params: {
            statuses: props.statuses,
            userID: props.userID
        }
    });

    const selectMenuValue = findNestedIH(departments?.departments ?? [], props.departmentID ?? -1);

    const [filterText, setFilterText] = useState('');

    const renderDepartments = (departments: INFORMATION_HIERARCHY[], indentationLevel: number = 0) => {
        // Sort departments alphabetically by TITLE
        const filteredDepartments = SearchInfoHierarchies(departments, filterText);
        const sortedDepartments = filteredDepartments.sort((a, b) => a.TITLE.localeCompare(b.TITLE));

        return sortedDepartments.map(department => {
            const hasChildren = department.CHILDREN && department.CHILDREN.length > 0;
            const indentStyle = {
                paddingLeft: `${indentationLevel * 5}px`, // Control the indentation
            };

            return (
                <div key={department.INFORMATION_HIERARCHY_ID} className={cn('w-full', indentationLevel > 1 && 'border-border border-l-2')} style={indentStyle}>
                    {hasChildren ? <>
                        <Button 
                            variant='ghost'
                            onClick={() => props.onChange(department.INFORMATION_HIERARCHY_ID)} 
                            className='justify-start w-full py-2 uppercase text-muted-foreground font-medium'

                        >
                            {department.TITLE}
                        </Button>
                        {renderDepartments(department.CHILDREN!, indentationLevel + 1)}
                    </>
                        :
                        <Button className='w-full justify-start' onClick={() => props.onChange(department.INFORMATION_HIERARCHY_ID)} variant={'ghost'}>
                            {department.TITLE}
                        </Button>
                    }
                </div>
            );
        });
    };



    return (
        <AdvancedSelect
            text={selectMenuValue?.TITLE}
            placeholder={props.placeholder ?? 'No placeholder defined.'}
            clearSelection={() => props.onChange(undefined)}
            filterText={filterText}
            setFilterText={setFilterText}
            isSingleSelect
            disabled={props.disabled}
        >
            {renderDepartments(departments?.departments ?? [])}
        </AdvancedSelect>
    );
}