import React, { useContext, useState } from 'react';

import { NavigationMenuContent, NavigationMenuItem, NavigationMenuTrigger } from '@/ui/ui/navigation-menu';
import { NavigationMenuContentDropdown, NavigationDropdownSectionEntry, NavigationDropdownSectionEntryLabel, NavigationDropdownTitle, NavigationMenuFooter } from '@/ui/ui/navigation-dropdown';
import { CreateCommitteeIcon } from '@/ui/icons/box/CreateCommittee';
import { MyMeetingsIcon } from '@/ui/icons/box/MyMeetings';
import { COMMITTEE, CommitteeStatuses } from '@/entities/org/COMMITTEE';
import { Input } from '@/ui/ui/input';
import useSWR from 'swr';
import { useOrgBaseURL } from '@/hooks/useOrgBaseURL';
import { hasRight } from '@/_shared/utils/hasRights';
import { RightsCodeKey } from '@/entities/master/PERSON';
import UserBundleContext from '@/context/UserBundleContext';


export const HeaderCommitteesDropdown: React.FC = () => {

    const [query, setQuery] = useState('');

    const orgBaseURL = useOrgBaseURL();
    const userBundle = useContext(UserBundleContext);

    const { data: committees, isLoading } = useSWR<{ committees: COMMITTEE[] }>('/api/committee');

    const displayedCommittees = committees?.committees?.sort(
            (a, b) =>
                a.NAME.localeCompare(b.NAME)
        )
        .filter(
            committee =>
                committee.NAME.toLowerCase().includes(query.toLowerCase()) &&
                committee.STATUS === CommitteeStatuses.ACTIVE
        ) || [];
    
    const hasDocumentAdminRights = hasRight(userBundle, RightsCodeKey.DocumentAdministrator);

    return (
        <NavigationMenuItem>
            <NavigationMenuTrigger>
                <p className='text-primary'>Committees</p>
            </NavigationMenuTrigger>
            <NavigationMenuContent>
                <NavigationMenuContentDropdown
                    footer={
                        <NavigationMenuFooter href={`${orgBaseURL}/committees?tab=myMeetings`} >
                        Go to committees...
                    </NavigationMenuFooter>
                    }
                >
                    <NavigationDropdownTitle>
                        Committees
                    </NavigationDropdownTitle>
                    <Input
                        type='search'
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        placeholder='Search committees...'
                        className='border-none pl-3.5'
                        autoFocus
                    />
                    {
                        !isLoading &&
                        displayedCommittees &&
                        displayedCommittees.length > 0 &&
                            <div
                                className='max-h-48 overflow-auto'
                            >
                                {
                                    displayedCommittees?.map (committee => 
                                        <NavigationDropdownSectionEntry
                                            icon={
                                                <svg width="6" height="20" viewBox="0 0 6 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect width="6" height="20" rx="1" fill="#DC9292"/>
                                                </svg>
                                            }
                                            href={`${orgBaseURL}/committees?committeeTab=committees&editingCommitteeId=${ committee.COMMITTEE_ID }`}
                                            title={committee.NAME}
                                            description={`Go to ${committee.NAME}...`}
                                            external={false}
                                        />
                                    )   
                                }
                            </div>
                    }      
                    {
                        isLoading ||
                        (displayedCommittees && displayedCommittees.length === 0) &&
                            <NavigationDropdownSectionEntry
                                title='No committees found'
                                description='Try different search criteria?'
                                external={false}
                            />
                    } 
                    <NavigationDropdownSectionEntryLabel>
                        committees
                    </NavigationDropdownSectionEntryLabel>
                    {
                        hasDocumentAdminRights &&
                            <NavigationDropdownSectionEntry
                                icon={<CreateCommitteeIcon size={28} />}
                                href={`${orgBaseURL}/committees?committeeTab=committees&createCommitteeDialogOpen=true`}
                                title='Create Committee'
                                description='Add a committee to your organization'
                                external={false}
                            />
                    }
                    <NavigationDropdownSectionEntry
                        icon={<MyMeetingsIcon size={28} />}
                        href={`${orgBaseURL}/committees?committeeTab=myMeetings`}
                        title='My Meetings'
                        description='View your meetings and their corresponding consent agendas'
                        external={false}
                    />
                </NavigationMenuContentDropdown>
            </NavigationMenuContent>
        </NavigationMenuItem>
    );
};
