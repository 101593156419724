import {
    Column,
    Entity,
    PrimaryColumn,
} from 'typeorm';
import { SchemaEntityManager } from '../SchemaEntityManager';

export enum AuditLogIdentifierNames {
    checklist_condition_id = 'checklist_condition_id',
    corrective_action_plan_id = 'corrective_action_plan_id',
    checklist_id = 'checklist_id',
    inspection_id = 'inspection_id',
    checklist_edition_id = 'checklist_edition_id',
    org_accreditation_id = 'org_accreditation_id',
    organizationid = 'organizationid',
    checklist_audit_id = 'checklist_audit_id',
}

enum AuditLogActions {
    added = 'added',
    updated = 'updated',
    removed = 'removed',
    discarded = 'discarded',
    undiscarded = 'undiscarded'
}

// Factory function for creating AUDIT_LOG entity with dynamic schema
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function createAccreditationManagerAuditLogEntity(schemaName: string, manager: SchemaEntityManager) {
    @Entity('AUDIT_LOG', { schema: 'ACCREDITATIONMANAGER' })
    class AUDIT_LOG {
        static SCHEMANAME = schemaName;

        @PrimaryColumn('varchar2', {
            nullable: false,
            length: 30,
            name: 'IDENTIFIER_NAME'
        })
        IDENTIFIER_NAME!: string;

        @PrimaryColumn('number', {
            nullable: false,
            name: 'IDENTIFIER'
        })
        IDENTIFIER!: number;

        @PrimaryColumn('timestamp', {
            nullable: false,
            default: () => 'SYSDATE',
            name: 'TIMESTAMP'
        })
        TIMESTAMP!: Date;

        @PrimaryColumn('number', {
            nullable: false,
            name: 'USERID'
        })
        USERID!: number;

        @PrimaryColumn('varchar2', {
            nullable: false,
            length: 195,
            name: 'USER_NAME_AND_TITLE'
        })
        USER_NAME_AND_TITLE!: string;

        @PrimaryColumn('number', {
            nullable: false,
            name: 'ORGANIZATIONID'
        })
        ORGANIZATIONID!: number;

        @PrimaryColumn('varchar2', {
            nullable: false,
            length: 32,
            name: 'ACTION'
        })
        ACTION!: string;

        @Column('varchar2', {
            nullable: true,
            length: 30,
            name: 'ATTRIBUTE_NAME'
        })
        ATTRIBUTE_NAME!: string | null;

        @Column('clob', {
            nullable: true,
            name: 'ATTRIBUTE'
        })
        ATTRIBUTE!: string | null;

        @Column('varchar2', {
            nullable: true,
            length: 400,
            name: 'JUSTIFICATION'
        })
        JUSTIFICATION!: string | null;
    }

    return AUDIT_LOG;
}

// Type definition for the return type of the factory function
type ACCREDITATIONMANAGER_AUDIT_LOG = InstanceType<ReturnType<typeof createAccreditationManagerAuditLogEntity>>;

export { createAccreditationManagerAuditLogEntity, AuditLogActions };
export type { ACCREDITATIONMANAGER_AUDIT_LOG as AUDIT_LOG };
