import {
    Column,
    Entity,
    JoinColumn,
    ManyToOne,
    PrimaryColumn,
} from 'typeorm';
import { FIELD } from './FIELD';
import { SchemaEntityManager } from '../SchemaEntityManager';

function createThesaurusEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('THESAURUS', { schema: schema })
    class THESAURUS {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'THESAURUSID'
        })
        THESAURUSID!: number;

        @Column('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'FIELDID'
        })
        FIELDID!: number;

        @ManyToOne(
            () => manager.getFieldEntity(schema), 
            (field) => field.THESAURUS_VALUES
        )
        @JoinColumn({
            name: 'FIELDID'
        })
        FIELD!: FIELD | null;

        @Column('varchar2', {
            nullable: true,
            length: 256,
            name: 'VALUE'
        })
        VALUE!: string | null;
    }
    return THESAURUS;
}

export { createThesaurusEntity };
export type THESAURUS = InstanceType<ReturnType<typeof createThesaurusEntity>>;