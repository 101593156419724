import { cn } from '@/lib/utils';
import React from 'react';

interface SkeletonProps {
    className?: string;
}


export const Skeleton: React.FC<SkeletonProps> = ({ className }) => {
    return (
        <div className={cn('animate-pulse h-4 bg-accent rounded-sm col-span-1', className)}></div>
    );
};