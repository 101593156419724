import React from 'react';

interface ClipboardOutlineIconProps {
    size: number
}

export const ClipboardOutlineIcon: React.FC<ClipboardOutlineIconProps> = ({ size }) => {
    return (
        <svg width={size.toString()} height={(size * (22 / 18)).toString()} viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 6C1 4.34315 2.34315 3 4 3H14H14.3743C15.8244 3 17 4.17558 17 5.62573V18C17 19.6569 15.6569 21 14 21H4C2.34315 21 1 19.6569 1 18V6Z" stroke="hsl(var(--foreground))" strokeWidth="2" />
            <line x1="5" y1="10.5" x2="13" y2="10.5" stroke="hsl(var(--foreground))" />
            <line x1="5" y1="8.5" x2="7" y2="8.5" stroke="hsl(var(--foreground))" />
            <line x1="10" y1="8.5" x2="13" y2="8.5" stroke="hsl(var(--foreground))" />
            <line x1="5" y1="12.5" x2="13" y2="12.5" stroke="hsl(var(--foreground))" />
            <line x1="5" y1="14.5" x2="13" y2="14.5" stroke="hsl(var(--foreground))" />
            <line x1="5" y1="16.5" x2="13" y2="16.5" stroke="hsl(var(--foreground))" />
            <path d="M5.83333 1C5.93287 0.701405 6.2123 0.5 6.52705 0.5H11.473C11.7877 0.5 12.0671 0.701405 12.1667 1C12.3657 1.59719 12.9246 2 13.5541 2H14L14.0002 4H4.00019L4 2H4.44591C5.0754 2 5.63427 1.59719 5.83333 1Z" fill="hsl(var(--foreground))" />
        </svg>

    );
};