import React, { useState, useEffect } from 'react';

import ReactSelect from 'react-select';

import { rightsCodes, PersonStatuses as PersonStatuses } from '../../../../entities/master/PERSON';
import useUserSearch from '../../../../hooks/useUserSearch';
import { constructFullName } from '@/components/UserPicker/utils/constructFullName';

interface ISelectUserProps {
    userId?: number;
    onChange: (userId?: number) => void;
    placeholder?: string;
    rights?: (keyof typeof rightsCodes)[];
    statuses?: PersonStatuses[],
    exemptUserIds?: number[]; // user ids you do not want to display as options
    disabled?: boolean;
    getMappedUsers?: boolean;
    isClearable?: boolean;
}

const SelectUser: React.FC<ISelectUserProps> = (props: ISelectUserProps) => {

    const [input, setInput] = useState('');
    const [users] = useUserSearch(
        input,
        props.userId ? [props.userId] : [],
        props.statuses,
        props.rights,
        undefined,
        props.exemptUserIds,
        props.getMappedUsers
    );

    useEffect(() => {
        if (
            props.exemptUserIds &&
            props.userId &&
            props.exemptUserIds.includes(props.userId)
        ) {
            props.onChange(undefined);
        }
    }, [props.exemptUserIds]);

    const options = (users || [])
        .map(user => ({
            label: constructFullName(user, 'firstMiddleLast'),
            value: user.USERID
        }))
        .filter(user => !(props.exemptUserIds || []).includes(user.value));

    return <ReactSelect
        onInputChange={ value => setInput(value) }
        isSearchable
        isDisabled={ props.disabled }
        onChange={
            (selected) => {
                props.onChange(!selected ? undefined : selected.value as (number | undefined));
            }
        }
        value={ options.find(o => o.value === props.userId) }
        options={ options }
        placeholder={ props.placeholder }
        isClearable={props.isClearable}
    />;

};

export default SelectUser;
