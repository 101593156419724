const Regexes = {
    URLs: {
        // lucidocDomain is extremely similar, but NOT identical, to the value in constants.js;
        // it's different because it can be used to scrub Lucidoc URLs are replace them with AD
        // URLs, meaning it needs no ^ at the beginning, and the /g at the end, for example.
        lucidocDomain: /https:\/\/([a-zA-Z1-9-]+\.)?lucidoc\.com(\/)?/g,

        // this one matches the full URL (including everything after the .com) up until a
        // ' or " or ` or < or whitespace, intended to find Lucidoc URLs within HTML,
        // likely to be replaced with a cleaned-up AD Bridge URL:
        //
        //     Capture Group 1: Lucidoc Domain
        //               |
        //               |    Capture Group 2: Subdomain (only useful to make it ? questionable so it could be lucidoc.com without the www for example)
        //               |          |
        //               |          |        Capture Group 3: Everything after the .com (including the first / slash)
        //               |          |                               |
        lucidocFullURL: /(https:\/\/([a-zA-Z1-9-]+\.)?lucidoc\.com)(.*?)(?='|"|`|<|\s)/g,
    }
};

export default Regexes;
