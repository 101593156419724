import { PERSON, isPerson } from '../../../entities/master/PERSON';
import { USERGROUP, isUserGroup } from '../../../entities/org/USERGROUP';


function hasIntersection(a: PERSON | USERGROUP, b: PERSON | USERGROUP): boolean {
    if (isPerson(a) && isPerson(b)) {
        return a.USERID === b.USERID;
    }

    if (isPerson(a) && isUserGroup(b)) {
        const groupMembers = b.PEOPLE || [];
        return !!groupMembers.find(person => person.USERID === a.USERID);
    }

    if (isUserGroup(a) && isPerson(b)) {
        const groupMembers = a.PEOPLE || [];
        return !!groupMembers.find(person => person.USERID === b.USERID);
    }

    if (isUserGroup(a) && isUserGroup(b)) {
        if (a.USERGROUPID === b.USERGROUPID) {
            return true;
        }

        const aMembers = new Set(a.PEOPLE || []);
        const bMembers = new Set(b.PEOPLE || []);

        // set intersection is not fully browser compatible, hence the manual check
        let groupsHaveIntersection = false;
        aMembers.forEach(member => {
            if (bMembers.has(member)) {
                groupsHaveIntersection = true;
            }
        });

        !groupsHaveIntersection && bMembers.forEach(member => {
            if (aMembers.has(member)) {
                groupsHaveIntersection = true;
            }
        });

        return groupsHaveIntersection;
    }

    return false;
}

export { hasIntersection };