export const TrainingVideosByDocID: {[key: string]: number[]} = {
    // Accreditation Manager:
    36605: [],

    // Acknowledgements:
    36606: [
        10002, // How Do I...Acknowledge a Document from an Email Notification?
        10004, // Managing Acknowledgements Using Your My Acknowledgements Page
        13896, // How Do I...Acknowledge a Manual?
    ],

    // Administration:
    36438: [],

    // Committee & Signer Approvals:
    36607: [
        10012, // How Do I...Use Consent Agenda's Split Panel?
        13779, // How Do I...Request an additional approval on an Official Document?
        13851, // How Do I...Edit and Sign a Document?
        13850, // How Do I...Request a Document's Owner to Make Edits Before I Sign the Document?
        13852, // How Do I...Fulfill an Edit Request?
        10005, // How Do I...Add a Signature Step to a Document's Approval Route?
        10006, // How Do I...Modify an Active Approval Route?
        13916, // How Do I...Sign One or More Documents Using My To SIgn Folder?
        13828, // How Do I...Add a Committee Approval Step to a Document's Approval Route?
    ],

    // Continuing Education:
    36608: [],

    // Contracts Management:
    36609: [],

    // Document Authoring & Management:
    36437: [
        13915, // How Do I...Create a New Document?
        13662, // How Do I...Submit Feedback to a Document's Owner?
        13745, // How Do I...Respond to a Draft Feedback Request?
        13913, // How Do I...Mark a Document as Reviewed?
        13682, // How Do I...Archive a Document?
        14406, // How Do I...Import and Link a New Document?
        13895, // How Do I...Remove Feedback from My Unread Feedback Folder?
        14351, // How Do I...Add a Link to an Existing Lucidoc Document?
        14403, // How Do I...Add a Link to a Web Address (URL)?
    ],

    // Home Page:
    36436: [
        13661, // How Do I...Search for a Document?
    ],

    // Multi-Org:
    36610: [
        14764, // How do I...Use Multi-Org Search
    ],
};
