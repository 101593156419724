import { Button } from '@/ui/ui/button';
import { Dialog, DialogContent, DialogTitle, DialogTrigger } from '@/ui/ui/dialog';
import React, { useEffect } from 'react';

interface EditInPlaceInstallDialogProps {

}

export const EditInPlaceInstallDialog: React.FC<EditInPlaceInstallDialogProps> = () => {


    enum EIP2Status {
        LOADING = '0',
        FOUND = '1',
        NOT_FOUND = '2',
    }

    const [status, setStatus] = React.useState<EIP2Status>(window.localStorage.getItem('EditInPlace_enabled') as EIP2Status);


    const renderStatus = () => {
        switch (status) {
            case EIP2Status.LOADING:
                return (
                    <div>
                        <p className='text-xl text-bold'>Checking Install Status...</p>
                    </div>
                );
            case EIP2Status.FOUND:
                return (
                    <div>
                        <p className='text-xl text-bold'>Edit in Place 2 is installed.</p>
                    </div>
                );
            case EIP2Status.NOT_FOUND:
                return (
                    <div>
                        <p className='text-xl text-bold'>Edit in Place 2 was not found.</p>
                    </div>
                );
        }

    };


    const refresh = () => {
        setStatus(EIP2Status.LOADING);
        // disable cors
        fetch('http://localhost:25565/isAlive', {
            method: 'GET',
            mode: 'no-cors',
        }).then(() => {
            console.log('Edit in Place API is available.');
            window.localStorage.setItem('EditInPlace_enabled', '1');
            setStatus(EIP2Status.FOUND);
        }).catch(() => {
            console.log('Edit in Place API is not available.');
            window.localStorage.removeItem('EditInPlace_enabled');
            setStatus(EIP2Status.NOT_FOUND);
        });
    };

    useEffect(() => {
        refresh();
    }, []);

    const [open, setOpen] = React.useState(false);

    return (
        <Dialog open={open} onOpenChange={(o) => setOpen(o)}>
            <DialogTrigger>
                <Button size={'md'}>Check Install Status</Button>
            </DialogTrigger>
            <DialogContent>
                <DialogTitle>EIP2 Install checker</DialogTitle>
                {renderStatus()}
                <div className='w-full flex justify-end space-x-2'>
                    <Button size={'md'} onClick={refresh}>Refresh</Button>
                    <Button size={'md'} variant={'destructive'} onClick={() => setOpen(false)}>Close</Button>
                </div>
            </DialogContent>
        </Dialog>
    );
};