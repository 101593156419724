import React from 'react';

interface GroupProps {
    size: number
}

export const GroupIcon: React.FC<GroupProps> = ({ size }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 4H20V20H4V4Z" fill="hsl(var(--white))" />
            <path d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z" fill="hsl(var(--primary))" />
            <path d="M12.5 13C12.5 11.8954 13.3954 11 14.5 11L17.5 11C18.6046 11.0001 19.5 11.8955 19.5 13V17.2457C19.5 17.6922 19.1983 18.0862 18.769 18.2089C16.9734 18.7219 15.0266 18.7219 13.231 18.2089C12.8017 18.0862 12.5 17.6922 12.5 17.2457V13Z" fill="hsl(var(--white))" />
            <path d="M4.5 15C4.5 13.8954 5.39543 13 6.5 13H9.5C10.6046 13 11.5 13.8954 11.5 15L11.5 17.2457C11.5 17.6922 11.1983 18.0862 10.769 18.2089C8.97343 18.7219 7.02657 18.7219 5.23102 18.2089C4.80172 18.0862 4.5 17.6922 4.5 17.2457V15Z" fill="hsl(var(--white))" />
            <path d="M5.40918 9.5C5.40918 8.11929 6.52847 7 7.90918 7H8.00009C9.3808 7 10.5001 8.11929 10.5001 9.5V9.59091C10.5001 10.9716 9.3808 12.0909 8.00009 12.0909H7.90918C6.52847 12.0909 5.40918 10.9716 5.40918 9.59091V9.5Z" fill="hsl(var(--white))" />
            <path d="M13.5 7.5C13.5 6.11929 14.6193 5 16 5H16.0909C17.4716 5 18.5909 6.11929 18.5909 7.5V7.59091C18.5909 8.97162 17.4716 10.0909 16.0909 10.0909H16C14.6193 10.0909 13.5 8.97162 13.5 7.59091V7.5Z" fill="hsl(var(--white))" />
            <path d="M8 18C8 15.7909 9.79086 14 12 14C14.2091 14 16 15.7909 16 18C16 20.2091 14.2091 22 12 22C9.79086 22 8 20.2091 8 18Z" fill="hsl(var(--primary))" />
            <path d="M9.46344 16.1368C9.44273 16.098 9.44984 16.0502 9.48096 16.019L10.019 15.481C10.0502 15.4498 10.098 15.4427 10.1368 15.4634L11.0228 15.9359C11.0874 15.9704 11.1676 15.9422 11.1965 15.8749L11.5455 15.0606C11.5612 15.0238 11.5974 15 11.6374 15H12.3626C12.4026 15 12.4388 15.0238 12.4545 15.0606L12.8035 15.8749C12.8324 15.9422 12.9126 15.9704 12.9772 15.9359L13.8632 15.4634C13.902 15.4427 13.9498 15.4498 13.981 15.481L14.519 16.019C14.5502 16.0502 14.5573 16.098 14.5366 16.1368L14.0641 17.0228C14.0296 17.0874 14.0578 17.1676 14.1251 17.1965L14.9394 17.5455C14.9762 17.5612 15 17.5974 15 17.6374V18.3626C15 18.4026 14.9762 18.4388 14.9394 18.4545L14.1251 18.8035C14.0578 18.8324 14.0296 18.9126 14.0641 18.9772L14.5366 19.8632C14.5573 19.902 14.5502 19.9498 14.519 19.981L13.981 20.519C13.9498 20.5502 13.902 20.5573 13.8632 20.5366L12.9772 20.0641C12.9126 20.0296 12.8324 20.0578 12.8035 20.1251L12.4545 20.9394C12.4388 20.9762 12.4026 21 12.3626 21H11.6374C11.5974 21 11.5612 20.9762 11.5455 20.9394L11.1965 20.1251C11.1676 20.0578 11.0874 20.0296 11.0228 20.0641L10.1368 20.5366C10.098 20.5573 10.0502 20.5502 10.019 20.519L9.48096 19.981C9.44984 19.9498 9.44273 19.902 9.46344 19.8632L9.93595 18.9772C9.97041 18.9126 9.9422 18.8324 9.87489 18.8035L9.06061 18.4545C9.02384 18.4388 9 18.4026 9 18.3626V17.6374C9 17.5974 9.02384 17.5612 9.06061 17.5455L9.87489 17.1965C9.9422 17.1676 9.97041 17.0874 9.93595 17.0228L9.46344 16.1368Z" fill="hsl(var(--white))" />
            <path d="M10.5 18C10.5 17.1716 11.1716 16.5 12 16.5C12.8284 16.5 13.5 17.1716 13.5 18C13.5 18.8284 12.8284 19.5 12 19.5C11.1716 19.5 10.5 18.8284 10.5 18Z" fill="hsl(var(--primary))" />
        </svg>

    );
};