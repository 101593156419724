import {
    Column,
    Entity,
    JoinColumn,
    ManyToOne,
    OneToMany,
    PrimaryColumn,
} from 'typeorm';
import { DOCUMENT_TYPE_PL,  } from './DOCUMENT_TYPE_PL';
import { FIELDTEMPLATE,  } from './FIELDTEMPLATE';
import { MIME_TYPE,  } from '../master/MIME_TYPE';
import { DOCUMENTREVISION,  } from './DOCUMENTREVISION';
import { SchemaEntityManager } from '../SchemaEntityManager';

export enum TemplateStatuses {
    active = 'active',
    archived = 'archived',
    disabled = 'disabled'
}

function createTemplateEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('TEMPLATE', { schema: schema })
    class TEMPLATE {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'TEMPLATEID'
        })
        TEMPLATEID!: number;

        @Column('number', {
            nullable: false,
            precision: 10,
            scale: 0,
            name: 'DOCTYPE_ID'
        })
        DOCTYPE_ID!: number;

        @ManyToOne(
            () => manager.getDocumentTypePLEntity(schema), 
            (documentTypePL) => documentTypePL.TEMPLATES
        )
        @JoinColumn({
            name: 'DOCTYPE_ID'
        })
        DOCTYPE!: DOCUMENT_TYPE_PL | null;

        @Column('varchar2', {
            nullable: false,
            length: 50,
            name: 'NAME'
        })
        NAME!: string;

        @Column('varchar2', {
            nullable: true,
            length: 100,
            name: 'DESCRIPTION'
        })
        DESCRIPTION!: string | null;

        @Column('clob', {
            nullable: true,
            name: 'CSS'
        })
        CSS!: string | null;

        @Column('clob', {
            nullable: true,
            name: 'TEMPLATE'
        })
        TEMPLATE!: string | null;

        @Column('clob', {
            nullable: true,
            name: 'XMLTEMPLATE'
        })
        XMLTEMPLATE!: string | null;

        @Column('char', {
            nullable: true,
            name: 'CASCADE'
        })
        CASCADE!: string | null;

        @Column('char', {
            nullable: true,
            name: 'BINARY'
        })
        BINARY!: string | null;

        @Column('char', {
            nullable: true,
            name: 'ACTIVE'
        })
        ACTIVE!: string | null;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'MIMETYPE_ID'
        })
        MIMETYPE_ID!: number | null;

        @ManyToOne(
            () => manager.getMimeTypeEntity(schema), 
            (mimeType) => mimeType.TEMPLATES
        )
        @JoinColumn({
            name: 'MIMETYPE_ID'
        })
        MIME_TYPE!: MIME_TYPE | null;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'DEFAULT_DOCID'
        })
        DEFAULT_DOCID!: number | null;

        @Column('number', {
            nullable: true,
            precision: 10,
            scale: 0,
            name: 'LOCATIONID'
        })
        LOCATIONID!: number | null;

        @Column('varchar2', {
            nullable: true,
            length: 20,
            default: () => '\'active\'',
            name: 'STATUS'
        })
        STATUS!: string | null;

        @Column('char', {
            nullable: true,
            default: () => '0',
            name: 'IS_USER_RESTRICTED'
        })
        IS_USER_RESTRICTED!: string | null;

        // @Column('number', {
        //     nullable: true,
        //     name: 'TEMPLATE_IMAGE_ID'
        // })
        // TEMPLATE_IMAGE_ID!: number | null;

        @Column('number', {
            nullable: true,
            name: 'IMAGE_BLOBID'
        })
        IMAGE_BLOBID!: number | null;

        @OneToMany(
            () => manager.getFieldTemplateEntity(schema), 
            (fieldTemplate) => fieldTemplate.TEMPLATE
        )
        FIELDTEMPLATES!: FIELDTEMPLATE[] | null;

        @OneToMany(
            () => manager.getDocumentRevisionEntity(schema), 
            (documentRevision) => documentRevision.TEMPLATE
        )
        DOCUMENTREVISIONS!: DOCUMENTREVISION[] | null;

        static Statuses = TemplateStatuses;
    }
    return TEMPLATE;
}

export { createTemplateEntity };
export type TEMPLATE = InstanceType<ReturnType<typeof createTemplateEntity>>;