import {
    Entity,
    JoinColumn,
    ManyToOne,
    OneToOne,
    PrimaryColumn,
} from 'typeorm';
import { PERSON,  } from '../master/PERSON';
import { DOCUMENT,  } from './DOCUMENT';
import { SchemaEntityManager } from '../SchemaEntityManager';

// Factory function to create DOC_REQUEST entity with dynamic schema
function createDocRequestEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('DOC_REQUEST', { schema })
    class DOC_REQUEST {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            scale: 0,
            name: 'USERID'
        })
        USERID!: number;

        @ManyToOne(
            () => manager.getPersonEntity(schema),
            (PERSON) => PERSON.DOC_REQUESTS
        )
        @JoinColumn({
            name: 'USERID'
        })
        PERSON!: PERSON | null;

        @PrimaryColumn('number', {
            nullable: false,
            scale: 0,
            name: 'DOCID'
        })
        DOCID!: number;

        @OneToOne(
            () => manager.getDocumentEntity(schema),
            (DOCUMENT) => DOCUMENT.DOC_REQUEST
        )
        @JoinColumn({
            name: 'DOCID'
        })
        DOCUMENT!: DOCUMENT | null;
    }

    return DOC_REQUEST;
}

export { createDocRequestEntity };
export type DOC_REQUEST = InstanceType<ReturnType<typeof createDocRequestEntity>>;
