import React from 'react';

interface LucidocLogoProps {
    size: number
}

export const LucidocLogoIcon: React.FC<LucidocLogoProps> = ({ size }) => {

    return (
            <svg 
                width={size.toString()}
                height={size.toString()} 
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="shadow-lg"
            >
                <path d="M0 20.25C0 21.2165 0.783502 22 1.75 22H20.25C21.2165 22 22 21.2165 22 20.25V6.91237C22 6.44825 21.8156 6.00313 21.4874 5.67494L16.3251 0.512562C15.9969 0.184374 15.5518 0 15.0876 0H1.75C0.783502 0 0 0.783503 0 1.75V20.25Z" fill="white" fillOpacity="0.5" />
                <path d="M0 20.25C0 21.2165 0.783502 22 1.75 22H20.25C21.2165 22 22 21.2165 22 20.25V6.91237C22 6.44825 21.8156 6.00313 21.4874 5.67494L16.3251 0.512564C15.9969 0.184374 15.5518 0 15.0876 0H1.75C0.783502 0 0 0.783503 0 1.75V20.25Z" fill="#A58BE4" fillOpacity="0.5" />
                <rect width="9" height="14" rx="2" transform="matrix(1 0 0 -1 0 18)" fill="#A58BE4" fillOpacity="0.5" />
                <rect y="12" width="9" height="10" rx="2" fill="#634B9C" fillOpacity="0.5" />
                <rect width="11" height="16" rx="2" transform="matrix(1 0 0 -1 5 22)" fill="#A58BE4" fillOpacity="0.5" />
                <rect width="6" height="16" rx="2" transform="matrix(1 0 0 -1 16 22)" fill="#A58BE4" fillOpacity="0.5" />
                <rect width="12" height="16" rx="2" transform="matrix(1 0 0 -1 5 22)" fill="#534C83" fillOpacity="0.5" />
                <rect width="16" height="12" rx="2" transform="matrix(1 0 0 -1 0 12)" fill="#A58BE4" fillOpacity="0.5" />
                <path d="M5 4.25C5 5.2165 5.7835 6 6.75 6H17.6754C19.2483 6 20.0227 4.08637 18.8924 2.99249L16.3093 0.492486C15.9829 0.176603 15.5465 -9.53674e-07 15.0922 -9.53674e-07H6.75C5.7835 -9.53674e-07 5 0.783501 5 1.75V4.25Z" fill="#DFDFDF" fillOpacity="0.5" />
                <path d="M16 14.25C16 15.2165 16.7835 16 17.75 16H20.25C21.2165 16 22 15.2165 22 14.25V6.98988C22 6.53999 21.8267 6.10738 21.5162 5.78188L19.0162 3.16146C17.9262 2.019 16 2.79046 16 4.36946V14.25Z" fill="#A58BE4" fillOpacity="0.5" />
                <rect width="6" height="6" rx="2" transform="matrix(1 0 0 -1 16 22)" fill="#6C51AD" fillOpacity="0.5" />
                <rect width="5" height="5" rx="2" transform="matrix(1 0 0 -1 0 5)" fill="#534C83" fillOpacity="0.5" />
            </svg>
    );
};