import { Dialog, DialogTitle, IconButton, makeStyles } from '@material-ui/core';
import { HighlightOff, Star, StarBorder } from '@material-ui/icons';
import SaveIndicator from '../SaveIndicator';
import SaveBar from '../SaveBar/SaveBar';
import { headerSharedStyles } from '../../Header/HeaderSharedStyles';
import { useFavorites } from '../../../hooks/useFavorites';
import { useEffect } from 'react';

const useStyles = makeStyles({
    dialogPaper: {
        maxWidth: '100%',
        // min so an expandable textarea works easily
        minWidth: '600px',
    },
    titleSpan: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#22313f',
        color: 'white'
    },
    titleButtons: {
        display: 'flex',
        alignItems: 'center',
    },
    dialogMainDiv: {
        position: 'relative',
        padding: '1rem',
        boxSizing: 'border-box',
    },
    lowerDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '0 1rem 1rem .5rem'
    },
    ...headerSharedStyles
});

type LucidocModalProps = {

    // REQUIRED

    // VISIBILITY
    open: boolean
    onOpen?: () => void
    onClose: () => void

    // CONTENT
    title: string
    children: JSX.Element | JSX.Element[]

    // OPTIONAL

    // (notice the entire SAVE/CANCEL is optional, in case you're loading a modal
    // of another component that already has its own save & cancel

    // ACTIONS: SAVE
    onSave?: () => void
    isSaveDisabled?: boolean
    whyIsSaveDisabled?: string

    // ACTIONS: CANCEL
    onCancel?: () => void
    isCancelDisabled?: boolean
    whyIsCancelDisabled?: string

    // SAVE INDICATOR:
    isSaveIndicatorVisible?: boolean
    setIsSaveIndicatorVisible?: (isSaveIndicatorVisible: boolean) => void
    saveIndicatorMessage?: string

    // HELP TOPIC LINK
    helpTopicID?: number

    // CSS
    removePadding?: boolean // useful if the modal has a Subheader.tsx at the top

    hideFavoriteStar?: boolean
    titleForFavoriteStarLink?: string
    zIndex?: number
}

export function LucidocModal(props: LucidocModalProps) {
    const classes = useStyles();

    const { pageAlreadyFavorited, addFavorite, toggleFavoriteToBeDeleted, isCurrentPageInTheDeleteList, currentURL, deleteMarkedFavorites } = useFavorites();

    useEffect(() => {
        if (props.onOpen) {
            props.onOpen?.();
        }
    }, []);

    const hideFavoriteStar = props.hideFavoriteStar ?? false;
    const styles = {};
    if (props.zIndex !== undefined) {
        styles['zIndex'] = props.zIndex;
    }

    return (
        <>
            <Dialog
                open={props.open}
                onClose={props.onClose}
                classes={{
                    paper: classes.dialogPaper,
                }}
                style={styles}
            >
                <span className={classes.titleSpan}>
                    <DialogTitle>{props.title}</DialogTitle>
                    <div className={classes.titleButtons}>
                        {!hideFavoriteStar && 
                        <IconButton
                            className={`${classes.lightColor} ${classes.lightColorHover}`}
                            onClick={() => {
                                pageAlreadyFavorited
                                    ? toggleFavoriteToBeDeleted(pageAlreadyFavorited, true)
                                    : addFavorite(currentURL, props.titleForFavoriteStarLink ?? window.document.title);
                                deleteMarkedFavorites();
                            }
                            }
                        >
                            {pageAlreadyFavorited && !isCurrentPageInTheDeleteList
                                ? <Star />
                                : <StarBorder />
                            }
                        </IconButton>
                        }
                        <IconButton style={{ color: 'white' }} onClick={() => props.onClose()}>
                            <HighlightOff fontSize={'large'} />
                        </IconButton>
                    </div>
                </span>

                <div
                    className={classes.dialogMainDiv}
                    style={props.removePadding ? { padding: 0 } : {}}
                >
                    {props.children}

                    {/* conditional since props.children might be a component with its own save/cancel */}
                    {(props.onSave || props.onCancel) &&
                        <SaveBar
                            onSave={() => props.onSave?.()}
                            isSaveDisabled={props.isSaveDisabled}
                            whyIsSaveDisabled={props.whyIsSaveDisabled}

                            // notice the odd difference; onClose works fine here:
                            onClose={() => props.onClose()}
                            isCloseDisabled={props.isCancelDisabled}
                            whyIsCloseDisabled={props.whyIsCancelDisabled}

                            helpTopicID={props.helpTopicID}
                        />
                    }
                </div>
            </Dialog>

            {props.isSaveIndicatorVisible && props.setIsSaveIndicatorVisible && props.saveIndicatorMessage &&
                <SaveIndicator
                    open={props.isSaveIndicatorVisible}
                    onClose={() => props.setIsSaveIndicatorVisible?.(false)}
                    message={props.saveIndicatorMessage}
                />
            }
        </>
    );
}
