import {
    Column,
    Entity,
    PrimaryGeneratedColumn,
    JoinColumn,
    OneToMany
} from 'typeorm';
import { SchemaEntityManager } from '../SchemaEntityManager';
import { LINK } from '../master/LINK';


function createReferenceTypePlEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('REFERENCE_TYPE_PL', { schema })
    class REFERENCE_TYPE_PL {
        static SCHEMANAME = schema;
        @PrimaryGeneratedColumn({
            name: 'REFERENCE_TYPE_ID'
        })
        REFERENCE_TYPE_ID!: number;

        @Column('number', {
            nullable: true,
            name: 'PARENT_ID'
        })
        PARENT_ID!: number | null;

        @Column('varchar2', {
            nullable: false,
            length: 30,
            name: 'NAME'
        })
        NAME!: string;

        @Column('varchar2', {
            nullable: true,
            length: 40,
            name: 'DESCRIPTION'
        })
        DESCRIPTION!: string | null;

        
        @OneToMany(
            () => manager.getLinkEntity(schema),
            (link) => link.REFERENCETYPEPL
        )
        @JoinColumn({
            name: 'REFERENCE_TYPE_ID'
        })
        LINKS!: LINK[];
    }



    return REFERENCE_TYPE_PL;
}

export type REFERENCE_TYPE_PL = InstanceType<ReturnType<typeof createReferenceTypePlEntity>>;
export { createReferenceTypePlEntity };