/**
 * Function that can get called when a user downloads a CSV from a DataTable component.
 * This function will prompt the user with a warning message about multi-line data possibly being truncated.
 * 
 */
export function onDownloadForTablesWithMultilineData(buildHead, buildBody, columns, data) {
    const downloadFile = window.confirm('Cell data that spans multiple lines will appear truncated unless the user expands the row sizes. Proceed to download file?');

    if (!downloadFile) {
        return false;
    }

    return buildHead(columns) + buildBody(data);
}