import React, { useContext } from 'react';
import { DashboardCard, DashboardCardContent, DashboardCardTitle, DashboardCardToolbar, DashboardCardToolbarItem } from '../../../../components/Homepage/components/DashboardCard';
import useSWR from 'swr';
import { ORGANIZATION } from '@/entities/master/ORGANIZATION';
import { HomepageContactsGroup } from '@/entities/org/CONTACTS';

import { EditContactsDialog } from './contactscard2/EditContactsDialog';
import UserBundleContext from '@/context/UserBundleContext';
import hasRights from '@/_shared/utils/hasRights';
import { RightsCodeKey } from '@/entities/master/PERSON';
import { constructFullName } from '@/components/UserPicker/utils/constructFullName';


interface ContactsCard2Props {

}


export const ContactsCard2: React.FC<ContactsCard2Props> = () => {

    const userBundle = useContext(UserBundleContext);


    interface HomepageContacts {
        organization: ORGANIZATION;
        homepageContacts: HomepageContactsGroup[]
    }

    const { data: homepageContactsData, mutate } = useSWR<HomepageContacts>('/api/homepage/card/homepage-contacts');


    const isOrgAdmin = hasRights(userBundle, RightsCodeKey.OrganizationAdministrator);

    return (
        <DashboardCard disableMinWidth>
            <DashboardCardTitle>
                Contacts
            </DashboardCardTitle>
            {isOrgAdmin && <DashboardCardToolbar>
                <DashboardCardToolbarItem tooltip='Edit contacts'>
                    <EditContactsDialog refetch={() => mutate()}/>
                </DashboardCardToolbarItem>
            </DashboardCardToolbar>}

            <DashboardCardContent>
                <div className='flex flex-col'>
                    {homepageContactsData && homepageContactsData.homepageContacts.map((group, idx) => {
                        return <div key={idx} className='mb-1'>
                            <p className='text-secondary font-semibold text-xs'>{group.subtype}</p>
                            {group.contacts.map((contact, personIdx) => {
                                return <div key={personIdx} className='mt-1'>
                                    <p className='text-primary text-sm font-semibold'>{contact.USER ? constructFullName(contact.USER, 'firstMiddleLast') : ''}</p>
                                    <a href={`mailto:${contact.USER?.LOCATION}`} className='text-secondary text-xs leading-tight hover:underline'>{contact.USER?.LOCATION}</a>
                                </div>;
                            })}
                        </div>;
                    })}
                </div>
            </DashboardCardContent>
        </DashboardCard>
    );
};