import React from 'react';

interface CreateDocumentIcon {
    size: number
}

export const CreateDocumentIcon: React.FC<CreateDocumentIcon> = ({ size }) => {
    return (
        <svg width={size.toString()} height={size.toString()} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" rx="4" fill="hsl(var(--primary))"/>
            <path d="M6 6C6 5.44772 6.44772 5 7 5H13.5858C13.851 5 14.1054 5.10536 14.2929 5.29289L17.7071 8.70711C17.8946 8.89464 18 9.149 18 9.41421V18C18 18.5523 17.5523 19 17 19H7C6.44772 19 6 18.5523 6 18V6Z" fill="white"/>
            <path d="M11 9.125C11 9.05596 11.056 9 11.125 9H12.875C12.944 9 13 9.05596 13 9.125V14.875C13 14.944 12.944 15 12.875 15H11.125C11.056 15 11 14.944 11 14.875V9.125Z" fill="#A291CE"/>
            <path d="M9 11.125C9 11.056 9.05596 11 9.125 11H14.875C14.944 11 15 11.056 15 11.125V12.875C15 12.944 14.944 13 14.875 13H9.125C9.05596 13 9 12.944 9 12.875V11.125Z" fill="#A291CE"/>
        </svg>
    );
};