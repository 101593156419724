import React from 'react';

interface PencilOutlineProps {
    size: number
}

export const PencilOutlineIcon: React.FC<PencilOutlineProps> = ({ size }) => {
    return (
        <svg width={size.toString()} height={size.toString()} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.6464 5.89645L10.1036 3.35355C9.90829 3.15829 9.59171 3.15829 9.39645 3.35355L1.18126 11.5687C1.11718 11.6328 1.07172 11.7131 1.04974 11.801L0.202113 15.1915C0.110565 15.5577 0.442261 15.8894 0.808451 15.7979L4.19898 14.9503C4.28689 14.9283 4.36718 14.8828 4.43126 14.8187L12.6464 6.60355C12.8417 6.40829 12.8417 6.09171 12.6464 5.89645Z" fill="#6B7280" />
            <path d="M15.6464 2.64645L13.3536 0.353553C13.1583 0.158291 12.8417 0.158291 12.6464 0.353553L11.3536 1.64645C11.1583 1.84171 11.1583 2.15829 11.3536 2.35355L13.6464 4.64645C13.8417 4.84171 14.1583 4.84171 14.3536 4.64645L15.6464 3.35355C15.8417 3.15829 15.8417 2.84171 15.6464 2.64645Z" fill="#6B7280" />
        </svg>

    );
};