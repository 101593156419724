import { helpTopicDocIDs } from '@/_shared/constants/HelpSystemIDs';
import { buildHelpDocURL } from '@/_shared/utils/docManagerFunctions';
import { useFavorites } from '@/hooks/useFavorites';
import { Button } from '@/ui/ui/button';
import { HybridLink } from '@/ui/ui/hybridlink';
import { Cross2Icon, QuestionMarkCircledIcon } from '@radix-ui/react-icons';
import React from 'react';

interface HeaderUserDropdownFavoritesProps {

}

export const HeaderUserDropdownFavorites: React.FC<HeaderUserDropdownFavoritesProps> = () => {

    const favs = useFavorites();

    const renderFavs = () => {
        if (favs.favorites.length > 0) {
            return <div className='w-full flex flex-col relative'>
                <div className='absolute right-0 top-0 translate -translate-y-8'>
                    <HybridLink external href={buildHelpDocURL(helpTopicDocIDs.FAVORITES)} openInNewTab>
                        <Button size={'icon'} variant={'ghost'}>
                            <QuestionMarkCircledIcon height={24} />
                        </Button>
                    </HybridLink>
                </div>
                {favs.getHrefsOnly().map((fav) => {
                    return <HybridLink
                        external
                        href={fav.REFERENCED_URL ?? ''}
                        className='text-sm p-0.5 rounded w-full flex flex-row justify-between items-center hover:cursor-pointer hover:underline'
                    >
                        <p className='w-96'>{fav.REFERENCED_URL_TITLE} {fav.archived && <span className='text-muted-foreground'>[Archived]</span>}</p>
                        <Button
                            variant={'ghost'}
                            size={'icon'}
                            className='align-top'
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                favs.toggleFavoriteToBeDeleted(fav, true);
                            }}
                        >
                            <Cross2Icon />
                        </Button>
                    </HybridLink>;
                })}
            </div>;
        } else {
            return <>
                <p className='text-sm font-normal text-primary pt-1'>No favorites</p>
                <p className='text-xs text-secondary'>Add to favorites by clicking the star icon on the page you wish to add</p>
            </>;
        }
    };


    return <div className='px-5'>
        {renderFavs()}
    </div>;


};