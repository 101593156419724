import React, { useState, useEffect } from 'react';
import { DocRevActiveStatuses, DOCUMENTREVISION, DocumentRevisionStatuses, DisplayStatuses } from '../../../../entities/org/DOCUMENTREVISION';
import axios from 'axios';
import DataTable from '../../DataTable/DataTable';
import { MUIDataTableColumn } from 'mui-datatables';

type SelectDocrevsProps = {
    statuses?: DocumentRevisionStatuses[],
    unselectableDocrevs?: DOCUMENTREVISION[],
    departmentID?: number,
    ignoreDocumentVisibility?: boolean,
    onChange: (docrevs: DOCUMENTREVISION[]) => void,
}

async function getDocrevs(
    statuses: DocumentRevisionStatuses[] = DocRevActiveStatuses,
    inputValue?: string,
    departmentID?: number,
    ignoreDocumentVisibility?: boolean,
)
    : Promise<DOCUMENTREVISION[]>
{
    const res = await axios.get('/api/_shared/forms/select/select-docrevs/', {
        params: {
            statuses,
            inputValue,
            departmentID,
            ignoreDocumentVisibility,
        }
    });

    return res.data.docrevs || [];
}

function formatDocRevForTableData(docrev: DOCUMENTREVISION) {
    return {
        Title: docrev.TITLE,
        Type: docrev.DOCTYPE?.DESCRIPTION,
        Status: DisplayStatuses[docrev.DOCSTATUS_ID],
        Department: docrev.DOCUMENT.INFORMATION_HIERARCHY?.TITLE,
    };
}

export function SelectDocrevs(props: SelectDocrevsProps) {
    const [ docrevs, setDocrevs ] = useState<DOCUMENTREVISION[]>([]);

    useEffect(() => {
        getDocrevs(
            props.statuses,
            '',
            props.departmentID,
            props.ignoreDocumentVisibility,
        ).then(docrevs => {
            setDocrevs(docrevs);
        });
    }, []);

    const tableData = docrevs.map(formatDocRevForTableData);

    return (
        <div>
            { tableData.length > 0 &&
            <DataTable
                muiDataTableProps={{
                    title: 'Document Search Results',
                    data: tableData,
                    columns: Object.keys(tableData[0]).map(key => {
                        const returnObj: MUIDataTableColumn = {
                            name: key,
                        };

                        if (key === 'Title') {
                            returnObj.options = {
                                filter: false,
                            };
                        }

                        return returnObj;
                    }),
                    options: {
                        selectableRows: 'multiple',
                        onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
                            const selectedDocRevs = rowsSelected.map(idx => docrevs[idx]);
                            props.onChange(selectedDocRevs);
                        },
                        isRowSelectable: (dataIndex) => {
                            return props.unselectableDocrevs?.findIndex(docrev => docrev.DOCREVID === docrevs[dataIndex].DOCREVID) === -1;
                        },
                    }
                }}
            />
            }
        </div>
    );
}
