import {
    Column,
    Entity,
    JoinColumn,
    ManyToOne,
    OneToMany,
    PrimaryColumn,
} from 'typeorm';
import { APPROVAL_SESSION_STEP,  } from './APPROVAL_SESSION_STEP';
import { EDIT_REQUEST,  } from './EDIT_REQUEST';
import { INFORMATION_HIERARCHY,  } from './INFORMATION_HIERARCHY';
import { SchemaEntityManager } from '../SchemaEntityManager';
import { DOCUMENTREVISION } from './DOCUMENTREVISION';

export enum ApprovalSessionTypes {
    archive = 'archive',
    officialize = 'officialize',
    review = 'review',
}

export enum ApprovalSessionStatuses {
    pending_approval_routing = 'pending_approval_routing',
    routing_cancelled_by_user = 'routing_cancelled_by_user',
    signatures_active = 'signatures_active',
    signatures_cancelled = 'signatures_cancelled',
    signatures_complete = 'signatures_complete',
    signatures_rejected = 'signatures_rejected',
    signatures_voided = 'signatures_voided',
    committees_active = 'committees_active',
    committees_cancelled_by_committee = 'committees_cancelled_by_committee',
    committees_cancelled_by_user = 'committees_cancelled_by_user',
    committees_complete = 'committees_complete',
    committees_pending = 'committees_pending',
    committees_rejected = 'committees_rejected',
    committees_voided = 'committees_voided',
    success = 'success',
}

function createApprovalSessionEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('APPROVAL_SESSION', { schema: schema })
    class APPROVAL_SESSION {
        static SCHEMANAME = schema;
        @PrimaryColumn('number', {
            nullable: false,
            name: 'APPROVAL_SESSION_ID',
        })
        APPROVAL_SESSION_ID!: number;

        @Column('number', {
            nullable: false,
            name: 'DOCREVID',
        })
        DOCREVID!: number;

        @ManyToOne(
            () => manager.getDocumentRevisionEntity(schema),
            (documentRevision) => documentRevision.APPROVAL_SESSIONS
        )
        @JoinColumn({
            name: 'DOCREVID',
        })
        DOCUMENTREVISION!: DOCUMENTREVISION | null;

        @Column('varchar2', {
            nullable: false,
            length: 20,
            name: 'TYPE',
        })
        TYPE!: ApprovalSessionTypes;

        @Column('timestamp', {
            nullable: false,
            default: () => 'SYSDATE',
            name: 'START_DATE',
        })
        START_DATE!: Date;

        @Column('timestamp', {
            nullable: true,
            name: 'COMPLETION_DATE',
        })
        COMPLETION_DATE!: Date | null;

        @Column('number', {
            nullable: false,
            name: 'MANUALID',
            default: () => '0',
        })
        MANUALID!: number;

        @ManyToOne(
            () => manager.getInformationHierarchyEntity(schema),
            (informationHierarchy) => informationHierarchy.APPROVAL_SESSIONS
        )
        @JoinColumn({
            name: 'MANUALID',
        })
        MANUAL!: INFORMATION_HIERARCHY | null;

        @Column('varchar2', {
            nullable: false,
            length: 64,
            name: 'LATEST_STATUS',
        })
        LATEST_STATUS!: ApprovalSessionStatuses;

        @OneToMany(
            () => manager.getApprovalSessionStepEntity(schema),
            (approvalSessionStep) => approvalSessionStep.APPROVAL_SESSION
        )
        APPROVAL_SESSION_STEPS!: APPROVAL_SESSION_STEP[] | null;

        @OneToMany(
            () => manager.getEditRequestEntity(schema),
            (editRequest) => editRequest.APPROVAL_SESSION
        )
        EDIT_REQUESTS!: EDIT_REQUEST[] | null;

        static Types = ApprovalSessionTypes;
        static Statuses = ApprovalSessionStatuses;
    }

    return APPROVAL_SESSION;
}

export { createApprovalSessionEntity };
export type APPROVAL_SESSION = InstanceType<ReturnType<typeof createApprovalSessionEntity>>;
