import React, { forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { MUIDataTableOptions } from 'mui-datatables';

import { LoadingIndicator } from '../../../../_shared/LoadingIndicator';
import { LoadingStatuses } from '../../../../../utils/LoadingStatuses';
import { COMMITTEE, CommitteeStatuses } from '../../../../../entities/org/COMMITTEE';
import axios from 'axios';
import DataTable from '../../../../_shared/DataTable/DataTable';
import hasRights from '../../../../../_shared/utils/hasRights';
import UserBundleContext from '../../../../../context/UserBundleContext';
import { Link } from 'react-router-dom';
import SaveBar from '../../../../_shared/SaveBar/SaveBar';
import { redirectToHomepage } from '../../../../../utils/savebarUtilities';
import { RightsCodeKey } from '@/entities/master/PERSON';
import { makeStyles } from '@material-ui/core';
import isInDocManagerPerl from '@/utils/isInDocManagerPerl';
import isInAdminPerl from '@/utils/isInAdminPerl';
import { constructFullName } from '@/components/UserPicker/utils/constructFullName';

export interface IMyCommitteesHandle {
    refreshCommittees: () => void;
}

const useStyles = makeStyles({
    myCommitteesTable: {
        position: 'relative',
        zIndex: 0
    },
});

const MyCommittees = forwardRef<IMyCommitteesHandle, { activeCommitteesOnly: boolean; }>((props, ref) => {
    const context = useContext(UserBundleContext);
    const classes = useStyles();

    const [loadingStatus, setLoadingStatus] = useState<LoadingStatuses>(LoadingStatuses.MOUNTING);

    const isCommitteeAdmin = hasRights(
        context,
        [
            RightsCodeKey.DocumentAdministrator,
            RightsCodeKey.OrganizationAdministrator
        ]
    );

    const [committees, setCommittees] = useState<COMMITTEE[]>([]);

    const loadInitialData = async () => {
        const res = await axios.get('/api/committee');
        setCommittees(res.data.committees);
        setLoadingStatus(LoadingStatuses.READY);
    };

    useEffect(() => {
        if (
            loadingStatus === LoadingStatuses.LOADING ||
            loadingStatus === LoadingStatuses.MOUNTING
        ) {
            loadInitialData().then();
        }
    }, [loadingStatus]);

    useImperativeHandle(ref, () => ({
        refreshCommittees() {
            setLoadingStatus(LoadingStatuses.LOADING);
        }
    }));

    const formattedCommitteesData = (committees || [])
        .filter(com => props.activeCommitteesOnly ? com.STATUS === CommitteeStatuses.ACTIVE : true)
        .map(
            committee => {
                const returnObj: {
                    [key: string]: string | React.ReactNode;
                } = {
                    ID: committee.COMMITTEE_ID,
                    Name: committee.NAME,
                    Chairperson: committee.CHAIRPERSON ? constructFullName(committee.CHAIRPERSON, 'lastFirstMiddle') : '',
                    Secretary: committee.SECRETARY ? constructFullName(committee.SECRETARY, 'lastFirstMiddle') : '',
                };

                if (isCommitteeAdmin) {
                    returnObj['Status'] = committee.STATUS === CommitteeStatuses.ACTIVE ? 'Active' : 'Archived';
                    returnObj['Minutes Department'] = `${committee.MINUTES_DEPARTMENT?.TITLE}`;
                }

                return returnObj;
            }
        );

    const committeeDataTableOptions: MUIDataTableOptions = {
        // sorts the "Name" column by default
        sortOrder: {
            name: 'Name',
            direction: 'asc'
        }
    };

    const noHeaderParam = (isInAdminPerl() || isInDocManagerPerl()) ? '&noheader=true' : '';

    return <div>
        {
            loadingStatus !== LoadingStatuses.READY &&
            <LoadingIndicator />
        }
        {
            loadingStatus === LoadingStatuses.READY &&
            <div className={classes.myCommitteesTable}>
                <DataTable
                    muiDataTableProps={{
                        title: isCommitteeAdmin ? 'Committees' : 'My Committees',
                        data: formattedCommitteesData,
                        columns: formattedCommitteesData[0] &&
                            Object.keys(formattedCommitteesData[0]).map(key => ({
                                name: key,
                                options: {
                                    customBodyRender: key === 'Name' ? (value, tableMeta) => <Link
                                        style={{ textDecoration: 'none' }}
                                        to={`/committees?idOfCommitteeBeingEdited=${tableMeta.rowData[0]}${noHeaderParam}`}
                                    >
                                        {tableMeta.rowData[1]}
                                    </Link> : undefined
                                }
                            })),
                        options: committeeDataTableOptions
                    }}
                    onRefresh={() => setLoadingStatus(LoadingStatuses.LOADING)}
                />
            </div>
        }
        
        {(!isInAdminPerl() && !isInDocManagerPerl()) && <SaveBar
            onClose={() => {
                redirectToHomepage();
            }}
        />}
    </div>;

});

export default MyCommittees;
